import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { InnovationProject } from '../../../../api-responses/InnovationProjects.interface';
import { BusinessData } from '../../../../api-responses/BusinessData.interface';
import {TreebuteProjectSummaryReducer} from "../../../../utils/treebuteProjectSummaryReducer";
import {Router} from "@angular/router";

@Component({
    selector: 'app-search-summary',
    templateUrl: './search-summary.component.html',
    styleUrls: ['./search-summary.component.scss'],
})
export class SearchSummaryComponent implements OnInit {
    @Input() project: InnovationProject;
    @Input() industryTableChildData: BusinessData[];
    @Input() title: string;
    @Input() pluralName: string;
    @Input() isIndustry: boolean;

    @Output() userClickDelete = new EventEmitter();

    @Input() readOnlyMode: boolean;
    @Output() clickChip = new EventEmitter();
    @Output() deleteSearch = new EventEmitter<number>();

    constructor(private router: Router,) {}

    ngOnInit() {}

    getWordCloud() : Map<String, Int16Array>{
        const result = {};
        const resultMultiplier = {};
        let candidates = this.project.treebuteSearchResults.filter(x =>
            this.isIndustry ?
                TreebuteProjectSummaryReducer.isIndustry(x) :
                !TreebuteProjectSummaryReducer.isIndustry(x));

        for (const treebuteSearchResult of candidates) {
            for (const key of Object.keys(treebuteSearchResult.wordCloud)) {
                let multiplier = resultMultiplier[key] ? resultMultiplier[key]*.8 : 1;
                resultMultiplier[key] = multiplier;

                let newVal = Number(treebuteSearchResult.wordCloud[key]) * multiplier;
                let val = result[key];
                val = val ? Number(val) : 0;
                result[key] = val + newVal;
            }
        }

        let values = Object.values(result).sort((a:any,b:any) => a-b);
        let max = values[values.length-1];
        let min = values[0];

        let from = 10;
        let to = 30;

        for (const key of Object.keys(result)) {
            let value = result[key];
            result[key] = Math.ceil(this.normalize(value, min, max, from, to));
        }

        let resultList = [];
        for (const key of Object.keys(result)) {
            resultList.push([key, result[key]]);
        }

        resultList = resultList.sort((a,b) => a[1]-b[1]).reverse().slice(0, 30);

        const filteredResult:Map<String, Int16Array> = new Map<String, Int16Array>();

        resultList.forEach(x => filteredResult[x[0]] = x[1]);

        return filteredResult;
    }

    normalize(val, min, max, from, to) { return val * (to - from) / (max - min); }

    wordClick($event: any) {
        // navigate to search page
        const searchType = this.isIndustry ? 'CORPORATES' : 'ACADEMIC_EXPERTS';
        this.router.navigate([`/app/projects/${this.project.id}/search`], { queryParams: { query: $event, searchType: searchType } });
    }
}
