<div class="search" id="search-bar">
    <div class="search-tabs">
        <div class="search-nav" [class.active]="this.isIndustry" (click)="tabClick('industry')">Industry</div>
        <div class="search-nav" [class.active]="this.isExperts" (click)="tabClick('experts')">Identify an Expert</div>
        <div class="search-nav" [class.active]="this.isKnowledge" (click)="tabClick('knowledge_map')" [hidden]="dataService.hideShared()">Knowledge map</div>
    </div>
    <div >
        <basic-search-tool
                [hidden]="!this.isIndustry"
                [searchResults]="getSearchResults('industry')"
                [searchOptions]="['CORPORATES', 'STARTUPS']"
                (searchSubmit)="this.onSubmitIndustry($event)"></basic-search-tool>

        <experts-search-tool
                [hidden]="!this.isExperts"
                [searchResults]="getSearchResults('experts')"
                (searchSubmit)="this.onSubmitExperts($event)"></experts-search-tool>

        <tiles-search-tool
                [hidden]="!this.isKnowledge"
                [searchResults]="getSearchResults('knowledge_map')"
                [searchOptions]="this.tileOptions"
                [selectedCountry]="this.selectedCountry"
                (searchSubmit)="this.onSubmitTiles($event)"></tiles-search-tool>
    </div>
</div>
