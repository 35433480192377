export interface UserInfo {
    id: number;
    email: string;
    created_at: number;
    firstName: string;
    lastName: string;
    authorities: string[];
    instituation: string;
    institutionLogoUrl: string;
    institutionHasResources: boolean;
    latitude: number;
    longitude: number;
    hasPendingPayments: boolean;
    instituationType: string;
    biomed: boolean;
    globalSearch: boolean;
    remainingDashboards: number;
    limitedUserMaxResultsSize: number;
    limitedObfuscatedResultsSize: number;
    intercomAppId: string;
    canonicalPrefix: string;
    dev: boolean;
}

export interface User {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    institution: string;
    limitedUserMaxResultsSize: number;
    roles: UserRole[];
    treebuteAccountId: number;
    suspended: boolean;
}

export type UserRole =
    | 'USER'
    | 'ADMIN'
    | 'DASHBOARD'
    | 'LIMITED'
    | 'STARTUP_FINDER'
    | 'CORPORATE_FINDER'
    | 'ACCOUNT_ADMIN';

export const roleTypes: any = [
    'USER',
    'ADMIN',
    'DASHBOARD',
    'LIMITED',
    'STARTUP_FINDER',
    'CORPORATE_FINDER',
    'ACCOUNT_ADMIN'
];

export interface InvitedUser {
    userEmail: string;
    treebuteAccount: number;
    userRole: string;
}

export interface TreebuteAccount {
    id: number;
    name: string;
    domain: string;
    institutionName: string;
    licenses: number;
    limited: boolean;
    creationDate: Date;
    users: User[];
    canonicalPrefix: string;
}

export interface RegistrationForm {
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    accept: boolean
}
