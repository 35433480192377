<div class="myContainer">
    <div class="col-12">
        <div class="side col-6">
            <textarea
                style="width: 49%; float: left"
                rows="50"
                [(ngModel)]="input"
            ></textarea>
        </div>
        <div class="side col-6">
            <textarea
                style="width: 49%; float: right"
                rows="50"
                [(ngModel)]="output"
            ></textarea>
        </div>
    </div>
    <div class="col-12">
        <input
            class="btn btn-primary"
            type="button"
            (click)="getCtPhases()"
            value="Get Ct Phases"
        />
    </div>
</div>
