import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SearchDetailsModalData, YesNoModalData} from "../../../api-responses/Dialogs.interface";
import {TreebuteProjectSummaryReducer} from "../../../utils/treebuteProjectSummaryReducer";
import {SearchQueryResults} from "../../../api-responses/SearchQueryResults.interface";
import {BusinessColors, EvidenceColors, ExpertsColors} from "../../../utils/colors.enum";

@Component({
    selector: 'search-details-modal',
    templateUrl: './search-details-modal.component.html',
    styleUrls: ['./search-details-modal.component.scss'],
})
export class SearchDetailsModalComponent implements OnInit {

    color: string;
    showWords: boolean;
    wordCloudTerms: string[];
    numberOfResults: number;

    constructor(public dialogRef: MatDialogRef<SearchDetailsModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: SearchDetailsModalData) {
    }

    ngOnInit(): void {
        if (TreebuteProjectSummaryReducer.isAcademicExperts(this.data.query)) {
            this.color = ExpertsColors.ACADEMIC_EXPERTS;
        }
        if (TreebuteProjectSummaryReducer.isCtExperts(this.data.query)) {
            this.color = ExpertsColors.CT_EXPERTS;
        }
        else if (TreebuteProjectSummaryReducer.isIndustryExperts(this.data.query)) {
            this.color = ExpertsColors.INDUSTRY_EXPERTS;
        }
        else if (TreebuteProjectSummaryReducer.isTileSearch(this.data.query)) {
            this.color = ExpertsColors.TILE_SEARCH_EXPERTS;
        }
        else if (TreebuteProjectSummaryReducer.isStartups(this.data.query)) {
            this.color = BusinessColors.STARTUPS;
        }
        else if (TreebuteProjectSummaryReducer.isCorporates(this.data.query)) {
            this.color = BusinessColors.CORPORATES;
        }

        this.showWords = Object.keys(this.data.query.wordCloud).length > 0;

        this.wordCloudTerms = this.getWordCloudTerms(this.data.query);

        this.numberOfResults = this.getNumberOfResults(this.data.query);
    }

    cancel() {
        this.dialogRef.close('cancel');
    }

    no() {
        this.dialogRef.close('no');
    }

    yes() {
        this.dialogRef.close('yes');
    }

    getYesText() {
        return this.data.yesText ? this.data.yesText : 'Yes';
    }

    getNoText() {
        return this.data.noText ? this.data.noText : 'No';
    }

    getCancelText() {
        return this.data.cancelText ? this.data.cancelText : 'Cancel';
    }

    close() {
        this.dialogRef.close();
    }

    getNumberOfResults(query: SearchQueryResults) {
        if (TreebuteProjectSummaryReducer.isExperts(query) || TreebuteProjectSummaryReducer.isTileSearch(query)) {
            return query.expertSearchResult.researchers.filter(x => x.exclude !== true).length;
        }
        else {
            return query.institutionSearchResult.institutions.filter(x => x.exclude !== true).length;
        }
    }

    getWordCloudTerms(query: SearchQueryResults) {
        // Create items array
        let items = Object.keys(query.wordCloud).map(function(key) {
            return [key, query.wordCloud[key]];
        });

        // Sort the array based on the second element
        items.sort(function(first, second) {
            return second[1] - first[1];
        });

        return items.map(x => x[0]).splice(0, 5);
    }
}
