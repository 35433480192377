import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NetworkService } from '../../network.service';
import { UtilitiesService } from '../../utilities.service';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }
    // private _routerSub = Subscription.EMPTY;
    // searchString = '';
    // query = '';
    // exportLoading = false;
    // loading = false;
    // showChart = false;
    // noResults = false;
    //
    // graphs: [];
    //
    // // https://www.tutorialspoint.com/angular_googlecharts/angular_googlecharts_line_basic.htm
    // googleChartOptions = {
    //     type: 'LineChart',
    //     width: 1200,
    //     height: 600,
    //     options: {
    //         // vAxis: {
    //         //   viewWindowMode:'explicit',
    //         //   viewWindow: {
    //         //     min:0
    //         //   }
    //         // }
    //     },
    // };
    //
    // constructor(
    //     private networkService: NetworkService,
    //     private router: Router,
    //     private route: ActivatedRoute,
    //     private cdRef: ChangeDetectorRef,
    // ) {
    //     this._routerSub = this.router.events
    //         .pipe(filter((event) => event instanceof NavigationEnd))
    //         .subscribe((event) => {
    //             if (event instanceof NavigationEnd) {
    //                 const searchString =
    //                     this.route.snapshot.params['searchString'];
    //                 this.searchString =
    //                     searchString.length >= 3 ? searchString : '';
    //                 if (this.searchString !== '') {
    //                     this.onSearchSubmit();
    //                 }
    //             }
    //         });
    // }
    //
    // ngOnInit() {}
    //
    // ngOnDestroy() {
    //     this._routerSub.unsubscribe();
    // }
    //
    // onSearchSubmit() {
    //     this.noResults = false;
    //     this.showChart = false;
    //     this.loading = true;
    //     this.networkService.getTimeline(this.searchString).subscribe(
    //         (data) => {
    //             this.handleResponse(data);
    //             if (this.graphsHaveResults()) {
    //                 this.showChart = true;
    //             } else {
    //                 this.noResults = true;
    //             }
    //             this.loading = false;
    //         },
    //         (error) => {
    //             console.log(error);
    //             this.showChart = false;
    //             this.loading = false;
    //             this.noResults = true;
    //         },
    //     );
    // }
    //
    // handleResponse(data) {
    //     this.query = this.searchString;
    //     this.graphs = data;
    //     for (const i in this.graphs) {
    //         if (!i) {
    //             continue;
    //         }
    //         const graph: any = this.graphs[i];
    //         graph.data.forEach((row) => {
    //             if (typeof row[0] === 'number') {
    //                 row[0] = new Date(row[0]); // convert millis to Date
    //             }
    //         });
    //     }
    // }
    //
    // graphsHaveResults() {
    //     for (const i in this.graphs) {
    //         if (!i) {
    //             continue;
    //         }
    //         const graph: any = this.graphs[i];
    //         if (graph.data.length > 0) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }
    //
    // export() {
    //     this.exportLoading = true;
    //     this.networkService
    //         .exportTimelineGraph(this.graphs, this.query)
    //         .subscribe(
    //             (results) => {
    //                 this.exportLoading = false;
    //                 this.cdRef.detectChanges();
    //                 const options = { type: 'application/ms-excel' };
    //                 const queryTrimmed =
    //                     this.searchString === undefined
    //                         ? null
    //                         : this.searchString.length < 50
    //                         ? this.searchString
    //                         : this.searchString.substring(0, 50) + '...';
    //                 const filename = queryTrimmed + ' - research timeline.xlsx';
    //                 this.createAndDownloadBlobFile(results, options, filename);
    //             },
    //             (error) => {
    //                 this.exportLoading = false;
    //                 this.cdRef.detectChanges();
    //                 console.log(error);
    //             },
    //         );
    // }
    //
    // createAndDownloadBlobFile(body, options, filename) {
    //     const blob = new Blob([body], options);
    //     if (navigator.msSaveBlob) {
    //         // IE 10+
    //         navigator.msSaveBlob(blob, filename);
    //     } else {
    //         const link = document.createElement('a');
    //         // Browsers that support HTML5 download attribute
    //         if (link.download !== undefined) {
    //             const url = URL.createObjectURL(blob);
    //             link.setAttribute('href', url);
    //             link.setAttribute('download', filename);
    //             link.style.visibility = 'hidden';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     }
    // }
    //
    // vw() {
    //     return UtilitiesService.vw();
    // }
}
