import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

declare var Chartist: any;

@Component({
    selector: 'app-expert-data',
    templateUrl: './expert-data.component.html',
    styleUrls: ['./expert-data.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExpertDataComponent implements OnInit {
    @Input() expert;
    chart;

    constructor() {}

    ngOnInit() {
        this.drawDescriptorsChart();
    }

    noText(ints) {
        return ints + '%';
    }

    drawDescriptorsChart() {
        const array_1_values = []; // these are the values of the first line
        const array_labels = []; // these are the labels that will appear at the bottom of the chart

        this.expert.expertData.children.forEach((child) => {
            array_labels.push(child.name);
            array_1_values.push(child.size);
        });

        // create a prototype multi-dimensional array
        const data_chart1 = {
            labels: [],
            series: [[]],
        };

        // populate the multi-dimensional array
        for (let i = 0; i < array_1_values.length; i += 1) {
            data_chart1.series[0].push(array_1_values[i]);
            data_chart1.labels.push(array_labels[i]);
        }

        const bar = new Chartist.Bar('.piechart', data_chart1, {
            seriesBarDistance: 10,
            reverseData: true,
            horizontalBars: true,
            axisY: {
                offset: 150,
            },
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value + '%';
                },
            },
            plugins: [
                Chartist.plugins.tooltip({
                    class: 'class1 class2',
                    appendToBody: true,
                    transformTooltipTextFnc: this.noText.bind(this),
                }),
            ],
        });
    }
}
