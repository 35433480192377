import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ChartDist, SelectedField} from "../../../../../api-responses/graphs.interface";
import {Chart, ChartConfiguration, ChartType} from "chart.js";
import {BusinessColors, EvidenceColors, ExpertsColors} from "../../../../../utils/colors.enum";
import {DataService} from "../../../../../data.service";
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'geo-chart',
    templateUrl: './geo-chart.component.html',
    styleUrls: ['./geo-chart.component.scss']
})
export class GeoChartComponent implements OnInit, AfterViewInit  {

    @Input() countries: ChartDist[];
    @ViewChild('canvas') canvasElementRef: ElementRef<HTMLCanvasElement>;

    @Input() ratio = 3;

    chart: Chart;
    chartOptions: Chart.ChartConfiguration = {
        type: 'doughnut',
        plugins: [ChartDataLabels],
        data: {
            datasets: null,
        },
        options: {
            layout: {
              padding: {
                  bottom: 25
              }
            },
            tooltips: {
                enabled: true
            },
            title: {
                display: false
            },
            legend: {
                align: 'center',
                display: true,
                position: 'left',
                fullWidth: true,
                labels: {
                    fontColor: '#d8d8d8'
                }
            },
            plugins: {
                // Change options for ALL labels of THIS CHART
                datalabels: {
                    color: '#dff5ff',
                    anchor: 'end',
                    align: 'end',
                    display: 'auto',
                    offset: 0,
                    formatter: (value, ctx) => {
                        return ctx.chart.config.data.labels[ctx.dataIndex];
                        // debugger
                        // let dataArr = ctx.chart.data.datasets[0].data as number[];
                        // let sum = dataArr.reduce((a,b) => a+b, 0);
                        // let percentage = (value*100 / sum).toFixed(0)+"%";
                        // return percentage;
                    },
                }
            }
        },

    };

    constructor(private ngZone: NgZone, public dataService: DataService) {
        Chart.plugins.register(ChartDataLabels);
    }

    @Input() set data(
        inputData: Chart.ChartDataSets & {
            datasets: any,
            labels: any
        },
    ) {
        // This will update the chart in a nice way if it was rendered before

    }

    ngOnInit() {
        if (!this.chart) {
            let topCountries = this.countries;
            if (topCountries.length >= 10) {
                topCountries = this.countries.slice(0, 9);
                let countOther = this.countries.slice(9, this.countries.length)
                if (countOther && countOther.length > 0) {
                    topCountries.push({
                        txt: 'Other',
                        count: countOther.map(x => x.count).reduce((a, b) => a + b, 0)
                    });
                }
            }

            this.chartOptions.data.datasets = [{
                label: 'country',
                data: topCountries.map(x => x.count).map(x => Number(x)),
                backgroundColor: [
                    EvidenceColors.grantCount,
                    EvidenceColors.clinicalTrialCount,
                    EvidenceColors.publicationCount,
                    EvidenceColors.websiteCount,
                    EvidenceColors.patentCount,
                    ExpertsColors.TILE_SEARCH_EXPERTS,
                    ExpertsColors.CT_EXPERTS,
                    ExpertsColors.INDUSTRY_EXPERTS,
                    ExpertsColors.ACADEMIC_EXPERTS,
                    BusinessColors.STARTUPS,
                    BusinessColors.CORPORATES
                ],
                borderColor: 'transparent'
            }]
            this.chartOptions.data.labels = topCountries.map(x => {
                let result = '';
                for (const s of x.txt.split(" ")) {
                    result += (s[0].toUpperCase() + s.substring(1) + " ");
                }
                return result + `(${x.count})`;
            });
        }

        //
        // this.updateFilter();
    }

    ngAfterViewInit(): void {
        this.chart = this.ngZone.runOutsideAngular(() => {
            const context =
                this.canvasElementRef.nativeElement.getContext('2d');
            return new Chart(context, this.chartOptions);
        });
    }

}
