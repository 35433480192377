<div *ngIf="loading">
    <div class="loader"></div>
</div>

<a class="btn-info btn-lg noResults" *ngIf="noResults">
    <span class="glyphicon glyphicon-warning-sign"></span> No results - please
    enter different text
</a>

<div id="container" *ngIf="!loading && rating.length > 0">
    <header>
        <div class="title">Competitive Analysis</div>
        <div
            class="arrow"
            [style.backgroundImage]="'url(assets/images/white_arrow.png)'"
            [style.transform]="'rotate(180deg)'"
        ></div>
        <div
            class="query ellipsis"
            (ngChange)="checkWidth($event)"
            (mouseover)="checkWidth($event)"
            title=""
        >
            {{ searchString }}
        </div>
    </header>

    <main *ngIf="!loading" id="main">
        <div id="top">
            <div id="rating" [class.dropDownOpen]="dropDownOpenRating">
                <div
                    *ngIf="vw() > 900"
                    class="sectionTitle ellipsis"
                    (ngChange)="
                        checkWidth($event, [
                            'Top',
                            rating.length,
                            'institutions according to their leadership in',
                            searchString,
                            'research'
                        ])
                    "
                    (mouseover)="
                        checkWidth($event, [
                            'Top',
                            rating.length,
                            'institutions according to their leadership in',
                            searchString,
                            'research'
                        ])
                    "
                    title=""
                >
                    Top
                    <span style="font-size: 14px; line-height: 13px">{{
                        rating.length
                    }}</span>
                    institutions according to their leadership in
                    {{ searchString }} research
                </div>

                <div
                    *ngIf="vw() <= 900"
                    [style.padding]="vw() <= 900 ? '0 22px 0 25px' : null"
                    class="sectionTitle ellipsis"
                    (click)="dropdownClicked('rating')"
                    (ngChange)="
                        checkWidth($event, [
                            'Top',
                            rating.length,
                            'institutions according to their leadership in',
                            searchString,
                            'research'
                        ])
                    "
                    (mouseover)="
                        checkWidth($event, [
                            'Top',
                            rating.length,
                            'institutions according to their leadership in',
                            searchString,
                            'research'
                        ])
                    "
                    title=""
                >
                    <div style="display: flex">
                        <img
                            src="assets/images/grants_trending.png"
                            style="
                                width: 34px;
                                height: 33px;
                                margin-right: 18px;
                            "
                        />
                        <div>
                            TOP <br />
                            INSTITUTIONS
                        </div>
                    </div>

                    <button
                        id="topInstitutionsDropdownBtn"
                        class="dropDownBtn"
                        [style.backgroundImage]="'url(assets/images/arrow.png)'"
                        [class.dropDownBtnOpen]="dropDownOpenRating"
                    ></button>
                </div>

                <div class="institutes">
                    <div
                        class="institute"
                        *ngFor="let institute of rating"
                        [class.myInstitute]="institute[0] === myInstitute"
                        (click)="myInstitute = institute[0]; scroll()"
                        (ngChange)="checkWidth($event, institute[0])"
                        (mouseover)="checkWidth($event, institute[0])"
                        title=""
                    >
                        <div class="instituteNumber">{{ institute[4] }}</div>
                        <div
                            class="instituteName ellipsis"
                            [style.paddingRight]="vw() <= 900 ? '20px' : null"
                        >
                            {{ institute[0] }}
                        </div>
                        <div
                            class="instituteBar"
                            [style.width]="
                                normalization(institute[1], 110, 20) + 'px'
                            "
                        ></div>
                    </div>
                </div>
            </div>

            <div id="activity" [class.dropDownOpen]="dropDownOpenActivity">
                <div *ngIf="vw() > 900" class="sectionTitle">
                    Research activity over time
                </div>

                <div
                    *ngIf="vw() <= 900"
                    class="sectionTitle ellipsis"
                    (click)="dropdownClicked('activity')"
                    (ngChange)="
                        checkWidth($event, [
                            'Top',
                            rating.length,
                            'institutions according to their leadership in',
                            searchString,
                            'research'
                        ])
                    "
                    (mouseover)="
                        checkWidth($event, [
                            'Top',
                            rating.length,
                            'institutions according to their leadership in',
                            searchString,
                            'research'
                        ])
                    "
                    title=""
                >
                    <div style="display: flex">
                        <img
                            src="assets/images/inst_rating.png"
                            style="
                                width: 40px;
                                height: 33px;
                                margin-right: 15px;
                            "
                        />
                        <div>
                            INSTITUTIONS <br />
                            ACTIVITY
                        </div>
                    </div>

                    <button
                        id="institutionsActivityDropdownBtn"
                        class="dropDownBtn"
                        [style.backgroundImage]="'url(assets/images/arrow.png)'"
                        [class.dropDownBtnOpen]="dropDownOpenActivity"
                    ></button>
                </div>

                <div class="activityContent">
                    <div
                        id="chartContainer"
                        style="
                            height: calc(100% - 52px);
                            width: 100%;
                            overflow-x: hidden;
                            overflow-y: auto;
                        "
                    >
                        <div
                            id="activityInstitutions"
                            [style.width]="
                                years.length < numOfYears
                                    ? activityInstitutionsWidth(
                                          years.length * 60 + 160 + 'px'
                                      )
                                    : null
                            "
                            [style.height]="
                                checkBrowser() === 'Edge'
                                    ? rating.length * 60 + 'px'
                                    : null
                            "
                        >
                            <div
                                (click)="myInstitute = institute[0]"
                                id="{{ institute[0] }}"
                                *ngFor="let institute of rating"
                                class="activityInst"
                                [class.selectedInstitute]="
                                    institute[0] === myInstitute
                                "
                                style="display: flex"
                                (ngChange)="checkWidth($event, institute[0])"
                                (mouseover)="checkWidth($event, institute[0])"
                                title=""
                            >
                                <div
                                    class="instituteNumber"
                                    [style.width]="
                                        vw() <= 900 ? '30px' : '50px'
                                    "
                                >
                                    {{ institute[4] }}
                                </div>
                                <div
                                    class="instituteName"
                                    [style.width]="
                                        vw() <= 1300
                                            ? 'calc(100% - 30px)'
                                            : 'calc(100% - 50px)'
                                    "
                                    [class.ellipsis]="vw() > 500"
                                    [style.fontSize]="
                                        vw() > 500 ? '13px' : '12px'
                                    "
                                >
                                    {{
                                        vw() <= 500
                                            ? calcInstituteNameLength(
                                                  institute[0]
                                              )
                                            : institute[0]
                                    }}
                                </div>
                            </div>
                        </div>

                        <div
                            style="display: flex"
                            [style.height]="
                                checkBrowser() === 'Edge'
                                    ? rating.length * 60 + 'px'
                                    : null
                            "
                        >
                            <div
                                id="activityCircles"
                                [style.maxWidth]="years.length * 60 + 'px'"
                            >
                                <div
                                    class="previousYearsBar"
                                    id="previousYears"
                                    *ngIf="checkBrowser() !== 'Edge'"
                                    [style.left]="
                                        (numOfYears - years.length) * 60 + 'px'
                                    "
                                >
                                    <svg
                                        [attr.width]="years.length * 60"
                                        height="60"
                                        *ngFor="let institute of rating"
                                        class="circlesSvg"
                                        id="{{
                                            'svg' +
                                                (rating.indexOf(institute) + 1)
                                        }}"
                                    >
                                        <circle
                                            *ngFor="let year of years"
                                            cy="30"
                                            [attr.fill]="
                                                institute[0] === myInstitute
                                                    ? '#008ded'
                                                    : '#9b9b9b'
                                            "
                                            [attr.cx]="
                                                years.indexOf(year) * 60 + 30
                                            "
                                            [attr.r]="
                                                normalization(
                                                    institute[2][
                                                        years.indexOf(year)
                                                    ],
                                                    60,
                                                    10,
                                                    this.maxVal,
                                                    this.minVal
                                                ) / 2
                                            "
                                        />

                                        <g *ngFor="let year of years">
                                            <g
                                                *ngIf="
                                                    isCircleInTheEdges() !==
                                                        years.indexOf(year) +
                                                            1 &&
                                                    isCircleInTheEdges() +
                                                        (numOfYears - 1) !==
                                                        years.indexOf(year) &&
                                                    normalization(
                                                        institute[2][
                                                            years.indexOf(
                                                                year
                                                            ) + 1
                                                        ],
                                                        60,
                                                        10,
                                                        this.maxVal,
                                                        this.minVal
                                                    ) !== 3
                                                "
                                            >
                                                <circle
                                                    *ngFor="
                                                        let n of [
                                                            1, 2, 3, 4, 5, 6
                                                        ]
                                                    "
                                                    cy="30"
                                                    style="
                                                        mix-blend-mode: color-burn;
                                                    "
                                                    [attr.fill]="
                                                        institute[0] ===
                                                        myInstitute
                                                            ? '#dbeffc'
                                                            : 'rgba(216, 216, 216, 0.35)'
                                                    "
                                                    [attr.cx]="
                                                        years.indexOf(year) *
                                                            60 +
                                                            makeCirclesArr(
                                                                30,
                                                                90,
                                                                numOfCircles(
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            )
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2,
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            ) +
                                                                                1
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2
                                                                ) + 2
                                                            )[
                                                                numOfCircles(
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            )
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2,
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            ) +
                                                                                1
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2
                                                                ) - n
                                                            ] || 0
                                                    "
                                                    [attr.r]="
                                                        makeCirclesArr(
                                                            normalization(
                                                                institute[2][
                                                                    years.indexOf(
                                                                        year
                                                                    )
                                                                ],
                                                                60,
                                                                10,
                                                                this.maxVal,
                                                                this.minVal
                                                            ) / 2,
                                                            normalization(
                                                                institute[2][
                                                                    years.indexOf(
                                                                        year
                                                                    ) + 1
                                                                ],
                                                                60,
                                                                10,
                                                                this.maxVal,
                                                                this.minVal
                                                            ) / 2,
                                                            numOfCircles(
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        )
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2,
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        ) + 1
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2
                                                            ) + 2
                                                        )[
                                                            numOfCircles(
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        )
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2,
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        ) + 1
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2
                                                            ) - n
                                                        ] || 0
                                                    "
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div
                                    class="previousYearsBar"
                                    id="previousYears"
                                    *ngIf="checkBrowser() === 'Edge'"
                                    [style.left]="
                                        (numOfYears - years.length) * 60 + 'px'
                                    "
                                >
                                    <svg
                                        [attr.width]="years.length * 60"
                                        height="60"
                                        *ngFor="let institute of rating"
                                        class="circlesSvg"
                                        id="{{
                                            'svg' +
                                                (rating.indexOf(institute) + 1)
                                        }}"
                                    >
                                        <g *ngFor="let year of years">
                                            <g
                                                *ngIf="
                                                    isCircleInTheEdges() !==
                                                        years.indexOf(year) +
                                                            1 &&
                                                    isCircleInTheEdges() +
                                                        (numOfYears - 1) !==
                                                        years.indexOf(year) &&
                                                    normalization(
                                                        institute[2][
                                                            years.indexOf(
                                                                year
                                                            ) + 1
                                                        ],
                                                        60,
                                                        10,
                                                        this.maxVal,
                                                        this.minVal
                                                    ) !== 3
                                                "
                                            >
                                                <circle
                                                    *ngFor="
                                                        let n of [
                                                            1, 2, 3, 4, 5, 6
                                                        ]
                                                    "
                                                    cy="30"
                                                    [attr.fill]="
                                                        institute[0] ===
                                                        myInstitute
                                                            ? 'rgba(219, 239, 252, 0.1)'
                                                            : 'rgba(216, 216, 216, 0.1)'
                                                    "
                                                    [attr.cx]="
                                                        years.indexOf(year) *
                                                            60 +
                                                            makeCirclesArr(
                                                                30,
                                                                90,
                                                                numOfCircles(
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            )
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2,
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            ) +
                                                                                1
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2
                                                                ) + 2
                                                            )[
                                                                numOfCircles(
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            )
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2,
                                                                    normalization(
                                                                        institute[2][
                                                                            years.indexOf(
                                                                                year
                                                                            ) +
                                                                                1
                                                                        ],
                                                                        60,
                                                                        10,
                                                                        this
                                                                            .maxVal,
                                                                        this
                                                                            .minVal
                                                                    ) / 2
                                                                ) - n
                                                            ] || 0
                                                    "
                                                    [attr.r]="
                                                        makeCirclesArr(
                                                            normalization(
                                                                institute[2][
                                                                    years.indexOf(
                                                                        year
                                                                    )
                                                                ],
                                                                60,
                                                                10,
                                                                this.maxVal,
                                                                this.minVal
                                                            ) / 2,
                                                            normalization(
                                                                institute[2][
                                                                    years.indexOf(
                                                                        year
                                                                    ) + 1
                                                                ],
                                                                60,
                                                                10,
                                                                this.maxVal,
                                                                this.minVal
                                                            ) / 2,
                                                            numOfCircles(
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        )
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2,
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        ) + 1
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2
                                                            ) + 2
                                                        )[
                                                            numOfCircles(
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        )
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2,
                                                                normalization(
                                                                    institute[2][
                                                                        years.indexOf(
                                                                            year
                                                                        ) + 1
                                                                    ],
                                                                    60,
                                                                    10,
                                                                    this.maxVal,
                                                                    this.minVal
                                                                ) / 2
                                                            ) - n
                                                        ] || 0
                                                    "
                                                />
                                            </g>
                                        </g>

                                        <circle
                                            *ngFor="let year of years"
                                            cy="30"
                                            [attr.fill]="
                                                institute[0] === myInstitute
                                                    ? '#008ded'
                                                    : '#acacac'
                                            "
                                            [attr.cx]="
                                                years.indexOf(year) * 60 + 30
                                            "
                                            [attr.r]="
                                                normalization(
                                                    institute[2][
                                                        years.indexOf(year)
                                                    ],
                                                    60,
                                                    10,
                                                    this.maxVal,
                                                    this.minVal
                                                ) / 2
                                            "
                                        />
                                    </svg>
                                </div>
                            </div>

                            <div class="currentYearBar">
                                <div
                                    *ngFor="let institute of rating"
                                    class="circlesRow"
                                >
                                    <div
                                        class="circle"
                                        [class.blue]="
                                            institute[0] === myInstitute
                                        "
                                        [style.width]="
                                            normalization(
                                                institute[3],
                                                60,
                                                10,
                                                this.maxVal,
                                                this.minVal
                                            ) + 'px'
                                        "
                                        [style.height]="
                                            normalization(
                                                institute[3],
                                                60,
                                                10,
                                                this.maxVal,
                                                this.minVal
                                            ) + 'px'
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="control">
                        <div
                            id="years"
                            [style.maxWidth]="years.length * 60 + 160 + 'px'"
                            [style.marginRight]="
                                rating.length > 5
                                    ? checkBrowser() !== 'Chrome'
                                        ? '16px'
                                        : '6px'
                                    : null
                            "
                        >
                            <div
                                class="previousYears"
                                id="previous"
                                [style.maxWidth]="years.length * 60 + 60 + 'px'"
                            >
                                <div
                                    style="
                                        display: flex;
                                        align-items: center;
                                        position: relative;
                                        height: 100%;
                                    "
                                >
                                    <button
                                        (click)="previous()"
                                        style="
                                            position: absolute;
                                            left: 0;
                                            display: flex;
                                            height: 100%;
                                            justify-content: center;
                                            align-items: center;
                                        "
                                        [disabled]="checkPrevious()"
                                        [class.nv]="checkPrevious()"
                                    >
                                        <img
                                            src="assets/images/prev-year.png"
                                        />
                                    </button>

                                    <div
                                        id="yearsRow"
                                        [style.maxWidth]="
                                            years.length * 60 + 'px'
                                        "
                                    >
                                        <div
                                            id="yearsContainer"
                                            [style.left]="
                                                (numOfYears - years.length) *
                                                    60 +
                                                'px'
                                            "
                                        >
                                            <div
                                                class="year"
                                                *ngFor="let year of years"
                                                style="
                                                    width: 60px;
                                                    flex-shrink: 0;
                                                "
                                            >
                                                {{ year }}
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        (click)="next()"
                                        style="
                                            position: absolute;
                                            right: 0;
                                            display: flex;
                                            height: 100%;
                                            justify-content: center;
                                            align-items: center;
                                        "
                                        [disabled]="checkNext()"
                                        [class.nv]="checkNext()"
                                    >
                                        <img
                                            src="assets/images/next-year.png"
                                        />
                                    </button>
                                </div>
                                <span class="yearsTitle"></span>
                            </div>

                            <div class="currentYear">
                                <span class="year"> {{ currentYear }} </span>
                                <span class="yearsTitle" *ngIf="vw() > 900">
                                    Future
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
