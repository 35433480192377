<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title">Add Expert to Request</div>
        <div class="search-tool-container">
            <div class="line">
                Filter Requests:
            </div>
            <div>
                <div class="search-tool-input">
                    <input type="text" #filterInput (keyup)="filter(filterInput.value)"/>
                </div>
                <div class="search-tool-selected-items">
                    <div *ngFor="let callout of filteredCallouts" class="p-1">
                    <button (click)="addToCallout(callout)" class="btn-dark border text-light">
                        {{ callout.title }}
                    </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="btn-primary px-3 py-2" (click)="closeModal()">
                Done
            </div>
        </div>
    </div>
</div>
