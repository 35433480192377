<div class="summary" *ngIf="project">
    <h2 class="pt-4 pb-4">{{ title }}</h2>
    <div>
        <app-chips
                [project]="project"
                [isIndustry]="this.isIndustry"
                (userClickDelete)="this.userClickDelete.emit($event)"
                (clickChip)="clickChip.emit($event)"
                (deleteSearch)="deleteSearch.emit($event)"
        ></app-chips>
    </div>
<!--    <div>-->
<!--                <app-map></app-map>-->
<!--    </div>-->
    <!--    <div [hidden]="true">-->
    <!--        <div class="vertical-banner-container">-->
    <!--            <div class="vertical-banner-cell">-->
    <!--                <div class="word-cloud-container">-->
    <!--                    <app-word-cloud-->
    <!--                            *ngIf="project"-->
    <!--                            [data]="this.getWordCloud()"-->
    <!--                            (wordClick)="this.wordClick($event)"-->
    <!--                    ></app-word-cloud>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="container-sep">-->
    <!--                <span>&nbsp;&nbsp;&nbsp;</span>-->
    <!--            </div>-->
    <!--            <div class="vertical-banner-cell-2">-->
    <!--                <app-industry-table *ngIf="project" [project]="project">-->
    <!--                </app-industry-table>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
</div>
