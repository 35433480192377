import {
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

// @ts-ignore
mapboxgl.accessToken =
    'pk.eyJ1Ijoib3JiZXJrb3YiLCJhIjoiY2tzcHp5NzU1MDBzbTJ2cG5ieWl4ZHc1eCJ9.RqP7o3Z1jP3ZCoQSeVRNlQ';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
    @ViewChild('map') canvasElementRef: ElementRef<HTMLCanvasElement>;

    map: mapboxgl.Map;

    constructor(private ngZone: NgZone) {}

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            const mapElement = this.canvasElementRef.nativeElement;

            this.map = new mapboxgl.Map({
                container: mapElement,
                style: 'mapbox://styles/mapbox/streets-v11', // style URL
                center: [34.7818, 32.0853], // Tel Aviv coordinates
                zoom: 9, // starting zoom
            });

            new mapboxgl.Marker().setLngLat([34.7818, 32.0853]).addTo(this.map);
        });
    }
}
