<div id="machineContainer">
    <button
        *ngIf="vw() > 1000 && !nextLoading"
        style="background-image: url('assets/images/experts-next.png')"
        id="nextMachine"
        (click)="nextMachine.emit()"
        [disabled]="!enableNextMachine"
    ></button>
    <button
        *ngIf="vw() > 1000 && !previousLoading"
        style="background-image: url('assets/images/experts-next.png')"
        id="previousMachine"
        (click)="previousMachine.emit()"
        [disabled]="!enablePreviousMachine"
    ></button>
    <div
        *ngIf="vw() > 1000 && nextLoading"
        style="
            background-image: url('assets/images/loadloop.gif');
            right: calc(50vw - 486px);
        "
        id="nextMachine"
        class="loadingSwitch"
    ></div>
    <div
        *ngIf="vw() > 1000 && previousLoading"
        style="
            background-image: url('assets/images/loadloop.gif');
            left: calc(50vw - 486px);
        "
        id="previousMachine"
        class="loadingSwitch"
    ></div>

    <div id="machineHeader">
        <img
            src="assets/images/close-dark.png"
            class="closeIcon"
            (click)="this.closeModal.emit()"
        />
        <a *ngIf="isAdmin()" routerLink="/app/admin/machMgmt">
            <img
                src="assets/images/edit.png"
                class="closeIcon edit"
                (click)="editMachine()"
            />
        </a>
        <div
            class="name ellipsis"
            (ngChange)="checkWidth($event, machine.name)"
            (mouseover)="checkWidth($event, machine.name)"
            title=""
        >
            {{ machine.name }}
        </div>

        <div class="headerContent">
            <div
                *ngIf="machine.institution"
                style="
                    display: flex;
                    align-items: center;
                    width: 50%;
                    flex-shrink: 1;
                "
            >
                <div
                    class="institutionIcon"
                    [style.backgroundImage]="
                        machine.institution['iconUrl']
                            ? 'url(' + machine.institution['iconUrl'] + ')'
                            : 'url(assets/images/university.png)'
                    "
                ></div>
                <div class="contactInfo" style="line-height: 16px">
                    {{ machine.institution['name'] }}
                </div>
            </div>

            <div
                style="
                    display: flex;
                    justify-content: flex-end;
                    width: calc(50% + 30px);
                    flex-shrink: 0;
                "
            >
                <div *ngIf="machine.contacts.length > 0" class="contact">
                    <div
                        class="academicContactIcon"
                        style="
                            background-image: url('assets/images/expert-dark.png');
                        "
                    ></div>
                    <div class="infoContainer">
                        <div
                            class="contactInfo ellipsis"
                            (ngChange)="
                                checkWidth($event, machine.contacts[0]['name'])
                            "
                            (mouseover)="
                                checkWidth($event, machine.contacts[0]['name'])
                            "
                            title=""
                        >
                            {{ machine.contacts[0]['name'] }}
                        </div>
                        <a
                            *ngIf="
                                machine.contacts[0]['email'] &&
                                machine.contacts[0]['email'] !== 'unknown'
                            "
                            class="contactInfo ellipsis"
                            target="_blank"
                            href="mailto:{{ machine.contacts[0]['email'] }}"
                            (ngChange)="
                                checkWidth($event, machine.contacts[0]['email'])
                            "
                            (mouseover)="
                                checkWidth($event, machine.contacts[0]['email'])
                            "
                            title=""
                        >
                            {{ machine.contacts[0]['email'] }}
                        </a>
                    </div>
                </div>

                <div *ngIf="machine.contacts.length > 1" class="contact">
                    <div
                        class="academicContactIcon"
                        style="
                            background-image: url('assets/images/expert-dark.png');
                        "
                    ></div>
                    <div class="infoContainer">
                        <div
                            class="contactInfo ellipsis"
                            (ngChange)="
                                checkWidth($event, machine.contacts[1]['name'])
                            "
                            (mouseover)="
                                checkWidth($event, machine.contacts[1]['name'])
                            "
                            title=""
                        >
                            {{ machine.contacts[1]['name'] }}
                        </div>
                        <a
                            *ngIf="
                                machine.contacts[1]['email'] &&
                                machine.contacts[1]['email'] !== 'unknown'
                            "
                            class="contactInfo ellipsis"
                            target="_blank"
                            href="mailto:{{ machine.contacts[1]['email'] }}"
                            (ngChange)="
                                checkWidth($event, machine.contacts[1]['email'])
                            "
                            (mouseover)="
                                checkWidth($event, machine.contacts[1]['email'])
                            "
                            title=""
                        >
                            {{ machine.contacts[1]['email'] }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <main>
        <div
            id="left"
            [class.single]="
                !machine.wikipediaDocument && !machine.otherInfoDocument
            "
        >
            <div
                id="machine"
                [class.singleMachine]="
                    !machine.wikipediaDocument && !machine.otherInfoDocument
                "
            >
                <div class="title" style="padding-bottom: 0">
                    <img src="assets/images/machine-dark.png" />
                </div>

                <div id="machineImg">
                    <div
                        [style.backgroundImage]="
                            machine.imageUrl
                                ? 'url(' + machine.imageUrl + ')'
                                : 'url(assets/images/machine.png)'
                        "
                        [style.backgroundSize]="
                            machine.imageUrl ? 'cover' : 'auto'
                        "
                        class="innerImg"
                    ></div>
                </div>
            </div>

            <div
                id="price"
                [class.singlePrice]="
                    !machine.wikipediaDocument && !machine.otherInfoDocument
                "
            >
                <div class="label">Soon</div>

                <div
                    style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 2px 0 17px 0;
                        opacity: 0.3;
                    "
                >
                    <div class="title price">
                        <img src="assets/images/machine-price-dark.png" />
                        <span> price </span>
                    </div>

                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <div class="dropdownBtn">
                            <span class="dropdownTitle"> Service Model </span>
                            <span
                                class="dropdownArrow"
                                style="
                                    background-image: url('assets/images/dropdwon-arrow.png');
                                "
                            ></span>
                        </div>

                        <div
                            id="calendar"
                            style="
                                background-image: url('assets/images/calendar-dark.png');
                            "
                        ></div>
                    </div>
                </div>

                <div id="priceTable">
                    <div class="priceRow">
                        <div class="cube img" style="width: 50px">
                            <div class="checkbox">
                                <div
                                    *ngIf="false"
                                    style="
                                        background-image: url('assets/images/service-check.png');
                                    "
                                ></div>
                            </div>
                        </div>
                        <div
                            class="cube desc"
                            style="width: calc(100% - 150px)"
                        >
                            Price per single sample test <br />
                            (min 10 samples tests)
                        </div>
                        <div class="cube amount" style="width: 100px">X</div>
                    </div>

                    <div class="priceRow">
                        <div class="cube img" style="width: 50px">
                            <div class="checkbox">
                                <div
                                    *ngIf="true"
                                    style="
                                        background-image: url('assets/images/service-check.png');
                                    "
                                ></div>
                            </div>
                        </div>
                        <div
                            class="cube desc"
                            style="width: calc(100% - 150px)"
                        >
                            Lab technician per 1 hour
                        </div>
                        <div class="cube amount" style="width: 100px">X</div>
                    </div>
                </div>

                <div
                    style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    "
                >
                    <div
                        style="
                            margin-left: 18px;
                            display: flex;
                            align-items: center;
                            opacity: 0.3;
                        "
                    >
                        <div class="checkbox">
                            <div
                                *ngIf="true"
                                style="
                                    background-image: url('assets/images/service-check.png');
                                "
                            ></div>
                        </div>
                        <div class="reportCheck">Formal Report</div>
                    </div>

                    <button class="bookingBtn" (click)="openBookingModal()">
                        BOOK NOW
                    </button>
                </div>
            </div>
        </div>

        <div
            id="right"
            *ngIf="machine.wikipediaDocument || machine.otherInfoDocument"
        >
            <div id="wiki" *ngIf="machine.wikipediaDocument">
                <div class="title wiki">
                    <img src="assets/images/wikipedia-dark.png" />
                    <span> Description </span>
                </div>

                <div
                    style="
                        height: 288px;
                        margin-left: 39px;
                        font-family: 'Roboto Mono', monospace;
                    "
                >
                    {{
                        machine.otherInfoDocument
                            ? trimString(machine.wikipediaDocument.data, 620)
                            : trimString(machine.wikipediaDocument.data, 800)
                    }}
                </div>
            </div>

            <div id="comment" *ngIf="machine.otherInfoDocument">
                <div class="title comment">
                    <img src="assets/images/comments.png" />
                </div>

                <div
                    style="
                        height: 54px;
                        margin: -34px 0 0 39px;
                        font-family: 'Roboto Mono', monospace;
                    "
                >
                    {{
                        machine.wikipediaDocument
                            ? trimString(machine.otherInfoDocument.data, 120)
                            : trimString(machine.otherInfoDocument.data, 820)
                    }}
                </div>
            </div>
        </div>
    </main>

    <footer *ngIf="machine.instanceDocuments.length > 0">
        <div id="tools">
            <div class="title tools">
                <img src="assets/images/specifications-dark.png" />
                <span> Specifications of tool </span>
            </div>

            <div id="toolsTable">
                <div *ngFor="let t of machine.instanceDocuments" class="tool">
                    <div class="toolNumber">
                        <span>
                            {{ machine.instanceDocuments.indexOf(t) + 1 }}
                        </span>
                    </div>
                    <div class="toolName">
                        <span
                            *ngIf="!t.url"
                            class="ellipsis"
                            (ngChange)="checkWidth($event, t.data)"
                            (mouseover)="checkWidth($event, t.data)"
                            title=""
                        >
                            {{ t.data }}
                        </span>
                        <a
                            *ngIf="t.url"
                            href="{{ t.url }}"
                            target="_blank"
                            class="ellipsis"
                            (ngChange)="checkWidth($event, t.data)"
                            (mouseover)="checkWidth($event, t.data)"
                            title=""
                        >
                            {{ t.data }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
