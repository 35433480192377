import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Canvas, CanvasProject, SharedCanvas} from "../pages/canvas/canvas.interface";
import {InnovationProject} from "../api-responses/InnovationProjects.interface";
import {RequestOptions} from "@angular/http";
import {ProjectUpdate} from "../api-responses/SearchQueryResults.interface";

@Injectable({
    providedIn: 'root',
})
export class NetworkServiceCanvas {
    private baseAPI = '/api/v1/canvas';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<Canvas[]>(this.baseAPI).toPromise();
    }

    getById(id: number) {
        return this.http.get<Canvas>(`${this.baseAPI}/${id}`).toPromise();
    }

    create(canvas: Canvas) {
        return this.http.post<Canvas>(`${this.baseAPI}`, canvas).toPromise();
    }

    delete(id: number) {
        return this.http.delete<Canvas>(`${this.baseAPI}/${id}`).toPromise();
    }

    addProject(id: number, canvasProject: CanvasProject) {
        return this.http.post(`${this.baseAPI}/${id}/addProject`, canvasProject).toPromise();
    }

    deleteProject(id: number, innovationProjectId: number) {
        return this.http.delete<Canvas>(`${this.baseAPI}/${id}/deleteProject?projectId=${innovationProjectId}`).toPromise();
    }

    sortProjects(id:number, canvasProjectId:number, ordinal:number) {
        // TODO
    }

    updateTitle(id: number, title: string) {
        return this.http.put(`${this.baseAPI}/${id}/updateCanvas`, { title: title }).toPromise();
    }

    updateDescription(id: number, description: string) {
        return this.http.put(`${this.baseAPI}/${id}/updateCanvas`, { description: description }).toPromise();
    }

    getSharedCanvas(isDev: boolean, canvasId: number) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let root = isDev ? 'canvas_DEV' : 'canvas'
        let protocol = isDev ? 'http' : 'https';
        return this.http.get<SharedCanvas>(`${protocol}://reports.treebute.io/${root}/${canvasId}.json`,
            {
                headers: headers,
                withCredentials: false
            }).toPromise();
    }

    getSharedCanvasProject(isDev: boolean, canvasId: number, projectFile: number) {
        let root = isDev ? 'canvas_DEV' : 'canvas'
        let protocol = isDev ? 'http' : 'https';
        return this.http.get<InnovationProject>(`${protocol}://reports.treebute.io/${root}/${canvasId}/projects/${projectFile}.json`, { withCredentials: false }).toPromise();
    }

    publish(canvasId: number, publish: boolean) {
        return this.http.get(`${this.baseAPI}/${canvasId}/publish?publish=${publish}`).toPromise()
    }

    getUpdates(isDev: boolean, canvasId: number, fromDate: string, toDate: string) {
        // return this.http.get<ProjectUpdate[]>(
        //     `${this.baseAPI}/${canvasId}/getUpdates?fromDate=${fromDate}&toDate=${toDate}`)
        //     .toPromise();

        // get the file
        try {
            return new Promise((res, rej) => {
                let root = isDev ? 'canvas_DEV' : 'canvas'
                return this.http.get<ProjectUpdate[]>(
                    `https://reports.treebute.io/${root}/${canvasId}/canvas_cache_${canvasId}_${fromDate}_${toDate}.json`)
                    .toPromise()
                    .then((result) => {
                        return res(result);
                    })
                    .catch(() => {
                        return res([]);
                    });
            })
        }
        catch (e) {
            return [];
        }
        // if no file exist - return empty array

    }

    processUpdates(canvasId: number, fromDate: string, toDate: string) {
        return this.http.get<ProjectUpdate[]>(
            `${this.baseAPI}/${canvasId}/processUpdates?fromDate=${fromDate}&toDate=${toDate}`)
            .toPromise();
    }
}
