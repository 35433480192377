import {
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffers,
    NgZone,
    OnChanges,
    OnInit,
    Output, SimpleChanges, ViewChild
} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {NetworkService} from "../../../../../network.service";
import {DataService, NavRequest} from "../../../../../data.service";
import {ReadOnlyProjectService} from "../../../../../read-only-project-service";
import {Researcher, SearchDetails} from "../../../../../api-responses/SearchQueryResults.interface";
import {
    CtExpertsModalComponent
} from "../../../../search-page/experts-search/ct-experts-modal/ct-experts-modal.component";
import {ExpertsModalData} from "../../../../../api-responses/Dialogs.interface";
import {ExpertModalComponent} from "../../../../search-page/experts-search/expert-modal/expert-modal.component";
import {InnovationProject} from "../../../../../api-responses/InnovationProjects.interface";
import {UtilitiesService} from "../../../../../utilities.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

interface SelectedCountry {
    country: string,
    selected: boolean
}

@Component({
    selector: 'experts-chunk',
    templateUrl: './experts-chunk.component.html',
    styleUrls: ['./experts-chunk.component.scss'],
})
export class ExpertsChunkComponent implements OnInit, OnChanges {
    @Input() showAction: boolean = false;
    @Input() actionButtonText: string = "Run";
    @Output() actionRun = new EventEmitter();

    @Input() project: InnovationProject;

    @Input() researchers: Researcher[] = [];
    private differ: IterableDiffers;

    @Input() showFromSearch: boolean = true;
    @Input() showStatus: boolean = false;
    researcherLines: MatTableDataSource<Researcher>;

    displayedColumns = ['action', 'name', 'department', 'fromSearch', 'country', 'status'];

    countries: SelectedCountry[] = [];

    @ViewChild(MatPaginator, {static: false}) paginator: any

    constructor(public matDialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                public networkService: NetworkService,
                private ngZone: NgZone,
                public dataService: DataService,
                public readOnlyProjectService: ReadOnlyProjectService,
                private differs: IterableDiffers,
                private cdr: ChangeDetectorRef) {
        this.differ = differs;
    }

    expertClick(researcher: Researcher, hideNav: boolean = false) {
        this._openDialog(researcher, hideNav);
    }

    private _openDialog(expert: Researcher, hideNav: boolean = false) {
        let dialogRef;
        if (expert.fromCtSearch) {
            dialogRef = this.matDialog.open(CtExpertsModalComponent, {
                data: {
                    expert: expert,
                    experts: this.researchers,
                    isGlobal: true,
                    industrySearch: true,
                    searchResults: undefined,
                    simpleNavigation: false,
                    hideNav: hideNav
                } as ExpertsModalData
            });
        } else {
            dialogRef = this.matDialog.open(ExpertModalComponent, {
                data: {
                    expert: expert,
                    experts: this.researchers,
                    isGlobal: true,
                    industrySearch: true,
                    searchResults: undefined,
                    simpleNavigation: false,
                    hideNav: hideNav
                } as ExpertsModalData
            });
        }

        dialogRef && dialogRef.afterClosed()
            .toPromise()
            .then((result) => {
                if (result && result.reopen) {
                    this._openDialog(result.reopen);
                }
            })
    }

    // isCtResearcher(researcher: Researcher) {
    //     // debugger
    //     // let flat = researcher.searchDetails2.map(x => x.searchId).reduce((acc, val) => acc.concat(val), []);
    //     // let ctSearches = new Set(
    //     //     project.treebuteSearchResults
    //     //         .filter(x => x.searchType === "CT_EXPERTS")
    //     //         .map(x => x.id)
    //     //         .reduce((acc, val) => acc.concat(val), []));
    //     // let size = ctSearches.size;
    //     // flat.forEach(x => ctSearches.add(x));
    //     // // if research appeared ONLY in ct searches
    //     // return size == ctSearches.size;
    // }

    getCountry(researcher: Researcher): string {
        return UtilitiesService.getCountry(researcher).trim();
    }

    getGoogleSearchUrl(researcher: Researcher) {
        let country = this.getCountry(researcher);
        country = country.toLowerCase() === 'undefined' ? '' : country;
        return UtilitiesService.getGoogleSearchUrl(`${researcher.department} ${country}`.trim());
    }

    getSearchName(searchDetails: SearchDetails) {
        return `#${searchDetails.searchId}`;
    }

    getSearchLink(searchDetails: SearchDetails) {
        if (!this.project) return;
        if (this.readOnlyProjectService.isReadOnlyProject) return '#';
        return `/app/projects/${this.project.id}/search/${searchDetails.searchId}`;
    }

    getFirstSearchLink(searchDetails2: SearchDetails[]) {
        return this.getSearchLink(searchDetails2[0]);
    }

    navToExpertsTool() {
        this.dataService.saveSearchUpdate(new NavRequest(`/app/projects/${this.project.id}/experts-tool`, NavRequest.MSG.NAV));
    }

    ngOnInit(): void {
        this.researcherLines = new MatTableDataSource<Researcher>(this.researchers);
        this.researcherLines.paginator = this.paginator;
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.researchers.currentValue && this.researchers.length > 0) {
            this.researcherLines = new MatTableDataSource<Researcher>(this.researchers);
            this.researcherLines.paginator = this.paginator;
            this.cdr.detectChanges();
        }

        // if (changes.researchers && changes.researchers.currentValue && changes.researchers.currentValue.length > 0) {
        //     this.researcherLines = new MatTableDataSource<Researcher>(this.researchers);
        //
        //     let arr = Array.from(new Set(this.researchers.map(r => this.getCountry(r))));
        //     this.countries = arr.map(c => {
        //         return {country: c, selected: true} as SelectedCountry
        //     });
        // }
    }

    getStatus(element: Researcher) {
        return element.status;
    }

    toggleAll($event: Event) {
        console.log($event);
    }

    toggleCountry($event: Event) {
        console.log($event);
    }

}
