import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {NetworkService} from "../../../../network.service";
import {
    Evidence,
    Institution,
    Researcher,
    Resource,
    SearchDetails,
    SearchQueryResults
} from "../../../../api-responses/SearchQueryResults.interface";
import {Chart} from "chart.js";
import 'chartjs-plugin-labels';
import {DataService, NavRequest} from "../../../../data.service";
import {take} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {InnovationProject} from "../../../../api-responses/InnovationProjects.interface";
import {MatDialog} from "@angular/material/dialog";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {ReadOnlyProjectService} from "../../../../read-only-project-service";
import {ExpertsChunkComponent} from "./experts/experts-chunk.component";

@Component({
    selector: 'evidence-chunk',
    templateUrl: './evidence-chunk.component.html',
    styleUrls: ['./evidence-chunk.component.scss'],
})
export class EvidenceChunkComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    @Input() showAction: boolean = false;
    @Input() actionButtonText: string = "Run";
    @Output() actionRun = new EventEmitter();

    hideChart: boolean = true;
    @Input() evidenceColor: string = '';
    @Input() evidenceHeader: string = 'Date';


    @Input() title: string;
    @Input() institutions: Institution[];
    @Input() researchers: Researcher[];
    @Input() evidenceList: Resource[];

    @Input() preview: boolean = true;
    @Input() isCt: boolean = false;

    @Input() project: InnovationProject;

    @Input() showList: boolean = true;
    @Input() showChart: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() showSubTitles: boolean = true;

    // canvasId:string = Math.floor(Math.random() * (1000)).toString();
    @ViewChild('canvas') canvasElementRef: ElementRef<HTMLCanvasElement>;
    chart: Chart;
    chartOptions: Chart.ChartConfiguration = {
        type: 'bar',
        plugins: [ChartDataLabels],
        options: {
            layout: {
                padding: {
                    top: 30
                }
            },
            elements: {
                rectangle: {
                    backgroundColor: this.evidenceColor
                }
            },
            plugins: {
                // Change options for ALL labels of THIS CHART
                datalabels: {
                    color: '#dff5ff',
                    anchor: 'end',
                    align: 'start',
                    offset: -25
                },
                labels: {
                    display: false,
                    render: ()=> {
                        return '';
                    }
                }
            },
            legend: {
                display: false,
                onClick: (e) => e.stopPropagation()
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    display: true,
                    ticks: {
                        display: true,
                        fontColor: '#fff'
                    }
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                        display: true,
                        max: 1000
                    }
                }]
            }
        },

        data: {
            datasets: null
        },
    };
    private projectId: string;

    @ViewChild('expertsChunk', {static: false}) expertsChunk: ExpertsChunkComponent;

    constructor(public matDialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                public networkService: NetworkService,
                private ngZone: NgZone,
                public dataService: DataService,
                public readOnlyProjectService: ReadOnlyProjectService) {
        Chart.plugins.register(ChartDataLabels)
    }

    inputData;
    @Input() set dataForChart(
        inputData: Chart.ChartDataSets & {
            datasets: any,
            labels: any
        },
    ) {
        this.inputData = inputData;
    }

    drawChart() {
        // This will update the chart in a nice way if it was rendered before
        if (!this.inputData || !this.inputData.datasets || !this.inputData.labels) return;

        let _clean = this.removeEmptyEdges(this.inputData);
        if (_clean.labels.length === 0) {
            return;
        }
        this.hideChart = false;
        this.chartOptions.data = _clean as any;
        this.chartOptions.data.datasets[0].label = this.title;
        this.chartOptions.options.elements.rectangle.backgroundColor = this.evidenceColor;
        //
        // let values = this.chartOptions.data.datasets[0].data.reduce((a,b) => {
        //     return a > b ? a : b
        // }, 0);
        //

        // @ts-ignore
        let max = Math.max(...this.chartOptions.data.datasets[0].data);
        this.chartOptions.options.scales.yAxes[0].ticks.max = max + 1;

        let canvasRef = this.canvasElementRef.nativeElement;
        this.chart = this.ngZone.runOutsideAngular(() => {
            const context =
                this.canvasElementRef.nativeElement.getContext('2d');
            let result = new Chart(context, this.chartOptions);

            setTimeout(() => {
                canvasRef.height = 400;
                canvasRef.style.height = '400px';
            }, 0);

            return result;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.evidenceList && changes.evidenceList.currentValue) {
            if (changes && changes.evidenceList && changes.evidenceList.currentValue) {
                if (changes.evidenceList.currentValue.length < 10) {
                    this.showChart = false;
                }
            }
            // if (this.preview) this.evidenceList = this.evidenceList.slice(0, 10);
            //if (this.evidenceList.length < 10) hideChart = false;
        }
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.pipe(take(1)).subscribe((map) => {
            this.projectId = map.get('projectId');
        });
        this.evidenceHeader = this.title === "Derived Patents" ? ' ' : 'Date';
    }

    private removeEmptyEdges(inputData: Chart.ChartDataSets & { datasets: any; labels: any }) {
        let result = this._removeEmptyEdges(inputData);
        result.datasets[0].data.reverse();
        result.labels.reverse();
        result = this._removeEmptyEdges(result);
        result.datasets[0].data.reverse();
        result.labels.reverse();
        return result;
    }

    private _removeEmptyEdges(inputData: Chart.ChartDataSets & { datasets: any; labels: any }) {
        let result = {
            datasets: [
                {data: []}
            ],
            labels: []
        }

        let foundFirstData = false;
        for (let i = 0; i < inputData.labels.length; i++) {
            if (!foundFirstData && inputData.datasets[0].data[i] === 0) continue;
            foundFirstData = true;
            result.datasets[0].data.push(inputData.datasets[0].data[i]);
            result.labels.push(inputData.labels[i]);
        }

        return result;
    }

    getEvidencePrefix(valueD: string) {
        return valueD ? `// ${valueD} ` : '';
    }

    // getEvidence(): Resource[] {
    //
    // }

    getInstitutions() {
        if (this.preview) return this.institutions; //.slice(0, 10);
        return this.institutions;
    }

    navToCtTool() {
        this.dataService.saveSearchUpdate(new NavRequest(`/app/projects/${this.projectId}/ct-tool`, NavRequest.MSG.NAV));
    }

    ngAfterViewInit(): void {
        this.drawChart();
    }

    expertClick(expert: Researcher, hideNav: boolean = false) {
        this.expertsChunk.expertClick(expert, hideNav)
    }
}
