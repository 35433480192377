import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NetworkService } from '../../network.service';

@Component({
    selector: 'app-user-dashboard',
    templateUrl: './user-dashboard.component.html',
    styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {
    form;
    additionalUrls = [];
    finished = false;
    error: string = null;
    resources: any = null;
    submitting = false;

    visualElements = [
        'Articles',
        'Experts',
        'Clinical Trials',
        'Patents',
        'Startups',
        'Corporates',
    ];

    constructor(
        public dataService: DataService,
        private networkService: NetworkService,
    ) {}

    ngOnInit() {
        this.form = new UntypedFormGroup({
            title: new UntypedFormControl(null, Validators.required),
            scope: new UntypedFormControl(null, Validators.required),
            geographyText: new UntypedFormControl(null, Validators.required),
            visualElements: new UntypedFormGroup({}, Validators.required),
            refreshRate: new UntypedFormControl(null, Validators.required),
            other: new UntypedFormControl(),
        });

        const checkboxes = <UntypedFormGroup>this.form.get('visualElements');
        this.visualElements.forEach((option: any) => {
            checkboxes.addControl(option, new UntypedFormControl(false));
        });
    }

    loaded() {
        return this.dataService.userData != null;
    }

    getRemainingDashboards() {
        let remainingDashboards = this.dataService.userData.remainingDashboards;
        if (remainingDashboards == null) {
            remainingDashboards = 0;
        }
        return remainingDashboards;
    }

    addAdditionalUrl(urlInput) {
        if (urlInput.value && urlInput.value.length > 0) {
            this.additionalUrls.push(urlInput.value);
        }
    }

    removeAdditionalUrl(i) {
        this.additionalUrls.splice(i, 1);
    }

    async onSubmit() {
        this.error = null;
        const request = this.prepareRequest();
        const scope = this.getSelectedScope();
        this.submitting = true;
        this.networkService.requestUserDashboard(request, scope).subscribe(
            async (data) => {
                this.finished = true;
                this.submitting = false;
                this.resources = data;
                const result = this.dataService.getUserInfo();
                return result
                    .then((x) => {
                        this.dataService.userData = x;
                    })
                    .catch((error) => console.log(error));
            },
            (error) => {
                this.error = error.error;
                this.submitting = false;
            },
        );
    }

    prepareRequest() {
        const allVisualElements = Object.keys(this.form.value.visualElements);
        const visualElements = allVisualElements.filter(
            (key) => !!this.form.value.visualElements[key] === true,
        );
        const request = {
            title: this.form.value.title,
            scopeOfWork: this.form.value.scope,
            geographyText: this.form.value.geographyText,
            visualElements: visualElements,
            refreshRate: this.form.value.refreshRate,
            other: this.form.value.other,
            urls: this.additionalUrls,
        };
        return request;
    }

    getSelectedScope() {
        const scopes = [
            'Articles',
            'Clinical Trials',
            'Experts',
            'Startups',
            'Patents',
        ]; /* ordered by importance */
        for (const scope of scopes) {
            if (!!this.form.value.visualElements[scope] === true) {
                return scope;
            }
        }
        return null;
    }
}
