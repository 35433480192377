<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="close()"
        ></div>
        <div class="header-title">{{ data.dialogTitle }}</div>
        <div class="header-subtitle">
            <div class="line">
                {{ data.dialogDescription }}
            </div>
        </div>
    </div>
    <div class="footer">
        <div *ngIf="data.buttons === 1" class="buttons-single" [class.error-button]="data.error">
            <div class="button-save" (click)="yes()">
                {{ getYesText() }}
            </div>
        </div>

        <div *ngIf="data.buttons === 2" class="buttons">
            <div class="button-cancel" (click)="no()">
                {{ getNoText() }}
            </div>
            <div class="button-save" (click)="yes()">
                {{ getYesText() }}
            </div>
        </div>

        <div *ngIf="data.buttons === 3" class="buttons">
            <div class="button-cancel" (click)="cancel()">
                {{ getCancelText() }}
            </div>
            <div class="button-cancel" (click)="no()">
                {{ getNoText() }}
            </div>
            <div class="button-save" (click)="yes()">
                {{ getYesText() }}
            </div>
        </div>


    </div>
</div>
