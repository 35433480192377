<div>
    <search-title-and-summary
            [(searchResults)]="searchResults"
            class="search-file-title-container"
    >
    </search-title-and-summary>
    <div class="saved-search-buttons">
        <div>
            <button class="btn btn-dark text-light border btn-fix px-3 mr-2"  (click)="this.exportData.emit($event)">
                <div class="export-icon mr-3"></div>
                <div>Export Displayed Data</div>
            </button>
            <button class="btn btn-dark text-light border btn-fix px-3 mr-2"  (click)="this.saveSearch($event)"
                    [hidden]="isFreeSearch() || dataService.hideShared()">
                <div class="save-icon mr-3"></div>
                <div>Save</div>
            </button>
            <button class="btn btn-dark text-light border px-3"  (click)="this.deleteSearchConfirm(this.searchResults.id)"
                    [hidden]="isFreeSearch() || dataService.hideShared()">
                <div class="delete-icon"></div>
            </button>
        </div>
    </div>
</div>
