<div class="row">
    <request-card class="col-4"
                  [readonly]="readonly"
                  [(value)]="advise"
                  (valueChange)="adviseChange.emit($event)"
                  [icon]="'frame_person'"
                  [header]="'Advisor'"
                  [body]="'Looking for professional advisory. You are looking for new knowledge or affirmation of current known facts'"
                  [footer]="'Professional Adviser'"
    ></request-card>

    <request-card class="col-4"
                  [readonly]="readonly"
                  [(value)]="project"
                  (valueChange)="projectChange.emit($event)"
                  [icon]="'construction'"
                  [header]="'Execution'"
                  [body]="'Looking for professional assistance or filling a role in a current endeavor you are running, or planning to run'"
                  [footer]="'Execute a Project'"
    ></request-card>

    <request-card class="col-4"
                  [readonly]="readonly"
                  [(value)]="data"
                  (valueChange)="dataChange.emit($event)"
                  [icon]="'database'"
                  [header]="'Dataset'"
                  [body]="'Looking for dataset(s) to enable your goals, and require professional assistance in creating or obtaining data'"
                  [footer]="'Get Data'"
    ></request-card>
</div>
