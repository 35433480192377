import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatTableModule } from '@angular/material/table';

import { SearchPageComponent } from '../pages/search-page/search-page.component';
import { SearchBarComponent } from '../pages/search-page/search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartComponent } from '../components/chart/chart.component';
import { CompaniesSearchComponent } from '../pages/search-page/companies-search/companies-search.component';
import { MapComponent } from '../components/map/map.component';
import { FeedbackWindowComponent } from '../components/feedback-window/feedback-window.component';
import { CompanyModalComponent } from '../pages/search-page/companies-search/company-modal/company-modal.component';
import {BasicSearchToolComponent} from "../pages/search-page/search-bar/basicSearchTool/basic-search-tool.component";
import {ExpertsSearchToolComponent} from "../pages/search-page/search-bar/expertsSearchTool/experts-search-tool.component";
import {SearchFilterCountry} from "../pages/search-page/search-clientside-tools/search-filter-country/search-filter-country.component";
import {SearchTilesClientsideToolsComponent} from "../pages/search-page/search-clientside-tools/search-tiles-clientside-tools/search-tiles-clientside-tools.component";
import {SearchExpertsClientsideToolsComponent} from "../pages/search-page/search-clientside-tools/search-experts-clientside-tools/search-experts-clientside-tools.component";
import {ExpertsComponent} from "../pages/search-page/experts-search/experts.component";
import {ExpertsCtComponent} from "../pages/experts-ct/experts-ct.component";
import {ExpertCardComponent} from "../components/experts/expert-card/expert-card.component";
import {ExpertDataComponent} from "../components/experts/expert-data/expert-data.component";
import {ExpertDetailsComponent} from "../components/experts/expert-details/expert-details.component";
import {GrantsListComponent} from "../components/experts/grants-list/grants-list.component";
import {PatentsListComponent} from "../components/experts/patents-list/patents-list.component";
import {PublicationsListComponent} from "../components/experts/publications-list/publications-list.component";

import {TileSearchComponent} from "../pages/search-page/tile-search/tile-search.component";
import {CampusMapD3Component} from "../pages/campus-map-d3/campus-map-d3.component";
import {WorldMapComponent} from "../pages/campus-map-d3/world-map/world-map.component";
import {SearchCampusMapComponent} from "../pages/campus-map-d3/search-campus-map/search-campus-map.component";
import {TilesSearchToolComponent} from "../pages/search-page/search-bar/tilesSearchTool/tiles-search-tool.component";
import {WordCloudComponent} from "../components/word-cloud/word-cloud.component";
import {ExpertModalComponent} from "../pages/search-page/experts-search/expert-modal/expert-modal.component";
import {SearchTitleAndSummaryComponent} from "../pages/search-page/search-clientside-tools/search-title-and-summary/search-title-and-summary.component";
import {TileSearchBreadcrumbsComponent} from "../pages/search-page/tile-search/breadcrumbs/tile-search-breadcrumbs.component";
import {TuneSearchComponent} from "../pages/search-page/search-bar/tune-search/tune-search.component";
import {CtExpertsModalComponent} from "../pages/search-page/experts-search/ct-experts-modal/ct-experts-modal.component";
import {ExcludeIncludeComponent} from "../pages/search-page/common/exclude-include/exclude-include.component";
import {CtExpertCardComponent} from "../components/experts/ct-expert-card/ct-expert-card.component";
import {CtExpertsComponent} from "../pages/search-page/experts-search/ct-experts-component/ct-experts.component";
import {EditableTextComponent} from "../pages/search-page/search-clientside-tools/search-title-and-summary/editable-text/editable-text.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {DeleteSearchModalComponent} from "../pages/search-page/dialogs/delete-search-modal/delete-search-modal.component";
import {NewSearchModalComponent} from "../pages/search-page/dialogs/new-search-modal/new-search-modal.component";
import {YesNoModalComponent} from "../pages/dialogs/yesNo/yes-no-modal.component";
import {RouterModule} from "@angular/router";
import {AddCompanyModalComponent} from "../pages/search-page/dialogs/add-company-modal/add-company-modal.component";
import {FoundersListComponent} from "../pages/search-page/common/founders-list/founders-list.component";
import {EvidenceChunkComponent} from "../pages/projects-page/project-page/evidenceChunk/evidence-chunk.component";
import {CreateNewCompanyComponent} from "../pages/companies-page/create-new-company/create-new-company.component";
import {SelectModalComponent} from "../pages/dialogs/select/select-modal.component";
import {FeedbackModal} from "../pages/search-page/dialogs/feedback-modal/feedback-modal.component";
import { StartupPreviewComponent } from '../pages/projects-page/project-page/startup-preview/startup-preview.component';
import {SearchChipComponent} from "../pages/projects-page/project-page/search-chip/search-chip.component";
import {
    SearchFiltersComponent
} from "../pages/search-page/search-clientside-tools/search-filters/search-filters.component";
import { SearchFileToolsComponent } from '../pages/search-page/search-clientside-tools/search-file-tools/search-file-tools.component';
import { SearchToolsComponent } from '../pages/search-page/search-clientside-tools/search-tools/search-tools.component';
import { SearchFilterResearcherComponent } from '../pages/search-page/search-clientside-tools/search-filter-researcher/search-filter-researcher.component';

import {AccountOptionsComponent} from "../components/navbar/account-options/account-options.component";
import {ContactComponent} from "../components/navbar/account-options/contact/contact.component";
import {GeoChartComponent} from "../pages/projects-page/tools/common-tools/geo-chart/geo-chart.component";
import { SearchFilterResearcherCtComponent } from '../pages/search-page/search-clientside-tools/search-filter-researcher-ct/search-filter-researcher-ct.component';
import {
    GeoChartContainerComponent
} from "../pages/projects-page/tools/common-tools/geo-chart/geo-chart-container/geo-chart-container.component";
import {
    ChartFilterModalComponent
} from "../pages/projects-page/tools/common-tools/chart-filter-modal/chart-filter-modal.component";
import {
    ExpertsBackgroundChartComponent
} from "../pages/projects-page/tools/common-tools/expert-background-chart/experts-background-chart.component";
import {
    ExpertsBackgroundChartContainerComponent
} from "../pages/projects-page/tools/common-tools/expert-background-chart/experts-summary-chart-container/experts-background-chart-container.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatListModule} from "@angular/material/list";
import {SearchDetailsModalComponent} from "../pages/dialogs/search-details-modal/search-details-modal.component";
import {ExpertsChunkComponent} from "../pages/projects-page/project-page/evidenceChunk/experts/experts-chunk.component";
import {LightComponent} from "../components/lights/light.component";
import {CandidateStatusComponent} from "../pages/callout/common/candidate-status/candidate-status.component";
import {ShareModalComponent} from "../pages/dialogs/share/share-modal.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatPaginatorModule} from "@angular/material/paginator";
import {
    EvidenceTableComponent
} from "../pages/projects-page/project-page/evidenceChunk/external-resources/evidence-table.component";
import {
    CanvasProjectSelectModalComponent
} from "../pages/canvas/canvas-project-select-modal/canvas-project-select-modal.component";

const exports = [
    FoundersListComponent,
    SearchPageComponent,
    SearchBarComponent,
    CompaniesSearchComponent,
    WordCloudComponent,
    FeedbackWindowComponent,
    ChartComponent,
    CompanyModalComponent,
    ExpertModalComponent,
    CtExpertsModalComponent,
    MapComponent,
    ExpertsComponent,
    ExpertsCtComponent,
    ExpertCardComponent,
    ExpertDataComponent,
    ExpertDetailsComponent,
    GrantsListComponent,
    PatentsListComponent,
    PublicationsListComponent,
    CampusMapD3Component,
    WorldMapComponent,
    SearchCampusMapComponent,
    SearchTitleAndSummaryComponent,
    TileSearchBreadcrumbsComponent,
    TuneSearchComponent,
    ExcludeIncludeComponent,
    CtExpertCardComponent,
    CtExpertsComponent,
    EditableTextComponent,
    DeleteSearchModalComponent,
    NewSearchModalComponent,
    FeedbackModal,
    YesNoModalComponent,
    ShareModalComponent,
    CanvasProjectSelectModalComponent,
    SearchDetailsModalComponent,
    SelectModalComponent,
    EvidenceChunkComponent,
    ExpertsChunkComponent,
    EvidenceTableComponent,
    CandidateStatusComponent,
    CreateNewCompanyComponent,
    SearchChipComponent,
    SearchFiltersComponent,
    AccountOptionsComponent,
    ContactComponent,
    GeoChartComponent,
    ChartFilterModalComponent,
    ExpertsBackgroundChartComponent,
    ExpertsBackgroundChartContainerComponent,
    LightComponent
];

@NgModule({
    declarations: [...exports,
        TilesSearchToolComponent,
        BasicSearchToolComponent,
        ExpertsSearchToolComponent,
        SearchFilterCountry, AddCompanyModalComponent,
        SearchTilesClientsideToolsComponent,
        SearchExpertsClientsideToolsComponent,
        TileSearchComponent,
        StartupPreviewComponent,
        SearchFileToolsComponent,
        SearchToolsComponent,
        SearchFilterResearcherComponent,
        GeoChartComponent,
        SearchFilterResearcherCtComponent,
        GeoChartContainerComponent,

    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatRadioModule,
        TextFieldModule,
        MatSnackBarModule,
        MatProgressBarModule,
        RouterModule,
        MatTooltipModule,
        MatListModule,
        MatMenuModule,
        MatTableModule,
        MatSlideToggleModule,
        MatPaginatorModule
    ],
    exports: [
        ...exports,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatMenuModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatRadioModule,
        TextFieldModule,
        MatSnackBarModule,
        BasicSearchToolComponent,
        StartupPreviewComponent,
        SearchChipComponent,
        GeoChartContainerComponent,
        LightComponent
    ],
    providers: []
})
export class SharedModule {}
