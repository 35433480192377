<!--<div>Welcome To Treebute!</div>-->
<!--<div>Let's Login</div>-->
<!--<input type="text" id="username" [(ngModel)]="username" />-->
<!--<input type="text" id="password" [(ngModel)]="password" />-->
<!--<input type="button" value="Login" (click)="login()" />-->
<!--<span>-->
<!--    {{ this.error_message }}-->
<!--</span>-->
<!--<input type="button" (click)="testAPI()" value="test API" />-->

<div class="login-container">
    <section id="content2">
        <form id="xform" class="user-site signInForm">
            <h1>Treebute</h1>
            <div>
                <input type="text" id="username" placeholder="Enter your email" name="username" [(ngModel)]="username">
            </div>
            <div>
                <input type="password" id="password" placeholder="Enter your password" name="password" [(ngModel)]="password">
            </div>
            <p class="inputError">
                {{ this.error_message }}
            </p>
            <div id="control">
                <div>
                    <label for="remember" style="margin: 0; display: flex; align-items: center;">
                        <input id="remember" name="remember-me" type="checkbox" checked="on" style="margin: 0; margin-right: 3px;"/>
                        <span>Remember me</span>
                    </label>
                    <a href="/app/register" style="margin: 0;">Register</a>
                    <a href="/forgot-password" style="margin: 0;">Forgot your password?</a>
                </div>

                <input id="signInButton" type="button" value="Sign in" (click)="login()"/>
            </div>

        </form>
    </section><!-- content -->
    <div id="versionInfo" style="margin: 0;">Version 2.0 Beta-{{ dataService.version }}</div>
</div><!-- container -->

<style>
    .login-container {
        background: #DCDDDF url('/assets/images/Treebute_Hero.jpg');
        background-repeat: no-repeat;
        background-position: center;
        color: #000;
        font: 14px Arial;
        margin: 0 auto;
        padding: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
    }
    h1{ font-size:28px;}
    h2{ font-size:26px;}
    h3{ font-size:18px;}
    h4{ font-size:16px;}
    h5{ font-size:14px;}
    h6{ font-size:12px;}
    h1,h2,h3,h4,h5,h6{ color:#563D64;}
    small{ font-size:10px;}
    b, strong{ font-weight:bold;}
    a{ text-decoration: none; }
    a:hover{ text-decoration: underline; }
    .left { float:left; }
    .right { float:right; }
    .alignleft { float: left; margin-right: 15px; }
    .alignright { float: right; margin-left: 15px; }
    .clearfix:after,
    #xform:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }

    #content2 {
        background: #f9f9f9;
        background: -moz-linear-gradient(top,  rgba(248,248,248,1) 0%, rgba(249,249,249,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        background: -o-linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        background: -ms-linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        background: linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
        -webkit-box-shadow: 0 1px 0 #fff inset;
        -moz-box-shadow: 0 1px 0 #fff inset;
        -ms-box-shadow: 0 1px 0 #fff inset;
        -o-box-shadow: 0 1px 0 #fff inset;
        box-shadow: 0 1px 0 #fff inset;
        border: 1px solid #c4c6ca;
        margin: 0 auto;
        padding: 25px 0 10px;
        position: absolute;
        text-align: center;
        text-shadow: 0 1px 0 #fff;
        width: 400px;
        left: calc(50vw - 200px);
        top: 100px;

    }
    #content2 h1 {
        color: #7E7E7E;
        font: bold 25px Helvetica, Arial, sans-serif;
        letter-spacing: -0.05em;
        line-height: 20px;
        margin: 10px 0 30px;
    }
    #content2 h1:before,
    #content2 h1:after {
        content: "";
        height: 1px;
        position: absolute;
        top: 10px;
        width: 34%;
    }
    #content2 h1:after {
        background: rgb(126,126,126);
        background: -moz-linear-gradient(left,  rgba(126,126,126,1) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        background: -o-linear-gradient(left,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        background: -ms-linear-gradient(left,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        background: linear-gradient(left,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        right: 0;
    }
    #content2 h1:before {
        background: rgb(126,126,126);
        background: -moz-linear-gradient(right,  rgba(126,126,126,1) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(right,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        background: -o-linear-gradient(right,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        background: -ms-linear-gradient(right,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        background: linear-gradient(right,  rgba(126,126,126,1) 0%,rgba(255,255,255,1) 100%);
        left: 0;
    }
    #content2:after,
    #content2:before {
        background: #f9f9f9;
        background: -moz-linear-gradient(top,  rgba(248,248,248,1) 0%, rgba(249,249,249,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        background: -o-linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        background: -ms-linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        background: linear-gradient(top,  rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
        border: 1px solid #c4c6ca;
        content: "";
        display: block;
        height: 100%;
        left: -1px;
        position: absolute;
        width: 100%;
    }
    #content2:after {
        -webkit-transform: rotate(2deg);
        -moz-transform: rotate(2deg);
        -ms-transform: rotate(2deg);
        -o-transform: rotate(2deg);
        transform: rotate(2deg);
        top: 0;
        z-index: -1;
    }
    #content2:before {
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        transform: rotate(-3deg);
        top: 0;
        z-index: -2;
    }
    #content2 #xform { margin: 0 20px; position: relative }
    #content2 #xform input[type="text"],
    #content2 #xform input[type="password"] {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        -webkit-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0,0,0,0.08) inset;
        -moz-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0,0,0,0.08) inset;
        -ms-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0,0,0,0.08) inset;
        -o-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0,0,0,0.08) inset;
        box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0,0,0,0.08) inset;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        border: 1px solid #c8c8c8;
        color: #777;
        font: 13px Helvetica, Arial, sans-serif;
        margin: 0 0 10px;
        padding: 15px 10px 15px 30px;
        width: 80%;
    }
    #content2 #xform input[type="text"]:focus,
    #content2 #xform input[type="password"]:focus {
        -webkit-box-shadow: 0 0 2px #ed1c24 inset;
        -moz-box-shadow: 0 0 2px #ed1c24 inset;
        -ms-box-shadow: 0 0 2px #ed1c24 inset;
        -o-box-shadow: 0 0 2px #ed1c24 inset;
        box-shadow: 0 0 2px #ed1c24 inset;
        background-color: #fff;
        border: 1px solid #ed1c24;
        outline: none;
    }
    #username { background-position: 10px 10px !important }
    #password { background-position: 10px -53px !important }
    #content2 #xform input[type="button"] {
        background: rgb(254,231,154);
        background: -moz-linear-gradient(top,  rgba(254,231,154,1) 0%, rgba(254,193,81,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(254,231,154,1) 0%,rgba(254,193,81,1) 100%);
        background: -o-linear-gradient(top,  rgba(254,231,154,1) 0%,rgba(254,193,81,1) 100%);
        background: -ms-linear-gradient(top,  rgba(254,231,154,1) 0%,rgba(254,193,81,1) 100%);
        background: linear-gradient(top,  rgba(254,231,154,1) 0%,rgba(254,193,81,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fee79a', endColorstr='#fec151',GradientType=0 );
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px;
        -webkit-box-shadow: 0 1px 0 rgba(255,255,255,0.8) inset;
        -moz-box-shadow: 0 1px 0 rgba(255,255,255,0.8) inset;
        -ms-box-shadow: 0 1px 0 rgba(255,255,255,0.8) inset;
        -o-box-shadow: 0 1px 0 rgba(255,255,255,0.8) inset;
        box-shadow: 0 1px 0 rgba(255,255,255,0.8) inset;
        border: 1px solid #D69E31;
        color: #85592e;
        cursor: pointer;
        float: left;
        font: bold 15px Helvetica, Arial, sans-serif;
        height: 35px;
        position: relative;
        text-shadow: 0 1px 0 rgba(255,255,255,0.5);
        width: 120px;
    }
    #content2 #xform input[type="button"]:hover {
        background: rgb(254,193,81);
        background: -moz-linear-gradient(top,  rgba(254,193,81,1) 0%, rgba(254,231,154,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(254,193,81,1) 0%,rgba(254,231,154,1) 100%);
        background: -o-linear-gradient(top,  rgba(254,193,81,1) 0%,rgba(254,231,154,1) 100%);
        background: -ms-linear-gradient(top,  rgba(254,193,81,1) 0%,rgba(254,231,154,1) 100%);
        background: linear-gradient(top,  rgba(254,193,81,1) 0%,rgba(254,231,154,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fec151', endColorstr='#fee79a',GradientType=0 );
    }
    #content2 #xform div a {
        color: #004a80;
        float: right;
        font-size: 12px;
        margin: 30px 15px 0 0;
        text-decoration: underline;
    }

    #control {
        height: 75px;
        padding: 10px 35px 15px;
        display: flex;
        align-items: center;
    }

    #control div {
        width: calc(100% - 120px);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    @media only screen and (max-width: 600px) {
        body {
            background-position: right top;
        }
    }

    @media only screen and (max-width: 450px) {
        .container {
            width: 85vw
        }
        #control {
            padding: 10px 10px 30px;
        }
        #content2 h1:after, #content2 h1:before {
            width: 31%;
        }
    }

    #versionInfo {
        position: fixed;
        bottom: 80px;
        left: 30px;
        font: 13px Helvetica, Arial, sans-serif;
    }

</style>
