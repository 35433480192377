<div style="display: flex; flex-direction: column">
    <h3>Search Synonym:</h3>
    <form (ngSubmit)="onSearchSubmit()" style="margin-bottom: 20px">
        <div class="form-group">
            <textarea
                [(ngModel)]="query"
                name="query"
                class="form-control"
                rows="1"
                id="synonymText"
                placeholder="Synonym"
            ></textarea>
        </div>
        <button class="btn btn-primary">Search</button>
        <button (click)="createSynonym()" class="btn btn-primary">
            Create Synonym
        </button>
        <h3 style="color: beige">{{ message }}</h3>
    </form>

    <div class="col-8">
        <div *ngFor="let synonym of synonyms">
            <button
                type="button"
                (click)="openSynonymDetails(synonym.dbId)"
                class="btn btn-primary btn-sm"
            >
                {{ synonym.dbId }}, {{ synonym.text }}
            </button>
        </div>
    </div>

    <div
        style="display: flex; justify-content: space-between; margin-left: 20px"
    >
        <app-synonyms-editor
            *ngIf="show"
            [id]="selectedId"
        ></app-synonyms-editor>
    </div>
</div>
