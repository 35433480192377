<div *ngIf="selectedCompany" id="company-modal">
    <div class="detailsContainer">
        <button
            *ngIf="vw() > 930"
            style="
                background-image: url('../../../../../assets/images/experts-next.png');
            "
            id="nextCompany"
            (click)="nextCompany()"
            [disabled]="!enableNextCompany"
        ></button>
        <button
            *ngIf="vw() > 930"
            style="
                background-image: url('../../../../../assets/images/experts-next.png');
            "
            id="previousCompany"
            (click)="previousCompany()"
            [disabled]="!enablePreviousCompany"
        ></button>

        <div class="header">
            <img
                src="../../../../../assets/images/close-dark.png"
                class="closeIcon"
                (click)="closeModal()"
            />

            <div
                class="cardName ellipsis"
                [style.color]="
                    selectedCompany.highProbability ? '#008ded' : '#e85a4e'
                "
                title=""
                (ngChange)="checkWidth($event, selectedCompany.fullName)"
                (mouseover)="checkWidth($event, selectedCompany.fullName)"
            >
                {{ selectedCompany.fullName }}
                <span
                    class="country"
                    *ngIf="
                        selectedCompany.countries &&
                        selectedCompany.countries.length == 1 &&
                        selectedCompany.countries[0] != null &&
                        selectedCompany.countries[0].toLowerCase() !== 'unknown'
                    "
                    >{{ selectedCompany.countries[0] }}</span
                >
            </div>
            <iframe
                *ngIf="selectedCompany.videoURL"
                [src]="selectedCompany.safeURL"
                class="video"
                frameborder="0"
            >
            </iframe>
            <div
                *ngIf="selectedCompany.description"
                class="description"
                title=""
                (ngChange)="checkWidth($event, selectedCompany.fullName)"
                (mouseover)="checkWidth($event, selectedCompany.fullName)"
            >
                {{ selectedCompany.description }}
            </div>

            <founders-list [selectedCompany]="selectedCompany"></founders-list>

            <div
                *ngIf="selectedCompany.foundingYear"
                class="founders ellipsis"
                [style.color]="'#008ded'"
                title=""
                (ngChange)="checkWidth($event, selectedCompany.fullName)"
                (mouseover)="checkWidth($event, selectedCompany.fullName)"
            >
                Founding year : {{ selectedCompany.foundingYear }}
            </div>
            <div
                *ngIf="selectedCompany.emp"
                class="founders ellipsis"
                [style.color]="'#008ded'"
                title=""
                (ngChange)="checkWidth($event, selectedCompany.fullName)"
                (mouseover)="checkWidth($event, selectedCompany.fullName)"
            >
                Number of employees:{{
                    getNumberOfEmployees(selectedCompany.emp)
                }}
            </div>
            <div *ngIf="dataService.isFeatureFlag()">
                <button class="btn-primary px-1 mt-2">Reach Out</button>
            </div>
        </div>

        <div class="articles">
            <div class="cardTitle" *ngIf="getResources(selectedCompany).length > 0">
                <span>
                    {{ getResources(selectedCompany).length }}
                    {{
	                getResources(selectedCompany).length === 1
                            ? 'Piece of Evidence'
                            : 'Pieces of Evidence'
                    }}
                </span>
            </div>
            <img
                *ngIf="selectedCompany.twitterName"
                src="../../../../../assets/images/twitter.png"
                class="twitter_icon"
                (click)="openTwitter(selectedCompany)"
            />
            <div
                *ngIf="selectedCompany.twitterScore > 0"
                class="twitter_bar"
                style="border: solid 1px rgb(46, 131, 205)"
            >
                <div
                    [style.background]="'rgb(255, 255, 225)'"
                    [style.width]="selectedCompany.twitterScore * 3 + 'px'"
                    [class.twitter_barfill]="selectedCompany.twitterScore > 0"
                    title="The score is derived by the influencers following {{
                        selectedCompany.fullName | uppercase
                    }}"
                ></div>
            </div>
            <img
                *ngIf="selectedCompany.linkedInURL"
                src="../../../../../assets/images/linkedin.jpg"
                class="linkedin_icon"
                (click)="utilsComponent.openURL(selectedCompany.linkedInURL)"
            />

            <div class="table" *ngIf="getResources(selectedCompany).length > 0">
                <div class="articlesTable">
                    <div
                        class="article"
                        *ngFor="let resource of getResources(selectedCompany)"
                    >
                        <span class="articleNumber">
                            {{
	                        getResources(selectedCompany).indexOf(resource) + 1
                            }}
                        </span>
                        <div
                            class="publicationTypeLogo"
                            title="{{
                                resource.valueC === 'ARTICLE'
                                    ? 'Publication'
                                    : resource.valueC === 'GRANT'
                                    ? 'Grant'
                                    : resource.valueC === 'PATENT'
                                    ? 'Patent'
                                    : resource.valueC === 'CT'
                                    ? 'Clinical Trial'
                                    : resource.valueC === 'WEBSITE' &&
                                      resource.valueD
                                    ? 'Website'
                                    : ''
                            }}"
                            [style.backgroundColor]="
                                resource.valueC === 'WEBSITE' && resource.valueD
                                    ? '#FFFFFF'
                                    : 'none'
                            "
                            [style.backgroundImage]="
                                resource.valueC === 'ARTICLE'
                                    ? 'url(assets/images/articles_dark.png)'
                                    : resource.valueC === 'GRANT'
                                    ? 'url(assets/images/grants_dark.png)'
                                    : resource.valueC === 'PATENT'
                                    ? 'url(assets/images/innovation_blue_dark.png)'
                                    : resource.valueC === 'CT'
                                    ? 'url(assets/images/clinical_trials_icon_dark.png)'
                                    : resource.valueC === 'WEBSITE' &&
                                      resource.valueD
                                    ? 'url(' + resource.valueD + ')'
                                    : resource.valueC === 'WEBSITE' &&
                                      !resource.valueD
                                    ? 'url(assets/images/topicPageDemo.png)'
                                    : 'none'
                            "
                        ></div>
                        <a
                            class="articleName ellipsis"
                            href="{{ resource.valueA }}"
                            target="_blank"
                            title=""
                            [class.disabled]="!resource.valueA ? true : null"
                            (ngChange)="checkWidth($event, resource.valueB)"
                            (mouseover)="checkWidth($event, resource.valueB)"
                            >{{ resource.valueB }}</a
                        >
                    </div>
                </div>
            </div>

            <div class="table" *ngIf="selectedCompany.news.length > 0">
                <div class="extraInfo">
                    <span>
                        {{ selectedCompany.news.length }} Relevant News</span
                    >
                </div>
                <div class="articlesTable">
                    <div
                        class="article"
                        *ngFor="let news of selectedCompany.news"
                    >
                        <span class="articleNumber">
                            {{ selectedCompany.news.indexOf(news) + 1 }}
                        </span>
                        <div
                            class="publicationTypeLogo"
                            [style.backgroundColor]="'#FFFFFF'"
                            [style.backgroundImage]="'url(assets/images/news.png)'"
                        ></div>
                        <a
                            class="articleName ellipsis"
                            href="{{ news.valueB }}"
                            target="_blank"
                            title=""
                            (ngChange)="checkWidth($event, news.valueB)"
                            (mouseover)="checkWidth($event, news.valueB)"
                            >{{ news.valueA }}</a
                        >
                    </div>
                </div>
            </div>

            <div
                class="table"
                *ngIf="
                    selectedCompany.extraInfo &&
                    selectedCompany.extraInfo.length > 0
                "
            >
                <div class="extraInfo">
                    <span>
                        {{ selectedCompany.extraInfo.length }} More
                        {{
                            selectedCompany.extraInfo.length === 1
                                ? 'Patent by the Company'
                                : 'Patents by the Company'
                        }}
                    </span>
                </div>
                <div class="articlesTable">
                    <div
                        class="article"
                        *ngFor="let resource of selectedCompany.extraInfo"
                    >
                        <span class="articleNumber">
                            {{
                                selectedCompany.extraInfo.indexOf(resource) + 1
                            }}
                        </span>
                        <div
                            class="publicationTypeLogo"
                            [style.backgroundColor]="
                                resource.valueC === 'WEBSITE' && resource.valueD
                                    ? '#FFFFFF'
                                    : 'none'
                            "
                            [style.backgroundImage]="
                                resource.valueC === 'ARTICLE'
                                    ? 'url(assets/images/articles_dark.png)'
                                    : resource.valueC === 'GRANT'
                                    ? 'url(assets/images/grants_dark.png)'
                                    : resource.valueC === 'PATENT'
                                    ? 'url(assets/images/innovation_blue_dark.png)'
                                    : resource.valueC === 'CT'
                                    ? 'url(assets/images/clinical_trials_icon_dark.png)'
                                    : resource.valueC === 'WEBSITE' &&
                                      resource.valueD
                                    ? 'url(' + resource.valueD + ')'
                                    : resource.valueC === 'WEBSITE' &&
                                      !resource.valueD
                                    ? 'url(assets/images/topicPageDemo.png)'
                                    : 'none'
                            "
                        ></div>
                        <a
                            class="articleName ellipsis"
                            href="{{ resource.valueA }}"
                            target="_blank"
                            title=""
                            (ngChange)="checkWidth($event, resource.valueB)"
                            (mouseover)="checkWidth($event, resource.valueB)"
                            >{{ resource.valueB }}</a
                        >
                    </div>
                </div>
            </div>

            <div
                    class="table"
                    *ngIf="hasAdditionalGrants(selectedCompany)"
            >
                <div class="extraInfo">
                    <span>
                        {{ getAdditionalGrants(selectedCompany).length }} More
                        {{
	                    getAdditionalGrants(selectedCompany).length === 1
		                    ? 'Grant by the Company'
		                    : 'Grants by the Company'
                        }}
                    </span>
                </div>
                <div class="articlesTable">
                    <div
                            class="article"
                            *ngFor="let resource of getAdditionalGrants(selectedCompany)"
                    >
                        <span class="articleNumber">
                            {{
	                        getAdditionalGrants(selectedCompany).indexOf(resource) + 1
                            }}
                        </span>
                        <div
                                class="publicationTypeLogo"
                                [style.backgroundColor]="
                                resource.valueC === 'WEBSITE' && resource.valueD
                                    ? '#FFFFFF'
                                    : 'none'
                            "
                                [style.backgroundImage]="
                                resource.valueC === 'ARTICLE'
                                    ? 'url(assets/images/articles_dark.png)'
                                    : resource.valueC.indexOf('GRANT') > -1
                                    ? 'url(assets/images/grants_dark.png)'
                                    : resource.valueC === 'PATENT'
                                    ? 'url(assets/images/innovation_blue_dark.png)'
                                    : resource.valueC === 'CT'
                                    ? 'url(assets/images/clinical_trials_icon_dark.png)'
                                    : resource.valueC === 'WEBSITE' &&
                                      resource.valueD
                                    ? 'url(' + resource.valueD + ')'
                                    : resource.valueC === 'WEBSITE' &&
                                      !resource.valueD
                                    ? 'url(assets/images/topicPageDemo.png)'
                                    : 'none'
                            "
                        ></div>
                        <a
                                class="articleName ellipsis"
                                href="{{ resource.valueA }}"
                                target="_blank"
                                title=""
                                (ngChange)="checkWidth($event, resource.valueB)"
                                (mouseover)="checkWidth($event, resource.valueB)"
                        >{{ resource.valueB }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
