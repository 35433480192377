import { Component, OnInit, Input } from '@angular/core';
import { NetworkService } from '../../../network.service';

@Component({
    selector: 'app-publications-list',
    templateUrl: './publications-list.component.html',
    styleUrls: ['./publications-list.component.scss'],
})
export class PublicationsListComponent implements OnInit {
    @Input() expert;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {}
}
