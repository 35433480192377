import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {SaveSearchDialogComponent} from "../../dialogs/save-search-dialog/save-search-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {MatSelectChange} from "@angular/material/select";
import {NewProjectDialogComponent} from "../../../projects-page/new-project-dialog/new-project-dialog.component";
import {DeleteSearchModalComponent} from "../../dialogs/delete-search-modal/delete-search-modal.component";
import {ActivatedRoute} from "@angular/router";
import {SearchPageUtils} from "../../common/searchPageUtils";
import {AddCompanyModalComponent} from "../../dialogs/add-company-modal/add-company-modal.component";
import {DataService} from "../../../../data.service";

@Component({
    selector: 'search-filter-country',
    templateUrl: './search-filter-country.component.html',
    styleUrls: ['./search-filter-country.component.scss'],
})
export class SearchFilterCountry implements OnInit, OnChanges {

    locationsOptions: string[] = [];
    locationOption: string = 'World Wide';

    @Input() searchResults!: SearchQueryResults;
    @Output() searchResultsChange = new EventEmitter<SearchQueryResults>()

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResults) {
            // this.searchResults = changes.searchResults.currentValue;
            // this.locationOption = this.searchResults.filterCountry;
            this.handleCountriesFilterAndTheVideoURL();
            this.locationOption = this.searchResults.filterCountry; // 'World Wide';
        }
    }

    handleCountriesFilterAndTheVideoURL() {
        const countriesArr = ['World Wide'];
        if (this.searchResults.expertSearchResult && this.searchResults.expertSearchResult.researchers) {
            this.searchResults.expertSearchResult.researchers.forEach((expert) => {
                expert.countries.forEach((country) => {
                    countriesArr.push(country);
                });
            });
        }

        if (this.searchResults.institutionSearchResult && this.searchResults.institutionSearchResult.institutions) {
            this.searchResults.institutionSearchResult.institutions.forEach((company) => {
                company.countries.forEach((country) => {
                    countriesArr.push(country);
                })
            })
        }

        const countriesSet = new Set(countriesArr);
        this.locationsOptions = Array.from(countriesSet).sort();
    }

    isTileSearch() {
        return this.searchResults.searchType === 'TILE_SEARCH_EXPERTS';
    }

    selectLocation($event: MatSelectChange) {
        this.searchResults.filterCountry = this.locationOption;
        this.searchResultsChange.emit(this.searchResults);
    }
}
