import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../network.service';

@Component({
    selector: 'app-deposit',
    templateUrl: './deposit.component.html',
    styleUrls: ['./deposit.component.scss'],
})
export class DepositComponent implements OnInit {
    model: any = {};
    children$: Object;
    selectedFiles: File[] = null;
    showAutoComplete = false;
    searchMode = false;

    constructor(private networkService: NetworkService) {}

    onSubmit() {
        this.networkService.depositNewPublet(this.model).subscribe(
            (response) =>
                this.uploadFile(response.responseMessage, response.publetId),
            (error) => console.log(error),
        );
    }

    uploadFile(status, publetId) {
        if (this.selectedFiles != null && this.selectedFiles.length > 0) {
            for (let f = 0; f < this.selectedFiles.length; f++) {
                const fd = new FormData();
                fd.append(
                    'file',
                    this.selectedFiles[f],
                    this.selectedFiles[f].name,
                );
                fd.append('publetId', publetId);
                this.networkService.uploadPubletsFile(fd).subscribe();
            }
        }
    }

    outsideOfModalClicked(event) {
        this.searchMode = false;
        this.showAutoComplete = false;
    }

    textClick(event) {
        if (event.key === '@') {
            this.searchMode = true;
        } else if (this.searchMode && this.isAlphanumeric(event.keyCode)) {
            this.searchMode = false;
            this.showAutoComplete = true;
        }
    }

    isAlphanumeric(keyCode) {
        if (keyCode >= 48 && keyCode <= 57) {
            return true;
        }

        return keyCode >= 65 && keyCode <= 90;
    }

    onFileSelected(event) {
        this.selectedFiles = event.target.files;
    }

    ngOnInit() {
        // this.networkService.getPublets().subscribe(
        //     (data) => (this.children$ = data),
        //     (error) => console.log(error),
        // );
    }
}
