import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {EvidenceContainer, ProjectUpdate} from "../../../api-responses/SearchQueryResults.interface";
import {NetworkServiceCanvas} from "../../../network/network-service-canvas.service";
import {UtilsComponent} from "../../../utils/utils.component";
import {DataService} from "../../../data.service";

@Component({
    selector: 'canvas-html-update-page',
    templateUrl: './canvas-html-update-page.component.html',
    styleUrls: ['./canvas-html-update-page.component.scss'],
})
export class CanvasHtmlUpdatePageComponent implements OnInit {

    canvasId: number;
    loading = false;
    fromDate = '';
    toDate = '';

    updates: ProjectUpdate[];

    @ViewChild('updateContent') updateContent: ElementRef<HTMLCanvasElement>;

    initialized = false;
    private messages: string[] = ["Poll for evidence data..."];

    constructor(public utilsComponent: UtilsComponent,
                private route: ActivatedRoute,
                private dataService: DataService,
                private networkServiceCanvas: NetworkServiceCanvas) {

    }

    ngOnInit(): void {
        // let loadingUpdateContent = localStorage.getItem('loadingUpdateContent');
        // if (loadingUpdateContent != undefined) return;
        // localStorage.setItem('loadingUpdateContent', "true");

        this.loading = true;
        this.route.queryParams
            .subscribe(params => {
                    this.fromDate = params.fromDate;
                    this.toDate = params.toDate;
                    this.route.params.subscribe(params => {
                        this.canvasId = Number.parseInt(params['canvasId']);

                        if (this.initialized) return;
                        this.initialized = true;

                        // request process
                        this.networkServiceCanvas.processUpdates(this.canvasId, this.fromDate, this.toDate).then(() => {

                            function _run() {
                                this.networkServiceCanvas.getUpdates(this.dataService.isDev(), this.canvasId, this.fromDate, this.toDate)
                                    .then((updates: ProjectUpdate[]) => {
                                        this.addMessage("getting updates...");
                                        if (updates.length > 0) {
                                            this.updates = updates;
                                            this.loading = false;
                                            this.clearMessages();
                                        } else {
                                            setTimeout(_run.bind(this), 5000)
                                        }
                                    })
                                    .catch(() => {
                                        this.loading = false;
                                    })
                                    .finally(() => {
                                        // localStorage.removeItem('loadingUpdateContent');
                                    });
                            }

                            _run.call(this);

                        })

                        // poll for evidence


                    })
                }
            );
    }

    anyEvidence(evidence: EvidenceContainer): boolean {
        return (evidence.experts.length > 0) ||
            (evidence.papers.length > 0) ||
            (evidence.cts.length > 0) ||
            (evidence.news.length > 0) ||
            (evidence.grants.length > 0) ||
            (evidence.corporates.length > 0) ||
            (evidence.patents.length > 0) ||
            (evidence.startups.length > 0) ||
            (evidence.websites.length > 0);
    }


    getEmailContent() {
        return this.updateContent && this.updateContent.nativeElement ?
            this.updateContent.nativeElement.innerHTML :
            '';
    }

    private addMessage(message: string) {
        this.messages.push(message)
    }

    private clearMessages() {
        this.messages = [];
    }
}

