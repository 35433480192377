import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    currentUrl$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((navigationEnd: NavigationEnd) => navigationEnd.url),
    );

    constructor(private router: Router) {}
}
