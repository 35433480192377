import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {NetworkService} from '../../../network.service';
import {DataService} from '../../../data.service';
import {UtilitiesService} from '../../../utilities.service';
import {Researcher, SearchQueryResults} from "../../../api-responses/SearchQueryResults.interface";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ExpertModalComponent} from "./expert-modal/expert-modal.component";
import {Subscription} from "rxjs";
import {TreebuteProjectSummaryReducer} from "../../../utils/treebuteProjectSummaryReducer";
import {ExpertsModalData} from "../../../api-responses/Dialogs.interface";
import {CreateCalloutModalComponent} from "../../callout/create/create-callout-modal.component";
import {Callout, CreateCalloutDialogData} from "../../callout/callouts/callout.interface";

@Component({
    selector: 'app-experts',
    templateUrl: './experts.component.html',
    styleUrls: ['./experts.component.scss']
})
export class ExpertsComponent implements OnInit, OnChanges {
    @Input() cardsDisplay: boolean = true;
    @Input() descriptorIds: [];
    @Input() loadingInput: boolean = false;
    @Input() allowFilters: boolean;
    @Input() type: string;
    @Input() searchString: string;

    // @Input() country: string;
    @Input() institution: string;
    @Input() forceNLP: boolean;
    @Input() industrySearch: boolean;
    @Input() academicField: string;
    @Input() selectedNameForExperts: string;

    @Output() nlp = new EventEmitter();
    @Output() setLoading = new EventEmitter();

    @Input() searchResults: SearchQueryResults;
    @Input() dumbInput: number;

    listDisplay = false;
    exportLoading = false;
    experts$: any;
    selectedExperts = [];
    noResults = false;
    expertDetailsLoading = false;
    expertExpandLoading = false;
    feedbackOpen = false;
    enableNextExpert: boolean;
    enablePreviousExpert: boolean;
    previousLoading = false;
    nextLoading = false;
    showWordCloud = false;
    innovationPresent = false;
    descriptorsCount: any;
    youngLeadersVisible = false;
    // @Input() youngLeadersOnly = false;
    CTVisible = false;
    CTOnly = false;
    influencerVisible = false;
    // @Input() influencerOnly = false;
    youngTalentsVisible = false;
    // @Input() youngTalentsOnly = false;
    justPIVisible = false;
    // @Input() pisOnly = false;
    showModal = false;
    currentExpert: Researcher;
    resizeTimer = null;
    googleSheets = false;
    private fromModalToData = Subscription.EMPTY;
    private selectedExpert: Researcher;

    constructor(
        private networkService: NetworkService,
        public dataService: DataService,
        public matDialog: MatDialog,
        public newCalloutDialogRef: MatDialogRef<CreateCalloutModalComponent>,
        // private cdRef: ChangeDetectorRef,
        // private floatingUpdateService: FloatingUpdateService,
    ) {
    }

    @Input() get results() {
        return this.noResults;
    }

    public _filteredExperts: Researcher[] = [];

    ngOnInit() {
    }

    ngOnDestroy() {
        this.fromModalToData.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.noResults = false;

        if (changes.searchResults && changes.searchResults.currentValue) {
            this._filteredExperts = this.filterExperts(changes.searchResults.currentValue.expertSearchResult.researchers);
            // this.handleVisualScore();
        }
    }

    pisOnly() {
        return this.searchResults.filterInvestigator === 'Lead Investigators';
    }

    youngLeadersOnly() {
        return this.searchResults.filterInvestigator === 'Potential collaborators';
    }

    youngTalentsOnly() {
        return this.searchResults.filterInvestigator === 'Rising talents';
    }

    influencerOnly() {
        return this.searchResults.filterInvestigator === 'Influencers';
    }

    country() {
        return this.searchResults.filterCountry;
    }

    rowClicked(event, expert) {
        if (event.target.classList[0] !== 'envelope_icon') {
            this.open(expert, this._filteredExperts, false);
        }
    }

    checkWidth(event, expert, type) {
        event.target.title = '';
        if (event.target.offsetWidth !== event.target.scrollWidth) {
            event.target.title =
                type === 'quote' ? expert.quote : expert.department;
        }
    }

    flipWordCloud() {
        this.showWordCloud = !this.showWordCloud;
    }

    calculateHeight() {
        setTimeout(() => {
            if (this.listDisplay && document.getElementById('topContainer')) {
                document.getElementById('topContainer').style.height =
                    'calc(100vh - 116px)';
            } else if (
                document.getElementById('expertsContainer') &&
                document.getElementById('topContainer')
            ) {
                const myHeight =
                    document.getElementById('expertsContainer').offsetHeight;
                const formContainerHeight = this.industrySearch ? 60 : 110;
                document.getElementById('topContainer').style.height =
                    myHeight + formContainerHeight + 'px';
            }
        }, 1);
    }

    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    loadResearchesForDescriptor(descriptors) {
        // this.loading = this.loadingInput;
        if (this.type === 'campus') {
            this.networkService
                .getResearchersForDescriptor(descriptors)
                .subscribe(
                    (data) => this.handleVisualScore(data),
                    (error) => console.log(error),
                );
        } else {
            this.networkService
                .getExpertsByQuery(
                    this.searchString,
                    this.isGlobal(),
                    this.country() !== 'World Wide' ? this.country() : 'null',
                    this.forceNLP,
                    this.academicField === undefined
                        ? 'ALL'
                        : this.academicField,
                    this.institution !== 'All Institutions'
                        ? this.institution
                        : null,
                    descriptors,
                )
                .subscribe((data) => {
                    this.handleVisualScore(data);
                    this.nlp.emit(data);
                });
        }
    }

    handleVisualScore(data) {
        // this.showAllClick();
        this.youngTalentsVisible = false;
        this.justPIVisible = false;
        this.youngLeadersVisible = false;
        // this.youngLeadersOnly() = false;
        this.influencerVisible = false;
        // this.influencerOnly() = false;
        this.CTOnly = false;
        this.CTVisible = false;
        this.allowFilters = true;
        this.noResults = false;
        let checkForLeadership = true;
        if (
            this.descriptorIds !== undefined &&
            this.descriptorIds.toString().startsWith('S')
        ) {
            checkForLeadership = false;
        }
        // this.loading = this.loadingInput;
        const descriptorsCount = this.descriptorsCount;
        let expertsArr;
        if (data !== undefined) {
            expertsArr = this.type === 'explorer' ? data.valueA : data;
            // this.loading = false;
            let ratio = 0;
            let id = 0;
            if (expertsArr.length === 0) {
                document.getElementById('topContainer').style.height =
                    'calc(100vh - 54px)';
                this.noResults = true;
                this.allowFilters = false;
            }
            expertsArr.forEach((researcher) => {
                if (checkForLeadership) {
                    if (researcher.youngPI) {
                        this.youngTalentsVisible = true;
                    }
                    if (researcher.pi === false) {
                        this.justPIVisible = true;
                    }
                    if (researcher.youngLeader && this.isGlobal()) {
                        this.youngLeadersVisible = true;
                    }
                    if (researcher.influencer) {
                        this.influencerVisible = true;
                    }
                    if (researcher.numberOfCTS > 0 && this.isGlobal()) {
                        this.CTVisible = true;
                    }
                }

                if (ratio === 0) {
                    let widthToFill = 300;
                    if (researcher.expertScore < 100) {
                        if (researcher.expertScore < 75) {
                            if (researcher.expertScore < 50) {
                                widthToFill = 150;
                            } else {
                                widthToFill = 200;
                            }
                        } else {
                            widthToFill = 250;
                        }
                    }
                    ratio = widthToFill / researcher.expertScore;
                }

                researcher.modifiedArticlesScore =
                    (researcher.expertScore -
                        researcher.citationsScore -
                        researcher.patentsScore) *
                    ratio;
                if (researcher.modifiedArticlesScore < 5) {
                    researcher.modifiedArticlesScore = 4;
                }
                researcher.modifiedCitationScore =
                    researcher.citationsScore * ratio;
                researcher.modifiedPatentsScore =
                    researcher.patentsScore * ratio;
                researcher.id = id++;
                // researcher.expand = false;
            });
        }

        // Filter the descriptors count
        this.descriptorsCount = this.sortProperties(descriptorsCount);
        this.experts$ = expertsArr;
        // this.cdRef.detectChanges();
    }

    filterExperts(experts): Researcher[] {
        const result = [];
        this.innovationPresent = false;
        // let numOfResults = 500;
        // if (this.searchString != null && this.searchString.includes(' OR ')) {
        //     numOfResults = 2000;
        // }

        let numOfResults = experts && experts.length;

        experts && experts.forEach((researcher) => {
            if (result.length < numOfResults) {
                if (researcher.patentsScore > 0) {
                    this.innovationPresent = true;
                }
                let add = true;
                if (researcher.lowScore) {
                    add = false;
                }
                if (this.youngTalentsOnly()) {
                    if (researcher.youngPI) {
                        add = true;
                    } else {
                        add = false;
                    }
                }
                if (this.influencerOnly()) {
                    if (researcher.influencer) {
                        add = true;
                    } else {
                        add = false;
                    }
                }
                if (this.youngLeadersOnly()) {
                    if (researcher.youngLeader) {
                        add = true;
                    } else {
                        add = false;
                    }
                }
                if (this.CTOnly) {
                    if (researcher.numberOfCTS > 0) {
                        add = true;
                    } else {
                        add = false;
                    }
                }
                if (this.pisOnly()) {
                    if (researcher.pi) {
                        add = true;
                    } else {
                        add = false;
                    }
                }

                if (this.searchResults.filterHasGrants) {
                    add = researcher.grants && researcher.grants.length > 0;
                }

                if (!add) return;

                if (!this.isWorld()) {
                    for (let i=0; i< researcher.countries.length; i++){
                        let country = researcher.countries[i];
                        if (this.searchResults.filterCountry.toLowerCase() == country.toLowerCase()) {
                            result.push(researcher);
                            break;
                        }
                    }
                }
                else {
                    result.push(researcher);
                }
            }
        });
        return result;
    }

    sortProperties(obj) {
        // convert object into array
        const sortable = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                sortable.push([key, obj[key]]);
            }
        }

        // sort items by value
        sortable.sort(function (a, b) {
            return b[1] - a[1]; // compare numbers
        });
        return sortable;
    }

    // expertClicked(expertId) {
    //
    //
    //     this.experts$.forEach((researcher) => {
    //         if (researcher.id === expertId) {
    //             if (researcher.expertData === undefined) {
    //                 this.networkService
    //                     .getDescriptorsMapping(
    //                         researcher.firstName,
    //                         researcher.lastName,
    //                         researcher.initials,
    //                         researcher.publications,
    //                         undefined,
    //                         researcher.email,
    //                         !this.allowFilters,
    //                         this.globalSearch,
    //                         researcher.department,
    //                         researcher.allNames,
    //                         researcher.affiliations,
    //                     )
    //                     .subscribe(
    //                         (data) => {
    //                             this.handleExpertData(researcher, data);
    //                         },
    //                         (error) => console.log(error),
    //                     );
    //             }
    //             return;
    //         }
    //     });
    // }


    private dialogRef: any;

    open(expert, experts, reachOut) {
        this.dialogRef = this.matDialog.open(ExpertModalComponent, {
            data: {
                expert: expert,
                experts: experts,
                isGlobal: this.isGlobal(),
                industrySearch: this.industrySearch,
                searchResults: this.searchResults,
                simpleNavigation: true,
                reachOut: reachOut,
                hideNav: false
            } as ExpertsModalData
        });


    }

    getResearcherLinkedIn(expert) {
        if (
            expert.linkedInURL === undefined &&
            expert.firstName !== undefined &&
            expert.lastName !== undefined &&
            expert.department !== undefined
        ) {
            const name = expert.firstName + ' ' + expert.lastName;
            const expertIndex = this.experts$.indexOf(expert);
            this.networkService
                .getLinkedIn(name, expert.department)
                .subscribe((linkedInResponse) => {
                    this.updateLinkedIn(linkedInResponse, expertIndex);
                });
        }
    }

    updateLinkedIn(response, expertIndex) {
        if (response !== undefined && response.toString().length > 5) {
            this.experts$[expertIndex].linkedInURL = response;
            // this.cdRef.detectChanges();
        }
    }

    close() {
        const body = document.getElementsByTagName('body')[0];
        // body.style.overflowY = 'auto';
        this.showModal = false;
    }

    getOpacity(index) {
        const limited = UtilitiesService.isLimited(this.dataService.userData);
        const indexAboveLimit =
            index >= this.dataService.userData.limitedUserMaxResultsSize;

        if (limited && indexAboveLimit) {
            const all =
                this.dataService.userData.limitedUserMaxResultsSize +
                this.dataService.userData.limitedObfuscatedResultsSize;
            const obfuscated =
                this.dataService.userData.limitedObfuscatedResultsSize;
            const grad = (index + obfuscated + 1 - all) * 0.1;
            const opacity = 0.8 - grad;
            return opacity;
        }
        return 1;
    }

    exportBtnClicked() {
        console.log(this.selectedNameForExperts);
        this.exportLoading = true;
        document.getElementById('exportBtn').style.backgroundImage =
            "url('assets/images/loadloop.gif')";

        let experts = this.filterExperts(this.experts$);
        if (this.selectedExperts.length > 0) {
            experts = experts.filter((e) => this.selectedExperts.includes(e));
        }

        const date = new Date();

        const type =
            this.type === 'campus'
                ? 'My Campus'
                : this.type === 'explorer'
                    ? 'Explorer Map'
                    : this.type === undefined
                        ? 'Experts'
                        : null;
        this.networkService
            .export(
                experts,
                this.searchString,
                type,
                this.isGlobal()
                    ? this.country() !== 'World Wide'
                        ? `Country (${this.country()})`
                        : 'World Wide'
                    : `Campus (${this.dataService.userData.instituation})`,
                this.selectedNameForExperts,
                date,
                this.googleSheets,
            )
            .subscribe(
                (results) => {
                    const options = {type: 'application/ms-excel'};
                    const queryTrimmed =
                        this.searchString === undefined
                            ? null
                            : this.searchString.length < 50
                                ? this.searchString
                                : this.searchString.substring(0, 50) + '...';
                    const filename = `experts${
                        this.type === 'campus'
                            ? ' - Campus map'
                            : ' - ' + queryTrimmed
                    }.xlsx`;
                    this.createAndDownloadBlobFile(results, options, filename);
                },
                (error) => {
                    console.log(error);
                },
            );
    }

    createAndDownloadBlobFile(body, options, filename) {
        this.exportLoading = false;
        document.getElementById('exportBtn').style.backgroundImage =
            "url('assets/images/export-icon-dark.png')";

        const blob = new Blob([body], options);
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
        }
    }

    addExpertToSelectedArr(expert) {
        this.selectedExperts.push(expert);
    }

    removeExpertFromSelectedArr(expert) {
        this.selectedExperts = this.selectedExperts.filter((e) => e !== expert);
    }

    openFeedback() {
        const body = document.getElementsByTagName('body')[0];
        // body.style.overflowY = 'hidden';
        this.feedbackOpen = true;
    }

    closeFeedback() {
        const body = document.getElementsByTagName('body')[0];
        // body.style.overflowY = 'auto';
        this.feedbackOpen = false;
    }

    vw() {
        return UtilitiesService.vw();
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }

    isGlobal() {
        return this.searchResults.scope.toLowerCase() === 'world' ||
            this.searchResults.scope.toLowerCase() === 'world wide';
    }

    isWorld() {
        return this.searchResults.filterCountry.toLowerCase() === 'world' ||
            this.searchResults.filterCountry.toLowerCase() === 'world wide';
    }

    isCtExperts(): boolean {
        return TreebuteProjectSummaryReducer.isCtExperts(this.searchResults);
    }

    newRequest(expert: Researcher) {
        this.newCalloutDialogRef = this.matDialog.open(CreateCalloutModalComponent, {
            data: {
                researcher: expert
            } as CreateCalloutDialogData
        });
        this.newCalloutDialogRef && this.newCalloutDialogRef.afterClosed().toPromise().then((callout: Callout) => {
            // create the callout
            window.open('/app/callout/' + callout.id, '_blank');
        })
    }
}
