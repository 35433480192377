<!--<div class="add-data-button" [routerLink]="getRouterLink()"  matTooltip="{{getToolTip()}}" [matTooltipPosition]="'above'">-->
<div class="add-data-button" [routerLink]="getRouterLink()" *ngIf="!readOnlyProjectService.isReadOnlyProject">
    <div class="circle" [ngStyle]="this.getColor()">
        <div class="done-icon"></div>
    </div>
    <div class="add-data">
        <div class="data-title">{{ this.searchResult.title }}</div>
    </div>
    <div class="delete-search" (click)="_deleteSearch($event)">
        X
    </div>
</div>
<div class="add-data-button add-data-button2" (click)="_clickChip($event)" *ngIf="readOnlyProjectService.isReadOnlyProject">
    <div class="circle circle2" [ngStyle]="this.getColor()">
        <div class="done-icon"></div>
    </div>
    <div class="add-data">
        <div class="data-title">{{ this.searchResult.title }}</div>
    </div>
</div>

