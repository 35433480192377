import {Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {DataService} from "../../../../data.service";
import {
    FeedbackModalData,
    Researcher,
    SearchQueryResults
} from "../../../../api-responses/SearchQueryResults.interface";
import {UtilitiesService} from "../../../../utilities.service";
import {Subscription} from "rxjs";
import {FeedbackModal} from "../../dialogs/feedback-modal/feedback-modal.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NetworkService} from "../../../../network.service";
import {ExpertsModalData} from "../../../../api-responses/Dialogs.interface";
import {AddExpertToCalloutModalComponent} from "../../dialogs/callout/add-expert-to-callout-modal.component";
import {CreateCalloutModalComponent} from "../../../callout/create/create-callout-modal.component";
import {Callout, CreateCalloutDialogData} from "../../../callout/callouts/callout.interface";

@Component({
    selector: 'ct-experts-modal',
    templateUrl: './ct-experts-modal.component.html',
    styleUrls: ['./ct-experts-modal.component.scss'],
})
export class CtExpertsModalComponent implements OnInit, OnDestroy, OnChanges {
    public selectedExpert: Researcher;

    evidenceTabActive = true;
    piecesOfEvidence = [];
    searchResults: SearchQueryResults;

    constructor(
        private networkService: NetworkService,
        public dataService: DataService,
        public feedbackModal: MatDialog,
        public dialogRef: MatDialogRef<CtExpertsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ExpertsModalData,
        public matDialog: MatDialog,
        public newCalloutDialogRef: MatDialogRef<CreateCalloutModalComponent>
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
        // this.subscription.unsubscribe();
    }

    subscription: Subscription;

    ngOnInit(): void {
        this.selectedExpert = this.data.expert;
        this.searchResults = this.data.searchResults;

        this.handlePiecesOfEvidenceArr();
    }

    nextExpert() {
        let index = this.data.experts.indexOf(this.selectedExpert);
        if (this.data.simpleNavigation) {
            this.selectedExpert = this.data.experts[index + 1];
            this.handlePiecesOfEvidenceArr();
        }
        else {
            this.dialogRef.close({reopen: this.data.experts[index + 1]})
        }
    }

    previousExpert() {
        let index = this.data.experts.indexOf(this.selectedExpert);
        if (this.data.simpleNavigation) {
            this.selectedExpert = this.data.experts[index-1];
            this.handlePiecesOfEvidenceArr();
        }
        else {
            this.dialogRef.close({reopen: this.data.experts[index - 1]})
        }
    }

    closeModal() {
        this.dialogRef.close();
    }

    handlePiecesOfEvidenceArr() {
        let expert = this.selectedExpert
        this.piecesOfEvidence = [];

        for (const clinicalTrial of expert.clinicalTrials) {
            const obj = {
                type: 'CT',
                title: clinicalTrial.valueB,
                url: `https://clinicaltrials.gov/ct2/show/study/${clinicalTrial.valueA}`,
                phase: clinicalTrial.valueC,
            };
            this.piecesOfEvidence.push(obj);
        }

        if (expert.publications) {
            for (const publication of expert.publications) {
                const obj = {
                    type: 'ARTICLE',
                    title: publication.valueC,
                    url: publication.valueD,
                };
                this.piecesOfEvidence.push(obj);
            }
        }

        if (expert.patentTitlesToURLs){
            for (const patent of expert.patentTitlesToURLs) {
                const obj = {
                    type: 'PATENT',
                    title: patent.valueA,
                    url: patent.valueB,
                };
                this.piecesOfEvidence.push(obj);
            }
        }

    }


    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    ellipsisCompany(company, maxLength) {
        return company.length > maxLength ?
            company.substring(0, maxLength) + "..." :
            company;
    }

    checkPreviousCompany() {
        let len = this.selectedExpert.workedWithIndustryNames.length;
        let left = document.getElementById('companiesContent').style.left;
        if (this.vw() > 900) {
            return Number(left.slice(0, left.length - 2)) === 0 || len <= 3;
        }
        else {
            return Number(left.slice(0, left.length - 2)) === 0 || len <= 1;
        }
    }

    checkNextCompany() {
        let len = this.selectedExpert.workedWithIndustryNames.length;
        let left = document.getElementById('companiesContent').style.left;
        if (this.vw() > 900) {
            return Math.abs(Number(left.slice(0, left.length - 2))) === ((len - 3) * 181) || len <= 3;
        }
        else {
            return Math.abs(Number(left.slice(0, left.length - 2))) === ((len - 1) * 181) || len <= 1;
        }
    }

    previousCompany() {
        let left = document.getElementById('companiesContent').style.left;
        document.getElementById('companiesContent').style.left = Number(left.slice(0, left.length - 2)) + 181 + 'px';
    }

    nextCompany() {
        let left = document.getElementById('companiesContent').style.left;
        document.getElementById('companiesContent').style.left = Number(left.slice(0, left.length - 2)) - 181 + 'px';
    }

    openFeedback(expert: Researcher) {
        const data = {
            dialogTitle: "New Search",
            dialogDescription: "How would you like to perform the search?",
            searchResults: this.searchResults,
            researcher: expert,
            btnText: "SUBMIT",
            textPlaceholder: "Type more than we know to help us..."
        } as FeedbackModalData;

        let dialogRef = this.feedbackModal.open(FeedbackModal, {data: data});

        dialogRef.afterClosed()
            .toPromise()
            .then((result) => {

            })
    }

    public enableNextExpert() {
        return this.data.experts.indexOf(this.selectedExpert) < this.data.experts.length - 1;
    }

    public enablePreviousExpert() {
        return this.data.experts.indexOf(this.selectedExpert) > 0;
    }

    public getGoogleSearchUrl(department: string) {
        return UtilitiesService.getGoogleSearchUrl(department);
    }

    addToRequest(expert: Researcher) {
        this.matDialog.open(AddExpertToCalloutModalComponent, { data: expert});
    }

    newRequest(expert: Researcher) {
        this.newCalloutDialogRef = this.matDialog.open(CreateCalloutModalComponent, {
            data: {
                researcher: expert
            } as CreateCalloutDialogData
        });
        this.newCalloutDialogRef && this.newCalloutDialogRef.afterClosed().toPromise().then((callout: Callout) => {
            // create the callout
            window.open('/app/callout/' + callout.id, '_blank');
        })
    }
}
