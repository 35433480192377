import { Component, OnInit, Input } from '@angular/core';
import { NetworkService } from '../../../network.service';

@Component({
    selector: 'app-users-group-edit',
    templateUrl: './users-group-edit.component.html',
    styleUrls: ['./users-group-edit.component.scss'],
})
export class UsersGroupEditComponent implements OnInit {
    @Input() group: any;
    usersInGroup$: any;
    newUser: any = {};

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        this.refreshUsersList();
    }

    refreshUsersList() {
        this.networkService.getUsersInGroup(this.group.id).subscribe(
            (data) => (this.usersInGroup$ = data),
            (error) => console.log(error),
        );
    }

    onSubmit() {
        this.newUser.groupId = this.group.id;
        this.networkService.createNewUserInGroup(this.newUser).subscribe(
            (data) => this.refreshUsersList(),
            (error) => console.log(error),
        );
    }

    deleteUserClicked(user) {
        this.networkService.deleteUserInGroup(user.id).subscribe(
            (data) => this.refreshUsersList(),
            (error) => console.log(error),
        );
    }
}
