<div class="detailsContainer" id="details" *ngIf="expert.expertData && expert.expertData.children">
    <button
        *ngIf="vw() > 1000"
        style="background-image: url('assets/images/experts-next.png')"
        id="nextExpert"
        (click)="nextExpert.emit($event)"
        [disabled]="!enableNextExpert"
    ></button>
    <button
        *ngIf="vw() > 1000"
        style="background-image: url('assets/images/experts-next.png')"
        id="previousExpert"
        (click)="previousExpert.emit($event)"
        [disabled]="!enablePreviousExpert"
    ></button>
    <div
        *ngIf="vw() >= 900 && searchResults"
        id="feedback"
        style="background-image: url('assets/images/feedback.png')"
        (click)="openFeedback(expert)"
        [class.fitFeedback]="!expert.email && !expert.department"
    ></div>

    <div class="header">
        <img
            src="assets/images/close-dark.png"
            class="closeIcon"
            (click)="close.emit($event)"
        />
        <img [hidden]="true"
            *ngIf="!exportLoading"
            src="assets/images/export-icon-dark.png"
            class="exportIcon"
            (click)="export()"
        />
        <img [hidden]="true"
            *ngIf="exportLoading"
            src="assets/images/loadloop.gif"
            class="loadingIcon"
        />
        <div class="name-and-action-container">
            <div class="name" *ngIf="vw() >= 900">
                {{ expert.lastName }} {{ expert.firstName }}
                <div *ngIf="expert.linkedIn">
                    <a target="_blank" href="{{ expert.linkedIn }}">
                        <img class="linkedin" src="assets/images/linkedin.jpg" />
                    </a>
                </div>
                <div *ngIf="expert.googleScholar">
                    <a target="_blank" href="{{ expert.googleScholar }}">
                        <img class="google-scholar" src="assets/images/google-scholar.png">
                    </a>
                </div>
            </div>
            <div>
                <button class="btn-primary mx-2 px-1" [matMenuTriggerFor]="menu">
                    <small>Connect</small>
                </button>
                <mat-menu #menu="matMenu" >
                    <button class="px-3" mat-menu-item (click)="newRequest(expert)">New Request</button>
                    <button class="px-3" mat-menu-item (click)="addToRequest(expert)" >Add to Request...</button>
                </mat-menu>
            </div>
        </div>

<!--        <div class="title-shared" *ngIf="dataService.isFeatureFlag()">-->
<!--            ${{ getPrice(expert) }}/hour *estimated</div>-->
        <div
            class="name"
            *ngIf="vw() < 900"
            [class.ellipsis]="
                expert.lastName.length + 1 + expert.firstName.length >= 18
            "
        >
            <span
                title="{{ expert.lastName }} {{ expert.firstName }}"
                style="color: #008ded"
            >
                {{ expert.lastName }} {{ expert.firstName }}
            </span>
            <div *ngIf="expert.linkedIn">
                <a target="_blank" href="{{ expert.linkedIn }}">
                    <img class="linkedin" src="assets/images/linkedin.jpg" />
                </a>
            </div>
        </div>

        <div
            class="headerContent"
            [class.secondary]="
                !expert.email && !expert.department && !expert.logoURL
            "
            [class.fitHeader]="
                vw() < 900 &&
                !expert.email &&
                !expert.department &&
                !expert.logoURL
            "
            [style.borderBottom]="
                ((expert.expertData.children &&
                    expert.expertData.children.length) ||
                    expert.expertData.allPublications.length ||
                    expert.expertData.matchingPublications.length) &&
                expert.expertData.workedWithOthers.length
                    ? null
                    : '1px solid #dbeffc'
            "
        >
            <div
                *ngIf="expert.logoURL"
                style="
                    width: 29px;
                    height: 29px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    flex-shrink: 0;
                    position: absolute;
                "
                [style.backgroundImage]="
                    expert.logoURL ? 'url(' + expert.logoURL + ')' : 'none'
                "
            ></div>

            <span
                [style.marginLeft]="
                    expert.department && expert.logoURL ? '50px' : '0'
                "
                (ngChange)="checkWidth($event, expert.department)"
                (mouseover)="checkWidth($event, expert.department)"
                class="department tt grayLink"
                title=""
                [class.primaryDep]="expert.department && !expert.email"
                >
                <a href="{{ getGoogleSearchUrl(expert.department) }}" target="_blank">{{expert.department}}</a>
            </span
            >

            <div
                class="email"
                *ngIf="expert.email"
                [class.primary]="
                    expert.email && !expert.department && !expert.logoURL
                "
            >
                <img src="assets/images/envelope_blue_dark.png" />
                <a target="_blank" href="mailto:{{ expert.email }}">
                    {{ expert.email }}
                </a>
            </div>
        </div>
    </div>

    <div
        class="tab"
        *ngIf="
            ((expert.expertData.children &&
                expert.expertData.children.length) ||
                expert.expertData.allPublications.length ||
                expert.expertData.matchingPublications.length) &&
            expert.expertData.workedWithOthers.length
        "
    >
        <button
            class="tablinks"
            (click)="tabActive = 0"
            [class.active]="tabActive === 0"
        >
            Evidence
        </button>
        <button
            class="tablinks"
            (click)="tabActive = 1"
            [class.active]="tabActive === 1"
        >
            Collaborations
        </button>
    </div>

    <div class="main">
        <div
            class="experties"
            *ngIf="
                expert.expertData.children &&
                expert.expertData.children.length > 0
            "
            [hidden]="tabActive!==0"
            [class.expertiesStatic]="
                (expert.expertData.children &&
                    expert.expertData.children.length >= 9) ||
                expert.expertData.matchingPublications.length >= 5
            "
            [class.fit]="vw() < 900 && expert.expertData.children.length < 6"
            [class.noArticles]="
                expert.expertData.allPublications.length === 0 &&
                expert.expertData.matchingPublications.length === 0
            "
        >
            <div class="title" style="padding-bottom: 5px">
                <img src="assets/images/search_dark.png" />
                <span> Expertise </span>
            </div>

            <div class="subject">SUBJECT</div>

            <div
                class="bars"
                [class.barsStatic]="
                    (expert.expertData.children &&
                        expert.expertData.children.length >= 9) ||
                    expert.expertData.matchingPublications.length >= 5
                "
                [class.fit]="
                    vw() < 900 && expert.expertData.children.length < 6
                "
            >
                <div
                    class="barContainer"
                    *ngFor="let child of expert.expertData.children"
                >
                    <span
                        class="barTitle"
                        title=""
                        (ngChange)="checkWidth($event, child.name)"
                        (mouseover)="checkWidth($event, child.name)"
                        >{{ child.name }}</span
                    >
                    <div class="barContent">
                        <div class="bar" style="border: solid 1px #2e83cd">
                            <div
                                [style.background]="'#2e83cd'"
                                [style.width]="
                                    descriptors[
                                        expert.expertData.children.indexOf(
                                            child
                                        )
                                    ] +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    descriptors[
                                        expert.expertData.children.indexOf(
                                            child
                                        )
                                    ] > 119
                                "
                                [class.empty]="
                                    descriptors[
                                        expert.expertData.children.indexOf(
                                            child
                                        )
                                    ] === 0
                                "
                            ></div>
                        </div>
                        <span class="number"> {{ child.size }}% </span>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="articles"
            *ngIf="
                (expert.expertData.allPublications.length > 0 ||
                    expert.expertData.matchingPublications.length > 0)
            "
            [hidden]="tabActive!==0"
            [class.articlesStatic]="
                (expert.expertData.children &&
                    expert.expertData.children.length >= 9) ||
                expert.expertData.matchingPublications.length >= 5
            "
            [class.fit]="
                vw() < 900 && expert.expertData.matchingPublications.length < 4
            "
            [class.single]="!expert.expertData.children.length"
        >
            <div class="title" style="padding-bottom: 2px">
                <img
                    src="assets/images/articles_dark.png"
                    style="width: 30px; height: 26px"
                />
                <span>
                    {{  totalArticlesCount  }}
                    Total Articles
                </span>
            </div>

            <div
                class="filters"
                *ngIf="expert.expertData.allPublications.length > 0"
            >
                <div
                    class="relevant"
                    (click)="
                        !relevantOnly ? (relevantOnly = !relevantOnly) : null
                    "
                >
                    <img
                        *ngIf="relevantOnly"
                        src="assets/images/checkbox-selected-dark.png"
                    />
                    <img
                        *ngIf="!relevantOnly"
                        src="assets/images/checkbox_dark.png"
                    />
                    <span>
                        {{ expert.expertData.matchingPublications.length }}
                        Relevant Articles
                    </span>
                </div>

                <div
                    class="all"
                    (click)="
                        relevantOnly ? (relevantOnly = !relevantOnly) : null
                    "
                >
                    <img
                        *ngIf="!relevantOnly"
                        src="assets/images/checkbox-selected-dark.png"
                    />
                    <img
                        *ngIf="relevantOnly"
                        src="assets/images/checkbox_dark.png"
                    />
                    <span> All Articles </span>
                </div>
            </div>

            <div
                class="table"
                [class.higher]="
                    relevantOnly && !expert.expertData.allPublications.length
                "
                [class.lower]="!relevantOnly"
                [class.singleTable]="
                    !expert.expertData.children.length &&
                    relevantOnly &&
                    !expert.expertData.allPublications.length
                "
                [class.tableStatic]="
                    (relevantOnly &&
                        expert.expertData.matchingPublications.length >= 5) ||
                    (!relevantOnly &&
                        expert.expertData.allPublications.length >= 5)
                "
            >
                <div
                    class="articlesTable"
                    *ngIf="!relevantOnly"
                    [class.fit]="
                        vw() < 900 &&
                        expert.expertData.allPublications.length < 4
                    "
                >
                    <div
                        class="article"
                        *ngFor="
                            let publication of this.expert.expertData
                                .allPublications
                        "
                    >
                        <span class="articleNumber">
                            {{
                                this.expert.expertData.allPublications.indexOf(
                                    publication
                                ) + 1
                            }}
                        </span>
                        <span class="articleName">
                            <a
                                href="{{ publication.url }}"
                                target="_blank"
                                *ngIf="publication.title.length <= 47"
                                >{{ publication.title }}</a
                            >
                            <a
                                href="{{ publication.url }}"
                                target="_blank"
                                data-toggle="tooltip"
                                title="{{ publication.title }}"
                                *ngIf="publication.title.length > 47"
                                >{{ publication.title }}</a
                            >
                        </span>
                    </div>
                </div>

                <div
                    class="articlesTable"
                    *ngIf="relevantOnly"
                    [class.fit]="
                        vw() < 900 &&
                        expert.expertData.matchingPublications.length < 4
                    "
                >
                    <div
                        class="article"
                        *ngFor="
                            let publication of this.expert.expertData
                                .matchingPublications
                        "
                    >
                        <span class="articleNumber">
                            {{
                                this.expert.expertData.matchingPublications.indexOf(
                                    publication
                                ) + 1
                            }}
                        </span>
                        <span class="articleName">
                            <span
                                ><a
                                    href="{{ publication.url }}"
                                    target="_blank"
                                    *ngIf="publication.title.length <= 47"
                                    >{{ publication.title }}</a
                                ></span
                            >
                            <span
                                ><a
                                    href="{{ publication.url }}"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    title="{{ publication.title }}"
                                    *ngIf="publication.title.length > 47"
                                    >{{ publication.title }}</a
                                ></span
                            >
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="experties evidenceExperties"
            [hidden]="tabActive!==1"
            [class.expertiesStatic]="
                expert.expertData.workedWithOthers.length >= 9
            "
            [class.fit]="
                vw() < 900 && expert.expertData.workedWithOthers.length < 6
            "
            [class.noArticles]="true"
        >
            <div
                class="title"
                [style.paddingBottom]="vw() <= 900 ? '10px' : '14px'"
            >
                <img
                    src="assets/images/people-dark.png"
                    style="width: 32px; height: 28px"
                />
                <span> Worked with others </span>
            </div>

            <div
                class="bars evidenceBars"
                [class.barsStatic]="
                    expert.expertData.workedWithOthers.length >= 9
                "
                style="height: 330px; margin-left: 0"
                [class.fit]="
                    vw() < 900 && expert.expertData.workedWithOthers.length < 6
                "
            >
                <div
                    class="barContainer"
                    *ngFor="let child of expert.expertData.workedWithOthers"
                >
                    <span
                        class="barTitle"
                        (ngChange)="checkWidth($event, child.valueA)"
                        (mouseover)="checkWidth($event, child.valueA)"
                        title=""
                        style="font-weight: bold; font-size: 12px"
                        [style.width]="
                            vw() <= 480
                                ? 'calc(100% - 170px)'
                                : vw() <= 900
                                ? '120px'
                                : '170px'
                        "
                        [style.paddingRight]="vw() <= 900 ? '20px' : '40px'"
                        >{{ child.valueA }}</span
                    >
                    <span
                        *ngIf="vw() > 480"
                        (ngChange)="checkWidth($event, child.valueB)"
                        (mouseover)="checkWidth($event, child.valueB)"
                        class="department tt"
                        title=""

                        [style.width]="
                            vw() <= 900
                                ? 'calc(100% - 290px)'
                                : 'calc(100% - 340px)'
                        "
                        [style.padding]="
                            vw() <= 900 ? '0 8px 0 0' : '0 28px 0 0'
                        "
                        >{{ child.valueB }}</span
                    >

                    <div class="barContent">
                        <div
                            class="bar"
                            style="border: solid 1px #b9646e"
                            title="{{ child.valueD }} {{
                                child.valueD > 1 ? 'times' : 'time'
                            }}"
                        >
                            <div
                                [style.background]="'#b9646e'"
                                [style.width]="
                                    normalize(
                                        child.valueD,
                                        expert.expertData.workedWithOthers[0]
                                            .valueD,
                                        120
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        child.valueD,
                                        expert.expertData.workedWithOthers[0]
                                            .valueD,
                                        120
                                    ) > 119
                                "
                            ></div>
                        </div>

                        <div class="email_container">
                            <a
                                *ngIf="child.valueC"
                                target="_blank"
                                href="mailto:{{ child.valueC }}"
                                title="{{ child.valueC }}"
                            >
                                <img
                                    src="assets/images/envelope-icon-dark.png"
                                    class="envelope_icon"
                                    [style.marginRight]="
                                        vw() <= 900 ? '8px' : '0'
                                    "
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="patents"
        *ngIf="
            expert.patentTitlesToURLs && expert.patentTitlesToURLs.length > 0
        "
        [class.onlyPatents]="
            !expert.expertData.children.length &&
            !expert.expertData.workedWithOthers.length &&
            !expert.expertData.allPublications.length &&
            !expert.expertData.matchingPublications.length
        "
    >
        <div class="title">
            <img
                src="assets/images/innovation_blue_dark.png"
                style="width: 32px; height: 32px"
            />
            <span
                style="padding-bottom: 1px"
                *ngIf="expert.patentTitlesToURLs.length === 1"
            >
                {{ expert.patentTitlesToURLs.length }} patent
            </span>
            <span
                style="padding-bottom: 1px"
                *ngIf="expert.patentTitlesToURLs.length > 1"
            >
                {{ expert.patentTitlesToURLs.length }} patents
            </span>
        </div>

        <div
            class="table"
            [class.short]="expert.patentTitlesToURLs.length === 1"
            [class.onlyPatentsTable]="
                !expert.expertData.children.length &&
                !expert.expertData.workedWithOthers.length &&
                !expert.expertData.allPublications.length &&
                !expert.expertData.allPublications.length
            "
        >
            <div class="patentsTable">
                <div
                    class="patent"
                    *ngFor="let patent of this.expert.patentTitlesToURLs"
                >
                    <span class="patentNumber">
                        {{ this.expert.patentTitlesToURLs.indexOf(patent) + 1 }}
                    </span>
                    <span class="patentName">
                        <a
                            href="{{ patent.valueB }}"
                            target="_blank"
                            *ngIf="patent.valueA.length <= 100"
                            >{{ patent.valueA }}</a
                        >
                        <a
                            href="{{ patent.valueB }}"
                            target="_blank"
                            data-toggle="tooltip"
                            title="{{ patent.valueA }}"
                            *ngIf="patent.valueA.length > 100"
                            >{{ patent.valueA }}</a
                        >
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div
        class="grants"
        *ngIf="
            expert.expertData.clinicalTrials &&
            expert.expertData.clinicalTrials.length > 0
        "
    >
        <div class="title" style="padding-bottom: 0" [class.pad]="vw() < 900">
            <img
                src="assets/images/clinical_trials_icon_dark.png"
                style="width: 29px; height: 42px"
            />
            <span
                style="width: 120px; line-height: 20px; margin-bottom: -2.5px"
                *ngIf="expert.expertData.clinicalTrials.length === 1"
            >
                {{ expert.expertData.clinicalTrials.length }} Clinical trial
                involvement</span
            >
            <span
                style="width: 120px; line-height: 20px; margin-bottom: -2.5px"
                *ngIf="expert.expertData.clinicalTrials.length > 1"
            >
                {{ expert.expertData.clinicalTrials.length }} Clinical trials
                involvement</span
            >
        </div>

        <div class="grantsList">
            <button
                (click)="previous('trialsContent')"
                [disabled]="checkPrevious('trialsContent')"
                [class.nv]="
                    vw() > 900
                        ? expert.expertData.clinicalTrials.length <= 3
                        : expert.expertData.clinicalTrials.length === 0
                "
            >
                <img
                    src="assets/images/previous_arrow-dark.png"
                    [class.disabled]="checkPrevious('trialsContent')"
                />
            </button>

            <div class="grantsContainer">
                <div
                    id="trialsContent"
                    [class.one]="expert.expertData.clinicalTrials.length === 1"
                    [class.two]="expert.expertData.clinicalTrials.length === 2"
                >
                    <div
                        class="grant trial"
                        *ngFor="let company of expert.expertData.clinicalTrials"
                        style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        "
                    >
                        <a
                            class="aEllipsis"
                            style="display: block; color: inherit; width: 100%"
                            target="_blank"
                            href="{{ company.url }}"
                            (ngChange)="checkWidth($event, company.nctid)"
                            (mouseover)="checkWidth($event, company.nctid)"
                            title=""
                        >
                            {{ company.nctid }}
                        </a>
                        <a
                            *ngIf="company.stage !== ''"
                            class="aEllipsis"
                            style="display: block; color: inherit; width: 100%"
                            target="_blank"
                            href="{{ company.url }}"
                            (ngChange)="checkWidth($event, company.stage)"
                            (mouseover)="checkWidth($event, company.stage)"
                            title=""
                        >
                            {{ company.stage }}
                        </a>
                    </div>
                </div>
            </div>

            <button
                (click)="next('trialsContent')"
                [disabled]="checkNext('trialsContent')"
                [class.nv]="
                    vw() > 900
                        ? expert.expertData.clinicalTrials.length <= 3
                        : expert.expertData.clinicalTrials.length === 0
                "
            >
                <img
                    src="assets/images/next_arrow-dark.png"
                    [class.disabled]="checkNext('trialsContent')"
                />
            </button>
        </div>
    </div>

    <div
        class="grants"
        *ngIf="
            expert.expertData.workedWithCompanies &&
            expert.expertData.workedWithCompanies.length > 0
        "
    >
        <div class="title" style="padding-bottom: 0" [class.pad]="vw() < 900">
            <img
                src="assets/images/building-dark.png"
                style="width: 29px; height: 31px; margin-top: 3px"
            />
            <span
                style="width: 120px; line-height: 20px; margin-bottom: -2.5px"
                *ngIf="expert.expertData.workedWithCompanies.length === 1"
            >
                Worked with
                {{ expert.expertData.workedWithCompanies.length }} company
            </span>
            <span
                style="width: 120px; line-height: 20px; margin-bottom: -2.5px"
                *ngIf="expert.expertData.workedWithCompanies.length > 1"
            >
                Worked with
                {{ expert.expertData.workedWithCompanies.length }} companies
            </span>
        </div>

        <div class="grantsList">
            <button
                (click)="previous('companiesContent')"
                [disabled]="checkPrevious('companiesContent')"
                [class.nv]="
                    vw() > 900
                        ? expert.expertData.workedWithCompanies.length <= 3
                        : expert.expertData.workedWithCompanies.length === 0
                "
            >
                <img
                    src="assets/images/previous_arrow-dark.png"
                    [class.disabled]="checkPrevious('companiesContent')"
                />
            </button>

            <div class="grantsContainer">
                <div
                    id="companiesContent"
                    [class.one]="
                        expert.expertData.workedWithCompanies.length === 1
                    "
                    [class.two]="
                        expert.expertData.workedWithCompanies.length === 2
                    "
                >
                    <div
                        class="grant"
                        *ngFor="
                            let company of this.expert.expertData
                                .workedWithCompanies
                        "
                    >
                        <div *ngIf="company === ellipsisCompany(company, 60)">
                            {{ ellipsisCompany(company, 60) }}
                        </div>
                        <span
                            title="{{ company }}"
                            *ngIf="company !== ellipsisCompany(company, 60)"
                        >
                            {{ ellipsisCompany(company, 60) }}
                        </span>
                    </div>
                </div>
            </div>

            <button
                (click)="next('companiesContent')"
                [disabled]="checkNext('companiesContent')"
                [class.nv]="
                    vw() > 900
                        ? expert.expertData.workedWithCompanies.length <= 3
                        : expert.expertData.workedWithCompanies.length === 0
                "
            >
                <img
                    src="assets/images/next_arrow-dark.png"
                    [class.disabled]="checkNext('companiesContent')"
                />
            </button>
        </div>
    </div>

    <div
        class="grants"
        *ngIf="expert.expertData.grants && expert.expertData.grants.length > 0"
    >
        <div class="title" style="padding-bottom: 0">
            <img
                src="assets/images/grants_dark.png"
                style="width: 29px; height: 31px"
            />
            <span
                style="width: 120px; line-height: 20px; margin-bottom: -2.5px"
                *ngIf="sumGrants(expert.expertData.grants) === 1"
            >
                {{ sumGrants(expert.expertData.grants) }} Grant awarded
            </span>
            <span
                style="width: 120px; line-height: 20px; margin-bottom: -2.5px"
                *ngIf="sumGrants(expert.expertData.grants) > 1"
            >
                {{ sumGrants(expert.expertData.grants) }} Grants awarded
            </span>
        </div>

        <div class="grantsList">
            <button
                (click)="previous('grantsContent')"
                [disabled]="checkPrevious('grantsContent')"
                [class.nv]="
                    vw() > 900
                        ? expert.expertData.grants.length <= 3
                        : expert.expertData.grants.length === 0
                "
            >
                <img
                    src="assets/images/previous_arrow-dark.png"
                    [class.disabled]="checkPrevious('grantsContent')"
                />
            </button>

            <div class="grantsContainer">
                <div
                    id="grantsContent"
                    [class.one]="expert.expertData.grants.length === 1"
                    [class.two]="expert.expertData.grants.length === 2"
                >
                    <div
                        class="grant"
                        *ngFor="let grant of this.expert.expertData.grants"
                    >
                        <div
                            *ngIf="
                                grant.fullGrantName +
                                    ' (' +
                                    grant.grantIds.length +
                                    ')' ===
                                ellipsis(grant)
                            "
                        >
                            {{ ellipsis(grant) }}
                        </div>
                        <span
                            title="{{ grant.fullGrantName }} ({{
                                grant.grantIds.length
                            }})"
                            *ngIf="
                                grant.fullGrantName +
                                    ' (' +
                                    grant.grantIds.length +
                                    ')' !==
                                ellipsis(grant)
                            "
                        >
                            {{ ellipsis(grant) }}
                        </span>
                    </div>
                </div>
            </div>

            <button
                (click)="next('grantsContent')"
                [disabled]="checkNext('grantsContent')"
                [class.nv]="
                    vw() > 900
                        ? expert.expertData.grants.length <= 3
                        : expert.expertData.grants.length === 0
                "
            >
                <img
                    src="assets/images/next_arrow-dark.png"
                    [class.disabled]="checkNext('grantsContent')"
                />
            </button>
        </div>
    </div>
</div>
