<div class="container">
    <div class="treebuteAdminContainer">
        <div class="row">
            <div>
                <h2>Google Client Tester</h2>
            </div>
            <div>Place: <input type="text" #place /></div>
            <div>Country: <input type="text" #country /></div>
            <div>
                <input
                    type="button"
                    class="btn btn-primary"
                    (click)="tryGoogleClient()"
                    value="Try Google Client"
                />
            </div>
        </div>
        <div class="row">
            <div>
                <h3>Result</h3>
            </div>
            <div>
                <textarea style="width: 100%" rows="5" #rawResult></textarea>
            </div>
            <div>
                <h3>Map</h3>
            </div>
            <div>
                <agm-map [latitude]="this.lat" [longitude]="this.lng"></agm-map>
            </div>
        </div>
    </div>
</div>
