<div class="col-md-6 offset-md-3" id="group-creation-modal">
    <h3>New Users Group</h3>
    <form #newUserGroupForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="name">Name</label>
            <input
                type="text"
                class="form-control"
                id="name"
                #name="ngModel"
                name="name"
                [(ngModel)]="model.name"
                required
            />
            <div [hidden]="name.valid" class="alert alert-danger">
                Name is required
            </div>
        </div>
        <div class="form-group">
            <label>Group type</label><br />
            <label class="radio-inline"
                ><input
                    name="type"
                    type="radio"
                    [(ngModel)]="model.type"
                    required
                    value="Lab"
                />Lab</label
            >
            <label class="radio-inline"
                ><input
                    name="type"
                    type="radio"
                    [(ngModel)]="model.type"
                    required
                    value="TTO"
                />TTO</label
            >
            <label class="radio-inline"
                ><input
                    name="type"
                    type="radio"
                    [(ngModel)]="model.type"
                    required
                    value="Faculty"
                />Faculty</label
            >
        </div>

        <div class="form-group">
            <button
                class="btn btn-primary"
                [disabled]="!newUserGroupForm.form.valid"
            >
                Create
            </button>
        </div>
    </form>
</div>
