import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { NetworkService } from '../../../network.service';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { DataService } from '../../../data.service';
import * as turf from '@turf/turf';
import { FloatingUpdateService } from '../../../floating-update.service';
import { Subscription } from 'rxjs';
import domtoimage from 'dom-to-image';

@Component({
    selector: 'app-world-map',
    templateUrl: './world-map.component.html',
    styleUrls: ['./world-map.component.scss'],
})
export class WorldMapComponent implements OnInit, OnDestroy {
    mapSubscription: Subscription;
    loading: boolean;
    companies;
    map: mapboxgl.Map;
    coordinates;
    center;
    bounds;
    min;
    max;

    @Output() mapLoaded = new EventEmitter();
    @Output() mapInit = new EventEmitter();

    constructor(
        private networkService: NetworkService,
        public dataService: DataService,
        private floatingUpdateService: FloatingUpdateService,
        private cdRef: ChangeDetectorRef,
    ) {
        this.mapSubscription = this.floatingUpdateService
            .getNotifiedCollMap()
            .subscribe(() => this.exportClicked());
    }

    ngOnInit() {
        this.mapInit.emit();
        this.loading = true;
        this.center = [
            this.dataService.userData.longitude,
            this.dataService.userData.latitude,
        ];
        this.bounds = [
            [-180, -85],
            [180, 85],
        ];

        this.networkService.getInstitutionCompanies().subscribe(
            (data) => {
                this.companies = data;
                this.min = 1000;
                this.max = -1;

                for (const company of this.companies) {
                    if (company.numberOfInteractions < this.min) {
                        this.min = company.numberOfInteractions;
                    }
                    if (company.numberOfInteractions > this.max) {
                        this.max = company.numberOfInteractions;
                    }
                }

                Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(
                    environment.mapbox.accessToken,
                );
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/zohar-nissim/ck1uh76gq49i11cqckncv7d9f',
                    zoom: 1,
                    maxBounds: this.bounds,
                    center: this.center,
                    renderWorldCopies: false,
                    preserveDrawingBuffer: true,
                    attributionControl: false
                });

                this.renderMap();
            },
            (error) => console.log(error),
        );
    }

    ngOnDestroy() {
        this.mapSubscription.unsubscribe();
    }

    normalize(value, NewMax, NewMin, OldMax, OldMin) {
        if (OldMax <= OldMin) {
            return (NewMax + NewMin) / 2;
        }
        return (
            ((value - OldMin) * (NewMax - NewMin)) / (OldMax - OldMin) + NewMin
        );
    }

    exportClicked() {
        const elem = document.querySelector(
            '.mapboxgl-canvas-container',
        ) as HTMLElement;
        elem.style.height = '100%';

        domtoimage
            .toPng(document.querySelector('.mapboxgl-canvas-container'), {
                quality: 1,
                bgcolor: '#ffffff',
            })
            .then((dataUrl) => {
                this.exportRequest(dataUrl);
            })
            .catch((error) => {
                console.log(error);
                this.exportRequest();
            });
    }

    exportRequest(dataUrl = null) {
        const date = new Date();

        this.networkService
            .exportCollMap(this.companies, dataUrl, date)
            .subscribe(
                (data) => {
                    this.floatingUpdateService.notifyExportFinished();
                    this.cdRef.detectChanges();
                    const options = { type: 'application/ms-excel' };
                    const filename = 'collaborations.xlsx';
                    this.createAndDownloadBlobFile(data, options, filename);
                },
                (error) => {
                    this.floatingUpdateService.notifyExportFinished();
                    this.cdRef.detectChanges();
                    console.log(error);
                },
            );
    }

    createAndDownloadBlobFile(body, options, filename) {
        const blob = new Blob([body], options);
            // Browsers that support HTML5 download attribute
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
    }

    renderMap() {
        const self = this;

        // Mark the others

        // Creating an SVG
        function createCircle(props) {
            const fontSize = 10;
            const r = self.normalize(
                props.numberOfInteractions,
                15,
                8,
                self.max,
                self.min,
            );

            const r0 = Math.round(r * 0.6);
            const w = r * 2;
            const strokeWidth = 3;

            let html =
                '<svg width="' +
                w +
                '" height="' +
                w +
                '" viewbox="0 0 ' +
                w +
                ' ' +
                w +
                '" text-anchor="middle" style="font: ' +
                fontSize +
                'px sans-serif">';

            const a = self.normalize(
                props.numberOfInteractions,
                100,
                0,
                self.max,
                self.min,
            );
            const companyColor = `rgb(${155 + a}, 90, 78)`;
            html +=
                `<circle cx="${r}" cy="${r}" r="${r0}" fill="${companyColor}" ` +
                `stroke="${companyColor}" stroke-width="${strokeWidth}px"/></svg>`;

            const el = document.createElement('div');
            el.innerHTML = html;
            return el.firstChild;
        }

        // Objects for caching and keeping track of HTML marker objects (for performance)
        const markers = {};
        let markersOnScreen = {};

        function updateMarkers() {
            // For every cluster on the screen, create an HTML marker for it (if we didn't yet),
            // and add it to the map if it's not there already
            const newMarkers = {};

            for (let i = 0; i < self.companies.length; i++) {
                if (self.companies[i].latitude && self.companies[i].longitude) {
                    const coords = [
                        self.companies[i].longitude,
                        self.companies[i].latitude,
                    ];
                    const props = self.companies[i];
                    let marker = markers[i];

                    if (!marker) {
                        const el1 = createCircle(props);

                        const elx = document.createElement('div');
                        elx.className = 'marker';
                        elx.appendChild(el1);

                        marker = markers[i] = new mapboxgl.Marker(elx);
                        marker.setLngLat(coords);
                        marker.setPopup(
                            // tslint:disable:max-line-length
                            new mapboxgl.Popup({ offset: 25 }) // Add popups
                                .setHTML(`<h3 style="font-size: 18px; background: rgb(46, 131, 205); color: #fff; margin: -10px -10px 0; display: block; padding: 10px; letter-spacing: 0.71px; font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol; text-transform: uppercase; font-weight: 500;">${
                                self.companies[i].name
                            }</h3>
                            <p style="font-size: 13px; margin: 0 -10px -15px; display: block; padding: 5px 10px; color: rgb(46, 131, 205); letter-spacing: 0.6px; font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol; font-weight: 400;">${
                                self.companies[i].numberOfInteractions
                            } ${
                                self.companies[i].numberOfInteractions > 1
                                    ? 'Interactions'
                                    : 'Interaction'
                            }</p>`),
                            // tslint:enable:max-line-length
                        );
                    }

                    newMarkers[i] = marker;

                    if (!markersOnScreen[i]) {
                        marker.addTo(self.map);
                    }
                }
            }

            // For every marker we've added previously, remove those that are no longer visible
            for (const id in markersOnScreen) {
                if (!newMarkers[id]) {
                    markersOnScreen[id].remove();
                }
            }

            markersOnScreen = newMarkers;
        }

        // Mark the others - end

        // Mark myself - start

        const size = 75;
        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            onAdd: function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            render: function () {
                const duration = 1000;
                const t = (performance.now() % duration) / duration;
                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * t + radius;
                const context = this.context;

                // Draw outer circle
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2,
                );
                context.fillStyle = 'rgba(232, 90, 78,' + (1 - t) + ')';
                context.fill();

                // Draw inner circle
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2,
                );
                context.fillStyle = 'white';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // Update this image's data with data from the canvas
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height,
                ).data;

                // Keep the map repainting
                self.map.triggerRepaint();

                // Return `true` to let the map know that the image was updated
                return true;
            },
        };

        // Mark myself - end

        // Draw it all

        this.map.on('load', drawMap);

        this.map.on('idle', () => this.mapLoaded.emit());

        function drawMap() {
            const layer: mapboxgl.Layer = {
                id: 'points',
                type: 'symbol',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'Point',
                                    coordinates: self.center,
                                },
                            },
                        ],
                    },
                },
                layout: {
                    'icon-image': 'pulsingDot',
                },
            };

            //self.map.addLayer(layer);

            self.map.addImage('pulsingDot', pulsingDot, { pixelRatio: 2 });

            // Create lines between myself and the others - start

            drawArcs();

            function drawArcs() {
                self.companies.forEach((element) => {
                    if (
                        element.longitude &&
                        element.latitude &&
                        element.name &&
                        element.numberOfInteractions
                    ) {
                        const origin = self.center;
                        const destination = [
                            element.longitude,
                            element.latitude,
                        ];

                        // A simple line from origin to destination
                        const line: turf.LineString = {
                            type: 'LineString',
                            coordinates: [origin, destination],
                        };

                        const feature: turf.AllGeoJSON = {
                            type: 'Feature',
                            properties: {},
                            geometry: line,
                        };

                        const route: mapboxgl.AnySourceData = {
                            type: 'geojson',
                            data: {
                                type: 'FeatureCollection',
                                features: [
                                    {
                                        type: 'Feature',
                                        properties: {},
                                        geometry: line,
                                    },
                                ],
                            },
                        };

                        // Calculate the distance in kilometers between route start/end point
                        const lineDistance = turf.length(feature, {
                            units: 'miles',
                        });

                        const arc = [];

                        // Number of steps to use in the arc and animation
                        const steps = 10000;

                        // Draw an arc between the `origin` & `destination` of the two points
                        for (
                            let i = lineDistance / steps;
                            i <= lineDistance + lineDistance / steps;
                            i += lineDistance / steps
                        ) {
                            const segment = turf.along(line, i, {
                                units: 'miles',
                            });
                            arc.push(segment.geometry.coordinates);
                        }

                        // Update the route with calculated arc coordinates
                        line.coordinates = arc;
                        const tr = self.normalize(
                            element.numberOfInteractions,
                            1,
                            0.5,
                            self.max,
                            self.min,
                        );
                        const color = `rgba(255,255,255,${tr})`;
                        self.map.addSource(`route${element.name}`, route);

                        self.map.addLayer({
                            id: `route${element.name}`,
                            source: `route${element.name}`,
                            type: 'line',
                            paint: {
                                'line-width': 1.5,
                                'line-color': color,
                            },
                        });
                    }
                });
            }

            // Create lines between myself and the others - end
        }

        updateMarkers();

        // Draw it all - end

        setTimeout(() => {
            self.loading = false;
            this.map.resize();
        }, 1);
    }
}
