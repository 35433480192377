<div class="search-section" [class.disable-when-loading]="loading">
    <div>
        <div class="tools">
            <div class="internal-container">
                <div class="tool lovely-text">
                    Tools:
                </div>
                <div class="tool">
                    <div class="toggle-enable-disable-container">
                        <button class="btn-secondary short-button" type="button" (click)="excludeAll.emit($event)"
                                [hidden]="dataService.hideShared()">Exclude All
                        </button>
                        <button class="btn-secondary short-button" type="button" (click)="includeAll.emit($event)"
                                [hidden]="dataService.hideShared()">Include All
                        </button>
                        <button *ngIf="showAddButton"
                                [hidden]="dataService.hideShared()"
                                (click)="addClick()" class="btn-secondary short-button"> + Add startup</button>
                        <button *ngIf="showExpertsTool()"
                                (click)="navToExpertsTool()" class="btn-secondary short-button"> experts tool </button>
                        <button *ngIf="showExpertsTool()"
                                (click)="navToCtTool()" class="btn-secondary short-button"> clinical trials tool </button>
                    </div>
                </div>
                <div class="tool">
                    <div class="toggle-enable-disable-container">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
