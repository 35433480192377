<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title"> {{ data.title }} </div>
    </div>
    <div class="line">
    </div>
    <div class="search-tool-container">
        <div class="result-box">
            <div class="chart-and-filter-container">
                <div class="filter-wrapper">
                    <div class="filter-tools-container">
                        <div>
                    <span>
                        <a (click)="selectAll()">Select All</a>
                    </span>
                            &nbsp;&nbsp;&nbsp;
                            <span>
                        <a (click)="selectNone()">Select None</a>
                    </span>
                        </div>
                        <div>
                            <input [(ngModel)]="textFilter"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-container">
        <div>
            <div *ngFor="let selectedItem of getSelectedItems()">
                <input class="selection" type="checkbox" [(ngModel)]="selectedItem.selected"/>
                <span class="text-capitalize">{{selectedItem.displayText}} ({{ selectedItem.num }})</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <div>
            <button class="btn btn-dark border text-light" (click)="closeModal()">
                Cancel
            </button>
            </div>
            <div>

            </div>
            <div>
            <button class="btn btn-primary" (click)="filter()" [disabled]="!anythingSelected()">
                Filter
            </button>
            </div>
        </div>
    </div>
</div>
