<div class="container">
    <div class="vcContainer row mt-1" style="margin-top: 10px">
        <div>
            <h2>Add a single VC:</h2>
        </div>
        <div>
            Name:
            <input
                #vcName
                type="text"
                value=""
                style="width: 80%; margin-right: 5px"
            />
        </div>
        <div>
            Profile Url:
            <input
                #url
                type="text"
                value=""
                style="width: 80%; margin-right: 5px"
            />
        </div>
        <div>
            Is Aggregator?: <input #isAggregator type="checkbox" [checked]="" />
        </div>
        <div>
            Country:
            <select #countrySelect [value]="'israel'">
                <option
                    [ngValue]="country.name.toLowerCase()"
                    *ngFor="let country of countries"
                    [value]="country.name.toLowerCase()"
                >
                    {{ country.name.toLowerCase() }}
                </option>
            </select>
        </div>
        <div>
            Crawl Depth:
            <select #crawlDepthSelect id="crawlDepthSelectId" [value]="1">
                <option [value]="0">Single Page</option>
                <option [value]="1">Index Page</option>
                <option [value]="2">All In</option>
            </select>
        </div>
        <div>
            <input
                (click)="
                    addSingleVC(
                        vcName.value,
                        url.value,
                        countrySelect.value,
                        isAggregator.checked,
                        crawlDepthSelect.value
                    )
                "
                type="button"
                value="Add VC"
                class="button btn-success"
            />
        </div>
    </div>
    <div class="vcContainer row mt-1" style="margin-top: 10px">
        <h2>Add multiple VCs</h2>
        <div style="margin-top: 25px">
            <textarea #vcsText rows="30" style="width: 100%"></textarea>
        </div>
        <div>
            <input
                (click)="addMultiVCs(vcsText.value)"
                type="button"
                value="Add VC List"
                class="button btn-success"
            />
        </div>
    </div>
</div>
