import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Callout, CalloutCandidate} from "../pages/callout/callouts/callout.interface";
import {Researcher} from "../api-responses/SearchQueryResults.interface";

@Injectable({
    providedIn: 'root',
})
export class Callouts implements OnInit, OnDestroy {

    constructor(private http: HttpClient) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    getById(id: number) {
        return this.http.get<Callout>(`/api/v1/callouts/${id}`).toPromise();
    }

    getAll() {
        return this.http.get<Callout[]>(`/api/v1/callouts/getAll`).toPromise();
    }

    create(data: { data: boolean; advise: boolean; project: boolean; description: string; title: string }) {
        let c = {
            advise: data.advise,
            data: data.data,
            project: data.project,
            title: data.title,
            description: data.description
        } as Callout;

        return this.http.post<Callout>(`/api/v1/callouts`, c).toPromise();
    }

    update(callout: Callout) {
        const payload: Callout = JSON.parse(JSON.stringify(callout));
        payload.calloutCandidates = undefined;
        return this.http.put(`/api/v1/callouts/${callout.id}`, payload ).toPromise();
    }

    del(callout: Callout) {
        return this.http.delete(`/api/v1/callouts/${callout.id}`).toPromise();
    }

    addCandidate(callout: Callout, researcher: Researcher) {
        return this.http.post(`/api/v1/callouts/${callout.id}/addCandidate`, researcher).toPromise();
    }

    removeCandidate(calloutCandidate: CalloutCandidate) {
        return this.http.delete(`/api/v1/callouts/removeCandidate/${calloutCandidate.id}`).toPromise();
    }

    updateCandidate(calloutCandidate: CalloutCandidate) {
        return this.http.put(`/api/v1/callouts/updateCandidate/`, calloutCandidate).toPromise();
    }

    publish(callout: Callout, publish: boolean) {
        return this.http.post(`/api/v1/callouts/${callout.id}/publish`, publish).toPromise();
    }

}
