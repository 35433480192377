import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InnovationProject} from "../../../../../../api-responses/InnovationProjects.interface";
import {SelectedField} from "../../../../../../api-responses/graphs.interface";
import {DataService} from "../../../../../../data.service";
import {NetworkService} from "../../../../../../network.service";
import {MatDialog} from "@angular/material/dialog";
import {ChartFilterModalData} from "../../../../../../api-responses/Dialogs.interface";
import {ChartFilterModalComponent} from "../../chart-filter-modal/chart-filter-modal.component";

@Component({
    selector: 'experts-background-chart-container',
    templateUrl: './experts-background-chart-container.component.html',
    styleUrls: ['./experts-background-chart-container.component.scss']
})
export class ExpertsBackgroundChartContainerComponent implements OnInit {

    @Input() project: InnovationProject;
    @Input() showTools:boolean = true;

    @Input() selectedExpertsBackground: SelectedField[] = [];
    @Output() selectedExpertsBackgroundChange = new EventEmitter<SelectedField[]>();

    constructor(public dataService: DataService,
                public networkService: NetworkService,
                public matDialog: MatDialog) {
    }

    ngOnInit() {
    }


    openExpertsBackgroundFilter() {
        let dialogRef = this.matDialog.open(ChartFilterModalComponent, {data: {
                items: this.selectedExpertsBackground,
                title: "Filter by Expert Background"
            } as ChartFilterModalData });

        dialogRef.afterClosed()
            .toPromise()
            .then((data) => {
                if (!data) return;
                this.selectedExpertsBackground = data.selectedItems;
                this.selectedExpertsBackgroundChange.emit(this.selectedExpertsBackground);
            })
    }

    getExpertsBackgroundDescription() {
        if (this.selectedExpertsBackground.filter(x => !x.selected).length === 0){
            return "Showing All Backgrounds";
        }
        else {
            let categories = this.selectedExpertsBackground.filter(x => x.selected).map(x => x.displayText).join(", ");
            return `Showing ${categories}`;
        }
    }

    getLink() {
        return `/app/projects/${this.project.id}/experts-tool`;
    }
}
