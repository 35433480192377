import { Component, Inject, OnInit, OnDestroy, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.css']
})
export class LoadingDialogComponent implements OnInit {

  public cancelSearchRequest : boolean = false;
  public messages: string[] = [];
  public finalMessage: string = '';

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data : {message : string}
  ) {
    dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  updateMessage(message : string){
    let msgs = message.split("\n");
    for (const msg of msgs) {
      if (this.messages.indexOf(msg) === -1) {
        this.messages.push(msg);
      }
    }

    this.finalMessage = this.messages.join("\n");
    let textArea = document.getElementsByClassName("message")[0];
    textArea.scrollTop = textArea.scrollHeight;
  }

  cancelSearch(){
    this.cancelSearchRequest = true;
  }

}
