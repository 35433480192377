import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {TreebuteProjectSummaryReducer} from "../../../../utils/treebuteProjectSummaryReducer";

@Component({
    selector: 'app-search-filter-researcher-ct',
    templateUrl: './search-filter-researcher-ct.component.html',
    styleUrls: ['./search-filter-researcher-ct.component.scss']
})
export class SearchFilterResearcherCtComponent implements OnInit, OnChanges {

    @Input() searchResults!: SearchQueryResults;
    @Output() searchResultsChange = new EventEmitter<SearchQueryResults>();

    expertPhaseOption: 'All Phases';
    expertPhaseOptions: string[] = [];

    expertStatusOption: 'All Statuses';
    expertStatusOptions: string[] = [];

    constructor() {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResults.currentValue && this.valid()) {
            this.searchResults && this.searchResults.expertSearchResult.researchers.forEach((expert) => {
                expert.clinicalTrialPhases.forEach((phase) => {
                    if (this.expertPhaseOptions.indexOf(phase) == -1) {
                        this.expertPhaseOptions.push(phase);
                    }
                });

                expert.clinicalTrialStatus.forEach((status) => {
                    if (this.expertStatusOptions.indexOf(status) == -1) {
                        this.expertStatusOptions.push(status);
                    }
                });
            });

            this.expertPhaseOptions = ['All Phases', ...this.expertPhaseOptions.sort()];
            this.expertStatusOptions = ['All Statuses', ...this.expertStatusOptions.sort()];

            this.expertPhaseOption = changes.searchResults.currentValue.filterCtPhase; // 'All Phases';
            this.expertStatusOption = changes.searchResults.currentValue.filterCtStatus; //'All Statuses';
        }
    }

    selectExpertPhaseOption() {
        this.searchResults.filterCtPhase = this.expertPhaseOption;
        this.searchResultsChange.emit(this.searchResults);
    }

    selectExpertStatusOption() {
        this.searchResults.filterCtStatus = this.expertStatusOption;
        this.searchResultsChange.emit(this.searchResults);
    }

    valid() {
        return this.searchResults && TreebuteProjectSummaryReducer.isCtExperts(this.searchResults);
    }

}
