import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService } from '../../network.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilitiesService } from '../../utilities.service';

@Component({
    selector: 'app-machines',
    templateUrl: './machines.component.html',
    styleUrls: ['./machines.component.scss'],
})
export class MachinesComponent implements OnInit, OnDestroy {
    machines;
    searchString;
    selectedMachine;
    showModal = false;
    showBookingModal = false;
    loading = false;
    noResults = false;
    enableNextMachine: boolean;
    enablePreviousMachine: boolean;
    previousLoading = false;
    nextLoading = false;
    private _routerSub = Subscription.EMPTY;

    constructor(
        private networkService: NetworkService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this._routerSub = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const searchString =
                        this.route.snapshot.params['searchString'];
                    this.searchString =
                        searchString.length >= 3 ? searchString : '';
                    if (this.searchString !== '') {
                        this.onSearchSubmit();
                    }
                }
            });
    }

    ngOnInit() {}

    ngOnDestroy() {
        this._routerSub.unsubscribe();
    }

    onSearchSubmit() {
        this.noResults = false;
        this.loading = true;
        this.networkService
            .getMachines(this.searchString, false, undefined, false)
            .subscribe(
                (data) => {
                    this.machines = data;
                    this.machines = this.machines.valueA;
                    this.loading = false;
                    this.noResults = this.machines.length === 0;
                },
                (error) => console.log(error),
            );
    }

    open(machine) {
        this.selectedMachine = machine;
        this.showModal = true;
        this.nextLoading = false;
        this.previousLoading = false;

        const researchers = [];
        for (const r of this.selectedMachine.contacts) {
            if (r.email == null || r.email === '') {
                researchers.push(r.name);
            }
        }

        if (researchers.length > 0) {
            this.networkService
                .findInstanceResearcherEmails(
                    this.selectedMachine.instanceId,
                    this.selectedMachine.institution.name,
                    researchers,
                )
                .subscribe(
                    (data) => this.handleFoundEmails(data, researchers),
                    (error) => console.log(error),
                );
        }

        this.checkMachineSwitch();
    }

    handleFoundEmails(data, researchers) {
        for (const r of this.selectedMachine.contacts) {
            if (researchers.includes(r.name)) {
                r.email = data.researcherToEmailMap[r.name];
            }
        }
    }

    close() {
        this.showModal = false;
    }

    openBookingModal(machine) {
        this.selectedMachine = machine;
        this.showBookingModal = true;
    }

    closeBookingModal() {
        this.showBookingModal = false;
    }

    isListOverflow() {
        const elem = document.getElementById('machinesList');
        return elem.offsetWidth !== elem.scrollWidth;
    }

    checkMachineSwitch() {
        this.machines.forEach((machine) => {
            if (machine === this.selectedMachine) {
                this.enableNextMachine =
                    this.machines.indexOf(machine) + 1 <=
                    this.machines.length - 1;
                this.enablePreviousMachine =
                    this.machines.indexOf(machine) - 1 >= 0;
            }
        });
    }

    switchMachine(side) {
        this.machines.forEach((machine) => {
            if (machine === this.selectedMachine) {
                if (side === 'next') {
                    this.nextLoading = true;
                    setTimeout(() => {
                        this.showModal = false;
                        this.open(
                            this.machines[this.machines.indexOf(machine) + 1],
                        );
                    });
                } else if (side === 'previous') {
                    this.previousLoading = true;
                    setTimeout(() => {
                        this.showModal = false;
                        this.open(
                            this.machines[this.machines.indexOf(machine) - 1],
                        );
                    });
                }
            }
        });
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }
}
