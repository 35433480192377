import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
// import {NavbarComponent} from "../../components/navbar/navbar.component";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {CanvasListPageComponent} from "./canvas-list-page/canvas-list-page.component";
import {CanvasPageComponent} from "./canvas-page/canvas-page.component";
import {MarkdownModule} from "ngx-markdown";
import {CanvasProjectPreviewComponent} from "./canvas-project-preview/canvas-project-preview.component";
import {ProjectsModule} from "../projects-page/projects.module";
import {SharedCanvasPageComponent} from "./shared-canvas-page/shared-canvas-page.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {CanvasHtmlUpdatePageComponent} from "./canvas-html-update/canvas-html-update-page.component";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {CanvasRoutingModule} from "./canvas-routing.module";

@NgModule({
    imports: [
        MatNativeDateModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        CommonModule,
        SharedModule,
        MatProgressBarModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        MarkdownModule,
        ProjectsModule,
        MatDatepickerModule,
        ClipboardModule,
        CanvasRoutingModule

    ],
    declarations: [
        CanvasListPageComponent,
        CanvasPageComponent,
        CanvasHtmlUpdatePageComponent,
        CanvasProjectPreviewComponent,
        SharedCanvasPageComponent
    ],
    exports: [
        MatProgressSpinnerModule
    ],
    providers: []
})
export class CanvasModule {}
