<div *ngIf="valid()" class="filter-container">
    <div>
        <mat-form-field class="location-select" appearance="fill">
            <mat-select [(value)]="expertPhaseOption"
                        (selectionChange)="selectExpertPhaseOption()">
                <mat-option
                        *ngFor="let expertPhaseOption of expertPhaseOptions"
                        [value]="expertPhaseOption"
                >{{ expertPhaseOption }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="location-select" appearance="fill">
            <mat-select [(value)]="expertStatusOption"
                        (selectionChange)="selectExpertStatusOption()">
                <mat-option
                        *ngFor="let expertStatusOption of expertStatusOptions"
                        [value]="expertStatusOption"
                >{{ expertStatusOption }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
