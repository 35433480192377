import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FloatingUpdateService {
    private subject = new Subject<any>();
    private subjectApp = new Subject<any>();
    private subjectCollMap = new Subject<any>();
    private subjectExportFinished = new Subject<any>();
    private subjectUrlUpdated = new Subject<any>();
    private wordSuggestionUpdated = new Subject<any>();
    public wordSuggestion = undefined;

    notify() {
        this.subject.next();
    }

    notifyApp() {
        this.subjectApp.next();
    }

    notifyCollMap() {
        this.subjectCollMap.next();
    }

    notifyExportFinished() {
        this.subjectExportFinished.next();
    }

    notifyUrlUpdated() {
        this.subjectUrlUpdated.next();
    }

    notifyWordSuggestionUpdated(wordSubject) {
        this.wordSuggestion = wordSubject;
        this.wordSuggestionUpdated.next();
    }

    getNotified(): Observable<any> {
        return this.subject.asObservable();
    }

    getNotifiedApp(): Observable<any> {
        return this.subjectApp.asObservable();
    }

    getNotifiedCollMap(): Observable<any> {
        return this.subjectCollMap.asObservable();
    }

    getNotifiedExportFinished(): Observable<any> {
        return this.subjectExportFinished.asObservable();
    }

    getNotifiedUrlUpdated(): Observable<any> {
        return this.subjectUrlUpdated.asObservable();
    }

    getWordSuggestionUpdated(): Observable<any> {
        return this.wordSuggestionUpdated.asObservable();
    }
}
