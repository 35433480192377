<div style="display: flex; flex-direction: column">
    <h3>Machine Parameters:</h3>
    <form (ngSubmit)="onSearchSubmit()" style="margin-bottom: 20px">
        <div class="form-group">
            <textarea
                [(ngModel)]="queryName"
                name="name"
                class="form-control"
                rows="1"
                id="investor-name"
                placeholder="Investor Name"
            ></textarea>
        </div>
        <button class="btn btn-primary">Search</button>
        <button (click)="createInvestor()" class="btn btn-primary">
            Create new
        </button>
        <h3>{{ message }}</h3>
    </form>

    <div class="col-8">
        <div *ngFor="let investor of investors">
            <button
                type="button"
                (click)="showInvestorDetails(investor)"
                class="btn btn-primary btn-sm"
            >
                {{ investor.name }}, {{ investor.country }}
            </button>
        </div>
    </div>

    <div
        style="display: flex; justify-content: space-between; margin-left: 20px"
    >
        <app-investor-editor
            *ngIf="showInvestor"
            [investor]="selectedInvestor"
            (investorDelete)="onSearchSubmit()"
        ></app-investor-editor>
    </div>
</div>
