import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-admin-search-calibrator',
    templateUrl: './admin-search-calibrator.component.html',
    styleUrls: ['./admin-search-calibrator.component.scss'],
})
export class AdminSearchCalibratorComponent implements OnInit {
    constructor(private networkService: NetworkService) {}

    query: string;
    results: any;
    searchFor = 'ARTICLES';
    domain = 'BIOMED';

    wordCloud: any;
    showWordCloud = false;

    ngOnInit() {}

    onSearchSubmit() {
        this.wordCloud = undefined;
        this.showWordCloud = false;
        this.networkService
            .getAdminSearchQuery(this.query, this.domain, this.searchFor)
            .subscribe((data) => this.handleSearchResult(data));
    }

    updateDomain(domain) {
        this.domain = domain;
    }

    updateSearchFor(searchFor) {
        this.searchFor = searchFor;
    }

    handleSearchResult(data) {
        this.results = data.valueA;
        this.wordCloud = data.valueB;
    }

    openArticle(url) {
        window.open(url, '_blank');
    }

    flipWordCloud() {
        this.showWordCloud = !this.showWordCloud;
    }
}
