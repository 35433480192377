import {
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NetworkService } from '../../../../network.service';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FloatingUpdateService } from '../../../../floating-update.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../../../utilities.service';
import { CompanyModalService } from './company-modal.service';
import {UtilsComponent} from "../../../../utils/utils.component";
import {Institution} from "../../../../api-responses/SearchQueryResults.interface";

@Component({
    selector: 'app-company-modal',
    templateUrl: './company-modal.component.html',
    styleUrls: ['./company-modal.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
})
export class CompanyModalComponent implements OnInit, OnDestroy {
    loading = false;
    searchString;
    wordCloud: any;
    noResults = false;
    showModal = false;
    selectedCompany = undefined;
    enableNextCompany = false;
    enablePreviousCompany = false;
    isDropdownOpen = false;
    exportLoading = false;
    selectedCountry = 'World Wide';
    allCompanies = true;
    countries = [];
    search = '';
    feedbackOpen = false;
    private fromDataToModal = Subscription.EMPTY;
    private selectedIndex: number = 0;

    constructor(
        public dataService: DataService,
        public sanitizer: DomSanitizer,
        public companyModelService: CompanyModalService,
        public utilsComponent: UtilsComponent
    ) {}

    ngOnInit() {
        this.fromDataToModal =
            this.companyModelService.selectedDataObservable.subscribe(
                (message) => {
                    this.selectedCompany = message.company;
                    this.selectedIndex = message.index;
                    this.enableNextCompany = message.enableNextCompany;
                    this.enablePreviousCompany = message.enablePreviousCompany;
                },
            );
    }

    ngOnDestroy() {
        this.fromDataToModal.unsubscribe();
    }

    searchDisabled() {
        return this.searchString === undefined || this.searchString.length < 3;
    }

    closeModal() {
        this.companyModelService.changeDesiredIndex(-1);
    }

    nextCompany() {
        this.companyModelService.changeDesiredIndex(this.selectedIndex + 1);
    }

    openTwitter(company) {
        const twitterName = company.twitterName;
        window.open('https://twitter.com/' + twitterName, '_blank');
    }

    previousCompany() {
        if (this.selectedIndex - 1 < 0) return;
        this.companyModelService.changeDesiredIndex(this.selectedIndex - 1);
    }

    getNumberOfEmployees(employees) {
        if (employees < 10) {
            return '1-10';
        } else if (employees < 25) {
            return '10-25';
        } else if (employees < 50) {
            return '25-50';
        } else if (employees < 100) {
            return '50-100';
        } else if (employees < 175) {
            return '100-175';
        } else if (employees < 250) {
            return '175-250';
        } else {
            return '250+';
        }
    }

    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    isLimited(index) {
        return (
            UtilitiesService.isLimited(this.dataService.userData) &&
            index >= this.dataService.userData.limitedUserMaxResultsSize
        );
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }


    hasAdditionalGrants(selectedCompany: Institution) {
        return selectedCompany.resources && selectedCompany.resources.filter(x => x.valueC === 'ADDITIONAL_GRANT').length > 0
    }

    getAdditionalGrants(selectedCompany: Institution) {
        return selectedCompany.resources.filter(x => x.valueC === 'ADDITIONAL_GRANT');
    }

    getResources(selectedCompany: any) {
        return selectedCompany.resources.filter(x => x.valueC !== 'ADDITIONAL_GRANT');
    }
}
