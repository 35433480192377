<div class="projects-page general-page-container">
    <div class="loader-panel" [class.hiddenProgressBar]="!loading && !toggleView">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="header" *ngIf="!this.loading">
        <h3 class="header__title">Innovation Projects</h3>
        <div class="header__button" (click)="openNewProjectDialog()" id="createNewProjectButton">
            <div class="header__button-icon white-plus-icon"></div>
            <div class="header__button-title">Create new project</div>
        </div>
    </div>
    <div class="tools-container" *ngIf="!this.loading" [hidden]="!projects || projects.length === 0">
        <div class="tool-container" [hidden]="!bundleOptions.length || bundleOptions.length === 1">
            <mat-form-field appearance="fill">
                <mat-select [(value)]="bundleOption"
                            (selectionChange)="onBundleChange()" (change)="onBundleChange()">
                    <mat-option
                            *ngFor="let option of bundleOptions"
                            [value]="option"
                            style="text-transform: capitalize"
                    >{{ option }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <div class="filter tool-container">
            <div style="flex: 1">
                <input type="checkbox" [(ngModel)]="listView" (ngModelChange)="toggleListView($event)"/>
                <span class="lovely-text">List View</span>
            </div>
        </div>
        <div class="filter tool-container">
            <input id="projectsFilterInput"
                    matInput
                   appearance="fill"
                   type="text"
                   placeholder="type to filter... 🔍"
                   [(ngModel)]="textFilter"
                   (input)="filterByText()"/>
        </div>
    </div>
    <div class="recent" *ngIf="!this.loading">
        <div class="recent-header" [hidden]="true">
            <div routerLink="/app/search" class="search-icon"></div>
            <mat-form-field appearance="fill">
                <mat-select [(value)]="option">
                    <mat-option
                            *ngFor="let option of options"
                            [value]="option"
                    >{{ option }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <div class="visual-container" [class.togglingView]="toggleView">
            <div class="list-view" [hidden]="!listView">
                <table mat-table matSort [dataSource]="dataSource" #sort
                       (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="name-column" [routerLink]="'/app/projects/' + element.id">
                            <div
                               (mousedown)="navigateToProjTargetBlank($event, element.id)">
                                {{ element.name }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="searchCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Queries
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.searchCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="patentCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Patents
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.patentCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ctCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #CTs
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.ctCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="publicationCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Pubs
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.publicationCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="grantCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Grants
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.grantCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="startupCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Startups
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.startupCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="corporateCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Corporates
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.corporateCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ctExpertCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #CT-Experts
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.ctExpertCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="academicExpertCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Academic-Experts
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.academicExpertCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="industryExpertCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Industry-Experts
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.industryExpertCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="patentExpertCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            #Inventors
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.patentExpertCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef="totalExpertCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            &Sigma; Experts
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.totalExpertCount}}&nbsp;
                            <a style="cursor: pointer; text-decoration: underline" *ngIf="!!element.totalExpertCount"
                               (mousedown)="navigateToProjTargetBlank2($event, element.id)">
                                explore...
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" #paginator
                               showFirstLastButtons
                               aria-label="Select page of periodic elements">
                </mat-paginator>
            </div>
            <div class="cards-view" *ngIf="!listView">
                <ng-container>
                    <div class="recent-projects recent-projects-top">
                        <app-empty-project-card></app-empty-project-card>
                        <div *ngFor="let project of userProjects">
                            <app-project-card
                                    [project]="project"
                            ></app-project-card>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="shared-projects">
            <div class="header" *ngIf="!this.loading">
                <h3 class="header__title">Shared Projects</h3>
            </div>

            <ng-container *ngIf="sharedProjects.length > 0">
                <div class="recent-projects">
                    <div *ngFor="let project of sharedProjects"
                       >
                        <app-project-card
                                [project]="project"
                        ></app-project-card>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>
