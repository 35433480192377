<div class="menu-wrapper">
    <div class="arrow-up"></div>
    <div class="account-options">
        <div class="account-options__info">
            <div class="avatar">
                <div class="avatar__profile">
                    {{
                        (
                            this.dataService.userInfo$ | async
                        )?.firstName[0]?.toUpperCase()
                    }}
                </div>
                <div class="avatar__title">
                    Hello,
                    {{
                        (
                            this.dataService.userInfo$ | async
                        )?.firstName[0]?.toUpperCase() +
                            (
                                this.dataService.userInfo$ | async
                            )?.firstName.slice(1)
                    }}
                    {{ (this.dataService.userInfo$ | async)?.lastName }}
                </div>
            </div>
        </div>
        <div class="breakline" [hidden]="true"></div>
        <div class="account-options__settings" [hidden]="true">
            <div class="menu-item" routerLink="app/account">My account</div>
            <div class="menu-item">Manage members</div>
        </div>
        <div class="breakline"  [hidden]="true"></div>
        <div class="account-options__legal"  [hidden]="true">
            <div class="menu-item" (click)="openContactModal()">Contact us</div>
            <div class="menu-item">
                <a href="/app/terms" target="_blank">Terms & conditions</a>
            </div>
        </div>
        <div class="breakline"></div>
        <div class="account-options__item">
            <div class="menu-item">Version {{ dataService.version }}</div>
        </div>
        <div class="breakline"></div>
        <div class="account-options__logout">
            <div id="logoutButton" class="menu-item" (click)="logout()">Log out</div>
        </div>
    </div>
    <div *ngIf="showContactModal">
        <app-contact (closeModal)="closeContactModal()"></app-contact>
    </div>
</div>
