import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {SidenavService} from "../../utils/sidenav.service";
import {animateText, onSideNavChange} from "../../animations/animations";
import {Router} from "@angular/router";
import {DataService, NavRequest} from "../../data.service";
import {NetworkService} from "../../network.service";
import {UtilitiesService} from "../../utilities.service";
import {take} from "rxjs/operators";


interface Page {
    link: string;
    name: string;
    icon: string;
    tooltip: string;
}

@Component({
    selector: 'sidebar2',
    templateUrl: './sidebar2.component.html',
    styleUrls: ['./sidebar2.component.scss'],
    animations: [onSideNavChange, animateText]
})
export class Sidebar2Component implements OnInit, OnDestroy, OnChanges {
    public sideNavState: boolean = false;
    public linkText: boolean = false;
    // public userData: any;
    public activePage : string = 'Projects';
    status = false;

    public pages: Page[] = [
        {name: 'Projects', link: '/app/projects', icon: 'public', tooltip: 'Projects'},
        {name: 'Quick Search', link: '/app/search', icon: 'search', tooltip: 'Quick Search'}
    ];

    constructor(private _sidenavService: SidenavService,
                public router: Router,
                public networkService: NetworkService,
                public dataService: DataService) {
        // this.dataService.userData = this.dataService.userData;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.dataService.userInfo$.pipe(take(1)).subscribe((userData) => {
            // this.userData = userData;
            if (UtilitiesService.isAdmin(this.dataService.userData)) { // || UtilitiesService.isAccountAdmin(this.dataService.userData)) {
                this.pages.push({name: 'Canvas', link: '/app/canvas', icon: 'map', tooltip: 'Canvas'},);
            }
            
            if (UtilitiesService.isAdmin(this.dataService.userData)) {
                this.pages.push({name: 'Requests', link: '/app/callout', icon: 'handshake', tooltip: 'Requests'});
            }

            // this.userData = userData;
            if (UtilitiesService.isAdmin(this.dataService.userData)) {
                this.pages.push({name: 'Startup Explorer', link: '/app/companies', icon: 'domain', tooltip: 'Startup Explorer'});
            }

            if (UtilitiesService.isAdmin(this.dataService.userData) || UtilitiesService.isAccountAdmin(this.dataService.userData)) {
                this.pages.push({name: 'Account Users', link: '/app/account', icon: 'manage_accounts', tooltip: 'Account Users'});
            }

            if (UtilitiesService.isAdmin(this.dataService.userData) || UtilitiesService.isAccountAdmin(this.dataService.userData)) {
                this.pages.push({name: 'Settings', link: '/app/admin', icon: 'settings', tooltip: 'Settings'});
            }
        });
    }

    onSidenavToggle() {
        this.sideNavState = !this.sideNavState

        setTimeout(() => {
            this.linkText = this.sideNavState;
        }, 200)
        this._sidenavService.sideNavState$.next(this.sideNavState)
    }

    navigate(page: Page) {
        this.dataService.saveSearchUpdate(new NavRequest(page.link, NavRequest.MSG.NAV));
        this.activePage = page.name;
        // this.toggleActiveLink(page);
    }

    // toggleActiveLink(page: Page){
    //     let items = document.querySelectorAll('mat-list-item.menu-item-icon-container');
    //     for(let i = 0 ; i < items.length ; i++){
    //         items[i].classList.remove('active-link');
    //     }
    //     document.querySelector('mat-list-item.' + page.name).classList.add('active-link');
    // }    
}
