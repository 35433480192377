import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../../network.service';

@Component({
    selector: 'app-machine-import',
    templateUrl: './machine-import.component.html',
    styleUrls: ['./machine-import.component.scss'],
})
export class MachineImportComponent implements OnInit {
    message = '';
    file;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {}

    btnDisabled() {
        return this.file == null;
    }

    updateFile(event) {
        this.file = event.target.files[0];
    }

    upload() {
        this.message = 'Uploading...';
        this.networkService.uploadMachineFile(this.file).subscribe(
            (data) => {
                this.message = data.toString();
            },
            (error) => {
                this.message = error.error.toString();
                console.log(error);
            },
        );
    }
}
