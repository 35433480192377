import {Component, Input, OnInit} from "@angular/core";
import {DataService} from "../../data.service";
import {NetworkService} from "../../network.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'light-component',
    templateUrl: './light.component.html',
    styleUrls: ['./light.component.scss'],
})
export class LightComponent implements OnInit{
    @Input() color: 'RED' | 'GREEN' | 'BLUE' | 'YELLOW' | 'GRAY' = 'GRAY';
    @Input() size: 'SMALL' | 'MEDIUM' | 'LARGE';

    constructor(public dataService: DataService,
                public networkService: NetworkService,
                public matDialog: MatDialog) {
    }

    ngOnInit(): void {
    }

}
