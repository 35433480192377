import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NetworkService } from '../../../network.service';

@Component({
    selector: 'app-search-campus-map',
    templateUrl: './search-campus-map.component.html',
    styleUrls: ['./search-campus-map.component.scss'],
})
export class SearchCampusMapComponent implements OnInit {
    @Input() type;
    @Input() query;
    @Input() globalSearch;
    @Input() country;
    @Input() institution;
    @Input() forceNLP;

    @Output() jumpToMesh: EventEmitter<any> = new EventEmitter();
    @Output() trendingState: EventEmitter<any> = new EventEmitter();

    searchString;
    noResults = false;
    trendingText = 'My campus';
    trending = false;
    topics: any[];
    loading = false;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        document.onclick = () => {
            this.topics = null;
            this.noResults = false;
        };
    }

    onSearchSubmit() {
        this.loading = true;
        if (this.type === 'campus') {
            this.networkService
                .getMatchingDescriptors(this.searchString, this.trending)
                .subscribe(
                    (data) => this.handleMatchingDescriptorsResponse(data),
                    (error) => console.log(error),
                );
        } else if (this.type === 'explorer') {
            this.networkService
                .getMatchingDescriptors(
                    this.searchString,
                    null,
                    this.query,
                    this.forceNLP,
                    this.globalSearch ? this.country : null,
                    this.globalSearch,
                )
                .subscribe(
                    (data) => this.handleMatchingDescriptorsResponse(data),
                    (error) => console.log(error),
                );
        }
    }

    cancelSearch() {
        this.topics = null;
    }

    searchDisabled() {
        return this.searchString === undefined || this.searchString.length < 3;
    }

    trendingClicked() {
        this.trending = !this.trending;
        if (this.trending) {
            this.trendingText = 'Global trending';
        } else {
            this.trendingText = 'My campus';
        }
        this.trendingState.emit(this.trending);
    }

    handleMatchingDescriptorsResponse(data) {
        this.noResults = false;
        this.loading = false;

        if (data.length > 1) {
            this.topics = data;
        } else if (data.length === 1) {
            this.topicSelected(data[0]);
        } else {
            this.noResults = true;
        }
    }

    topicSelected(topic) {
        this.topics = undefined;
        this.searchString = undefined;
        this.jumpToMesh.emit(topic);
    }
}
