<div class="dialog">
    <div class="header">
        <div
            class="close-button close-icon"
            (click)="closeSaveSearchModal()"
        ></div>
        <div class="header-title">{{ data.dialogTitle }}</div>
        <div class="header-subtitle">
            <div class="line">
                {{ data.dialogDescription }}
            </div>
        </div>
    </div>
    <div class="info">
        <form [formGroup]="formGroup" class="save-search-input">
            <div class="dialog-item">
                <div class="dialog-title">
                    {{ data.dialogTextPrefix }}
                </div>
                <div class="searchline-save-search">
                    <mat-form-field appearance="fill">
                        <input
                            matInput
                            type="text"
                            [(ngModel)]="data.title"
                            [formControl]="formGroup.controls.title"
                            placeholder="DNA sequencing "
                        />
<!--                        <mat-icon-->
<!--                            *ngIf="data.title"-->
<!--                            matSuffix-->
<!--                            mat-icon-button-->
<!--                            aria-label="Clear"-->
<!--                            (click)="data.title = ''"-->
<!--                            >close</mat-icon-->
<!--                        >-->
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="button-cancel" (click)="closeSaveSearchModal()">
                Cancel
            </div>
            <div class="button-save" (click)="saveSearch()">Save</div>
        </div>
    </div>
</div>
