<div class="myContainer">
    <div class="side col-6">
        <label for="query">Search</label>
        <div style="display: flex">
            <input
                type="text"
                class="form-control"
                id="query"
                [(ngModel)]="query"
                style="width: 95%"
                (keyup.enter)="search(query)"
            />
            <button
                type="button"
                (click)="search(query)"
                class="btn btn-primary btn-sm"
            >
                Search
            </button>
        </div>
        <label for="searchResults">Search Results</label>
        <div style="display: flex">
            <ngx-json-viewer
                rows="200"
                class="form-control"
                id="searchResults"
                [json]="searchResults"
                style="width: 95%; height: 95%"
            ></ngx-json-viewer>
        </div>
    </div>
</div>
