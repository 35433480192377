<!--<router-outlet *ngIf="!this.dataService.userData"></router-outlet>-->
<mat-sidenav-container class="app-wrapper" id="app-wrapper">
        <mat-sidenav *ngIf="this.dataService.userData && !readOnlyProject"
                     #sidenav
                     class="sidenav"
                     mode="side"
                     opened="{{isExpanded}}"
                     autosize="true"
                     fixedInViewport="true">

            <sidebar2 (toggle)="sidenav.toggle()"></sidebar2>
        </mat-sidenav>

        <mat-sidenav-content >
            <div id="content" class="relative">
                <div [class.show-bar]="this.dataService.userData && !readOnlyProject">
                    <app-navbar [hidden]="!this.dataService.userData" *ngIf="!readOnlyProject"></app-navbar>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </mat-sidenav-content>

</mat-sidenav-container>
