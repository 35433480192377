<div class="modal fade in" id="payment-modal" role="dialog">
    <div id="paymentContainer">
        <div
            style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 25px;
            "
        >
            <div class="header">Our contact information</div>
            <img
                src="assets/images/close-dark.png"
                class="closeIcon"
                (click)="close()"
            />
        </div>

        <div class="group">
            <div class="title">Address</div>
            <div class="content">
                Chaim Levanon Street 30, Tel Aviv-Yafo, Israel
            </div>
        </div>

        <div class="group">
            <div class="title">Phone number</div>
            <div class="content">+97236445173</div>
        </div>
    </div>
</div>
