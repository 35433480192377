import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NewProjectResult } from 'src/app/api-responses/NewProjectResult.interface';
import { NetworkService } from 'src/app/network.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import moment from 'moment';

@Component({
    selector: 'app-edit-project-dialog',
    templateUrl: './edit-project-dialog.component.html',
    styleUrls: ['./edit-project-dialog.component.scss'],
})
export class EditProjectDialogComponent implements OnInit {
    creationDate: any;

    constructor(
        public networkService: NetworkService,
        public http: HttpClient,
        public router: Router,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<EditProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NewProjectResult,
    ) {
    }

    ngOnInit() {
        this.creationDate = moment(this.data.creationDate);
    }

    close() {
        this.dialogRef.close();
    }

    closeEditProjectModal() {
        this.dialogRef.close({
            name: this.data.name,
            description: this.data.description
        });
        // window.history.pushState('', '/', window.location.pathname);
    }

    deleteProject() {
        console.log('deleted');
    }

    updateProject() {
        return this.http
            .put<NewProjectResult>(
                `/api/v1/innovationProjects/${this.data.id}/`,
                null,
                {
                    params: {
                        title: this.data.name,
                        description: this.data.description,
                    },
                },
            )
            .toPromise()
            .then((project) => {
                this.closeEditProjectModal();
                this.router.navigate([`/app/projects/${project.id}`]);
                console.log('updated');
            });
    }

    shareProject() {
        console.log('shared');
    }
}
