<div class="general-page-container projects-page">
    <div>
        <h1>
            Open Alex Admin
        </h1>
    </div>
    <div class="my-3">
        States:
        <select [(ngModel)]="filterState" name="stateSelect" (ngModelChange)="getByState(filterStateSelect.value)" #filterStateSelect>
            <option *ngFor="let state of statesFilter">
                {{ state }}
            </option>
        </select>
    </div>
    <div>
        <table mat-table matSort [dataSource]="this.manifestLines" class="mat-elevation-z8 open-alex-table"
               (matSortChange)="announceSortChange($event)" >

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Id
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.id }}
                </td>
            </ng-container>

            <ng-container matColumnDef="fileNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    File Number
                </th>
                <td mat-cell *matCellDef="let element"> {{element.fileNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="openAlexDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    OpenAlex Date
                </th>
                <td mat-cell *matCellDef="let element"> {{element.openAlexDate}} </td>
            </ng-container>

            <ng-container matColumnDef="openAlexPart">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    OpenAlex Part
                </th>
                <td mat-cell *matCellDef="let element"> {{element.openAlexPart}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    State
                </th>
                <td mat-cell *matCellDef="let element">
                    <select class="p-1 m-1" [(ngModel)]="element.state" name="stateSelect" [selectedIndex]="states.indexOf(element.state)" #selectedState>
                        <option *ngFor="let state of states">
                            {{ state }}
                        </option>
                    </select>
                    <button class="p-1 m-1 btn-primary" (click)="updateState(element, selectedState)">Update</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" #paginator
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>

</div>
