import {Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NewSearchModalData} from "../../../../api-responses/Dialogs.interface";
import {Institution} from "../../../../api-responses/SearchQueryResults.interface";
import {NetworkService} from "../../../../network.service";
import {UtilsComponent} from "../../../../utils/utils.component";

interface SelectedPair {
    selected: boolean,
    inst: Institution
}

@Component({
    selector: 'add-company-modal',
    templateUrl: './add-company-modal.component.html',
    styleUrls: ['./add-company-modal.component.scss'],
})
export class AddCompanyModalComponent implements OnInit, OnDestroy, OnChanges {
    companies: SelectedPair[] = [];
    selectedCompanies: Institution[] = [];
    loading: boolean = false;
    public showAddForm: boolean;


    @ViewChild('findCompany') findCompany: ElementRef;
    importing: boolean = false;
    errorImport: boolean = false;

    constructor(public utilsComponent: UtilsComponent,
                public networkService: NetworkService,
        public dialogRef: MatDialogRef<AddCompanyModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: NewSearchModalData) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    closeModal() {
        this.dialogRef.close();
    }

    addCompany() {
        this.dialogRef.close({companies: this.selectedCompanies});
    }

    search(query: string) {
        this.loading = true;
        this.networkService.searchCompanies(query, false).toPromise().then((data: any) => {
            const vals = this.selectedCompanies.map(x => { return {selected: true, inst: x} });
            this.companies = [...vals];

            if (data && data.length > 0) {
                for (const company of data) {
                    if (this.companies.filter(x => x.inst.id === company.id).length > 0) continue;
                    this.companies.push({
                        selected: this.selectedCompanies.indexOf(company) > 0,
                        inst: company
                    });
                }
                this.loading = false;
            }
            else {
                if (UtilsComponent.isUrl(query.trim())) {
                    this.networkService.searchByHost(query.trim()).toPromise()
                        .then((inst: Institution) => {
                            if (inst) {
                                this.companies.push({
                                    selected: this.selectedCompanies.indexOf(inst) > 0,
                                    inst: inst
                                });
                            }
                            this.loading = false;
                        })
                        .catch(() => {
                            this.loading = false;
                        })
                }
                else {
                    this.loading = false;
                }
            }

        }).catch(() => {
            this.loading = false;
        })
    }

    addToSelected($event: Event, checkbox: HTMLInputElement, selectedCompany: Institution, toggle: boolean) {
        let value = checkbox.checked;

        if (toggle) {
            value = !value;
            checkbox.checked = value;
        }

        let selectedComp = this.selectedCompanies.filter(x => x.id === selectedCompany.id);

        if (value) {
            this.selectedCompanies.push(selectedCompany);
        }
        else {
            this.removeFromSelected(selectedCompany);
        }

        let comp = this.companies.filter(x => x.inst.id === selectedCompany.id);
        if (comp && comp.length > 0) {
            comp[0].selected = value;
        }

        this.companies = JSON.parse(JSON.stringify(this.companies));

        $event.preventDefault();
        $event.stopPropagation();
        return false;
    }

    removeFromSelected(selectedCompany: Institution) {
        let index = this.selectedCompanies.indexOf(selectedCompany);
        this.selectedCompanies.splice(index,1);

        let comp = this.companies.filter(x => x.inst.id === selectedCompany.id);
        if (comp && comp.length > 0) {
            comp[0].selected = false;
        }

        this.companies = JSON.parse(JSON.stringify(this.companies));
    }


    instFound(inst: Institution) {
        this.showAddForm = false;
        this.findCompany.nativeElement.value = inst.name;
        this.search(inst.name);
    }

    tryImportFromUrl(url: string) {

    }

    reportImportError() {

    }


}
