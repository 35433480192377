import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'request-cards',
    templateUrl: './request-cards.component.html',
    styleUrls: ['./request-cards.component.scss'],
})
export class RequestCardsComponent {

    @Input() readonly: boolean = true;

    @Input()  advise!: boolean;
    @Output() adviseChange = new EventEmitter<boolean>();

    @Input()  project!: boolean;
    @Output() projectChange = new EventEmitter<boolean>();

    @Input()  data!: boolean;
    @Output() dataChange = new EventEmitter<boolean>();

}
