import {Injectable, OnInit} from "@angular/core";
import {Subject} from "rxjs";
import {NetworkService} from "./network.service";
import {ActivatedRoute, ParamMap} from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class ReadOnlyProjectService implements OnInit{

    public _sharedProject: SharedProject;

    // *****************************************************
    public sharedProject = new Subject<SharedProject>();
    updateSharedProjectState(sharedProject: SharedProject) {
        this._sharedProject = sharedProject;
        this.sharedProject.next(sharedProject);
    }

    // *****************************************************
    public projectId = new Subject<number>();
    updateProjectIdState(projectId: number) {
        this.projectId.next(projectId);
    }

    public isReadOnlyProject: boolean = false;

    constructor(public activatedRoute: ActivatedRoute,
        public networkService: NetworkService) {
        this.isReadOnlyProject = window.location.href.indexOf('/read-only-') > -1;
        if (!this.isReadOnlyProject) return;

        this.projectId.subscribe((projectId: number) => {
            this.networkService.getSharedProject(projectId).then((sharedProject: SharedProject) => {
                this.updateSharedProjectState(sharedProject);
            });
        })

        this.activatedRoute.paramMap.subscribe((map: ParamMap) => {
            // no specific project
            if (map.has("projectId")) {
                this.updateProjectIdState(parseInt(map.get("projectId")));
            }
        });

    }

    ngOnInit(): void {
    }


}

export interface SharedProject {
    innovationProjectId: number,
    projectFile: string,
    evidenceFile: string,
    descriptorsFile: string
    enabled: boolean;
    sharedByName: string;
    sharedByEmail: string;
    canonicalUrl: string;
    placesFile: string;
}
