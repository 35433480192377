import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import { UtilsComponent } from 'src/app/utils/utils.component';
import { Router } from '@angular/router';
import * as eol from 'eol';

@Component({
    selector: 'vcs-admin-add-management',
    templateUrl: './vcs-admin-add.component.html',
    styleUrls: ['./vcs-admin-add.component.scss'],
})
export class VcsAdminAddComponent implements OnInit {
    countries: any[] = UtilsComponent.countries;
    @ViewChild('vcName') vcName: ElementRef;
    @ViewChild('url') url: ElementRef;
    @ViewChild('isAggregator') isAggregator: ElementRef;
    @ViewChild('countrySelect') countrySelect: ElementRef;
    @ViewChild('crawlDepthSelect') crawlDepthSelect: ElementRef;

    constructor(
        private router: Router,
        private networkService: NetworkService,
    ) {}

    ngOnInit() {
        this.networkService.getVCs().subscribe(
            (data) => {
                if (data) {
                }
            },
            (error) => console.error(error),
        );
    }

    async addMultiVCs(text: string) {
        const lines = eol.split(text);

        try {
            for (const line of lines) {
                const split = line.split(' ||| ');
                const lineName = split[0];
                const lineUrl = split[1];
                const lineCountry = split[2];
                const lineIsAggregator = split[3].toLowerCase() === 'true';
                const lineCrawlDepth = split[4];

                await this.networkService.addVc(
                    lineName,
                    lineUrl,
                    lineCountry,
                    lineIsAggregator,
                    parseInt(lineCrawlDepth, 10),
                );
            }

            alert('Saved!');
            this.router.navigate(['/app/admin/vcs-admin/']);
        } catch (e) {
            alert('error! ' + e.error);
        }
    }

    async addSingleVC(
        name: string,
        url: string,
        country: string,
        isAggregator: boolean,
        crawlDepth: string,
    ) {
        try {
            await this.networkService.addVc(
                name,
                url,
                country,
                isAggregator,
                parseInt(crawlDepth, 10),
            );

            alert('Saved!');
            this.router.navigate(['/app/admin/vcs-admin/']);
        } catch (e) {
            alert('error! ' + e.error);
        }
    }
}
