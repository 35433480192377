import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NetworkService } from '../../network.service';
import { UtilitiesService } from '../../utilities.service';
import domtoimage from 'dom-to-image';

@Component({
    selector: 'app-trending-topics',
    templateUrl: './trending-topics.component.html',
    styleUrls: ['./trending-topics.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class TrendingTopicsComponent implements OnInit {
    ngOnInit(): void {
    }
    // loading = true;
    // noResults = false;
    // originalInstitutionsData: any = undefined;
    // institutionsData: any = undefined;
    // colorsArray: any = this.interpolateColors(
    //     'rgb(232, 90, 78)',
    //     'rgb(46, 131, 205)',
    //     25,
    // );
    // exportLoading = false;
    // feedbackOpen = false;
    //
    // groupToTopicsMap: any = undefined;
    // selectedGroup = 'All science fields';
    // selectedTopic = 'All topics';
    // groups = [];
    // topics = [];
    // topicsDropdownSearch = '';
    // topicsDropdownLastClick;
    //
    // selectedInstitution = 'Compare against the world';
    // institutions = [];
    //
    constructor(
        private networkService: NetworkService,
        private cdRef: ChangeDetectorRef,
    ) {}
    //
    // ngOnInit() {
    //     this.topicsDropdownLastClick = Date.now();
    //     this.getTrendingTopicsForInstitution();
    //     this.networkService.getTrendingComparableInstitutions().subscribe(
    //         (data) => this.handleComparableInstitutionsResponse(data),
    //         (error) => console.log(error),
    //     );
    // }
    //
    // getTrendingTopicsForInstitution() {
    //     this.institutionsData = null;
    //     this.loading = true;
    //
    //     let ins = 'world';
    //     if (this.selectedInstitution !== 'Compare against the world') {
    //         ins = this.selectedInstitution;
    //     }
    //
    //     this.networkService.getTrendingTopicsForInstitution(ins).subscribe(
    //         (data) => this.handleResponse(data),
    //         (error) => console.log(error),
    //     );
    // }
    //
    // institutionChanged(institution) {
    //     if (institution.localeCompare(this.selectedInstitution) !== 0) {
    //         this.selectedInstitution = institution;
    //         this.getTrendingTopicsForInstitution();
    //     }
    // }
    //
    // handleResponse(data) {
    //     this.loading = false;
    //
    //     if (!data) {
    //         this.noResults = true;
    //     } else {
    //         this.originalInstitutionsData = data.institutionResponses;
    //         this.groupToTopicsMap = data.groupToTopics;
    //         const keys = Object.keys(this.groupToTopicsMap);
    //         this.groups = Array.from(keys).sort();
    //         this.setTopics();
    //         this.filterResults();
    //     }
    // }
    //
    // setTopics() {
    //     let values;
    //     if (this.selectedGroup !== 'All science fields') {
    //         values = this.groupToTopicsMap[this.selectedGroup];
    //     } else {
    //         values = [].concat.apply([], Object.values(this.groupToTopicsMap));
    //     }
    //     this.topics = Array.from(new Set(values)).sort();
    //     this.selectedTopic = 'All topics';
    // }
    //
    // handleKeyboardEvents(event) {
    //     const topicsDropdownOpen = document.getElementById('topicsDropdown')
    //         ? document
    //               .getElementById('topicsDropdown')
    //               .classList.contains('open')
    //         : false;
    //
    //     if (topicsDropdownOpen) {
    //         const arr = this.topics;
    //         const lastClick = this.topicsDropdownLastClick;
    //         const search = this.topicsDropdownSearch;
    //         const result = UtilitiesService.handleKeyboardEvents(
    //             event,
    //             lastClick,
    //             arr,
    //             search,
    //         );
    //
    //         if (result) {
    //             this.topicsDropdownSearch = result[0];
    //             this.topicsDropdownLastClick = result[1];
    //         }
    //     }
    // }
    //
    // handleComparableInstitutionsResponse(data) {
    //     this.institutions = data;
    // }
    //
    // openFeedback() {
    //     const body = document.getElementsByTagName('body')[0];
    //     body.style.overflowY = 'hidden';
    //     this.feedbackOpen = true;
    // }
    //
    // filterResults() {
    //     this.institutionsData = [];
    //     for (const i in this.originalInstitutionsData) {
    //         if (i) {
    //             continue;
    //         }
    //         const insCopy = { ...this.originalInstitutionsData[i] };
    //         if (this.selectedTopic !== 'All topics') {
    //             // filter topics for the chosen topic
    //             insCopy.topicScores = insCopy.topicScores.filter(
    //                 (topicScore) => topicScore.valueA === this.selectedTopic,
    //             );
    //         } else if (this.selectedGroup !== 'All science fields') {
    //             // filter topics for the chosen group
    //             const topics = this.groupToTopicsMap[this.selectedGroup];
    //             insCopy.topicScores = insCopy.topicScores.filter((topicScore) =>
    //                 topics.includes(topicScore.valueA),
    //             );
    //         }
    //
    //         // find top headings for institution
    //         insCopy.topicScores = insCopy.topicScores.filter(
    //             (topicScore) => topicScore.valueB >= 50,
    //         ); // keep topics with score >= 50
    //         insCopy.topicScores = insCopy.topicScores.slice(0, 25); // show only top 25 topics
    //
    //         // exclude institutions with 0 results
    //         if (insCopy.topicScores.length > 0) {
    //             this.institutionsData.push(insCopy);
    //         }
    //     }
    //
    //     // sort all institutions by total score descending
    //     this.institutionsData.sort((insA, insB) => {
    //         const totalScoreA = insA.topicScores
    //             .map((topicScore) => topicScore.valueD)
    //             .reduce((sum, current) => sum + current);
    //         const totalScoreB = insB.topicScores
    //             .map((topicScore) => topicScore.valueD)
    //             .reduce((sum, current) => sum + current);
    //         return totalScoreA > totalScoreB ? -1 : 1;
    //     });
    // }
    //
    // interpolateColor(color1, color2, factor) {
    //     factor = arguments.length < 3 ? 0.5 : factor;
    //
    //     const result = color1.slice();
    //     for (let i = 0; i < 3; i++) {
    //         result[i] = Math.round(
    //             result[i] + factor * (color2[i] - color1[i]),
    //         );
    //     }
    //
    //     return result;
    // }
    //
    // getStrengthExplain(valueD, valueE) {
    //     let ins = 'the world average';
    //     if (this.selectedInstitution !== 'Compare against the world') {
    //         ins = this.selectedInstitution;
    //
    //         if (valueD > 100) {
    //             return valueD - 100 + '% above ' + ins;
    //         }
    //         if (valueD < 100) {
    //             return 100 - valueD + '% below ' + ins;
    //         } else {
    //             return 'The same as ' + ins;
    //         }
    //     } else {
    //         if (valueE >= 0.9) {
    //             return 'In the highest tier (10/10)';
    //         } else if (valueE >= 0.8) {
    //             return 'In the ninth tier (9/10)';
    //         } else if (valueE >= 0.7) {
    //             return 'In the eighth tier (8/10)';
    //         } else if (valueE >= 0.6) {
    //             return 'In the seventh tier (7/10)';
    //         } else if (valueE >= 0.5) {
    //             return 'In the sixth tier (6/10)';
    //         } else if (valueE >= 0.4) {
    //             return 'In the fifth tier (5/10)';
    //         } else if (valueE >= 0.3) {
    //             return 'In the fourth tier (4/10)';
    //         } else if (valueE >= 0.2) {
    //             return 'In the third tier (3/10)';
    //         } else if (valueE >= 0.1) {
    //             return 'In the second tier (2/10)';
    //         } else {
    //             return 'In the lowest tier (1/10)';
    //         }
    //     }
    // }
    //
    // interpolateColors(color1, color2, steps) {
    //     const stepFactor = 1 / (steps - 1);
    //     const interpolatedColorArray = [];
    //
    //     color1 = color1.match(/\d+/g).map(Number);
    //     color2 = color2.match(/\d+/g).map(Number);
    //
    //     for (let i = 0; i < steps; i++) {
    //         const color = this.interpolateColor(color1, color2, stepFactor * i);
    //         interpolatedColorArray.push(
    //             `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
    //         );
    //     }
    //
    //     return interpolatedColorArray;
    // }
    //
    // exportBtnClicked() {
    //     this.exportLoading = true;
    //
    //     domtoimage
    //         .toPng(document.getElementById('resultsContainer'), {
    //             quality: 1,
    //             bgcolor: '#ffffff',
    //         })
    //         .then((dataUrl) => {
    //             this.networkService
    //                 .exportTrendingTopics(
    //                     this.institutionsData,
    //                     dataUrl,
    //                     this.selectedInstitution !== 'Compare against the world'
    //                         ? this.selectedInstitution
    //                         : null,
    //                 )
    //                 .subscribe(
    //                     (data) => {
    //                         this.exportLoading = false;
    //                         this.cdRef.detectChanges();
    //                         const options = { type: 'application/ms-excel' };
    //                         const filename = 'strength-mapper.xlsx';
    //                         this.createAndDownloadBlobFile(
    //                             data,
    //                             options,
    //                             filename,
    //                         );
    //                     },
    //                     (error) => {
    //                         this.exportLoading = false;
    //                         this.cdRef.detectChanges();
    //                         console.log(error);
    //                     },
    //                 );
    //         })
    //         .catch((error) => console.log(error));
    // }
    //
    // createAndDownloadBlobFile(body, options, filename) {
    //     const blob = new Blob([body], options);
    //     if (navigator.msSaveBlob) {
    //         // IE 10+
    //         navigator.msSaveBlob(blob, filename);
    //     } else {
    //         // Browsers that support HTML5 download attribute
    //         const link = document.createElement('a');
    //         if (link.download !== undefined) {
    //             const url = URL.createObjectURL(blob);
    //             link.setAttribute('href', url);
    //             link.setAttribute('download', filename);
    //             link.style.visibility = 'hidden';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     }
    // }
    //
    // normalize(number, oldRange, newRange) {
    //     return Math.round((number * newRange) / oldRange);
    // }
    //
    // checkWidth(event, title) {
    //     return UtilitiesService.checkWidth(event, title);
    // }
    //
    // vw() {
    //     return UtilitiesService.vw();
    // }
}
