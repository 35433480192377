import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Chart, PositionType} from 'chart.js';
import {PositionOptions} from "mapbox-gl";
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, AfterViewInit {

    @Input() ratio = 3;

    @Input() legendAlign: any = 'center';
    @Input() legendPosition: PositionType = 'bottom' ;

    @ViewChild('canvas') canvasElementRef: ElementRef<HTMLCanvasElement>;
    // @Input() @HostBinding('style.width') width = '400px';
    // @Input() @HostBinding('style.height') height = '400px';
    chart: Chart;
    chartOptions: Chart.ChartConfiguration = {
        type: 'doughnut',
        plugins: [ChartDataLabels],
        options: {
            tooltips: {
                enabled: true
            },
            title: {
              display: false
            },
            legend: {
                align: 'center',
                position: 'left',
                display: true,
                fullWidth: true,
                labels: {
                    fontColor: '#d8d8d8',
                    fontSize: 14
                }

            },
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        let dataArr = ctx.chart.data.datasets[0].data as number[];
                        let sum = dataArr.reduce((a,b) => a+b, 0);
                        let percentage = (value*100 / sum).toFixed(0)+"%";
                        return percentage;
                    },
                    color: '#fff',
                }
            }
        },
        data: {
            datasets: null,
        }
    };

    constructor(private ngZone: NgZone) {}

    inputData;
    @Input() set data(
        inputData: any
    ) {
        // This will update the chart in a nice way if it was rendered before
        this.inputData = inputData;
    }

    ngAfterViewInit(): void {
        if (!this.chart) {
            this.chartOptions.data = this.inputData;
            this.drawChart();
            return;
        }

        this.chart.data = this.inputData;
        this.chart.update();
    }

    ngOnInit() {}

    drawChart() {
        this.chart = this.ngZone.runOutsideAngular(() => {
            const context =
                this.canvasElementRef.nativeElement.getContext('2d');
            return new Chart(context, this.chartOptions);
        });
    }
}
