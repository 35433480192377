import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NetworkService } from '../../network.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from '../../data.service';
import { UtilitiesService } from '../../utilities.service';

@Component({
    selector: 'app-trending-bubble',
    templateUrl: './trending-bubble.component.html',
    styleUrls: ['./trending-bubble.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(window:resize)': 'onResize()',
    },
})
export class TrendingBubbleComponent
    implements OnInit, OnDestroy, AfterContentChecked
{
    searchString;
    loading = false;
    noResults = false;
    chart = undefined;
    isMapVisible = false;
    locations;
    rating = [];
    years;
    myInstitute;
    maxVal;
    minVal;
    numOfYears = 6;
    dropDownOpenRating = false;
    dropDownOpenActivity = false;
    currentYear;
    private _routerSub = Subscription.EMPTY;

    constructor(
        private networkService: NetworkService,
        private router: Router,
        private route: ActivatedRoute,
        public dataService: DataService,
        private cdRef: ChangeDetectorRef,
    ) {
        this._routerSub = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const searchString =
                        this.route.snapshot.params['searchString'];
                    this.searchString =
                        searchString.length >= 3 ? searchString : '';
                    if (this.searchString !== '') {
                        this.onSearchSubmit();
                    }
                }
            });
    }

    ngOnInit() {
        this.onResize();
        this.currentYear = new Date().getFullYear();
    }

    ngOnDestroy() {
        this._routerSub.unsubscribe();
    }

    ngAfterContentChecked() {
        this.cdRef.detectChanges();
    }

    onResize() {
        // Calculate number of years to display at the canvas, according to the viewport width
        if (this.vw() <= 500) {
            this.numOfYears = 2;
        } else if (this.vw() <= 600) {
            this.numOfYears = 3;
        } else if (this.vw() <= 700) {
            this.numOfYears = 4;
        } else if (this.vw() <= 750) {
            this.numOfYears = 5;
        } else if (this.vw() <= 800) {
            this.numOfYears = 6;
        } else if (this.vw() <= 900) {
            this.numOfYears = 7;
        } else if (this.vw() <= 1000) {
            this.numOfYears = 1;
        } else if (this.vw() <= 1100) {
            this.numOfYears = 2;
        } else if (this.vw() <= 1200) {
            this.numOfYears = 3;
        } else if (this.vw() <= 1300) {
            this.numOfYears = 4;
        } else if (this.vw() <= 1400) {
            this.numOfYears = 5;
        } else if (this.vw() <= 1500) {
            this.numOfYears = 6;
        } else if (this.vw() <= 1600) {
            this.numOfYears = 7;
        } else if (this.vw() > 1600) {
            this.numOfYears = 8;
        }

        this.resetControlsPosition();
    }

    resetControlsPosition() {
        const yearsContainerLeft = document.getElementById('yearsContainer');
        const previousYearsLeft = document.getElementById('previousYears');
        if (yearsContainerLeft) {
            yearsContainerLeft.style.left =
                (this.numOfYears - this.years.length) * 60 + 'px';
        }

        if (previousYearsLeft) {
            previousYearsLeft.style.left =
                (this.numOfYears - this.years.length) * 60 + 'px';
        }
    }

    searchDisabled() {
        return this.searchString === undefined || this.searchString.length < 3;
    }

    onSearchSubmit() {
        this.loading = true;
        this.noResults = false;
        this.networkService
            .getTrendingInStateByQuery(this.searchString)
            .subscribe(
                (data) => this.handleTrendingInStateResponse(data),
                (error) => console.log(error),
            );
    }

    updateMarker(title) {
        this.myInstitute = title;
        this.scroll();
    }

    updateYearsArr(arr) {
        while (arr.length > arr[arr.length - 1] - 2009) {
            // Array contains years or data from years before 2010
            arr.shift();
        }

        for (const i of this.rating) {
            while (i[2].length > arr.length) {
                i[2].shift();
            }
        }

        return arr;
    }

    handleTrendingInStateResponse(data) {
        this.loading = false;
        this.isMapVisible = true;

        this.locations = data.locations;
        this.years = data.years;
        this.myInstitute = this.dataService.userData.instituation;
        this.institutesRating(data.values, data.currentYearValues, data.order);

        this.years = this.updateYearsArr(this.years);
        setTimeout(() => this.scroll(), 1);
        this.noResults = this.rating.length === 0;
    }

    institutesRating(obj, currentYearVals, order) {
        // Sort institutes by their cumulative value
        const maxArr = [];
        const minArr = [];
        const sortable = [];

        for (const key in obj) {
            if (!key) {
                continue;
            }
            if (obj.hasOwnProperty(key)) {
                sortable.push([
                    key,
                    obj[key].reduce((a, b) => a + b, 0),
                    obj[key],
                    currentYearVals[key],
                    order[key] + 1,
                ]);
            }

            maxArr.push(Math.max(...obj[key], currentYearVals[key]));
            minArr.push(
                Math.min(
                    ...obj[key].filter((n) => n > 0),
                    currentYearVals[key],
                ),
            );
        }

        sortable.sort((a, b) => a[4] - b[4]);
        this.rating = sortable;
        this.maxVal = Math.max(...maxArr);
        this.minVal = Math.min(...minArr);
    }

    makeCirclesArr(start, stop, amount) {
        // Calculate a given amount of equally different numbers in the range between start and stop values
        const arr = [];
        const step = (stop - start) / (amount - 1);
        for (let i = 1; i < amount - 1; i++) {
            arr.push(start + step * i);
        }
        return arr;
    }

    numOfCircles(r1, r2) {
        // How many light circles to display between every two dark ones
        const space = 60 - r1 - r2;

        if (space >= 0 && space < 10) {
            return 1;
        } else if (space >= 10 && space < 20) {
            return 2;
        } else if (space >= 20 && space < 30) {
            return 3;
        } else if (space >= 30 && space < 40) {
            return 4;
        } else if (space >= 40 && space <= 50) {
            return 5;
        } else if (space >= 50 && space <= 60) {
            return 6;
        } else {
            return 0;
        }
    }

    isCircleInTheEdges() {
        const elem = document.getElementById('previousYears');
        const left = Math.abs(
            Number(elem.style.left.slice(0, elem.style.left.length - 2)),
        );
        return left / 60;
    }

    normalization(value, NewMax, NewMin, OldMax = null, OldMin = null) {
        if (value === 0 || value < this.minVal) {
            return 3;
        }

        if (!OldMax && !OldMin) {
            OldMax = this.rating[0][1];
            OldMin = this.rating[this.rating.length - 1][1];
        }
        return Math.round(
            ((value - OldMin) * (NewMax - NewMin)) / (OldMax - OldMin) + NewMin,
        );
    }

    checkWidth(event, title) {
        event.target.title = '';
        if (event.target.offsetWidth !== event.target.scrollWidth) {
            if (title && typeof title === 'object') {
                event.target.title = title.join(' ');
            } else if (title) {
                event.target.title = title;
            } else {
                event.target.title = event.target.innerHTML;
            }
        }
    }

    calcInstituteNameLength(institute) {
        if (institute.length > 20) {
            institute = institute.substring(0, 20) + '...';
        }
        return institute;
    }

    dropdownClicked(type) {
        if (type === 'rating') {
            this.dropDownOpenRating = !this.dropDownOpenRating;
        }
        if (type === 'activity') {
            this.dropDownOpenActivity = !this.dropDownOpenActivity;
        }
    }

    scroll() {
        const inst = document.getElementById(this.myInstitute);
        if (inst) {
            inst.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
    }

    checkPrevious() {
        const left = document.getElementById('yearsContainer').style.left;
        return (
            Number(left.slice(0, left.length - 2)) === 0 ||
            this.years.length <= this.numOfYears
        );
    }

    checkNext() {
        const left = document.getElementById('yearsContainer').style.left;
        return (
            Math.abs(Number(left.slice(0, left.length - 2))) ===
                (this.years.length - this.numOfYears) * 60 ||
            this.years.length <= this.numOfYears
        );
    }

    previous() {
        const yearsContainerLeft =
            document.getElementById('yearsContainer').style.left;
        const previousYearsLeft =
            document.getElementById('previousYears').style.left;

        document.getElementById('yearsContainer').style.left =
            Number(yearsContainerLeft.slice(0, yearsContainerLeft.length - 2)) +
            60 +
            'px';
        document.getElementById('previousYears').style.left =
            Number(previousYearsLeft.slice(0, previousYearsLeft.length - 2)) +
            60 +
            'px';
    }

    next() {
        const yearsContainerLeft =
            document.getElementById('yearsContainer').style.left;
        const previousYearsLeft =
            document.getElementById('previousYears').style.left;

        document.getElementById('yearsContainer').style.left =
            Number(yearsContainerLeft.slice(0, yearsContainerLeft.length - 2)) -
            60 +
            'px';
        document.getElementById('previousYears').style.left =
            Number(previousYearsLeft.slice(0, previousYearsLeft.length - 2)) -
            60 +
            'px';
    }

    activityInstitutionsWidth(val) {
        return (
            document
                .getElementById('chartContainer')
                .style.height.slice(0, 12) +
            val +
            ')'
        );
    }

    vw() {
        return UtilitiesService.vw();
    }

    checkBrowser() {
        return UtilitiesService.checkBrowser();
    }
}
