import {Component, Input} from "@angular/core";

@Component({
    selector: 'candidate-status',
    templateUrl: './candidate-status.component.html',
    styleUrls: ['./candidate-status.component.scss'],
})
export class CandidateStatusComponent {
    @Input() status: string = 'INIT';
    @Input() active: boolean = false;

    tooltips = [
        '\'Treebute\': Your request was received by treebute, and it is being processed',
        '\'Candidate\': Treebute approached the candidate, waiting for candidate\'s initial response',
        '\'Negotiation\': The request is under negotiation with the prospect',
        '\'Ready\': Your request is ready for schedule!'
    ];

    dict = {
        INIT: {text: 'Treebute', index: 0},
        PROPOSAL_SENT: {text: 'Candidate', index: 1},
        NEGOTIATE: {text: 'Negotiation', index: 2},
        // CLOSING: {text: 'Closing', index: 3},
        READY: {text: 'Ready', index: 4}
    };
    arr = [
        this.dict.INIT,
        this.dict.PROPOSAL_SENT,
        this.dict.NEGOTIATE,
        // this.dict.CLOSING,
        this.dict.READY
    ];

    isActive(index: number) {
        return index <= this.dict[this.status].index;
    }

    getStatusIndex() {
        return this.dict[this.status].index;
    }

    getText(index: number) {
        return this.arr[index].text;
    }


    getTooltip(number: number) {
        return this.tooltips[number];
    }
}
