<div *ngIf="wordCloud" class="wordCloud" id="wordCloud">
    <button
        *ngIf="!showWordCloud"
        [ngClass]="'btnWordCloud btn-primary'"
        (click)="flipWordCloud()"
    >
        Tune your search
    </button>
    <div *ngIf="showWordCloud">
        <span
            ><button
                [ngClass]="'btnWordCloud btn-primary'"
                (click)="flipWordCloud()"
            >
                Hide</button
            >&nbsp;Click on a term and then choose how it should be added to the
            query</span
        >
        <app-word-cloud
            *ngIf="showWordCloud"
            [data]="wordCloud"
            style="display: inline !important"
        ></app-word-cloud>
    </div>
</div>
<div style="display: flex; flex-direction: column">
    <h3>Calibrate a search:</h3>
    <form (ngSubmit)="onSearchSubmit()" style="margin-bottom: 20px">
        <div style="padding-left: 30px">
            <div class="form-check" style="display: flex; height: 16px">
                <input
                    class="form-check-input"
                    type="radio"
                    id="domainRadio1"
                    value="option1"
                    style="margin-right: 4px; margin-top: 2px"
                    (click)="updateDomain('BIOMED')"
                    [checked]="domain === 'BIOMED'"
                />
                <label
                    class="form-check-label"
                    for="domainRadio1"
                    style="
                        margin-bottom: 0;
                        margin-top: 0;
                        font-weight: 100;
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        color: #acacac;
                    "
                >
                    Only Bio-Med
                </label>
            </div>
            <div class="form-check" style="display: flex; height: 16px">
                <input
                    class="form-check-input"
                    type="radio"
                    id="domainRadio2"
                    value="option2"
                    style="margin-right: 4px; margin-top: 2px"
                    (click)="updateDomain('NONBIOMED')"
                    [checked]="domain === 'NONBIOMED'"
                />
                <label
                    class="form-check-label"
                    for="exampleRadios2"
                    style="
                        margin-bottom: 0;
                        margin-top: 0;
                        font-weight: 100;
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        color: #acacac;
                    "
                >
                    Only non Bio-Med
                </label>
            </div>
            <div class="form-check" style="display: flex; height: 16px">
                <input
                    class="form-check-input"
                    type="radio"
                    id="domainRadio3"
                    value="option3"
                    style="margin-right: 4px; margin-top: 2px"
                    (click)="updateDomain('ALL')"
                    [checked]="domain === 'ALL'"
                />
                <label
                    class="form-check-label"
                    for="domainRadio3"
                    style="
                        margin-bottom: 0;
                        margin-top: 0;
                        font-weight: 100;
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        color: #acacac;
                    "
                >
                    All Domains
                </label>
            </div>
        </div>

        <div style="padding-left: 30px; padding-top: 20px">
            <div class="form-check" style="display: flex; height: 16px">
                <input
                    class="form-check-input"
                    type="radio"
                    id="searchForRadio1"
                    value="option1"
                    style="margin-right: 4px; margin-top: 2px"
                    (click)="updateSearchFor('ARTICLES')"
                    [checked]="searchFor === 'ARTICLES'"
                />
                <label
                    class="form-check-label"
                    for="searchForRadio1"
                    style="
                        margin-bottom: 0;
                        margin-top: 0;
                        font-weight: 100;
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        color: #acacac;
                    "
                >
                    Articles
                </label>
            </div>
            <div class="form-check" style="display: flex; height: 16px">
                <input
                    class="form-check-input"
                    type="radio"
                    id="searchForRadio2"
                    value="option2"
                    style="margin-right: 4px; margin-top: 2px"
                    (click)="updateSearchFor('CT')"
                    [checked]="searchFor === 'CT'"
                />
                <label
                    class="form-check-label"
                    for="searchForRadio2"
                    style="
                        margin-bottom: 0;
                        margin-top: 0;
                        font-weight: 100;
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        color: #acacac;
                    "
                >
                    Clinical Trials
                </label>
            </div>
            <div class="form-check" style="display: flex; height: 16px">
                <input
                    class="form-check-input"
                    type="radio"
                    id="searchForRadio2"
                    value="option2"
                    style="margin-right: 4px; margin-top: 2px"
                    (click)="updateSearchFor('PATENTS')"
                    [checked]="searchFor === 'PATENTS'"
                />
                <label
                    class="form-check-label"
                    for="searchForRadio2"
                    style="
                        margin-bottom: 0;
                        margin-top: 0;
                        font-weight: 100;
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        color: #acacac;
                    "
                >
                    Patents
                </label>
            </div>
            <div class="form-check" style="display: flex; height: 16px">
                <input
                    class="form-check-input"
                    type="radio"
                    id="searchForRadio2"
                    value="option2"
                    style="margin-right: 4px; margin-top: 2px"
                    (click)="updateSearchFor('GRANTS')"
                    [checked]="searchFor === 'GRANTS'"
                />
                <label
                    class="form-check-label"
                    for="searchForRadio2"
                    style="
                        margin-bottom: 0;
                        margin-top: 0;
                        font-weight: 100;
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        color: #acacac;
                    "
                >
                    Grants
                </label>
            </div>
        </div>

        <div class="form-group">
            <textarea
                [(ngModel)]="query"
                name="query"
                class="form-control"
                rows="1"
                id="query"
                placeholder=""
            ></textarea>
        </div>
        <button class="btn btn-primary">Search</button>
    </form>

    <div *ngIf="results" id="results">
        <div style="color: white">{{ results.length }} Results</div>
        <div *ngFor="let result of results">
            <button
                type="button"
                (click)="openArticle(result.valueB)"
                class="btn btn-primary btn-sm"
            >
                {{ result.valueA }}
            </button>
        </div>
    </div>
</div>
