import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Chart, PositionType} from 'chart.js';
import {PositionOptions} from "mapbox-gl";
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, AfterViewInit {

    @Input() ratio = 3;

    @Input() legendAlign: any = 'center';
    @Input() legendPosition: PositionType = 'bottom' ;

    @ViewChild('canvas') canvasElementRef: ElementRef<HTMLCanvasElement>;
    // @Input() @HostBinding('style.width') width = '400px';
    // @Input() @HostBinding('style.height') height = '400px';
    chart: Chart;
    chartOptions: Chart.ChartConfiguration = {
        type: 'horizontalBar',
        plugins: [ChartDataLabels],
        options: {
            layout: {
                padding: {
                    left: 0,
                    right: 50
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#d8d8d8'
                    }
                }]
            },
            tooltips: {
                enabled: true
            },
            title: {
              display: false
            },
            legend: {
                align: 'start',
                position: 'right',
                display: false,
                fullWidth: true
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                    anchor: 'start',
                    align: 'end',
                    formatter: (value, ctx) => {
                        // let dataArr = ctx.chart.data.datasets[0].data as number[];
                        // let sum = dataArr.reduce((a,b) => a+b, 0);
                        // let percentage = (value*100 / sum).toFixed(1)+"%";
                        // return percentage;
                        return this.descriptorLabels[ctx.dataIndex]
                    },
                    color: '#fff',
                }
            }
        },
        data: {
            datasets: null,
        }
    };

    constructor(private ngZone: NgZone) {}

    @Input() descriptorLabels: string[];

    inputData;
    @Input() set data(
        inputData: any
    ) {
        // This will update the chart in a nice way if it was rendered before
        this.inputData = inputData;
    }

    ngAfterViewInit(): void {
        if (!this.chart) {
            this.chartOptions.data = this.inputData;
            this.drawChart();
            return;
        }

        this.chart.data = this.inputData;
        this.chart.update();
    }

    ngOnInit() {}

    drawChart() {
        this.chart = this.ngZone.runOutsideAngular(() => {
            const context =
                this.canvasElementRef.nativeElement.getContext('2d');
            return new Chart(context, this.chartOptions);
        });
    }
}
