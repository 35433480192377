import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CanvasListPageComponent} from "./canvas-list-page/canvas-list-page.component";
import {CanvasPageComponent} from "./canvas-page/canvas-page.component";
import {SharedCanvasPageComponent} from "./shared-canvas-page/shared-canvas-page.component";
import {CanvasHtmlUpdatePageComponent} from "./canvas-html-update/canvas-html-update-page.component";

const routes: Routes = [
    {
        path: 'app/canvas',
        children: [
            {
                path: '',
                component: CanvasListPageComponent
            },
            {
                path: ':canvasId/read-only-canvas',
                component: SharedCanvasPageComponent
            },
            {
                path: ':canvasId/html-update',
                component: CanvasHtmlUpdatePageComponent
            },
            {
                path: ':canvasId',
                component: CanvasPageComponent
            }
            // {
            //     path: ':projectId/search',
            //     component: SearchPageComponent,
            // },
            // {
            //     path: ':projectId/search/:searchId',
            //     component: SearchPageComponent,
            // },
            // {
            //     path: ':projectId/experts-tool',
            //     component: ExpertsToolComponent,
            // },
            // {
            //     path: ':projectId/ct-tool',
            //     component: CtToolComponent,
            // }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CanvasRoutingModule {}
