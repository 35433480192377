<!--<div class="summary-subtitle">Your saved searches</div>-->
<div class="saved-search-data" *ngIf="project">
    <app-search-chip class="app-search-chip"
                     *ngFor="let search of this.getSearches()"
                     [searchResult]="search"
                     [project]="project"
                     (userClickDelete)="this.userClickDelete.emit($event)"
                     (clickChip)="clickChip.emit($event)"
                     (deleteSearch)="deleteSearch.emit($event)"
    >
    </app-search-chip>
    <div class="add-data-button app-search-chip" [routerLink]="['search']"
         [hidden]="dataService.hideShared() || readOnlyProjectService.isReadOnlyProject">
        <div class="circle">
            <div class="plus-icon"></div>
        </div>
        <div style="display: flex; flex-direction: row;">
        <div class="add-data">
            <div class="data-title">Add data layer</div>
        </div>
        <div class="create-new-search-hint lovely-text" *ngIf="showHintCreateNew">
            <span class="animate-arrow inline-icon material-icons">arrow_back</span>
            Create New Search Here
        </div>
        </div>
    </div>
</div>
