<div style="display: flex; flex-direction: column">
    <div class="col-md-6 offset-md-3">
        <h3>Search for an institution:</h3>
        <form (ngSubmit)="onSearchSubmit()">
            <div class="form-group" style="display: flex">
                <textarea
                    [(ngModel)]="searchString"
                    name="searchString"
                    class="form-control"
                    rows="1"
                    id="searchString"
                ></textarea>
                <button [disabled]="searchDisabled()" class="btn btn-primary">
                    Search
                </button>
            </div>
        </form>
    </div>
    <div *ngIf="noResults" class="noResults">
        <a class="btn btn-info btn-lg">
            <span class="glyphicon glyphicon-warning-sign"></span> No results -
            please enter different text
        </a>
        <div *ngIf="newInstitute" style="margin: 20px">
            <span style="font-size: 18px; vertical-align: middle">
                Do you want to create a new institute?
            </span>
            <button
                class="btn btn-primary btn-sm"
                style="width: 50px; margin: 0 2px"
                (click)="createInstitution()"
            >
                Yes
            </button>
            <button
                class="btn btn-primary btn-sm"
                style="width: 50px; margin: 0 2px"
                (click)="newInstitute = !newInstitute"
            >
                No
            </button>
        </div>
    </div>
    <div *ngIf="name" class="results">
        <div class="col-8" style="margin-bottom: 10px">
            <label for="id">ID</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="id"
                    value="{{ id }}"
                    style="
                        width: 500px;
                        cursor: default;
                        background-color: lightgrey;
                    "
                    disabled
                />
            </div>
        </div>
        <div class="col-8">
            <label for="name">Name</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    [(ngModel)]="newName"
                    placeholder="Enter name"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateName(newName)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div class="col-8">
            <label for="country">Country</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="country"
                    [(ngModel)]="country"
                    placeholder="Enter country"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateCountry(country)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <label for="location">Location</label>
        <div style="display: flex">
            <input
                type="text"
                class="form-control"
                id="location"
                [(ngModel)]="location"
                placeholder="Location"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateLocation(location)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
        <div class="col-8" style="margin-bottom: 10px">
            <label for="latitude">Latitude</label>
            <div style="display: flex">
                <input
                    type="number"
                    class="form-control"
                    id="latitude"
                    [(ngModel)]="latitude"
                    placeholder="Enter latitude"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateLatitude(latitude)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div class="col-8" style="margin-bottom: 10px">
            <label for="longitude">Longitude</label>
            <div style="display: flex">
                <input
                    type="number"
                    class="form-control"
                    id="longitude"
                    [(ngModel)]="longitude"
                    placeholder="Enter longitude"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateLongitude(longitude)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div class="col-8" style="margin-bottom: 10px">
            <label for="locationVerified">Location Verified</label>
            <div
                style="
                    display: flex;
                    width: 560px;
                    justify-content: space-between;
                "
            >
                <input
                    type="checkbox"
                    class="form-control"
                    id="locationVerified"
                    [(ngModel)]="locationVerified"
                    style="width: 25px; height: 25px; cursor: default"
                />
                <button
                    type="button"
                    (click)="updateLocationVerified()"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div>
            <div class="col-8" *ngIf="institutionType == 'STARTUP'">
                <label for="homepage_url"
                    >Homepage URL (for Startups ONLY!)</label
                >
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="homepage_url"
                        [(ngModel)]="homepageUrl"
                        placeholder="Enter Homepage URL"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateHomepageUrl(homepageUrl)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
                <label for="founder">Founder (for Startups ONLY!)</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="founder"
                        [(ngModel)]="founder"
                        placeholder="Founder Name"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateFounder(founder)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
                <label for="foundingYear"
                    >Founding Year (for Startups ONLY!)</label
                >
                <div style="display: flex">
                    <input
                        type="number"
                        class="form-control"
                        id="foundingYear"
                        [(ngModel)]="foundingYear"
                        placeholder="Founding Year"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateFoundingYear(foundingYear)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
                <h4 style="margin-top: 20px">Investors (for Startups ONLY!)</h4>
                <div *ngIf="investorList">
                    <ul class="list-group">
                        <li
                            *ngFor="let investor of investorList"
                            style="margin: 0 0 5px 20px"
                        >
                            <b>{{ investor.valueA }}, {{ investor.valueB }}</b>
                            <button
                                type="button"
                                (click)="removeInvestor(investor.valueC)"
                                class="btn btn-danger btn-sm"
                            >
                                Remove
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="col-8" style="margin-bottom: 10px">
                    <label for="contactEmail"
                        >Contact Email (for Startups ONLY!)</label
                    >
                    <div style="display: flex">
                        <input
                            type="text"
                            class="form-control"
                            id="contactEmail"
                            [(ngModel)]="contactEmail"
                            placeholder="Enter Contact Email"
                            style="width: 500px"
                        />
                        <button
                            type="button"
                            (click)="updateContactEmail(contactEmail)"
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                    </div>
                </div>
                <div class="col-8" style="margin-bottom: 10px">
                    <label for="linkedin"
                        >Linkedin Name (for Startups ONLY!)</label
                    >
                    <div style="display: flex">
                        <input
                            type="text"
                            class="form-control"
                            id="linkedin"
                            [(ngModel)]="linkedin"
                            placeholder="Enter Linkedin"
                            style="width: 500px"
                        />
                        <button
                            type="button"
                            (click)="updateLinkedin(linkedin)"
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                    </div>
                </div>
                <div class="col-8" style="margin-bottom: 10px">
                    <label for="autoClassification"
                        >Auto Classification (for Startups ONLY!)</label
                    >
                    <div style="display: flex">
                        <input
                            type="text"
                            class="form-control"
                            id="autoClassification"
                            [(ngModel)]="autoClassification"
                            placeholder="Enter Classificaions"
                            style="width: 500px"
                        />
                        <button
                            type="button"
                            (click)="
                                updateAutoClassification(autoClassification)
                            "
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                    </div>
                </div>
                <div class="col-8" style="margin-bottom: 10px">
                    <label for="manuallyClassified"
                        >Manually Classified (Exclude from AutoClassification
                        job)? (for Startups ONLY!)</label
                    >
                    <div
                        style="
                            display: flex;
                            width: 560px;
                            justify-content: space-between;
                        "
                    >
                        <input
                            type="checkbox"
                            class="form-control"
                            id="manuallyClassified"
                            [(ngModel)]="manuallyClassified"
                            style="width: 25px; height: 25px; cursor: default"
                        />
                        <button
                            type="button"
                            (click)="updateManuallyClassified()"
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                    </div>
                </div>
                <div class="col-8" style="margin-bottom: 10px">
                    <label for="numberOfEmployees"
                        >Number Of Employees (for Startups ONLY!)</label
                    >
                    <div
                        style="
                            display: flex;
                            width: 560px;
                            justify-content: space-between;
                        "
                    >
                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            id="numberOfEmployees"
                            [(ngModel)]="numberOfEmployees"
                        />
                        <button
                            type="button"
                            (click)="updateNumberOfEmployees(numberOfEmployees)"
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-8" style="margin-bottom: 10px">
                <label for="governmentFunded">Government Funded</label>
                <div
                    style="
                        display: flex;
                        width: 560px;
                        justify-content: space-between;
                    "
                >
                    <input
                        type="checkbox"
                        class="form-control"
                        id="governmentFunded"
                        [(ngModel)]="governmentFunded"
                        style="width: 25px; height: 25px; cursor: default"
                    />
                    <button
                        type="button"
                        (click)="updateGovernmentFunded()"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8" style="margin-bottom: 10px">
                <label for="classification">Classification</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="classification"
                        [(ngModel)]="classification"
                        placeholder="Enter classification"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateClassification(classification)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8" style="margin-bottom: 10px">
                <label for="description">Description</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="description"
                        [(ngModel)]="description"
                        placeholder="Enter description"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateDescription(description)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <label for="dropdownMenuButton">Type</label>
            <div class="dropdown">
                <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {{ institutionType }}
                    <span class="caret"></span>
                </button>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button
                        class="dropdown-item"
                        type="button"
                        *ngFor="let insType of insTypes"
                        (click)="updateInsType(insType)"
                    >
                        {{ insType }}
                    </button>
                </div>
            </div>

            <h4 style="margin-top: 20px">Other names</h4>
            <div *ngIf="nickNames">
                <ul class="list-group">
                    <li
                        *ngFor="let nickName of nickNames"
                        style="margin: 0 0 5px 20px"
                    >
                        <b>{{ nickName }}</b>
                        <button
                            type="button"
                            (click)="deleteNickName(nickName)"
                            class="btn btn-danger btn-sm"
                        >
                            Delete
                        </button>
                    </li>
                </ul>
            </div>
            <input
                type="text"
                id="newNick"
                [(ngModel)]="newNick"
                placeholder="Enter other name"
                name="newNick"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="AddNickName(newNick)"
                class="btn btn-primary btn-sm"
            >
                Add
            </button>

            <h4 style="margin-top: 20px">Black list</h4>
            <div *ngIf="blackList">
                <ul class="list-group">
                    <li
                        *ngFor="let black of blackList"
                        style="margin: 0 0 5px 20px"
                    >
                        <b>{{ black }}</b>
                        <button
                            type="button"
                            (click)="deleteBlackList(black)"
                            class="btn btn-danger btn-sm"
                        >
                            Delete
                        </button>
                    </li>
                </ul>
            </div>
            <input
                type="text"
                placeholder="Enter black list name"
                [(ngModel)]="newBlackList"
                name="newBlack"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="AddBlacklist(newBlackList)"
                class="btn btn-primary btn-sm"
            >
                Add
            </button>

            <h4 style="margin-top: 20px">Patent name</h4>
            <div *ngIf="patentNames">
                <ul class="list-group">
                    <li
                        *ngFor="let patentName of patentNames"
                        style="margin: 0 0 5px 20px"
                    >
                        <b>{{ patentName }}</b>
                        <button
                            type="button"
                            (click)="deletePatentName(patentName)"
                            class="btn btn-danger btn-sm"
                        >
                            Delete
                        </button>
                    </li>
                </ul>
            </div>
            <input
                type="text"
                placeholder="Enter patent name"
                [(ngModel)]="newPatentName"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="AddPatentName(newPatentName)"
                class="btn btn-primary btn-sm"
            >
                Add
            </button>

            <h4 style="margin-top: 20px">Memberships</h4>
            <div *ngIf="memberShips">
                <ul class="list-group">
                    <li
                        *ngFor="let memberShip of memberShips"
                        style="margin: 0 0 5px 20px"
                    >
                        <b>{{ memberShip }}</b>
                        <button
                            type="button"
                            (click)="deleteMembership(memberShip)"
                            class="btn btn-danger btn-sm"
                        >
                            Delete
                        </button>
                    </li>
                </ul>
            </div>
            <input
                type="text"
                placeholder="Enter membership name"
                [(ngModel)]="newMembershipName"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="addMembership(newMembershipName)"
                class="btn btn-primary btn-sm"
            >
                Add
            </button>

            <h4 style="margin-top: 20px" *ngIf="relatedInstitutions">
                Related institutions
            </h4>
            <div *ngIf="relatedInstitutions">
                <ul class="list-group">
                    <li
                        *ngFor="let inst of relatedInstitutions"
                        style="margin: 0 0 5px 20px; text-transform: capitalize"
                    >
                        <b>{{ inst }}</b>
                        <a>
                            <img
                                src="assets/images/edit.png"
                                class="edit"
                                (click)="relatedInstitutionClicked(inst)"
                            />
                        </a>
                    </li>
                </ul>
            </div>

            <h4 style="margin-top: 10px">Logo Image</h4>
            <div
                *ngIf="imageUrl"
                [style.backgroundImage]="'url(' + imageUrl + ')'"
                style="
                    width: 300px;
                    height: 300px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                "
            ></div>
            <div class="col-8">
                <label for="imageUrl">Logo URL</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="imageUrl"
                        [(ngModel)]="imageUrl"
                        disabled
                        style="width: 500px; cursor: text"
                    />
                </div>
                <button
                    type="button"
                    [disabled]="removeBtnDisabled()"
                    (click)="removeImage()"
                    class="btn btn-primary btn-sm"
                >
                    Remove
                </button>
            </div>
            <div class="col-8">
                <label for="imageFile">Image file:</label>
                <input
                    id="imageFile"
                    type="file"
                    style="text-align: center"
                    (change)="updateFile($event)"
                />
                <button
                    type="button"
                    [disabled]="btnDisabled()"
                    (click)="updateImage()"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>

            <div style="margin-top: 20px">
                <button
                    type="button"
                    (click)="deleteInstitution()"
                    class="btn btn-primary btn-lg"
                >
                    Delete institution
                </button>
            </div>
        </div>
    </div>
</div>
