import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {Researcher, SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {UtilitiesService} from "../../../../utilities.service";
import {DataService, NavRequest} from "../../../../data.service";
import {Subscription} from "rxjs";
import {NetworkService} from "../../../../network.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CtExpertsModalComponent} from "../ct-experts-modal/ct-experts-modal.component";
import {ExpertsModalData} from "../../../../api-responses/Dialogs.interface";
import {AddExpertToCalloutModalComponent} from "../../dialogs/callout/add-expert-to-callout-modal.component";
import {CreateCalloutModalComponent} from "../../../callout/create/create-callout-modal.component";
import {Callout, CreateCalloutDialogData} from "../../../callout/callouts/callout.interface";

@Component({
    selector: 'ct-experts',
    templateUrl: './ct-experts.component.html',
    styleUrls: ['./ct-experts.component.scss']
})
export class CtExpertsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() searchResults: SearchQueryResults;
    @Output() setLoading = new EventEmitter();

    patentsPresent: boolean = false;
    selectedExperts = [];

    filteredExperts: Researcher[] = [];

    private fromModalToData = Subscription.EMPTY;
    private selectedExpert: Researcher;
    private dialogRef: any;

    constructor(
        public dataService: DataService,
        public matDialog: MatDialog,
        private networkService: NetworkService,
        public newCalloutDialogRef: MatDialogRef<CreateCalloutModalComponent>) {

    }

    ngOnInit(): void {}

    open(expert, experts) {
        this.matDialog.open(CtExpertsModalComponent, {
            data: {
                expert: expert,
                experts: this.filteredExperts,
                searchResults: this.searchResults,
                simpleNavigation: true, hideNav: false
            } as ExpertsModalData
        });
    }

    ngOnDestroy(): void {
        this.fromModalToData.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResults) {
            this.searchResults = changes.searchResults.currentValue;
            this.filteredExperts = this.filterExperts();
        }
    }

    filterExperts() {
        let response = this.searchResults.expertSearchResult.researchers;

        if (!['world wide', 'all countries', 'world'].some(el => this.searchResults && this.searchResults.filterCountry.toLowerCase().includes(el))) {
            response = response.filter(expert => expert.countries.includes(this.searchResults.filterCountry));
        }

        if (this.searchResults.filterCtPhase !== 'All Phases') {
            response = response.filter(expert => expert.clinicalTrialPhases.includes(this.searchResults.filterCtPhase));
        }

        if (this.searchResults.filterCtStatus !== 'All Statuses') {
            response = response.filter(expert => expert.clinicalTrialStatus.includes(this.searchResults.filterCtStatus));
        }

        if (this.searchResults.filterHasGrants) {
            response = response.filter(expert => expert.grants.length > 0);
        }

        return response;
    }

    //
    // checkPatents() {
    //     this.patentsPresent = false;
    //
    //     this.searchResults.expertSearchResult.researchers.forEach(expert => {
    //         if (expert.patentsScore > 0) {
    //             this.patentsPresent = true;
    //         }
    //     });
    // }

    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }

    expertCardClicked(event, expert) {
        // if (event.target.classList[0] != 'name' && event.target.classList[0] != 'email' && event.target.classList[0] != 'read_more_button') {
        //     if (!this.selectedExperts.includes(expert)) {
        //         this.selectedExperts.push(expert);
        //     } else {
        //         this.selectedExperts = this.selectedExperts.filter(e => e !== expert);
        //     }
        // }
    }

    expertClicked(expert: Researcher) {
        this.open(expert, true);
    }

    private shouldEnableNextExpert(_filteredExperts, expert) {
        return _filteredExperts.indexOf(this.selectedExpert) < _filteredExperts.length - 1;
    }

    shouldSave() {
        this.dataService.saveSearchUpdate(new NavRequest('', NavRequest.MSG.SHOULD_SAVE));
    }

    addToRequest(expert: Researcher) {
        this.dialogRef = this.matDialog.open(AddExpertToCalloutModalComponent, { data: expert});
    }

    newRequest(expert: Researcher) {
        this.newCalloutDialogRef = this.matDialog.open(CreateCalloutModalComponent, {
            data: {
                researcher: expert
            } as CreateCalloutDialogData
        });
        this.newCalloutDialogRef && this.newCalloutDialogRef.afterClosed().toPromise().then((callout: Callout) => {
            // create the callout
            window.open('/app/callout/' + callout.id, '_blank');
        })
    }

    preventDefault($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
    }
}
