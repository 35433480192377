import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {TreebuteProjectSummaryReducer} from "../../../../utils/treebuteProjectSummaryReducer";

@Component({
    selector: 'tune-search',
    templateUrl: './tune-search.component.html',
    styleUrls: ['./tune-search.component.scss'],
})
export class TuneSearchComponent implements OnInit, OnDestroy, OnChanges {

    @Input() wordCloud: Map<String, Int16Array>;
    @Output() addTermToSearch = new EventEmitter();
    @Input() query!: string;
    @Output() queryChange = new EventEmitter<string>()

    @Input() searchResults: SearchQueryResults;

    showWordMap: boolean = false;
    showWordSuggest: boolean = false;
    wordSuggestion = undefined;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.wordCloud) {
            this.showWordSuggest = false;
            this.showWordMap = false;
        }
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    suggestWord($event: any) {
        this.showWordSuggest = true;
        this.wordSuggestion = $event;
    }

    getTerm() {
        return this.wordSuggestion.includes(" ") ?
            "\"" + this.wordSuggestion + "\"" :
            this.wordSuggestion;
    }

    // addWord(word: string) {
    //     this.addTermToSearch.emit(`${word? word + ' ' : ''}${this.getTerm()}`)
    //     this.showWordSuggest = false;
    // }

    addWord(word: string) {
        try {
            const term = `${word? word + ' ' : ''}${this.getTerm()}`;
            let result = this.query;
            if (!result) {
                // "AND something" --> "something"
                result = term.split[" "][1];
            } else {
                result = this.query + " " + term;
            }

            this.queryChange.emit(result);
            this.showWordSuggest = false;
        }
        catch (e) {}
    }

    showComponent() {
        return Object.keys(this.wordCloud).length > 0;
    }

    isNotNLP() {
        let isNLP =
            (TreebuteProjectSummaryReducer.isIndustry(this.searchResults) && this.searchResults.institutionSearchResult.isNLP) ||
            (TreebuteProjectSummaryReducer.isExperts(this.searchResults) && this.searchResults.expertSearchResult.isNLP) ||
            (TreebuteProjectSummaryReducer.isTileSearch(this.searchResults) && this.searchResults.expertSearchResult.isNLP);

        return !isNLP;
    }
}
