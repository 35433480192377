import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-patents-list',
    templateUrl: './patents-list.component.html',
    styleUrls: ['./patents-list.component.scss'],
})
export class PatentsListComponent implements OnInit {
    @Input() patents;

    constructor() {}

    ngOnInit() {}
}
