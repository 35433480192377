<form class="form-inline" role="form" (ngSubmit)="onSearchSubmit()">
    <div class="form-group" style="background: #3e3e3e">
        <input
            [(ngModel)]="searchString"
            type="text"
            name="searchString"
            class="form-control"
            id="searchString"
            placeholder="Search this map"
        />

        <button
            *ngIf="!loading"
            [disabled]="searchDisabled()"
            type="submit"
            class="btn btn-xs"
            [class.hover]="!searchDisabled()"
        >
            <span
                class="glyphicon glyphicon-search"
                style="font-size: 21px; padding-top: 2px"
            ></span>
        </button>

        <button
            *ngIf="loading"
            [disabled]="true"
            class="btn btn-xs"
            id="loadingBtn"
            style="background-image: url('assets/images/loadloop.gif')"
        ></button>

        <button
            *ngIf="topics"
            class="btn btn-xs"
            (click)="cancelSearch()"
            [class.hover]="!searchDisabled()"
        >
            <span
                class="glyphicon glyphicon-remove"
                style="font-size: 24px"
            ></span>
        </button>
    </div>
</form>

<div
    *ngIf="noResults"
    style="width: 189px; position: absolute; top: 85px; z-index: 9"
    id="{{ type }}Noresults"
>
    <h4>No results</h4>
</div>

<div *ngIf="topics" class="topics" id="{{ type }}Topics">
    <ul class="list-group list-group-flush">
        <li *ngFor="let topic of topics" class="list-group-item">
            <a class="dropdown-item btn-sm" (click)="topicSelected(topic)">{{
                topic.heading
            }}</a>
        </li>
    </ul>
</div>
