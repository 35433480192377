<div class="dialog">
    <div class="header-title py-3">
        <div>{{ "Share Project" }}</div>
        <div class="close-button close-icon"
             (click)="cancel()"
        ></div>
    </div>
    <div class="pb-3">
        <div class="pb-2">
            Setup share and canonical public URL
        </div>
        <div class="pb-2">
            <span class="pr-3">Enable Share: </span>
            <mat-slide-toggle [(ngModel)]="data.shared">
            </mat-slide-toggle>
        </div>
        <div class="pb-2">
            <div class="pb-1">Canonical URL:</div>
            <input type="text" [(ngModel)]="data.canonicalUrl" style="width: 93%" (ngModelChange)="checkCanonical($event)" />
            <div class="spinner-border ml-3" role="status" style="width: 16px; height: 16px"
                 [hidden]="!showLoader">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="text-warning" [hidden]="validCanonical===true || validCanonical === null">This url already exists</div>
        </div>
        <div class="pb-2 font-italic">
            ✨ <a href="{{ getShareLink() }}" target="_blank">{{ getShareLink() }}</a>

        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <button class="btn btn-dark border text-light" (click)="cancel()">
                Cancel
            </button>
            <button class="btn btn-primary" (click)="save()" [disabled]="!validCanonical">
                Save
            </button>
        </div>
    </div>
</div>
