<div class="share-project-container">
    <div class="shared-state-container" >
        <div class="shared-state-container">
            <button class="btn btn-dark border text-light" (click)="showShareModal()"
                    [disabled]="sharingIsInProgress || loading || removeShareIsInProgress || !project.searchCount">Share</button>
        </div>
        <div class="shared-state-container" *ngIf="!loading && sharedProject && sharedProject.enabled">
            <div>
                <a href="{{shareLink}}" target="_blank"  *ngIf="!removeShareIsInProgress && !sharingIsInProgress">
                    <div class="p-2">
                        <span class="the-project-is-shared">The project is shared </span>
                        <span class="material-icons-outlined lower-icon open-in-new">
                            open_in_new
                        </span>
                    </div>
                </a>
            </div>
            <div style="position: relative" *ngIf="false">
                <button style="position: absolute; top: -3px" mat-icon-button class="copy-to-clipboard"
                        (click)="copyUrl()">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="pl-3 shared-state-container" *ngIf="sharingIsInProgress || removeShareIsInProgress">
        <div class="action-container">
            <span *ngIf="sharingIsInProgress" class="pr-1">Exporting...</span>
            <span *ngIf="removeShareIsInProgress" class="pr-1">Removing...</span>
        </div>
        <div class="action-container pl-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
    </div>
</div>
