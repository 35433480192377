import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DataService} from "../../../../data.service";
import {NetworkService} from "../../../../network.service";
import {Institution, Researcher} from "../../../../api-responses/SearchQueryResults.interface";
import {Callout} from "../../../callout/callouts/callout.interface";
import {YesNoModalComponent} from "../../../dialogs/yesNo/yes-no-modal.component";
import {NewSearchModalData, YesNoModalData} from "../../../../api-responses/Dialogs.interface";

@Component({
    selector: 'add-expert-to-callout',
    templateUrl: './add-expert-to-callout-modal.component.html',
    styleUrls: ['./add-expert-to-callout-modal.component.scss'],
})
export class AddExpertToCalloutModalComponent implements OnInit {

    public loading: boolean = false;
    public callouts: Callout[] = [];
    public filteredCallouts: Callout[] = [];

    constructor(
        public dataService: DataService,
        public networkService: NetworkService,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<AddExpertToCalloutModalComponent>,
        @Inject(MAT_DIALOG_DATA) public researcher: Researcher
    ) {
    }

    ngOnInit() {
        this.networkService.callouts.getAll().then((callouts: Callout[]) => {
            this.callouts = callouts;
            this.filteredCallouts = this.callouts;
        });
    }

    filter(filterInput: string) {
        this.filteredCallouts = this.callouts
            .filter(x => x.title && x.title.toLowerCase().indexOf(filterInput.toLowerCase()) > -1)
            .filter(x => x.description && x.description.toLowerCase().indexOf(filterInput.toLowerCase()) > -1);
    }

    closeModal() {
        this.dialogRef.close();
    }

    addToCallout(callout: Callout) {
        let dialogRef = this.matDialog.open(YesNoModalComponent, {
            data: {
                dialogTitle: 'Add to Request',
                dialogDescription: `Do you want to add ${this.researcher.firstName} ${this.researcher.lastName} to this request? \n(${callout.title})`,
                yesText: 'Yes',
                noText: 'Cancel',
                buttons: 2
            } as YesNoModalData,
        });

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result === 'yes') {
                    this.networkService.callouts.addCandidate(callout, this.researcher)
                        .then(() => {
                            return this.matDialog.open(YesNoModalComponent, {
                                data: {
                                    dialogTitle: 'Expert in Request',
                                    dialogDescription: 'The expert was added to the request',
                                    yesText: 'OK',
                                    buttons: 1
                                } as YesNoModalData,
                            });
                        })
                        .catch((e) => {
                            return this.matDialog.open(YesNoModalComponent, {
                                data: {
                                    dialogTitle: 'Error',
                                    dialogDescription: 'The expert was NOT added to the request!',
                                    yesText: 'OK',
                                    buttons: 1
                                } as YesNoModalData,
                            });
                        })
                        .then(() => {
                            window.open(`/app/callout/${callout.id}`, '_blank');
                        })
                }
            });
    }
}
