import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DataService, NavRequest} from "../../../../data.service";
import {AddCompanyModalComponent} from "../../dialogs/add-company-modal/add-company-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {InnovationProject} from "../../../../api-responses/InnovationProjects.interface";
import {ProjectStateService} from "../../../projects-page/project-state-service";
import {TreebuteProjectSummaryReducer} from "../../../../utils/treebuteProjectSummaryReducer";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-search-tools',
    templateUrl: './search-tools.component.html',
    styleUrls: ['./search-tools.component.scss'],
    providers: [ProjectStateService]
})
export class SearchToolsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() searchResults: SearchQueryResults;
    @Input() project: InnovationProject;
    @Input() loading: boolean;

    @Output() excludeAll = new EventEmitter();
    @Output() includeAll = new EventEmitter();
    @Output() add = new EventEmitter();
    showAddButton: boolean = false;

    subscriptions: Subscription[] = [];

    constructor(public dataService: DataService,
                public projectStateService: ProjectStateService,
                public matDialog: MatDialog) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.projectStateService.projectState.subscribe((project) => {
                this.project = project;
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    addClick() {
        // launch modal
        const dialogRef = this.matDialog.open(AddCompanyModalComponent);

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result && result.companies && result.companies.length > 0) {
                    console.log(`Dialog result: ${result}`);
                    this.add.emit(result.companies);
                    this.dataService.saveSearchUpdate(new NavRequest('', NavRequest.MSG.SHOULD_SAVE));
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResults && changes.searchResults.currentValue) {
            this.showAddButton = this.searchResults.searchType === 'STARTUPS';
        }
    }

    expertsToolLink() {
        return `/app/projects/${this.project.id}/experts-tool`;
    }

    showExpertsTool() {
        return this.project &&
            (TreebuteProjectSummaryReducer.isExperts(this.searchResults) ||
                TreebuteProjectSummaryReducer.isTileSearch(this.searchResults));
    }

    navToExpertsTool() {
        this.dataService.saveSearchUpdate(new NavRequest(`/app/projects/${this.project.id}/experts-tool`, NavRequest.MSG.NAV));
    }

    navToCtTool() {
        this.dataService.saveSearchUpdate(new NavRequest(`/app/projects/${this.project.id}/ct-tool`, NavRequest.MSG.NAV));
    }
}
