<div class="patents-content">
    Patents :
    <ul class="list-group">
        <li *ngFor="let patent of patents" class="list-group-item">
            <a
                target="_blank"
                href="{{ patent.valueB }}"
                class="dropdown-item btn-sm"
                >{{ patent.valueA }}</a
            >
        </li>
    </ul>
</div>
