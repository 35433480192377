import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessColors, ExpertsColors} from "../../../../utils/colors.enum";
import {SearchResult} from "../../../../api-responses/SearchResult.interface";
import {InnovationProject} from "../../../../api-responses/InnovationProjects.interface";
import {ReadOnlyProjectService} from "../../../../read-only-project-service";

@Component({
    selector: 'app-search-chip',
    templateUrl: './search-chip.component.html',
    styleUrls: ['./search-chip.component.scss'],
})
export class SearchChipComponent implements OnInit {
    @Input() searchResult: SearchResult;
    @Input() project: InnovationProject;
    @Output() userClickDelete = new EventEmitter();

    @Output() clickChip = new EventEmitter();
    @Output() deleteSearch = new EventEmitter<number>;

    constructor(public readOnlyProjectService: ReadOnlyProjectService) {}

    ngOnInit() {}

    getColor() {
        let bgColor;
        if (this.searchResult.searchType == 'ACADEMIC_EXPERTS') {
            bgColor = ExpertsColors.ACADEMIC_EXPERTS;
        }
        else if (this.searchResult.searchType == 'INDUSTRY_EXPERTS') {
            bgColor = ExpertsColors.INDUSTRY_EXPERTS;
        }
        else if (this.searchResult.searchType == 'CT_EXPERTS') {
            bgColor = ExpertsColors.CT_EXPERTS;
        }
        else if (this.searchResult.searchType == 'TILE_SEARCH_EXPERTS') {
            bgColor = ExpertsColors.TILE_SEARCH_EXPERTS;
        }
        else if (this.searchResult.searchType == 'STARTUPS') {
            bgColor = BusinessColors.STARTUPS;
        }
        else if (this.searchResult.searchType == 'CORPORATES') {
            bgColor = BusinessColors.CORPORATES;
        }

        return {
            'background-color': bgColor
        }
    }

    navigateToSearch() {

    }

    getRouterLink() {
        return '/app/projects/' + this.project.id + '/search/' + this.searchResult.id ;
    }

    getToolTip() {
        let result;
        if (this.searchResult.searchType === "ACADEMIC_EXPERTS") {
            result = "Academic Experts"
        }
        else if (this.searchResult.searchType === "TILE_SEARCH_EXPERTS") {
            result = "Experts from Explorer Map"
        }
        else if (this.searchResult.searchType === "INDUSTRY_EXPERTS") {
            result = "Industry Experts"
        }
        else if (this.searchResult.searchType === "CT_EXPERTS") {
            result = "Clinical Trial Experts"
        }
        else if (this.searchResult.searchType === "STARTUPS") {
            result = "Startups"
        }
        else if (this.searchResult.searchType === "CORPORATES") {
            result = "Corporates"
        }

        result += `\n ${this.searchResult.count} Results`

        return result;
    }

    _clickChip($event) {
        if (this.readOnlyProjectService.isReadOnlyProject) {
            $event.preventDefault();
            $event.stopPropagation();
            this.clickChip.emit(this.searchResult);
        }
    }


    _deleteSearch($event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        this.deleteSearch.emit(this.searchResult.id)
    }
}
