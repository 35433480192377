<div class="dialog">
    <div class="header">
        <div
            class="close-button close-icon"
            (click)="close()"
        ></div>
        <div class="header-title">Project Details</div>
    </div>
    <div class="info">
        <form class="new-project-input">
            <div class="dialog-item">
                <div class="title name">Your project’s name</div>
                <input
                    type="text"
                    class="input name"
                    placeholder="Project Name"
                    [(ngModel)]="data.name"
                    [ngModelOptions]="{standalone: true}"
                />
            </div>
            <div class="dialog-item">
                <div class="title description">Add a description</div>
                <textarea
                    minRows={3}
                    class="input description"
                    placeholder="Project Description"
                    [(ngModel)]="data.description"
                    [ngModelOptions]="{standalone: true}"
                ></textarea>
            </div>
        </form>
<!--        <div [ngClass]="{ 'create-mode': true, 'edit-mode': isEditMode }">-->
<!--            <div class="dialog-fixed-item">-->
<!--                <div class="subtitle owner">Owner</div>-->
<!--                <div-->
<!--                    class="dialog-info owner"-->
<!--                    *ngIf="this.networkService.userInfo$ | async as userInfo"-->
<!--                >-->
<!--                    {{-->
<!--                        userInfo.firstName[0].toUpperCase() +-->
<!--                            userInfo.firstName.slice(1) +-->
<!--                            ' ' +-->
<!--                            userInfo.lastName[0].toUpperCase() +-->
<!--                            userInfo.lastName.slice(1)-->
<!--                    }}-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="dialog-fixed-item">-->
<!--                <div class="subtitle Created">Created</div>-->
<!--                <div class="dialog-info Created">{{this.creationDate.format('MMM DD, YYYY')}}</div>-->
<!--            </div>-->
<!--            <div class="dialog-fixed-item">-->
<!--                <div class="subtitle modified">Last modified</div>-->
<!--                <div class="dialog-info modified">Today</div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="left-side">
                <div class="button-delete" (click)="deleteProject()">
                    <div class="delete-icon"></div>
                    <div class="title-delete">Delete</div>
                </div>
            </div>
            <div class="right-side">
                <div class="button-cancel" (click)="close()">
                    Cancel
                </div>
<!--                <div class="button-share" (click)="shareProject()">Share</div>-->
                <div class="button-save" (click)="updateProject()">Save</div>
            </div>
        </div>
    </div>
</div>
