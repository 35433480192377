import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../network.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../data.service';

@Component({
    selector: 'app-institution-manager',
    templateUrl: './institution-manager.component.html',
    styleUrls: ['./institution-manager.component.scss'],
})
export class InstitutionManagerComponent implements OnInit {
    searchString;
    noResults = false;
    newInstitute = false;
    duringUpdate = false;
    id;
    name: string;
    address: string;
    latitude: number;
    longitude: number;
    locationVerified: boolean;
    country: string;
    homepageUrl: string;
    founder: string;
    location: string;
    foundingYear: number;
    institutionType: string;
    governmentFunded: boolean;
    classification: string;
    autoClassification: string;
    manuallyClassified: boolean;
    contactEmail: string;
    description: string;
    numberOfEmployees: number;
    linkedin: string;
    memberShips: [];
    relatedInstitutions: [];
    nickNames: [];
    patentNames: [];
    blackList: [];
    investorList: [];
    imageUrl;
    imageFile;

    newNick: string;
    newBlackList: string;
    newPatentName: string;
    newMembershipName: string;
    newName: string;
    insTypes = [
        'UNKNOWN',
        'University',
        'Hospital',
        'Research institute',
        'Corporate',
        'Eco system',
        'Garbage',
        'Startup',
        'CRO',
    ];

    constructor(
        private networkService: NetworkService,
        private http: HttpClient,
        public dataService: DataService,
    ) {}

    ngOnInit() {
        if (this.dataService.insMgntQuery !== undefined) {
            this.searchString = this.dataService.insMgntQuery;
            this.onSearchSubmit();
        }
    }

    searchDisabled() {
        return this.searchString === undefined || this.searchString.length < 3;
    }

    onSearchSubmit() {
        this.id = null;
        this.name = '';
        this.address = '';
        this.country = '';
        this.homepageUrl = '';
        this.founder = '';
        this.location = '';
        this.foundingYear = null;
        this.newName = '';
        this.nickNames = [];
        this.patentNames = [];
        this.blackList = [];
        this.investorList = [];
        this.memberShips = [];
        this.institutionType = 'UNKNOWN';
        this.latitude = null;
        this.longitude = null;
        this.locationVerified = null;
        this.autoClassification = null;
        this.manuallyClassified = null;
        this.contactEmail = null;
        this.numberOfEmployees = null;
        this.linkedin = null;
        this.networkService.getInstitutionByName(this.searchString).subscribe(
            (data) => this.handleInstitutionResponse(data),
            (error) => console.log(error),
        );
    }

    createInstitution() {
        this.networkService.createInstitution(this.searchString).subscribe(
            (institution) => this.handleInstitutionResponse(institution),
            (error) => console.log(error),
        );
    }

    updateInsType(insType) {
        this.institutionType = insType;
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'institutionType',
                this.institutionType,
                true,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    deleteInstitution() {
        if (confirm('Are you sure?')) {
            this.networkService.deleteInstitution(this.searchString).subscribe(
                () => this.onSearchSubmit(),
                (error) => console.log(error),
            );
        }
    }

    handleInstitutionResponse(data) {
        this.newNick = '';
        this.newBlackList = '';
        this.newPatentName = '';
        if (!data) {
            this.noResults = true;
            this.newInstitute = true;
        } else {
            this.noResults = false;
            this.id = data.id;
            this.name = data.name;
            this.newName = this.name;
            this.address = data.address;
            this.latitude = data.latitude;
            this.longitude = data.longitude;
            this.locationVerified = data.locationVerified;
            this.country = data.country;
            this.homepageUrl = data.homepageUrl;
            this.founder = data.founder;
            this.location = data.location;
            this.foundingYear = data.foundingYear;
            this.nickNames = data.nickNames;
            this.patentNames = data.patentNames;
            this.memberShips = data.memberShips;
            this.blackList = data.blackList;
            this.investorList = data.investors;
            this.institutionType = data.institutionType;
            this.imageUrl = data.logoUrl;
            this.description = data.description;
            this.classification = data.classification;
            this.relatedInstitutions = data.relatedInstitutions;
            this.autoClassification = data.autoClassification;
            this.manuallyClassified = data.manuallyClassified;
            this.contactEmail = data.contactEmail;
            this.numberOfEmployees = data.numberOfEmployees;
            this.linkedin = data.linkedInURL;
            this.governmentFunded = data.governmentFunded;
        }
    }

    updateName(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'name', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateCountry(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'country', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateHomepageUrl(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'homepageUrl', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateFounder(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'founder', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateLocation(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'location', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateFoundingYear(name) {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'foundingYear',
                name == null ? '' : name,
                true,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateClassification(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'classification', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateDescription(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'description', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateAddress(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'address', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateLatitude(latitude) {
        this.networkService
            .updateInstitutionDetails(this.name, 'latitude', latitude, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateLongitude(longitude) {
        this.networkService
            .updateInstitutionDetails(this.name, 'longitude', longitude, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    AddNickName(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'nick', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    deleteNickName(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'nick', name, false)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    AddBlacklist(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'blacklist', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    deleteBlackList(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'blacklist', name, false)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    removeInvestor(id) {
        if (confirm('Are you sure?')) {
            this.networkService
                .updateInstitutionDetails(this.name, 'investor', id, false)
                .subscribe(
                    (data) => this.handleInstitutionResponse(data),
                    (error) => console.log(error),
                );
        }
    }

    AddPatentName(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'patentName', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    deletePatentName(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'patentName', name, false)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    addMembership(membership) {
        this.networkService
            .updateInstitutionDetails(this.name, 'membership', membership, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    deleteMembership(membership) {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'membership',
                membership,
                false,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateImage() {
        this.duringUpdate = true;
        this.networkService
            .updateInstitutionImage(this.name, 'image', this.imageFile)
            .subscribe(
                (data) => {
                    this.handleInstitutionResponse(data);
                    this.duringUpdate = false;
                },
                (error) => {
                    console.log(error);
                    this.duringUpdate = false;
                },
            );
    }

    removeImage() {
        if (confirm('Are you sure?')) {
            this.duringUpdate = true;
            this.networkService
                .updateInstitutionImage(this.name, 'image-remove', null)
                .subscribe(
                    (data) => {
                        this.handleInstitutionResponse(data);
                        this.duringUpdate = false;
                    },
                    (error) => {
                        console.log(error);
                        this.duringUpdate = false;
                    },
                );
        }
    }

    updateFile(event) {
        this.imageFile = event.target.files[0];
    }

    removeBtnDisabled() {
        return this.imageUrl == null || this.duringUpdate;
    }

    btnDisabled() {
        return this.imageFile == null || this.duringUpdate;
    }

    updateLocationVerified() {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'locationVerified',
                null,
                this.locationVerified,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateGovernmentFunded() {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'governmentFunded',
                null,
                this.governmentFunded,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateAutoClassification(autoClassification) {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'auto-classification',
                autoClassification,
                true,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateManuallyClassified() {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'manually-classified',
                null,
                this.manuallyClassified,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateContactEmail(contactEmail) {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'contact-email',
                contactEmail,
                true,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateNumberOfEmployees(name) {
        this.networkService
            .updateInstitutionDetails(
                this.name,
                'number-of-employees',
                name,
                true,
            )
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    updateLinkedin(name) {
        this.networkService
            .updateInstitutionDetails(this.name, 'linkedin', name, true)
            .subscribe(
                (data) => this.handleInstitutionResponse(data),
                (error) => console.log(error),
            );
    }

    relatedInstitutionClicked(inst) {
        console.log(inst);
        this.searchString = inst;
        this.onSearchSubmit();
    }
}
