import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/data.service';

@Component({
    selector: 'app-machine-management',
    templateUrl: './machine-management.component.html',
    styleUrls: ['./machine-management.component.scss'],
})
export class MachineManagementComponent implements OnInit {
    constructor(
        private networkService: NetworkService,
        public dataService: DataService,
        private http: HttpClient,
    ) {}

    queryName: string;
    queryMake: string;
    queryModel: string;
    machineId: string;
    instanceId: string;
    showMachine = false;
    selectedMachineId;
    message;
    machines;

    ngOnInit() {
        if (this.dataService.machineParameters !== undefined) {
            this.queryName = this.dataService.machineParameters.name;
            this.queryMake = this.dataService.machineParameters.make;
            this.queryModel = this.dataService.machineParameters.model;
            this.machineId = this.dataService.machineParameters.machineId;
            this.instanceId = this.dataService.machineParameters.instanceId;

            this.onSearchSubmit();
            this.openMachineDetails(this.machineId);
        }
    }

    onSearchSubmit() {
        this.message = null;
        this.showMachine = false;
        this.machines = [];
        this.networkService
            .getMachinesForAdmin(
                this.queryName === undefined ? '' : this.queryName,
                this.queryMake === undefined ? '' : this.queryMake,
                this.queryModel === undefined ? '' : this.queryModel,
            )
            .subscribe(
                (data) => {
                    if (!data) {
                        this.message = 'No machines found.';
                    } else {
                        this.machines = data;
                    }
                },
                (error) => (this.message = error.error.toString()),
            );
    }

    createMachine() {
        this.message = null;
        this.networkService
            .createMachine(
                this.queryName === undefined ? '' : this.queryName,
                this.queryMake === undefined ? '' : this.queryMake,
                this.queryModel === undefined ? '' : this.queryModel,
            )
            .subscribe(
                (data) => this.openMachineDetails(data),
                (error) => (this.message = error.error.toString()),
            );
    }

    openMachineDetails(id) {
        this.showMachine = false;
        this.selectedMachineId = id;
        setTimeout(() => (this.showMachine = true), 1);
    }
}
