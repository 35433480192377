import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-machine-editor',
    templateUrl: './machine-editor.component.html',
    styleUrls: ['./machine-editor.component.scss'],
})
export class MachineEditorComponent implements OnInit {
    @Input() id;
    @Input() instanceId;
    @Output() machineDelete = new EventEmitter();

    showModal = false;

    showCreateInstace = false;
    showInstance = false;
    selectedInstanceId;
    institutions;
    newDepartmentName = '';
    dropdownInsName;
    dropdownInsId;
    duringUpdate = false;

    name: string;
    make: string;
    model: string;
    class: string;
    order: string;
    genus: string;
    imageUrl: string;
    imageFile;
    documents = [];

    instances;

    constructor(
        private networkService: NetworkService,
        private http: HttpClient,
    ) {}

    ngOnInit() {
        this.showModal = true;
        this.networkService.getMachineById(this.id).subscribe(
            (data) => this.handleMachineResponse(data),
            (error) => console.log(error),
        );
        if (this.institutions === undefined) {
            this.getInstitutions();
        }
        if (this.instanceId !== undefined) {
            this.findInstances();
            this.onInstanceClick(this.instanceId);
        }
    }

    getInstitutions() {
        this.networkService.getAllInstitutions().subscribe(
            (data) => (this.institutions = data),
            (error) => console.log(error),
        );
    }

    createNewInstance(institutionId, departmentName) {
        this.networkService
            .createNewMachineInstance(this.id, institutionId, departmentName)
            .subscribe(
                (data) => {
                    this.showCreateInstace = false;
                    this.onInstanceClick(data);
                },
                (error) => {
                    console.log(error);
                    alert(error.error.toString());
                },
            );
    }

    handleMachineResponse(data) {
        this.id = data.id;
        this.name = data.name;
        this.make = data.make;
        this.model = data.model;
        this.class = data.className;
        this.order = data.order;
        this.genus = data.genus;
        this.imageUrl = data.imageUrl;
        this.documents = data.documents;
        this.duringUpdate = false;
    }

    updateField(field, value) {
        this.duringUpdate = true;
        this.networkService
            .updateMachineDetails(this.id, field, value, this.imageFile)
            .subscribe(
                (data) => this.handleMachineResponse(data),
                (error) => {
                    this.duringUpdate = false;
                    console.log(error);
                    alert(error.error.toString());
                },
            );
    }

    updateDocument(docId, type, data, url) {
        this.networkService
            .updateMachineDocument(
                this.id,
                null,
                docId,
                type.value,
                data.value,
                url.value,
                'edit',
            )
            .subscribe(
                (response) => this.handleMachineResponse(response),
                (error) => console.log(error),
            );
    }

    removeDocument(docId) {
        this.networkService
            .updateMachineDocument(
                this.id,
                null,
                docId,
                null,
                null,
                null,
                'remove',
            )
            .subscribe(
                (data) => this.handleMachineResponse(data),
                (error) => console.log(error),
            );
    }

    addDocument(type, data, url) {
        this.networkService
            .updateMachineDocument(
                this.id,
                null,
                null,
                type.value,
                data.value,
                url.value,
                'add',
            )
            .subscribe(
                (response) => this.handleMachineResponse(response),
                (error) => console.log(error),
            );
    }

    findInstances() {
        this.networkService.findMachineInstances(this.id).subscribe(
            (data) => (this.instances = data),
            (error) => console.log(error),
        );
    }

    onInstanceClick(id) {
        this.showInstance = false;
        this.selectedInstanceId = id;
        setTimeout(() => (this.showInstance = true), 1);
    }

    deleteMachine() {
        if (confirm('Are you sure to delete machine id ' + this.id + '?')) {
            this.networkService.deleteMachine(this.id).subscribe(
                (data) => {
                    this.showModal = false;
                    this.machineDelete.emit();
                },
                (error) => console.log(error),
            );
        }
    }

    updateFile(event) {
        this.imageFile = event.target.files[0];
    }

    btnDisabled() {
        return this.imageFile == null || this.duringUpdate;
    }
}
