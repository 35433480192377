export enum EvidenceColors {
    patentCount = '#ffb74d',
    websiteCount = '#aed581',
    publicationCount = '#4dd0e1',
    clinicalTrialCount = '#9575cd',
    grantCount = '#f06292',
}

export enum ExpertsColors {
    TILE_SEARCH_EXPERTS = '#81d4fa',
    CT_EXPERTS = '#aed581',
    INDUSTRY_EXPERTS = '#4dd0e1',
    ACADEMIC_EXPERTS = '#80cbc4',
    PATENT_EXPERTS = '#cdaa75'
}

export enum BusinessColors {
    CORPORATES = '#009eff',
    STARTUPS = '#b55963',
}
