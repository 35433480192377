import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChartDist, SelectedField} from "../../../../../../api-responses/graphs.interface";
import {DataService} from "../../../../../../data.service";
import {NetworkService} from "../../../../../../network.service";
import {MatDialog} from "@angular/material/dialog";
import {ChartFilterModalData} from "../../../../../../api-responses/Dialogs.interface";
import {InnovationProject} from "../../../../../../api-responses/InnovationProjects.interface";
import {ChartFilterModalComponent} from "../../chart-filter-modal/chart-filter-modal.component";

@Component({
    selector: 'app-geo-chart-container',
    templateUrl: './geo-chart-container.component.html',
    styleUrls: ['./geo-chart-container.component.scss']
})
export class GeoChartContainerComponent implements OnInit {

    @Input() ratio: number;

    @Input() project: InnovationProject;
    @Input() countryDist: ChartDist[];
    @Input() selectedCountries: SelectedField[] = [];
    @Output() selectedCountriesChange = new EventEmitter<SelectedField[]>();

    @Input() chartTitle: string = "Geographic Distribution";
    @Input() filterButtonText: string = "Filter Countries";
    @Input() showingXofYText: string = "countries";
    @Input() popupTitle: string = "Filter by Country";

    constructor(public dataService: DataService,
                public networkService: NetworkService,
                public matDialog: MatDialog) {
    }

    ngOnInit() {

    }

    getCountryFilterDescription() {
        let selectedCount = this.selectedCountries.filter(x => x.selected).length;
        let allCountries = this.selectedCountries.length;
        return `Showing ${selectedCount} of ${allCountries} ${this.showingXofYText}`;
    }

    openCountryFilter() {
        let dialogRef = this.matDialog.open(ChartFilterModalComponent, {data: {
            items: this.selectedCountries,
                title: this.popupTitle
        } as ChartFilterModalData });

        dialogRef.afterClosed()
            .toPromise()
            .then((data) => {
                if (!data) return;
                this.selectedCountries = data.selectedItems;
                this.selectedCountriesChange.emit(this.selectedCountries);
            })
    }
}
