<!-- THIS IS THE OLDER VERSION!!! -->
<div
    *ngIf="!expertsData"
    style="
        background-image: url('assets/images/new_background_repeatable.jpeg');
        background-repeat: repeat-y;
        background-size: 100%;
    "
>
    <div
        style="
            background-color: rgba(46, 46, 46, 0.8);
            width: 100%;
            min-height: calc(100vh - 54px);
        "
    >
        <div *ngIf="loading">
            <div class="loader"></div>
            <div id="stage">{{ stages[stageIndex] }}...</div>
        </div>

        <a class="btn-info btn-lg noResults" *ngIf="noResults">
            <span
                class="glyphicon glyphicon-warning-sign"
                style="font-family: 'Glyphicons Halflings'"
            ></span>
            No results - please enter different text
        </a>
    </div>
</div>
<div
    *ngIf="expertsData"
    id="top"
    style="
        background-image: url('assets/images/new_background_repeatable.jpeg');
    "
>
    <div
        style="
            background-color: rgba(46, 46, 46, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: calc(100vh - 54px);
        "
    >
        <div
            *ngIf="isAdmin()"
            style="position: absolute; right: 62px; top: 25px"
        >
            <input
                type="checkbox"
                id="googleSheetChbx"
                style="position: relative; float: left"
                [(ngModel)]="googleSheets"
            />
            <span
                style="
                    float: left;
                    position: relative;
                    margin-right: 20px;
                    color: white;
                "
            >
                GoogleSheets
            </span>
            <button
                [disabled]="exportLoading"
                id="exportBtn"
                [class.exportBtnClick]="!exportLoading"
                style="
                    top: 0px !important;
                    position: relative !important;
                    right: 0px !important;
                    background-image: url('assets/images/export-icon-dark.png');
                    height: 35px;
                "
                (click)="exportBtnClicked()"
            ></button>
        </div>

        <button
            *ngIf="!isAdmin()"
            [disabled]="exportLoading"
            id="exportBtn"
            [class.exportBtnClick]="!exportLoading"
            style="
                background-image: url('assets/images/export-icon-dark.png');
                height: 35px;
            "
            (click)="exportBtnClicked()"
        ></button>

        <a
            class="btn-info btn-lg noResults"
            *ngIf="filterExperts().length === 0"
        >
            <span
                class="glyphicon glyphicon-warning-sign"
                style="font-family: 'Glyphicons Halflings'"
            ></span>
            No results - please enter different text
        </a>

        <div
            *ngIf="!showModal && vw() > 600"
            id="feedback"
            style="background-image: url('assets/images/feedback.png')"
            (click)="openFeedback()"
        ></div>

        <div
            [style.background]="'rgb(0, 141, 237)'"
            class="glossfill glossary"
            style="top: 131px"
        >
            &nbsp;CLINICAL TRIAL EXPERT&nbsp;
        </div>
        <div
            [style.background]="'rgb(34, 139, 34)'"
            class="glossfill glossary"
            style="top: 154px"
        >
            &nbsp;C.T INDUSTRY EXPERT&nbsp;
        </div>
        <div
            [style.background]="'rgb(232, 90, 78)'"
            class="glossfill glossary"
            style="top: 167px"
        >
            &nbsp;C.T COLLABORATOR&nbsp;
        </div>

        <div
            *ngIf="feedbackOpen && vw() > 600"
            class="modal fade in"
            id="feedbackContainer"
        >
            <app-feedback-window
                (closeModal)="closeFeedback()"
                [query]="searchString"
                [scope]="'Clinical trials experts'"
            ></app-feedback-window>
        </div>

        <div class="dropdownsContainer">
            <div class="dropdown phasesDropdown">
                <button
                    class="dropdown-btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <div
                        class="ellipsis"
                        style="padding: 0 30px"
                        (ngChange)="checkWidth($event, selectedPhase)"
                        (mouseover)="checkWidth($event, selectedPhase)"
                        title=""
                    >
                        {{ selectedPhase }}
                    </div>
                    <button
                        class="dropDownBtn"
                        [class.dropDownBtnOpen]="isPhasesDropdownOpen"
                        [style.backgroundImage]="'url(assets/images/white_arrow.png)'"
                    ></button>
                </button>

                <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                >
                    <span
                        class="dropdown-item"
                        type="button"
                        (click)="selectedPhase = 'All Phases'"
                        id="allPhases"
                    >
                        All Phases
                    </span>
                    <span
                        id="{{ phase }}"
                        class="dropdown-item"
                        type="button"
                        *ngFor="let phase of phases"
                        (click)="selectedPhase = phase"
                        (ngChange)="checkWidth($event, phase)"
                        (mouseover)="checkWidth($event, phase)"
                        title=""
                    >
                        {{ phase }}
                    </span>
                </div>
            </div>

            <div class="dropdown countriesDropdown">
                <button
                    class="dropdown-btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [style.marginLeft]="vw() > 800 ? '20px' : '0px'"
                >
                    <div
                        *ngIf="selectedCountry !== 'All Countries'"
                        class="label"
                    >
                        Beta
                    </div>
                    <div
                        class="ellipsis"
                        style="padding: 0 30px"
                        (ngChange)="checkWidth($event, selectedCountry)"
                        (mouseover)="checkWidth($event, selectedCountry)"
                        title=""
                    >
                        {{ selectedCountry }}
                    </div>
                    <button
                        class="dropDownBtn"
                        [class.dropDownBtnOpen]="isCountriesDropdownOpen"
                        [style.backgroundImage]="'url(assets/images/white_arrow.png)'"
                    ></button>
                </button>

                <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton2"
                    [style.marginLeft]="vw() > 800 ? '20px' : '0px'"
                >
                    <span
                        class="dropdown-item"
                        type="button"
                        (click)="selectedCountry = 'All Countries'"
                        id="allCountries"
                    >
                        All Countries
                    </span>
                    <div id="betaComment">Beta</div>
                    <span
                        id="{{ country }}"
                        class="dropdown-item"
                        type="button"
                        *ngFor="let country of countries"
                        (click)="selectedCountry = country"
                        (ngChange)="checkWidth($event, country)"
                        (mouseover)="checkWidth($event, country)"
                        title=""
                    >
                        {{ country }}
                    </span>
                </div>
            </div>

            <div class="dropdown statusesDropdown">
                <button
                    class="dropdown-btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [style.marginLeft]="vw() > 800 ? '20px' : '0px'"
                >
                    <div
                        class="ellipsis"
                        style="padding: 0 30px"
                        (ngChange)="checkWidth($event, selectedStatus)"
                        (mouseover)="checkWidth($event, selectedStatus)"
                        title=""
                    >
                        {{ selectedStatus }}
                    </div>
                    <button
                        class="dropDownBtn"
                        [class.dropDownBtnOpen]="isStatusesDropdownOpen"
                        [style.backgroundImage]="'url(assets/images/white_arrow.png)'"
                    ></button>
                </button>

                <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton3"
                    [style.marginLeft]="vw() > 800 ? '20px' : '0px'"
                >
                    <span
                        class="dropdown-item"
                        type="button"
                        (click)="selectedStatus = 'All Statuses'"
                        id="allStatuses"
                    >
                        All Statuses
                    </span>
                    <span
                        id="{{ status }}"
                        class="dropdown-item"
                        type="button"
                        *ngFor="let status of statuses"
                        (click)="selectedStatus = status"
                        (ngChange)="checkWidth($event, status)"
                        (mouseover)="checkWidth($event, status)"
                        title=""
                    >
                        {{ status }}
                    </span>
                </div>
            </div>
        </div>

        <div
            [style.padding]="vw() <= 800 ? '175px 30px 30px' : '55px 30px 30px'"
        >
            <div *ngIf="wordCloud" class="wordCloud" id="wordCloud">
                <button
                    *ngIf="!showWordCloud"
                    [ngClass]="'btnWordCloud btn-primary'"
                    (click)="flipWordCloud()"
                >
                    Tune your search
                </button>
                <div *ngIf="showWordCloud">
                    <span
                        ><button
                            [ngClass]="'btnWordCloud btn-primary'"
                            (click)="flipWordCloud()"
                        >
                            Hide</button
                        >&nbsp;Click on a term and then choose how it should be
                        added to the query</span
                    >
                    <app-word-cloud
                        *ngIf="showWordCloud"
                        [data]="wordCloud"
                        style="display: inline !important"
                    ></app-word-cloud>
                </div>
            </div>

            <div *ngIf="filterExperts().length > 6" class="resultsNumber">
                {{ filterExperts().length }} experts matching the criteria in
                the result were found
            </div>
            <div class="results">
                <div
                    *ngFor="let expert of filterExperts()"
                    class="expert"
                    [style.height]="patentsPresent ? '376px' : '350px'"
                    [class.selectedCard]="selectedExperts.includes(expert)"
                    (click)="expertCardClicked($event, expert)"
                >
                    <div
                        *ngIf="selectedExperts.includes(expert)"
                        class="check"
                        style="
                            background-image: url('assets/images/v-symbol.png');
                            display: block;
                        "
                    ></div>
                    <div
                        *ngIf="!selectedExperts.includes(expert)"
                        class="check"
                        style="
                            background-image: url('assets/images/v-symbol-hover.png');
                            background-size: 90%;
                        "
                    ></div>

                    <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        "
                    >
                        <div style="width: 100%">
                            <div
                                class="name ellipsis"
                                title=""
                                [style.color]="
                                    expert.company
                                        ? '#228B22'
                                        : expert.ctInvestigator
                                        ? '#008ded'
                                        : '#e85a4e'
                                "
                                (click)="expertClicked(expert)"
                                (ngChange)="checkWidth($event, expert.lastName)"
                                (mouseover)="
                                    checkWidth($event, expert.lastName)
                                "
                            >
                                {{ expert.lastName }}
                            </div>

                            <div
                                class="institute ellipsis"
                                title=""
                                (ngChange)="
                                    checkWidth($event, expert.department)
                                "
                                (mouseover)="
                                    checkWidth($event, expert.department)
                                "
                            >
                                {{ expert.department }}
                            </div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Evidence</span>
                        <div class="bar" style="border: solid 1px #e85a4e">
                            <div
                                [style.background]="'#e85a4e'"
                                [style.width]="
                                    normalize(expert.expertScore, 100, 130) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(expert.expertScore, 100, 130) >
                                    129
                                "
                                [class.empty]="
                                    normalize(expert.expertScore, 100, 130) ===
                                    0
                                "
                            ></div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Clinical Trials</span>
                        <div class="bar" style="border: solid 1px #b9646e">
                            <div
                                [style.background]="'#b9646e'"
                                [style.width]="
                                    normalize(
                                        expert.clinicalTrialScore,
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        expert.clinicalTrialScore,
                                        100,
                                        130
                                    ) > 129
                                "
                                [class.empty]="
                                    normalize(
                                        expert.clinicalTrialScore,
                                        100,
                                        130
                                    ) === 0
                                "
                            ></div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Publications</span>
                        <div class="bar" style="border: solid 1px #74749d">
                            <div
                                [style.background]="'#74749d'"
                                [style.width]="
                                    normalize(
                                        expert.publicationScore,
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        expert.publicationScore,
                                        100,
                                        130
                                    ) > 129
                                "
                                [class.empty]="
                                    normalize(
                                        expert.publicationScore,
                                        100,
                                        130
                                    ) === 0
                                "
                            ></div>
                        </div>
                    </div>

                    <div class="category" *ngIf="patentsPresent">
                        <span class="title">Patents</span>
                        <div class="bar" style="border: solid 1px #2e83cd">
                            <div
                                [style.background]="'#2e83cd'"
                                [style.width]="
                                    normalize(expert.patentsScore, 100, 130) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(expert.patentsScore, 100, 130) >
                                    129
                                "
                                [class.empty]="
                                    normalize(expert.patentsScore, 100, 130) ===
                                    0
                                "
                            ></div>
                        </div>
                    </div>

                    <div class="line"></div>

                    <div class="quote_container" *ngIf="expert.quote">
                        <img
                            src="assets/images/innovation-icon.png"
                            class="innovation_icon"
                        />
                        <div class="quote">
                            {{ trimString(expert.quote, 90) }}
                        </div>
                    </div>

                    <div class="email_container" *ngIf="expert.email">
                        <img
                            src="assets/images/envelope-icon-dark.png"
                            class="envelope_icon"
                        />
                        <a
                            target="_blank"
                            href="mailto:{{ expert.email }}"
                            class="email ellipsis"
                            >{{ expert.email }}</a
                        >
                    </div>

                    <button
                        class="read_more_button"
                        [class.selectedCardButton]="
                            selectedExperts.includes(expert)
                        "
                        (click)="expertClicked(expert)"
                    >
                        View More
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    *ngIf="showModal && selectedExpert"
    class="modal fade in"
    id="company-modal"
    role="dialog"
>
    <div class="detailsContainer">
        <button
            *ngIf="vw() > 1010"
            style="background-image: url('assets/images/experts-next.png')"
            id="nextExpert"
            (click)="nextExpert()"
            [disabled]="!enableNextExpert"
        ></button>
        <button
            *ngIf="vw() > 1010"
            style="background-image: url('assets/images/experts-next.png')"
            id="previousExpert"
            (click)="previousExpert()"
            [disabled]="!enablePreviousExpert"
        ></button>

        <div
            *ngIf="vw() >= 600"
            id="innerFeedback"
            style="background-image: url('assets/images/feedback.png')"
            (click)="innerFeedbackOpen = true"
            [class.fitFeedback]="
                !selectedExpert.email && !selectedExpert.department
            "
        ></div>

        <div
            *ngIf="innerFeedbackOpen && vw() >= 600"
            class="modal fade in"
            id="innerFeedbackContainer"
        >
            <app-feedback-window
                (closeModal)="innerFeedbackOpen = false"
                [expert]="selectedExpert.lastName"
                [query]="searchString"
                [scope]="'Clinical trials expert'"
            ></app-feedback-window>
        </div>

        <div
            class="header"
            [class.noTabs]="
                !selectedExpert.workedWith ||
                selectedExpert.workedWith.length === 0
            "
        >
            <img
                src="assets/images/close-dark.png"
                class="closeIcon"
                (click)="closeModal()"
            />
            <div
                class="cardName ellipsis"
                title=""
                [style.color]="
                    selectedExpert.company
                        ? '#228B22'
                        : selectedExpert.ctInvestigator
                        ? '#008ded'
                        : '#e85a4e'
                "
                (ngChange)="checkWidth($event, selectedExpert.lastName)"
                (mouseover)="checkWidth($event, selectedExpert.lastName)"
            >
                {{ selectedExpert.lastName }}
            </div>

            <div
                class="headerContent"
                *ngIf="selectedExpert.department || selectedExpert.email"
            >
                <span
                    *ngIf="selectedExpert.department"
                    (ngChange)="checkWidth($event, selectedExpert.department)"
                    (mouseover)="checkWidth($event, selectedExpert.department)"
                    class="ellipsis department"
                    title=""
                    [class.primaryDep]="
                        selectedExpert.department && !selectedExpert.email
                    "
                >
                    {{ selectedExpert.department }}
                </span>

                <div
                    *ngIf="selectedExpert.email"
                    class="innerEmail"
                    [class.primary]="
                        !selectedExpert.department && selectedExpert.email
                    "
                >
                    <img src="assets/images/envelope_blue_dark.png" />
                    <a
                        target="_blank"
                        href="mailto:{{ selectedExpert.email }}"
                        class="ellipsis"
                    >
                        {{ selectedExpert.email }}
                    </a>
                </div>
            </div>

            <div
                class="tab"
                *ngIf="
                    selectedExpert.workedWith &&
                    selectedExpert.workedWith.length > 0
                "
            >
                <button
                    class="tablinks"
                    (click)="evidenceTabActive = true"
                    [class.active]="evidenceTabActive"
                >
                    Evidence
                </button>
                <button
                    class="tablinks"
                    (click)="evidenceTabActive = false"
                    [class.active]="!evidenceTabActive"
                >
                    Collaborations
                </button>
            </div>
        </div>

        <div
            class="articles"
            *ngIf="
                piecesOfEvidence &&
                piecesOfEvidence.length > 0 &&
                evidenceTabActive
            "
        >
            <div class="cardTitle">
                <span>
                    {{ piecesOfEvidence.length }}
                    {{
                        piecesOfEvidence.length === 1
                            ? 'Piece of Evidence'
                            : 'Pieces of Evidence'
                    }}
                </span>
            </div>

            <div class="table">
                <div class="articlesTable">
                    <div
                        class="article"
                        *ngFor="let resource of piecesOfEvidence"
                    >
                        <span class="articleNumber">
                            {{ piecesOfEvidence.indexOf(resource) + 1 }}
                        </span>
                        <div
                            class="publicationTypeLogo"
                            [style.backgroundImage]="
                                resource.type === 'ARTICLE'
                                    ? 'url(assets/images/articles_dark.png)'
                                    : resource.type === 'PATENT'
                                    ? 'url(assets/images/innovation_blue_dark.png)'
                                    : resource.type === 'CT'
                                    ? 'url(assets/images/clinical_trials_icon_dark.png)'
                                    : none
                            "
                        ></div>
                        <a
                            class="articleName ellipsis"
                            href="{{ resource.url }}"
                            target="_blank"
                            title=""
                            (ngChange)="checkWidth($event, resource.title)"
                            (mouseover)="checkWidth($event, resource.title)"
                            >{{ resource.title }}</a
                        >
                        <div
                            *ngIf="
                                resource.type === 'CT' &&
                                resource.phase &&
                                resource.phase !== '[]'
                            "
                            style="
                                flex-shrink: 0;
                                align-self: center;
                                padding-right: 15px;
                                color: #b9646e;
                                font-size: 13px;
                                letter-spacing: 0.2px;
                            "
                        >
                            {{ resource.phase }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            *ngIf="
                !evidenceTabActive &&
                selectedExpert.workedWith &&
                selectedExpert.workedWith.length > 0
            "
        >
            <div class="cardTitle" style="padding-bottom: 10px">
                <img
                    src="assets/images/people-dark.png"
                    style="width: 32px; height: 28px"
                />
                <span> Worked with others </span>
            </div>

            <div
                class="bars evidenceBars"
                style="max-height: 300px; overflow: auto; margin-left: 0"
                [class.fit]="vw() < 900 && selectedExpert.workedWith.length < 6"
            >
                <div
                    class="barContainer"
                    *ngFor="let i of selectedExpert.workedWith"
                >
                    <span
                        class="barTitle"
                        (ngChange)="checkWidth($event, i.valueA)"
                        (mouseover)="checkWidth($event, i.valueA)"
                        title=""
                        style="font-weight: bold; font-size: 12px"
                        [style.width]="
                            vw() <= 480
                                ? 'calc(100% - 170px)'
                                : vw() <= 900
                                ? '120px'
                                : '170px'
                        "
                        [style.paddingRight]="vw() <= 900 ? '20px' : '40px'"
                        >{{ i.valueA }}</span
                    >
                    <span
                        *ngIf="vw() > 480"
                        (ngChange)="checkWidth($event, i.valueB)"
                        (mouseover)="checkWidth($event, i.valueB)"
                        class="department tt ellipsis"
                        title=""
                        style="font-weight: 100"
                        [style.width]="
                            vw() <= 900
                                ? 'calc(100% - 290px)'
                                : 'calc(100% - 340px)'
                        "
                        [style.padding]="
                            vw() <= 900 ? '0 8px 0 0' : '0 28px 0 0'
                        "
                        >{{ i.valueB }}</span
                    >

                    <div class="barContent">
                        <div
                            class="barr"
                            style="border: solid 1px #b9646e"
                            title="{{ i.valueD }} {{
                                i.valueD > 1 ? 'times' : 'time'
                            }}"
                        >
                            <div
                                [style.background]="'#b9646e'"
                                [style.width]="
                                    normalize(
                                        i.valueD,
                                        selectedExpert.workedWith[0].valueD,
                                        120
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.barr-fill]="
                                    normalize(
                                        i.valueD,
                                        selectedExpert.workedWith[0].valueD,
                                        120
                                    ) > 119
                                "
                            ></div>
                        </div>

                        <div class="email_containerr">
                            <a
                                *ngIf="i.valueC"
                                target="_blank"
                                href="mailto:{{ i.valueC }}"
                                title="{{ i.valueC }}"
                            >
                                <img
                                    src="assets/images/envelope-icon-dark.png"
                                    class="envelope_iconn"
                                    [style.marginRight]="
                                        vw() <= 900 ? '8px' : '0'
                                    "
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="grants"
            *ngIf="
                selectedExpert.workedWithIndustryNames &&
                selectedExpert.workedWithIndustryNames.length > 0
            "
        >
            <div
                class="bottomTitle"
                style="padding-bottom: 0"
                [class.pad]="vw() < 900"
            >
                <img
                    src="assets/images/building-dark.png"
                    style="width: 29px; height: 31px; margin-top: 3px"
                />
                <span
                    style="line-height: 20px; margin-bottom: -2.5px"
                    *ngIf="selectedExpert.workedWithIndustryNames.length === 1"
                >
                    Worked with
                    {{ selectedExpert.workedWithIndustryNames.length }} company
                </span>
                <span
                    style="line-height: 20px; margin-bottom: -2.5px"
                    *ngIf="selectedExpert.workedWithIndustryNames.length > 1"
                >
                    Worked with
                    {{ selectedExpert.workedWithIndustryNames.length }} companies
                </span>
            </div>

            <div class="grantsList">
                <button
                    (click)="previousCompany()"
                    [disabled]="checkPreviousCompany()"
                    [class.nv]="
                        vw() > 900
                            ? selectedExpert.workedWithIndustryNames.length <= 3
                            : selectedExpert.workedWithIndustryNames.length === 0
                    "
                >
                    <img
                        src="assets/images/previous_arrow-dark.png"
                        [class.disabled]="checkPreviousCompany()"
                    />
                </button>

                <div class="grantsContainer">
                    <div
                        id="companiesContent"
                        [class.one]="
                            selectedExpert.workedWithIndustryNames.length === 1
                        "
                        [class.two]="
                            selectedExpert.workedWithIndustryNames.length === 2
                        "
                    >
                        <div
                            class="grant"
                            *ngFor="
                                let company of this.selectedExpert
                                    .workedWithIndustryNames
                            "
                        >
                            <div
                                *ngIf="company === ellipsisCompany(company, 45)"
                            >
                                {{ ellipsisCompany(company, 45) }}
                            </div>
                            <span
                                title="{{ company }}"
                                *ngIf="company !== ellipsisCompany(company, 45)"
                            >
                                {{ ellipsisCompany(company, 45) }}
                            </span>
                        </div>
                    </div>
                </div>

                <button
                    (click)="nextCompany()"
                    [disabled]="checkNextCompany()"
                    [class.nv]="
                        vw() > 900
                            ? selectedExpert.workedWithIndustryNames.length <= 3
                            : selectedExpert.workedWithIndustryNames.length === 0
                    "
                >
                    <img
                        src="assets/images/next_arrow-dark.png"
                        [class.disabled]="checkNextCompany()"
                    />
                </button>
            </div>
        </div>
    </div>
</div>
