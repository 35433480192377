import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";

@Component({
    selector: 'exclude-include',
    templateUrl: './exclude-include.component.html',
    styleUrls: ['./exclude-include.component.scss'],
})
export class ExcludeIncludeComponent implements OnInit, OnDestroy, OnChanges {

    @Input() isExclude!: boolean;
    @Output() isExcludeChange = new EventEmitter<boolean>()

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    updateValue($event: any) {
        this.isExclude = !this.isExclude;
        this.isExcludeChange.emit(this.isExclude);
        $event.preventDefault();
        $event.stopPropagation();
    }
}
