import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    AfterViewChecked,
} from '@angular/core';
import moment from 'moment';
import { UtilitiesService } from '../../../utilities.service';
import { NetworkService } from '../../../network.service';
import { DataService } from '../../../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FloatingUpdateService } from '../../../floating-update.service';

@Component({
    selector: 'app-machine-booking',
    templateUrl: './machine-booking.component.html',
    styleUrls: ['./machine-booking.component.scss'],
})
export class MachineBookingComponent implements OnInit, AfterViewChecked {
    paymentDone = false;
    iframeUrl;
    transactionId;
    transactionFinished = false;
    paymentSucceeded = false;
    paymentDisplayFailed = false;
    loading = true;
    pelecardLoading = true;
    minimumOperationDuration = '';
    minimumDurationComment = false;
    validTimeComment = false;
    notAvailableComment = false;
    personalAccount = true;
    startTime = ['??', '??', '??'];
    endTime = ['??', '??', '??'];
    machineSchedule;
    unavailableDays = [];
    partiallyAvailableDays = [];
    daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    fullDaysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    daysOfMonth = [];
    step = 1;
    hours = [];
    moment;
    day: number;

    @Output() closeModal = new EventEmitter();
    @Input() machine;

    constructor(
        private networkService: NetworkService,
        private cdRef: ChangeDetectorRef,
        public dataService: DataService,
        private sanitizer: DomSanitizer,
        private floatingUpdateService: FloatingUpdateService
    ) {}

    ngOnInit() {
        this.handleMinimumDuration();

        this.networkService.getMachineSchedule().subscribe(
            (data) => {
                this.machineSchedule = data;
                this.updateCalendar('current', 'months');
                this.updateCalendar('current', 'days');
            },
            (error) => console.log(error),
        );

        this.hours.push([12, 'am', 0]);
        for (let i = 1; i <= 11; i++) {
            this.hours.push([i, 'am', i]);
        }

        this.hours.push([12, 'pm', 12]);
        for (let i = 1; i <= 11; i++) {
            this.hours.push([i, 'pm', i + 12]);
        }
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    handleMinimumDuration() {
        const minHours = Math.floor(this.machine.minimumOperationDuration / 60);
        const minMinutes = this.machine.minimumOperationDuration % 60;

        if (minHours !== 0) {
            this.minimumOperationDuration =
                minHours + (minHours === 1 ? ' hour' : ' hours');
        }
        if (minHours !== 0 && minMinutes !== 0) {
            this.minimumOperationDuration += ' and ';
        }
        if (minMinutes !== 0) {
            this.minimumOperationDuration += `${minMinutes} minutes`;
        }
    }

    updateCalendar(direction, type) {
        this.resetComments();
        this.loading = false;
        this.startTime = ['??', '??', '??'];
        this.endTime = ['??', '??', '??'];

        if (
            direction === 'previous' &&
            (type === 'months' || (type === 'days' && this.day !== 1))
        ) {
            this.moment = moment(this.moment).subtract(1, type);
            this.day = this.moment.date();
            if (
                this.moment.month() === moment().month() &&
                this.moment.year() === moment().year() &&
                this.moment
                    .startOf('day')
                    .diff(moment().startOf('day'), 'days') <= 0
            ) {
                this.moment = moment();
                this.day = this.moment.date();
            }
        } else if (
            direction === 'next' &&
            (type === 'months' ||
                (type === 'days' && this.day !== this.moment.daysInMonth()))
        ) {
            this.moment = moment(this.moment).add(1, type);
            this.day = this.moment.date();
        } else if (direction === 'current') {
            this.moment = moment();
            this.day = this.moment.date();
        }

        if (type === 'months') {
            this.daysOfMonth = [];
            const startOfMonth = moment(this.moment).startOf('month').day();
            const numOfDays = moment(this.moment).daysInMonth();

            for (let i = 0; i < startOfMonth; i++) {
                this.daysOfMonth.push('');
            }
            for (let i = 1; i <= numOfDays; i++) {
                this.daysOfMonth.push(i.toString());
            }
        }

        this.renderMachineSchedule();
        if (type === 'days') {
            setTimeout(() => this.renderDay(), 1);
        }
    }

    checkIfControlsDisabled(direction, type) {
        if (type === 'months') {
            return (
                this.moment.month() === moment().month() &&
                this.moment.year() === moment().year()
            );
        } else if (type === 'days') {
            if (direction === 'previous') {
                return (
                    this.day === 1 ||
                    this.moment
                        .startOf('day')
                        .diff(moment().startOf('day'), 'days') <= 0
                );
            } else if (direction === 'next') {
                return this.day === this.moment.daysInMonth();
            }
        }
    }

    renderMachineSchedule() {
        this.unavailableDays = [];
        this.partiallyAvailableDays = [];

        for (const obj in this.machineSchedule) {
            let counter = 0;

            for (const i in this.machineSchedule[obj]) {
                const dateFrom = moment(
                    this.machineSchedule[obj][i]['dateFrom'],
                );
                const dateTo = moment(this.machineSchedule[obj][i]['dateTo']);
                counter += dateTo.diff(dateFrom, 'minutes');
            }

            if (counter === 1440) {
                this.unavailableDays.push(String(moment(obj).date()));
            } else if (counter > 0) {
                this.partiallyAvailableDays.push(String(moment(obj).date()));
            }
        }
    }

    renderDay() {
        const elements = document.querySelectorAll('.hourTaken');
        elements.forEach((e) => e.classList.remove('hourTaken'));

        let obj;
        for (const i in this.machineSchedule) {
            if (moment(i).date() === this.day) {
                obj = i;
            }
        }

        for (const i in this.machineSchedule[obj]) {
            const dateFrom = moment(this.machineSchedule[obj][i]['dateFrom']);
            const dateTo = moment(this.machineSchedule[obj][i]['dateTo']);

            for (let j = dateFrom; j < dateTo; ) {
                document
                    .getElementById(`${j.hour()}:${j.minutes()}`)
                    .classList.add('hourTaken');
                j = j.add(15, 'minutes');
            }

            if (dateTo.date() > this.day) {
                document.getElementById('24:0').classList.add('hourTaken');
            }
        }
    }

    checkIfHourTaken(type, hour) {
        const hours =
            hour[2] === 'pm'
                ? hour[0] === '12'
                    ? Number(hour[0])
                    : Number(hour[0]) + 12
                : hour[0] === '12'
                ? 0
                : Number(hour[0]);
        const minutes = Number(hour[1]);
        let elem, m;

        if (type === 'end') {
            m = moment([
                this.moment.year(),
                this.moment.month(),
                this.moment.date(),
                hours,
                minutes,
            ]).subtract(15, 'minutes');
        } else if (type === 'start') {
            m = moment([
                this.moment.year(),
                this.moment.month(),
                this.moment.date(),
                hours,
                minutes,
            ]);
        }

        elem = document.getElementById(`${m.hour()}:${m.minutes()}`);
        return elem ? elem.classList.contains('hourTaken') : false;
    }

    checkIfDayPassed(d) {
        const temp = moment([this.moment.year(), this.moment.month(), d]);
        return temp.startOf('day').diff(moment().startOf('day'), 'days') < 0;
    }

    resetComments() {
        this.minimumDurationComment = false;
        this.validTimeComment = false;
        this.notAvailableComment = false;
    }

    checkIfTimeAvailable(start, end) {
        let result = true;
        for (let i = start; i < end; ) {
            if (
                document
                    .getElementById(`${i.hour()}:${i.minutes()}`)
                    .classList.contains('hourTaken')
            ) {
                result = false;
                break;
            } else {
                i = i.add(15, 'minutes');
            }
        }
        return result;
    }

    hourClicked(type, hour) {
        this.resetComments();

        if (type === 'startTime') {
            if (this.endTime[0] !== '??') {
                const hoursStart =
                    hour[2] === 'pm'
                        ? hour[0] === '12'
                            ? Number(hour[0])
                            : Number(hour[0]) + 12
                        : hour[0] === '12'
                        ? 0
                        : Number(hour[0]);
                const minutesStart = Number(hour[1]);
                const start = moment([
                    this.moment.year(),
                    this.moment.month(),
                    this.moment.date(),
                    hoursStart,
                    minutesStart,
                ]);

                const hoursEnd =
                    this.endTime[2] === '+1'
                        ? 24
                        : this.endTime[2] === 'pm'
                        ? this.endTime[0] === '12'
                            ? Number(this.endTime[0])
                            : Number(this.endTime[0]) + 12
                        : this.endTime[0] === '12'
                        ? 0
                        : Number(this.endTime[0]);
                const minutesEnd = Number(this.endTime[1]);
                const end = moment([
                    this.moment.year(),
                    this.moment.month(),
                    this.moment.date(),
                    hoursEnd,
                    minutesEnd,
                ]);

                if (
                    start < end &&
                    end.diff(start, 'minutes') >=
                        this.machine.minimumOperationDuration &&
                    this.checkIfTimeAvailable(start, end)
                ) {
                    this.startTime = hour;
                } else if (start >= end) {
                    this.validTimeComment = true;
                } else if (
                    end.diff(start, 'minutes') <
                    this.machine.minimumOperationDuration
                ) {
                    this.minimumDurationComment = true;
                } else if (!this.checkIfTimeAvailable(start, end)) {
                    this.notAvailableComment = true;
                }
            } else {
                this.startTime = hour;
            }
        } else if (type === 'endTime') {
            if (this.startTime[0] !== '??') {
                const hoursEnd =
                    hour[2] === '+1'
                        ? 24
                        : hour[2] === 'pm'
                        ? hour[0] === '12'
                            ? Number(hour[0])
                            : Number(hour[0]) + 12
                        : hour[0] === '12'
                        ? 0
                        : Number(hour[0]);
                const minutesEnd = Number(hour[1]);
                const end = moment([
                    this.moment.year(),
                    this.moment.month(),
                    this.moment.date(),
                    hoursEnd,
                    minutesEnd,
                ]);

                const hoursStart =
                    this.startTime[2] === 'pm'
                        ? this.startTime[0] === '12'
                            ? Number(this.startTime[0])
                            : Number(this.startTime[0]) + 12
                        : this.startTime[0] === '12'
                        ? 0
                        : Number(this.startTime[0]);
                const minutesStart = Number(this.startTime[1]);
                const start = moment([
                    this.moment.year(),
                    this.moment.month(),
                    this.moment.date(),
                    hoursStart,
                    minutesStart,
                ]);

                if (
                    end > start &&
                    end.diff(start, 'minutes') >=
                        this.machine.minimumOperationDuration &&
                    this.checkIfTimeAvailable(start, end)
                ) {
                    this.endTime = hour;
                } else if (end <= start) {
                    this.validTimeComment = true;
                } else if (
                    end.diff(start, 'minutes') <
                    this.machine.minimumOperationDuration
                ) {
                    this.minimumDurationComment = true;
                } else if (!this.checkIfTimeAvailable(start, end)) {
                    this.notAvailableComment = true;
                }
            } else {
                this.endTime = hour;
            }
        }
    }

    calcBracketPosition(side, type) {
        const hoursStart =
            this.startTime[2] === 'pm'
                ? this.startTime[0] === '12'
                    ? Number(this.startTime[0])
                    : Number(this.startTime[0]) + 12
                : this.startTime[0] === '12'
                ? 0
                : Number(this.startTime[0]);
        const minutesStart = Number(this.startTime[1]);
        const start = moment([
            this.moment.year(),
            this.moment.month(),
            this.moment.date(),
            hoursStart,
            minutesStart,
        ]);

        const hoursEnd =
            this.endTime[2] === '+1'
                ? 24
                : this.endTime[2] === 'pm'
                ? this.endTime[0] === '12'
                    ? Number(this.endTime[0])
                    : Number(this.endTime[0]) + 12
                : this.endTime[0] === '12'
                ? 0
                : Number(this.endTime[0]);
        const minutesEnd = Number(this.endTime[1]);
        const end = moment([
            this.moment.year(),
            this.moment.month(),
            this.moment.date(),
            hoursEnd,
            minutesEnd,
        ]);

        const startOfDay = moment([
            this.moment.year(),
            this.moment.month(),
            this.moment.date(),
            0,
            0,
        ]);
        const quartersStart = Math.abs(start.diff(startOfDay, 'minutes')) / 15;
        const quartersEnd = Math.abs(end.diff(startOfDay, 'minutes')) / 15;

        const container = Math.abs(
            Number(
                document
                    .getElementById('hoursContent')
                    .style.left.slice(
                        0,
                        document.getElementById('hoursContent').style.left
                            .length - 2,
                    ),
            ),
        );

        if (type === 'width') {
            return (quartersEnd - quartersStart) * 8 + 'px';
        }
        if (side === 'start' && type === 'left') {
            return 5 + quartersStart * 8 - container + 'px';
        }
        if (side === 'end' && type === 'left') {
            return -1 + quartersEnd * 8 - container + 'px';
        }
    }

    checkPrevious(id) {
        const left = document.getElementById(id).style.left;
        return Number(left.slice(0, left.length - 2)) === 0;
    }

    checkNext(id) {
        const left = document.getElementById(id).style.left;
        return (
            Math.abs(Number(left.slice(0, left.length - 2))) ===
                (24 - 11) * 32 ||
            Math.abs(Number(left.slice(0, left.length - 2))) ===
                (24 - 11) * 32 + 12
        );
    }

    previous(id) {
        let left = document.getElementById(id).style.left;
        document.getElementById(id).style.left =
            Number(left.slice(0, left.length - 2)) + 32 + 'px';

        if (
            Math.abs(Number(left.slice(0, left.length - 2))) ===
            (24 - 11) * 32 + 12
        ) {
            left = document.getElementById(id).style.left;
            document.getElementById(id).style.left =
                Number(left.slice(0, left.length - 2)) + 12 + 'px';
        }

        for (const elemId of [
            'bracketBlueStart',
            'bracketBlueEnd',
            'blueBackground',
        ]) {
            const elem = document.getElementById(elemId);
            if (elem) {
                elem.style.left =
                    Number(
                        elem.style.left.slice(0, elem.style.left.length - 2),
                    ) +
                    32 +
                    'px';
            }
        }
    }

    next(id) {
        let left = document.getElementById(id).style.left;
        document.getElementById(id).style.left =
            Number(left.slice(0, left.length - 2)) - 32 + 'px';

        if (this.checkNext(id)) {
            left = document.getElementById(id).style.left;
            document.getElementById(id).style.left =
                Number(left.slice(0, left.length - 2)) - 12 + 'px';
        }

        for (const elemId of [
            'bracketBlueStart',
            'bracketBlueEnd',
            'blueBackground',
        ]) {
            const elem = document.getElementById(elemId);
            if (elem) {
                elem.style.left =
                    Number(
                        elem.style.left.slice(0, elem.style.left.length - 2),
                    ) -
                    32 +
                    'px';
            }
        }
    }

    setDate(d) {
        this.moment = moment([this.moment.year(), this.moment.month(), d]);
        this.day = this.moment.date();
        this.updateCalendar('update', 'days');
    }

    nextStep() {
        if (
            this.step !== 2 &&
            this.endTime[0] !== '??' &&
            this.startTime[0] !== '??'
        ) {
            this.step = 2;
            this.pelecardLoading = true;
            this.paymentDone = false;
            this.iframeUrl = undefined;
            this.transactionId = undefined;
            this.transactionFinished = false;
            this.paymentSucceeded = false;
            this.paymentDisplayFailed = false;
            this.getPaymentTransactionUrl();
        }
    }

    previousStep() {
        if (this.step !== 1) {
            this.step = 1;
            setTimeout(() => this.renderDay(), 1);
            this.stopPolling();
        }
    }

    dropdownClicked(dropdownId, btnId) {
        document.getElementById(dropdownId).classList.toggle('dropDownOpen');
        document.getElementById(btnId).classList.toggle('dropDownBtnOpen');

        if (
            (dropdownId === 'endTimeDropdown' && this.endTime[0] === '??') ||
            (dropdownId === 'startTimeDropdown' && this.startTime[0] === '??')
        ) {
            if (
                document
                    .getElementById(dropdownId)
                    .classList.contains('dropDownOpen')
            ) {
                document
                    .getElementById(`${dropdownId}9,am,9,00`)
                    .scrollIntoView();
            }
        }
    }

    getPaymentTransactionUrl() {
        this.networkService.getPaymentTransactionUrl('MACHINE').subscribe(
            (data) => {
                this.handleResponse(data);
                this.poll(
                    () => this.hasTransactionFinished(),
                    () => this.successCallback(),
                    1200000, // The time allowed on the clearing page (20 minutes)
                    250,
                );
            },
            (error) => {
                console.log(error);
                this.pelecardLoading = false;
                this.paymentDisplayFailed = true;
            },
        );
    }

    handleResponse(data) {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            data.url,
        );
        this.transactionId = data.transactionId;
        setTimeout(() => (this.pelecardLoading = false), 1000);
    }

    hasTransactionFinished() {
        let result;
        this.networkService
            .hasTransactionFinished(this.transactionId)
            .subscribe(
                (data) => {
                    result = data;
                    this.transactionFinished = result.finished;
                    if (this.transactionFinished) {
                        this.paymentSucceeded = result.success;
                    }
                },
                (error) => {
                    console.log(error);
                    this.paymentDone = true;
                    this.paymentDisplayFailed = true;
                },
            );
    }

    async successCallback() {
        this.paymentDone = true;
        const result = this.dataService.getUserInfo();
        return result
            .then((data) => {
                this.dataService.userData = data;
                this.floatingUpdateService.notifyApp();
            })
            .catch((error) => console.log(error));
    }

    poll(condition, callback, timeout, interval) {
        const endTime = Number(new Date()) + timeout;
        const self = this;

        (function p() {
            condition();
            if (!self.paymentDone) {
                if (self.transactionFinished) {
                    // If the condition is met, we're done!
                    callback();
                } else if (Number(new Date()) < endTime) {
                    // If the condition isn't met but the timeout hasn't elapsed, go again
                    setTimeout(p, interval);
                }
            }
        })();
    }

    stopPolling() {
        this.paymentDone = true;
    }

    isLandingPageVisible() {
        return document.getElementById('landingPage') ? true : false;
    }

    checkClass(event, className) {
        return event.target.classList.contains(className);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    arraysEqual(arr1, arr2) {
        return UtilitiesService.arraysEqual(arr1, arr2);
    }

    vw() {
        return UtilitiesService.vw();
    }
}
