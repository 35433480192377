<div *ngIf="loading">
    <div class="loader"></div>
</div>

<a
    class="btn-info btn-lg noResults"
    *ngIf="noResults"
    style="padding: 10px 16px"
>
    <span
        class="glyphicon glyphicon-warning-sign"
        style="font-family: 'Glyphicons Halflings'"
    ></span>
    No results - please enter different text
</a>

<div *ngIf="showModal" class="modal fade in" id="machine-modal" role="dialog">
    <app-machine-card
        [machine]="selectedMachine"
        (closeModal)="close()"
        (openBooking)="openBookingModal($event)"
        [previousLoading]="previousLoading"
        [nextLoading]="nextLoading"
        [enablePreviousMachine]="enablePreviousMachine"
        [enableNextMachine]="enableNextMachine"
        (nextMachine)="switchMachine('next')"
        (previousMachine)="switchMachine('previous')"
    ></app-machine-card>
</div>

<div
    *ngIf="showBookingModal"
    class="modal fade in"
    id="booking-modal"
    role="dialog"
>
    <app-machine-booking
        [machine]="selectedMachine"
        (closeModal)="closeBookingModal()"
    ></app-machine-booking>
</div>

<div id="machinesContainer" *ngIf="machines && !loading && !noResults">
    <div id="machines">
        <header [style.paddingRight]="isListOverflow() ? '6px' : 0">
            <div
                id="numberTitle"
                style="justify-content: center; text-align: center"
            >
                #
            </div>
            <div id="imageTitle"></div>
            <div id="detailsTitle" style="padding-right: 40px">
                Instrument Name and Details
            </div>
            <div id="descriptionTitle">Description</div>
            <div id="contactsTitle">Academic / Technical Contact</div>
            <div
                id="serviceTitle"
                style="
                    justify-content: center;
                    text-align: center;
                    padding: 0 15px;
                    margin-right: 15px;
                "
            >
                Service Model
            </div>
            <div
                id="timeTitle"
                style="
                    justify-content: center;
                    text-align: center;
                    margin-right: 15px;
                "
            >
                Confirmation Time
            </div>
            <div id="bookingTitle"></div>
        </header>

        <main id="machinesList">
            <div
                *ngFor="let m of machines"
                class="machine"
                [class.machineBorder]="
                    machines.indexOf(m) === machines.length - 1
                "
            >
                <div class="number">{{ machines.indexOf(m) + 1 }}</div>

                <div class="image">
                    <div
                        [style.backgroundImage]="
                            m.imageUrl
                                ? 'url(' + m.imageUrl + ')'
                                : 'url(assets/images/machine.png)'
                        "
                        [style.backgroundSize]="m.imageUrl ? 'cover' : 'auto'"
                        class="innerImg"
                        (click)="open(m)"
                    ></div>
                </div>

                <div
                    class="details"
                    [class.detailsFlex]="m.name && m.model && m.make"
                >
                    <div
                        *ngIf="m.name"
                        [class.detailMargin]="!(m.name && m.model && m.make)"
                    >
                        <div class="detailTitle">name</div>
                        <div
                            class="detailContent ellipsis"
                            (ngChange)="checkWidth($event, m.name)"
                            (mouseover)="checkWidth($event, m.name)"
                            title=""
                        >
                            {{ m.name }}
                        </div>
                    </div>
                    <div
                        *ngIf="m.model"
                        [class.detailMargin]="!(m.name && m.model && m.make)"
                    >
                        <div class="detailTitle">model</div>
                        <div
                            class="detailContent ellipsis"
                            (ngChange)="checkWidth($event, m.model)"
                            (mouseover)="checkWidth($event, m.model)"
                            title=""
                        >
                            {{ m.model }}
                        </div>
                    </div>
                    <div *ngIf="m.make">
                        <div class="detailTitle">make</div>
                        <div
                            class="detailContent ellipsis"
                            (ngChange)="checkWidth($event, m.make)"
                            (mouseover)="checkWidth($event, m.make)"
                            title=""
                        >
                            {{ m.make }}
                        </div>
                    </div>
                </div>

                <div class="description">
                    {{
                        m.wikipediaDocument
                            ? trimString(m.wikipediaDocument.data, 175)
                            : m.otherInfoDocument
                            ? trimString(m.otherInfoDocument.data, 175)
                            : ''
                    }}
                </div>

                <div
                    class="contacts"
                    [class.contactsFlex]="
                        m.contacts.length > 1 && m.institution
                    "
                >
                    <div
                        *ngIf="m.contacts.length > 0"
                        style="display: flex; height: 29px"
                        [class.contactMargin]="
                            !(m.contacts.length > 1 && m.institution)
                        "
                    >
                        <div
                            class="academicContactIcon"
                            style="
                                background-image: url('assets/images/expert-dark.png');
                            "
                        ></div>
                        <div class="infoContainer">
                            <div
                                class="contactInfo ellipsis"
                                (ngChange)="
                                    checkWidth($event, m.contacts[0]['name'])
                                "
                                (mouseover)="
                                    checkWidth($event, m.contacts[0]['name'])
                                "
                                title=""
                            >
                                {{ m.contacts[0]['name'] }}
                            </div>
                            <a
                                *ngIf="
                                    m.contacts[0]['email'] &&
                                    m.contacts[0]['email'] !== 'unknown'
                                "
                                class="contactInfo ellipsis"
                                target="_blank"
                                href="mailto:{{ m.contacts[0]['email'] }}"
                                (ngChange)="
                                    checkWidth($event, m.contacts[0]['email'])
                                "
                                (mouseover)="
                                    checkWidth($event, m.contacts[0]['email'])
                                "
                                title=""
                            >
                                {{ m.contacts[0]['email'] }}
                            </a>
                        </div>
                    </div>

                    <div
                        *ngIf="m.contacts.length > 1"
                        style="display: flex; height: 29px"
                        [class.contactMargin]="
                            !(m.contacts.length > 1 && m.institution)
                        "
                    >
                        <div
                            class="academicContactIcon"
                            style="
                                background-image: url('assets/images/expert-dark.png');
                            "
                        ></div>
                        <div class="infoContainer">
                            <div
                                class="contactInfo ellipsis"
                                (ngChange)="
                                    checkWidth($event, m.contacts[1]['name'])
                                "
                                (mouseover)="
                                    checkWidth($event, m.contacts[1]['name'])
                                "
                                title=""
                            >
                                {{ m.contacts[1]['name'] }}
                            </div>
                            <a
                                *ngIf="
                                    m.contacts[1]['email'] &&
                                    m.contacts[1]['email'] !== 'unknown'
                                "
                                class="contactInfo ellipsis"
                                target="_blank"
                                href="mailto:{{ m.contacts[1]['email'] }}"
                                (ngChange)="
                                    checkWidth($event, m.contacts[1]['email'])
                                "
                                (mouseover)="
                                    checkWidth($event, m.contacts[1]['email'])
                                "
                                title=""
                            >
                                {{ m.contacts[1]['email'] }}
                            </a>
                        </div>
                    </div>

                    <div
                        *ngIf="m.institution"
                        style="display: flex; align-items: center"
                    >
                        <div
                            class="institutionIcon"
                            [style.backgroundImage]="
                                m.institution['iconUrl']
                                    ? 'url(' + m.institution['iconUrl'] + ')'
                                    : 'url(assets/images/university.png)'
                            "
                        ></div>
                        <div class="contactInfo" style="line-height: initial">
                            {{ m.institution['name'] }}
                        </div>
                    </div>
                </div>

                <div class="service">
                    <div class="label">Soon</div>
                </div>

                <div class="time">
                    <div class="label">Soon</div>
                </div>

                <div class="booking">
                    <div class="bookingComment">
                        To see the price please book your priorities
                    </div>
                    <button class="bookingBtn" (click)="openBookingModal(m)">
                        BOOK NOW
                    </button>
                    <span class="bookingComment readMoreBtn" (click)="open(m)">
                        Read More
                    </span>
                </div>
            </div>
        </main>
    </div>
</div>
