<!--<div-->
<!--    *ngIf="false"-->
<!--    style="-->
<!--        background-image: url('../../../../assets/images/new_background_repeatable.jpeg');-->
<!--        background-repeat: repeat-y;-->
<!--        background-size: 100%;-->
<!--    "-->
<!--&gt;-->
<!--    <div-->
<!--        style="-->
<!--            background-color: rgba(46, 46, 46, 0.8);-->
<!--            width: 100%;-->
<!--            min-height: calc(100vh - 54px);-->
<!--        "-->
<!--    >-->
<!--        <div *ngIf="loading">-->
<!--            <div class="loader"></div>-->
<!--        </div>-->

<!--        <a class="btn-info btn-lg noResults" *ngIf="noResults">-->
<!--            <span-->
<!--                class="glyphicon glyphicon-warning-sign"-->
<!--                style="font-family: 'Glyphicons Halflings'"-->
<!--            ></span>-->
<!--            No results - please enter different text-->
<!--        </a>-->
<!--    </div>-->
<!--</div>-->

<div *ngIf="show()" style="display: flex">
<!--    <div style="flex: 1">-->
<!--        <div>-->
<!--            <input type="text" [(ngModel)]="title" />-->
<!--        </div>-->
<!--        <div>-->
<!--            <div-->
<!--                *ngIf="filterCompanies().length > 4 && corporatesSearch === 'CORPORATES'"-->
<!--                class="resultsNumber"-->
<!--            >-->
<!--                {{ filterCompanies().length }} companies matching the criteria-->
<!--                in the result were found-->
<!--            </div>-->

<!--            <div-->
<!--                *ngIf="filterCompanies().length > 4 && corporatesSearch !== 'CORPORATES'"-->
<!--                class="resultsNumber"-->
<!--            >-->
<!--                {{ filterCompanies().length }} startups matching the criteria in-->
<!--                the result were found-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div style="flex: 1">-->
<!--        <div>-->
<!--            <mat-form-field class="industry-select" appearance="fill">-->
<!--                <mat-select [(value)]="this.selectedCountry">-->
<!--                    <mat-option-->
<!--                        *ngFor="let option of this.countries"-->
<!--                        [value]="option"-->
<!--                        style="text-transform: capitalize"-->
<!--                        >{{ option }}</mat-option-->
<!--                    >-->
<!--                </mat-select>-->
<!--            </mat-form-field>-->
<!--        </div>-->
<!--        <div>-->
<!--            <button class="btn-primary">Export</button>-->
<!--        </div>-->
<!--        <div>-->
<!--            <button class="btn-primary" (click)="this.saveSearch()">-->
<!--                Save-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->
</div>

<div
        *ngIf="show()"
    id="top"

>
    <div class="saved-search-area"
        style="
            /*background-color: rgba(46, 46, 46, 0.8);*/
            display: block;
            justify-content: center;
            align-items: center;
            min-height: calc(100vh - 54px);
        "
    >
        <!--        <div-->
        <!--            *ngIf="isAdmin()"-->
        <!--            style="position: absolute; right: 62px; top: 25px"-->
        <!--        >-->
        <!--            <input-->
        <!--                type="checkbox"-->
        <!--                id="googleSheetChbx"-->
        <!--                style="position: relative; float: left"-->
        <!--                [(ngModel)]="googleSheets"-->
        <!--            />-->
        <!--            <span-->
        <!--                style="-->
        <!--                    float: left;-->
        <!--                    position: relative;-->
        <!--                    margin-right: 20px;-->
        <!--                    color: white;-->
        <!--                "-->
        <!--            >-->
        <!--                GoogleSheets-->
        <!--            </span>-->
        <!--            <button-->
        <!--                [disabled]="exportLoading"-->
        <!--                id="exportBtn"-->
        <!--                style="-->
        <!--                    position: relative !important;-->
        <!--                    right: 0px !important;-->
        <!--                    top: 0px !important;-->
        <!--                    background-image: url('../../../../assets/images/export-icon-dark.png');-->
        <!--                    height: 35px;-->
        <!--                "-->
        <!--                (click)="!exportLoading ? exportBtnClicked() : null"-->
        <!--            ></button>-->
        <!--        </div>-->

        <!--        <button-->
        <!--            *ngIf="!isAdmin()"-->
        <!--            [disabled]="exportLoading"-->
        <!--            id="exportBtn"-->
        <!--            [class.exportBtnClick]="!exportLoading"-->
        <!--            style="-->
        <!--                background-image: url('../../../../assets/images/export-icon-dark.png');-->
        <!--                height: 35px;-->
        <!--            "-->
        <!--            (click)="exportBtnClicked()"-->
        <!--        ></button>-->

        <!--        <a-->
        <!--            class="btn-info btn-lg noResults"-->
        <!--            *ngIf="filterCompanies().length === 0"-->
        <!--        >-->
        <!--            <span-->
        <!--                class="glyphicon glyphicon-warning-sign"-->
        <!--                style="font-family: 'Glyphicons Halflings'"-->
        <!--            ></span>-->
        <!--            No results - please enter different text-->
        <!--        </a>-->

        <!--        <div *ngIf="wordCloud" id="wordCloud">-->
        <!--            <button-->
        <!--                *ngIf="!showWordCloud"-->
        <!--                style="margin-left: 25px; margin-top: 5px"-->
        <!--                [ngClass]="'btnWordCloud btn-primary'"-->
        <!--                (click)="flipWordCloud()"-->
        <!--            >-->
        <!--                Tune your search-->
        <!--            </button>-->
        <!--            <span-->
        <!--                *ngIf="showWordCloud"-->
        <!--                style="margin-left: 25px; margin-top: 5px; color: wheat"-->
        <!--                ><button-->
        <!--                    [ngClass]="'btnWordCloud btn-primary'"-->
        <!--                    (click)="flipWordCloud()"-->
        <!--                >-->
        <!--                    Hide</button-->
        <!--                >&nbsp;Click on a term and then choose how it should be added to-->
        <!--                the query</span-->
        <!--            >-->
        <!--            <app-word-cloud-->
        <!--                *ngIf="showWordCloud"-->
        <!--                [data]="wordCloud"-->
        <!--                style="width: 100%"-->
        <!--            ></app-word-cloud>-->
        <!--        </div>-->

        <!--        <div style="width: 100%">-->
        <!--            <div id="countriesDropdown" class="dropdown">-->
        <!--                <button-->
        <!--                    class="dropdown-btn btn-secondary dropdown-toggle"-->
        <!--                    type="button"-->
        <!--                    id="dropdownMenuButton"-->
        <!--                    data-toggle="dropdown"-->
        <!--                    aria-haspopup="true"-->
        <!--                    aria-expanded="false"-->
        <!--                >-->
        <!--                    <div *ngIf="selectedCountry !== 'World Wide'" class="label">-->
        <!--                        Beta-->
        <!--                    </div>-->
        <!--                    <div-->
        <!--                        class="ellipsis"-->
        <!--                        style="padding: 0 30px"-->
        <!--                        (ngChange)="checkWidth($event, selectedCountry)"-->
        <!--                        (mouseover)="checkWidth($event, selectedCountry)"-->
        <!--                        title=""-->
        <!--                    >-->
        <!--                        {{ selectedCountry }}-->
        <!--                    </div>-->
        <!--                    <button-->
        <!--                        id="dropDownBtnId"-->
        <!--                        class="dropDownBtn"-->
        <!--                        [style.backgroundImage]="'url(assets/images/white_arrow.png)'"-->
        <!--                        [class.dropDownBtnOpen]="isDropdownOpen"-->
        <!--                    ></button>-->
        <!--                </button>-->

        <!--                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
        <!--                    <span-->
        <!--                        class="dropdown-item"-->
        <!--                        type="button"-->
        <!--                        (click)="selectedCountry = 'World Wide'"-->
        <!--                        id="worldWide"-->
        <!--                    >-->
        <!--                        World Wide-->
        <!--                    </span>-->
        <!--                    <div id="betaComment">Beta</div>-->
        <!--                    <span-->
        <!--                        id="{{ country }}"-->
        <!--                        class="dropdown-item"-->
        <!--                        type="button"-->
        <!--                        *ngFor="let country of countries"-->
        <!--                        (click)="selectedCountry = country"-->
        <!--                        (ngChange)="checkWidth($event, country)"-->
        <!--                        (mouseover)="checkWidth($event, country)"-->
        <!--                        title=""-->
        <!--                    >-->
        <!--                        {{ country }}-->
        <!--                    </span>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <div
            [style.padding]="
                vw() <= 550
                    ? '140px 30px 30px'
                    : vw() <= 800
                    ? '95px 30px 30px'
                    : '16px 30px 30px'
            "
        >
            <div class="results saved-search-area">
                <!--                <div-->
                <!--                    *ngIf="!showModal && vw() > 600"-->
                <!--                    id="feedback"-->
                <!--                    style="background-image: url('../../../../assets/images/feedback.png')"-->
                <!--                    (click)="openFeedback()"-->
                <!--                ></div>-->

                <!--                <div-->
                <!--                    *ngIf="feedbackOpen && vw() >= 600"-->
                <!--                    class="modal fade in"-->
                <!--                    id="feedbackContainer"-->
                <!--                >-->
                <!--                    <app-feedback-window-->
                <!--                        (closeModal)="feedbackOpen = false"-->
                <!--                        [query]="searchString"-->
                <!--                        [scope]="'Companies search'"-->
                <!--                    >-->
                <!--                    </app-feedback-window>-->
                <!--                </div>-->

                <div
                    *ngFor="let company of filterCompanies(); let i = index"
                    class="company"
                    [style.opacity]="getOpacity(i)"
                    [class.container_disabled]="isLimited(i)"
                    [class.exclude]="company.exclude"
                >
                    <div
                        class="name ellipsis"
                        [style.color]="
                            company.highProbability ? '#008ded' : '#e85a4e'
                        "
                        title=""
                        (click)="companyClicked(company)"
                        (ngChange)="checkWidth($event, company.fullName)"
                        (mouseover)="checkWidth($event, company.fullName)"
                    >
                        <div>{{ company.fullName }}</div>
                        <div
                            class="country"
                            *ngIf="
                                company.countries &&
                                company.countries.length == 1 &&
                                company.countries[0] != null &&
                                company.countries[0].toLowerCase() !== 'unknown'
                            "
                        >
                            {{ company.countries[0] }}
                        </div>
                        <div class="country" *ngIf="company.id > 0">
                            <a (click)="$event.stopPropagation()" [href]="getTreebuteProfile(company)" target="_blank">Synopsys ...</a>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Evidence</span>
                        <div
                            class="bar"
                            style="border: solid 1px rgb(232, 90, 78)"
                        >
                            <div
                                [style.background]="'rgb(232, 90, 78)'"
                                [style.width]="
                                    normalize(
                                        company.totalScore,
                                        maxScore,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        company.totalScore,
                                        maxScore,
                                        130
                                    ) > 129
                                "
                                [class.empty]="
                                    normalize(
                                        company.totalScore,
                                        maxScore,
                                        130
                                    ) === 0
                                "
                            ></div>
                        </div>
                    </div>

                    <div *ngIf="this.searchResults.searchType === 'CORPORATES'" class="category">
                        <span class="title">Articles</span>
                        <div
                            class="bar"
                            style="border: solid 1px rgb(186, 100, 110)"
                        >
                            <div
                                [style.background]="'rgb(186, 100, 110)'"
                                [style.width]="
                                    normalize(
                                        company.resourceScores['ARTICLE'],
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        company.resourceScores['ARTICLE'],
                                        100,
                                        130
                                    ) > 129
                                "
                                [class.empty]="
                                    normalize(
                                        company.resourceScores['ARTICLE'],
                                        100,
                                        130
                                    ) === 0
                                "
                            ></div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Patents</span>
                        <div
                            class="bar"
                            style="border: solid 1px rgb(139, 111, 142)"
                        >
                            <div
                                [style.background]="'rgb(139, 111, 142)'"
                                [style.width]="
                                    normalize(
                                        company.resourceScores['PATENT'],
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        company.resourceScores['PATENT'],
                                        100,
                                        130
                                    ) > 129
                                "
                                [class.empty]="
                                    normalize(
                                        company.resourceScores['PATENT'],
                                        100,
                                        130
                                    ) === 0
                                "
                            ></div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Clinical Trials</span>
                        <div
                            class="bar"
                            style="border: solid 1px rgb(93, 121, 173)"
                        >
                            <div
                                [style.background]="'rgb(93, 121, 173)'"
                                [style.width]="
                                    normalize(
                                        company.resourceScores['CT'],
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        company.resourceScores['CT'],
                                        100,
                                        130
                                    ) > 129
                                "
                                [class.empty]="
                                    normalize(
                                        company.resourceScores['CT'],
                                        100,
                                        130
                                    ) === 0
                                "
                            ></div>
                        </div>
                    </div>

                    <div *ngIf="this.searchResults.searchType === 'STARTUPS'" class="category">
                        <span class="title">Other</span>
                        <div
                            class="bar"
                            style="border: solid 1px rgb(46, 131, 205)"
                        >
                            <div
                                [style.background]="'rgb(46, 131, 205)'"
                                [style.width]="
                                    normalize(
                                        company.resourceScores['OTHER'],
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                                [class.fill]="
                                    normalize(
                                        company.resourceScores['OTHER'],
                                        100,
                                        130
                                    ) > 129
                                "
                                [class.empty]="
                                    normalize(
                                        company.resourceScores['OTHER'],
                                        100,
                                        130
                                    ) === 0
                                "
                            ></div>
                        </div>
                    </div>

                    <div class="line"></div>

                    <div
                        class="quote_container"
                        *ngIf="
                            company.resources.length > 0 &&
                            company.resources[0].valueB
                        "
                    >
                        <img
                            src="../../../../assets/images/innovation-icon.png"
                            class="innovation_icon"
                        />
                        <div class="quote">
                            {{ trimString(company.resources[0].valueB, 90) }}
                        </div>
                    </div>

                    <button *ngIf="!company.exclude"
                        class="read_more_button"
                        (click)="companyClicked(company)"
                    >
                        View More
                    </button>

                    <button *ngIf="company.exclude"
                            class="read_more_button"
                            (click)="this.removeCompanyFromList.emit(company)"
                    >
                        Delete
                    </button>

                    <exclude-include class="exclude-include"
                                     (isExcludeChange)="shouldSave()"
                                     [hidden]="dataService.hideShared()"
                                     [(isExclude)]="company.exclude"
                    ></exclude-include>
                </div>
            </div>
        </div>
    </div>
</div>
