<div id="project-tools" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
    <div class="card bg-dark">
        <div class="card-header panel-heading" (click)="preventDefault($event)" [class.collapsed]="openTools"
             id="headingOne" data-toggle="collapse"
             data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <div class="panel-title">
                <h5 class="mb-0 px-3">
                    <div class="btn-header" (click)="preventDefault($event)">
                        <span class="pr-2">Tools</span>
                        <span class="tool-attribute px-1" *ngIf="project.autoRefresh">
                        <i class='fas fa-recycle'></i>
                    </span>
                        <span class="tool-attribute px-1" *ngIf="isSharedProject">
                        <i class='fas fa-share-alt'></i>
                    </span>
                        <div class="spinner-border ml-3" role="status" style="width: 16px; height: 16px"
                             [hidden]="!showLoader && !processing">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </h5>
            </div>
        </div>
        <div id="collapseOne" class="collapse" [class.show]="openTools" [class.hide]="!openTools"
             aria-labelledby="headingOne" data-parent="#project-tools">
            <div class="card-body">
                <div class="pb-2">
                    <span class="pr-3">Auto Refresh</span>
                    <mat-slide-toggle (change)="autoRefreshUpdate($event)" [(ngModel)]="project.autoRefresh" [disabled]="!project.searchCount">

                    </mat-slide-toggle>
                </div>
                <div class="pb-2 refresh-select-wrapper">
                    <span class="pr-3">Refresh Rate</span>
                    <select class="form-control bg-dark text-light"
                            [disabled]="!project.searchCount"
                            [(ngModel)]="selectedRefreshRate"
                            (ngModelChange)="autoRefreshUpdate($event)">
                        <option value="DAY">Daily</option>
                        <option value="WEEK">Weekly</option>
                        <option value="MONTH">Monthly</option>
                    </select>
                </div>
                <div class="pb-3">
                    <span>Last Refresh: {{ lastRefresh }}</span>
                </div>
                <div class="pb-2">
                    <button class="btn btn-dark border text-light"
                            [disabled]="!project.searchCount"
                            (click)="refreshNow.emit($event)">Refresh Now
                    </button>
                </div>
                <div *ngIf="project &&
                                !hideShared &&
                                !readOnlyProjectService.isReadOnlyProject">
                    <hr/>
                    <app-share-project
                                       [project]="project"
                                       [(processing)]="processing"
                                       (updateShareProject)="updateShareProject($event)"></app-share-project>
                </div>
                <div *ngIf="!readOnlyProjectService.isReadOnlyProject">
                    <hr/>
                    <div class="d-none d-sm-none d-md-inline">
                        <div class="export-button" (click)="!!project.searchCount && exportProject()">
                            <button class="btn btn-dark border text-light" style="display: flex" [disabled]="!project.searchCount">
                                <div class="export-icon"></div>
                                <div class="pl-2">{{btnText}}</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div [hidden]="!isAdmin()">
                    <div>
                        <hr/>
                        <div>
                            <button class="btn btn-danger" (click)="deleteProject()">Delete Project</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
