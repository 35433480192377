<div [hidden]="!this.dataService.userData" [class.hide-navbar]="!this.dataService.userData"
        class="sidenav_container" [@onSideNavChange]="sideNavState ? 'open' : 'close'" >
    <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">

        <div class="sidebar-header">
<!--            <div><span>T</span></div>-->
            <div>
                <img src="/assets/images/Treebute - White FAVCON.png" class="big-logo" />
            </div>
        </div>

        <div [class.user_menu]="true">
<!--            <mat-nav-list>-->
<!--                <mat-list-item class="logo-container">-->
<!--                    -->
<!--                </mat-list-item>-->
<!--            </mat-nav-list>-->
            <mat-divider class="divider"></mat-divider>
        </div>

        <div>
            <mat-nav-list >
                <mat-list-item
                        *ngFor="let page of pages"
                        (click)="navigate(page)"
                        class="menu-item-icon-container "
                        matTooltip="{{page.tooltip}}"
                        matTooltipClass="tooltip-x"
                        [matTooltipPosition]="'after'"
                        [class.activePage] = "page.name == activePage"
                >
                    <div class="menu-item-wrapper">
                        <div class="menu-item-icon-sub-container">
                            <div class="menu-item-icon-container">
                                <mat-icon style="padding-right:5px;">{{page?.icon}}</mat-icon>
                            </div>
                            <div class="menu-item-text-container" *ngIf="linkText">
                                <span>{{ page?.name }} </span>
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-nav-list>
        </div>

        <mat-divider class="divider"></mat-divider>

        <mat-list-item class="logo-container" [hidden]="true">
            <div class="toggle-container-closed" [@animateText]="linkText ? 'hide' : 'show'" *ngIf="!linkText">
                <span class="material-icons" (click)="onSidenavToggle()" >keyboard_double_arrow_right</span>
            </div>
            <div class="toggle-container-open" [@animateText]="linkText ? 'show' : 'hide'" *ngIf="linkText">
                <span class="material-icons" (click)="onSidenavToggle()" >keyboard_double_arrow_left</span>
            </div>
        </mat-list-item>
    </div>

    <span class="spacer"></span>
    <div fxLayout="row" fxLayoutAlign="end center" style="padding: 0px 10px;">

    </div>
</div>
