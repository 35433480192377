<div *ngIf="expert">
    <ul
        class="nav nav-tabs"
        *ngIf="this.expert.expertData.allPublications.length > 0"
    >
        <li class="active">
            <a data-toggle="tab" href="#relevantPublications"
                >Relevant Publications</a
            >
        </li>
        <li>
            <a data-toggle="tab" href="#AllPublications">Other Publications</a>
        </li>
    </ul>
</div>

<div class="tab-content">
    <div id="relevantPublications" class="tab-pane active">
        <ul class="list-group">
            <li
                *ngFor="
                    let publication of this.expert.expertData
                        .matchingPublications
                "
                class="list-group-item"
            >
                <a
                    class="dropdown-item btn-sm"
                    href="{{ publication.url }}"
                    target="_blank"
                    >{{ publication.title }}</a
                >
            </li>
        </ul>
    </div>
    <div id="AllPublications" class="tab-pane">
        <ul class="list-group">
            <li
                *ngFor="
                    let publication of this.expert.expertData.allPublications
                "
                class="list-group-item"
            >
                <a
                    class="dropdown-item btn-sm"
                    href="{{ publication.url }}"
                    target="_blank"
                    >{{ publication.title }}</a
                >
            </li>
        </ul>
    </div>
</div>
