<div class="dialog">
    <div class="header">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <textarea class="message" readonly="true" disabled>{{finalMessage}}</textarea>
    <div class="footer">
        <button class="btn btn-dark border text-light" (click)="cancelSearch()">
            Cancel
        </button>
    </div>

</div>
