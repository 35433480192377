<div>
    <table mat-table matSort [dataSource]="this.researcherLines" class="common-table">
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!showAction">

            </th>
            <td mat-cell *matCellDef="let element" [hidden]="!showAction">
                <button style="margin-left: 0px !important" class="btn-primary mr-3" (click)="actionRun.emit(element)" >{{ actionButtonText }}</button>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Name
            </th>
            <td mat-cell *matCellDef="let element">
                <a href="{{'https://www.google.com/search?q='+element.lastName + ' ' + element.firstName+' '+element.department}}"
                   target="_blank" *ngIf="readOnlyProjectService.isReadOnlyProject">
                    {{ element.lastName }} {{ element.firstName }}
                </a>
                <a (click)="expertClick(element)" [routerLink]="[]" class="name" *ngIf="!readOnlyProjectService.isReadOnlyProject">
                    {{ element.lastName }} {{ element.firstName }}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="department" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="showStatus">
                Department
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="showStatus">
                <a href="{{ getGoogleSearchUrl(element) }}"
                   target="_blank">{{element.department}}</a>
<!--                <span class="material-icons-outlined">-->
<!--                    open_in_new-->
<!--                </span>-->
            </td>
        </ng-container>

        <ng-container  matColumnDef="fromSearch" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!showFromSearch">
                From Search
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="!showFromSearch">
                <div *ngFor="let searchDetails of element.searchDetails2">
                    <a [routerLink]="[getSearchLink(searchDetails)]">
                        {{ getSearchName(searchDetails) }}</a>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Country
<!--                <mat-select placeholder="Country" multiple>-->
<!--                    <mat-option [value]="true" (change)="toggleAll($event)">All</mat-option>-->
<!--                    <mat-option *ngFor="let country of countries"-->
<!--                                (change)="toggleCountry($event)"-->
<!--                                [value]="country.selected">{{country.country}}</mat-option>-->
<!--                </mat-select>-->
            </th>
            <td mat-cell *matCellDef="let element" style="text-transform: capitalize">
                <a href="{{ getGoogleSearchUrl(element) }}"
                   target="_blank">{{getCountry(element)}}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!showStatus">
                Status
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="!showStatus">
                <candidate-status [status]="getStatus(element)" [active]="showStatus"></candidate-status>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" #paginator class="mt-3"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
</div>


