import {
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {DataService} from "../../../../../data.service";

@Component({
    selector: 'editable-text',
    templateUrl: './editable-text.component.html',
    styleUrls: ['./editable-text.component.scss'],
})
export class EditableTextComponent implements OnInit, OnDestroy, OnChanges {

    // @Input() searchResults!: SearchQueryResults;
    // @Output() searchResultsChange = new EventEmitter<SearchQueryResults>();

    @Input() text!: string;
    @Output() textChange = new EventEmitter<string>();

    @Input() initialCaption: string;

    // @Output() titleChange = new EventEmitter<string>();
    editing: boolean = false;

    @ViewChild('titleInput') titleInput: ElementRef;


    constructor(public dataService: DataService) {
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    edit() {
        if (this.dataService.hideShared()) return;
        this.editing = true;
        setTimeout(() => this.titleInput.nativeElement.focus(), 0);
    }

    getTitle() {
        return this.text ?
            this.text :
            this.initialCaption;
    }

    updateTitle($event) {
        this.textChange.emit($event.srcElement.value);
        this.editing = false;
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

}
