import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { NetworkService } from '../../network.service';
import {Subscription} from "rxjs";
import {DataService, NavRequest} from "../../data.service";
import {MatSidenav} from "@angular/material/sidenav";
import {ProjectStateService} from "../../pages/projects-page/project-state-service";
import {InnovationProject} from "../../api-responses/InnovationProjects.interface";
import {UtilitiesService} from "../../utilities.service";
import {SearchQueryResults} from "../../api-responses/SearchQueryResults.interface";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [ProjectStateService]
})
export class NavbarComponent implements OnInit, OnDestroy {
    showAccountOptionsModal = false;
    public project: InnovationProject;
    public searchId: number;
    @Input() sidenav: MatSidenav;

    // userData: any;
    public search: SearchQueryResults;

    constructor(
        public router: Router,
        public networkService: NetworkService,
        public activatedRoute: ActivatedRoute,
        public dataService: DataService,
        public utilitiesService: UtilitiesService
    ) {
        this.subscriptions.push(
            this.dataService.otherPageState.subscribe(otherPage => {
                this.project = undefined;
                this.searchId = undefined;
                this.search = undefined;
            })
        );

        this.subscriptions.push(
            this.dataService.projectState.subscribe((project => {
                if (!project) return;
                this.project = project;
                if (!this.isSearchPage()) {
                    this.searchId = undefined;
                    this.search = undefined;
                }
            }))
        );

        this.subscriptions.push(
            this.dataService.searchIdState.subscribe((searchId) => {
                this.searchId = searchId;
            })
        );

        this.subscriptions.push(
            this.dataService.searchState.subscribe((search: SearchQueryResults) => {
                this.search = search;
            })
        );

        // *************************************************************************************

        this.subscriptions.push(this.dataService.saveSearchObservable.subscribe((navRequest: NavRequest) => {
            if (navRequest.message === NavRequest.MSG.NAV_OK) {
                this._nav(navRequest);
            }
        }));

        this.dataService.newVersionExists$.subscribe((val: boolean) => {
            this.newVersionExists = val;
        });
    }

    subscriptions: Subscription[] = [];

    async ngOnInit() {
    }

    newVersionExists:boolean = false;

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    toggleAccountOptionsModal() {
        this.showAccountOptionsModal = !this.showAccountOptionsModal;
    }

    isProjectPage() {
        return !this.searchId && this.project && this.project.id && !this.isSearchPage();
    }

    isSearchPage() {
        return this.project && !!this.project.id && window.location.href.indexOf('/search') > -1;
    }

    back() {
        this.dataService.saveSearchUpdate(new NavRequest('/app/projects/' + this.project.id, NavRequest.MSG.NAV));
    }

    _nav(navRequest: NavRequest) {
        if (!navRequest.mouseEvent) {
            return this.router.navigate([navRequest.dest], {});
        }
        else {
            if (navRequest.mouseEvent.button === 0) {
                return this.router.navigate([navRequest.dest], {});
            } else if (navRequest.mouseEvent.button === 1) {
                window.open(navRequest.dest, "_blank");
            }
        }
    }

    reload() {
        window.location.reload();
    }

    navToProjects($event: MouseEvent = null) {
        this.dataService.saveSearchUpdate(new NavRequest('/app/projects/', NavRequest.MSG.NAV, $event));
    }

    getProjectName() {
        return UtilitiesService.trimString(this.project.name, 40);
    }

    navToProject($event: MouseEvent = null) {
        this.dataService.saveSearchUpdate(new NavRequest('/app/projects/' + this.project.id, NavRequest.MSG.NAV, $event));
    }

    isToolPage() {
        return this.project && !!this.project.id &&
            (window.location.href.indexOf('/experts-tool') > -1 || window.location.href.indexOf('/ct-tool') > -1);
    }

    getToolName() {
        let parts = window.location.href.split("/");
        let toolName = parts[parts.length-1];
        return toolName.replace("-", " ").trim();
    }

}
