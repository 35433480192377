<div class="container" style="width: 100%">
    <div class="row">
        <h1>
            {{ domain }}
        </h1>
    </div>
    <div
        class="row"
        style="margin: 1px 0px 10px 1px; background-color: whitesmoke"
        *ngIf="this.review"
    >
        <div class="container-fluid">
            <div class="row justify-content-md-center">
                <div class="col col-lg-4">
                    <div>
                        <label
                            >Filter:
                            <input
                                #filter
                                style="width: 70vw"
                                (keydown.enter)="searchStartups(filter.value)"
                        /></label>
                    </div>
                    <div>
                        <span #feedback style="display: none"> &#11044; </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="container-fluid">
            <div class="row justify-content-md-center">
                <div class="col col-lg-4">
                    <div>
                        <div
                            *ngFor="
                                let startup of this.startups;
                                first as isFirst
                            "
                            class="cardContainer"
                        >
                            <div>
                                Name:
                                <input
                                    *ngIf="isFirst"
                                    #name
                                    type="text"
                                    value="{{ startup['name'] }}"
                                    (input)="
                                        onSearchChange($event.target.value)
                                    "
                                />
                                <span *ngIf="!isFirst">{{
                                    startup['name']
                                }}</span>
                            </div>
                            <div>
                                Country:
                                <input
                                    *ngIf="isFirst"
                                    #country
                                    type="text"
                                    value="{{ startup['country'] }}"
                                />
                                <span *ngIf="!isFirst">{{
                                    startup['country']
                                }}</span>
                            </div>
                            <div>
                                <div>
                                    <span
                                        >Homepage Url:
                                        <a
                                            href="{{ startup['homepageUrl'] }}"
                                            target="_blank"
                                            >{{ startup['homepageUrl'] }}</a
                                        ></span
                                    >
                                    <span> | </span>
                                    <span>Address: </span>
                                    <span>{{ startup['address'] }}</span>
                                </div>
                                <div>Info: {{ startup['info'] }}</div>
                                <div>
                                    Discovered: {{ startup['creationDate'] }}
                                </div>
                                <div *ngIf="isFirst">
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="setAsUnknown(startup)"
                                        [class.selectedType]="
                                            this.chosenType === 'UNKNOWN'
                                        "
                                        value="[1] Unknown"
                                        style="display: inline"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="setAsStartup(startup)"
                                        [class.selectedType]="
                                            this.chosenType === 'STARTUP'
                                        "
                                        value="[2] Startup"
                                        style="display: inline"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="setAsVc(startup)"
                                        [class.selectedType]="
                                            this.chosenType === 'VC'
                                        "
                                        value="[3] Venture Capital"
                                        style="display: inline"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="setAsAggregator(startup)"
                                        [class.selectedType]="
                                            this.chosenType === 'AGGREGATOR'
                                        "
                                        value="[4] Aggregator"
                                        style="display: inline"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="setAsHospital(startup)"
                                        [class.selectedType]="
                                            this.chosenType === 'HOSPITAL'
                                        "
                                        value="[5] hospital"
                                        style="display: inline"
                                        [disabled]="true"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="setAsCorporate(startup)"
                                        [class.selectedType]="
                                            this.chosenType === 'CORPORATE'
                                        "
                                        value="[6] corporate"
                                        style="display: inline"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="save(startup)"
                                        value="[7] save"
                                        style="display: inline"
                                        [disabled]="!enableSave"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="close(startup)"
                                        value="[8] close"
                                        style="display: inline"
                                        [disabled]="!enableClose"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="excludeUrl(startup)"
                                        value="[9] exclude"
                                        [class.excludeInclude]="
                                            this.excludeState === 'exclude'
                                        "
                                        style="display: inline"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="includeUrl(startup)"
                                        value="[0] include"
                                        [class.excludeInclude]="
                                            this.excludeState === 'include'
                                        "
                                        style="display: inline"
                                    />
                                    <input
                                        class="btn btn-primary btn-sm"
                                        type="button"
                                        (click)="openWindow(startup)"
                                        value="[o] open"
                                        style="display: inline"
                                    />

                                    <!--        <input class="btn btn-primary btn-sm" type="button" (click)="markAsDuplicateAndClose(startup)" value="[D] mark as duplicate and close"-->
                                    <!--               style="display: inline;"/>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-lg-8">
                    <iframe
                        style="height: 100vh; width: 100%"
                        #preview
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
