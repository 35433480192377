import {Injectable, OnInit} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {NetworkService} from "../../network.service";
import {InnovationProjectEvidenceInterface} from "../../api-responses/InnovationProjectEvidence.interface";
import {InnovationProject} from "../../api-responses/InnovationProjects.interface";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {DataService} from "../../data.service";
import {ReadOnlyProjectService} from "../../read-only-project-service";

@Injectable({providedIn: 'root'})
export class ProjectStateService {


//     constructor(
//         public activatedRoute: ActivatedRoute,
//                 public networkService: NetworkService) {
//                  this.activatedRoute.paramMap.subscribe(map => {
//                      console.log("*****")
//                      console.log(map)
//                  });
//     }
//
//     ngOnInit(): void {
//     }
// }
    private projectId: number;
    private compareProjectId: number;

    private otherPage: boolean = true;
    private otherPageState = new BehaviorSubject(this.otherPage);
    otherPageStateObservable: Observable<boolean> = this.otherPageState.asObservable();

    updateOtherPageState(otherPage: boolean) {
        this.otherPageState.next(otherPage);
        this.dataService.updateOtherPageState(otherPage);
    }

    private project: InnovationProject;
    private compareProject: InnovationProject
    public projectState = new Subject<InnovationProject>();
    public compareProjectState = new Subject<InnovationProject>();

    updateProjectIdState(project: InnovationProject) {
        this.projectState.next(project);
        this.dataService.updateProjectIdState(this.project, false);
    }

    updateCompareProjectIdState(project: InnovationProject) {
        this.compareProjectState.next(project);
        this.dataService.updateProjectIdState(this.compareProject, true);
    }

    public evidence: InnovationProjectEvidenceInterface;
    private evidenceState = new Subject<InnovationProjectEvidenceInterface>();
    evidenceStateObservable: Observable<InnovationProjectEvidenceInterface> = this.evidenceState.asObservable();

    public compareEvidence: InnovationProjectEvidenceInterface;
    private compareEvidenceState = new Subject<InnovationProjectEvidenceInterface>();
    compareEvidenceStateObservable: Observable<InnovationProjectEvidenceInterface> = this.compareEvidenceState.asObservable();

    updateEvidenceState(evidence: InnovationProjectEvidenceInterface) {
        this.evidenceState.next(evidence);
    }

    updateCompareEvidenceState(evidence: InnovationProjectEvidenceInterface) {
        this.compareEvidenceState.next(evidence);
    }


    public searchId: number = undefined;
    private searchIdState = new BehaviorSubject(this.searchId);
    searchIdStateObservable: Observable<number> = this.searchIdState.asObservable();

    updateSearchIdState(searchId: number) {
        this.searchIdState.next(searchId);
    }

    getInovationProject(map: ParamMap){
        this.networkService
        .innovationProjects
        .getById(this.projectId, true)
        .then((project) => {
            this.project = project;
            
            this.updateProjectIdState(this.project);

            // phase 1: get the innovation project evidence
            this.networkService
                .getProjectEvidence(this.project.id.toString())
                .toPromise()
                .then((evidence: InnovationProjectEvidenceInterface) => {
                    this.evidence = evidence;
                    // console.log(this.evidence.experts.filter(x => x.searchDetails2 && x.searchDetails2.length > 1))

                    this.evidence.experts.sort((a, b) => {
                        let bestRankA = Math.min(...(a.searchDetails2.map(x => x.rank)));
                        let bestRankB = Math.min(...(b.searchDetails2.map(x => x.rank)));
                        if (bestRankA > bestRankB) return 1;
                        return -1;
                    })

                    this.updateEvidenceState(evidence)
                });

            // phase 2 is there a search too ?
            if (map.get('searchId') && (!this.searchId || map.get('searchId') !== this.searchId.toString())) {
                this.searchId = Number.parseInt(map.get('searchId'));
                this.updateSearchIdState(this.searchId);
            }
        });
    }

    getCompareProject(map: ParamMap){
        this.networkService
        .innovationProjects
        .getById(this.compareProjectId, true)
        .then((project) => {
            this.compareProject = project;
            
            this.updateCompareProjectIdState(this.compareProject)

            // phase 1: get the innovation project evidence
            this.networkService
                .getProjectEvidence(this.compareProject.id.toString())
                .toPromise()
                .then((evidence: InnovationProjectEvidenceInterface) => {
                    this.compareEvidence = evidence;
                    // console.log(this.evidence.experts.filter(x => x.searchDetails2 && x.searchDetails2.length > 1))

                    this.compareEvidence.experts.sort((a, b) => {
                        let bestRankA = Math.min(...(a.searchDetails2.map(x => x.rank)));
                        let bestRankB = Math.min(...(b.searchDetails2.map(x => x.rank)));
                        if (bestRankA > bestRankB) return 1;
                        return -1;
                    })

                    this.updateCompareEvidenceState(evidence)
                });
        });
    }

    constructor(
        public dataService: DataService,
        public activatedRoute: ActivatedRoute,
        public networkService: NetworkService,
        public readOnlyProjectService: ReadOnlyProjectService
    ) {
        if (readOnlyProjectService.isReadOnlyProject) return;

        // console.log("boker tov");
        this.activatedRoute.paramMap.subscribe((map: ParamMap) => {
            // no specific project
            if (!map.has("projectId")) {
                this.updateOtherPageState(true);
                return;
            }

            this.updateOtherPageState(false);

            if (this.projectId && (this.projectId.toString() === map.get("projectId"))) return; // no change

            // phase 1: get the innovation project id from fragment
            this.projectId = Number(map.get("projectId"));

            // phase 2: get the innovation project object from the server
            this.getInovationProject(map);

            // phase 3: check if compare project id exists
            if (map.has("compareId")){
                
                //phase 3.1: get compare project id from fragment
                (this.compareProjectId = Number(map.get("compareId")));

                // phase 3.2: get compare project object from server if exists
                map.has("compareId") && this.getCompareProject(map);
            }
        })
    }


}
