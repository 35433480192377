import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewProjectDialogComponent } from '../new-project-dialog/new-project-dialog.component';
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-empty-project-card',
    templateUrl: './empty-project-card.component.html',
    styleUrls: ['./empty-project-card.component.scss'],
})
export class EmptyProjectCardComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        public matDialog: MatDialog,
    ) {}

    ngOnInit() {}

    openDialog() {
        const dialogRef = this.matDialog.open(NewProjectDialogComponent);

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                console.log(`Dialog result: ${result}`);
            });

        // window.location.hash = 'new';
    }
}
