<div>
<!--    <div>-->
<!--        <h1>Create New Company</h1>-->
<!--    </div>-->
    <div>
        <span>Company Website:</span>
        <input type="text" #homepageUrl>
        <input *ngIf="!editMode" type="button" value="Search" (click)="search(homepageUrl.value)">
        <input *ngIf="!editMode" type="button" value="Reset" (click)="startOver(homepageUrl.value)">
    </div>
    <div class="loader-panel" *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="this.error">
        <span style="color: #ff7777">{{error}}</span>
    </div>
    <div [hidden]="!afterSearch">
        <div class="">
            <span>Company Name: </span>
            <input #companyName
                    type="text"
                    class="input name"
                    placeholder="Company Name"
            />
        </div>
        <div>
            <span>Country:</span>
            <select #countrySelect [value]="'israel'">
                <option
                        [ngValue]="country.name.toLowerCase()"
                        *ngFor="let country of countries"
                        [value]="country.name.toLowerCase()"
                >
                    {{ country.name.toLowerCase() }}
                </option>
            </select>
        </div>
    </div>
    <div>
        <input type="button" value="{{getActionText()}}" (click)="add()">
    </div>

</div>
