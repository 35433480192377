import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {DataService} from "../../../../data.service";
import {TileBreadCrumb} from "../tile-bread.crumb";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {UtilsComponent} from "../../../../utils/utils.component";


@Component({
    selector: 'tile-search-breadcrumbs',
    templateUrl: './tile-search-breadcrumbs.component.html',
    styleUrls: ['./tile-search-breadcrumbs.component.scss'],
})
export class TileSearchBreadcrumbsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() type = 'campus';
    @Input() searchString;
    @Input() searchResults: SearchQueryResults;
    // @Input() selectedCountry: string;
    @Input() showSearchResults: boolean;
    @Input() tileData: any;
    @Input() selectedCountry;
    @Output() showTheResearchers = new EventEmitter();
    @Output() showTiles = new EventEmitter();

    @Input() breadCrumbsStack:TileBreadCrumb[] = [] ;
    @Output() navigate = new EventEmitter<TileBreadCrumb[]>();
    @Output() searchTiles = new EventEmitter();

    @Input() showBreadCrumbs: boolean = true;

    constructor(public dataService: DataService, public utilsComponent: UtilsComponent) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResults && changes.searchResults.currentValue){
            this.searchResults = changes.searchResults.currentValue;
            this.searchString = this.searchResults.query;
            this.type = this.searchString ? 'explorer' : 'campus';
            this.breadCrumbsStack = this.utilsComponent.getBreadCrumbs(this.searchResults);
        }
        if (changes.searchString && changes.searchString.currentValue){
            this.searchString = changes.searchString.currentValue;
            this.type = this.searchString ? 'explorer' : 'campus';
            // if (changes.searchString.currentValue != changes.searchString.previousValue) {
            //     this.breadCrumbsStack = [];
            // }
        }
        if (changes.tileData && changes.tileData.currentValue){
            this.tileData = changes.tileData.currentValue;
        }
        if (changes.breadCrumbsStack && changes.breadCrumbsStack.currentValue) {
            this.breadCrumbsStack = changes.breadCrumbsStack.currentValue;
        }

        // if (changes.selectedCountry) {
        //     this.selectedCountry = changes.selectedCountry.currentValue;
        // }

    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    isCampus() {
        return this.type === 'campus';
    }

    isExplorer() {
        return this.type === 'explorer';
    }

    getBreadcrumbRootText() {
        return (this.getScope() === 'User Scope' || this.getScope() === 'user') && this.dataService.userData ?
                this.dataService.userData.instituation :
                this.getScope();
    }


    breadCrumbClicked(event: any) {
        // if (this.showSearchResults) {
        //     const result = [];
        //     for (const tileBreadCrumb of this.breadCrumbsStack) {
        //         result.push(tileBreadCrumb)
        //         if (tileBreadCrumb.id === event) break;
        //     }
        //     this.searchTiles.emit(result);
        //     this.showSearchResults = false;
        // }
        // else {
            if (event instanceof MouseEvent) {
                this.breadCrumbsStack = [];
                this.navigate.emit([]);
            } else {
                const result = [];
                for (const tileBreadCrumb of this.breadCrumbsStack) {
                    result.push(tileBreadCrumb)
                    if (tileBreadCrumb.id === event) break;
                }
                this.breadCrumbsStack = result;
                this.navigate.emit(result);
            }
        // }
    }

    _showExperts() {
        this.showTheResearchers.emit({
            data: {
                query: this.searchString,
                searchType: 'TILE_SEARCH_EXPERTS',
                scope: this.getScope(),
                descriptorParentIds: this.breadCrumbsStack.map(x => x.id).join()
            }
        });
    }

    isGlobal() {
        return this.utilsComponent.isGlobal(this.searchResults.scope);
    }

    getScope() {
        if (this.isCampus()) {
            // only user data
            // return this.dataService.userData ? this.dataService.userData.instituation : 'User Scope';
            return 'User Scope';
        }
        else if (this.isExplorer()) {
            if (this.tileData) {
                return this.tileData.scope;
            }
            else {
                return this.selectedCountry;
            }
        }
    }

}
