import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { workerPromise } from './msw/workerPromise';

if (environment.production) {
    enableProdMode();
}
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

// if (environment.production) {
//     enableProdMode();
//     platformBrowserDynamic()
//         .bootstrapModule(AppModule)
//         .catch((err) => console.error(err));
// } else {
//     workerPromise.then(() => {
//         platformBrowserDynamic()
//             .bootstrapModule(AppModule)
//             .catch((err) => console.error(err));
//     });
// }
