import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {DataService} from "../../../../data.service";

@Component({
    selector: 'search-title-and-summary',
    templateUrl: './search-title-and-summary.component.html',
    styleUrls: ['./search-title-and-summary.component.scss'],
})
export class SearchTitleAndSummaryComponent implements OnInit, OnDestroy, OnChanges {

    title: string;
    @Input() searchResults!: SearchQueryResults;
    @Output() searchResultsChange = new EventEmitter<SearchQueryResults>()

    constructor(public dataService: DataService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResults.currentValue) {
            //this.title = changes.searchResults.currentValue.title;
            this.searchResultsChange.emit(this.searchResults);
        }
        // if (changes.title && !changes.title.firstChange) {
        //     this.title = changes.title.currentValue;
        //     this.titleUpdate.emit(this.title);
        // }
    }

    ngOnDestroy(): void {
    }

}
