import {Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {NetworkService} from "../../../../network.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    ExportProject,
    NewSearchModalData,
    SelectModalData,
    SelectModalSelectItem
} from "../../../../api-responses/Dialogs.interface";
import {SearchResult} from "../../../../api-responses/SearchResult.interface";
import {DataService} from "../../../../data.service";
import {UtilitiesService} from "../../../../utilities.service";

interface selectedSearchResult {
    selected: boolean,
    searchResult: SearchResult
}

@Component({
    selector: 'export-project-modal',
    templateUrl: 'export-project-modal.component.html',
    styleUrls: ['export-project-modal.component.scss'],
})
export class ExportProjectModalComponent implements OnInit, OnDestroy, OnChanges {

    exportProject: ExportProject = {
        ids: [],
        geo: false,
        linkedIn: false,
        googleAcademic: false,
        description: false,
        esri: false
    } as ExportProject;
    searches: selectedSearchResult[] = [];

    constructor(public networkService: NetworkService,
                public dataService: DataService,
                public dialogRef: MatDialogRef<ExportProjectModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: SearchResult[]) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.searches = this.data.map(x => Object.assign({ selected: false, searchResult: x }));
    }

    export() {
        this.exportProject.ids = this.searches.filter(x => x.selected).map(x => x.searchResult.id);
        this.dialogRef.close(this.exportProject);
    }

    closeModal() {
        this.dialogRef.close();
    }

    getTitle(title: string) {
        return (title.length > 25) ?
            title.substr(0, 25) + "..." :
            title;
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }

    anythingSelected() {
        return this.searches && this.searches.filter(x => x.selected).length > 0;
    }
}
