import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NewSearchModalData, SearchTitleDialogData} from "../../../../api-responses/Dialogs.interface";

@Component({
    selector: 'new-search-modal',
    templateUrl: './new-search-modal.component.html',
    styleUrls: ['./new-search-modal.component.scss'],
})
export class NewSearchModalComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<NewSearchModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NewSearchModalData) {
    }

    ngOnInit(): void {

    }

    closeModal() {
        this.dialogRef.close('cancel');
    }

    newSearch() {
        this.dialogRef.close('new');
    }

    overwriteSearch() {
        this.dialogRef.close('overwrite');
    }

    showingSavedResults() {
        return this.data.searchResults && !!this.data.searchResults.id;
    }
}
