import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SearchQueryResults, SearchQueryResultsAsync} from "../api-responses/SearchQueryResults.interface";

@Injectable({
    providedIn: 'root',
})
export class Searches implements OnInit, OnDestroy {

    constructor(private http: HttpClient) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    getNewSearchResults(params: {
        query: string;
        kind: string;
        scope: string;
        academicField: string;
        descriptorParentIds: string;
        projectId: string;
    }) {
        if (params.descriptorParentIds === undefined) {
            delete params.descriptorParentIds;
        }

        return this.http.get<String>(`/api/v1/searches`, {
            params,
        });
    }

    getAsyncSearch(uuid) {
        return this.http.get<SearchQueryResultsAsync>(`/api/v1/searches/getAsyncSearch?searchId=${uuid}`)
            .toPromise().then((searchQueryResultsAsync: SearchQueryResultsAsync) => {
                this.normalize(searchQueryResultsAsync.treebuteSearchResult);
                return searchQueryResultsAsync;
            });
    }

    getSearchById(id: string, latest: boolean) {
        let path = `/api/v1/searches/` + id;
        if (latest) {
            path += '?latest=true'
        }
        return this.http.get<SearchQueryResults>(path)
            .toPromise()
            .then((searchQueryResults: SearchQueryResults) => {
                this.normalize(searchQueryResults);
                return searchQueryResults;
            })
    }

    grantAnalysis(id: string) {
        return this.http.get<SearchQueryResults>(`/api/v1/searches/${id}/grantAnalysis`)
            .toPromise()
            .then((searchQueryResults: SearchQueryResults) => {
                this.normalize(searchQueryResults);
                return searchQueryResults;
            });
    }

    updateSearch(search: SearchQueryResults, searchId: string) {
        return this.http.put<SearchQueryResults>(
            `/api/v1/searches/${searchId}`,
            search,
        ).toPromise()
            .then((searchQueryResults: SearchQueryResults) => {
                this.normalize(searchQueryResults);
                return searchQueryResults;
            });
    }

    saveSearch(
        search: SearchQueryResults & { title: string },
        params: { projectId: string },
    ) {
        return this.http.post<SearchQueryResults>(`/api/v1/searches`, search, {
            params,
        }).toPromise()
            .then((searchQueryResults: SearchQueryResults) => {
                this.normalize(searchQueryResults);
                return searchQueryResults;
            });
    }

    deleteSearch($event: any) {
        return this.http.delete(`/api/v1/searches/${$event}`);
    }

    private normalize(searchQueryResults: SearchQueryResults) {
        if (!searchQueryResults) return;

        if (searchQueryResults.academicField === 'ONLY_BIOMED') {
            searchQueryResults.academicField = 'BIOMED';
        }
        else if (searchQueryResults.academicField === 'ONLY_NON_BIOMED') {
            searchQueryResults.academicField = 'NONBIOMED';
        }
    }
}
