<div class="app-chart-container">
    <div>
        <div class="chart-header">
            <div class="chart-text">
                {{ chartTitle }}
                <button (click)="openCountryFilter()" class="btn-secondary short-button">{{ filterButtonText }}</button>
            </div>
            <div class="lovely-text">
                {{getCountryFilterDescription()}}
            </div>
        </div>
        <div class="app-chart">
            <geo-chart [ratio]="ratio" [countries]="countryDist"></geo-chart>
        </div>
    </div>
</div>
