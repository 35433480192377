<div class="general-page-container projects-page">
    <div [class.disable-when-loading]="loading" class="search-wrapper">
        <app-search-file-tools
                [(searchResults)]="searchResults"
                (save)="saveSearch($event)"
                (exportData)="exportData($event)"
                (deleteSearch)="this.deleteSearch($event)"
                class="search-file-tools-section"
        ></app-search-file-tools>
        <app-search-bar
                [isIndustry]="this.isIndustry"
                [isExperts]="this.isExperts"
                [isKnowledge]="this.isKnowledge"
                [searchResults]="this.searchResults"
                (searchIndustry)="this.search($event)"
                (searchExperts)="this.search($event)"
                (searchTiles)="this.searchTiles($event)"
                (changeTab)="this.changeTab($event)"
        ></app-search-bar>
    </div>
    <div>
        <div class="loader-panel" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <app-search-filters [class.disable-when-loading]="loading"
                            [loading]="loading"
                            [hidden]="hideTools()"
                            [isIndustry]="this.isIndustry"
                            [(searchResults)]="searchResults"
                            (searchResultsChange)="invalidate()"
                            [hasGrants]="searchResults.filterHasGrants"
                            (hasGrantsChange)="updateHasGrants($event)"
                            *ngIf="searchResults"
        >
        </app-search-filters>

        <app-search-tools [class.disable-when-loading]="loading"
                          [loading]="loading"
                          [hidden]="hideTools()"
                          [searchResults]="searchResults"
                          (excludeAll)="excludeAll($event)"
                          (includeAll)="includeAll($event)"
                          (add)="addCompanyToList($event)"
        >
        </app-search-tools>

        <div [class.disable-when-loading]="loading">
            <app-companies-search
                    (setLoading)="this.setLoading($event)"
                    [searchResults]="this.getSearchResults('INDUSTRY')"
                    [projectId]="this.projectId"
                    (removeCompanyFromList)="this.removeCompanyFromList($event)"
                    *ngIf="this.getIsIndustry()"
            ></app-companies-search>

            <app-experts
                    *ngIf="this.getIsExperts() && !this.getIsCtExperts()"
                    [searchResults]="this.getSearchResults('EXPERTS')"
                    (setLoading)="this.setLoading($event)"
            ></app-experts>

            <ct-experts
                    *ngIf="this.getIsCtExperts()"
                    [searchResults]="this.getSearchResults('EXPERTS')"
                    (setLoading)="this.setLoading($event)"
            ></ct-experts>

            <tile-search-component
                    *ngIf="this.getIsTiles()"
                    [pisOnly]="this.pisOnly"
                    [youngLeadersOnly]="this.youngLeadersOnly"
                    [youngTalentsOnly]="this.youngTalentsOnly"
                    [influencerOnly]="this.influencerOnly"
                    [globalSearch]="this.searchResults && this.searchResults.scope && this.searchResults.scope.toLowerCase() == 'world'"
                    [showSearchResults]="this.tilesShowSearchResults"
                    [searchResults]="getSearchResults('TILES')"
                    [tileData]="this.tileData"
                    [searchString]="this.queryTiles"
                    (breadCrumbsStackPop)="this.breadCrumbsStackPop()"
                    (breadCrumbsStackPush)="this.breadCrumbsStackPush($event)"
                    [breadCrumbsStack]="this.breadCrumbsStack"
                    (updateMap)="this.searchTiles($event)"
                    (showTheResearchers)="this.search($event)"
                    (showTiles)="this.showTiles($event)"
                    (navigate)="this.navigateBreadcrumbs($event)"
            ></tile-search-component>
        </div>
    </div>
</div>
