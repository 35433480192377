import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {InnovationProject} from "../../../api-responses/InnovationProjects.interface";
import {DataService} from "../../../data.service";
import {NetworkService} from "../../../network.service";
import {ProjectStateService} from "../../projects-page/project-state-service";
import {ReadOnlyProjectService} from "../../../read-only-project-service";
import { Canvas } from "../canvas.interface";


@Component({
    selector: 'canvas-project-preview',
    templateUrl: './canvas-project-preview.component.html',
    styleUrls: ['./canvas-project-preview.component.scss']
})
export class CanvasProjectPreviewComponent implements OnInit{
    @Input() canvasId: number;
    @Input() readonly : boolean = false;
    @Input() innovationProjectId: number;
    project: InnovationProject;
    @Output() doneLoading = new EventEmitter();

    constructor(public dataService: DataService,
                public readOnlyProjectService: ReadOnlyProjectService,
                public networkService: NetworkService,
    ) {}

    ngOnInit(): void {
        if (this.readonly) {
            this.networkService.canvas.getSharedCanvasProject(this.dataService.isDev(), this.canvasId, this.innovationProjectId).then((res: InnovationProject) => {
                this.project = res;
                this.doneLoading.emit(true);
            })
        }
        else {
            this.networkService.innovationProjects.getById(this.innovationProjectId, false).then((res: InnovationProject) => {
                this.project = res;
                this.doneLoading.emit(true);
            })
        }

    }

}
