import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {environment} from "../environments/environment";

@Injectable({providedIn: 'root'})
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // if (request.url.indexOf('/signin') > -1) {
        //     return next.handle(apiReq);
        // }

        let apiReq;
        if (request.url.indexOf('/api/v1/') === 0 || request.url.indexOf('/login') === 0 || request.url.indexOf('/logout') === 0 || request.url.indexOf('iapi/') > -1) {
            let slash = request.url.indexOf('/') === 0 ? '': '/';
            apiReq = request.clone({ url: `${environment.apiBaseUrl}${slash}${request.url}` });
        }
        else {
            apiReq = request;
        }

        // const httpOptions = {
        //     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        //     withCredentials: true,
        //     observe: 'response' as 'response',
        // };

        const apiReq2 = apiReq.clone({
            withCredentials: true
        });

        return next.handle(apiReq2).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // if (error && error.url && error.url.endsWith("/signin")) {
                //     window.open("/signin");
                // }
                // if (error.url.indexOf('/signin') > -1) {
                //     window.location.assign('/app/login-page');
                //     return;
                // }
                if (
                    error &&
                    error.message &&
                    error.message.includes('unknown url')
                ) {
                    // window.location.reload();
                }
                console.error(error);
                return throwError(error);
            }),
        );
    }
}
