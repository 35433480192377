import { Component, OnInit } from '@angular/core';
import {DataService} from "../../data.service";
import {NetworkService} from "../../network.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {

    email = '';
    firstName = '';
    lastName = '';
    password = '';
    error_message: string;
    public check = false;

    constructor(
        public dataService: DataService,
        private networkService: NetworkService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        localStorage.clear();
        document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 2010 00:00:00 UTC; path=/; domain=.mysite.se';
    }

    register() {
        try {
            this.networkService.register(this.email, this.firstName, this.lastName, this.password, this.check).then((result) => {
                if (result === 'firstName' || result === 'lastName') {
                    this.error_message = 'Please enter name';
                }
                else if (result === 'email') {
                    this.error_message = 'Please enter valid email';
                }
                else if (result === 'password') {
                    this.error_message = 'Please enter valid password';
                }
                else if (result === 'alreadyExists' || result === 'notInvited') {
                    this.error_message = 'Error. Please contact Treebute for support.';
                }
                else if (result === 'accept') {
                    this.error_message = 'Terms of use must be accepted';
                }
                else if (result === 'ok') {
                    window.location.href = '/app/projects';
                }
            });
        } catch (e) {
            console.error(e);
            this.error_message = e.message;
        }
    }
}
