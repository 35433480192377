import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import WordCloud from 'wordcloud';

@Component({
    selector: 'app-word-cloud',
    templateUrl: './word-cloud.component.html',
    styleUrls: ['./word-cloud.component.scss'],
})
export class WordCloudComponent implements OnInit, AfterViewInit {
    @Input() data: Map<String, Int16Array>;
    @Output() wordClick = new EventEmitter();
    values = [];

    constructor() {}

    @ViewChild('canvasElement') canvasElement: ElementRef;

    ngOnInit() {
        const keys = Object.keys(this.data);
        for (let i = 0; i < keys.length; i++) {
            const value = this.data[keys[i]];
            this.values.push([keys[i], value]);
        }
    }

    pickWordColor(word: string, weight) {
        if (word.includes(' ')) {
            return 'pink';
        }
        return `rgb(${(100 - (25 - weight) * 4) * 2.56}, ${
            (100 - (25 - weight) * 4) * 2.56
        },${(100 - (25 - weight) * 4) * 2.56})`;
    }

    ngAfterViewInit(): void {
        WordCloud(this.canvasElement.nativeElement, {
            list: this.values,
            backgroundColor: 'transparent',
            click: (item, dimension, event) => {
                const word = item[0];
                // this.floatingUpdateService.notifyWordSuggestionUpdated(word);
                this.wordClick.emit(word);
            },
            gridSize: 20,
            minRotation: 0,
            maxRotation: 1,
            color: this.pickWordColor,
        });
    }
}
