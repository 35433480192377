<div id="topContainer" class="saved-search-area">
    <div id="layer">
        <div class="results">
            <div  id="expertsContainer">
                <div
                        id="listOption"
                        *ngIf="cardsDisplay && vw() >= 1000 && false"
                        (click)="listDisplay = !listDisplay; calculateHeight()"
                        [class.listMargin]="listDisplay"
                >
                    <img *ngIf="listDisplay" src="../../../../assets/images/checkbox_selected.png"/>
                    <img *ngIf="!listDisplay" src="../../../../assets/images/checkbox.png"/>
                    <span> View the experts in a list </span>
                </div>

                <div
                        class="expertsContainer"
                        *ngIf="cardsDisplay && !listDisplay && type === undefined && !this.isCtExperts()"
                >
                    <app-expert-card
                            *ngFor="let expert of _filteredExperts; let i = index"
                            (addExpertToSelectedArr)="addExpertToSelectedArr(expert)"
                            (removeExpertFromSelectedArr)="removeExpertFromSelectedArr(expert)"
                            (showModal)="open(expert, this._filteredExperts, false)"
                            (directRequest)="newRequest(expert)"
                            [expertDetailsLoading]="expertDetailsLoading"
                            [firstName]="expert.firstName"
                            [lastName]="expert.lastName"
                            [department]="expert.department"
                            [relevancy]="expert.expertScore"
                            [impact]="expert.citationsScore"
                            [innovation]="expert.patentsScore"
                            [quote]="expert.quote"
                            [email]="expert.email"
                            [expert]="expert"
                            [innovationPresent]="innovationPresent"
                            [opacity]="getOpacity(i)"
                            [cardIndex]="i"
                    >
                    </app-expert-card>
                </div>

                <div
                        class="expertsContainer"
                        *ngIf="cardsDisplay && !listDisplay && type === undefined && this.isCtExperts()"
                >
                    <ct-expert-card
                            *ngFor="let expert of filterExperts(experts$); let i = index"
                            (showModal)="open(expert, this._filteredExperts)"
                            [searchResults]="this.searchResults"
                    >
                    </ct-expert-card>
                </div>

            </div>
        </div>
    </div>
</div>
