<div>
    <h1>Generate ESRI CSV</h1>
    <div class="row">
        <h3><span id="researcher-file">Researchers File:</span></h3>
        <input
            type="file"
            style="text-align: center"
            (change)="updateResearchersFile($event)"
        />
    </div>
    <div class="row">
        <h3><span id="company-file">Companies File:</span></h3>
        <input
            type="file"
            style="text-align: center"
            (change)="updateCompaniesFile($event)"
        />
    </div>
    <div id="message">{{ message }}</div>
    <button
        id="addBtn"
        (click)="upload()"
        [disabled]="btnDisabled()"
        [class.btnEnabled]="!btnDisabled()"
    >
        Upload
    </button>
</div>
