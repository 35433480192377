import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {InnovationProject, Place, RefreshProjectDto} from "../api-responses/InnovationProjects.interface";
import {ExportProject, ShareModalData} from "../api-responses/Dialogs.interface";
import {SharedProject} from "../read-only-project-service";

@Injectable({
    providedIn: 'root',
})
export class NetworkServiceInnovationProjectService {
    private baseAPI = '/api/v1/innovationProjects';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<InnovationProject[]>(this.baseAPI).toPromise();
    }

    getById(projectId: number, fullData: boolean = false) {
        return this.http.get<InnovationProject>(`${this.baseAPI}/${projectId}?fullData=${fullData}`).toPromise();
    }

    exportProject(exportProject: ExportProject) {
        return this.http.post(`${this.baseAPI}/export`, exportProject);
    }

    canExport() {
        return this.http.get(`${this.baseAPI}/canExportProjects`);
    }

    deleteProject(id: number) {
        return this.http.delete(`${this.baseAPI}/${id.toString()}`).toPromise();
    }

    getCts(projectId: number) {
        return this.http.get(`${this.baseAPI}/${projectId}/getClinicalTrials`).toPromise();
    }

    getProjectDescriptors(projectId: number) {
        return this.http.get(`${this.baseAPI}/${projectId}/getDescriptors`).toPromise();
    }

    share(projectId: number, share: ShareModalData) {
        return this.http.post<SharedProject>(
            `${this.baseAPI}/${projectId}/share`, share).toPromise();
    }

    setRefreshUpdate(projectId: number, autoRefresh: boolean, autoRefreshInterval:string) {
        const payload = {
            autoRefresh: autoRefresh,
            autoRefreshInterval: autoRefreshInterval ? autoRefreshInterval : 'MONTH'
        } as RefreshProjectDto;

        return this.http.put(`${this.baseAPI}/${projectId}/setRefreshUpdate`,
            payload).toPromise();
    }

    refresh(projectId: number) {
        return this.http.get(`${this.baseAPI}/${projectId}/refreshProject`).toPromise();
    }


    getPlaces(projectId: number) {
        return this.http.get(`${this.baseAPI}/${projectId}/places`).toPromise();
    }
}
