<div class="general-page-container projects-page">

    <div class="loader-panel" [class.invisible]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="charts-container" *ngIf="cts && project">
        <div class="chart-container">
            <app-geo-chart-container
                    (selectedCountriesChange)="updateByCountry($event)"
                    [selectedCountries]="selectedCountries"
                    [countryDist]="getCountries()"
                    [project]="project"
                    [ratio]="2"
            ></app-geo-chart-container>
        </div>
        <div class="container-sep">
            <span>&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div class="chart-container">
            <app-geo-chart-container
                    [chartTitle]="'Clinical Trial Phases'"
                    [filterButtonText]="'Filter by Phase'"
                    [showingXofYText]="'phases'"
                    [popupTitle]="'Choose Clinical Trial Phases'"
                    (selectedCountriesChange)="updateByPhase($event)"
                    [selectedCountries]="selectedPhases"
                    [countryDist]="getPhases()"
                    [project]="project"
                    [ratio]="2"
            ></app-geo-chart-container>
        </div>
    </div>

    <div *ngIf="cts">
        <table mat-table matSort [dataSource]="dataSource" #sort
               (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

            <ng-container matColumnDef="ctId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Id
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ctId }}
                </td>
            </ng-container>

            <ng-container matColumnDef="title" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Title
                </th>
                <td mat-cell *matCellDef="let element" >
                    <a href="{{ element.url }}" target="_blank" matTooltip="{{element.title}}" [matTooltipPosition]="'right'">
                        {{ getTitle(element.title) }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="studyType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Study Type
                </th>
                <td mat-cell *matCellDef="let element"> {{element.studyType}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Status
                </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <ng-container matColumnDef="phases">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Phases
                </th>
                <td mat-cell *matCellDef="let element"> {{element.phases}} </td>
            </ng-container>

            <ng-container matColumnDef="studyStartDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Start Date
                </th>
                <td mat-cell *matCellDef="let element"> {{getDate(element.studyStartDate)}} </td>
            </ng-container>

            <ng-container matColumnDef="lastUpdatePostedDateNct">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Last Update Posted Date (Nct)
                </th>
                <td mat-cell *matCellDef="let element"> {{getDate(element.lastUpdatePostedDateNct)}} </td>
            </ng-container>

            <ng-container matColumnDef="facilities">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Facilities
                </th>
                <td mat-cell *matCellDef="let element"
                    matTooltip="{{getTextForListAsTooltip(element.facilities)}}" matTooltipClass="allow-cr"
                    [matTooltipPosition]="'left'"> {{getTextForList(element.facilities)}} </td>
            </ng-container>

            <ng-container matColumnDef="countries">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Countries
                </th>
                <td mat-cell *matCellDef="let element"
                    matTooltip="{{getTextForListAsTooltip(element.countries)}}"
                    [matTooltipPosition]="'left'"> {{getTextForList(element.countries)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" #paginator
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>
