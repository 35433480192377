import { Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NewProjectDialogComponent } from './new-project-dialog/new-project-dialog.component';
import { NetworkService } from '../../network.service';
import { InnovationProject } from '../../api-responses/InnovationProjects.interface';
import {ProjectStateService} from "./project-state-service";
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from "@angular/material/table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {MatPaginator} from "@angular/material/paginator";

@Component({
    selector: 'app-projects-page',
    templateUrl: './projects-page.component.html',
    styleUrls: ['./projects-page.component.scss'],
})
export class ProjectsPageComponent implements OnInit {

    loading = false;
    projects: InnovationProject[] = [];
    filteredProjects: InnovationProject[] = [];
    textFilter: string;

    option = 'Recent';

    options: string[] = ['Recent', 'Recent1', 'Recent2'];
    bundleOptions: string[] = [];
    bundleOption: string;

    listView: boolean = false;
    toggleView: boolean = false;

    displayedColumns: string[] = [
        'name',
        'searchCount',
        'patentCount',
        'ctCount',
        'publicationCount',
        'grantCount',
        'startupCount',
        'corporateCount',
        'ctExpertCount',
        'academicExpertCount',
        'industryExpertCount',
        'patentExpertCount',
        'totalExpertCount'
    ];
    dataSource = new MatTableDataSource(this.filteredProjects);

    userProjects: InnovationProject[] = [];
    sharedProjects: InnovationProject[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        public matDialog: MatDialog,
        public networkService: NetworkService,
        public router: Router,
        public projectStateService: ProjectStateService,
        private _liveAnnouncer: LiveAnnouncer
    ) {}

    ngOnInit() {
        this.loading = true;
        // this.openNewProjectDialogWhenUrlHashEqualsNew();
        this.projectStateService.updateOtherPageState(true);

        this.networkService.innovationProjects.getAll().then((projects: InnovationProject[]) => {
            this.projects = this.filteredProjects = projects;
            this.bundleOptions = [...Array.from(new Set(this.projects.filter(x => x.bundle).map(x => x.bundle))), "Other"];
            this.bundleOption = this.bundleOptions[0];
            this.onBundleChange();
        });
    }

    onBundleChange() {
        if (this.bundleOptions && this.bundleOptions.length > 0) {
            if (this.bundleOption === "Other") {
                this.filteredProjects = this.projects.filter(x => !x.bundle && !x.shared);
            }
            else {
                this.filteredProjects = this.projects.filter(x => x.bundle === this.bundleOption);
            }
        }

        if (this.textFilter) {
            this.filteredProjects =
                this.filteredProjects
                    .filter(x => x.name.toLowerCase().indexOf(this.textFilter.toLowerCase()) > -1)
        }

        this._setSortTable()

        this.userProjects = this.filteredProjects.slice(0, 100);
        this.sharedProjects = this.projects.filter(x => x.shared === true);

        this.loading = false;
    }

    // openNewProjectDialogWhenUrlHashEqualsNew() {
    //     this.activatedRoute.fragment
    //         .pipe(
    //             filter((fragment) => fragment === 'new'),
    //             take(1),
    //         )
    //         .toPromise()
    //         .then(() => {
    //             this.openNewProjectDialog();
    //         });
    // }

    openNewProjectDialog() {
        const dialogRef = this.matDialog.open(NewProjectDialogComponent);

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                console.log(`Dialog result: ${result}`);
            });

        // window.location.hash = 'new';
    }

    navigateToProjTargetBlank2($event, id: number) {
        window.open('/app/projects/' + id + '/experts-tool', "_blank");
    }

    navigateToProjTargetBlank($event, id: number) {
        $event.nutton = 1;
        this.navigateToProj($event, id);
    }

    navigateToProj($event, id: number) {
        switch ($event.button) {
            case 0:
                this.router.navigate(['/app/projects/'+ id], {});
                break;
            case 1:
                window.open('/app/projects/'+ id, "_blank");
                break;
        }
    }

    // sortData(sort: Sort) {
    //     const data = this.filteredProjects.slice();
    //     if (!sort.active || sort.direction === '') {
    //         this.filteredProjects = data;
    //         return;
    //     }
    //
    //     this.filteredProjects = data.sort((a, b) => {
    //         const isAsc = sort.direction === 'asc';
    //         switch (sort.active) {
    //             case 'patentCount':
    //                 return compare(a.patentCount, b.patentCount, isAsc);
    //             case 'websiteCount':
    //                 return compare(a.websiteCount, b.websiteCount, isAsc);
    //             case 'ctCount':
    //                 return compare(a.ctCount, b.ctCount, isAsc);
    //             case 'newsCount':
    //                 return compare(a.newsCount, b.newsCount, isAsc);
    //             case 'publicationCount':
    //                 return compare(a.publicationCount, b.publicationCount, isAsc);
    //             case 'grantCount':
    //                 return compare(a.grantCount, b.grantCount, isAsc);
    //             case 'startupCount':
    //                 return compare(a.startupCount, b.startupCount, isAsc);
    //             case 'corporateCount':
    //                 return compare(a.corporateCount, b.corporateCount, isAsc);
    //             case 'searchCount':
    //                 return compare(a.searchCount, b.searchCount, isAsc);
    //             case 'ctExpertCount':
    //                 return compare(a.ctExpertCount, b.ctExpertCount, isAsc);
    //             case 'academicExpertCount':
    //                 return compare(a.academicExpertCount, b.academicExpertCount, isAsc);
    //             case 'industryExpertCount':
    //                 return compare(a.industryExpertCount, b.industryExpertCount, isAsc);
    //             case 'patentExpertCount':
    //                 return compare(a.patentExpertCount, b.patentExpertCount, isAsc);
    //             case 'totalExpertCount':
    //                 return compare(a.totalExpertCount, b.totalExpertCount, isAsc);
    //             default:
    //                 return 0;
    //         }
    //     });
    //
    //     function compare(a: number | string, b: number | string, isAsc: boolean) {
    //         return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    //     }
    // }

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    _setSortTable() {
        this.dataSource = new MatTableDataSource(this.filteredProjects);
        setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        })
    }

    /** Announce the change in sort state for assistive technology. */
    announceSortChange(sortState: Sort) {
        // This example uses English messages. If your application supports
        // multiple language, you would internationalize these strings.
        // Furthermore, you can customize the message to add additional
        // details about the values being sorted.
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce('Sorting cleared');
        }
    }

    toggleListView($event: boolean) {
        this.toggleView = true;
        this.listView = $event;
        setTimeout(() => {
            if (this.listView) {
                this.onBundleChange();
            }
            setTimeout(() => {
                this.toggleView = false;
            }, 0);
        }, 0)

    }

    filterByText() {
        this.onBundleChange();
    }
}
