import {Component, OnInit} from "@angular/core";
import {DataService} from "../../../data.service";
import {NetworkService} from "../../../network.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {CanvasProject, SharedCanvas} from "../canvas.interface";
import {InnovationProject} from "../../../api-responses/InnovationProjects.interface";

@Component({
    selector: 'shared-canvas-page',
    templateUrl: './shared-canvas-page.component.html',
    styleUrls: ['./shared-canvas-page.component.scss'],
})
export class SharedCanvasPageComponent implements OnInit{

    canvasId: number;
    loading: boolean = true;
    projects: InnovationProject[] = [];
    canvas: SharedCanvas;
    isDev: boolean = false;

    constructor(public dataService: DataService,
                private router: Router,
                private route: ActivatedRoute,
                public networkService: NetworkService,
                public matDialog: MatDialog
    ) {

    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.canvasId = Number.parseInt(params['canvasId']);
            this.route.queryParams.subscribe((x) => {
                this.isDev = x['isDev'] === 'true';
                this._load();
            })
        });
    }

    _load() {
        this.networkService.canvas.getSharedCanvas(this.isDev, this.canvasId).then((sharedCanvas: SharedCanvas) => {
            this.canvas = sharedCanvas;
            for (const projectId of sharedCanvas.projectIds) {
                    this.networkService.canvas.getSharedCanvasProject(this.dataService.isDev(), this.canvasId, projectId).then((res: InnovationProject) => {
                        this.projects.push(res)
                    })
            }
        })
    }

}
