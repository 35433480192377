import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trending-page',
    templateUrl: './trending-page.component.html',
    styleUrls: ['./trending-page.component.scss'],
})
export class TrendingPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
