<div
        *ngFor="let expert of filterExperts()"
        class="expert"
        [style.height]="patentsPresent ? '376px' : '350px'"
        [class.selectedCard]="selectedExperts.includes(expert)"
        (click)="expertCardClicked($event, expert)"
>
    <div
            *ngIf="selectedExperts.includes(expert)"
            class="check"
            style="
                            background-image: url('assets/images/v-symbol.png');
                            display: block;
                        "
    ></div>
    <div
            *ngIf="!selectedExperts.includes(expert)"
            class="check"
            style="
                            background-image: url('assets/images/v-symbol-hover.png');
                            background-size: 90%;
                        "
    ></div>

    <div
            style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        "
    >
        <div style="width: 100%">
            <div
                    class="name ellipsis"
                    title=""
                    [style.color]="
                                    expert.company
                                        ? '#228B22'
                                        : expert.ctInvestigator
                                        ? '#008ded'
                                        : '#e85a4e'
                                "
                    (click)="expertClicked(expert)"
                    (ngChange)="checkWidth($event, expert.lastName)"
                    (mouseover)="
                                    checkWidth($event, expert.lastName)
                                "
            >
                {{ expert.lastName }}
            </div>

            <div
                    class="institute ellipsis"
                    title=""
                    (ngChange)="
                                    checkWidth($event, expert.department)
                                "
                    (mouseover)="
                                    checkWidth($event, expert.department)
                                "
            >
                <a href="{{ getGoogleSearchUrl(expert.department) }}" target="_blank">{{expert.department}}</a>
            </div>
        </div>
    </div>

    <div class="category">
        <span class="title">Evidence</span>
        <div class="bar" style="border: solid 1px #e85a4e">
            <div
                    [style.background]="'#e85a4e'"
                    [style.width]="
                                    normalize(expert.expertScore, 100, 130) +
                                    2 +
                                    'px'
                                "
                    [class.fill]="
                                    normalize(expert.expertScore, 100, 130) >
                                    129
                                "
                    [class.empty]="
                                    normalize(expert.expertScore, 100, 130) ===
                                    0
                                "
            ></div>
        </div>
    </div>

    <div class="category">
        <span class="title">Clinical Trials</span>
        <div class="bar" style="border: solid 1px #b9646e">
            <div
                    [style.background]="'#b9646e'"
                    [style.width]="
                                    normalize(
                                        expert.clinicalTrialScore,
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                    [class.fill]="
                                    normalize(
                                        expert.clinicalTrialScore,
                                        100,
                                        130
                                    ) > 129
                                "
                    [class.empty]="
                                    normalize(
                                        expert.clinicalTrialScore,
                                        100,
                                        130
                                    ) === 0
                                "
            ></div>
        </div>
    </div>

    <div class="category">
        <span class="title">Publications</span>
        <div class="bar" style="border: solid 1px #74749d">
            <div
                    [style.background]="'#74749d'"
                    [style.width]="
                                    normalize(
                                        expert.publicationScore,
                                        100,
                                        130
                                    ) +
                                    2 +
                                    'px'
                                "
                    [class.fill]="
                                    normalize(
                                        expert.publicationScore,
                                        100,
                                        130
                                    ) > 129
                                "
                    [class.empty]="
                                    normalize(
                                        expert.publicationScore,
                                        100,
                                        130
                                    ) === 0
                                "
            ></div>
        </div>
    </div>

    <div class="category" *ngIf="patentsPresent">
        <span class="title">Patents</span>
        <div class="bar" style="border: solid 1px #2e83cd">
            <div
                    [style.background]="'#2e83cd'"
                    [style.width]="
                                    normalize(expert.patentsScore, 100, 130) +
                                    2 +
                                    'px'
                                "
                    [class.fill]="
                                    normalize(expert.patentsScore, 100, 130) >
                                    129
                                "
                    [class.empty]="
                                    normalize(expert.patentsScore, 100, 130) ===
                                    0
                                "
            ></div>
        </div>
    </div>

    <div class="line"></div>

    <div class="quote_container" *ngIf="expert.quote">
        <img
                src="assets/images/innovation-icon.png"
                class="innovation_icon"
        />
        <div class="quote">
            {{ trimString(expert.quote, 90) }}
        </div>
    </div>

    <div class="email_container" *ngIf="expert.email">
        <img
                src="assets/images/envelope-icon-dark.png"
                class="envelope_icon"
        />
        <a
                target="_blank"
                href="mailto:{{ expert.email }}"
                class="email ellipsis"
        >{{ expert.email }}</a
        >
    </div>

    <button
            class="read_more_button"
            [class.selectedCardButton]="
                            selectedExperts.includes(expert)
                        "
            (click)="expertClicked(expert)"
    >
        View More
    </button>
</div>
