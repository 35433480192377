<div class="general-page-container projects-page">
  <div *ngIf="project" [hidden]="true">
    <mat-form-field
            class="select-search"
            appearance="fill"
    >
      <mat-select [(ngModel)]="selectedSearchTitle">
        <mat-option
                *ngFor="let searchTitle of searchTitles"
                [value]="searchTitle"
                style="text-transform: capitalize"
        >{{ searchTitle }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="loader-panel" [class.invisible]="!loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="charts-container" *ngIf="evidence && project">
    <div class="chart-container">
        <app-geo-chart-container
                (selectedCountriesChange)="updateByCountry($event)"
                [selectedCountries]="selectedCountries"
                [countryDist]="getCountries()"
                [project]="project"
        ></app-geo-chart-container>
    </div>
    <div class="container-sep">
      <span>&nbsp;&nbsp;&nbsp;</span>
    </div>
    <div class="chart-container">
      <experts-background-chart-container
        (selectedExpertsBackgroundChange)="updateByBackground($event)"
        [selectedExpertsBackground]="selectedExpertBackgrounds"
        [project]="project"
      >
      </experts-background-chart-container>
    </div>
  </div>

  <div *ngIf="evidence">
    <evidence-chunk
            [preview]="false"
            [title]="'Experts'"
            [researchers]="selectedResults"
            [evidenceColor]="getColor()"
            [project]="project"
    >
    </evidence-chunk>
  </div>
</div>
