import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {DataService} from "../../../../data.service";

@Component({
    selector: 'basic-search-tool',
    templateUrl: './basic-search-tool.component.html',
    styleUrls: ['./basic-search-tool.component.scss'],
})
export class BasicSearchToolComponent implements OnInit {

    query: string = "";
    @Input() searchResults: SearchQueryResults;

    selectedOption: string = 'CORPORATES';
    @Input() searchOptions: string[];
    @Output() searchSubmit = new EventEmitter();

    formGroup = this.formBuilder.group({
        query: [''],
    });

    @Input() placeholder: string = "";
    @Input() showDropDown: boolean = true;
    @Input() multiline: boolean = true;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        public dataService: DataService
    ) {
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // const query = params.get('query');
            // const searchType = params.get('searchType');
            // if (query) {
            //     this.query = query;
            //     this.selectedOption = searchType || 'World Wide';
            //     // this.search({
            //     //     data: {
            //     //         query,
            //     //         searchType,
            //     //         scope: 'world',
            //     //         academicField: 'ALL',
            //     //         descriptorParentIds: '',
            //     //         projectId: this.projectId
            //     //     }
            //     // })
            // }
        })
    }

    ngOnDestroy() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchResults && !changes.searchResults.firstChange) {
            this.searchResults = changes.searchResults.currentValue;
            this.query = this.searchResults.query;
            this.selectedOption = this.searchResults.searchType;
        }
        // else {
        //     this.query = "";
        //     this.selectedOption = 'CORPORATES';
        // }
    }

    onSubmit() {
        this.searchSubmit.emit({
            action: "search",
            data: {
                query: this.query,
                searchType: this.selectedOption,
                scope: "world",
                academicField: 'ALL'
            }
        })
    }

    changeQueryVal($event: Event) {
        this.query = $event.srcElement['value'];
    }

    getWordCloud() {
        if (this.searchResults && this.searchResults.institutionSearchResult) {
            return this.searchResults.institutionSearchResult.wordCloud;
        }
        else {
            return {};
        }
    }

}
