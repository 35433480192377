import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-research-timeline-page',
    templateUrl: './research-timeline-page.component.html',
    styleUrls: ['./research-timeline-page.component.scss'],
})
export class ResearchTimelinePageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
