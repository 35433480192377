<div class="dialog">
    <div class="header">
        <div
            class="close-button close-icon"
            (click)="closeNewProjectModal()"
        ></div>
        <div class="header-title">Create a new project</div>
    </div>
    <div class="info">
        <form [formGroup]="formGroup" class="new-project-input">
            <div class="dialog-item">
                <div class="title name">Your project's name</div>
                <input
                    type="text"
                    class="input name"
                    placeholder="Minmum 5 characters"
                    [formControl]="formGroup.controls.title"
                    (keyup)="onTitleChange()"
                />
            </div>
            <div class="dialog-item">
                <div class="title description">Add a description</div>
                <textarea
                    minRows={3}
                    class="input description"
                    placeholder="Your project’s description"
                    [formControl]="formGroup.controls.description"
                ></textarea>
            </div>
        </form>
        <div [ngClass]="{ 'create-mode': true, 'edit-mode': isEditMode }">
            <div class="dialog-fixed-item">
                <div class="subtitle owner">Owner</div>
                <div
                    class="dialog-info owner"
                    *ngIf="this.dataService.userData"
                >
                    {{
	                this.dataService.userData.firstName[0].toUpperCase() +
	                this.dataService.userData.firstName.slice(1) +
                            ' ' +
	                this.dataService.userData.lastName[0].toUpperCase() +
	                this.dataService.userData.lastName.slice(1)
                    }}
                </div>
            </div>
            <div class="dialog-fixed-item">
                <div class="subtitle Created">Created</div>
                <div class="dialog-info Created">May 13, 2021</div>
            </div>
            <div class="dialog-fixed-item">
                <div class="subtitle modified">Last modified</div>
                <div class="dialog-info modified">Today</div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <button class="btn btn-dark border text-light" (click)="closeNewProjectModal()">
                Cancel
            </button>
            <button class="btn btn-primary" (click)="createProject()" [disabled]="!canCreate">
                Save and proceed
            </button>
        </div>
    </div>
</div>
