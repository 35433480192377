import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {InnovationProject} from "../../../../../api-responses/InnovationProjects.interface";
import {Chart} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {DataService} from "../../../../../data.service";
import {ExpertsColors} from "../../../../../utils/colors.enum";

@Component({
    selector: 'experts-background-chart',
    templateUrl: './experts-background-chart.component.html',
    styleUrls: ['./experts-background-chart.component.scss']
})
export class ExpertsBackgroundChartComponent implements OnInit, AfterViewInit  {

    @Input() ratio = 3;

    @Input() project: InnovationProject;
    @ViewChild('canvas') canvasElementRef: ElementRef<HTMLCanvasElement>;

    chart: Chart;
    chartOptions: Chart.ChartConfiguration = {
        type: 'horizontalBar',
        plugins: [ChartDataLabels],
        data: {
            datasets: null,
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        fontSize: 16,
                        fontColor: 'white'
                    }
                }]
            },
            layout: {
                padding: {
                    right: 35
                }
            },
            // aspectRatio: 2,
            // maintainAspectRatio: true,
            plugins: {
                // Change options for ALL labels of THIS CHART
                datalabels: {
                    color: '#dff5ff',
                    anchor: 'end',
                    align: 'end',
                    display: 'auto',
                    offset: 0
                }
            },
            legend: {
                display: false
            }
        }
    };

    constructor(private ngZone: NgZone, public dataService: DataService) {
        Chart.plugins.register(ChartDataLabels)
    }



    ngOnInit() {
        // TODO: add "business founders"
        this.chartOptions.data.labels = [
            "Total Experts",
            "With Clinical Trials",
            "Inventors (patents)",
            "Industry Collaborators",
            "Purely Academic"
        ];

        this.chartOptions.data.datasets = [{
            label: 'Experts',
            data: [
                this.project.totalExpertCount,
                this.project.ctExpertCount,
                this.project.patentExpertCount,
                this.project.industryExpertCount,
                this.project.academicExpertCount
            ],
            backgroundColor: [
                ExpertsColors.TILE_SEARCH_EXPERTS,
                ExpertsColors.PATENT_EXPERTS,
                ExpertsColors.CT_EXPERTS,
                ExpertsColors.INDUSTRY_EXPERTS,
                ExpertsColors.ACADEMIC_EXPERTS,
            ]
        }]
    }

    ngAfterViewInit(): void {
        this.chart = this.ngZone.runOutsideAngular(() => {
            const context =
                this.canvasElementRef.nativeElement.getContext('2d');
            return new Chart(context, this.chartOptions);
        });

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);

    }
}
