import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectPageComponent } from './project-page/project-page.component';
import { ProjectsPageComponent } from './projects-page.component';
import { SearchPageComponent } from '../search-page/search-page.component';
import {ExpertsToolComponent} from "./tools/experts-tool/experts-tool.component";
import {CtToolComponent} from "./tools/ct-tool/ct-tool.component";
import { ProjectComparePageComponent } from './project-compare-page/project-compare-page.component';

const routes: Routes = [
            {
                path: '',
                component: ProjectsPageComponent,
            },
            {
                path: ':projectId/read-only-project',
                component: ProjectPageComponent,
            },
            {
                path: ':projectId',
                component: ProjectPageComponent,
            },
            {
                path: ':projectId/compare-to/:compareId',
                component: ProjectComparePageComponent,
            }
            ,
            {
                path: ':projectId/search',
                component: SearchPageComponent,
            },
            {
                path: ':projectId/search/:searchId',
                component: SearchPageComponent,
            },
            {
                path: ':projectId/experts-tool',
                component: ExpertsToolComponent,
            },
            {
                path: ':projectId/ct-tool',
                component: CtToolComponent,
            }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProjectsRoutingModule {}
