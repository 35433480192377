import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-investor-management',
    templateUrl: './investor-management.component.html',
    styleUrls: ['./investor-management.component.scss'],
})
export class InvestorManagementComponent implements OnInit {
    selectedInvestor: any;

    constructor(private networkService: NetworkService) {}

    queryName: string;
    message;
    investors;
    showInvestor = false;
    investorId;

    ngOnInit() {}

    onSearchSubmit() {
        this.message = null;
        this.showInvestor = false;
        this.investors = [];

        if (this.isEmptyString(this.queryName)) {
            alert('Enter investor name to search.');
            return;
        }

        this.networkService.getInvestorsAdmin(this.queryName).subscribe(
            (data) => {
                if (!data) {
                    this.message = 'No investors found.';
                } else {
                    this.investors = data;
                }
            },
            (error) => (this.message = error.error.toString()),
        );
    }

    createInvestor() {
        if (this.isEmptyString(this.queryName)) {
            alert('Enter investor name to search.');
            return;
        }

        this.message = null;
        this.networkService.createInvestor(this.queryName).subscribe(
            (data) => this.showInvestorDetails(data),
            (error) => (this.message = error.error.toString()),
        );
    }

    showInvestorDetails(investor) {
        this.message = null;
        this.showInvestor = false;
        this.selectedInvestor = investor;
        setTimeout(() => (this.showInvestor = true), 1);
    }

    isEmptyString(str) {
        return (
            str === undefined ||
            str === null ||
            this.queryName.trim().length === 0
        );
    }
}
