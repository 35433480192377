<div class="myContainer">
    <div
        style="
            display: flex;
            flex-direction: column;
            width: 500px;
            margin-left: 20px;
        "
    >
        <h3>Enter Dashboard Name:</h3>
        <form style="margin-bottom: 20px">
            <div class="form-group">
                <input
                    type="text"
                    [(ngModel)]="query"
                    name="email"
                    class="form-control"
                    rows="1"
                    id="dashboardNameQuery"
                    placeholder="Dashboard Name"
                />
                <button
                    [disabled]="!query || query.length == 0"
                    class="btn btn-primary"
                    (click)="onSearchSubmit()"
                >
                    Search
                </button>
                <button
                    *ngIf="noResults"
                    [disabled]="!query || query.length == 0"
                    class="btn btn-secondary"
                    (click)="onCrate()"
                >
                    Create New Dashboard
                </button>
            </div>
            <h3>{{ message }}</h3>
        </form>
    </div>

    <div style="margin-bottom: 10px; margin-left: 20px; font-size: larger">
        <a
            target="_blank"
            href="https://treebute.io/dashboard/demo-chickpeas"
            style=""
            >Example: Pepsico (Chickpeas) Dashboard</a
        >
    </div>

    <div style="margin-bottom: 10px; margin-left: 20px; font-size: larger">
        <h4>How To Create A New Dashboard (Tutorial)</h4>
        <video width="400" controls>
            <source
                src="https://s3-eu-west-1.amazonaws.com/treebute.io/dashboard/dynamic-dashboard-tutorial.webm"
                type="video/webm"
            />
            Your browser does not support HTML video.
        </video>
        <p></p>
        <h5>List of dynamic dashboards</h5>
        <div>
            <ul class="">
                <li *ngFor="let dash of dynamicDashboards">
                    <a
                        href="https://treebute.io/dashboard/{{ dash }}"
                        target="_blank"
                        >{{ dash }}</a
                    >
                </li>
            </ul>
        </div>
        <p></p>
        <h5>List of static dashboards</h5>
        <div>
            <ul class="">
                <li *ngFor="let dash of staticDashboards">
                    <a
                        href="https://treebute.io/dashboard/{{ dash }}"
                        target="_blank"
                        >{{ dash }}</a
                    >
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="dashboardFound" style="margin-left: 20px">
        <div class="col-8">
            <label for="name">Name</label>
            <div style="display: flex">
                <input
                    readonly
                    type="text"
                    class="form-control"
                    id="name"
                    value="{{ name }}"
                    [(ngModel)]="name"
                    style="width: 500px"
                />
                <!--      <button [hidden]="true" type="button" (click)='updateField("name", name, false)'-->
                <!--      class="btn btn-primary btn-sm">Update</button>-->
            </div>
        </div>
        <div class="col-8">
            <label for="dashboardId">Dashboard Id</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="dashboardId"
                    value="{{ dashboardId }}"
                    [(ngModel)]="dashboardId"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('dashboardId', dashboardId, false)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div class="col-8">
            <label for="title">Title</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="title"
                    [(ngModel)]="title"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('title', title, false)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div class="col-8">
            <label for="passwordProtected"
                >Password Protected (accessible only through /co-pilot/)</label
            >
            <div style="display: flex">
                <input
                    type="checkbox"
                    class="form-control"
                    id="passwordProtected"
                    [(ngModel)]="passwordProtected"
                    style="width: 25px; height: 25px; cursor: default"
                />
                <button
                    type="button"
                    (click)="
                        updateField(
                            'passwordProtected',
                            passwordProtected,
                            passwordProtected
                        )
                    "
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div class="col-8">
            <label for="itemId">Item Id</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="itemId"
                    [(ngModel)]="itemId"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('itemId', itemId, false)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>
        <div class="col-8">
            <label for="expertsQuery">Experts Query</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="expertsQuery"
                    [(ngModel)]="expertsQuery"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('expertsQuery', expertsQuery, false)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <h4>Experts Scope</h4>
            <select #expertsScopeSelect>
                <option></option>
                <option
                    *ngFor="let scope of scopes"
                    [selected]="scope == expertsScope"
                >
                    {{ scope }}
                </option>
            </select>
            <button
                type="button"
                (click)="
                    updateField('expertsScope', expertsScopeSelect.value, false)
                "
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>

        <div class="col-8">
            <label for="startupsQuery">Startups Query</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="startupsQuery"
                    [(ngModel)]="startupsQuery"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('startupsQuery', startupsQuery, false)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <label for="corporatesQuery">Corporates Query</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="corporatesQuery"
                    [(ngModel)]="corporatesQuery"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="
                        updateField('corporatesQuery', corporatesQuery, false)
                    "
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <label for="clinicalTrialsQuery">Clinical Trials Query</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="clinicalTrialsQuery"
                    [(ngModel)]="clinicalTrialsQuery"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="
                        updateField(
                            'clinicalTrialsQuery',
                            clinicalTrialsQuery,
                            false
                        )
                    "
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <label for="itemFilename">Item File Name</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="itemFilename"
                    [(ngModel)]="itemFilename"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('itemFilename', itemFilename, false)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <label for="exportFilename">Export File Name</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="exportFilename"
                    [(ngModel)]="exportFilename"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="
                        updateField('exportFilename', exportFilename, false)
                    "
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <div *ngIf="exportFilename" style="margin-left: 15px">
                <label for="exportFileHtmlContent" style="display: block"
                    >Export File: Download Button Html Content</label
                >
                <textarea
                    id="exportFileHtmlContent"
                    disabled
                    rows="4"
                    cols="70"
                >
        {{ getDownloadButtonHtmlContent() }}
        </textarea
                >
            </div>
        </div>

        <div class="col-8">
            <label for="interval"
                >Dashboard Update Interval (waiting period until next dashboard
                update)</label
            >
            <div style="display: flex">
                <input
                    type="number"
                    class="form-control"
                    id="interval"
                    [(ngModel)]="interval"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('interval', interval, false)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <h4>Restrict update to day of week</h4>
            <select #processDaySelect>
                <option></option>
                <option
                    *ngFor="let day of days"
                    [selected]="day === processDay"
                >
                    {{ day }}
                </option>
            </select>
            <button
                type="button"
                (click)="
                    updateField('processDay', processDaySelect.value, false)
                "
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>

        <div class="col-8">
            <label for="sendLandscapeAlerts"
                >Send Landscape Alerts Automatically</label
            >
            <div style="display: flex">
                <input
                    type="checkbox"
                    class="form-control"
                    id="sendLandscapeAlerts"
                    [(ngModel)]="sendLandscapeAlerts"
                    style="width: 25px; height: 25px; cursor: default"
                />
                <button
                    type="button"
                    (click)="
                        updateField(
                            'sendLandscapeAlerts',
                            sendLandscapeAlerts,
                            sendLandscapeAlerts
                        )
                    "
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <label for="landscapeAlertInterval"
                >Landscape Alert Interval (in days)</label
            >
            <div style="display: flex">
                <input
                    type="number"
                    class="form-control"
                    id="landscapeAlertInterval"
                    [(ngModel)]="landscapeAlertInterval"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="
                        updateField(
                            'landscapeAlertInterval',
                            landscapeAlertInterval,
                            false
                        )
                    "
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
        </div>

        <div class="col-8">
            <label for="landscapeAlertEmails" style="display: block"
                >Landscape Emails (separate each email with ';' and new-line,
                each line is a mailing group)</label
            >
            <textarea
                id="landscapeAlertEmails"
                rows="4"
                cols="70"
                [(ngModel)]="landscapeAlertEmails"
                placeholder="email1;email2;..."
            ></textarea>
            <button
                type="button"
                (click)="
                    updateField(
                        'landscapeAlertEmails',
                        landscapeAlertEmails,
                        false
                    )
                "
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>

        <hr />
        <!-- {{ updateMessage }} -->
        <h3 style="" *ngIf="updateMessage" [innerHTML]="updateMessage"></h3>
        <button
            style="margin-top: 5px; display: block"
            class="btn btn-warning"
            (click)="updateDashboard()"
        >
            Full Refresh Dashboard Data On Esri (use google for locations)
        </button>
        <button
            style="margin-top: 5px; display: block"
            class="btn btn-warning"
            (click)="testDashboard()"
        >
            Test Dashboard Data On Esri (don't use google for locations)
        </button>
        <button
            style="margin-top: 5px; display: block"
            class="btn btn-warning"
            (click)="runInnovationLandscapeAlert()"
        >
            Run Landscape Innovation Alert
        </button>
        <button
            style="margin-top: 5px; display: block"
            class="btn btn-danger"
            (click)="onDelete()"
        >
            Delete Dashboard
        </button>
    </div>
</div>
