import {InnovationProject} from "../api-responses/InnovationProjects.interface";
import {SearchResult} from "../api-responses/SearchResult.interface";
import {SearchQueryResults} from "../api-responses/SearchQueryResults.interface";


export class TreebuteProjectSummaryReducer {
    static getEntitiesSummary(project: InnovationProject) {
        return {
            academicExpertsCount: project.academicExpertCount,
            ctExpertsCount: project.ctExpertCount,
            industryExpertsCount: project.industryExpertCount,
            patentExpertCount: project.patentExpertCount,
            totalExpertCount: project.totalExpertCount,
            corporatesCount:
            project.treebuteSearchResults.filter(
                (search) =>
                    ['CORPORATES'].includes(
                        search.searchType,
                    ),
            ).map(x => x.count).reduce((a,b) => a+b, 0),
            startupsCount:
            project.treebuteSearchResults.filter(
                (search) =>
                    ['STARTUPS'].includes(
                        search.searchType,
                    ),
            ).map(x => x.count).reduce((a,b) => a+b, 0),
            savedSearchCount:
            project.treebuteSearchResults.length,
        }
    }

    static getEvidence(project: InnovationProject, getAll: boolean, industry: boolean) {
        return project.treebuteSearchResults.reduce(
            (acc: SearchResult, val: SearchResult) => {

                function shouldPass(searchResult: SearchResult, industry: boolean) {
                    return (TreebuteProjectSummaryReducer.isIndustry(searchResult) && industry) ||
                        (TreebuteProjectSummaryReducer.isExperts(searchResult) && !industry);
                }

                return {
                    patentCount:
                        acc.patentCount +
                        (getAll ? val.patentCount : shouldPass(val, industry) ? val.patentCount : 0),
                    websiteCount:
                        acc.websiteCount +
                        (getAll ? val.websiteCount : shouldPass(val, industry) ? val.websiteCount : 0),
                    publicationCount:
                        acc.publicationCount  +
                        (getAll ? val.publicationCount : shouldPass(val, industry) ? val.publicationCount : 0),
                    clinicalTrialCount:
                        acc.clinicalTrialCount  +
                        (getAll ? val.clinicalTrialCount : shouldPass(val, industry) ? val.clinicalTrialCount : 0),
                    grantCount:
                        acc.grantCount  +
                        (getAll ? val.grantCount : shouldPass(val, industry) ? val.grantCount : 0)
                }
            },
            {
                patentCount: 0,
                websiteCount: 0,
                publicationCount: 0,
                clinicalTrialCount: 0,
                grantCount: 0,
            }
        )
    }

    static isIndustry(searchResults: SearchResult | SearchQueryResults) {
        return ['CORPORATES', 'STARTUPS'].some(el => searchResults && searchResults.searchType.includes(el))
    }

    static isStartups(searchResults: SearchResult | SearchQueryResults) {
        return ['STARTUPS'].some(el => searchResults && searchResults.searchType.includes(el))
    }

    static isCorporates(searchResults: SearchResult | SearchQueryResults) {
        return ['CORPORATES'].some(el => searchResults && searchResults.searchType.includes(el))
    }

    static isAcademicExperts(searchResult: SearchResult | SearchQueryResults) {
        return ['ACADEMIC_EXPERTS'].some(el => searchResult && searchResult.searchType.includes(el))
    }

    static isExperts(searchResult: SearchResult | SearchQueryResults) {
        return ['ACADEMIC_EXPERTS', 'INDUSTRY_EXPERTS', 'CT_EXPERTS'].some(el => searchResult && searchResult.searchType.includes(el))
    }

    static isIndustryExperts(searchResult: SearchResult | SearchQueryResults) {
        return ['INDUSTRY_EXPERTS'].some(el => searchResult && searchResult.searchType.includes(el))
    }

    static isCtExperts(searchResult: SearchResult | SearchQueryResults) {
        return ['CT_EXPERTS'].some(el => searchResult && searchResult.searchType.includes(el))
    }

    static isTileSearch(searchResult: SearchResult | SearchQueryResults) {
        return ['TILE_SEARCH_EXPERTS'].some(el => searchResult && searchResult.searchType.includes(el))
    }


    static isExpertsSearchValid(searchResults: SearchQueryResults) {
        return searchResults &&
            searchResults.expertSearchResult &&
            searchResults.expertSearchResult.researchers &&
            searchResults.expertSearchResult.researchers.length > 0;
    }

    static isIndustrySearchValid(searchResults: SearchQueryResults) {
        return searchResults &&
            searchResults.institutionSearchResult &&
            searchResults.institutionSearchResult.institutions &&
            searchResults.institutionSearchResult.institutions.length > 0;
    }
}
