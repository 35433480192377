import {Inject, Injectable, OnInit} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CountriesDataService{

    public data: Object[];
    public static countries: string[] = [];

    constructor(private httpClient: HttpClient){}

    ngOnInit() {
        if (CountriesDataService.countries.length > 0) return;

        return this.httpClient.get("assets/data/countries.json").subscribe(data => {
            CountriesDataService.countries = data['map'](x => x.name['official']);
            return new Promise(() => { return CountriesDataService.countries});;
        });
    }

    ngOnDestroy() {

    }

    getCountries() : Observable<any> {
        if (CountriesDataService.countries.length > 0) {
            return new Observable((observer) => {
                observer.next(CountriesDataService.countries);
            });
        }

        return this.httpClient
            .get("assets/data/countries.json")
            .pipe(map((x) => {
                let countries: string[] =  Object.values<any>(x).map(y => y['name']['common'])
                countries.sort();
                return countries;
            }));
    }

}
