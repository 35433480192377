import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../../../utilities.service';
import { NetworkService } from '../../../network.service';
import {DataService, NavRequest} from '../../../data.service';
import {Researcher} from "../../../api-responses/SearchQueryResults.interface";
import {
    AddExpertToCalloutModalComponent
} from "../../../pages/search-page/dialogs/callout/add-expert-to-callout-modal.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-expert-card',
    templateUrl: './expert-card.component.html',
    styleUrls: ['./expert-card.component.scss'],
})
export class ExpertCardComponent implements OnInit {
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() department: string;
    @Input() relevancy: number;
    @Input() impact: number;
    @Input() innovation: number;
    @Input() quote: string;
    @Input() email: string;
    @Input() expertDetailsLoading: boolean;
    @Input() expert: Researcher;
    @Input() globalSearch;
    @Input() innovationPresent;
    @Input() opacity;
    @Input() cardIndex: number;

    @Output() showModal = new EventEmitter();
    @Output() directRequest = new EventEmitter();
    @Output() addExpertToSelectedArr = new EventEmitter();
    @Output() removeExpertFromSelectedArr = new EventEmitter();

    name;
    nameMaxLength;
    selected = false;

    constructor(public dataService: DataService,
                public matDialog: MatDialog,
                public dialogRef: MatDialogRef<AddExpertToCalloutModalComponent>
    ) {}

    ngOnInit() {
        this.name = this.lastName + ' ' + this.firstName;
        this.checkBrowser();
    }

    checkBrowser() {
        const browser = UtilitiesService.checkBrowser();
        if (browser === 'Chrome' || browser === 'Edge') {
            this.nameMaxLength = this.expert.logoURL ? 16 : 20;
        } else if (browser === 'Firefox') {
            this.nameMaxLength = this.expert.logoURL ? 14 : 18;
        }
    }

    // expertClicked(event) {
    //     if (
    //         event.target.classList[0] !== 'name' &&
    //         event.target.classList[0] !== 'email' &&
    //         event.target.classList[0] !== 'read_more_button'
    //     ) {
    //         this.selected = !this.selected;
    //     }
    //
    //     if (this.selected) {
    //         this.addExpertToSelectedArr.emit(this.expert);
    //     } else {
    //         this.removeExpertFromSelectedArr.emit(this.expert);
    //     }
    // }

    isLimited() {
        return UtilitiesService.isLimited(this.dataService.userData);
    }

    shouldSave() {
        this.dataService.saveSearchUpdate(new NavRequest('', NavRequest.MSG.SHOULD_SAVE));
    }

    addToRequest(expert: Researcher) {
        this.dialogRef = this.matDialog.open(AddExpertToCalloutModalComponent, { data: expert});
    }

    preventDefault($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
    }
}
