import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {DataService} from "../../../data.service";
import {NetworkService} from "../../../network.service";
import {MatDialog} from "@angular/material/dialog";
import {ManifestLine} from "./ManifestLine";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'openAlex',
    templateUrl: 'openAlex.component.html',
    styleUrls: ['openAlex.component.scss']
})
export class OpenAlexComponent implements OnInit, OnDestroy, OnChanges {
    public manifestLines: MatTableDataSource<ManifestLine>;
    public statesFilter: string[];
    public states: string[];
    displayedColumns: string[] = ['id', 'fileNumber', 'openAlexDate', 'openAlexPart', 'state'];
    filterState: string = 'All';
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dataService: DataService,
                public networkService: NetworkService,
                public matDialog: MatDialog) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        // load all manifest lines, sorted by date
        this.networkService.getOpenAlexManifestLines('').then((data: ManifestLine[])=> {
            this._processData(data);
        });

        // load all states to the selection box
        this.networkService.getOpenAlexManifestLineStates().then((data: string[]) => {
            this.statesFilter = data ? ['All', ...data] : [];
            this.states = data ? data : [];
        })
    }

    changeState($event) {
        // get the current manifest line id

        // get the selected state

        // sent the request

        // show loaders

        // hide loader when done, alert about the change

        // refresh the view
    }

    announceSortChange($event: any) {

    }


    updateState(element: ManifestLine, selectedState: HTMLSelectElement) {
        if (confirm("Are you sure you want to reset the state?")) {
            this.networkService.updateOpenAlexManifestLineState(element.id, selectedState.value);
            alert("State updated")
        }
    }

    getByState(state: string) {
        let _s = state == 'All' ? '' : state;
        this.networkService.getOpenAlexManifestLines(_s).then((data: ManifestLine[])=> {
            this._processData(data);
        });
    }

    private _processData(data: ManifestLine[]) {
        if (data) {
            for (const datum of data) {
                // datum = "s3://openalex/data/works/updated_date=2022-02-22/0000_part_00.gz"
                // s1 = "2022-02-22/0000_part_00.gz"
                let s1 = datum.url.replace("s3://openalex/data/works/updated_date=", "");
                datum.openAlexDate = s1.substring(0, s1.indexOf("/"));
                datum.openAlexPart = s1.substring(s1.lastIndexOf("/") + 1)
                    .replace('.gz', '');
            }
        }
        this.manifestLines = new MatTableDataSource<ManifestLine>(data);
        this.manifestLines.paginator = this.paginator;
    }
}
