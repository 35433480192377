<div id="container" style="background-image: url('assets/images/temp.jpg')">
    <div id="layer">
        <div id="search">
            <div
                class="global"
                (click)="
                    classReference.tempGlobal = !classReference.tempGlobal;
                    classReference.tempCountry = 'World Wide';
                    classReference.tempAffiliation = 'All Institutions';
                    globalClicked()
                "
            >
                <img
                    *ngIf="classReference.tempGlobal"
                    src="assets/images/checkbox_selected.png"
                />
                <img
                    *ngIf="!classReference.tempGlobal"
                    src="assets/images/checkbox.png"
                />
                <span> Global Search </span>
            </div>

            <div
                id="countriesDropdown"
                class="dropdown"
                *ngIf="classReference.tempGlobal"
            >
                <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <div
                        class="ellipsis"
                        (ngChange)="
                            checkWidth($event, classReference.tempCountry)
                        "
                        (mouseover)="
                            checkWidth($event, classReference.tempCountry)
                        "
                        title=""
                    >
                        {{ classReference.tempCountry }}
                    </div>
                </button>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <span
                        class="dropdown-item"
                        type="button"
                        (click)="
                            classReference.tempCountry = 'World Wide';
                            classReference.tempAffiliation = 'All Institutions';
                            globalClicked()
                        "
                        id="worldWide"
                    >
                        World Wide
                    </span>
                    <span
                        id="{{ country }}"
                        class="dropdown-item"
                        type="button"
                        *ngFor="let country of countries"
                        (click)="
                            classReference.tempCountry = country;
                            loadInstitutions(country);
                            globalClicked()
                        "
                        (ngChange)="checkWidth($event, country)"
                        (mouseover)="checkWidth($event, country)"
                        title=""
                    >
                        {{ country }}
                    </span>
                </div>
            </div>

            <!--
      <div id="institutionsDropdown" class="dropdown" *ngIf="classReference.tempGlobal && classReference.tempCountry != 'World Wide'">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="institutionDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="ellipsis" (ngChange)="checkWidth($event, classReference.tempAffiliation)" (mouseover)="checkWidth($event, classReference.tempAffiliation)" title="">{{ classReference.tempAffiliation }}</div>
          <div *ngIf="classReference.tempAffiliation !== 'All Institutions'" class="label"> Beta </div>
        </button>

        <div class="dropdown-menu dropdown-menu-institutions" aria-labelledby="institutionDropdownMenuButton">
          <span class="dropdown-item" type="button" (click)="classReference.tempAffiliation = 'All Institutions'; globalClicked();" id="institutionList"> All Institutions </span>
          <div id="betaComment"> Beta </div>
          <span id="{{institution}}" class="dropdown-item" type="button" *ngFor="let institution of institutions" (click)="classReference.tempAffiliation = institution; globalClicked();" (ngChange)="checkWidth($event, institution)" (mouseover)="checkWidth($event, institution)" title=""> {{ institution }} </span>
        </div>
      </div>
  -->
        </div>

        <div class="map">
            <app-campus-map-d3
                #explorer
                type="explorer"
                (nlpStatus)="handleNlp($event)"
                [searchString]="searchString"
                [selectedCountry]="selectedCountry"
                [forceNLP]="forceNLP"
                [selectedAffiliation]="selectedAffiliation"
            ></app-campus-map-d3>
        </div>
    </div>
</div>
