<div class="project-card p-4 m-4" [class.shared-project-card]="project.shared">
    <div (mouseup)="navigateToProj($event, project.id)">
        <div class="project-card-top">
            <div class="project-card-top__icons" *ngIf="!readonly">
                <div class="title-shared" *ngIf="project.shared">
                    <div>Shared Project</div>
                    <div>Author: Naveh D. Shetrit</div>
                </div>
                <div>
                    <span class="px-1" style="color: #aed581" *ngIf="project.autoRefresh">
                        <i class='fas fa-recycle'></i>
                    </span>
                </div>
                <!--            <div-->
                <!--                [ngClass]="{-->
                <!--                    'favorite-icon': true,-->
                <!--                    'favorite-selected-icon': isFavorite-->
                <!--                }"-->
                <!--                (click)="toggleFavorite($event)"-->
                <!--                *ngIf="futureFeature === true"-->
                <!--            ></div>-->
                <!--TODO- delete *ngIf on future release-->
                <div class="optionsMenu" *ngIf="futureFeature === true">
                    <!--TODO- delete *ngIf on future release-->
                    <div
                            [matMenuTriggerFor]="menu"
                    >
                        <mat-icon class="options-icon"></mat-icon>
                    </div>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <mat-icon class="edit-icon"></mat-icon>
                            <span>Edit project info</span>
                        </button>
                        <button mat-menu-item>
                            <mat-icon class="duplicate-icon"></mat-icon>
                            <span>Duplicate</span>
                        </button>
                        <button mat-menu-item>
                            <mat-icon class="new-tab-icon"></mat-icon>
                            <span>Open in new tab</span>
                        </button>
                        <div class="breakline"></div>
                        <button mat-menu-item>
                            <mat-icon class="delete-icon"></mat-icon>
                            <span class="delete-title">Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="project-card-top__title">
                {{ getShortProjectName(project.name) }}
            </div>
        </div>
        <div class="project-card-middle">
            <div *ngFor="let e of evidence" class="menu-item-middle">
                <div class="menu-item-middle-left">
                    <div
                            class="menu-item-middle-left__icon {{ e.icon }}"
                    ></div>
                    <div
                            class="menu-item-middle-left__title"
                            [ngStyle]="{ color: e.color }"
                    >
                        {{ e.title }}
                    </div>
                </div>
                <div
                        class="menu-item-middle__amount evidence_amount_{{e.title.replace(' ', '_').toLowerCase()}}"
                        [ngStyle]="{ 'background-color': e.color }"
                >
                    {{ e.evidenceAmount }}
                </div>
            </div>
        </div>
        <div class="project-card-bottom">
            <div *ngFor="let item of summary" class="menu-item-bottom">
                <div class="menu-item-bottom__amount">{{ item.summaryAmount }}</div>
                <div class="menu-item-bottom__title">{{ item.title }}</div>
            </div>
        </div>
    </div>
</div>
