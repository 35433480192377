<div class="container project-page">
    <div class="total-progress-bar" *ngIf="!loading">
        <mat-spinner mode="indeterminate"></mat-spinner>
    </div>
    <div *ngIf="canvas" class="readonly-canvas">
        <div class="pt-5 pb-3 pl-3" style="display: flex; justify-content: space-between">
            <div>
                <div *ngIf="canvasId < 12">
                <img src="https://beta2.treebute.io/assets/images/CYD_Campus.png" style="height: 50px; margin-top: 15px">
                </div>
                <div style="display: flex; justify-content: right"  *ngIf="canvasId >= 12">
                    <img src="https://beta.treebute.io/assets/images/logo_topic.png" style="height: 70px" >
                </div>
            </div>
            <div *ngIf="canvasId < 12">
                <div style="display: flex; justify-content: right">
                <img src="https://beta.treebute.io/assets/images/logo_topic.png" style="height: 70px" >
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end pr-3" *ngIf="false">
            <button class="btn btn-success" onclick=" window.open('http://eepurl.com/h85Iu1','_blank')">Subscribe</button>
        </div>
        <div class="p-4">
            <markdown [data]="canvas.description"></markdown>
        </div>
        <div *ngFor="let project of canvas.projectIds">
            <canvas-project-preview
                    [canvasId]="canvasId"
                    [readonly]="true"
                    [innovationProjectId]="project">
            </canvas-project-preview>
        </div>
    </div>
</div>
