<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title"> Add Company</div>
        <div class="search-tool-container">
            <div class="line">
                Find by name or domain:
            </div>
            <div>
                <div class="search-tool-input">
                    <input type="text" #findCompany (keydown.enter)="search(findCompany.value)"/>
                    <input value="search"
                           type="button"
                           class="btn-primary"
                           (click)="search(findCompany.value)"/>
                </div>
                <div class="search-tool-selected-items">
                <span *ngFor="let selectedCompany of selectedCompanies" class="selected-company">
                    <span>
                        {{ selectedCompany.name }}
                    </span>
                    <span class="button-remove-item" (click)="removeFromSelected(selectedCompany)">
                        &nbsp;x&nbsp;
                    </span>
                </span>
                </div>
                <div class="loader-panel" *ngIf="loading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div class="result-box">
                    <div *ngFor="let company of companies" (click)="addToSelected($event, checkbox, company.inst, true)"
                         class="result-box-item">
                        <input #checkbox
                               class="company-checkbox"
                               type="checkbox"
                               [checked]="company.selected"
                               [value]="company.inst.id"
                               (click)="addToSelected($event, checkbox, company.inst, false)"
                        />[{{company.inst.institutionType}}] {{company.inst.name}} {{ utilsComponent.getCountryText(company.inst) }}
                    </div>
                </div>
                <span *ngIf="errorImport">Error importing from Url <button (click)="reportImportError()">Report</button></span>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="button-cancel" (click)="closeModal()">
                Cancel
            </div>
            <div class="button-save" (click)="addCompany()">
                Add
            </div>
        </div>
    </div>
</div>
