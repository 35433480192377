<div id="deposit" (click)="outsideOfModalClicked($event)">
    <div class="col-md-6 offset-md-3">
        <h3>Add a leaf</h3>
        <form #deposityForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="Title">Title</label>
                <input
                    type="text"
                    class="form-control"
                    id="title"
                    #title="ngModel"
                    name="title"
                    [(ngModel)]="model.title"
                    required
                />
                <div
                    [hidden]="title.valid || title.pristine"
                    class="alert alert-danger"
                >
                    Title is required
                </div>
            </div>
            <div class="form-group">
                <label for="abstract">Abstract</label>
                <textarea
                    class="form-control"
                    rows="5"
                    (keyup)="textClick($event)"
                    #abs="ngModel"
                    name="abstract"
                    [(ngModel)]="model.synopsis"
                    required
                ></textarea>
                <div
                    [hidden]="abs.valid || abs.pristine"
                    class="alert alert-danger"
                >
                    Abstract is required
                </div>
            </div>

            <div *ngIf="children$">
                <label for="children">Related to</label>
                <select
                    name="children"
                    multiple
                    class="form-control"
                    [(ngModel)]="model.children"
                >
                    <option
                        [ngValue]="child.id"
                        *ngFor="let child of children$.publets"
                    >
                        {{ child.title }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label for="upload">Choose a file</label><br />
                <label name="upload" class="btn btn-primary btn-file"
                    >Choose a file
                    <input
                        type="file"
                        style="display: none"
                        (change)="onFileSelected($event)"
                        multiple
                    />
                </label>
                <li class="list-group-item" *ngFor="let file of selectedFiles">
                    {{ file.name }}
                </li>
            </div>

            <div class="form-group">
                <button
                    class="btn btn-primary"
                    [disabled]="!deposityForm.form.valid"
                >
                    Deposit
                </button>
            </div>
        </form>
    </div>

    <div
        *ngIf="showAutoComplete"
        id="autocomplete-modal"
        class="modal"
        role="dialog"
        style="display: block"
    >
        <div class="modal-dialog" role="document">
            <!-- Modal content-->
            <div class="modal-content" (click)="$event.stopPropagation()">
                <div class="modal-header">
                    <app-publets-auto-complete></app-publets-auto-complete>
                </div>
            </div>
        </div>
    </div>
</div>
