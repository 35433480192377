<div
    style="
        display: flex;
        flex-direction: column;
        width: 500px;
        margin-left: 20px;
    "
>
    <h3>Search for a user:</h3>
    <form (ngSubmit)="onSearchSubmit()" style="margin-bottom: 20px">
        <div class="form-group">
            <input
                type="text"
                [(ngModel)]="query"
                name="email"
                class="form-control"
                rows="1"
                id="emailQuery"
                placeholder="User email"
            />
        </div>
        <button
            [disabled]="!query || query.length == 0"
            class="btn btn-primary"
        >
            Search
        </button>
        <h3>{{ message }}</h3>
    </form>
</div>

<div *ngIf="userFound" class="results">
    <div class="col-8">
        <label for="id">Id</label>
        <input
            disabled
            type="text"
            class="form-control"
            id="id"
            value="{{ id }}"
            style="width: 500px; cursor: default"
        />
    </div>
    <div class="col-8">
        <label for="email">Email</label>
        <input
            disabled
            type="text"
            class="form-control"
            id="email"
            value="{{ email }}"
            style="width: 500px; cursor: default"
        />
    </div>
    <div class="col-8">
        <label for="firstName">First Name</label>
        <div style="display: flex">
            <input
                type="text"
                class="form-control"
                id="firstName"
                [(ngModel)]="firstname"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateField('firstName', firstname, false)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <div class="col-8">
        <label for="lastName">Last Name</label>
        <div style="display: flex">
            <input
                type="text"
                class="form-control"
                id="lastName"
                [(ngModel)]="lastname"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateField('lastName', lastname, false)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <div class="col-8">
        <label for="dashboards">Remaining Dashboards</label>
        <div style="display: flex">
            <input
                type="text"
                class="form-control"
                id="dashboards"
                [(ngModel)]="dashboards"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateField('dashboards', dashboards, false)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <div class="col-8">
        <label for="biomed">Biomed</label>
        <div style="display: flex">
            <input
                disabled
                type="text"
                class="form-control"
                id="biomed"
                [(ngModel)]="biomed"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateField('biomed', !biomed, false)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <div class="col-8">
        <label for="biomed">Global searcher</label>
        <div style="display: flex">
            <input
                disabled
                type="text"
                class="form-control"
                id="globalSearch"
                [(ngModel)]="globalSearch"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateField('global', !globalSearch, false)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>

    <div style="display: flex">
        <form
            #userDetails="ngForm"
            (ngSubmit)="onUserDetailsSubmit()"
            style="width: 500px"
        >
            <div class="form-group">
                <label for="institution">Institution</label>
                <div
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    "
                >
                    <input
                        type="text"
                        class="form-control"
                        id="institution"
                        #name="ngModel"
                        name="institution"
                        [(ngModel)]="institution"
                        required
                    />
                </div>
                <ul
                    class="institutions"
                    [hidden]="!isVisible"
                    (click)="onInstitutionClick($event.target.innerText)"
                >
                    <li *ngFor="let institution of institutionsList">
                        <span>{{ institution }}</span>
                    </li>
                </ul>
            </div>
        </form>
        <button
            type="button"
            (click)="updateField('institution', institution, false)"
            class="btn btn-primary btn-sm"
        >
            Update
        </button>
    </div>

    <div class="col-8">
        <label for="emailValidated">Email Validated</label>
        <input
            disabled
            type="text"
            class="form-control"
            id="emailValidated"
            value="{{ emailValidated }}"
            style="width: 500px; cursor: default"
        />
    </div>
    <div class="col-8">
        <label for="updatePassword">Update Password</label>
        <div style="display: flex">
            <input
                #updatePassword
                type="text"
                class="form-control"
                id="updatePassword"
                style="width: 500px"
                placeholder="Enter new password"
            />
            <button
                type="button"
                (click)="
                    updateField('password', updatePassword.value, false);
                    updatePassword.value = ''
                "
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <div class="col-8">
        <label for="registrationDate">Registration Date</label>
        <input
            disabled
            type="text"
            class="form-control"
            id="registrationDate"
            value="{{ registrationDate | date: 'medium' }}"
            style="width: 500px; cursor: default"
        />
    </div>
    <div class="col-8">
        <label for="limitedUserMaxResultsSize">Limited User Max Results</label>
        <div style="display: flex">
            <input
                type="number"
                class="form-control"
                id="limitedUserMaxResultsSize"
                [(ngModel)]="limitedUserMaxResultsSize"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="
                    updateField(
                        'limitedUserMaxResultsSize',
                        limitedUserMaxResultsSize,
                        false
                    )
                "
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <h4>Roles</h4>
    <div *ngIf="roles">
        <ul class="list-group">
            <li *ngFor="let role of roles">
                <b>{{ role }}</b>
                <button
                    *ngIf="role != 'USER'"
                    type="button"
                    (click)="updateField('role', role, false)"
                    class="btn btn-danger btn-sm"
                >
                    Delete
                </button>
            </li>
        </ul>
    </div>

    <label for="roleDropdownMenuButton">Add Role</label>
    <div class="dropdown">
        <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="roleDropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            {{ selectedRole }} <span class="caret"></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="roleDropdownMenuButton">
            <button
                class="dropdown-item"
                type="button"
                *ngFor="let role of roleTypes"
                (click)="selectedRole = role"
            >
                {{ role }}
            </button>
        </div>
        <button
            [disabled]="!selectedRole"
            type="button"
            (click)="updateField('role', selectedRole, true)"
            class="btn btn-primary btn-sm"
        >
            Add
        </button>
    </div>

    <hr />

    <div>
        <div>
            <form
                class="paymentBox"
                #createPaymentForm="ngForm"
                (ngSubmit)="createPayment()"
            >
                <h2>Create payment</h2>
                <div class="form-group">
                    <label for="name"
                        >Total ($ - use only 2 digits for cents after decimal
                        point)</label
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="name"
                        #name="ngModel"
                        name="name"
                        [(ngModel)]="model.total"
                        min="0"
                        step=".01"
                        required
                    />
                </div>
                <div class="form-group">
                    <h5>Payment Status</h5>
                    <select
                        [(ngModel)]="model.status"
                        name="statusSelect"
                        required
                    >
                        <option *ngFor="let pStatus of paymentStatus">
                            {{ pStatus }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <h5>Payment Reason</h5>
                    <select [(ngModel)]="model.reason" name="reasonSelect">
                        <option *ngFor="let reason of paymentReason">
                            {{ reason }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="payDate2">Payment Date</label>
                    <input
                        type="date"
                        class="form-control"
                        id="payDate2"
                        #payDate2="ngModel"
                        name="name"
                        [(ngModel)]="model.paymentDate"
                        required
                    />
                </div>
                <div class="form-group">
                    <button
                        class="btn btn-primary"
                        [disabled]="!createPaymentForm.form.valid"
                    >
                        Create
                    </button>
                </div>
            </form>
        </div>

        <div *ngFor="let payment of pendingPayments">
            <div class="paymentBox">
                <h3>
                    Pending Payment {{ pendingPayments.indexOf(payment) + 1 }}
                </h3>
                <div class="col-8">
                    <label for="id">ID</label>
                    <div style="display: flex">
                        <input
                            disabled
                            #id
                            type="text"
                            class="form-control"
                            id="paymentId"
                            style="width: 500px"
                            value="{{ payment.id }}"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="paymentTotal"
                        >Total ($ - use only 2 digits for cents after decimal
                        point)</label
                    >
                    <div style="display: flex">
                        <input
                            #paymentTotal
                            type="number"
                            class="form-control"
                            id="paymentTotal"
                            min="0"
                            step=".01"
                            required
                            value="{{ payment.total }}"
                        />
                        <button
                            type="button"
                            (click)="
                                updatePayment(
                                    payment.id,
                                    'total',
                                    paymentTotal.value
                                )
                            "
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                    </div>
                </div>
                <div class="col-8">
                    <h4>Payment Status</h4>
                    <select #statusSelect>
                        <option
                            *ngFor="let payStatus of paymentStatus"
                            [selected]="payStatus == payment.status"
                        >
                            {{ payStatus }}
                        </option>
                    </select>
                    <button
                        type="button"
                        (click)="
                            updatePayment(
                                payment.id,
                                'status',
                                statusSelect.value
                            )
                        "
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>

                <div class="col-8">
                    <h4>Payment Reason</h4>
                    <select #reasonSelect>
                        <option
                            *ngFor="let reason of paymentReason"
                            [selected]="reason == payment.reason"
                        >
                            {{ reason }}
                        </option>
                    </select>
                    <button
                        type="button"
                        (click)="
                            updatePayment(
                                payment.id,
                                'reason',
                                reasonSelect.value
                            )
                        "
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
                <div class="col-8">
                    <label for="paymentDate">Payment Date</label>
                    <div style="display: flex">
                        <input
                            type="text"
                            disabled
                            class="form-control"
                            id="paymentDate"
                            style="width: 500px; cursor: default"
                            value="{{ payment.paymentDate | date: 'fullDate' }}"
                        />
                        <input
                            #paymentDate2
                            type="date"
                            class="form-control"
                            id="paymentDate2"
                            style="width: 500px"
                        />
                        <button
                            type="button"
                            (click)="
                                updatePayment(
                                    payment.id,
                                    'paymentDate',
                                    paymentDate2.value
                                )
                            "
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                    </div>
                </div>
                <div class="col-8">
                    <label for="updateDate">Update Date</label>
                    <div style="display: flex">
                        <input
                            disabled
                            #updateDate
                            type="text"
                            class="form-control"
                            id="updateDate"
                            style="width: 500px"
                            value="{{ payment.updateDate | date: 'medium' }}"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="creationDate">Creation Date</label>
                    <div style="display: flex">
                        <input
                            disabled
                            #creationDate
                            type="text"
                            class="form-control"
                            id="creationDate"
                            style="width: 500px"
                            value="{{ payment.creationDate | date: 'medium' }}"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
