import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {Institution, InstitutionResult} from "../../../../api-responses/SearchQueryResults.interface";
import {UtilitiesService} from "../../../../utilities.service";
import {UtilsComponent} from "../../../../utils/utils.component";

@Component({
    selector: 'founders-list',
    templateUrl: './founders-list.component.html',
    styleUrls: ['./founders-list.component.scss'],
})
export class FoundersListComponent implements OnInit, OnDestroy, OnChanges {

    @Input() selectedCompany: Institution;

    constructor(public utilsComponent: UtilsComponent) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    getComma(founder: any) {
        return this.selectedCompany.foundersData.indexOf(founder) < this.selectedCompany.foundersData.length - 1 ? ", " : "";
    }

}
