import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {Researcher, SearchQueryResults} from "../../../api-responses/SearchQueryResults.interface";
import {UtilitiesService} from "../../../utilities.service";
import {Subscription} from "rxjs";
import {ExpertModalComponent} from "../../../pages/search-page/experts-search/expert-modal/expert-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {NetworkService} from "../../../network.service";
import {DataService} from "../../../data.service";
import {TreebuteProjectSummaryReducer} from "../../../utils/treebuteProjectSummaryReducer";
import {ExpertsModalData} from "../../../api-responses/Dialogs.interface";
import {UtilsComponent} from "../../../utils/utils.component";

@Component({
    selector: 'ct-expert-card',
    templateUrl: './ct-expert-card.component.html',
    styleUrls: ['./ct-expert-card.component.scss'],
})
export class CtExpertCardComponent implements OnInit, OnDestroy, OnChanges {

    @Input() searchResults: SearchQueryResults;

    @Output() showModal = new EventEmitter();

    currentExpert: Researcher;

    interval;
    patentsPresent: boolean;
    piecesOfEvidence = [];
    selectedExperts: Researcher[] = []

    private fromModalToData = Subscription.EMPTY;
    private dialogRef: any;
    private selectedExpert: Researcher;
    index: number;
    expertDetailsLoading: boolean;

    constructor(private networkService: NetworkService,
                public dataService: DataService,
                public matDialog: MatDialog,
                public utilsComponent: UtilsComponent
    ) {
    }

    ngOnDestroy(): void {
        this.fromModalToData.unsubscribe();
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResults) {
            this.searchResults = changes.searchResults.currentValue;
            this.handleCtResponse();
        }
    }

    handleCtResponse() {
        this.checkPatents();
    }

    checkPatents() {
        this.patentsPresent = false;

        if (this.searchResults.expertSearchResult && this.searchResults.expertSearchResult.researchers) {
            this.searchResults.expertSearchResult.researchers.forEach((expert) => {
                if (expert.patentsScore > 0) {
                    this.patentsPresent = true;
                }
            });
        }
    }

    handlePiecesOfEvidenceArr(expert) {
        this.piecesOfEvidence = [];

        for (const clinicalTrial of expert.clinicalTrials) {
            const obj = {
                type: 'CT',
                title: clinicalTrial.valueB,
                url: `https://clinicaltrials.gov/ct2/show/study/${clinicalTrial.valueA}`,
                phase: clinicalTrial.valueC,
            };
            this.piecesOfEvidence.push(obj);
        }

        for (const publication of expert.publicationsWithDates) {
            const obj = {
                type: 'ARTICLE',
                title: publication.valueC,
                url: publication.valueD,
            };
            this.piecesOfEvidence.push(obj);
        }

        for (const patent of expert.patentTitlesToURLs) {
            const obj = {
                type: 'PATENT',
                title: patent.valueA,
                url: patent.valueB,
            };
            this.piecesOfEvidence.push(obj);
        }
    }

    filterExperts() {
        let response = this.searchResults.expertSearchResult.researchers;

        if (this.searchResults.filterCtPhase !== 'All Phases') {
            response = response.filter((expert) =>
                expert.clinicalTrialPhases.includes(this.searchResults.filterCtPhase),
            );
        }
        if (this.searchResults.filterCountry !== 'All Countries') {
            response = response.filter((expert) =>
                expert.countries.includes(this.searchResults.filterCountry),
            );
        }
        if (this.searchResults.filterCtStatus !== 'All Statuses') {
            response = response.filter((expert) =>
                expert.clinicalTrialStatus.includes(this.searchResults.filterCtStatus),
            );
        }

        this.selectedExperts = response;
        return response;
    }


    expertCardClicked($event: any, expert: Researcher) {
        // open dialog
    }

    closeModal() {
        this.dialogRef && this.dialogRef.close();
    }

    open(expert, openDialog) {
        this.expertDetailsLoading = true;
        // const btn = document.getElementById(
        //     `loadingBtn${this.currentExpert.firstName}${this.currentExpert.lastName}`,
        // );
        // if (btn != null) {
        //     btn.innerHTML = '';
        //     btn.classList.add('loadingBtn');
        // }

        this.currentExpert = expert;
        if (!this.currentExpert.expertData) this.currentExpert.expertData = {};

        const _filteredExperts = this.selectedExperts;

        this.networkService
            .getDescriptorsMapping(
                this.currentExpert.firstName,
                this.currentExpert.lastName,
                this.currentExpert.initials,
                this.currentExpert.publications,
                undefined,
                this.currentExpert.email,
                false,
                this.isGlobal(),
                this.currentExpert.department,
                this.currentExpert.allNames,
                this.currentExpert.affiliations,
            )
            .subscribe((data) => {
                const body = document.getElementsByTagName('body')[0];
                // body.style.overflowY = 'hidden';

                // this.handleExpertData(this.currentExpert, data);
                // this.showModal = true;
                this.expertDetailsLoading = false;
                // this.nextLoading = false;
                // this.previousLoading = false;

                // this.getResearcherLinkedIn(this.currentExpert);

                // if (btn != null) {
                //     btn.innerHTML = 'VIEW MORE';
                //     btn.classList.remove('loadingBtn');
                // }

                if (openDialog) {
                    this.dialogRef = this.matDialog.open(ExpertModalComponent, { data: {
                        expert: this.currentExpert,
                        experts: this.filterExperts(),
                        isGlobal: this.utilsComponent.isGlobal(this.searchResults.scope),
                        industrySearch: TreebuteProjectSummaryReducer.isIndustry(this.searchResults),
                        searchResults: this.searchResults,
                        simpleNavigation: true,
                        hideNav: false
                    } as ExpertsModalData});
                }

            });

        // this.checkExpertSwitch();
    }

    private shouldEnableNextExpert(_filteredExperts, expert) {
        return _filteredExperts.indexOf(this.selectedExpert) < _filteredExperts.length - 1;
    }


    isGlobal() {
        return this.searchResults.scope === 'World Wide' || this.searchResults.scope === 'world';
    }

    expertClicked(expert: Researcher) {

    }

    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }

    getGoogleSearchUrl(department: string) {
        return UtilitiesService.getGoogleSearchUrl(department);
    }
}

