import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { NetworkService } from '../../network.service';
import { DataService } from '../../data.service';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../utilities.service';
import { FloatingUpdateService } from '../../floating-update.service';
import {TileBreadCrumb} from "../search-page/tile-search/tile-bread.crumb";

declare var CarrotSearchFoamTree: any;

@Component({
    selector: 'app-campus-map-d3',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './campus-map-d3.component.html',
    styleUrls: ['./campus-map-d3.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(window:resize)': 'onResize()',
    },
})
export class CampusMapD3Component implements OnInit, OnDestroy {
    exportSubscription: Subscription;
    show = false;
    currentId = 'top';
    // callStack = [];
    selectedNameForExperts = 'top';
    selectedScopeForExperts;
    foamtree: any;
    isLoading = false;

    onChild = false;
    trendingState = false;
    onGroupExposureFunction = null;
    onGroupClickFunction = null;
    onRolloutCompleteFunction = null;
    onViewResetFunction = null;
    onGroupMouseWheelFunction = null;
    resizeTimer = null;
    mapLoading = true;
    currentSize = 0;
    searchResultLoading = false;
    showBar = false;
    noResults = false;
    campusTabActive = true;
    exportLoading = false;
    collMapExportEnabled = false;

    @Input() type = 'campus';
    @Input() searchString: any;
    @Input() selectedCountry: string;
    @Input() selectedAffiliation: string;
    @Input() forceNLP: boolean;
    @Output() nlpStatus = new EventEmitter();
    @Output() showTheResearchers = new EventEmitter();

    @Input() tileData: any;
    @Input() callStack: any[];
    @Output() breadCrumbsStackPop = new EventEmitter();
    @Output() breadCrumbsStackPush = new EventEmitter<TileBreadCrumb>();
    @Output() updateMap = new EventEmitter();

    constructor(
        public dataService: DataService,
        private floatingUpdateService: FloatingUpdateService,
        private cdRef: ChangeDetectorRef,
    ) {
        this.exportSubscription = this.floatingUpdateService
            .getNotifiedExportFinished()
            .subscribe(() => this.exportFinished());
    }

    ngOnInit() {
        if (this.type === 'campus') {
            this.currentId = 'top';
            //this.updateMap();
            this.tryToUpdateFoamTree(this.tileData);
        } else {
            this.mapLoading = false;
        }
    }

    ngOnDestroy() {
        if (this.foamtree) {
            this.foamtree.off(
                'groupExposureChanged',
                this.onGroupExposureFunction,
            );
            this.foamtree.off('viewReset', this.onViewResetFunction);
            this.foamtree.off(
                'groupMouseWheel',
                this.onGroupMouseWheelFunction,
            );
            this.foamtree.off('groupClick', this.onGroupClickFunction);

            this.foamtree.dispose();
            this.foamtree = null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("****** campus map d3 ngOnChanges");
        console.log(changes);
        if (changes.searchString) {
            this.searchString = changes.searchString.currentValue;
        }
        if (changes.tileData && changes.tileData.currentValue) {
            this.tileData = changes.tileData.currentValue;
            this.selectedCountry = this.tileData.scope;
            // this.updateMap();
            this.tryToUpdateFoamTree(this.tileData);
        }
        if (changes.callStack !== undefined){
            this.callStack = changes.callStack.currentValue;
            let prevCallStack = changes.callStack.previousValue ? changes.callStack.previousValue : [];

            if (!this.callStack || this.callStack.length === 0) {
                // reset
                this.currentId = 'top';
                // this.updateMap();
                this.tryToUpdateFoamTree(this.tileData);
                if (this.foamtree) {
                    this.foamtree.reset();
                    this.onViewReset();
                }
            }
            else {
                // set to specific node
                let count = 0;
                let toPop = 0;
                let updateMap = true;
                this.callStack.forEach((crumb) => {
                    if (crumb.id === event) {
                        if (count === this.callStack.length - 1) {
                            // Last one was clicked
                            return;
                        } else if (
                            count === this.callStack.length - 2 &&
                            this.onChild
                        ) {
                            this.foamtree.reset();
                            this.onViewReset();
                            updateMap = false;
                            return;
                        } else {
                            toPop = this.callStack.length - 1 - count;
                        }
                    }
                    count++;
                });
                this.currentId = this.callStack[this.callStack.length -1];
                if (updateMap) {
                    // this.updateMap();
                    this.tryToUpdateFoamTree(this.tileData);
                }
            }
        }
    }

    mapLoaded() {
        this.collMapExportEnabled = true;
        this.cdRef.detectChanges();
    }

    mapInit() {
        this.collMapExportEnabled = false;
        this.cdRef.detectChanges();
    }

    exportBtnClicked() {
        this.exportLoading = true;
        document.getElementById('exportBtn').style.backgroundImage =
            "url('assets/images/loadloop.gif')";
        this.floatingUpdateService.notifyCollMap();
    }

    exportFinished() {
        this.exportLoading = false;
        document.getElementById('exportBtn').style.backgroundImage =
            "url('assets/images/export-icon-dark.png')";
    }

    onViewReset() {
        if (!this.foamtree) {
            return;
        }

        if (this.onChild) {
            this.breadCrumbsStackPop.emit();
        }

        this.onChild = false;
    }

    onResize() {
        if (this.foamtree) {
            if (this.resizeTimer) {
                clearTimeout(this.resizeTimer);
                this.resizeTimer = null;
            }
            this.resizeTimer = setTimeout(() => {
                if (this.foamtree) {
                    this.foamtree.resize();
                }
                this.resizeTimer = null;
            }, 500);
        }
    }

    onGroupMouseWheel(event) {
        // if (!this.actOnEvent(event)) {
        //     return;
        // }

        event.preventDefault();
        return false;
    }

    onGroupClick(event) {
        if (this.searchResultLoading) {
            event.preventDefault();
        } else {
            if (!this.actOnEvent(event)) {
                return;
            }

            if (!this.foamtree) {
                return;
            }

            event.preventDefault();
            this.foamtree.open(event.group.id);
            this.foamtree.expose(event.group.id);

            event.groups = [];
            event.groups[0] = event.group;
            this.focus(event);
        }
    }

    // updateMap(fromJump = false) {
        // console.log('*** updateMap');
        // console.log(this.tileData);
        //
        // this.searchResultLoading = true;
        // // Get the data
        //
        // this.mapLoading = true;
        // this.currentSize = 0;
        //
        // this.type = this.searchString ? 'explorer' : 'campus';
        //
        // if (this.tileData) {
        //     const tileDataCopy = JSON.parse(JSON.stringify(this.tileData));
        //     this.tileData = undefined;
        //     this.tryToUpdateFoamTree(tileDataCopy);
        //     console.log('tileDataCopy');
        //     console.log(tileDataCopy);
        // }
        // else if (this.isExplorer()) {
        //     this.networkService
        //         .getDataForExplorer(
        //             this.currentId,
        //             this.searchString,
        //             this.isGlobal(),
        //             !this.isGlobal()
        //                 ? this.selectedCountry
        //                 : null,
        //             this.forceNLP,
        //             true,
        //             this.callStack,
        //             this.selectedAffiliation !== 'All Institutions'
        //                 ? this.selectedAffiliation
        //                 : null,
        //         )
        //         .subscribe(
        //             (data) => this.tryToUpdateFoamTree(data),
        //             (error) => console.log(error),
        //         );
        // } else if (this.isCampus()) {
        //     this.networkService
        //         .getDataForCampus(
        //             this.currentId,
        //             this.trendingState,
        //             true,
        //             this.callStack,
        //         )
        //         .subscribe(
        //             (data) => {
        //                 this.tryToUpdateFoamTree(data);
        //             },
        //             (error) => console.log(error),
        //         );
        // }
    // }

    tryToUpdateFoamTree(data) {
        if (!data) return;
        console.log('tryToUpdateFoamTree');
        console.log(data);
        if (data.nodes.length === 0 && this.currentId === 'top') {
            this.noResults = data.nodes.length === 0;
        } else {
            this.noResults = false;
            this.showBar = true;
        }

        try {
            this.updateFoamTree(data);
        } catch (error) {
            if (error.toString().includes('zero')) {
                // Error that happens when the foamtree is loaded when the view is not available - we are on a different tab.
            } else {
                // setTimeout(() => this.tryToUpdateFoamTree(data), 1);
            }
        }
    }

    actOnEvent(event, type = null) {
        if (event.type === 'mousewheel') {
            event.preventDefault();
            event.preventOriginalEventDefault();
            return false;
        }

        if (!type) {
            if (event.group === undefined) {
                if (event.groups === undefined) {
                    return false;
                }
                if (event.groups[0] === undefined) {
                    return false;
                }
                type = event.groups[0].isCampus;
            } else {
                type = event.group.isCampus;
            }
        }
        if (this.type === 'campus') {
            if (!type) {
                return false;
            }
        } else {
            if (type) {
                return false;
            }
        }
        return true;
    }

    focus(event) {
        if (!this.actOnEvent(event)) {
            return;
        }

        if (!this.foamtree) {
            return;
        }

        if (event.groups.length > 0) {
            const currentClickedId = event.groups[0].id.split('_')[0];
            let add = true;
            if (this.callStack.length > 0) {
                const lastCrumb = this.callStack[this.callStack.length - 1];
                if (lastCrumb === currentClickedId) {
                    add = false;
                } else if (this.onChild === true && event.groups[0].top) {
                    this.callStack.pop();
                    this.breadCrumbsStackPop.emit()
                }
            }
            if (add) {
                this.callStack.push(currentClickedId);
                const crumb = {
                    name: event.groups[0].label,
                    id: currentClickedId,
                    scope: event.groups[0].scope,
                };
                // this.breadCrumbsStack.breadCrumbsStackPush(crumb);
                this.breadCrumbsStackPush.emit(crumb);
                if (!event.groups[0].top) {
                    this.currentId = currentClickedId;
                    this.updateMap.emit({
                        data: {
                            scope: this.selectedCountry, // event.groups[0].scope,
                            currentId: this.currentId,
                            query: this.tileData.query
                        }

                    });
                    //this.tryToUpdateFoamTree(this.tileData);
                } else {
                    this.onChild = true;
                }
                this.currentSize = event.groups[0].weight;
            } else {
                // Nothing was added - no change.
            }
        }
    }

    onRolloutComplete() {
        this.searchResultLoading = false;
    }

    updateFoamTree(data) {
        this.mapLoading = false;

        this.onChild = false;
        const groups = [];

        data.nodes.forEach((node) => {
            const gd = [];

            if (node.children.length > 0) {
                node.children.forEach((child) => {
                    const grandChild = {
                        label: child.name,
                        id: child.id,
                        weight: child.value,
                        scope: child.scope,
                        top: false,
                        trendingWeight: child.trendingWeight,
                        isCampus: this.isCampus(),
                    };
                    gd.push(grandChild);
                });
            }

            const group = {
                label: node.name,
                id: node.id + '_' + this.type,
                weight: node.value,
                groups: gd,
                top: true,
                scope: node.scope,
                trendingWeight: node.trendingWeight,
                isCampus: this.isCampus(),
            };

            groups.push(group);
        });

        if (this.foamtree) {
            this.foamtree.set({
                dataObject: { groups: groups },
            });
        } else {
            // First time
            try {
                this.foamtree = new CarrotSearchFoamTree({
                    id: this.type,
                    groupExposureScale: 1,
                    layoutByWeightOrder: false,
                    layout: 'squarified',
                });
            } catch (error) {
                this.searchResultLoading = false;
                throw error;
            }

            this.onGroupExposureFunction = this.focus.bind(this);
            this.onViewResetFunction = this.onViewReset.bind(this);
            this.onGroupMouseWheelFunction = this.onGroupMouseWheel.bind(this);
            this.onGroupClickFunction = this.onGroupClick.bind(this);
            this.onRolloutCompleteFunction = this.onRolloutComplete.bind(this);

            this.foamtree.on(
                'groupExposureChanged',
                this.onGroupExposureFunction,
            );
            this.foamtree.on('viewReset', this.onViewResetFunction);
            this.foamtree.on('groupMouseWheel', this.onGroupMouseWheelFunction);
            this.foamtree.on('groupClick', this.onGroupClickFunction);
            this.foamtree.on('rolloutComplete', this.onRolloutCompleteFunction);
        }

        this.foamtree.set({
            dataObject: { groups: groups },
        });

        if (this.type === 'explorer') {
            this.foamtree.set({
                rainbowStartColor: 'hsla(186, 100%, 39%, 1)',
                rainbowEndColor: 'hsla(359, 100%, 26%, 1)',
                groupColorDecorator: null,
                layoutByWeightOrder: false,
                layout: 'squarified',
            });
            this.foamtree.redraw();
            this.onResize();
        }
    }

    updateTrendingState(trendingState) {
        this.trendingState = trendingState;
        console.log(trendingState);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    vw() {
        return UtilitiesService.vw();
    }

    isAdmin(userData) {
        return UtilitiesService.isAdmin(userData);
    }

    isCampus() {
        return this.type === 'campus';
    }

    isExplorer() {
        return this.type === 'explorer';
    }


    isGlobal() {
        return this.selectedCountry === 'World Wide';
    }

}
