import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {DataService} from "../../../../data.service";
import {NetworkService} from "../../../../network.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-search-filters',
    templateUrl: './search-filters.component.html',
    styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnInit, OnChanges {

    @Input() searchResults!: SearchQueryResults;
    @Output() searchResultsChange = new EventEmitter<SearchQueryResults>();

    @Input() hasGrants!: boolean;
    @Output() hasGrantsChange = new EventEmitter<boolean>();

    @Input() isIndustry: boolean = false;
    @Input() loading: boolean;

    constructor(public dataService: DataService,
                public networkService: NetworkService,
                public matDialog: MatDialog,) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log("*****")
        // console.log(changes);
        if (changes.hasGrants && changes.hasGrants.firstChange) {
            if (!changes.hasGrants.currentValue) {
                this.hasGrants = false;
            }
        }
    }

    toggle(e) {
        this.hasGrantsChange.emit(e);
    }

    getSearchDescription() {
        if (this.searchResults) {
            let selectedItems;

            const size =
                (this.searchResults.expertSearchResult ? this.searchResults.expertSearchResult.researchers.length : 0) +
                (this.searchResults.institutionSearchResult ? this.searchResults.institutionSearchResult.institutions.length : 0);

            // return `Selected ${selectedItems} of ${size} results`;
            return `Total: ${size} results`;
        } else {
            return "";
        }
    }
}
