import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsPageComponent } from './projects-page.component';
import { ProjectPageComponent } from './project-page/project-page.component';
import { SharedModule } from '../../shared/shared.module';
import { NewProjectDialogComponent } from './new-project-dialog/new-project-dialog.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { EmptyProjectCardComponent } from './empty-project-card/empty-project-card.component';
import { EditProjectDialogComponent } from './edit-project-dialog/edit-project-dialog.component';
import { SaveSearchDialogComponent } from '../search-page/dialogs/save-search-dialog/save-search-dialog.component';
import { SaveSearchSnackBarComponent } from '../search-page/save-search-snack-bar/save-search-snack-bar.component';
import { IndustryTableComponent } from './project-page/search-summary/industry-table/industry-table.component';
import { SearchSummaryComponent } from './project-page/search-summary/search-summary.component';
import { ChipsComponent } from './project-page/search-summary/chips/chips.component';
import { SummaryChartComponent } from './project-page/search-summary/summary-chart/summary-chart.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ExportProjectModalComponent} from "./project-page/export/export-project-modal.component";
// import {NavbarComponent} from "../../components/navbar/navbar.component";
import {ExpertsToolComponent} from "./tools/experts-tool/experts-tool.component";
import {CtToolComponent} from "./tools/ct-tool/ct-tool.component";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TaxonomyChartComponent} from "./project-page/search-summary/taxonomy-chart/taxonomy-chart.component";
import {BarChartComponent} from "../../components/bar-chart/bar-chart.component";
import { ShareProjectComponent } from './project-page/share-project/share-project.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import {ProjectToolsComponent} from "./project-page/project-tools/project-tools.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MarkdownModule} from "ngx-markdown";
import { ProjectComparePageComponent } from './project-compare-page/project-compare-page.component';

@NgModule({
    imports: [
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        CommonModule,
        ProjectsRoutingModule,
        SharedModule,
        MatProgressBarModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        MarkdownModule,
    ],
    declarations: [
        // NavbarComponent,
        ProjectsPageComponent,
        ProjectPageComponent,
        ProjectComparePageComponent,
        NewProjectDialogComponent,
        ProjectCardComponent,
        EmptyProjectCardComponent,
        EditProjectDialogComponent,
        IndustryTableComponent,
        SaveSearchDialogComponent,
        SaveSearchSnackBarComponent,
        SearchSummaryComponent,
        ChipsComponent,
        SummaryChartComponent,
        TaxonomyChartComponent,
        ExportProjectModalComponent,
        ExpertsToolComponent,
        CtToolComponent,
        BarChartComponent,
        ShareProjectComponent,
        ProjectToolsComponent
    ],
    exports: [
        // NavbarComponent,
        MatProgressSpinnerModule,
        ProjectCardComponent
    ],
    providers: []
})
export class ProjectsModule {}
