import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../../network.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../data.service';

@Component({
    selector: 'app-institution-manager',
    templateUrl: './search-tool.component.html',
    styleUrls: ['./search-tool.component.scss'],
})
export class SearchToolComponent implements OnInit {
    query: string;
    searchResults: any;

    constructor(
        private networkService: NetworkService,
        private http: HttpClient,
        public dataService: DataService,
    ) {}

    ngOnInit() {}

    async search(query: string) {
        try {
            const result = await this.networkService.search(query);
            this.searchResults = result;
        } catch (e) {
            alert('error! ' + e.error);
        }
    }
}
