import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-newpaper-editor',
    templateUrl: './newpaper-editor.component.html',
    styleUrls: ['./newpaper-editor.component.scss'],
})
export class NewpaperEditorComponent implements OnInit {
    @Input() id;
    @Input() instanceId;
    @Output() newspaperDelete = new EventEmitter();

    showModal = false;
    duringUpdate = false;

    country: string;
    site: string;
    inUrlFilter: string;
    titleSelector: string;
    subTitleSelector: string;
    textSelector: string;
    logoUrl: string;
    logoUrlDisplay: string;
    active: boolean;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        this.showModal = true;
        this.networkService.getNewspaperById(this.id).subscribe(
            (data) => this.handleNewspaperResponse(data),
            (error) => console.log(error),
        );
    }

    handleNewspaperResponse(data) {
        this.id = data.id;
        this.country = data.country;
        this.site = data.site;
        this.inUrlFilter = data.inUrlFilter;
        this.titleSelector = data.titleSelector;
        this.subTitleSelector = data.subTitleSelector;
        this.textSelector = data.textSelector;
        this.logoUrl = data.logoUrl;
        this.logoUrlDisplay = this.logoUrl;
        this.active = data.active;
        this.duringUpdate = false;
    }

    updateField(field, value) {
        this.duringUpdate = true;
        this.networkService.updateNewspaper(this.id, field, value).subscribe(
            (data) => this.handleNewspaperResponse(data),
            (error) => {
                this.duringUpdate = false;
                console.log(error);
                alert(error.error.toString());
            },
        );
    }

    deleteNewspaper() {
        if (confirm('Are you sure to delete newspaper id ' + this.id + '?')) {
            this.networkService.deleteNewspaper(this.id).subscribe(
                (data) => {
                    this.showModal = false;
                    this.newspaperDelete.emit();
                },
                (error) => console.log(error),
            );
        }
    }

    btnDisabled() {
        return this.duringUpdate;
    }
}
