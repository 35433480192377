<div style="display: flex; flex-direction: column">
    <h3>Search Newspaper:</h3>
    <form (ngSubmit)="onSearchSubmit()" style="margin-bottom: 20px">
        <div class="form-group">
            <textarea
                [(ngModel)]="query"
                name="name"
                class="form-control"
                rows="1"
                id="newpaperCountry"
                placeholder="Country"
            ></textarea>
        </div>
        <button class="btn btn-primary">Search</button>
        <button (click)="createNewspaper()" class="btn btn-primary">
            Create newspaper
        </button>
        <h3 style="color: beige">{{ message }}</h3>
    </form>

    <div class="col-8">
        <div *ngFor="let newspaper of newspapers">
            <button
                type="button"
                (click)="openNewspaperDetails(newspaper.id)"
                class="btn btn-primary btn-sm"
            >
                {{ newspaper.id }}, {{ newspaper.country }},
                {{ newspaper.site }}
            </button>
        </div>
    </div>

    <div
        style="display: flex; justify-content: space-between; margin-left: 20px"
    >
        <app-newpaper-editor
            *ngIf="show"
            [id]="selectedId"
            (newspaperDelete)="onSearchSubmit()"
        ></app-newpaper-editor>
    </div>
</div>
