<div class="dialog callout-dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title"> Create New Request</div>
    </div>
    <mat-stepper class="px-5" style="min-height: 400px">
        <mat-step>
            <div>
                <ng-template matStepLabel>Select the request type</ng-template>
            </div>
            <div class="container pt-3">
                <request-cards
                        [readonly]="false"
                    [(advise)]="optionAdvise"
                    [(project)]="optionProject"
                    [(data)]="optionData"
                ></request-cards>
                <div class="row p-5 d-flex justify-content-between">
                    <button (click)="close()" class="align-content-start btn btn-dark text-light border mx-2">Cancel</button>
                    <button
                            [disabled]="!optionData && !optionProject && !optionAdvise"
                            mat-button matStepperNext class="btn btn-primary border mx-2" (click)="logit()">Next</button>
                </div>
            </div>
        </mat-step>
        <mat-step>
            <div>
                <ng-template matStepLabel>Message</ng-template>
            </div>
            <div>
                <div class="p-2">
                    <span class="h3"> Title  </span>
                    <span style="font-style: italic">(Must be at least <b>20</b> characters long and be meaningful)</span>
                    <span *ngIf="isLengthValid(title)" class="color-error">*</span>
                </div>
                <input style="width: 100%"
                       type="text"
                       name="title"
                       #title="ngModel"
                       id="title"
                       [(ngModel)]="titleInput"
                       minlength="20" />
            </div>
            <div class="pt-2">
                <div class="p-2">
                    <span class="h3"> Request Content </span>
                    <span style="font-style: italic">(Must be at least <b>50</b> characters long and be meaningful)</span>
                    <span *ngIf="isLengthValid(description)" class="color-error">*</span>
                </div>
                <textarea
                    minlength="50"
                    class="request-textarea"
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Write your request here..."
                    style="width: 100%"
                    rows="15"
                    [(ngModel)]="descriptionInput"
                    #description="ngModel"></textarea>
            </div>
            <div class="row p-5 d-flex justify-content-between">
                <button mat-button matStepperPrevious class="btn btn-dark text-light border mx-2">Back</button>
                <button
                        [disabled]="title.invalid || description.invalid || (titleInput.length === 0 || descriptionInput.length === 0)"
                        (click)="create(
                    optionAdvise,
                    optionProject,
                    optionData,
                    title.value,
                    description.value)" class="btn btn-primary border mx-2">
                    {{ data.callout ? 'Update' : 'Create' }}
                </button>
            </div>
        </mat-step>
    </mat-stepper>
</div>


