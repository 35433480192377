import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { NetworkService } from 'src/app/network.service';
import { MatDialog } from '@angular/material/dialog';
import { NewProjectResult } from 'src/app/api-responses/NewProjectResult.interface';
import {DataService} from "../../../data.service";

@Component({
    selector: 'app-new-project-dialog',
    templateUrl: './new-project-dialog.component.html',
    styleUrls: ['./new-project-dialog.component.scss'],
})
export class NewProjectDialogComponent implements OnInit {
    formGroup = this.formBuilder.group({
        title: [''],
        description: [''],
    });
    
    public canCreate : boolean = false;


    isEditMode = false; /*TODO - will be boolean on future release */

    constructor(
        public networkService: NetworkService,
        public dataService: DataService,
        public http: HttpClient,
        public router: Router,
        public matDialog: MatDialog,
        public formBuilder: UntypedFormBuilder,
    ) {}

    ngOnInit() {}

    closeNewProjectModal() {
        this.matDialog.closeAll();
        window.history.pushState('', '/', window.location.pathname);
    }

    createProject() {
        return this.http
            .post<NewProjectResult>(`/api/v1/innovationProjects/`, null, {
                params: {
                    title: this.formGroup.controls.title.value,
                    description: this.formGroup.controls.description.value,
                },
            })
            .toPromise()
            .then((project) => {
                this.closeNewProjectModal();
                this.router.navigate([`/app/projects/${project.id}`]);
            });
    }

    onTitleChange(){
        this.canCreate = this.formGroup.controls.title.value.length > 4;
    }
}
