import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './pages/logout/logout.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ExplorerMapComponent } from './pages/explorer-map/explorer-map.component';
import { TrendingBubbleComponent } from './pages/trending-bubble/trending-bubble.component';
import { CompaniesSearchComponent } from './pages/search-page/companies-search/companies-search.component';
// import { CampusMapD3Component } from './pages/campus-map-d3/campus-map-d3.component';
import { DepositComponent } from './pages/deposit/deposit.component';
import { GroupsManagmentComponent } from './pages/groups-managment/groups-managment.component';
import { InstitutionManagerComponent } from './components/institution-manager/institution-manager.component';
import { MachinesComponent } from './pages/machines/machines.component';
import { MachineImportComponent } from './pages/admin/machine-import/machine-import.component';
import { MachineManagementComponent } from './pages/admin/machine-management/machine-management.component';
import { UserManagementComponent } from './pages/admin/user-management/user-management.component';
import { VcsAdminComponent } from './pages/admin/vcs-admin/vcs-admin.component';
import { GoogleClientTesterComponent } from './pages/admin/google-client-tester/google-client-tester.component';
import { VcsAdminAddComponent } from './pages/admin/vcs-admin/vcs-admin-add/vcs-admin-add.component';
import { StartupsAdminComponent } from './pages/admin/startups-admin/startups-admin.component';
import { ExpertsCtComponent } from './pages/experts-ct/experts-ct.component';
import { InvestorManagementComponent } from './pages/admin/investor-management/investor-management.component';
import { TrendingTopicsComponent } from './pages/trending-topics/trending-topics.component';
import { EsriCsvGeneratorComponent } from './pages/admin/esri-csv-generator/esri-csv-generator.component';
import { NewpaperManagementComponent } from './pages/admin/newpaper-management/newpaper-management.component';
import { SynonymsManagementComponent } from './pages/admin/synonyms-management/synonyms-management.component';
import { StrengthCompInsManagementComponent } from './pages/admin/strength-comp-ins-management/strength-comp-ins-management.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { AdminSearchCalibratorComponent } from './pages/admin/admin-search-calibrator/admin-search-calibrator.component';
import { EsriDynamicGeneratorComponent } from './pages/admin/esri-dynamic-generator/esri-dynamic-generator.component';
import { CtPhaseToolComponent } from './pages/admin/ct-phase-tool/ct-phase-tool.component';
import { SearchToolComponent } from './pages/admin/search-tool/search-tool.component';
import { SmartSheetAdminComponent } from './pages/admin/smart-sheet-admin/smart-sheet-admin.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { TrendingPageComponent } from './pages/trending-page/trending-page.component';
import { ResearchTimelinePageComponent } from './pages/research-timeline-page/research-timeline-page.component';
import { StrengthMapperPageComponent } from './pages/strength-mapper-page/strength-mapper-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import {TermsPageComponent} from "./pages/terms-page/terms-page.component";
import {OpenAlexComponent} from "./pages/admin/open-alex/openAlex.component";

const routes: Routes = [
    {
        path: 'app/register',
        component: RegisterPageComponent,
    },
    {
        path: 'app/terms',
        component: TermsPageComponent,
    },
    {
        path: 'app/search',
        component: SearchPageComponent,
    },
    {
        path: 'app/login',
        component: LoginComponent,
    },
    {
        path: 'app/forgot-my-password',
        component: ForgotPasswordPageComponent,
    },
    {
        path: 'app/myCampus',
        pathMatch: 'full',
        redirectTo: 'app/map/',
    },
    {
        path: 'app/logout',
        component: LogoutComponent,
    },
    // {
    //     path: 'app/map',
    //     component: CampusMapD3Component,
    // },
    {
        path: 'app/admin',
        component: AdminComponent,
    },
    {
        path: 'app/admin/user-management',
        component: UserManagementComponent,
    },
    {
        path: 'app/admin/open-alex',
        component: OpenAlexComponent,
    },
    {
        path: 'app/admin/vcs-admin',
        component: VcsAdminComponent,
    },
    {
        path: 'app/admin/google-client-tester',
        component: GoogleClientTesterComponent,
    },
    {
        path: 'app/admin/vcs-admin/vcs-admin-add',
        component: VcsAdminAddComponent,
    },
    {
        path: 'app/admin/startups-admin',
        component: StartupsAdminComponent,
    },
    {
        path: 'app/admin/startups-admin/url',
        component: StartupsAdminComponent,
    },
    {
        path: 'app/admin/groupMgnt',
        component: GroupsManagmentComponent,
    },
    {
        path: 'app/admin/insMgnt',
        component: InstitutionManagerComponent,
    },
    {
        path: 'app/admin/investorMgmt',
        component: InvestorManagementComponent,
    },
    {
        path: 'app/admin/machMgmt',
        component: MachineManagementComponent,
    },
    {
        path: 'app/admin/machine-import',
        component: MachineImportComponent,
    },
    {
        path: 'app/deposit',
        component: DepositComponent,
    },
    // {
    //     path: 'app/account',
    //     component: ProfileComponent,
    // },
    {
        path: 'app/explorerMap/:searchString',
        component: ExplorerMapComponent,
    },
    {
        path: 'app/campusTrending/:searchString',
        component: TrendingBubbleComponent,
    },
    {
        path: 'app/corporatesSearch/:searchString',
        component: CompaniesSearchComponent,
    },
    {
        path: 'app/startupsSearch/:searchString',
        component: CompaniesSearchComponent,
    },
    // {
    //     path: 'app/map/:searchString',
    //     component: CampusMapD3Component,
    // },
    {
        path: 'app/strengthMapper/:searchString',
        component: TrendingTopicsComponent,
    },
    {
        path: 'app/resources/:searchString',
        component: MachinesComponent,
    },
    {
        path: 'app/clinicalTrialsExperts/:searchString',
        component: ExpertsCtComponent,
    },
    {
        path: 'app/admin/esri-csv-generator',
        component: EsriCsvGeneratorComponent,
    },
    {
        path: 'app/admin/newspaperMgmt',
        component: NewpaperManagementComponent,
    },
    {
        path: 'app/admin/synonymsMgmt',
        component: SynonymsManagementComponent,
    },
    {
        path: 'app/admin/adminSearch',
        component: AdminSearchCalibratorComponent,
    },
    {
        path: 'app/admin/strengthCompInsMgmt',
        component: StrengthCompInsManagementComponent,
    },
    {
        path: 'app/timeline/:searchString',
        component: TimelineComponent,
    },
    {
        path: 'app/copilotAssignment',
        children: [
            {
                path: '**' /* support trailing "/" appended by floating service */,
                component: UserDashboardComponent,
            },
        ],
    },
    {
        path: 'app/admin/esriDynamicGenerator',
        component: EsriDynamicGeneratorComponent,
    },

    {
        path: 'app/admin/smart-sheet-admin',
        component: SmartSheetAdminComponent,
    },
    {
        path: 'app/admin/search-tool',
        component: SearchToolComponent,
    },
    {
        path: 'app/admin/ct-phase-tool',
        component: CtPhaseToolComponent,
    },
    {
        path: 'app/trending',
        component: TrendingPageComponent,
    },
    {
        path: 'app/research-timeline',
        component: ResearchTimelinePageComponent,
    },
    {
        path: 'app/strength-mapper',
        component: StrengthMapperPageComponent,
    },
    // {
    //     path: 'app/home',
    //     component: CampusMapD3Component,
    // },
    {
        path: 'app/projects',
        loadChildren: () =>
            import('./pages/projects-page/projects.module').then(
                (m) => m.ProjectsModule,
            ),
    },
    // {
    //     path: 'app/canvas',
    //     loadChildren: () =>
    //         import('./pages/canvas/canvas.module').then(
    //             (m) => m.CanvasModule,
    //         ),
    // },
    {
        path: 'app/companies',
        loadChildren: () =>
            import('./pages/companies-page/companies.module').then(
                (m) => m.CompaniesModule,
            ),
    },
    {
        path: 'app/callout',
        loadChildren: () =>
            import('./pages/callout/callout.module').then(
                (m) => m.CalloutModule,
            ),
    },
    {
        path: 'app/account',
        loadChildren: () =>
            import('./pages/account/account.module').then(
                (m) => m.AccountModule,
            ),
    },
    {
        path: '',
        loadChildren: () =>
            import('./pages/projects-page/projects.module').then(
                (m) => m.ProjectsModule,
            ),
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
