import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NetworkService } from '../../network.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilitiesService } from '../../utilities.service';
import { FloatingUpdateService } from '../../floating-update.service';
import { DataService } from '../../data.service';

@Component({
    selector: 'app-explorer-map',
    templateUrl: './explorer-map.component.html',
    styleUrls: ['./explorer-map.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class ExplorerMapComponent implements OnInit, OnDestroy {
    static tempGlobal = false;
    static tempCountry = 'World Wide';
    static tempAffiliation = 'All Institutions';
    static urlParams = `${DataService.char}local${DataService.char}All Institutions`;
    @ViewChild('explorer') input: ElementRef;
    public classReference = ExplorerMapComponent;
    countriesDropdownSearch = '';
    institutionsDropdownSearch = '';
    map;
    searchString: string;
    global = false;
    countries;
    institutions;
    selectedCountry = 'World Wide';
    selectedAffiliation = 'All Institutions';
    enableNLP = false;
    forceNLP = false;
    countriesDropdownLastClick;
    institutionsDropdownLastClick;
    char: string = DataService.char;
    private _routerSub = Subscription.EMPTY;

    constructor(
        private networkService: NetworkService,
        private router: Router,
        private route: ActivatedRoute,
        private floatingUpdateService: FloatingUpdateService,
        public dataService: DataService,
    ) {
        this._routerSub = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    let searchString =
                        this.route.snapshot.params['searchString'];

                    if (searchString.includes(this.char)) {
                        searchString = searchString.substring(
                            0,
                            searchString.lastIndexOf(this.char),
                        );

                        const affIndex = searchString.lastIndexOf(this.char);
                        const scopeIndex = searchString.lastIndexOf(
                            this.char,
                            affIndex - 1,
                        );
                        const scope = searchString.substring(
                            scopeIndex + 1,
                            affIndex,
                        );
                        const aff = searchString.substring(affIndex + 1);

                        searchString = searchString.substring(0, scopeIndex);
                        if (searchString !== '' && scope !== '' && aff !== '') {
                            ExplorerMapComponent.tempGlobal = scope !== 'local';
                            if (ExplorerMapComponent.tempGlobal) {
                                ExplorerMapComponent.tempCountry = scope;
                            }
                            ExplorerMapComponent.tempAffiliation = aff;
                        }
                    }

                    this.searchString =
                        searchString.length >= 3 ? searchString : '';
                    if (this.searchString !== '') {
                        this.onSearchSubmit();
                    }
                }
            });
    }

    ngOnInit() {
        this.updateUrl();
        this.getCountries();
        if (
            ExplorerMapComponent.tempGlobal &&
            ExplorerMapComponent.tempCountry !== 'World Wide'
        ) {
            this.loadInstitutions(ExplorerMapComponent.tempCountry, false);
        }
        this.countriesDropdownLastClick = Date.now();
        this.institutionsDropdownLastClick = Date.now();
    }

    ngOnDestroy() {
        this._routerSub.unsubscribe();
    }

    getCountries() {
        this.networkService.getCountries().subscribe(
            (data) => {
                this.countries = data;
                this.countries.valueB.shift();
                this.countries = this.countries.valueB;
            },
            (error) => console.log(error),
        );
    }

    searchDisabled() {
        return this.searchString === undefined || this.searchString.length < 3;
    }

    globalClicked() {
        this.updateUrl();
    }

    updateUrl() {
        ExplorerMapComponent.urlParams = `${this.char}${
            ExplorerMapComponent.tempGlobal
                ? ExplorerMapComponent.tempCountry
                : 'local'
        }${this.char}${ExplorerMapComponent.tempAffiliation}${this.char}`;
        this.dataService.urlParams = ExplorerMapComponent.urlParams;
        this.floatingUpdateService.notifyUrlUpdated();
    }

    onSearchSubmit() {
        this.global = ExplorerMapComponent.tempGlobal;
        this.selectedCountry = ExplorerMapComponent.tempCountry;
        this.selectedAffiliation = ExplorerMapComponent.tempAffiliation;

        if (!this.map) {
            this.map = this.input;
        }

        this.forceNLP = false;
        this.checkNLP();
        setTimeout(() => {
            this.map.resetMap();
            this.map.onResize();
        }, 1);
    }

    nlpClicked(explorer) {
        this.forceNLP = true;
        this.enableNLP = false;
        setTimeout(() => explorer.resetMap(), 1);
    }

    checkNLP() {
        this.enableNLP = this.searchString.trim().split(' ').length > 3;
    }

    handleNlp(value) {
        if (!value) {
            this.checkNLP();
        }
    }

    handleKeyboardEvents(event) {
        const countriesDropdownOpen = document.getElementById(
            'countriesDropdown',
        )
            ? document
                  .getElementById('countriesDropdown')
                  .classList.contains('open')
            : false;
        const institutionsDropdownOpen = document.getElementById(
            'institutionsDropdown',
        )
            ? document
                  .getElementById('institutionsDropdown')
                  .classList.contains('open')
            : false;

        if (
            ExplorerMapComponent.tempGlobal &&
            (countriesDropdownOpen || institutionsDropdownOpen)
        ) {
            const arr = countriesDropdownOpen
                ? this.countries
                : this.institutions;
            const lastClick = countriesDropdownOpen
                ? this.countriesDropdownLastClick
                : this.institutionsDropdownLastClick;
            const search = countriesDropdownOpen
                ? this.countriesDropdownSearch
                : this.institutionsDropdownSearch;
            const result = UtilitiesService.handleKeyboardEvents(
                event,
                lastClick,
                arr,
                search,
                ExplorerMapComponent.tempGlobal,
            );

            if (result) {
                countriesDropdownOpen
                    ? (this.countriesDropdownSearch = result[0])
                    : (this.institutionsDropdownSearch = result[0]);
                countriesDropdownOpen
                    ? (this.countriesDropdownLastClick = result[1])
                    : (this.institutionsDropdownLastClick = result[1]);
            }
        }
    }

    loadInstitutions(country, reset = true) {
        if (reset) {
            ExplorerMapComponent.tempAffiliation = 'All Institutions';
            this.selectedAffiliation = 'All Institutions';
        }

        this.networkService.getNonIndustryInstitutions(country).subscribe(
            (data) => {
                this.institutions = data;
            },
            (error) => console.log(error),
        );
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }
}
