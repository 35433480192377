import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {NetworkService} from '../../../network.service';
import {Institution, Researcher, SearchQueryResults} from '../../../api-responses/SearchQueryResults.interface';
import {
    getSearchType,
    SearchTypes,
} from '../../../api-responses/SearchTypes.interface';
import {Subscription} from 'rxjs';
import {CountriesDataService} from "../../../shared/CountriesDataService";
import {TreebuteProjectSummaryReducer} from "../../../utils/treebuteProjectSummaryReducer";
import {DataService} from "../../../data.service";

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
    public tileOptions: (string | any)[];

    @Input() isIndustry: boolean;
    @Input() isExperts: boolean;
    @Input() isKnowledge: boolean;
    @Input() selectedCountry: string;

    @Output() changeTab = new EventEmitter();

    @Output() searchIndustry= new EventEmitter();
    @Output() searchExperts= new EventEmitter();
    @Output() searchTiles= new EventEmitter();

    // @Input() companies: Institution[] = [];
    // @Input() experts: Researcher[] = [];

    tabs = [
        {
            hash: 'industry',
            title: 'Industry',
        },
        {
            hash: 'experts',
            title: 'Identify an Expert',
        },
        {
            hash: 'knowledge_map',
            title: 'Knowledge map',
        },
    ];
    @Input() searchResults: SearchQueryResults;
    private redirectTo: string;

    constructor(
        public activatedRoute: ActivatedRoute,
        private countriesDataService: CountriesDataService,
        private location: Location,
        private router: Router,
        public dataService: DataService
    ) {
        this.redirectTo = activatedRoute.snapshot.data.redirectTo;
    }

    ngOnInit() {
        this.countriesDataService.getCountries().subscribe(countries => {
            this.tileOptions = ['User Scope', 'World Wide', ...countries];
        });

        //this.isHash();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isIndustry || changes.isExperts || changes.isKnowledge) {
            this.isIndustry = changes.isIndustry ? changes.isIndustry.currentValue : this.isIndustry;
            this.isExperts = changes.isExperts ? changes.isExperts.currentValue: this.isExperts;
            this.isKnowledge = changes.isKnowledge ? changes.isKnowledge.currentValue : this.isKnowledge;
        }
    }

    ngOnDestroy() {
    }

    // isHash() {
    //     if (window.location['hash'] === '#industry') {
    //         this.isIndustry = true;
    //         this.isExperts = false;
    //         this.isKnowledge = false;
    //         this.changeTab.emit('industry');
    //     } else if (window.location['hash'] === '#experts') {
    //         this.isIndustry = false;
    //         this.isExperts = true;
    //         this.isKnowledge = false;
    //         this.changeTab.emit('experts');
    //     } else if (window.location['hash'] === '#knowledge_map') {
    //         this.isIndustry = false;
    //         this.isExperts = false;
    //         this.isKnowledge = true;
    //         this.changeTab.emit('tiles');
    //     } else {
    //         window.location['hash'] = "#industry";
    //         this.isIndustry = true;
    //         this.isExperts = false;
    //         this.isKnowledge = false;
    //         this.changeTab.emit('industry');
    //     }
    // }

    tabClick(hash: string) {
        // let parts = window.location.href.split("#");
        // location.replace(parts[0] + '#' + hash);
        // location.replace(hash);

        if (hash === 'industry') {
            this.changeTab.emit('industry');
        } else if (hash === 'experts') {
            this.changeTab.emit('experts');
        } else if (hash === 'knowledge_map') {
            this.changeTab.emit('knowledge_map');
        } else {
            this.changeTab.emit('industry');
        }
    }

    onSubmitIndustry($event: any) {
        if (!$event.data.query) return;
        this.searchIndustry.emit($event)
    }

    onSubmitExperts($event: any) {
        if (!$event.data.query) return;
        this.searchExperts.emit($event)
    }

    onSubmitTiles($event: any) {
        this.searchTiles.emit($event)
    }
    //
    // getSelectedAcademicField() {
    //     if (this.searchResults &&
    //         ['ACADEMIC_EXPERTS', 'INDUSTRY_EXPERTS', 'CT_EXPERTS'].some(el => this.searchResults.searchType.includes(el))) {
    //         return this.searchResults.academicField;
    //     }
    //     else {
    //         return 'ALL';
    //     }
    // }
    get activeTab() {
        return this.isIndustry ?
            'industry' :
            this.isExperts ?
                'experts' :
                this.isKnowledge ?
                    'knowledge_map' :
                    '';
        // if (tab.hash === 'industry' && this.isIndustry) return true;
        // if (tab.hash === 'experts' && this.isExperts) return true;
        // if (tab.hash === 'knowledge_map' && this.isKnowledge) return true;
        //
        // return false;
    }

    getSearchResults(kind: string) {
        if (kind === 'industry') {
            return TreebuteProjectSummaryReducer.isIndustry(this.searchResults) && this.searchResults;
        }
        else if (kind === 'experts') {
            return TreebuteProjectSummaryReducer.isExperts(this.searchResults) && this.searchResults;
        }
        else if(kind === 'knowledge_map') {
            return TreebuteProjectSummaryReducer.isTileSearch(this.searchResults) && this.searchResults;
        }
    }
}
