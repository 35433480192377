import {Injectable} from "@angular/core";
import {SearchQueryResults} from "../../../api-responses/SearchQueryResults.interface";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class SearchPageUtils {

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router) {
    }

    isFreeSearch() {
        return this.router.url.indexOf('app/search') > -1;
    }

    getIdFromUrl() :string {
        if (this.isFreeSearch()) return '';
        // 'https://beta.treebute.io/app/projects/1/search/6'
        let url = this.activatedRoute.snapshot.routeConfig.path;
        let parts = url.split('/');
        return parts[parts.length - 1];
    }

    getTitleByQuery(query: string) {
        return query
            .replace('(', ' ')
            .replace(')', ' ')
            .substr(0, 20);
    }
}
