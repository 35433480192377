import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { NetworkService } from '../../../network.service';
import {DataService} from "../../../data.service";

@Component({
    selector: 'app-account-options',
    templateUrl: './account-options.component.html',
    styleUrls: ['./account-options.component.scss'],
})
export class AccountOptionsComponent implements OnInit {
    showContactModal = false;

    @Output() closeModal = new EventEmitter();

    constructor(public networkService: NetworkService, public dataService: DataService) {}

    ngOnInit() {
    }

    openContactModal() {
        this.showContactModal = true;
    }

    closeContactModal() {
        this.showContactModal = false;
    }

    close() {
        this.closeModal.emit();
    }

    logout() {
        this.dataService.logout();
    }
}
