<div class="evidence-container" [hidden]="!hasCompanies()">
    <div>
        <h2 class="pt-5 pb-3" [style.color]="evidenceColor">
            Selected Startups
        </h2>
    </div>
    <div class="evidence-container-inner">
        <div class="companies-show-container">
            <div *ngFor="let inst of this.companies.slice(0,10)" class="company-show-box">
                <div class="company-show-box-header" (click)="goToInst(inst)">
                    <div class="company-show-box-logo">
                        <img src="{{utilsComponent.getLogo(inst)}}"/>
                    </div>
                    <div class="company-show-box-title name">
                    <span [style.color]="evidenceColor">
                        {{inst.name}}
                    </span>
                    </div>
                </div>
                <div class="company-show-box-description" (click)="goToInst(inst)">
                <span>
                    {{inst.description}}
                </span>
                </div>
                <div class="chips-container" *ngIf="!readOnlyProjectService.isReadOnlyProject">
                    <app-search-chip class="add-data-button-2"
                                     *ngFor="let search of companySearches[inst.id]"
                                     [project]="project"
                                     [searchResult]="search"
                    >
                    </app-search-chip>
                </div>
                <div class="company-show-box-footer">
                    <div class="company-show-box-website-link">
                        <a href="{{inst.homepageUrl}}" target="_blank">website...</a>
                    </div>
                    <div *ngIf="inst.linkedInURL" class="company-show-box-linkedInLink">
                        <a target="_blank" href="{{ inst.linkedInURL }}">
                            <img class="linkedin" src="assets/images/linkedin.jpg"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
