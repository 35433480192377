<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title"> {{ data.dialogTitle }}</div>
        <div class="search-tool-container">
            <div class="line">
            </div>
<!--            <div>-->
<!--                <input type="text" [(ngModel)]="instQuery" />-->
<!--                <button class="btn btn-primary" (click)="search()">Search</button>-->
<!--            </div>-->
            <div class="result-box">
                <ul *ngIf="!data.multiselect">
                    <li *ngFor="let item of items">
                        <button (click)="selectItem(item)"
                                class="mat-flat-button">
                            {{ item.value }} </button>
                    </li>
                </ul>
                <div *ngIf="data.multiselect">
                    <div class="form-group">
                        <div *ngFor="let item of items" class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="{{item.id}}"
                                       name="{{item.id}}"
                                       [(ngModel)]="item.selected" />
                                {{item.value}}
                            </label>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="button-cancel" (click)="closeModal()">
                Cancel
            </div>
            <div class="button-save" (click)="selectItems()">
                Save
            </div>
        </div>
    </div>
</div>
