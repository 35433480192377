<div style="display: flex; flex-direction: column">
    <div
        *ngIf="showModal"
        style="display: flex; justify-content: space-between"
    >
        <div style="height: fit-content; margin-top: 20px">
            <div class="col-8">
                <div style="border: 1px solid gray">
                    <button
                        type="button"
                        (click)="showCreateInstace = !showCreateInstace"
                        class="btn btn-primary btn-sm"
                    >
                        Add an instance
                    </button>
                    <div *ngIf="showCreateInstace">
                        <div class="col-8">
                            <span id="institution">Select an institution:</span>
                            <div class="dropdown">
                                <button
                                    class="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {{ dropdownInsId }} {{ dropdownInsName }}
                                </button>
                                <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <span
                                        id="{{ i.valueA }}"
                                        class="dropdown-item"
                                        type="button"
                                        *ngFor="let i of institutions"
                                        (click)="
                                            dropdownInsName = i.valueA;
                                            dropdownInsId = i.valueB
                                        "
                                    >
                                        {{ i.valueB }} - {{ i.valueA }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            id="newDepartmentName"
                            [(ngModel)]="newDepartmentName"
                            placeholder="Enter department name"
                            style="width: 500px"
                        />
                        <button
                            type="button"
                            (click)="
                                createNewInstance(
                                    dropdownInsId,
                                    newDepartmentName
                                )
                            "
                            class="btn btn-primary btn-sm"
                        >
                            Create a new instance
                        </button>
                    </div>
                </div>
                <div style="border: 1px solid gray">
                    <button
                        type="button"
                        (click)="findInstances()"
                        class="btn btn-primary btn-sm"
                    >
                        Find Instances
                    </button>
                    <div *ngFor="let instance of instances">
                        <button
                            type="button"
                            (click)="onInstanceClick(instance.id)"
                            class="btn btn-primary btn-sm"
                        >
                            {{ instance.institutionName }},
                            {{ instance.institutionId }},
                            {{ instance.department }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <label for="name">Name</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        [(ngModel)]="name"
                        placeholder="Enter name"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('name', name)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="make">Make</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="make"
                        [(ngModel)]="make"
                        placeholder="Enter make"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('make', make)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="model">Model</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="model"
                        [(ngModel)]="model"
                        placeholder="Enter model"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('model', model)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="class">Class</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="class"
                        [(ngModel)]="class"
                        placeholder="Enter class"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('class', class)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="order">Order</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="order"
                        [(ngModel)]="order"
                        placeholder="Enter order"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('order', order)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="genus">Genus</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="genus"
                        [(ngModel)]="genus"
                        placeholder="Enter genus"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('genus', genus)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="imageUrl">Image URL</label>
                <div style="display: flex">
                    <input
                        disabled
                        type="text"
                        class="form-control"
                        id="imageUrl"
                        [(ngModel)]="imageUrl"
                        style="width: 500px"
                    />
                </div>
                <div
                    *ngIf="imageUrl"
                    [style.backgroundImage]="'url(' + imageUrl + ')'"
                    style="
                        width: 300px;
                        height: 300px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                    "
                ></div>
            </div>
            <div class="col-8">
                <label for="imageFile">Image file:</label>
                <input
                    id="imageFile"
                    type="file"
                    style="text-align: center"
                    (change)="updateFile($event)"
                />
                <button
                    type="button"
                    [disabled]="btnDisabled()"
                    (click)="updateField('imageFile', null)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
            </div>
            <div>
                <div>
                    <h3>Add Document</h3>
                    <div class="documentBox">
                        <div class="col-8">
                            <label for="type">Type</label>
                            <div style="display: flex">
                                <input
                                    #type
                                    type="text"
                                    class="form-control"
                                    id="docType"
                                    placeholder="Enter document type"
                                    style="width: 500px"
                                />
                            </div>
                        </div>
                        <div class="col-8">
                            <label for="data">Data</label>
                            <div style="display: flex">
                                <textarea
                                    #data
                                    type="text"
                                    class="form-control"
                                    id="docData"
                                    placeholder="Enter document data"
                                    style="width: 500px"
                                ></textarea>
                            </div>
                        </div>
                        <div class="col-8">
                            <label for="type">URL</label>
                            <div style="display: flex">
                                <input
                                    #url
                                    type="text"
                                    class="form-control"
                                    id="docUrl"
                                    placeholder="Enter document URL"
                                    style="width: 500px"
                                />
                            </div>
                        </div>
                        <button
                            type="button"
                            (click)="addDocument(type, data, url)"
                            class="btn btn-primary btn-sm"
                        >
                            Add
                        </button>
                    </div>
                </div>
                <div *ngFor="let doc of documents">
                    <h3>Document {{ documents.indexOf(doc) + 1 }}</h3>
                    <div class="documentBox">
                        <div class="col-8">
                            <label for="type">Type</label>
                            <div style="display: flex">
                                <input
                                    #type
                                    type="text"
                                    class="form-control"
                                    id="docType"
                                    placeholder="Enter document type"
                                    style="width: 500px"
                                    value="{{ doc.type }}"
                                />
                            </div>
                        </div>
                        <div class="col-8">
                            <label for="data">Data</label>
                            <div style="display: flex">
                                <textarea
                                    #data
                                    type="text"
                                    class="form-control"
                                    id="docData"
                                    placeholder="Enter document data"
                                    style="width: 500px"
                                    value="{{ doc.data }}"
                                ></textarea>
                            </div>
                        </div>
                        <div class="col-8">
                            <label for="type">URL</label>
                            <div style="display: flex">
                                <input
                                    #url
                                    type="text"
                                    class="form-control"
                                    id="docUrl"
                                    placeholder="Enter document URL"
                                    style="width: 500px"
                                    value="{{ doc.url }}"
                                />
                            </div>
                        </div>
                        <button
                            type="button"
                            (click)="updateDocument(doc.id, type, data, url)"
                            class="btn btn-primary btn-sm"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            (click)="removeDocument(doc.id)"
                            class="btn btn-primary btn-sm"
                        >
                            Remove
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <button
                    type="button"
                    (click)="deleteMachine()"
                    class="btn btn-primary btn-sm"
                    style="color: red"
                >
                    Delete Machine
                </button>
            </div>
        </div>
        <div *ngIf="showInstance" style="margin-left: 200px">
            <app-instance-editor
                [id]="selectedInstanceId"
                (instanceDelete)="findInstances()"
            ></app-instance-editor>
        </div>
    </div>
</div>
