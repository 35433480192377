<div class="container">
    <div class="row">
        <button
            [routerLink]="['/app/admin/vcs-admin/vcs-admin-add/']"
            [queryParams]="{}"
            class="btn btn-success addVcButton"
        >
            Add Vc...
        </button>
    </div>
    <div>
        <div *ngFor="let vc of vcs" class="vcContainer row mt-1">
            <div>
                Name:
                <input
                    #vcName
                    type="text"
                    value="{{ vc['name'] }}"
                    style="width: 80%; margin-right: 5px"
                />
            </div>
            <div>
                Full Name:
                <input
                    #fullName
                    type="text"
                    value="{{ vc['fullName'] }}"
                    style="width: 80%; margin-right: 5px"
                />
            </div>
            <div>
                Active:
                <input #isActive type="checkbox" [checked]="vc['active']" />
            </div>
            <div>
                Is Aggregator?:
                <input
                    #isAggregator
                    type="checkbox"
                    [checked]="vc['isAggregator']"
                />
            </div>
            <div>
                Url:
                <input
                    #url
                    type="text"
                    value="{{ vc['url'] }}"
                    style="width: 80%; margin-right: 5px"
                    (change)="urlChange($event)"
                />
                <a #fastLink [href]="url.value" target="_blank">link...</a>
            </div>
            <div>
                Country:
                <select #countrySelect [value]="vc['country']">
                    <option
                        [ngValue]="country.name.toLowerCase()"
                        *ngFor="let country of countries"
                        [value]="country.name.toLowerCase()"
                    >
                        {{ country.name.toLowerCase() }}
                    </option>
                </select>
                <div>
                    Crawl Depth:
                    <select
                        #crawlDepthSelect
                        id="crawlDepthSelectId"
                        [value]="vc['crawlDepth']"
                    >
                        <option [value]="0">Single Page</option>
                        <option [value]="1">Index Page</option>
                        <option [value]="2">All In</option>
                    </select>
                </div>

                <div>
                    <h3>Test Page Processor:</h3>
                    <div>
                        Company Page:
                        <input
                            #companyPage
                            type="text"
                            value="{{ vc['url'] }}"
                            style="width: 80%; margin-right: 5px"
                        />
                    </div>
                    <div>
                        Expression:
                        <input
                            #pageProcessor
                            type="text"
                            value="{{ vc['pageProcessor'] }}"
                            style="width: 80%; margin-right: 5px"
                        />
                    </div>
                    <div>
                        <input
                            type="button"
                            (click)="
                                testPageProcessor(
                                    vc['name'],
                                    companyPage,
                                    pageProcessor,
                                    spinner,
                                    result
                                )
                            "
                            value="Test"
                            class="btn btn-primary"
                        />
                        <span #result></span>
                    </div>
                    <div class="spinner-border" role="status" #spinner>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div>
                    <input
                        type="button"
                        (click)="
                            crawlVC(
                                vcName.value,
                                fullName.value,
                                isActive.checked,
                                isAggregator.checked,
                                url.value,
                                countrySelect.value,
                                crawlDepthSelect.value,
                                pageProcessor.value
                            )
                        "
                        value="Save and Crawl now..."
                        class="btn btn-primary"
                    />
                    ( Last Scraped: {{ vc['lastProcessedDate'] }} )
                    <input
                        type="button"
                        (click)="
                            saveAll(
                                vcName.value,
                                fullName.value,
                                isActive.checked,
                                isAggregator.checked,
                                url.value,
                                countrySelect.value,
                                crawlDepthSelect.value,
                                pageProcessor.value
                            )
                        "
                        value="Save All"
                        class="btn btn-primary"
                    />
                    <input
                        type="button"
                        (click)="
                            saveAllAndClose(
                                vcName.value,
                                fullName.value,
                                isActive.checked,
                                isAggregator.checked,
                                url.value,
                                countrySelect.value,
                                crawlDepthSelect.value,
                                pageProcessor.value
                            )
                        "
                        value="Save All And Close"
                        class="btn btn-primary"
                    />
                    <a
                        [routerLink]="['/app/admin/startups-admin']"
                        [queryParams]="{ url: vc['url'] }"
                        >Startups...</a
                    >
                    <input
                        style="float: right"
                        type="button"
                        (click)="deleteAndExcludeVc(vc['name'], false)"
                        value="Delete"
                        class="btn btn-danger"
                    />
                    <input
                        style="float: right"
                        type="button"
                        (click)="deleteAndExcludeVc(vc['name'], true)"
                        value="Delete and Exclude"
                        class="btn btn-danger"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
