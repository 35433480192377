import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'request-card',
    templateUrl: './request-card.component.html',
    styleUrls: ['./request-card.component.scss'],
})
export class RequestCardComponent {
    @Input()  readonly: boolean;

    @Input()  value!: boolean;
    @Output() valueChange = new EventEmitter<boolean>();

    @Input() header: string;
    @Input() icon: string;
    @Input() body: string;
    @Input() footer: string;

    toggle() {
        if (this.readonly) return;
        this.value = !this.value;
        this.valueChange.emit(this.value);
    }
}
