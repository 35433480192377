<div style="display: flex; flex-direction: column">
    <div
        *ngIf="showModal"
        style="display: flex; justify-content: space-between"
    >
        <div style="height: fit-content; margin-top: 20px">
            <div class="col-8">
                <label for="country" style="color: beige">Country</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="country"
                        [(ngModel)]="country"
                        placeholder="Enter country"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('country', country)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="site" style="color: beige"
                    >'site' Google Filter</label
                >
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="site"
                        [(ngModel)]="site"
                        placeholder="Enter Site"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('site', site)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="inUrlFilter" style="color: beige"
                    >'inurl' Google Filter</label
                >
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="inUrlFilter"
                        [(ngModel)]="inUrlFilter"
                        placeholder="Enter 'inurl' google filter"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('inUrlFilter', inUrlFilter)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="titleSelector" style="color: beige"
                    >Title Selector</label
                >
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="titleSelector"
                        [(ngModel)]="titleSelector"
                        placeholder="Enter Title Selector"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('titleSelector', titleSelector)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="subTitleSelector" style="color: beige"
                    >SubTitle Selector</label
                >
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="subTitleSelector"
                        [(ngModel)]="subTitleSelector"
                        placeholder="Enter Subtitle Selector"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="
                            updateField('subTitleSelector', subTitleSelector)
                        "
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="textSelector" style="color: beige"
                    >Text Selector</label
                >
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="textSelector"
                        [(ngModel)]="textSelector"
                        placeholder="Enter Text Selector"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('textSelector', textSelector)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="logoUrl" style="color: beige">Logo URL</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="logoUrl"
                        [(ngModel)]="logoUrl"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('logoUrl', logoUrl)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
                <div
                    *ngIf="logoUrlDisplay"
                    [style.backgroundImage]="'url(' + logoUrlDisplay + ')'"
                    style="
                        width: 300px;
                        height: 300px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                    "
                ></div>
            </div>

            <button
                *ngIf="active"
                type="button"
                (click)="updateField('active', false)"
                class="btn btn-primary btn-sm"
            >
                Disable
            </button>
            <button
                *ngIf="!active"
                type="button"
                (click)="updateField('active', true)"
                class="btn btn-primary btn-sm"
            >
                Enable
            </button>

            <div>
                <button
                    type="button"
                    (click)="deleteNewspaper()"
                    class="btn btn-danger btn-sm"
                >
                    Delete Newspaper
                </button>
            </div>
        </div>
    </div>
</div>
