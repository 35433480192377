import { Component, OnInit } from '@angular/core';
import {DataService} from "../../data.service";
import {NetworkService} from "../../network.service";
import {Router} from "@angular/router";

@Component({
    selector: 'terms-page',
    templateUrl: './terms-page.component.html',
    styleUrls: ['./terms-page.component.scss'],
})
export class TermsPageComponent implements OnInit {

    constructor(
        public dataService: DataService,
        private networkService: NetworkService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        localStorage.clear();
        document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 2010 00:00:00 UTC; path=/; domain=.mysite.se';
    }


}
