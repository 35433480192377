<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="close(false)"
        ></div>
        <div class="header-title">Delete Search</div>
    </div>
    <div class="info">
        <div class="dialog-item">
        <div class="title description">Are you sure you want to delete the search?</div>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="left-side">
                <div class="button-cancel" (click)="close(false)">
                    <div class=""></div>
                    <div class="title-delete">Cancel</div>
                </div>
            </div>
            <div class="right-side">
                <div class="button-cancel" (click)="close(true)">
                    Delete
                </div>
            </div>
        </div>
    </div>
</div>
