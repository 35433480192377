import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges, OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewChild,
} from '@angular/core';
// import domtoimage from 'dom-to-image';
import {NetworkService} from '../../../network.service';
import {DataService} from '../../../data.service';
import {UtilitiesService} from '../../../utilities.service';
import {FeedbackModalData, Researcher, SearchQueryResults} from "../../../api-responses/SearchQueryResults.interface";
import {Subscription} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FeedbackModal} from "../../../pages/search-page/dialogs/feedback-modal/feedback-modal.component";
import {LinkedInResult} from "../../../api-responses/graphs.interface";
import {
    AddExpertToCalloutModalComponent
} from "../../../pages/search-page/dialogs/callout/add-expert-to-callout-modal.component";
import {CreateCalloutModalComponent} from "../../../pages/callout/create/create-callout-modal.component";
import {Callout, CreateCalloutDialogData} from "../../../pages/callout/callouts/callout.interface";

@Component({
    selector: 'app-expert-details',
    templateUrl: './expert-details.component.html',
    styleUrls: ['./expert-details.component.scss'],
})
export class ExpertDetailsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() type;
    @Input() expert: Researcher;
    @Input() expertData;
    @Input() query;
    @Input() globalSearch;
    @Input() country;
    @Input() nextLoading;
    @Input() previousLoading;

    @Input() searchResults: SearchQueryResults;
    @Input() public enableNextExpert: boolean;
    @Input() public enablePreviousExpert: boolean;

    @Output() close = new EventEmitter();
    @Output() nextExpert = new EventEmitter();
    @Output() previousExpert = new EventEmitter();

    descriptors = [];
    relevantOnly: boolean;
    expertise: boolean;
    maxLength = 45;
    exportLoading = false;

    @Input() tabActive = 0;

    index: number;

    constructor(
        public dataService: DataService,
        public networkService: NetworkService,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<AddExpertToCalloutModalComponent>,
        public newCalloutDialogRef: MatDialogRef<CreateCalloutModalComponent>,
    ) {
    }

    subscription: Subscription;

    ngOnInit() {
        this._update()
    }

    ngOnChanges(changes: SimpleChanges) {
        // if (!changes.expert.firstChange) {
        this._update();
        // }
    }

    _update() {
        this.relevantOnly = true;

        if (!this.expert) return;
        if (!this.expert.expertData) return;

        if (
            this.expert &&
            this.expert.expertData &&
            this.expert.expertData.children &&
            this.expert.expertData.children.length
        ) {
            this.expertise = true;
        } else {
            this.expertise = false;
        }

        if (this.expert && this.expert.expertData && this.expert.expertData.workedWithOthers && this.expert.expertData.workedWithOthers.length > 0) {
            this.expert.expertData.workedWithOthers =
                this.expert.expertData.workedWithOthers
                    .filter((n) => n.valueA !== '' && n.valueB !== null)
                    .slice(0, 50);
        }

        this.normalization();
        this.mergeArticles();

        this.getResearcherLinkedIn();
        this.getResearcherGoogleScholar();
    }

    ngOnDestroy(): void {
        // this.subscription.unsubscribe();
    }

    getResearcherLinkedIn() {
        if (
            !this.expert.linkedIn
        ) {
            const name = this.expert.firstName + ' ' + this.expert.lastName;
            this.networkService
                .getLinkedIn2(name, this.expert.department)
                .then((res: LinkedInResult) => {
                    // validate we have the right one
                    if (res.name === this.expert.firstName + ' ' + this.expert.lastName) {
                        this.expert.linkedIn = res.linkedInResponse;
                    }
                });
        }
    }

    getResearcherGoogleScholar() {
        if (
            !this.expert.googleScholar
        ) {
            const name = this.expert.firstName + ' ' + this.expert.lastName;
            this.networkService
                .getGoogleScholar2(name, this.expert.department)
                .then((res: LinkedInResult) => {
                    if (res.name === this.expert.firstName + ' ' + this.expert.lastName) {
                        this.expert.googleScholar = res.linkedInResponse;
                    }
                });
        }
    }

    normalization() {
        if (
            this.expert.expertData.children &&
            this.expert.expertData.children.length
        ) {
            this.descriptors = [];
            let max = 0;
            let factor = 1.0;
            for (const item of this.expert.expertData.children) {
                if (item.size > max) {
                    max = item.size;
                    factor = 100 / max;
                }

                const value = item.size * factor;
                this.descriptors.push(Math.round((value * 120) / 100));
            }
        }
    }

    mergeArticles() {
        if (
            this.expert.expertData.allPublications &&
            this.expert.expertData.allPublications.length
        ) {
            let totalArray = [];
            totalArray = totalArray.concat(
                this.expert.expertData.allPublications,
            );

            for (const pub of this.expert.expertData.matchingPublications) {
                if (totalArray.indexOf(pub) == -1) {
                    totalArray.push(pub);
                }
            }

            this.expert.expertData.allPublications = totalArray;
            this.totalArticlesCount = this.expert.expertData.allPublications.length;
        } else {
            this.totalArticlesCount = this.expert.expertData.matchingPublications.length;
        }
    }

    ellipsis(grant) {
        let str = grant.fullGrantName;
        if (str.length > this.maxLength) {
            str =
                str.substring(0, this.maxLength) +
                '...' +
                ' (' +
                grant.grantIds.length +
                ')';
            return str;
        } else {
            return str + ' (' + grant.grantIds.length + ')';
        }
    }

    ellipsisCompany(company, maxLength) {
        if (company.length > maxLength) {
            company = company.substring(0, maxLength) + '...';
        }
        return company;
    }

    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    sumGrants(grants) {
        let count = 0;
        for (const grant of grants) {
            count += grant.grantIds.length;
        }
        return count;
    }

    checkPrevious(id) {
        const len =
            id === 'grantsContent'
                ? this.expert.expertData.grants.length
                : id === 'companiesContent'
                    ? this.expert.expertData.workedWithCompanies.length
                    : this.expert.expertData.clinicalTrials.length;
        const left = document.getElementById(id).style.left;
        if (this.vw() > 900) {
            return Number(left.slice(0, left.length - 2)) === 0 || len <= 3;
        } else {
            return Number(left.slice(0, left.length - 2)) === 0 || len <= 1;
        }
    }

    checkNext(id) {
        const len =
            id === 'grantsContent'
                ? this.expert.expertData.grants.length
                : id === 'companiesContent'
                    ? this.expert.expertData.workedWithCompanies.length
                    : this.expert.expertData.clinicalTrials.length;
        const left = document.getElementById(id).style.left;
        if (this.vw() > 900) {
            return (
                Math.abs(Number(left.slice(0, left.length - 2))) ===
                (len - 3) * 181 || len <= 3
            );
        } else {
            return (
                Math.abs(Number(left.slice(0, left.length - 2))) ===
                (len - 1) * 181 || len <= 1
            );
        }
    }

    previous(id) {
        const left = document.getElementById(id).style.left;
        document.getElementById(id).style.left =
            Number(left.slice(0, left.length - 2)) + 181 + 'px';
    }

    next(id) {
        const left = document.getElementById(id).style.left;
        document.getElementById(id).style.left =
            Number(left.slice(0, left.length - 2)) - 181 + 'px';
    }

    // export() {
    //     this.exportLoading = true;
    //
    //     const expertData = {
    //         firstName: this.expert.firstName,
    //         lastName: this.expert.lastName,
    //         initials: this.expert.initials,
    //         department: this.expert.department,
    //         email: this.expertData.email,
    //         children: this.expertData.children,
    //         publications: this.relevantOnly
    //             ? this.expertData.matchingPublications
    //             : this.expertData.allPublications,
    //         grants: this.expertData.grants,
    //         workedWithCompanies: this.expertData.workedWithCompanies,
    //         patentTitlesToURLs: this.expert.patentTitlesToURLs,
    //         clinicalTrials: this.expertData.clinicalTrials,
    //         expertScore: this.expert.expertScore,
    //     };
    //
    //     // if (document.querySelector('.experties') && this.evidenceTabActive) {
    //     //     domtoimage
    //     //         .toPng(document.querySelector('.experties'), {
    //     //             bgcolor: '#fff',
    //     //         })
    //     //         .then((dataUrl) => {
    //     //             this.exportRequest(expertData, dataUrl);
    //     //         })
    //     //         .catch((error) => {
    //     //             console.error(error);
    //     //             this.exportRequest(expertData);
    //     //         });
    //     // } else {
    //     //     this.exportRequest(expertData);
    //     // }
    // }

    // exportRequest(expertData, dataUrl = null) {
    //     const date = new Date();
    //
    //     this.networkService
    //         .exportExpert(
    //             expertData,
    //             dataUrl,
    //             this.query,
    //             'Experts',
    //             this.globalSearch
    //                 ? this.country !== 'World Wide'
    //                     ? `Country (${this.country})`
    //                     : 'World Wide'
    //                 : `Campus (${this.dataService.userData.instituation})`,
    //             date,
    //         )
    //         .subscribe(
    //             (results) => {
    //                 this.exportLoading = false;
    //                 this.cdRef.detectChanges();
    //                 const options = { type: 'application/ms-excel' };
    //                 const filename = 'expert.xlsx';
    //                 this.createAndDownloadBlobFile(results, options, filename);
    //             },
    //             (error) => {
    //                 this.exportLoading = false;
    //                 this.cdRef.detectChanges();
    //                 console.log(error);
    //             },
    //         );
    // }

    // createAndDownloadBlobFile(body, options, filename) {
    //     const blob = new Blob([body], options);
    //     if (navigator.msSaveBlob) {
    //         // IE 10+
    //         navigator.msSaveBlob(blob, filename);
    //     } else {
    //         const link = document.createElement('a');
    //         // Browsers that support HTML5 download attribute
    //         if (link.download !== undefined) {
    //             const url = URL.createObjectURL(blob);
    //             link.setAttribute('href', url);
    //             link.setAttribute('download', filename);
    //             link.style.visibility = 'hidden';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     }
    // }
    public totalArticlesCount: number;

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    vw() {
        return UtilitiesService.vw();
    }

    openFeedback(expert: Researcher) {
        const data = {
            dialogTitle: "New Search",
            dialogDescription: "How would you like to perform the search?",
            searchResults: this.searchResults,
            researcher: expert,
            btnText: "SUBMIT",
            textPlaceholder: "Type more than we know to help us..."
        } as FeedbackModalData;

        let dialogRef = this.matDialog.open(FeedbackModal, {data: data});

        dialogRef.afterClosed()
            .toPromise()
            .then((result) => {

            })
    }

    getGoogleSearchUrl(department: string) {
        return UtilitiesService.getGoogleSearchUrl(department);
    }

    getPrice(expert: Researcher) {
        let str = expert.firstName + expert.lastName;
        return 525 - [...Array(str.length).keys()]
            .map((x) => str.charCodeAt(x))
            .reduce((a, b) => a + b, 0) % 75;
    }

    addToRequest(expert: Researcher) {
        this.dialogRef = this.matDialog.open(AddExpertToCalloutModalComponent, {data: expert});
    }

    newRequest(expert: Researcher) {
        this.newCalloutDialogRef = this.matDialog.open(CreateCalloutModalComponent,
            {
                data: {
                    researcher: expert
                } as CreateCalloutDialogData
            });
        this.newCalloutDialogRef && this.newCalloutDialogRef.afterClosed().toPromise().then((callout: Callout) => {
            // create the callout
            window.open('/app/callout/' + callout.id, '_blank');
        })
    }
}
