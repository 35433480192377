<div  id="top" class="saved-search-area">
    <div >

        <a class="btn-info btn-lg noResults" *ngIf="filteredExperts.length === 0">
            <span class="glyphicon glyphicon-warning-sign" style="font-family: 'Glyphicons Halflings';"></span> No results - please enter different text
        </a>

        <div [style.background]="'rgb(0, 141, 237)'" class="glossfill glossary" style="top: 131px;" >&nbsp;CLINICAL TRIAL EXPERT&nbsp;</div>
        <div [style.background]="'rgb(34, 139, 34)'" class="glossfill glossary" style="top: 154px;" >&nbsp;C.T INDUSTRY EXPERT&nbsp;</div>
        <div [style.background]="'rgb(232, 90, 78)'" class="glossfill glossary" style="top: 177px;" >&nbsp;C.T COLLABORATOR&nbsp;</div>

        <div [style.padding]="vw() <= 800 ? '175px 30px 30px' : '55px 30px 30px'">

            <div class="results">
                <div *ngFor="let expert of filteredExperts" class="expert"
                     [style.height]="patentsPresent ? '376px' : '350px'"
                     [class.selectedCard]="selectedExperts.includes(expert)"
                     (click)="expertClicked(expert)"
                     [class.exclude]="expert.exclude"
                     [class.background_card]="true"
                >
<!--                    <div *ngIf="selectedExperts.includes(expert)" class="check" style="background-image: url('images/v-symbol.png'); display: block;"></div>-->
<!--                    <div *ngIf="!selectedExperts.includes(expert)" class="check" style="background-image: url('images/v-symbol-hover.png'); background-size: 90%;"></div>-->

                    <div style="display: flex; align-items: center; justify-content: space-between;">
                        <div style="width: 100%;">
                            <div class="name ellipsis" title="" [style.color]="expert.company ? '#228B22' : expert.ctInvestigator ? '#008ded' : '#e85a4e'" (click)="expertClicked(expert)" (ngChange)="checkWidth($event, expert.lastName)" (mouseover)="checkWidth($event, expert.lastName)">
                                {{ expert.lastName }}
                            </div>

                            <div class="institute ellipsis" title="" (ngChange)="checkWidth($event, expert.department)" (mouseover)="checkWidth($event, expert.department)">
                                {{ expert.department }}
                            </div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Evidence</span>
                        <div class="bar" style="border: solid 1px #e85a4e;">
                            <div [style.background]="'#e85a4e'" [style.width]="normalize(expert.expertScore, 100, 130) + 2 + 'px'"
                                 [class.fill]="normalize(expert.expertScore, 100, 130) > 129" [class.empty]="normalize(expert.expertScore, 100, 130) === 0">
                            </div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Clinical Trials</span>
                        <div class="bar" style="border: solid 1px #b9646e;">
                            <div [style.background]="'#b9646e'" [style.width]="normalize(expert.clinicalTrialScore, 100, 130) + 2 + 'px'"
                                 [class.fill]="normalize(expert.clinicalTrialScore, 100, 130) > 129" [class.empty]="normalize(expert.clinicalTrialScore, 100, 130) === 0">
                            </div>
                        </div>
                    </div>

                    <div class="category">
                        <span class="title">Publications</span>
                        <div class="bar" style="border: solid 1px #74749d;">
                            <div [style.background]="'#74749d'" [style.width]="normalize(expert.publicationScore, 100, 130) + 2 + 'px'"
                                 [class.fill]="normalize(expert.publicationScore, 100, 130) > 129" [class.empty]="normalize(expert.publicationScore, 100, 130) === 0">
                            </div>
                        </div>
                    </div>

                    <div class="category" *ngIf="patentsPresent">
                        <span class="title">Patents</span>
                        <div class="bar" style="border: solid 1px #2e83cd;">
                            <div [style.background]="'#2e83cd'" [style.width]="normalize(expert.patentsScore, 100, 130) + 2 + 'px'"
                                 [class.fill]="normalize(expert.patentsScore, 100, 130) > 129" [class.empty]="normalize(expert.patentsScore, 100, 130) === 0">
                            </div>
                        </div>
                    </div>

                    <div class="line"></div>

                    <div class="quote_container" *ngIf="expert.quote">
                        <img src="/assets/images/innovation-icon.png" class="innovation_icon">
                        <div class="quote"> {{ trimString(expert.quote, 90) }} </div>
                    </div>

                    <div class="email_container" *ngIf="expert.email">
                        <img src="/assets/images/envelope-icon-dark.png" class="envelope_icon">
                        <a target="_blank" href="mailto:{{ expert.email }}" class="email ellipsis">{{ expert.email }}</a>
                    </div>

                    <div>
                        <button class="read_more_button" [matMenuTriggerFor]="menu" (click)="preventDefault($event)">
                            Connect
                        </button>
                        <mat-menu #menu="matMenu" >
                            <button class="px-3" mat-menu-item (click)="newRequest(expert)">New Request</button>
                            <button class="px-3" mat-menu-item (click)="addToRequest(expert)" >Add to Request...</button>
                        </mat-menu>
                    </div>
                    <exclude-include class="exclude-include" (click)="preventDefault($event)"
                                     (isExcludeChange)="shouldSave()"
                                     [hidden]="dataService.hideShared()"
                                     [(isExclude)]="expert.exclude"
                    ></exclude-include>
                </div>
            </div>
        </div>
    </div>
</div>
