import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-esri-dynamic-generator',
    templateUrl: './esri-dynamic-generator.component.html',
    styleUrls: ['./esri-dynamic-generator.component.scss'],
})
export class EsriDynamicGeneratorComponent implements OnInit {
    query: string;
    message: any;
    dashboardFound = false;
    updateMessage: any;

    scopes = ['ALL', 'ONLY_BIOMED', 'ONLY_NON_BIOMED'];

    days = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
    ];

    noResults = false;

    name: string;
    dashboardId: string;
    title: boolean;
    passwordProtected: boolean;
    itemId: string;
    expertsQuery: string;
    expertsScope: string;
    startupsQuery: string;
    corporatesQuery: string;
    clinicalTrialsQuery: string;
    itemFilename: string;
    exportFilename: string;
    interval: number;
    staticDashboards: Array<string>;
    dynamicDashboards: Array<string>;
    sendLandscapeAlerts: boolean;
    landscapeAlertInterval: number;
    landscapeAlertEmails: string;
    processDay: string;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        this.networkService.getEsriDashboards().subscribe((data) => {
            this.staticDashboards = data['staticDashboards'].map((x) => x.name);
            this.dynamicDashboards = data['dynamicDashboards'].map(
                (x) => x.name,
            );
        });
    }

    onCrate() {
        this.resetState();
        this.networkService.createEsriDashboardAdmin(this.query).subscribe(
            (data) => {
                if (data) {
                    this.handleResponse(data);
                } else {
                    this.message = data;
                }
            },
            (error) => (this.message = error.error.toString()),
        );
    }

    onSearchSubmit() {
        this.resetState();
        this.networkService.getEsriDashboardAdmin(this.query).subscribe(
            (data) => {
                if (data) {
                    this.handleResponse(data);
                } else {
                    this.noResults = true;
                    this.message =
                        'Dashboard not found. Press "Create" to create a new dashboard';
                }
            },
            (error) => (this.message = error.error.toString()),
        );
    }

    updateField(field, value, add) {
        this.networkService
            .updateEsriDashboardAdmin(this.name, field, value, add)
            .subscribe(
                (data) => this.handleResponse(data),
                (error) => {
                    console.log(error);
                    alert(error.error.toString());
                },
            );
    }

    updateDashboard() {
        if (
            confirm(
                'Are you sure to update the dashboard? This might take a long time and cannot be undone.',
            )
        ) {
            this.updateMessage = 'Running Update. Please wait...';
            this.networkService
                .executeDashboardUpdateOnEsriAdmin(this.name)
                .subscribe(
                    (data) =>
                        (this.updateMessage =
                            'Full Update Finished:' +
                            data +
                            '<br>' +
                            this.getExportLink()),
                    (error) => {
                        this.updateMessage =
                            'Full Update Failed:' + error.error.toString();
                    },
                );
        }
    }

    onDelete() {
        if (
            confirm(
                'Are you sure to delete the dashboard? This cannot be undone.',
            )
        ) {
            this.networkService.deleteEsriDashboardAdmin(this.name).subscribe(
                (data) => {
                    alert('Dashboard Deleted.');
                    this.resetState();
                },
                (error) => (this.updateMessage = error.error.toString()),
            );
        }
    }

    testDashboard() {
        if (
            confirm(
                'Are you sure to run a test for this dashboard? This cannot be undone.',
            )
        ) {
            this.updateMessage = 'Running Test Update. Please wait...';
            this.networkService
                .executeDashboardTestOnEsriAdmin(this.name)
                .subscribe(
                    (data) => {
                        this.goToLink(data);
                        this.updateMessage =
                            'Test Run Finished:' +
                            data +
                            '<br>' +
                            this.getExportLink();
                    },
                    (error) => {
                        this.updateMessage =
                            'Test Run Failed:' + error.error.toString();
                    },
                );
        }
    }

    runInnovationLandscapeAlert() {
        if (
            confirm(
                'Are you sure to run alerts for this landscape innovation? This cannot be undone.',
            )
        ) {
            this.updateMessage =
                'Running Innovation Landscape Alert. Please wait...';
            this.networkService.executeLandscapeAlert(this.name).subscribe(
                (data) => {
                    this.updateMessage = 'Landscape Innovation Run Finished.';
                },
                (error) => {
                    this.updateMessage =
                        'Landscape Innovation Alert Failed:' +
                        error.error.toString();
                },
            );
        }
    }

    goToLink(url) {
        window.open(url, '_blank');
    }

    loadDashboardLists(data) {
        this.dynamicDashboards = data.dynamicDashboards;
        this.staticDashboards = data.staticDashboards;
    }

    handleResponse(data) {
        this.name = data.name;
        this.dashboardId = data.dashboardId;
        this.title = data.title;
        this.passwordProtected = data.passwordProtected;
        this.itemId = data.itemId;
        this.expertsQuery = data.expertsQuery;
        this.expertsScope = data.expertsScope;
        this.startupsQuery = data.startupsQuery;
        this.corporatesQuery = data.corporatesQuery;
        this.clinicalTrialsQuery = data.clinicalTrialsQuery;
        this.itemFilename = data.itemFilename;
        this.exportFilename = data.exportFilename;
        this.interval = data.interval;
        this.sendLandscapeAlerts = data.sendLandscapeAlerts;
        this.landscapeAlertInterval = data.landscapeAlertInterval;
        this.landscapeAlertEmails = data.landscapeAlertEmails;
        this.processDay = data.processDay;

        this.dashboardFound = true;
    }

    resetState() {
        this.dashboardFound = false;
        this.message = null;
        this.noResults = false;
        this.updateMessage = null;

        this.name = null;
        this.dashboardId = null;
        this.title = null;
        this.passwordProtected = null;
        this.itemId = null;
        this.expertsQuery = null;
        this.expertsScope = null;
        this.startupsQuery = null;
        this.corporatesQuery = null;
        this.clinicalTrialsQuery = null;
        this.itemFilename = null;
        this.exportFilename = null;
        this.interval = null;
        this.sendLandscapeAlerts = null;
        this.landscapeAlertInterval = null;
        this.landscapeAlertEmails = null;
        this.processDay = null;
    }

    getExportLink() {
        if (this.exportFilename != null && this.exportFilename.length > 0) {
            return (
                'https://s3-eu-west-1.amazonaws.com/treebute.io/dashboard/' +
                this.exportFilename
            );
        } else {
            return '';
        }
    }

    getDownloadButtonHtmlContent() {
        const html =
            `<div style="align-items:center; display:flex; flex-direction:column; height:100vh; justify-content:center"><a href="` +
            this.getExportLink() +
            `"><img alt="" src="https://i.ibb.co/nm9tVHg/icons8-download-pie-chart-report-50.png" style="height:40px; width:40px" /></a>
    <p>&nbsp;</p>

    <p style="text-align:center"><span style="font-size:14px"><a href="` +
            this.getExportLink() +
            `">Download</a></span></p>
    </div>
    `;
        return html;
    }
}
