import { Component, OnInit, PipeTransform, ViewChild } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'startups-admin-management',
    templateUrl: './startups-admin.component.html',
    styleUrls: ['./startups-admin.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(document:keypress)': 'handleKeyboardEvent($event)',
    },
})
export class StartupsAdminComponent implements OnInit {
    startups: any = [];
    domain: string;
    review: boolean;
    enableClose: boolean;
    chosenType: string;
    @ViewChild('feedback') feedback;
    @ViewChild('name') name;
    @ViewChild('country') country;
    @ViewChild('filter') filter;
    @ViewChild('preview') preview;
    aggregator: boolean;
    excludeState: string;
    enableSave: boolean;

    constructor(
        private networkService: NetworkService,
        private route: ActivatedRoute,
    ) {
        this.review = this.route.snapshot.queryParams['review'] === 'true';
        this.enableClose = false;
        this.chosenType = '';
        this.enableSave = false;
    }

    handleKeyboardEvent(event: KeyboardEvent) {
        if (
            document.activeElement === this.filter.nativeElement ||
            document.activeElement === this.name.nativeElement ||
            document.activeElement === this.country.nativeElement
        ) {
            return;
        }

        if (event.key === '1') {
            this.setAsUnknown(this.startups[0]);
        } else if (event.key === '2') {
            this.setAsStartup(this.startups[0]);
        } else if (event.key === '3') {
            this.setAsVc(this.startups[0]);
        } else if (event.key === '4') {
            this.setAsAggregator(this.startups[0]);
        } else if (event.key === '5') {
            this.setAsHospital(this.startups[0]);
        } else if (event.key === '6') {
            this.setAsCorporate(this.startups[0]);
        } else if (event.key === '7') {
            this.save(this.startups[0]);
        } else if (event.key === '8' && this.enableClose) {
            this.close(this.startups[0]);
        } else if (event.key === '9') {
            this.excludeUrl(this.startups[0]);
        } else if (event.key === '0') {
            this.includeUrl(this.startups[0]);
        } else if (event.key === 'r') {
            this.preview.nativeElement.contentWindow.location.reload();
        } else if (event.key === 'o') {
            this.openWindow(this.startups[0]);
        }
    }

    ngOnInit() {
        this.route.queryParams.subscribe((queryParams) => {
            try {
                this.domain = queryParams['url'];
            } catch (e) {
                this.domain = '';
            }

            this.loadData();
        });
    }

    handleResponse(data) {
        // data.forEach(x => x['creationDate'] = moment(x['creationDate']).fromNow());
        data.forEach((x) => {
            if (x['creationDate']) {
                x['creationDate'] = moment(x['creationDate']).fromNow();
            }
        });
        this.startups = data;
        if (data.length > 0) {
            this.preview.nativeElement.src = this.startups[0]['homepageUrl'];
        }
    }

    excludeUrl(record) {
        const url = new URL(record['homepageUrl']);
        this.networkService.deadLink('add', url.host).subscribe(
            (data) => {
                this.excludeState = 'exclude';
                this.showOk(this.feedback.nativeElement, true);
            },
            (error) => {
                this.showOk(this.feedback.nativeElement, false);
            },
        );
    }

    includeUrl(record) {
        const url = new URL(record['homepageUrl']);
        this.networkService.deadLink('delete', url.host).subscribe(
            (data) => {
                this.excludeState = 'include';
                this.showOk(this.feedback.nativeElement, true);
            },
            (error) => {
                this.showOk(this.feedback.nativeElement, false);
            },
        );
    }

    showOk(feedbackControl, ok: boolean) {
        if (ok) {
            feedbackControl.style['color'] = 'green';
            feedbackControl.style['display'] = 'inline-block';
        } else {
            feedbackControl.style['color'] = 'red';
            feedbackControl.style['display'] = 'inline-block';
        }
        setTimeout(() => {
            feedbackControl.style['display'] = 'none';
        }, 2000);
    }

    save(startup: any) {
        if (!this.enableSave) {
            return;
        }

        this.networkService
            .saveStartup(
                startup['name'] as string,
                this.name.nativeElement.value,
                this.country.nativeElement.value,
            )
            .subscribe(
                (data) => {
                    this.showOk(this.feedback.nativeElement, true);
                    startup['name'] =
                        this.name.nativeElement.value.toLowerCase();
                    startup['country'] = this.country.nativeElement.value;
                    this.startups = this.startups.map((x) => x);
                    this.enableSave = false;
                },
                (error) => {
                    alert(error.error);
                    this.showOk(this.feedback.nativeElement, false);
                },
            );
    }

    setAsUnknown(startup: any) {
        this._setType(startup, 'UNKNOWN', false);
    }

    setAsStartup(startup: any) {
        this._setType(startup, 'STARTUP', false);
    }

    setAsVc(startup: any) {
        this._setType(startup, 'VC', false);
    }

    setAsAggregator(startup: any) {
        this._setType(startup, 'AGGREGATOR', true);
    }

    setAsHospital(startup: any) {
        // this._setType(startup, 'HOSPITAL', false);
    }

    setAsCorporate(startup: any) {
        this._setType(startup, 'CORPORATE', false);
    }

    _setType(startup: any, newType: string, aggregator: boolean) {
        this.enableClose = true;
        this.chosenType = newType;
        this.aggregator = aggregator;
    }

    // markAsDuplicateAndClose(startup: any) {
    //   this.networkService.markAsDuplicate(startup['name'] as string).subscribe(
    //     data => {
    //       this.showOk(this.feedback.nativeElement, true);
    //     },
    //     error => {
    //       this.showOk(this.feedback.nativeElement, false);
    //     }
    //   )
    // }

    close(startup: any) {
        this.networkService
            .setInstitutionType(
                startup['name'] as string,
                this.chosenType,
                this.aggregator,
            )
            .subscribe(
                (data) => {
                    startup['reviewed'] = true;
                    this.startups = this.startups.filter(
                        (x) => x['reviewed'] !== true,
                    );
                    this.enableClose = false;
                    this.chosenType = '';
                    this.aggregator = false;
                    this.preview.nativeElement.src =
                        this.startups[0]['homepageUrl'];
                    this.excludeState = '';
                    this.enableSave = false;
                    this.showOk(this.feedback.nativeElement, true);
                },
                (error) => {
                    this.showOk(this.feedback.nativeElement, false);
                },
            );
    }

    openWindow(startup: any) {
        window.open(startup['homepageUrl'], '_blank').focus();
    }

    onSearchChange(value: any) {
        this.enableSave = this.startups[0].name !== value;
    }

    searchStartups(search: string) {
        if (document.activeElement !== this.filter.nativeElement) {
            return;
        }

        if (search.length <= 2) {
            this.loadData();
        } else {
            try {
                this.networkService.findStartups(search).subscribe(
                    (data) => {
                        if (data) {
                            this.handleResponse(data);
                        }
                    },
                    (error) => {
                        console.error(error);
                    },
                );
            } catch (e) {
                console.log(e);
            }
        }
    }

    loadData() {
        if (this.review) {
            this.networkService.getStartupsForReview(this.domain).subscribe(
                (data) => {
                    if (data) {
                        this.handleResponse(data);
                    }
                },
                (error) => {
                    console.error(error);
                },
            );
        } else {
            this.networkService.getStartups(this.domain).subscribe(
                (data) => {
                    if (data) {
                        this.handleResponse(data);
                    }
                },
                (error) => console.error(error),
            );
        }
    }
}
