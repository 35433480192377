<form [formGroup]="formGroup" class="search-input">
    <div class="search-input-searchline">
        <div class="searchline-industry">
            <mat-form-field
                    class="industry-input experts"
                    [class.wide-search]="!showDropDown"
                    appearance="fill"
            >
                <textarea *ngIf="multiline"
                          [readonly]="dataService.hideShared()"
                        (change)="changeQueryVal($event)"
                        matInput
                        [style.boxSizing]="'content-box'"
                        type="text"
                        cdkTextareaAutosize
			            #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="15"
                        [(ngModel)]="query"
                        [formControl]="formGroup.controls.query"
                        placeholder="{{ placeholder ? placeholder : 'E.g stem cells' }}"
                >
                    </textarea>
                <textarea *ngIf="!multiline"
                          [readonly]="dataService.hideShared()"
                        (change)="changeQueryVal($event)"
                        matInput
                        type="text"
                        cdkTextareaAutosize
			            #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="15"
                        [(ngModel)]="query"
                        [formControl]="formGroup.controls.query"
                        placeholder="{{ placeholder ? placeholder : 'E.g stem cells' }}"
                >
                    </textarea>
<!--                <mat-icon-->
<!--                        *ngIf="query"-->
<!--                        matSuffix-->
<!--                        mat-icon-button-->
<!--                        aria-label="Clear"-->
<!--                        (click)="query = ''"-->
<!--                >clear-->
<!--                </mat-icon>-->
            </mat-form-field>
        </div>
        <tune-search *ngIf="showDropDown"
                     [(query)]="this.query"
                     [wordCloud]="getWordCloud()"
                     [searchResults]="this.searchResults"
        ></tune-search>
    </div>
    <div *ngIf="showDropDown" class="search-input-select">
        <mat-form-field
                class="industry-select"
                appearance="fill"
        >
            <mat-select [(value)]="selectedOption" [disabled]="dataService.hideShared()">
                <mat-option
                        *ngFor="let option of searchOptions"
                        [value]="option"
                        style="text-transform: capitalize"
                >{{ option }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </div>
    <div (click)="onSubmit()" class="search-input-button" [hidden]="dataService.hideShared()">
        <div class="search-icon-white"></div>
        <div class="search-text">Search</div>
    </div>
</form>
