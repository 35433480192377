<form [formGroup]="formGroup" class="search-input">
    <div class="search-input-searchline">
        <div class="searchline-experts">
            <mat-form-field
                    class="industry-input experts"
                    appearance="fill"
            >
                    <textarea
                            (change)="changeQueryVal($event)"
                            [readonly]="dataService.hideShared()"
                            matInput
                            type="text"
                            cdkTextareaAutosize
		                    #autosize="cdkTextareaAutosize"
                            [style.boxSizing]="'content-box'"

                            cdkAutosizeMinRows="3"
                            cdkAutosizeMaxRows="15"
                            [(ngModel)]="query"
                            [formControl]="formGroup.controls.query"
                            placeholder="E.g stem cells"
                    >
                    </textarea>
<!--                <mat-icon-->
<!--                        *ngIf="query"-->
<!--                        matSuffix-->
<!--                        mat-icon-button-->
<!--                        aria-label="Clear"-->
<!--                        (click)="query = ''"-->
<!--                >clear-->
<!--                </mat-icon>-->
            </mat-form-field>
        </div>
        <tune-search
                [(query)]="this.query"
                [wordCloud]="getWordCloud()"
                [searchResults]="this.searchResults"
        ></tune-search>
    </div>
    <div class="search-input-select">
        <div class="experts-searches">
            <div class="experts-searches-top">
                <mat-form-field
                        class="experts-select"
                        appearance="fill"
                >
                    <mat-select [(value)]="selectedOption"  [disabled]="dataService.hideShared()">
                        <mat-option
                                *ngFor="let option of options"
                                [value]="option"
                        >{{ getExpertTypeString(option) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field
                        class="local-search-select"
                        appearance="fill"
                >
                    <mat-select [(value)]="selectedScope" [disabled]="dataService.hideShared()">
                        <mat-option
                                *ngFor="let option of scopeOptions"
                                [value]="option"
                        >{{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="experts-searches-bottom" *ngIf="this.selectedOption == 'ACADEMIC_EXPERTS'">
                <div
                        class="experts-select-radio"
                >
                    <mat-radio-group [disabled]="dataService.hideShared()" aria-label="Select an option" [(ngModel)]="this.selectedAcademicField" [formControl]="formGroup.controls.selectedAcademicField">
                        <mat-radio-button
                                class="experts-radio-button"
                                *ngFor="let academicField of academicFields"
                                [value]="academicField.value"
                                [checked]="academicField.checked"
                                name="opList">
                            {{ academicField.name }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <div (click)="onSubmit()" class="search-input-button" [hidden]="dataService.hideShared()">
        <div class="search-icon-white"></div>
        <div class="search-text">Search</div>
    </div>
</form>
