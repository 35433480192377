import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { DepositComponent } from './pages/deposit/deposit.component';
import { D3Component } from './components/d3/d3.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AdminComponent } from './pages/admin/admin.component';
import { Ang2TagCloudModule } from 'ang2-tag-cloud';
import { GroupsManagmentComponent } from './pages/groups-managment/groups-managment.component';
import { UsersGroupCreationComponent } from './pages/groups-managment/users-group-creation/users-group-creation.component';
import { UsersGroupEditComponent } from './pages/groups-managment/users-group-edit/users-group-edit.component';
import { PubletsAutoCompleteComponent } from './pages/deposit/publets-auto-complete/publets-auto-complete.component';
import { GoogleChartComponent } from './pages/timeline/google-chart/google-chart.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { TrendingBubbleComponent } from './pages/trending-bubble/trending-bubble.component';
import { InstitutionManagerComponent } from './components/institution-manager/institution-manager.component';
import { AgmCoreModule } from '@agm/core';
import { ExplorerMapComponent } from './pages/explorer-map/explorer-map.component';
import { HttpConfigInterceptor } from './http-interceptor';

import { MachinesComponent } from './pages/machines/machines.component';
import { MachineCardComponent } from './pages/machines/machine-card/machine-card.component';
import { MachineImportComponent } from './pages/admin/machine-import/machine-import.component';
import { MachineManagementComponent } from './pages/admin/machine-management/machine-management.component';
import { MachineEditorComponent } from './pages/admin/machine-editor/machine-editor.component';
import { InstanceEditorComponent } from './pages/admin/instance-editor/instance-editor.component';
import { UserManagementComponent } from './pages/admin/user-management/user-management.component';
import { VcsAdminComponent } from './pages/admin/vcs-admin/vcs-admin.component';
import { GoogleClientTesterComponent } from './pages/admin/google-client-tester/google-client-tester.component';
import { VcsAdminAddComponent } from './pages/admin/vcs-admin/vcs-admin-add/vcs-admin-add.component';
import { StartupsAdminComponent } from './pages/admin/startups-admin/startups-admin.component';
import { MachineBookingComponent } from './pages/machines/machine-booking/machine-booking.component';
import { InvestorManagementComponent } from './pages/admin/investor-management/investor-management.component';
import { InvestorEditorComponent } from './pages/admin/investor-editor/investor-editor.component';
import { TrendingTopicsComponent } from './pages/trending-topics/trending-topics.component';
import { EsriCsvGeneratorComponent } from './pages/admin/esri-csv-generator/esri-csv-generator.component';
import { NewpaperManagementComponent } from './pages/admin/newpaper-management/newpaper-management.component';
import { NewpaperEditorComponent } from './pages/admin/newpaper-editor/newpaper-editor.component';
import { SynonymsManagementComponent } from './pages/admin/synonyms-management/synonyms-management.component';
import { SynonymsEditorComponent } from './pages/admin/synonyms-editor/synonyms-editor.component';
import { StrengthCompInsManagementComponent } from './pages/admin/strength-comp-ins-management/strength-comp-ins-management.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { AdminSearchCalibratorComponent } from './pages/admin/admin-search-calibrator/admin-search-calibrator.component';
import { EsriDynamicGeneratorComponent } from './pages/admin/esri-dynamic-generator/esri-dynamic-generator.component';
import { MyFilterPipe } from './pages/admin/startups-admin/my-filter.pipe';
import { CtPhaseToolComponent } from './pages/admin/ct-phase-tool/ct-phase-tool.component';
import { SearchToolComponent } from './pages/admin/search-tool/search-tool.component';
import { SmartSheetAdminComponent } from './pages/admin/smart-sheet-admin/smart-sheet-admin.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { LoginComponent } from './pages/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { TrendingPageComponent } from './pages/trending-page/trending-page.component';
import { ResearchTimelinePageComponent } from './pages/research-timeline-page/research-timeline-page.component';
import { StrengthMapperPageComponent } from './pages/strength-mapper-page/strength-mapper-page.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {Sidebar2Component} from "./components/sidebar2/sidebar2.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TermsPageComponent} from "./pages/terms-page/terms-page.component";
import {ProjectsModule} from "./pages/projects-page/projects.module";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {OpenAlexComponent} from "./pages/admin/open-alex/openAlex.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatMenuModule} from "@angular/material/menu";
import {
    AddExpertToCalloutModalComponent
} from "./pages/search-page/dialogs/callout/add-expert-to-callout-modal.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { CanvasModule } from './pages/canvas/canvas.module';
import {NavbarComponent} from "./components/navbar/navbar.component";
import { MarkdownModule } from 'ngx-markdown';
import { LoadingDialogComponent } from './pages/dialogs/loading-dialog/loading-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        LogoutComponent,
        DepositComponent,
        D3Component,
        ProfileComponent,
        AdminComponent,
        GroupsManagmentComponent,
        UsersGroupCreationComponent,
        UsersGroupEditComponent,
        PubletsAutoCompleteComponent,
        NavbarComponent,
        // CampusMapD3Component,
        // ExpertsComponent,
        // SearchCampusMapComponent,
        // ExpertDataComponent,
        // PublicationsListComponent,
        // GrantsListComponent,
        TrendingBubbleComponent,
        // CompaniesSearchComponent,
        // PatentsListComponent,
        InstitutionManagerComponent,
        // ExpertCardComponent,
        // ExpertDetailsComponent,
        ExplorerMapComponent,
        // FloatingSearchComponent,
        // FeedbackWindowComponent,
        MachinesComponent,
        MachineCardComponent,
        MachineImportComponent,
        MachineManagementComponent,
        MachineEditorComponent,
        InstanceEditorComponent,
        // WorldMapComponent,
        UserManagementComponent,
        VcsAdminComponent,
        GoogleClientTesterComponent,
        VcsAdminAddComponent,
        StartupsAdminComponent,
        MachineBookingComponent,
        // ExpertsCtComponent,
        InvestorManagementComponent,
        InvestorEditorComponent,
        TrendingTopicsComponent,
        EsriCsvGeneratorComponent,
        GoogleChartComponent,
        // WordCloudComponent,
        NewpaperManagementComponent,
        NewpaperEditorComponent,
        SynonymsManagementComponent,
        SynonymsEditorComponent,
        StrengthCompInsManagementComponent,
        TimelineComponent,
        UserDashboardComponent,
        AdminSearchCalibratorComponent,
        EsriDynamicGeneratorComponent,
        MyFilterPipe,
        CtPhaseToolComponent,
        SearchToolComponent,
        SmartSheetAdminComponent,
        LoginComponent,
        SidebarComponent,
        Sidebar2Component,
        RegisterPageComponent,
        TermsPageComponent,
        ForgotPasswordPageComponent,
        TrendingPageComponent,
        ResearchTimelinePageComponent,
        StrengthMapperPageComponent,
        OpenAlexComponent,
        AddExpertToCalloutModalComponent,
        LoadingDialogComponent
    ],
    imports: [
        MatMenuModule,
        MatSortModule,
        MatDialogModule,
        MatTableModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        Ang2TagCloudModule,
        GoogleChartsModule,
        NgxJsonViewerModule,
        SharedModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDhQHHLL5t5peVdj3YrEbPDLgbxx59pRC8',
        }),
        BrowserAnimationsModule,
        MatSidenavModule,
        MatListModule,
        MatTooltipModule,
        ProjectsModule,
        CanvasModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MarkdownModule.forRoot({ loader: HttpClient }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ],
    bootstrap: [AppComponent],
    exports: [
        NavbarComponent
    ]
})
export class AppModule {}
