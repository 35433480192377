import {Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {NetworkService} from "../../../../network.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {UntypedFormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NewProjectResult} from "../../../../api-responses/NewProjectResult.interface";

@Component({
    selector: 'delete-search-dialog',
    templateUrl: './delete-search-modal.component.html',
    styleUrls: ['./delete-search-modal.component.scss'],
})
export class DeleteSearchModalComponent implements OnInit, OnDestroy, OnChanges {


    constructor(private networkService: NetworkService,
                private http: HttpClient,
                private router: Router,
                public matDialog: MatDialog,
                private formBuilder: UntypedFormBuilder,
                public dialogRef: MatDialogRef<DeleteSearchModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: NewProjectResult) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    close(shouldDelete) {
        this.dialogRef.close(shouldDelete);
    }
}
