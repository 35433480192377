import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-esri-csv-generator',
    templateUrl: './esri-csv-generator.component.html',
    styleUrls: ['./esri-csv-generator.component.scss'],
})
export class EsriCsvGeneratorComponent implements OnInit {
    message = '';
    researchersFile;
    companiesFile;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {}

    btnDisabled() {
        return this.researchersFile == null || this.companiesFile == null;
    }

    updateResearchersFile(event) {
        this.researchersFile = event.target.files[0];
    }

    updateCompaniesFile(event) {
        this.companiesFile = event.target.files[0];
    }

    upload() {
        this.message = 'Uploading...';
        this.networkService
            .generateEsriCsv(this.researchersFile, this.companiesFile)
            .subscribe(
                (results) => {
                    const options = { type: 'text/csv' };
                    const filename = 'esri.csv';
                    this.createAndDownloadBlobFile(results, options, filename);
                    this.message = 'Finished.';
                },
                (error) => {
                    this.message = 'Error (see logs).';
                    console.log(error);
                },
            );
    }

    createAndDownloadBlobFile(body, options, filename) {
        const blob = new Blob([body], options);
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
    }
}
