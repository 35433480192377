<div *ngIf="project">
    <div style="display: flex">
        <app-project-card
                [readonly]="true"
                [project]="project"
        ></app-project-card>
        <div class="pt-4">
            <h2>{{ project.name }}</h2>
            <markdown
                    [data]="project.description">
            </markdown>
        </div>
    </div>
</div>
