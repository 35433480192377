import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {MatSelect} from "@angular/material/select";
import {DataService} from "../../../../data.service";

@Component({
    selector: 'tiles-search-tool',
    templateUrl: './tiles-search-tool.component.html',
    styleUrls: ['./tiles-search-tool.component.scss'],
})
export class TilesSearchToolComponent implements OnInit {

    query: string = "";
    @Input() searchResults: SearchQueryResults;

    @Input() selectedCountry: string = "";
    @Input() searchOptions: string[];
    @Output() searchSubmit = new EventEmitter();
    @Output() updatedSelectedCountry = new EventEmitter();

    formGroup = this.formBuilder.group({
        query: [''],
    });

    @ViewChild('scopeSelector') scopeSelector: MatSelect;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dataService: DataService
    ) {
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchResults) {
            this.searchResults = changes.searchResults.currentValue;
            this.query = this.searchResults.query;
            this.selectedCountry = this.query ? this.searchResults.filterCountry : 'User Scope';
        }
    }

    onSubmit() {
        this.searchSubmit.emit({
            action: "search",
            data: {
                query: this.query,
                searchType: 'TILE_SEARCH_EXPERTS',
                scope: this.selectedCountry,
                academicField: 'ALL'
            }
        })
    }

    changeQueryVal($event: Event) {
        this.query = $event.srcElement['value'];
        if (this.query === '') {
            this.selectedCountry = 'User Scope'
        }
    }

    // _updatedSelectedCountry($event) {
    //     debugger
    //     this.updatedSelectedCountry.emit($event)
    // }

}
