import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../network.service';

@Component({
    selector: 'app-groups-managment',
    templateUrl: './groups-managment.component.html',
    styleUrls: ['./groups-managment.component.scss'],
})
export class GroupsManagmentComponent implements OnInit {
    userGroups$: any;
    showGroupCreationModal = false;
    showGroupEditModal = false;
    selectedGroup: any;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        this.networkService.getUserGroups(10).subscribe(
            (data) => (this.userGroups$ = data),
            (error) => this.handleError(error),
        );
    }

    createGroupClicked() {
        this.showGroupCreationModal = true;
    }

    groupClicked(group) {
        this.selectedGroup = group;
        this.showGroupEditModal = true;
    }

    hideModalIfOpen(event) {
        if (!this.showGroupCreationModal && !this.showGroupEditModal) {
            return;
        }
        let found = false;
        event.path.forEach((path) => {
            if (path.id !== undefined && path.id.search('modal') >= 0) {
                found = true;
                return;
            }
        });
        if (!found) {
            this.hideModal();
        }
    }

    hideModal() {
        this.showGroupCreationModal = false;
        this.showGroupEditModal = false;
    }

    handleError(error) {
        if (error.url.includes('/login')) {
            this.networkService.logout();
        }
        console.log(error);
    }
}
