import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NetworkService} from "../../../../network.service";
import {DataService} from "../../../../data.service";
import {Evidence, Researcher} from "../../../../api-responses/SearchQueryResults.interface";
import {InnovationProject} from "../../../../api-responses/InnovationProjects.interface";
import {ProjectStateService} from "../../project-state-service";
import {InnovationProjectEvidenceInterface} from "../../../../api-responses/InnovationProjectEvidence.interface";
import {ChartDist, SelectedField} from "../../../../api-responses/graphs.interface";
import {ExpertsColors} from "../../../../utils/colors.enum";
import {UtilitiesService} from "../../../../utilities.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-experts-tool',
    templateUrl: './experts-tool.component.html',
    styleUrls: ['./experts-tool.component.scss'],
    providers: [ProjectStateService]
})
export class ExpertsToolComponent implements OnInit {

    project: InnovationProject;

    selectedSearchTitle: string;
    searchTitles: string[];
    evidence: InnovationProjectEvidenceInterface;
    selectedCountries: SelectedField[];

    private selectedResults: Researcher[];
    loading: boolean = false;
    subscriptions: Subscription[] = [];

    private _countryDist: ChartDist[];
    selectedExpertBackgrounds: SelectedField[];

    constructor(private networkService: NetworkService,
                private projectStateService: ProjectStateService,
                public dataService: DataService) {
    }

    ngOnInit() {
        this.loading = true;
        this.subscriptions.push(
            this.projectStateService.projectState.subscribe((project: InnovationProject) => {
                this.project = project;
                this.searchTitles = ["All"].concat(project.treebuteSearchResults.map(x => x.title));
                this.selectedSearchTitle = "All";
                this.loading = !(this.project && this.evidence);

                let index = 0;
                let values = [project.ctExpertCount, project.patentExpertCount, project.industryExpertCount, project.academicExpertCount];
                let displayTexts = ["With Clinical Trials", "Inventors", "Worked with Industry", "Academics"];
                this.selectedExpertBackgrounds = ["cts", "patents", "industry", "academic"].map(x => {
                    return {
                        text: x,
                        displayText: displayTexts[index],
                        selected: true,
                        num: values[index++]
                    } as SelectedField
                });
            }));

        this.subscriptions.push(
            this.projectStateService.evidenceStateObservable.subscribe((evidence: InnovationProjectEvidenceInterface) => {
                this.evidence = evidence;

                for (const expert of evidence.experts) {
                    expert.countries = expert.countries && expert.countries.length > 0 ? expert.countries : ['Unknown'];
                    expert.countries = expert.countries.map(x => x.toLowerCase());
                }

                this.loading = !(this.project && this.evidence);

                this.selectedCountries = this.getCountries().map(x => {
                    return {text: x.txt, displayText: x.txt, selected: true, num: x.count}
                });

                this._update(this.selectedCountries, this.selectedExpertBackgrounds);
            })
        );

        this.subscriptions.push(
            this.dataService.loadingState.subscribe((loading: boolean) => {
                this.loading = loading;
            })
        );
    }

    getCountries(): ChartDist[] {
        let list = this.evidence.experts;
        let getAttr = (x) => { return !x.exclude ? x.countries : undefined };
        return UtilitiesService.getDistributionForChart(list, getAttr);

        // if (!this._countryDist) {
        //     let countriesMap = {};
        //     this.evidence.experts.filter(x => !x.exclude).forEach(expert => {
        //         expert.countries.forEach(country => {
        //             let element = countriesMap[country.toLowerCase()];
        //             if (!element) {
        //                 countriesMap[country.toLowerCase()] = 1;
        //             } else {
        //                 countriesMap[country.toLowerCase()] += 1;
        //             }
        //         })
        //     })
        //
        //     let result: ChartDist[] = [];
        //     Object.keys(countriesMap).forEach(country => {
        //         result.push({txt: country, count: countriesMap[country]});
        //     });
        //
        //     this._countryDist = result.sort((a, b) => b.count - a.count);
        // }
        // return this._countryDist;
    }

    getColor() {
        return ExpertsColors.ACADEMIC_EXPERTS
    }

    update(selectedCountries: SelectedField[], selectedBackgrounds: SelectedField[]) {
        this.selectedCountries = selectedCountries;
        this._update(selectedCountries, selectedBackgrounds);
    }

    _update(selectedCountries: SelectedField[], selectedBackgrounds: SelectedField[]) {
        let _selectedCountries = selectedCountries.filter(x => x.selected).map(x => x.text.toLowerCase());
        let result = this.evidence.experts.filter(x => !x.exclude).filter(x => _selectedCountries.indexOf(UtilitiesService.getCountry(x).toLowerCase()) > -1);

        let result2: Researcher[] = [];
        for (const selectedBackground of selectedBackgrounds.filter(x => x.selected)) {
            if (selectedBackground.text === 'cts') {
                result2.push(...result.filter(x => x.clinicalTrials.length > 0));
            } else if (selectedBackground.text === 'patents') {
                result2.push(...result.filter(x => x.patentTitlesToURLs.length > 0));
            } else if (selectedBackground.text === 'industry') {
                result2.push(...result.filter(x => x.workedWithIndustry > 0));
            } else if (selectedBackground.text === 'academic') {
                result2.push(...result.filter(x => x.publications.length > 0));
            }
        }

        this.selectedResults = Array.from(new Set(result2)).sort(x => x.expertScore).reverse();

    }

    updateByCountry($event: SelectedField[]) {
        this.update($event, this.selectedExpertBackgrounds);
    }

    updateByBackground($event: SelectedField[]) {
        this.update(this.selectedCountries, $event);
    }

}
