import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-investor-editor',
    templateUrl: './investor-editor.component.html',
    styleUrls: ['./investor-editor.component.scss'],
})
export class InvestorEditorComponent implements OnInit {
    @Input() investor;
    @Output() investorDelete = new EventEmitter();

    showModal = false;

    id;
    name: string;
    country: string;
    url: string;
    companies;
    newCompany;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        this.showModal = true;
        this.handleResponse(this.investor);
        this.networkService.getInvestorCompanies(this.id).subscribe(
            (data) => (this.companies = data),
            (error) => {
                console.log(error);
                alert(error.error.toString());
            },
        );
    }
    handleResponse(data) {
        this.id = data.id;
        this.name = data.name;
        this.country = data.country;
        this.url = data.url;
        this.companies = data.companies;
    }

    updateField(field, value, add) {
        this.networkService
            .updateInvestorDetails(this.id, field, value, add)
            .subscribe(
                (data) => this.handleResponse(data),
                (error) => {
                    console.log(error);
                    alert(error.error.toString());
                },
            );
    }

    deleteInvestor() {
        if (confirm('Are you sure?')) {
            this.networkService.deleteInvestor(this.id).subscribe(
                (data) => {
                    this.showModal = false;
                    this.investorDelete.emit();
                },
                (error) => console.log(error),
            );
        }
    }

    enableCrawling() {
        if (confirm('Are you sure?')) {
            this.updateField('crawl', '', true);
        }
    }

    disableCrawling() {
        if (confirm('Are you sure?')) {
            this.updateField('crawl', '', false);
        }
    }
}
