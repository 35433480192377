<form [formGroup]="formGroup" class="search-input">
    <div class="search-input-searchline">
        <div class="searchline-industry">
            <mat-form-field
                    class="industry-input experts"
                    appearance="fill"
            >
                <textarea
                        (keyup)="changeQueryVal($event)"
                        (change)="changeQueryVal($event)"
                        [style.boxSizing]="'content-box'"
                        [readonly]="dataService.hideShared()"

                        matInput
                        type="text"
                        cdkTextareaAutosize
		                #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="15"
                        [(ngModel)]="query"
                        [formControl]="formGroup.controls.query"
                        placeholder="E.g stem cells"
                >
                    </textarea>
<!--                <mat-icon-->
<!--                        *ngIf="query"-->
<!--                        matSuffix-->
<!--                        mat-icon-button-->
<!--                        aria-label="Clear"-->
<!--                        (click)="query = ''"-->
<!--                >clear-->
<!--                </mat-icon>-->
            </mat-form-field>
        </div>
    </div>
    <div class="search-input-select">
        <mat-form-field
                class="industry-select"
                appearance="fill"
        >
            <mat-select [disabled]="!query" [(value)]="selectedCountry" #scopeSelector  [disabled]="dataService.hideShared()">
                <mat-option
                        *ngFor="let option of this.searchOptions"
                        [value]="option"
                        style="text-transform: capitalize"
                >{{ option }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div (click)="onSubmit()" class="search-input-button"  [hidden]="dataService.hideShared()">
        <div class="search-icon-white"></div>
        <div class="search-text">Search</div>
    </div>
</form>
