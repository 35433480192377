import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { InnovationProject } from '../../../../../api-responses/InnovationProjects.interface';
import {TreebuteProjectSummaryReducer} from "../../../../../utils/treebuteProjectSummaryReducer";
import {SearchResult} from "../../../../../api-responses/SearchResult.interface";
import {DataService} from "../../../../../data.service";
import {ReadOnlyProjectService} from "../../../../../read-only-project-service";

@Component({
    selector: 'app-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent implements OnInit {
    @Input() project: InnovationProject;
    @Input() isIndustry: boolean;
    @Output() userClickDelete = new EventEmitter();
    @Output() clickChip = new EventEmitter();
    @Output() deleteSearch = new EventEmitter<number>();

    public showHintCreateNew: boolean = true;

    constructor(public readOnlyProjectService: ReadOnlyProjectService,
                public dataService: DataService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.project.currentValue) {
            this.showHintCreateNew =
                changes.project.currentValue.treebuteSearchResults &&
                changes.project.currentValue.treebuteSearchResults.length == 0;
        }
    }

    getSearches() {
        return this.project.treebuteSearchResults;
        // return this.project.treebuteSearchResults.filter(x => this.isIndustry ?
        //     TreebuteProjectSummaryReducer.isIndustry(x) :
        //     !TreebuteProjectSummaryReducer.isIndustry(x))
    }

    getHash(){

    }

}
