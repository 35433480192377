import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {YesNoModalData} from "../../../api-responses/Dialogs.interface";

@Component({
    selector: 'yes-no-modal',
    templateUrl: './yes-no-modal.component.html',
    styleUrls: ['./yes-no-modal.component.scss'],
})
export class YesNoModalComponent implements OnInit {


    constructor(public dialogRef: MatDialogRef<YesNoModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: YesNoModalData) {
    }

    ngOnInit(): void {
    }

    cancel() {
        this.dialogRef.close('cancel');
    }

    no() {
        this.dialogRef.close('no');
    }

    yes() {
        this.dialogRef.close('yes');
    }

    getYesText() {
        return this.data.yesText ? this.data.yesText : 'Yes';
    }

    getNoText() {
        return this.data.noText ? this.data.noText : 'No';
    }

    getCancelText() {
        return this.data.cancelText ? this.data.cancelText : 'Cancel';
    }

    close() {
        this.dialogRef.close();
    }
}
