<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title">Share your knowledge with us!</div>
        <div class="header-subtitle">
            <div class="line">
                {{ getDescription() }}
            </div>
        </div>
    </div>
        <div *ngIf="this.data.researcher !== undefined" id="feedbackFields">
        <span>
            Please select the relevant fields, you can select more than one
        </span>
        <div *ngFor="let field of selectedFields" class="field">
            <input type="checkbox" class="fieldCheckbox" [(ngModel)]="field.selected"/>
            <div class="fieldTitle">{{ field.text }}</div>
        </div>
        </div>
    <div id="textAreaContainer">
        <textarea
                placeholder="{{ this.data.textPlaceholder }}"
                [(ngModel)]="message"
        ></textarea>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="button-cancel" (click)="closeModal()">
                Cancel
            </div>
            <div class="button-save" (click)="sendFeedback()">
                {{ this.data.btnText }}
            </div>
        </div>
    </div>
</div>
