<div id="feedbackWindow">
    <header>
        <div *ngIf="expert === undefined" class="title">SEND FEEDBACK</div>
        <div *ngIf="expert !== undefined" class="title">
            SHARE WITH US YOUR KNOWLEDGE
        </div>
        <img
            src="assets/images/close-dark.png"
            class="closeIcon"
            (click)="this.closeModal.emit()"
        />
    </header>

    <div *ngIf="expert !== undefined" id="subtitle">
        <span id="feedbackQuery"> {{ query }} </span> >
        <span id="feedbackExpert"> {{ expert }} </span>
    </div>

    <div *ngIf="expert !== undefined" id="feedbackFields">
        <span>
            Please select the relevant fields, you can select more than one
        </span>

        <div
            *ngFor="let field of fields"
            class="field"
            (click)="fieldClicked(field)"
        >
            <div
                class="fieldCheckbox"
                [style.backgroundImage]="
                    selectedFields.includes(field)
                        ? 'url(assets/images/feedback-checkbox-checked.png)'
                        : 'none'
                "
            ></div>
            <div class="fieldTitle">{{ field }}</div>
        </div>
    </div>

    <textarea
        *ngIf="expert !== undefined"
        placeholder="Type more than we know to help us..."
        [(ngModel)]="message"
    ></textarea>
    <textarea
        *ngIf="expert === undefined"
        placeholder="Describe your issue or share your ideas..."
        [(ngModel)]="message"
    ></textarea>

    <footer>
        <button
            *ngIf="expert !== undefined"
            id="sendBtn"
            (click)="sendFeedback()"
        >
            <div>SUBMIT</div>
        </button>
        <button
            *ngIf="expert === undefined"
            id="sendBtn"
            (click)="sendFeedback()"
        >
            <div>SEND</div>
        </button>
    </footer>
</div>
