<div *ngIf="loading">
    <div class="loader"></div>
</div>

<div id="container" *ngIf="!loading">
    <img
        src="assets/images/close-dark.png"
        class="closeIcon"
        (click)="this.closeModal.emit(); stopPolling()"
    />

    <div
        *ngIf="
            paymentDone &&
            paymentSucceeded &&
            !paymentDisplayFailed &&
            !pelecardLoading &&
            step === 2
        "
        id="landingPage"
    >
        <div>Thank you!</div>
    </div>

    <div
        *ngIf="
            paymentDone &&
            !paymentSucceeded &&
            !paymentDisplayFailed &&
            !pelecardLoading &&
            step === 2
        "
        id="landingPage"
    >
        <div>Payment failed, please contact us.</div>
    </div>

    <div
        *ngIf="paymentDisplayFailed && !pelecardLoading && step === 2"
        id="landingPage"
    >
        <div>Something went wrong, please try again.</div>
    </div>

    <div id="left" *ngIf="!isLandingPageVisible()">
        <div class="title">Machine Order</div>
        <div *ngIf="step === 1" class="subtitle">Service Details</div>
        <div *ngIf="step === 2" class="subtitle">Personal Details</div>

        <div class="circle first stepComplete">1</div>
        <div class="circle second" [class.stepComplete]="step === 2">2</div>

        <div *ngIf="step === 1" class="detailsContainer">
            <div>
                <div *ngIf="machine.name" class="detail">
                    <div class="detailTitle">name</div>
                    <div
                        class="detailContent ellipsis"
                        (ngChange)="checkWidth($event, machine.name)"
                        (mouseover)="checkWidth($event, machine.name)"
                        title=""
                    >
                        {{ machine.name }}
                    </div>
                </div>

                <div *ngIf="machine.model" class="detail">
                    <div class="detailTitle">model</div>
                    <div
                        class="detailContent ellipsis"
                        (ngChange)="checkWidth($event, machine.model)"
                        (mouseover)="checkWidth($event, machine.model)"
                        title=""
                    >
                        {{ machine.model }}
                    </div>
                </div>

                <div *ngIf="machine.make" class="detail">
                    <div class="detailTitle">make</div>
                    <div
                        class="detailContent ellipsis"
                        (ngChange)="checkWidth($event, machine.make)"
                        (mouseover)="checkWidth($event, machine.make)"
                        title=""
                    >
                        {{ machine.make }}
                    </div>
                </div>

                <div
                    *ngIf="this.minimumOperationDuration !== ''"
                    class="detail"
                >
                    <div class="detailTitle">minimum duration</div>
                    <div
                        class="detailContent ellipsis"
                        (ngChange)="
                            checkWidth($event, this.minimumOperationDuration)
                        "
                        (mouseover)="
                            checkWidth($event, this.minimumOperationDuration)
                        "
                        title=""
                    >
                        {{ this.minimumOperationDuration }}
                    </div>
                </div>
            </div>

            <div id="machineImg">
                <div
                    [style.backgroundImage]="
                        machine.imageUrl
                            ? 'url(' + machine.imageUrl + ')'
                            : 'url(assets/images/machine.png)'
                    "
                    [style.backgroundSize]="machine.imageUrl ? 'cover' : 'auto'"
                    class="innerImg"
                ></div>
            </div>

            <div
                *ngIf="machine.institution"
                style="display: flex; align-items: center"
            >
                <div
                    class="institutionIcon"
                    [style.backgroundImage]="
                        machine.institution['iconUrl']
                            ? 'url(' + machine.institution['iconUrl'] + ')'
                            : 'url(assets/images/university.png)'
                    "
                ></div>
                <div class="institutionName" style="line-height: initial">
                    {{ machine.institution['name'] }}
                </div>
            </div>
        </div>

        <div *ngIf="step === 2" class="detailsStep2">
            <div class="container my-5" style="width: 100%">
                <div class="row">
                    <div class="col-md-6 mx-auto">
                        <div class="form-group">
                            <input
                                type="text"
                                id="firstName"
                                class="form-control"
                                required
                            />
                            <label
                                class="form-control-placeholder"
                                for="firstName"
                                >First Name</label
                            >
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                id="lastName"
                                class="form-control"
                                required
                            />
                            <label
                                class="form-control-placeholder"
                                for="lastName"
                                >Last Name</label
                            >
                        </div>
                    </div>

                    <div class="col-md-6 mx-auto">
                        <div class="form-group">
                            <input
                                type="text"
                                id="companyName"
                                class="form-control"
                                required
                            />
                            <label
                                class="form-control-placeholder"
                                for="companyName"
                                >Company name</label
                            >
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                id="phone"
                                class="form-control"
                                required
                            />
                            <label class="form-control-placeholder" for="phone"
                                >Phone</label
                            >
                        </div>
                    </div>

                    <div class="col-md-6 mx-auto">
                        <div class="form-group" style="width: 100%">
                            <input
                                type="text"
                                id="email"
                                class="form-control"
                                required
                            />
                            <label class="form-control-placeholder" for="email"
                                >Email</label
                            >
                        </div>
                    </div>

                    <div class="col-md-6 mx-auto">
                        <div class="form-group">
                            <input
                                type="text"
                                id="country"
                                class="form-control"
                                required
                            />
                            <label
                                class="form-control-placeholder"
                                for="country"
                                >Country</label
                            >
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                id="city"
                                class="form-control"
                                required
                            />
                            <label class="form-control-placeholder" for="city"
                                >City</label
                            >
                        </div>
                    </div>

                    <div class="col-md-6 mx-auto">
                        <div class="form-group" style="width: 100%">
                            <input
                                type="text"
                                id="address"
                                class="form-control"
                                required
                            />
                            <label
                                class="form-control-placeholder"
                                for="address"
                                >Address</label
                            >
                        </div>
                    </div>
                </div>

                <div id="tellUsMore">
                    <div id="tellUsMoreTitle">Tell us More</div>
                    <textarea
                        id="tellUsMoreTextbox"
                        placeholder="Write your comment here..."
                    ></textarea>
                </div>
            </div>
        </div>
    </div>

    <div
        id="right"
        [style.backgroundImage]="'url(assets/images/booking_bg_dark.png)'"
        *ngIf="!isLandingPageVisible()"
    >
        <div *ngIf="step === 1" class="title">Service</div>
        <div *ngIf="step === 2" class="title">Payment</div>

        <div *ngIf="step === 2" id="iframeTopContainer">
            <div
                style="
                    width: 100%;
                    height: 64px;
                    box-shadow: 0 0 13px 0 rgba(0, 141, 237, 0.41);
                    background-color: #2e83cd;
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                "
            >
                <div
                    style="
                        font-size: 13px;
                        line-height: 1.08;
                        letter-spacing: 0.5px;
                        color: #ebebeb;
                        font-family: -apple-system, BlinkMacSystemFont, Segoe UI,
                            Helvetica, Arial, sans-serif, Apple Color Emoji,
                            Segoe UI Emoji, Segoe UI Symbol;
                    "
                >
                    {{ day }} {{ moment.format('MMMM') }}, {{ moment.year() }}
                    <span
                        style="
                            font-style: italic;
                            font-size: 11px;
                            font-family: inherit;
                            margin-left: 5px;
                        "
                    >
                        {{ fullDaysOfWeek[moment.weekday()] }}
                    </span>
                </div>
                <div
                    style="
                        font-size: 13px;
                        line-height: 1.08;
                        letter-spacing: 0.5px;
                        color: #ebebeb;
                        font-family: -apple-system, BlinkMacSystemFont, Segoe UI,
                            Helvetica, Arial, sans-serif, Apple Color Emoji,
                            Segoe UI Emoji, Segoe UI Symbol;
                    "
                >
                    <span
                        style="
                            color: #000000;
                            font-family: inherit;
                            margin-right: 3px;
                        "
                    >
                        Start test:
                    </span>
                    {{ startTime[0] }}:{{ startTime[1] }} {{ startTime[2] }}
                    <span
                        style="
                            color: #000000;
                            margin-left: 15px;
                            font-family: inherit;
                            margin-right: 3px;
                        "
                    >
                        End test:
                    </span>
                    {{ endTime[0] }}:{{ endTime[1] }} {{ endTime[2] }}
                </div>
            </div>

            <div id="paymentContainer">
                <div *ngIf="pelecardLoading">
                    <div class="paymentLoader"></div>
                </div>

                <div
                    *ngIf="!paymentDone && iframeUrl"
                    [class.iframeHidden]="
                        !paymentDone && iframeUrl && pelecardLoading
                    "
                    id="iframeContainer"
                >
                    <iframe
                        style="width: 100%; height: 100%; border: none"
                        [src]="iframeUrl"
                    ></iframe>
                </div>
            </div>
        </div>

        <div *ngIf="step === 1" class="calendarControls">
            <div class="dayControl" style="width: 150px">
                <button
                    (click)="
                        checkClass($event, 'disabled')
                            ? none
                            : updateCalendar('previous', 'days')
                    "
                    [class.disabled]="
                        checkIfControlsDisabled('previous', 'days')
                    "
                    [style.backgroundImage]="'url(assets/images/booking_arrow.png)'"
                    style="
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    "
                ></button>
                <span style="width: calc(100% - 36px); text-align: center">
                    {{ day }}, {{ fullDaysOfWeek[moment.weekday()] }}
                </span>
                <button
                    (click)="
                        checkClass($event, 'disabled')
                            ? none
                            : updateCalendar('next', 'days')
                    "
                    [class.disabled]="checkIfControlsDisabled('next', 'days')"
                    [style.backgroundImage]="'url(assets/images/booking_arrow.png)'"
                ></button>
            </div>

            <div class="monthControl" style="width: 160px">
                <button
                    (click)="
                        checkClass($event, 'disabled')
                            ? none
                            : updateCalendar('previous', 'months')
                    "
                    [class.disabled]="
                        checkIfControlsDisabled('previous', 'months')
                    "
                    [style.backgroundImage]="'url(assets/images/booking_arrow.png)'"
                    style="
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    "
                ></button>
                <span style="width: calc(100% - 36px); text-align: center">
                    {{ moment.format('MMMM') }} {{ moment.year() }}
                </span>
                <button
                    (click)="updateCalendar('next', 'months')"
                    [style.backgroundImage]="'url(assets/images/booking_arrow.png)'"
                ></button>
            </div>
        </div>

        <div
            *ngIf="step === 1"
            style="
                width: 385px;
                height: 252px;
                margin: 0 auto;
                border-bottom: solid 1px rgba(78, 78, 78, 0.8);
                padding-bottom: 20px;
            "
        >
            <div
                style="
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                "
            >
                <div *ngFor="let d of daysOfWeek" class="dayTitle">{{ d }}</div>
            </div>

            <div style="display: flex; flex-wrap: wrap; align-items: center">
                <div
                    *ngFor="let d of daysOfMonth"
                    class="dayNumber"
                    [class.selectedDay]="d == day"
                    [class.disabledDay]="unavailableDays.includes(d)"
                    (click)="
                        checkClass($event, 'disabled') || d == day
                            ? none
                            : setDate(d)
                    "
                    [class.disabled]="d == '' || checkIfDayPassed(d)"
                >
                    {{ d }}
                    <div
                        class="dot"
                        *ngIf="partiallyAvailableDays.includes(d)"
                    ></div>
                </div>
            </div>
        </div>

        <div *ngIf="step === 1" id="hoursSelection">
            <div style="width: 50%; display: flex; padding-right: 1px">
                <div class="hoursSelectionTitle">Start test:</div>

                <div
                    id="startTimeDropdown"
                    class="dropDown"
                    (click)="
                        dropdownClicked('startTimeDropdown', 'startDropDownBtn')
                    "
                >
                    <div class="dropDownHeader">
                        <div>
                            {{ startTime[0] }}:{{ startTime[1] }}
                            {{ startTime[2] }}
                        </div>
                        <button
                            id="startDropDownBtn"
                            class="dropDownBtn"
                            [style.backgroundImage]="
                                'url(assets/images/booking_arrow.png)'
                            "
                        ></button>
                    </div>

                    <div class="dropDownMenu">
                        <div *ngFor="let hour of hours">
                            <div
                                *ngFor="let i of ['00', '15', '30', '45']"
                                class="dropDownHour"
                                id="startTimeDropdown{{ hour }},{{ i }}"
                                (click)="
                                    !checkIfHourTaken('start', [
                                        ('0' + hour[0]).slice(-2),
                                        i,
                                        hour[1]
                                    ])
                                        ? hourClicked('startTime', [
                                              ('0' + hour[0]).slice(-2),
                                              i,
                                              hour[1]
                                          ])
                                        : null
                                "
                                [class.dropDownHourSelected]="
                                    arraysEqual(startTime, [
                                        ('0' + hour[0]).slice(-2),
                                        i,
                                        hour[1]
                                    ])
                                "
                                [class.dropDownHourTaken]="
                                    checkIfHourTaken('start', [
                                        ('0' + hour[0]).slice(-2),
                                        i,
                                        hour[1]
                                    ])
                                "
                            >
                                <span
                                    >{{ ('0' + hour[0]).slice(-2) }}:{{
                                        i
                                    }}</span
                                >
                                <span style="margin-left: 8px">{{
                                    hour[1]
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                style="
                    width: 50%;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 3px;
                "
            >
                <div class="hoursSelectionTitle">End test:</div>

                <div
                    id="endTimeDropdown"
                    class="dropDown"
                    (click)="
                        dropdownClicked('endTimeDropdown', 'endDropDownBtn')
                    "
                >
                    <div class="dropDownHeader">
                        <div>
                            {{ endTime[0] }}:{{ endTime[1] }} {{ endTime[2] }}
                        </div>
                        <button
                            id="endDropDownBtn"
                            class="dropDownBtn"
                            [style.backgroundImage]="
                                'url(assets/images/booking_arrow.png)'
                            "
                        ></button>
                    </div>

                    <div class="dropDownMenu">
                        <div *ngFor="let hour of hours">
                            <div
                                *ngFor="let i of ['00', '15', '30', '45']"
                                class="dropDownHour"
                                id="endTimeDropdown{{ hour }},{{ i }}"
                                [class.hourHidden]="
                                    arraysEqual(
                                        [('0' + hour[0]).slice(-2), i, hour[1]],
                                        ['12', '00', 'am']
                                    )
                                "
                                (click)="
                                    !checkIfHourTaken('end', [
                                        ('0' + hour[0]).slice(-2),
                                        i,
                                        hour[1]
                                    ])
                                        ? hourClicked('endTime', [
                                              ('0' + hour[0]).slice(-2),
                                              i,
                                              hour[1]
                                          ])
                                        : null
                                "
                                [class.dropDownHourSelected]="
                                    arraysEqual(endTime, [
                                        ('0' + hour[0]).slice(-2),
                                        i,
                                        hour[1]
                                    ])
                                "
                                [class.dropDownHourTaken]="
                                    checkIfHourTaken('end', [
                                        ('0' + hour[0]).slice(-2),
                                        i,
                                        hour[1]
                                    ])
                                "
                            >
                                <span
                                    >{{ ('0' + hour[0]).slice(-2) }}:{{
                                        i
                                    }}</span
                                >
                                <span style="margin-left: 8px">{{
                                    hour[1]
                                }}</span>
                            </div>
                        </div>

                        <div>
                            <div
                                class="dropDownHour"
                                (click)="
                                    !checkIfHourTaken('end', ['12', '00', '+1'])
                                        ? hourClicked('endTime', [
                                              '12',
                                              '00',
                                              '+1'
                                          ])
                                        : null
                                "
                                [class.dropDownHourSelected]="
                                    arraysEqual(endTime, ['12', '00', '+1'])
                                "
                                [class.dropDownHourTaken]="
                                    checkIfHourTaken('end', ['12', '00', '+1'])
                                "
                            >
                                <span>12:00</span>
                                <span style="margin-left: 10px">+1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                style="
                    height: 20px;
                    width: 20px;
                    margin-left: 15px;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                "
                [style.opacity]="
                    endTime[0] !== '??' || startTime[0] !== '??' ? 1 : 0.3
                "
                [style.cursor]="
                    endTime[0] !== '??' || startTime[0] !== '??'
                        ? 'pointer'
                        : 'default'
                "
                [style.backgroundImage]="'url(assets/images/reset-dark.png)'"
                (click)="
                    endTime[0] !== '??' || startTime[0] !== '??'
                        ? updateCalendar('update', 'days')
                        : null
                "
            ></div>
        </div>

        <div
            *ngIf="step === 1"
            id="hoursSelectionComment"
            [class.hiddenComment]="
                !minimumDurationComment &&
                !validTimeComment &&
                !notAvailableComment
            "
        >
            {{
                minimumDurationComment
                    ? 'The minimum duration for this machine is ' +
                      minimumOperationDuration
                    : validTimeComment
                    ? 'Please select a valid time period'
                    : notAvailableComment
                    ? 'Please select a time when the machine is available'
                    : 'hiddenComment'
            }}
        </div>

        <div *ngIf="step === 1" class="hoursButtons">
            <button
                (click)="previous('hoursContent')"
                [disabled]="checkPrevious('hoursContent')"
            >
                <img
                    src="assets/images/prev-year.png"
                    [class.disabled]="checkPrevious('hoursContent')"
                />
            </button>

            <button
                (click)="next('hoursContent')"
                [disabled]="checkNext('hoursContent')"
            >
                <img
                    src="assets/images/next-year.png"
                    [class.disabled]="checkNext('hoursContent')"
                />
            </button>
        </div>

        <div *ngIf="step === 1" class="hoursContainer">
            <div
                *ngIf="endTime[0] !== '??' && startTime[0] !== '??'"
                id="bracketBlueStart"
                [style.left]="calcBracketPosition('start', 'left')"
                class="bracketBlue"
                [style.backgroundImage]="'url(assets/images/start_hour.png)'"
            ></div>
            <div
                *ngIf="endTime[0] !== '??' && startTime[0] !== '??'"
                id="blueBackground"
                [style.left]="calcBracketPosition('start', 'left')"
                [style.width]="calcBracketPosition('start', 'width')"
                class="blueBg"
            ></div>
            <div
                *ngIf="endTime[0] !== '??' && startTime[0] !== '??'"
                id="bracketBlueEnd"
                [style.left]="calcBracketPosition('end', 'left')"
                class="bracketBlue"
                [style.backgroundImage]="'url(assets/images/end_hour.png)'"
            ></div>

            <div id="hoursContent">
                <div class="hour" *ngFor="let hour of hours">
                    <div class="hourLines">
                        <div id="{{ hour[2] }}:0" class="line firstLine"></div>
                        <div id="{{ hour[2] }}:15" class="line thirdLine"></div>
                        <div
                            id="{{ hour[2] }}:30"
                            class="line secondLine"
                        ></div>
                        <div id="{{ hour[2] }}:45" class="line thirdLine"></div>
                    </div>

                    <div class="hourTitle">
                        <span>{{ hour[0] }}</span>
                        <span style="font-size: 8px; font-weight: normal">{{
                            hour[1]
                        }}</span>
                    </div>
                </div>

                <div class="hour">
                    <div class="hourLines">
                        <div id="24:0" class="line firstLine"></div>
                    </div>

                    <div class="hourTitle">
                        <span>12</span>
                        <span style="font-size: 8px; font-weight: normal"
                            >+1</span
                        >
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="step === 1" class="legend">
            <div>
                <div
                    style="
                        width: 9px;
                        height: 9px;
                        margin-right: 6px;
                        background-color: #008ded;
                    "
                ></div>
                Your selection
            </div>
            <div>
                <div
                    style="
                        width: 9px;
                        height: 9px;
                        margin-right: 6px;
                        background-color: #ff0000;
                    "
                ></div>
                Unavailable
            </div>
        </div>

        <div class="buttons" [class.buttonsWidth]="step === 1">
            <button
                class="btnBack"
                [class.btnDisabled]="step === 1"
                (click)="previousStep()"
            >
                <span
                    style="
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                        margin-right: 5px;
                    "
                ></span>
                Back
            </button>
            <button
                *ngIf="step === 1"
                class="btnNext"
                [class.btnDisabled]="
                    endTime[0] === '??' || startTime[0] === '??'
                "
                (click)="nextStep()"
            >
                Next
                <span
                    style="
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        margin-left: 5px;
                    "
                ></span>
            </button>
        </div>
    </div>
</div>
