import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {getSearchType, SearchTypes} from "../../api-responses/SearchTypes.interface";
import {Location} from "@angular/common";
import {NetworkService} from "../../network.service";
import {CountriesDataService} from "../../shared/CountriesDataService";
import {
    Institution,
    InstitutionResult,
    Researcher,
    SearchQueryResults, SearchQueryResultsAsync,
    WordCloud
} from "../../api-responses/SearchQueryResults.interface";
import {Subscription} from "rxjs";
import {TreebuteProjectSummaryReducer} from "../../utils/treebuteProjectSummaryReducer";
import {SearchPageUtils} from "./common/searchPageUtils";
import {SaveSearchDialogComponent} from "./dialogs/save-search-dialog/save-search-dialog.component";
import {NewSearchModalComponent} from "./dialogs/new-search-modal/new-search-modal.component";
import {YesNoModalComponent} from "../dialogs/yesNo/yes-no-modal.component";
import {YesNoModalData} from "../../api-responses/Dialogs.interface";
import {UtilsComponent} from "../../utils/utils.component";
import {TileBreadCrumb} from "./tile-search/tile-bread.crumb";
import {DataService, NavRequest} from "../../data.service";
import {take} from "rxjs/operators";
import {ProjectStateService} from "../projects-page/project-state-service";
import {innovationProjects} from "../../../msw/mocks/innovationProjects";
import {InnovationProject} from "../../api-responses/InnovationProjects.interface";
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component';
import { data } from 'msw/lib/types/context';

@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
    providers: [ProjectStateService]
})
export class SearchPageComponent implements OnInit, OnDestroy, OnChanges {
    private searchUuid: string;

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    @Input() filterHasGrants: boolean = false;

    public projectId: number;
    searchResults: SearchQueryResults;

    isIndustry: boolean = true;
    isExperts: boolean = false;
    isKnowledge: boolean = false;

    public query: string = '';
    public queryTiles: string = '';
    industryOption: SearchTypes;

    private routeSub: Subscription;
    // companies: Institution[];
    // experts: Researcher[];
    wordCloud: WordCloud;

    pisOnly: boolean = false;
    youngLeadersOnly: boolean = false;
    youngTalentsOnly: boolean = false;
    influencerOnly: boolean = false;
    potentialCollaborators: boolean = false;
    private tileData: any;
    tilesShowSearchResults: boolean = false;

    public loading: boolean = true;
    breadCrumbsStack: TileBreadCrumb[] = [];
    subscriptions: Subscription[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private router: Router,
        public matDialog: MatDialog,
        private networkService: NetworkService,
        private countriesDataService: CountriesDataService,
        private ref: ChangeDetectorRef,
        private searchPageUtils: SearchPageUtils,
        private utilsComponent: UtilsComponent,
        public dataService: DataService,
        public projectStateService: ProjectStateService
    ) {

    }

    ngOnInit() {
// this.routeSub = this.activatedRoute.params.subscribe((params) => {
        //     if (params.projectId && params.projectId.length > 0) {
        //         this.projectId = parseInt(params.projectId);
        //     }
        // });
        //
        // this.projectId = Number(this.activatedRoute.snapshot.paramMap.get('projectId'));
        // this.searchId = Number(this.activatedRoute.snapshot.paramMap.get('searchId'));
        //
        // if (this.projectId !== 0 && this.searchId !== 0) {
        //     this.activatedRoute.fragment
        //         .pipe(
        //             filter(
        //                 (fragment) =>
        //                     fragment && fragment.indexOf('show') === 0,
        //             ),
        //             take(1),
        //         )
        //         .toPromise()
        //         .then(() => {
        //             // this.openDialog();
        //         });
        // }

        // phase 1: take project
        this.subscriptions.push(
            this.projectStateService.projectState.subscribe((project => {
                if (!project) return;
                this.projectId = (project as InnovationProject).id;
                this.loading = false;

                // phase 2: take search
                this.subscriptions.push(
                    this.projectStateService.searchIdStateObservable.subscribe(searchId => {
                        if (!searchId) return;
                        // if this is already set, don't use the change in url
                        if (this.searchResults && this.searchResults.id) return;

                        this.loading = true;
                        this.networkService.searches
                            .getSearchById(searchId.toString(), true)
                            .then((res) => {
                                // get the id from the url
                                let url = this.router.url;
                                let suffixIndex = url.lastIndexOf("/");
                                let idFromUrl = Number(url.slice(suffixIndex+1));

                                // are the urls different ?
                                let differentIds = idFromUrl !== res.id;

                                // if different - replace the search id
                                if (differentIds) {
                                    this.location.replaceState(`/app/projects/${this.projectId}/search/${res.id}`);
                                }

                                this.searchResults = res;
                                this.query = res.query;
                                this.industryOption = getSearchType(res.searchType);
                                if (this.industryOption == 'TILE_SEARCH_EXPERTS') {
                                    this.queryTiles = res.query;
                                }
                                this.update();
                                this.loading = false;
                            })
                            .catch(x => {
                                this.loading = false;
                            });
                    }));
            })));

        this.subscriptions.push(
            this.projectStateService.otherPageStateObservable.subscribe((otherPage) => {
                if (otherPage) {
                    setTimeout(() => this.loading = false, 0);
                }
            })
        );

        this.subscriptions.push(
            this.dataService.loadingState.subscribe((loading:boolean) => {
                setTimeout(() => this.loading = loading, 0);
            })
        );

        // if search id exists - fetch from save searches
        // this.routeSub = this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
        //     if (params.projectId && params.projectId.length > 0) {
        //         this.projectId = parseInt(params.projectId);
        //     }
        //
        //     if (this.projectId) {
        //         this.networkService.getProjectById(this.projectId.toString()).toPromise().then((project: InnovationProject) => {
        //             this.dataService.project = project;
        //             let searchId = parseInt(params.searchId);
        //             this.routeStateService.updatePathParamState({'projectId': this.projectId, 'searchId': searchId});
        //
        //             if (!searchId) {
        //                 this.loading = false;
        //                 return;
        //             }
        //
        //             this.loading = true;
        //             this.networkService.searches
        //                 .getSearchById(params.searchId)
        //                 .toPromise()
        //                 .then((res) => {
        //                     this.searchResults = res;
        //                     this.query = res.query;
        //                     this.industryOption = getSearchType(res.searchType);
        //                     if (this.industryOption == 'TILE_SEARCH_EXPERTS') {
        //                         this.queryTiles = res.query;
        //                     }
        //                     this.update();
        //                     this.loading = false;
        //                 })
        //                 .catch(x => {
        //                     this.loading = false;
        //                 });
        //         })
        //     } else {
        //         this.routeStateService.updatePathParamState({'projectId': this.projectId});
        //         this.isIndustry = true;
        //         this.dataService.project = undefined;
        //         this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
        //             const query = params.get('query');
        //             const searchType = params.get('searchType');
        //             if (query) {
        //                 this.query = query;
        //                 // this.search({
        //                 //     data: {
        //                 //         query,
        //                 //         searchType,
        //                 //         scope: 'world',
        //                 //         academicField: 'ALL',
        //                 //         descriptorParentIds: '',
        //                 //         projectId: this.projectId
        //                 //     }
        //                 // })
        //             }
        //             this.loading = false;
        //         })
        //     }
        // });

        this.subscriptions.push(this.dataService.saveSearchObservable.subscribe((navRequest: NavRequest) => {
            if (navRequest.message === NavRequest.MSG.REQUEST_SAVE) {
                let isFreeSearch = this.searchPageUtils.isFreeSearch();

                if (isFreeSearch) {
                    this._nav(navRequest);
                } else {
                    this.showSaveDialog(navRequest);
                }
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchResults && changes.searchResults.currentValue && !changes.searchResults.firstChange) {
            console.log('ngOnChanges ngOnChanges ngOnChanges');
            this.invalidate();
        }
    }

    showTiles($event: any) {
        this.tilesShowSearchResults = false;
        if (this.searchResults && TreebuteProjectSummaryReducer.isTileSearch(this.searchResults)) {
            this.searchTiles({
                "action": "search",
                "data": {
                    "query": this.searchResults.query,
                    "searchType": "TILE_SEARCH_EXPERTS",
                    "scope": this.tileData ? this.tileData.scope : this.getScope(this.searchResults.scope),
                    "academicField": "ALL"
                }
            });
        } else {
            this.searchTiles({
                "action": "search",
                "data": {
                    "query": '',
                    "searchType": "TILE_SEARCH_EXPERTS",
                    "scope": 'world',
                    "academicField": "ALL"
                }
            });
        }
    }

    search($event: any) {
        let isInitialSearch = !this.searchResults;
        let isFreeSearch = this.searchPageUtils.isFreeSearch();
        if (isFreeSearch || isInitialSearch) {
            this._search($event, undefined);
            this.shouldSave(true);
        } else {
            // show modal
            let dialogRef = this.matDialog.open(NewSearchModalComponent,
                {
                    data: {
                        dialogTitle: "New Search",
                        dialogDescription: "How would you like to perform the search?",
                        searchResults: this.searchResults
                    }
                });

            dialogRef.afterClosed()
                .toPromise()
                .then((result) => {
                    if (result === 'new') {
                        // this.routeStateService.updatePathParamState({
                        //     'projectId': this.projectId,
                        //     'searchId': undefined
                        // });
                        this.location.replaceState(`/app/projects/${this.projectId}/search`)
                        this._search($event, undefined);
                        this.shouldSave(true);
                    } else if (result === 'overwrite') {
                        this._search($event, this.searchResults);
                        this.shouldSave(false);
                    }
                });
        }

    }

    _search($event: any, oldSearchResults: SearchQueryResults) {
        this.loading = true;
        this.networkService.searches
            .getNewSearchResults({
                query: $event.data.query ? $event.data.query : '',
                kind: $event.data.searchType,
                scope: this.getScope($event.data.scope),
                academicField: $event.data.academicField ? $event.data.academicField : 'ALL',
                descriptorParentIds: $event.data.descriptorParentIds,
                projectId: this.projectId ? this.projectId.toString() : '',
            })
            .toPromise()
            .then((uuid: string) => {
                return this._getAsyncSearch(uuid, oldSearchResults);
            })
            .catch(x => {
                this.loading = false;
            });
    }

    _getAsyncSearch(uuid: string, oldSearchResults: SearchQueryResults) {
        let loadingScreen = this.matDialog.open(LoadingDialogComponent, {data : {message : ''}});

        function handler() {
            console.log('_getAsyncSearch handler running')
            this.networkService.searches.getAsyncSearch(uuid)
                .then((res: SearchQueryResultsAsync) => {
                    if(loadingScreen.componentInstance.cancelSearchRequest){
                        loadingScreen.close();
                        this.loading = false;
                        console.log('Search cancelled')
                        return;
                    }
                    else if (res.treebuteSearchResult) {
                        this.searchResults = res.treebuteSearchResult;
                        this.searchResults.title = oldSearchResults && oldSearchResults.title;
                        this.searchResults.id = oldSearchResults && oldSearchResults.id;
                        this.update();
                        loadingScreen.close();
                        this.loading = false;
                    } else {
                        console.log(res.message);
                        loadingScreen.componentInstance.updateMessage(res.message);
                        setTimeout(handler.bind(this), 2000);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    loadingScreen.close();
                    this.loading = false;
                });
        }

        setTimeout(handler.bind(this), 500);
    }


    getScope(scope) {
        return scope === 'World Wide' ?
            'world' :
            scope === 'User Scope' ?
                'user' :
                scope;
    }

    // getCompanies() {
    //     const result =
    //         this.searchResults &&
    //         this.searchResults.institutionSearchResult &&
    //         this.searchResults.institutionSearchResult.institutions;
    //     return result ? result : [];
    // }
    //
    // getExperts() {
    //     const result =
    //         this.searchResults &&
    //         this.searchResults.expertSearchResult &&
    //         this.searchResults.expertSearchResult.researchers;
    //     return result ? result : [];
    // }

    private update() {
        // this.companies =
        //     this.searchResults &&
        //     this.searchResults.institutionSearchResult &&
        //     this.searchResults.institutionSearchResult.institutions;
        //
        // if (this.companies) this.wordCloud = this.searchResults.institutionSearchResult.wordCloud;
        //
        // this.experts =
        //     this.searchResults &&
        //     this.searchResults.expertSearchResult &&
        //     this.searchResults.expertSearchResult.researchers;
        //
        // if (this.experts) this.wordCloud = this.searchResults.expertSearchResult.wordCloud;

        if (this.searchResults && this.searchResults.searchType === 'TILE_SEARCH_EXPERTS') {
            this.tilesShowSearchResults = true;
            this.breadCrumbsStack = this.utilsComponent.getBreadCrumbs(this.searchResults);
        }

        this.isIndustry = TreebuteProjectSummaryReducer.isIndustry(this.searchResults);
        this.isExperts = TreebuteProjectSummaryReducer.isExperts(this.searchResults)
        this.isKnowledge = TreebuteProjectSummaryReducer.isTileSearch(this.searchResults)

        this.dataService.updateSearchState(this.searchResults);


        // if (this.isKnowledge) {
        //     this.showTiles({});
        // }

    }

    hasIndustryResults() {
        return TreebuteProjectSummaryReducer.isIndustry(this.searchResults);
    }

    hasExpertsResults() {
        return TreebuteProjectSummaryReducer.isExperts(this.searchResults)
    }

    hasTileExpertsResults() {
        return TreebuteProjectSummaryReducer.isTileSearch(this.searchResults)
    }

    changeTab($event: any) {
        this.isIndustry = $event === 'industry';
        this.isExperts = $event === 'experts';
        this.isKnowledge = $event === 'knowledge_map';

        // this.activatedRoute.snapshot.params

        if (this.isKnowledge) {
            this.showTiles({});
        }
    }

    saveSearch($event: SearchQueryResults) {
        let hasTitle = $event.title && $event.title.length > 0;

        if (!hasTitle) {
            return this.showTitleDialog($event);
        } else {
            return this._saveSearch($event).then((res) => {
                return true;
            });
        }
    }

    _saveSearch($event: SearchQueryResults) {
        this.loading = true;
        if ($event.id) {
            return this.networkService.searches.updateSearch(
                $event,
                this.searchResults.id.toString()
            )
                .then((res) => {
                    return this._afterSave(res)
                })
                .catch(x => {
                    this.loading = false;
                    let dialogRef = this.matDialog.open(YesNoModalComponent, {
                        data: {
                            dialogTitle: 'Error Saving Query',
                            dialogDescription: x ? x.error : 'Error saving query',
                            yesText: 'OK',
                            buttons: 1
                        } as YesNoModalData,
                    });

                    dialogRef.afterClosed().toPromise().then(() => {
                        window.location.reload();
                    })
                });
        } else {
            return this.networkService.searches.saveSearch(
                $event,
                {projectId: this.projectId.toString()}
            )
                .then((res) => {
                    return this._afterSave(res);
                })
                .catch(x => {
                    this.loading = false;
                });
        }
    }

    _afterSave(res) {
        console.log('print save result from server: ');
        console.log(res);
        // this.searchId = res.id;
        this.searchResults = res;
        this.location.replaceState(`/app/projects/${this.projectId}/search/${this.searchResults.id}`)
        this.loading = false;
        this.shouldSave(false);
    }

    selectExpertOption($event: any) {
        // this.influencerOnly = $event.value === 'Influencers';
        // this.youngTalentsOnly = $event.value === 'Rising talents';
        // this.pisOnly = $event.value === 'Lead Investigators';
        // this.youngLeadersOnly = $event.value === 'Potential collaborators';
        this.searchResults.filterInvestigator = $event;
        this.invalidate();
    }

    selectExpertPhaseOption($event: any) {
        this.searchResults.filterCtPhase = $event;
        this.invalidate();
    }

    selectExpertStatusOption($event: any) {
        this.searchResults.filterCtStatus = $event;
        this.invalidate();
    }

    getIsIndustry() {
        return this.isIndustry && TreebuteProjectSummaryReducer.isIndustry(this.searchResults);
        // return this.isIndustry &&
        //     this.searchResults &&
        //     ['CORPORATES', 'STARTUPS'].some(el => this.searchResults.searchType.includes(el)) &&
        //     this.searchResults.institutionSearchResult &&
        //     this.searchResults.institutionSearchResult.institutions &&
        //     this.searchResults.institutionSearchResult.institutions.length > 0;
    }

    getIsExperts() {
        return this.isExperts &&
            TreebuteProjectSummaryReducer.isExperts(this.searchResults) &&
            !TreebuteProjectSummaryReducer.isCtExperts(this.searchResults);
        // return this.isExperts &&
        //     this.searchResults &&
        //     ['ACADEMIC_EXPERTS', 'INDUSTRY_EXPERTS', 'CT_EXPERTS'].some(el => this.searchResults.searchType.includes(el)) &&
        //     this.searchResults.expertSearchResult &&
        //     this.searchResults.expertSearchResult.researchers &&
        //     this.searchResults.expertSearchResult.researchers.length > 0;
    }

    getIsCtExperts() {
        return this.isExperts && TreebuteProjectSummaryReducer.isCtExperts(this.searchResults);
    }

    getIsTiles() {
        return this.isKnowledge; // &&
        // this.searchResults &&
        // ['TILE_SEARCH_EXPERTS'].some(el => this.searchResults.searchType.includes(el)) &&
        // this.searchResults.expertSearchResult &&
        // this.searchResults.expertSearchResult.researchers &&
        // this.searchResults.expertSearchResult.researchers.length > 0;
    }

    navigateBreadcrumbs($event: any) {
        this.tilesShowSearchResults = false;
        this.loading = true;

        this.breadCrumbsStack = JSON.parse(JSON.stringify($event));
        // this.searchResults = undefined;
        this.searchTiles({
            data: {
                scope: this.tileData ? this.tileData.scope : this.getScope(this.searchResults.scope),
                query: this.queryTiles,
                callStack: $event
            }
        });
    }

    searchTiles($event: any) {
        this.loading = true;

        // run the getSearchHeatMap API
        const scope = $event.data.scope;
        const query = $event.data.query;
        const currentId = $event.data.currentId;
        const callStack = $event.data.callStack;

        this.queryTiles = query;

        let parentId = currentId ? currentId :
            this.breadCrumbsStack.length > 0 ?
                this.breadCrumbsStack[this.breadCrumbsStack.length - 1].id :
                'top';

        let type = this.queryTiles ? 'explorer' : 'campus';
        if (type === 'explorer') {
            this.networkService
                .getDataForExplorer(
                    parentId,
                    query,
                    !this.utilsComponent.isUser(scope),
                    scope !== !this.utilsComponent.isGlobal(scope)
                        ? scope
                        : null,
                    false,
                    true,
                    this.breadCrumbsStack.map(x => x.id).join(),
                    null,
                )
                .subscribe(
                    (data) => {
                        console.log("got result for searchTiles")
                        console.log(data);
                        this.tileData = data;
                        this.tileData.query = query;
                        this.tileData.scope = scope;
                        this.loading = false;
                        // this.breadCrumbsStack = callStack ? callStack : this.utilsComponent.getBreadCrumbs(this.searchResults);
                        // if (this.searchResults) {
                        //     this.searchResults = JSON.parse(JSON.stringify(this.searchResults));
                        // }
                    },
                    (error) => {
                        console.log(error);
                        this.loading = false;
                    },
                );
        } else {
            this.networkService
                .getDataForCampus(
                    parentId,
                    false,
                    true,
                    this.breadCrumbsStack.map(x => x.id),
                )
                .subscribe(
                    (data) => {
                        console.log("got result for searchTiles")
                        console.log(data);
                        this.tileData = data;
                        this.tileData.scope = scope;
                        this.loading = false;
                        // this.breadCrumbsStack = this.utilsComponent.getBreadCrumbs(this.searchResults);
                        // if (this.searchResults) {
                        //     this.searchResults = JSON.parse(JSON.stringify(this.searchResults));
                        // }
                    },
                    (error) => {
                        console.log(error);
                        this.loading = false;
                    },
                );
        }


        // connet the results back to the tiles component


    }

    titleUpdate($event: any) {
        this.searchResults.title = $event;
        this.searchResults = JSON.parse(JSON.stringify(this.searchResults));

        this.shouldSave(true);
    }

    getSearchResults(type: string) {
        if (type == 'INDUSTRY') {
            return TreebuteProjectSummaryReducer.isIndustry(this.searchResults) ?
                this.searchResults : undefined;
        } else if (type == 'EXPERTS') {
            return TreebuteProjectSummaryReducer.isExperts(this.searchResults) ?
                this.searchResults : undefined;
        } else if (type == 'TILES') {
            return this.getIsTiles() && TreebuteProjectSummaryReducer.isTileSearch(this.searchResults) ?
                this.searchResults : undefined;
        }
    }

    userSelectLocation($event: any) {
        this.searchResults.filterCountry = $event.value;
        this.invalidate();
        // this.ref.detectChanges();
        // this.dumbInput = Math.floor(Math.random() * (1000));
    }

    deleteSearch($event: any) {
        this.loading = true;
        this.networkService.searches.deleteSearch($event).toPromise().then(x => {
            this.shouldSave(false);
            this.loading = false;
            return this.router.navigate([`/app/projects/${this.projectId}`]);
        }).catch(x => {
            this.loading = false;
        });
    }

    setLoading($event: any) {
        this.loading = $event;
    }

    addCompanyToList($event: any) {
        let institutions = this.searchResults.institutionSearchResult.institutions;
        for (const inst of $event as Institution[]) {
            inst.manuallyAdded = true; /// <-- !!!!!!!!!! this is so important...
            inst.highProbability = true;
            inst.resources = [{
                valueA: inst.homepageUrl,
                valueB: inst.fullName,
                valueC: 'WEBSITE',
                valueD: inst.logoUrl
            }];
            inst.resourceScores = {OTHER: 0, CT: 0, ARTICLE: 0, PATENT: 0};
            inst.news = [];
            inst.resourceIds = [];
            inst.affiliations = [];

            institutions.unshift(inst);
        }

        // for (const inst of $event as Institution[]) {
        //     let newInst = {} as Institution;
        //     newInst.id = inst.id;
        //     newInst.logoUrl = inst.logoUrl;
        //     newInst.linkedInURL = inst.linkedInURL;
        //     newInst.name = inst.name;
        //     newInst.countries = inst.countries;
        //     newInst.description = inst.description;
        //     newInst.founders = inst.founders;
        //     newInst.foundersData = JSON.parse(JSON.stringify(inst.foundersData));
        //     newInst.foundingYear = inst.foundingYear;
        //     newInst.fullName = inst.name;
        //     newInst.homepageUrl = inst.homepageUrl;
        //     newInst.twitterName = inst.twitterName;
        //     newInst.videoURL = inst.videoURL;
        //     newInst.address = inst.address;
        //
        //     newInst.hasWebsite = true;
        //     newInst.exclude = false;
        //     newInst.manuallyAdded = true; /// <-- !!!!!!!!!! this is so important...
        //     newInst.highProbability = true;
        //     newInst.resources = [{
        //         valueA: newInst.homepageUrl,
        //         valueB: newInst.fullName,
        //         valueC: 'WEBSITE',
        //         valueD: newInst.logoUrl
        //     }];
        //     newInst.resourceScores = {OTHER: 0, CT: 0, ARTICLE: 0, PATENT: 0};
        //     newInst.news = [];
        //     newInst.resourceIds = [];
        //     newInst.affiliations = [];
        //
        //     institutions.unshift(newInst);
        // }

        this.invalidate();
    }

    removeCompanyFromList($event: any) {
        // show popup
        let dialogRef = this.matDialog.open(YesNoModalComponent, {
            data: {
                dialogTitle: 'Delete Company',
                dialogDescription: 'Would you like to remove this company from the list?',
                yesText: 'Delete',
                noText: 'Cancel',
                buttons: 2
            } as YesNoModalData,
        });

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result === 'yes') {
                    let list = this.searchResults.institutionSearchResult.institutions;
                    let inst = list.filter(x => x.id === $event.id);
                    if (inst && inst.length > 0) {
                        let index = list.indexOf(inst[0])
                        list.splice(index, 1);
                    }

                    this.invalidate();
                }
            });
    }

    invalidateDontSave() {
        this.searchResults = JSON.parse(JSON.stringify(this.searchResults));
        this.loading = false;
    }

    invalidate() {
        this.searchResults = JSON.parse(JSON.stringify(this.searchResults));
        this.shouldSave(true);
    }

    updateHasGrants($event: boolean) {
        if ($event) {
            // show the yes/no modal
            let dialogRef = this.matDialog.open(YesNoModalComponent, {
                data: {
                    dialogTitle: 'Deep Grants Analysis',
                    dialogDescription: 'Would you like to run a deeper grant search for this filter? (this will also save the results)',
                    yesText: 'Yes',
                    noText: 'No',
                    buttons: 2
                } as YesNoModalData,
            });

            dialogRef
                .afterClosed()
                .toPromise()
                .then((result) => {
                    this.loading = true;
                    if (result === 'yes') {
                        this.saveSearch(this.searchResults).then((res) => {
                            this.loading = true;
                            if (res) {
                                return this.networkService.searches.grantAnalysis(this.searchResults.id.toString())
                                    .then((searchResults) => {
                                        this.loading = true;
                                        this.searchResults = searchResults;
                                        this.searchResults.filterHasGrants = $event;
                                        this.invalidateDontSave();
                                    })
                            }
                        })
                    } else {
                        this.searchResults.filterHasGrants = $event;
                        this.invalidateDontSave();
                    }
                });
        } else {
            this.searchResults.filterHasGrants = $event;
            this.invalidateDontSave();
        }

    }

    breadCrumbsStackPop() {
        this.breadCrumbsStack.pop();
        this.breadCrumbsStack = JSON.parse(JSON.stringify(this.breadCrumbsStack));
    }

    breadCrumbsStackPush($event: TileBreadCrumb) {
        this.breadCrumbsStack.push($event);
        this.breadCrumbsStack = JSON.parse(JSON.stringify(this.breadCrumbsStack));
    }

    excludeAll($event: unknown) {
        this._excludeInclude(true);
    }

    includeAll($event: unknown) {
        this._excludeInclude(false);
    }

    _excludeInclude(exclude: boolean) {
        if (TreebuteProjectSummaryReducer.isExpertsSearchValid(this.searchResults)) {
            for (const researcher of this.searchResults.expertSearchResult.researchers) {
                researcher.exclude = exclude;
            }
        } else if (TreebuteProjectSummaryReducer.isIndustrySearchValid(this.searchResults)) {
            for (const institution of this.searchResults.institutionSearchResult.institutions) {
                institution.exclude = exclude;
            }
        }
        this.shouldSave(true);
    }

    _getExpertExportType(searchResults: SearchQueryResults) {
        if (!TreebuteProjectSummaryReducer.isTileSearch(searchResults)) return null;
        return searchResults.query ?
            'Explorer Map' :
            'My Campus';

        // const type =
        //     this.type === 'campus'
        //         ? 'My Campus'
        //         : this.type === 'explorer'
        //             ? 'Explorer Map'
        //             : this.type === undefined
        //                 ? 'Experts'
        //                 : null;
    }

    exportData($event: unknown) {
        if (TreebuteProjectSummaryReducer.isExpertsSearchValid(this.searchResults)) {
            if (TreebuteProjectSummaryReducer.isCtExperts(this.searchResults)) {
                // export ct
                this.loading = true;
                this.networkService.exportCtExperts(
                    this.utilsComponent.getFilteredCtExperts(this.searchResults),
                    this.searchResults.query,
                    this.searchResults.filterCtPhase,
                    this.searchResults.filterCountry,
                    this.searchResults.filterCtStatus,
                    new Date(),
                    false).toPromise().then((data) => {
                    this.downloadFile(data);
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            } else {
                // export experts
                this.loading = true;
                this.networkService.export(
                    this.utilsComponent.getFilteredExperts(this.searchResults),
                    this.searchResults.query,
                    'Experts',
                    this.utilsComponent.getScope(this.searchResults.scope),
                    this._getExpertExportType(this.searchResults),
                    new Date(),
                    false)
                    .toPromise().then((data) => {
                    this.downloadFile(data);
                    this.loading = false;

                }).catch(() => {
                    this.loading = false;
                });
            }
        } else if (TreebuteProjectSummaryReducer.isIndustrySearchValid(this.searchResults)) {
            this.loading = true;
            this.networkService.exportCompanies(
                this.utilsComponent.getFilteredInstitutions(this.searchResults),
                this.searchResults.query,
                this.searchResults.filterCountry,
                new Date(),
                false)
                .toPromise().then((data) => {
                this.downloadFile(data);
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        }
    }

    downloadFile(data) {
        const queryTrimmed =
            this.searchResults.query === undefined
                ? null
                : this.searchResults.query.length < 50
                    ? this.searchResults.query
                    : this.searchResults.query.substring(0, 50) + '...';
        const filename = queryTrimmed + ' - companies.xlsx';
        const options = {type: 'application/ms-excel'};
        this.createAndDownloadBlobFile(data, options, filename);

    }

    createAndDownloadBlobFile(body, options, filename) {
        const blob = new Blob([body], options);
        // Browsers that support HTML5 download attribute
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    shouldSave(shouldSave: boolean) {
        return this.dataService.saveSearchUpdate(new NavRequest('', shouldSave ? NavRequest.MSG.SHOULD_SAVE : NavRequest.MSG.SHOULD_NOT_SAVE));
    }

    private showSaveDialog(navRequest: NavRequest) {
        let dialogRef = this.matDialog.open(YesNoModalComponent, {
            data: {
                dialogTitle: 'Save Search',
                dialogDescription: 'Would you like to save your results?',
                buttons: 3
            } as YesNoModalData,
        });

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result === 'yes') {
                    if (!this.searchResults.title) {
                        this.showTitleDialog(this.searchResults).then((res) => {
                            if (res) {
                                return this._nav(navRequest);
                            }
                        });
                    } else {
                        return this._saveSearch(this.searchResults).then(() => {
                            return this._nav(navRequest);
                        })
                    }
                } else if (result === 'no') {
                    return this._nav(navRequest);
                }
            });
    }

    private _nav(navRequest: NavRequest) {
        this.dataService.saveSearchUpdate(new NavRequest(navRequest.dest, NavRequest.MSG.NAV_GRANTED));
    }

    private showTitleDialog($event: SearchQueryResults) {
        const dialogRef = this.matDialog.open(SaveSearchDialogComponent,
            {
                data: {
                    title: this.searchPageUtils.getTitleByQuery(this.searchResults.query),
                    dialogTitle: "Save Search",
                    dialogDescription: "Titles help you identify the search scope and encapsulate the results.",
                    dialogTextPrefix: "Enter a brief title:"
                }
            });

        return dialogRef.afterClosed()
            .toPromise()
            .then((titleResult) => {
                if (!titleResult) return false;
                $event.title = titleResult;
                return this._saveSearch($event).then((res) => {
                    return true;
                });
            });
    }

    hideTools() {
        if (!this.searchResults) return true;
        if (this.isIndustry && TreebuteProjectSummaryReducer.isIndustry(this.searchResults)) return false;
        if (this.isExperts && TreebuteProjectSummaryReducer.isExperts(this.searchResults)) return false;
        if (this.isKnowledge && TreebuteProjectSummaryReducer.isTileSearch(this.searchResults) && this.tilesShowSearchResults) return false;
        return true;
    }
}
