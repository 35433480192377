import {
    ChangeDetectorRef,
    Component, EventEmitter,
    HostListener, Input,
    OnDestroy,
    OnInit, Output, SimpleChange,
} from '@angular/core';
import { NetworkService } from '../../../network.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilitiesService } from '../../../utilities.service';
import { FloatingUpdateService } from '../../../floating-update.service';
import {DataService, NavRequest} from '../../../data.service';
import { MatDialog } from '@angular/material/dialog';
import { CompanyModalComponent } from './company-modal/company-modal.component';
import { CompanyModalService } from './company-modal/company-modal.service';
import {Institution, SearchQueryResults} from '../../../api-responses/SearchQueryResults.interface';
import { Location } from '@angular/common';
import {TreebuteProjectSummaryReducer} from "../../../utils/treebuteProjectSummaryReducer";

@Component({
    selector: 'app-companies-search',
    templateUrl: './companies-search.component.html',
    styleUrls: ['./companies-search.component.scss'],
})
export class CompaniesSearchComponent implements OnInit, OnDestroy {
    @Input() searchResults: SearchQueryResults;
    @Output() setLoading = new EventEmitter();
    @Output() removeCompanyFromList = new EventEmitter();
    noResults = false;
    selectedCompany = undefined;
    enableNextCompany = false;
    enablePreviousCompany = false;
    countries = ['World Wide'];
    maxScore;
    search = '';
    feedbackOpen = false;
    showWordCloud = false;
    googleSheets = false;
    title: string = 'Enter search title here...';
    searchId: number;
    @Input() projectId: number;

    private fromModalToData = Subscription.EMPTY;

    constructor(
        private networkService: NetworkService,
        public dataService: DataService,
        public sanitizer: DomSanitizer,
        public matDialog: MatDialog,
        public companyModelService: CompanyModalService,
        public location: Location,
    ) {}

    ngOnInit() {
        // Company Modal
        if (this.fromModalToData !== Subscription.EMPTY) return;

        this.fromModalToData =
            this.companyModelService.desiredIndexObservable.subscribe(
                async (desiredIndex) => {
                    if (typeof desiredIndex !== 'number') return;
                    if (!this.dialogRef) return;


                    if (desiredIndex === -1) {
                        this.dialogRef.close();
                        return;
                    }

                    this.setLoading.emit(true);

                    // update the current company index
                    this.selectedCompany = this.filterCompanies()[desiredIndex];
                    await this.getExtraInfo();
                    this.companyModelService.changeSelectedCompany({
                        company: this.selectedCompany,
                        index: desiredIndex,
                        enablePreviousCompany:
                            this.filterCompanies().indexOf(
                                this.selectedCompany,
                            ) > 0,
                        enableNextCompany: this.shouldEnableNextCompany(),
                    });

                    this.setLoading.emit(false);
                },
            );
    }


    ngOnDestroy() {
        this.fromModalToData.unsubscribe();
    }

    ngOnChanges(change: SimpleChange) {
        if (!TreebuteProjectSummaryReducer.isIndustry(this.searchResults)) return;
        if (this.searchResults.institutionSearchResult.institutions.length === 0) return;

        // update video URLs
        this.handleCountriesFilterAndTheVideoURL();
        this.calculateTopEvidenceAndCheckForStartups();
        this.filterCompanies();
    }

    flipWordCloud() {
        this.showWordCloud = !this.showWordCloud;
    }

    openFeedback() {
        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
        this.feedbackOpen = true;
    }

    handleCountriesFilterAndTheVideoURL() {
        const countriesArr = ['World Wide'];

        this.searchResults.institutionSearchResult.institutions.forEach((company) => {
            company.countries.forEach((country) => {
                countriesArr.push(country);
            });
            if (company.videoURL) {
                if (!company.videoURL.startsWith('http')) {
                    company.videoURL = 'https://' + company.videoURL;
                }
                company.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(
                    company.videoURL,
                ).toString();
            }
        });

        const countriesSet = new Set(countriesArr);
        this.countries = Array.from(countriesSet).sort();
    }

    filterCompanies() {
        let result: Institution[]  = this.searchResults.institutionSearchResult.institutions;

        if (this.searchResults.filterCountry != 'World Wide' && this.searchResults.filterCountry != 'world') {
            result = result.filter((company) =>
                company.countries.includes(this.searchResults.filterCountry),
            );
        }

        if (this.searchResults.filterHasGrants) {
            result = result.filter(company => company.resources.filter(r=>r.valueC.indexOf('GRANT') > -1).length > 0);
        }

        return result;
    }

    // exportBtnClicked() {
    //     this.exportLoading = true;
    //     document.getElementById('exportBtn').style.backgroundImage =
    //         "url('assets/images/loadloop.gif')";
    //
    //     const date = new Date();
    //
    //     this.networkService
    //         .exportCompanies(
    //             this.filterCompanies(),
    //             this.searchString,
    //             this.selectedCountry,
    //             date,
    //             this.googleSheets,
    //         )
    //         .subscribe(
    //             (data) => {
    //                 this.exportLoading = false;
    //                 document.getElementById('exportBtn').style.backgroundImage =
    //                     "url('assets/images/export-icon-dark.png')";
    //                 this.cdRef.detectChanges();
    //                 const queryTrimmed =
    //                     this.searchString === undefined
    //                         ? null
    //                         : this.searchString.length < 50
    //                         ? this.searchString
    //                         : this.searchString.substring(0, 50) + '...';
    //                 const filename = queryTrimmed + ' - companies.xlsx';
    //                 const options = { type: 'application/ms-excel' };
    //                 this.createAndDownloadBlobFile(data, options, filename);
    //             },
    //             (error) => {
    //                 this.exportLoading = false;
    //                 document.getElementById('exportBtn').style.backgroundImage =
    //                     "url('assets/images/export-icon-dark.png')";
    //                 this.cdRef.detectChanges();
    //                 console.log(error);
    //             },
    //         );
    // }

    // createAndDownloadBlobFile(body, options, filename) {
    //     const blob = new Blob([body], options);
    //     if (navigator.msSaveBlob) {
    //         // IE 10+
    //         navigator.msSaveBlob(blob, filename);
    //     } else {
    //         // Browsers that support HTML5 download attribute
    //         const link = document.createElement('a');
    //         if (link.download !== undefined) {
    //             const url = URL.createObjectURL(blob);
    //             link.setAttribute('href', url);
    //             link.setAttribute('download', filename);
    //             link.style.visibility = 'hidden';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     }
    // }

    calculateTopEvidenceAndCheckForStartups() {
        this.maxScore = 0;
        for (const company of this.searchResults.institutionSearchResult.institutions) {
            if (Math.round(company.totalScore) > this.maxScore) {
                this.maxScore = Math.round(company.totalScore);
            }

            if (company.hasWebsite) {
            }
        }
    }

    private dialogRef: any;

    async companyClicked(company) {
        this.setLoading.emit(true);

        this.selectedCompany = company;

        await this.getExtraInfo();

        this.dialogRef = this.matDialog.open(CompanyModalComponent, {});

        this.companyModelService.changeSelectedCompany({
            company: company,
            index: this.filterCompanies().indexOf(company),
            enableNextCompany: this.shouldEnableNextCompany(),
            enablePreviousCompany: this.filterCompanies().indexOf(company) > 0,
        });

        this.setLoading.emit(false);
    }

    getExtraInfo() {
        if (this.selectedCompany.hasWebsite) {
            let patentNumbers = '';
            const patents = this.selectedCompany.resources.filter(
                (r) => r.valueC === 'PATENT',
            );
            patents.forEach((patent) => {
                const index = patent.valueA.lastIndexOf('/');
                const patentNumber = patent.valueA.substring(index + 1);
                patentNumbers += patentNumber + ',';
            });

            this.networkService
                .getCompanyExtraInfo(
                    this.searchResults.query,
                    this.selectedCompany.id,
                    patentNumbers,
                )
                .subscribe(
                    (data) => {
                        this.selectedCompany.extraInfo = data;
                    },
                    (error) => console.log(error),
                );
        }
    }

    closeModal() {}

    openTwitter(company) {
        const twitterName = company.twitterName;
        window.open('https://twitter.com/' + twitterName, '_blank');
    }

    shouldEnableNextCompany() {
        let result: boolean;
        const index = this.filterCompanies().indexOf(this.selectedCompany);
        const inArray = index + 1 <= this.filterCompanies().length - 1;
        const inLimitedRange =
            index + 1 < this.dataService.userData.limitedUserMaxResultsSize;
        const limited = UtilitiesService.isLimited(this.dataService.userData);

        if (limited) {
            result = inLimitedRange;
        } else {
            result = inArray;
        }

        return result;
    }

    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    getOpacity(index) {
        const limited = UtilitiesService.isLimited(this.dataService.userData);
        const indexAboveLimit =
            index >= this.dataService.userData.limitedUserMaxResultsSize;

        if (limited && indexAboveLimit) {
            const all =
                this.dataService.userData.limitedUserMaxResultsSize +
                this.dataService.userData.limitedObfuscatedResultsSize;
            const obfuscated =
                this.dataService.userData.limitedObfuscatedResultsSize;
            const grad = (index + obfuscated + 1 - all) * 0.1;
            const opacity = 0.8 - grad;
            return opacity;
        }
        return 1;
    }

    isLimited(index) {
        return (
            UtilitiesService.isLimited(this.dataService.userData) &&
            index >= this.dataService.userData.limitedUserMaxResultsSize
        );
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }


    show() {
        return TreebuteProjectSummaryReducer.isIndustry(this.searchResults) &&
        this.searchResults.institutionSearchResult.institutions.length > 0
    }

    getTreebuteProfile(company: Institution) {
        return `/app/companies/${company.id}`;
    }

    shouldSave() {
        this.dataService.saveSearchUpdate(new NavRequest('', NavRequest.MSG.SHOULD_SAVE));
    }
}
