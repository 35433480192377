export type SearchTypes =
    | 'ACADEMIC_EXPERTS'
    | 'INDUSTRY_EXPERTS'
    | 'CT_EXPERTS'
    | 'TILE_SEARCH_EXPERTS'
    | 'STARTUPS'
    | 'CORPORATES';

const stringToTypeMap = {
    ACADEMIC_EXPERTS: 'ACADEMIC_EXPERTS' as SearchTypes,
    INDUSTRY_EXPERTS: 'INDUSTRY_EXPERTS' as SearchTypes,
    CT_EXPERTS: 'CT_EXPERTS' as SearchTypes,
    TILE_SEARCH_EXPERTS: 'TILE_SEARCH_EXPERTS' as SearchTypes,
    STARTUPS: 'STARTUPS' as SearchTypes,
    CORPORATES: 'CORPORATES' as SearchTypes,
};

export function getSearchType(searchType: string) {
    return stringToTypeMap[searchType];
}
