import {Component, Inject, OnInit} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SaveSearchSnackBarComponent } from '../../save-search-snack-bar/save-search-snack-bar.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SearchTitleDialogData} from "../../../../api-responses/Dialogs.interface";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-save-search-dialog',
    templateUrl: './save-search-dialog.component.html',
    styleUrls: ['./save-search-dialog.component.scss'],
})
export class SaveSearchDialogComponent implements OnInit {
    public formGroup = this.formBuilder.group({
        title: '',
    });

    constructor(
        public matDialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<SaveSearchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SearchTitleDialogData)
    {}

    ngOnInit() {}

    closeSaveSearchModal() {
        this.dialogRef.close();
    }

    saveSearch() {
        // this._snackBar.openFromComponent(SaveSearchSnackBarComponent, {
        //     duration: this.durationInSeconds * 1000,
        // });
        this.dialogRef.close(this.data.title);
    }
}
