<div class="container project-page pt-5" [class.disable-when-loading]="loading">
    <div class="loader-panel" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div>
        <div class="pt-3">
            <div style="display: flex; justify-content: space-between">
                <editable-text *ngIf="canvas"
                               [(text)]="canvas.title"
                               (textChange)="updateTitle($event)"
                               [initialCaption]="'Add Title to Search Results...'"
                ></editable-text>
                <div>
                    <button class="btn btn-primary" (click)="publish()">
                        Publish
                    </button>
                    <a *ngIf="canvas" href="{{ readOnlyLink }}" target="_blank">open...</a>
                </div>
            </div>
        </div>
        <div class="pt-3">
            <h2>
                Introduction Text (Markdown text)
            </h2>
            <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">(How to write Markdown text? click here....)</a>
            <textarea style="width: 100%; padding: 25px" rows="10" [(ngModel)]="markdown"></textarea>
            <button class="btn btn-primary" (click)="updateDescription($event)">Save</button>
        </div>
        <hr />
        <div class="pt-4">
            <h2>
                Project Selection
            </h2>
            <div class="px-4">
                <div *ngFor="let project of selectedProjects">
                    <input class="form-check-input" type="checkbox" value="{{ project.id }}" id="#checkbox"
                           [checked]="project.selected" (change)="updateProject($event)">
                    <label class="form-check-label" for="#checkbox">
                        <a href="{{'/app/projects/' + project.id}}" target="_blank">{{ project.name }}</a>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <hr *ngIf="isAdmin"/>
    <div style="display: flex" *ngIf="isAdmin">
        <div style="width: 100%">
            <div class="pt-5 pb-2">
                <h1>Create HTML for update email</h1>
            </div>
            <div style="display: flex; align-items: center">
                <div class="pr-5">
                    <span class="pr-3">From:</span>
                    <mat-form-field appearance="fill" class="datepicker border p-1 m-1">
                        <mat-label>Choose 'FROM' date</mat-label>
                        <input matInput [matDatepicker]="picker" #dateFromInput (dateChange)="fromDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="pr-5">
                    <span class="pr-3">To:</span>
                    <mat-form-field appearance="fill" class="datepicker border p-1 m-1">
                        <mat-label>Choose 'TO' date</mat-label>
                        <input matInput [matDatepicker]="picker2" #dateToInput (dateChange)="toDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="pr-5">
                    <a class="btn btn-primary" href="{{ linkToHtml }}" role="button" target="_blank">Create HTML</a>
                </div>
            </div>
        </div>
    </div>
    <hr/>
    <div style="display: flex">
        <div style="width: 100%">
            <div class="pt-5 pb-2">
                <h1>Preview</h1>
            </div>
            <div class="py-3">
                <button class="btn btn-primary" (click)="refresh()">Refresh <i class='fas fa-recycle'></i></button>
            </div>
            <div class="preview-frame p-3">
                <div *ngIf="canvas && canvas.projects">
                    <div class="p-4">
                        <markdown
                                [data]="markdown"
                                (ready)="onReady()">
                        </markdown>
                    </div>
                    <div class="loader-panel" [hidden]="allProjectsLoaded===canvas.projects.length">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <div *ngFor="let project of canvas.projects">
                        <canvas-project-preview
                                [canvasId]="canvasId"
                                (doneLoading)="projectLoaded()"
                                [innovationProjectId]="project.innovationProjectId"></canvas-project-preview>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
