<div style="display: flex; flex-direction: column">
    <h3>Machine Parameters:</h3>
    <form (ngSubmit)="onSearchSubmit()" style="margin-bottom: 20px">
        <div class="form-group">
            <textarea
                [(ngModel)]="queryName"
                name="name"
                class="form-control"
                rows="1"
                id="machineName"
                placeholder="Machine Name"
            ></textarea>
            <textarea
                [(ngModel)]="queryMake"
                name="make"
                class="form-control"
                rows="1"
                id="machineMake"
                placeholder="Machine Make"
            ></textarea>
            <textarea
                [(ngModel)]="queryModel"
                name="model"
                class="form-control"
                rows="1"
                id="machineModel"
                placeholder="Machine Model"
            ></textarea>
        </div>
        <button class="btn btn-primary">Search</button>
        <button (click)="createMachine()" class="btn btn-primary">
            Create new
        </button>
        <h3>{{ message }}</h3>
    </form>

    <div class="col-8">
        <div *ngFor="let machine of machines">
            <button
                type="button"
                (click)="openMachineDetails(machine.id)"
                class="btn btn-primary btn-sm"
            >
                {{ machine.id }}, {{ machine.name }}, {{ machine.make }},
                {{ machine.model }}
            </button>
        </div>
    </div>

    <div
        style="display: flex; justify-content: space-between; margin-left: 20px"
    >
        <app-machine-editor
            *ngIf="showMachine"
            [id]="selectedMachineId"
            [instanceId]="instanceId"
            (machineDelete)="onSearchSubmit()"
        ></app-machine-editor>
    </div>
</div>
