<div *ngIf="selectedCompany.founders && selectedCompany.founders.length > 0" class="founders ellipsis">
    Founded by:&nbsp;
    <div *ngFor="let founder of selectedCompany.foundersData">
                    <span
                            title="{{ founder.valueC }}"
                            (ngChange)="
                            checkWidth($event, selectedCompany.fullName)
                        "
                            [ngStyle]="{
                            cursor: founder.valueB ? 'pointer' : 'auto'
                        }"
                            (click)="
                            founder.valueB ? utilsComponent.openURL(founder.valueB) : false
                        "
                    >
                        {{ founder.valueA + getComma(founder) }}&nbsp;
                    </span>
    </div>
</div>
