import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {Canvas, CanvasProject} from "../canvas.interface";
import {NetworkService} from "../../../network.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../../data.service";
import {MatDialog} from "@angular/material/dialog";
import {CanvasProjectSelectModalComponent} from "../canvas-project-select-modal/canvas-project-select-modal.component";
import {InnovationProject} from "../../../api-responses/InnovationProjects.interface";
import {YesNoModalComponent} from "../../dialogs/yesNo/yes-no-modal.component";
import {YesNoModalData} from "../../../api-responses/Dialogs.interface";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import moment from "moment";
import {UtilitiesService} from "../../../utilities.service";


interface SelectedProject {
    id: number,
    name: string,
    selected: boolean
}

@Component({
    selector: 'canvas-page',
    templateUrl: './canvas-page.component.html',
    styleUrls: ['./canvas-page.component.scss'],
})
export class CanvasPageComponent implements OnInit {
    htmlOutput: string;
    markdown: string;
    canvas: Canvas;
    selectedProjects: SelectedProject[] = [];
    private projects: InnovationProject[];
    allProjectsLoaded: number = 0;

    readOnlyLink: string;

    canvasId: number;
    loading: boolean = true;
    linkToHtml: string;

    isAdmin: boolean = false;

    @ViewChild('dateFromInput') dateFromInput: ElementRef<HTMLInputElement>;
    @ViewChild('dateToInput') dateToInput: ElementRef<HTMLInputElement>;

    constructor(private networkService: NetworkService,
                private router: Router,
                private route: ActivatedRoute,
                public dataService: DataService,
                public matDialog: MatDialog) {

    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.canvasId = Number.parseInt(params['canvasId']);
            this._load();
        });

        this.isAdmin = UtilitiesService.isAdmin(this.dataService.userData) || UtilitiesService.isAccountAdmin(this.dataService.userData)
    }

    _load() {
        this.networkService.canvas.getById(this.canvasId)
            .then((res: Canvas) => {
                this.canvas = res;
                this.markdown = res.description;

                const param = this.dataService.isDev() ? '?isDev=true' : '';
                this.readOnlyLink = `/app/canvas/${this.canvas.id}/read-only-canvas${param}`;

                this.networkService.innovationProjects.getAll().then((res: InnovationProject[]) => {
                    this.projects = res;
                    let selected = this.canvas.projects.map(x => x.innovationProjectId);

                    this.selectedProjects = this.projects.map(x => {
                        return {
                            id: x.id,
                            selected: selected.indexOf(x.id) > -1,
                            name: x.name
                        }
                    })
                })
            })
            .finally(() => {
                this.loading = false;
            })
    }

    selectProjects() {
        // open modal select projects
        let dialogRef = this.matDialog.open(CanvasProjectSelectModalComponent, {
            data: this.canvas,
        });

        dialogRef.afterClosed().toPromise().then((res) => {
            if (res) {
                this.canvas = res;
            }
        })

    }

    onReady() {
        console.log('markdown is ready')
    }

    updateProject($event: Event) {
        const add = $event.target['checked'];
        const innovationProjectId = $event.target['value'];

        if (add) {
            this.networkService.canvas.addProject(this.canvas.id, {
                innovationProjectId: innovationProjectId,
                ordinal: 0
            } as CanvasProject)
        } else {
            this.networkService.canvas.deleteProject(this.canvas.id, innovationProjectId);
        }
    }

    updateTitle($event: string) {
        this.networkService.canvas.updateTitle(this.canvas.id, $event).then((res) => {
            this.matDialog.open(YesNoModalComponent, {
                data: {
                    dialogTitle: 'Canvas Updated',
                    dialogDescription: 'The canvas was updated',
                    yesText: 'OK',
                    buttons: 1
                } as YesNoModalData,
            });
        })
    }

    updateDescription($event: MouseEvent) {
        this.networkService.canvas.updateDescription(this.canvas.id, this.markdown).then((res) => {
            this.matDialog.open(YesNoModalComponent, {
                data: {
                    dialogTitle: 'Canvas Updated',
                    dialogDescription: 'The canvas was updated',
                    yesText: 'OK',
                    buttons: 1
                } as YesNoModalData,
            });
        })
    }

    projectLoaded() {
        this.allProjectsLoaded++;
    }

    refresh() {
        this.canvas = undefined;
        this.allProjectsLoaded = 0;
        this.projects = undefined;

        this._load();

    }

    publish() {
        this.loading = true;
        this.networkService.canvas.publish(this.canvasId, true)
            .then(res => {
                this.matDialog.open(YesNoModalComponent, {
                    data: {
                        dialogTitle: 'Canvas Published',
                        dialogDescription: 'The canvas was published',
                        yesText: 'OK',
                        buttons: 1
                    } as YesNoModalData,
                });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    fromDateChange($event: MatDatepickerInputEvent<unknown, unknown | null>) {
        this._calcLink();
    }

    toDateChange($event: MatDatepickerInputEvent<unknown, unknown | null>) {
        this._calcLink();
    }

    _calcLink() {
        let from = this.dateFromInput.nativeElement.value;
        let to = this.dateToInput.nativeElement.value;
        let mFrom = moment(from);
        let mTo = moment(to);
        this.linkToHtml = `/app/canvas/${this.canvasId}/html-update?fromDate=${mFrom.format('YYYY-MM-DD')}&toDate=${mTo.format('YYYY-MM-DD')}`;
    }


}
