<div *ngIf="this.isAcademicExpertsSearch()">
    <mat-form-field class="location-select" appearance="fill">
      <mat-select [(value)]="this.expertOption"
                  (selectionChange)="this.selectExpertOption($event.value)">
        <mat-option
                *ngFor="let expertOption of expertOptions"
                [value]="expertOption"
        >{{ expertOption }}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>
