<div (click)="hideModalIfOpen($event)" id="grp-mgnt-container">
    <h1>Users group Management</h1>
    <button
        type="button"
        class="btn btn-primary"
        (click)="createGroupClicked(); $event.stopPropagation()"
    >
        Create new users group
    </button>

    <div
        *ngIf="showGroupCreationModal"
        class="modal"
        role="dialog"
        style="display: block"
    >
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <app-users-group-creation></app-users-group-creation>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="showGroupEditModal"
        tabindex="-1"
        class="modal"
        role="dialog"
        style="display: block"
    >
        <div class="modal-dialog" role="document">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <app-users-group-edit
                        [group]="selectedGroup"
                    ></app-users-group-edit>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="userGroups$">
        <h1>Users groups</h1>
        <ul class="list-group">
            <li *ngFor="let group of userGroups$" class="list-group-item">
                <a
                    class="dropdown-item btn-sm"
                    (click)="groupClicked(group); $event.stopPropagation()"
                    >{{ group.groupName }} - {{ group.groupType }}</a
                >
            </li>
        </ul>
    </div>

    <div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Modal body text goes here.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary">
                        Save changes
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
