<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="close()"
        ></div>
        <h2 class="header-title">{{ data.query.title }}</h2>
        <div class="header-subtitle">
            <h5>Query</h5>
            <div class="line">
                <p class="lead">
                    {{ data.query.query }}
                </p>
            </div>
        </div>
    </div>
    <div class="body">
        <div>
            <span>Type: </span>
            <span [style.color]="this.color">{{data.query.searchType}}</span>
        </div>
        <div>
            <span>Scope: </span>
            <span>{{data.query.scope}}</span>
        </div>
        <div class="pb-3">
            <span>Number of results: </span>
            <span>{{ numberOfResults }}</span>
        </div>
        <div *ngIf="showWords">
            <div class="pb-1">Related Terms: </div>
            <ul class="word-list">
                <li *ngFor="let word of wordCloudTerms">{{word}}</li>
            </ul>
        </div>

    </div>
    <div class="footer">
        <div *ngIf="data.buttons === 1" class="buttons-single" [class.error-button]="data.error">
            <div class="button-save" (click)="yes()">
                {{ getYesText() }}
            </div>
        </div>

        <div *ngIf="data.buttons === 2" class="buttons">
            <div class="button-cancel" (click)="no()">
                {{ getNoText() }}
            </div>
            <div class="button-save" (click)="yes()">
                {{ getYesText() }}
            </div>
        </div>

        <div *ngIf="data.buttons === 3" class="buttons">
            <div class="button-cancel" (click)="cancel()">
                {{ getCancelText() }}
            </div>
            <div class="button-cancel" (click)="no()">
                {{ getNoText() }}
            </div>
            <div class="button-save" (click)="yes()">
                {{ getYesText() }}
            </div>
        </div>


    </div>
</div>
