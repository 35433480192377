import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import {roleTypes} from "../../../api-responses/UserInfo.interface";

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
    query: string;
    message: string;
    userFound = false;

    insQuery: string = null;
    noResults = false;
    isVisible = false;
    institutionsList: any = [];

    paymentReason: any = ['MONTHLY_TALENT_SUBSCRIPTION', 'MACHINE_BOOKING'];
    paymentStatus: any = ['PENDING', 'PAID', 'CANCELLED'];

    model: any = {};

    id: number;
    email: string;
    biomed: boolean;
    globalSearch: boolean;
    firstname: string;
    lastname: string;
    dashboards: string;
    limitedUserMaxResultsSize: number;
    emailValidated: boolean;
    registrationDate: Date;
    institution: string;
    roles: any = [];
    pendingPayments: any = [];
    roleTypes: any = roleTypes;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
    }

    onSearchSubmit() {
        this.userFound = false;
        this.message = null;
        this.insQuery = null;
        this.noResults = false;
        this.institutionsList = [];

        this.id = null;
        this.email = null;
        this.firstname = null;
        this.biomed = null;
        this.globalSearch = null;
        this.lastname = null;
        this.dashboards = null;
        this.limitedUserMaxResultsSize = null;
        this.emailValidated = null;
        this.registrationDate = null;
        this.institution = null;
        this.roles = [];
        this.pendingPayments = [];

        this.networkService.getUserDetailsAdmin(this.query).subscribe(
            (data) => {
                if (data) {
                    this.handleResponse(data);
                } else {
                    this.message = 'User not found.';
                }
            },
            (error) => (this.message = error.error.toString()),
        );
    }

    handleResponse(data) {
        this.id = data.id;
        this.email = data.email;
        this.firstname = data.firstName;
        this.lastname = data.lastName;
        this.biomed = data.biomed;
        this.dashboards = data.remainingDashboards;
        this.limitedUserMaxResultsSize = data.limitedUserMaxResultsSize;
        this.globalSearch = data.globalSearch;
        this.institution = data.institution;
        this.emailValidated = data.emailValidated;
        this.registrationDate = new Date(data.registrationDate);
        this.roles = data.roles;
        this.pendingPayments = data.pendingPayments;
        this.userFound = true;
    }

    onUserDetailsSubmit() {
        this.noResults = false;
        const searchString = this.institution.toLowerCase();

        this.networkService
            .getInstitutionsList(this.institution)
            .subscribe((institutions) => {
                this.institutionsList = institutions;

                if (this.insQuery === this.institution) {
                    // The institution is already updated
                } else if (!this.institutionsList.length) {
                    // The institutions list does not contain any items
                    this.noResults = true;
                } else if (
                    this.institutionsList.length === 1 ||
                    this.institutionsList[0] === searchString
                ) {
                    // The search string exactly matches an item from the institutions list
                    this.onInstitutionClick(this.institutionsList[0]);
                } else if (
                    this.institutionsList.length > 1 &&
                    !this.institutionsList.includes(searchString)
                ) {
                    // The institutions list contains more than one option, and does not include the exact search string
                    this.isVisible = true;
                }
            });
    }

    onInstitutionClick(institution) {
        this.isVisible = false;
        const index = institution.lastIndexOf('(');
        if (index > 0) {
            institution = institution.substring(
                0,
                institution.lastIndexOf('('),
            );
        }
        this.institution = institution;
        this.insQuery = institution;
    }

    updateField(field, value, add) {
        if (
            field === 'password' &&
            (value === undefined || value.length === 0)
        ) {
            alert('Password cannot be empty.');
            return;
        }
        this.networkService
            .updateUserDetailsAdmin(this.email, field, value, add)
            .subscribe(
                (data) => this.handleResponse(data),
                (error) => {
                    console.log(error);
                    alert(error.error.toString());
                },
            );
    }

    updatePayment(id, field, value) {
        if (
            field === 'total' &&
            (value === null || value === undefined || value === '' || value < 0)
        ) {
            alert('Payment total amount cannot be empty or negative.');
            return;
        }
        if (
            field === 'paymentDate' &&
            (value === null || value === undefined || value === '')
        ) {
            alert('Payment date must be specified.');
            return;
        }
        if (field === 'reason' && value === undefined) {
            value = '';
        }
        this.networkService
            .updatePaymentRequestAdmin(this.email, id, field, value)
            .subscribe(
                (data) => this.handleResponse(data),
                (error) => {
                    console.log(error);
                    alert(error.error.toString());
                },
            );
    }

    createPayment() {
        if (
            this.model.total === null ||
            this.model.total === undefined ||
            this.model.total === '' ||
            this.model.total < 0
        ) {
            alert('Payment total amount cannot be empty or negative.');
            return;
        }
        if (
            this.model.paymentDate === null ||
            this.model.paymentDate === undefined ||
            this.model.paymentDate === ''
        ) {
            alert('Payment date must be specified.');
            return;
        }
        if (this.model.reason === '') {
            this.model.reason = null;
        }
        this.model.email = this.email;
        this.networkService.createPaymentRequestAdmin(this.model).subscribe(
            (data) => this.handleResponse(data),
            (error) => {
                console.log(error);
                alert(error.error.toString());
            },
        );
    }
}
