import {
    Component,
    ElementRef,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {NetworkService} from "../../../network.service";
import {Institution, InstitutionResult} from "../../../api-responses/SearchQueryResults.interface";
import {UntypedFormBuilder} from "@angular/forms";
import {UtilsComponent} from "../../../utils/utils.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'create-new-company',
    templateUrl: './create-new-company.component.html',
    styleUrls: ['./create-new-company.component.scss'],
})
export class CreateNewCompanyComponent implements OnInit, OnDestroy, OnChanges {

    afterSearch: boolean = false;
    inst: Institution = {} as Institution;
    public loading: boolean = false;
    countries: any[] = UtilsComponent.countries;
    editMode: boolean = false;

    @ViewChild('homepageUrl') homepageUrl: ElementRef;
    @ViewChild('companyName') companyName: ElementRef;
    @ViewChild('countrySelect') countrySelect: ElementRef;
    public error: string;

    @Output() instFound = new EventEmitter<Institution>();

    constructor(public networkService: NetworkService,
                public formBuilder: UntypedFormBuilder,
    public activatedRoute: ActivatedRoute,
    public  router:Router) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.loading = true;
        this.activatedRoute.paramMap.subscribe((map) => {
            const companyId = map.get('companyId');
            if (!companyId) {
                this.loading = false;
                return;
            }

            this.networkService
                .getCompanyById(companyId)
                .toPromise()
                .then((inst: Institution) => {
                    this.inst = inst;
                    this.companyName.nativeElement.value = this.inst.name;
                    this.countrySelect.nativeElement.value = this.inst.countries && this.inst.countries.length > 0? this.inst.countries[0] : '';
                    this.homepageUrl.nativeElement.value = this.inst.homepageUrl;
                    this.afterSearch = true;
                    this.loading = false;
                    this.editMode = true;
                })
                .catch(() => {
                    this.loading = false;
                });
        });
    }

    search(value: string) {
        // disabled website
        this.error = "";
        this.homepageUrl.nativeElement.disabled = true;
        this.loading = true;
        // run a search in the server
        this.networkService.searchByHost(value).toPromise()
            .then((inst: Institution) => {
                if (inst) {
                    this.inst = inst;
                    this.afterSearch = true;
                    // this.companyName.nativeElement.value = this.inst.name;
                    // this.countrySelect.nativeElement.value = this.inst.country.toLowerCase();
                    // this.router.navigate([`/app/companies/${this.inst.id}`]);
                    // this.instFound.emit(inst);
                }
                else {
                    this.homepageUrl.nativeElement.disabled = false;
                    this.error = "Couldn't find the site for this company"
                }
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });

    }

    startOver(value: string) {
        this.homepageUrl.nativeElement.disabled = false;
        this.afterSearch = false;
        this.error = "";
    }

    add() {
        this.inst.name = this.companyName.nativeElement.value;
        this.inst.countries = [this.countrySelect.nativeElement.value];
        this.networkService.updateInstitution(this.inst).toPromise()
            .then((x: Institution) => {
                //this.router.navigate([`/app/companies/${this.inst.id}`]);
                this.instFound.emit(x);
            })
            .catch(x => {
                this.error = "Couldn't create or update the company. Please contact Treebute support.";
            });
    }

    getActionText() {
        return this.editMode ?
            "Update Company" :
            "Add This Company";
    }
}
