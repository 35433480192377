import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {CountriesDataService} from "../../../../shared/CountriesDataService";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {TreebuteProjectSummaryReducer} from "../../../../utils/treebuteProjectSummaryReducer";
import {DataService} from "../../../../data.service";

@Component({
    selector: 'experts-search-tool',
    templateUrl: './experts-search-tool.component.html',
    styleUrls: ['./experts-search-tool.component.scss'],
})
export class ExpertsSearchToolComponent implements OnInit {

    @Output() searchSubmit = new EventEmitter();

    public query: string = "";
    public selectedAcademicField: string = "BIOMED";

    public selectedOption: string = 'ACADEMIC_EXPERTS';
    public options: string[] = ['ACADEMIC_EXPERTS', 'INDUSTRY_EXPERTS', 'CT_EXPERTS']; // todo: change to input attribute

    public selectedScope: string = 'User Scope'; // todo: change to input attribute
    public scopeOptions: string[] = [];

    public academicFields: any[] = [
        { name: 'Only bio-med', value: 'BIOMED', checked: true },
        { name:'Only non bio-med', value: 'NONBIOMED', checked: false},
        {name: 'All domains', value: 'ALL', checked: false}];


    formGroup = this.formBuilder.group({
        query: [''],
        selectedAcademicField: ['']
    });

    @Input() searchResults: SearchQueryResults;

    constructor(
        private countriesDataService: CountriesDataService,
        private formBuilder: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        public dataService: DataService
    ) {
    }

    ngOnInit(): void {
        this.countriesDataService.getCountries().subscribe(countries => {
            this.scopeOptions = ['User Scope', 'World Wide', ...countries]
        })

        this.selectedAcademicField = 'BIOMED';
        // this._loadFormValues();

        this.activatedRoute.queryParamMap.subscribe((params:ParamMap) => {
            const query = params.get('query');
            const searchType = params.get('searchType');
            if (query) {
                this.query = query;
                // this.search({
                //     data: {
                //         query,
                //         searchType,
                //         scope: 'world',
                //         academicField: 'ALL',
                //         descriptorParentIds: '',
                //         projectId: this.projectId
                //     }
                // })
            }
        })
    }

    ngOnDestroy() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchResults) {
            this._loadFormValues(changes.searchResults.currentValue);
        }
    }

    onSubmit() {
        this.searchSubmit.emit({
            action: "search",
            data: {
                query: this.query,
                searchType: this.selectedOption,
                scope: this.selectedScope,
                academicField: this.selectedOption === 'ACADEMIC_EXPERTS' ? this.selectedAcademicField : 'ALL'
            }
        })
    }

    changeQueryVal($event: Event) {
        this.query = $event.srcElement['value'];
    }

    private _loadFormValues(searchResults: SearchQueryResults) {
        if (TreebuteProjectSummaryReducer.isTileSearch(searchResults) || TreebuteProjectSummaryReducer.isExperts(searchResults)) {
            this.query = this.searchResults.query;
            this.selectedAcademicField = this.searchResults.academicField;
            this.selectedOption = searchResults.searchType
            this.selectedScope = this.getScope(searchResults.scope)
        }
    }
    getWordCloud() {
        if (this.searchResults && this.searchResults.expertSearchResult) {
            return this.searchResults.expertSearchResult.wordCloud;
        }
        else {
            return {};
        }
    }

    private getScope(scope: string) {
        if (scope.toLowerCase() === 'user') {
            return 'User Scope';
        }
        else if (scope.toLowerCase() === 'world') {
            return 'World Wide';
        }
        else {
            return scope;
        }

    }

    getExpertTypeString(option: string) {
        if (option == 'ACADEMIC_EXPERTS') {
            return 'From Research';
        }
        else if (option == 'INDUSTRY_EXPERTS') {
            return 'From Industry';
        }
        else if (option == 'CT_EXPERTS') {
            return 'From Clinical Trials';
        }
    }

    hasChange($event: any) {
        console.log($event);
    }
}
