import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from './network.service';
import { Observable, Subscription } from 'rxjs';
import { DataService } from './data.service';
import { UtilitiesService } from './utilities.service';
import { FloatingUpdateService } from './floating-update.service';
import { AppService } from './app.service';
import {SidenavService} from "./utils/sidenav.service";
import {onMainContentChange} from "./animations/animations";
import {ProjectStateService} from "./pages/projects-page/project-state-service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [ onMainContentChange ],
})
export class AppComponent implements OnInit {
    subscription: Subscription;
    // userData$: Object = null;
    isExpanded: boolean = false;
    readOnlyProject: boolean = false;
    public onSideNavChange: boolean;

    constructor(
        public router: Router,
        public networkService: NetworkService,
        public dataService: DataService,
        public floatingUpdateService: FloatingUpdateService,
        public appService: AppService,
        public _sidenavService: SidenavService,
        public projectStateService: ProjectStateService
    ) {
        if (window.location.href.indexOf('/read-only-') > -1) {
            this.readOnlyProject = true;
            return;
        }

        this._sidenavService.sideNavState$.subscribe( res => {
            console.log(res)
            this.onSideNavChange = res;
        })

        this.dataService.updateUserInfo();
        // console.log("&&&&&&")
        console.log(projectStateService.searchId);
    }

    saveUserData(data) {
        // this.userData$ = data;
        this.dataService.userData = data;
    }

    async ngOnInit() {
        // const result = this.dataService.getUserInfo();
        // result
        //     .then((data) => {
        //         this.saveUserData(data);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.router.navigate(['/app/login']);
        //     });
        if (window.location.href.indexOf('/app/register') > -1) {
            return;
        }

        this.dataService.userInfo$.subscribe((data) => {
            // this.userData$ = data;
            this.saveUserData(data);
        });
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    vw() {
        return UtilitiesService.vw();
    }

    // isDashboardUser() {
    //     try {
    //         return this.userData$ === null
    //             ? false
    //             : UtilitiesService.isDashboardUser(this.userData$);
    //     } catch (e) {
    //         return false;
    //     }
    // }
}

