import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../network.service';
import { UtilitiesService } from '../../utilities.service';
import { DataService } from '../../data.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class AdminComponent implements OnInit {
    search = '';
    showModal = false;
    email = '';
    institution = '';
    institutionId;
    institutions;
    badRequest = false;
    lastClick;

    userRoleTypes: any = [
        'DASHBOARD',
        'LIMITED',
        'STARTUP_FINDER',
        'CORPORATE_FINDER',
    ];
    newUserRole = '';

    constructor(
        private networkService: NetworkService,
        public dataService: DataService,
    ) {}

    ngOnInit() {
        this.lastClick = Date.now();
    }

    updateHomepage(event) {
        const file = event.target.files[0];
        const fd = new FormData();
        fd.append('file', file, file.name);
        this.networkService.uploadHomePageFile(fd).subscribe();
    }

    getInstitutions() {
        this.networkService.getAllInstitutions().subscribe(
            (data) => (this.institutions = data),
            (error) => console.log(error),
        );
    }

    openModal() {
        if (this.institutions === undefined) {
            this.getInstitutions();
        }
        this.showModal = true;
        this.institution = '';
        this.email = '';
    }

    add() {
        this.networkService
            .createUser(this.email, this.institutionId, this.newUserRole)
            .subscribe(
                (data) => {
                    this.badRequest = false;
                    console.log(data);
                },
                (error) => {
                    this.badRequest = true;
                    console.log(error);
                },
            );
        this.openModal();
    }

    btnDisabled() {
        return (
            this.email === '' ||
            !this.email.includes('@') ||
            this.institution === ''
        );
    }

    resetMachineParams() {
        this.dataService.machineParameters = undefined;
    }

    resetInstitutionParams() {
        this.dataService.insMgntQuery = undefined;
    }

    handleKeyboardEvents(event) {
        if (
            this.showModal &&
            document.querySelector('.dropdown').classList.contains('open')
        ) {
            const institutions = [];
            this.institutions.forEach((i) => institutions.push(i.valueA));

            const result = UtilitiesService.handleKeyboardEvents(
                event,
                this.lastClick,
                institutions,
                this.search,
                null,
                null,
                true,
            );
            if (result) {
                this.search = result[0];
                this.lastClick = result[1];
            }
        }
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }
}
