<div>
    <div>
        <tile-search-breadcrumbs
            [showBreadCrumbs]="this.showBreadCrumbs()"
            [breadCrumbsStack]="this.breadCrumbsStack"
            [searchResults]="this.searchResults"
            [tileData]="this.tileData"
            [searchString]="this.searchString"
            [selectedCountry]="this.selectedCountry"
            [showSearchResults]="this.showSearchResults"
            (showTheResearchers)="this.showTheResearchers.emit($event)"
            (showTiles)="this.showTiles.emit($event)"
            (navigate)="this.navigate.emit($event)"
        ></tile-search-breadcrumbs>
    </div>
    <div class="map">
        <app-campus-map-d3
                *ngIf="!this.showSearchResults"
                (nlpStatus)="handleNlp($event)"
                [searchString]="this.searchString"
                [selectedCountry]="this.selectedCountry"
                [forceNLP]="false"
                [selectedAffiliation]="'All Institutions'"
                [tileData]="this.tileData"
                (showTheResearchers)="this.showTheResearchers.emit($event)"
                (breadCrumbsStackPop)="this._breadCrumbsStackPop()"
                (breadCrumbsStackPush)="this._breadCrumbsStackPush($event)"
                (updateMap)="this._updateMap($event)"
                [callStack]="this.callStack"
                (reset)="this.reset()"
        >

        </app-campus-map-d3>
    </div>
    <app-experts
            *ngIf="this.showSearchResults"
            [searchResults]="this.searchResults"
     >
    </app-experts>
</div>
