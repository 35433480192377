<div class="work-type-container" [class.editable]="!readonly" (click)="toggle()">
    <div class="card border" [class.selected]="value">
        <div class="card-header" style="font-size: 1.3rem">
                                <span class="material-symbols-outlined" style="vertical-align: sub">
                                    {{ icon }}
                                </span>
            <span>
                                    {{ header }}
                                </span>
        </div>
        <div class="card-body">
            <div class="pt-2">
                {{ body }}
            </div>
        </div>
        <div class="card-footer" *ngIf="!readonly">
            <input type="checkbox" [(ngModel)]="value" /> Get
            {{ footer }}
        </div>
    </div>
</div>
