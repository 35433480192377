<div class="saved-search-title" style="display: flex; flex-direction: row">
        <span class="editrow" child-scope>
          <span [hidden]="this.editing">
            <span>
              {{this.getTitle()}}
                </span>
              <span (click)="this.edit()" [hidden]="this.dataService.hideShared()">
                <img style="height: 20px; margin: 0px 5px;" src="/assets/images/edit-icon.svg"/>
            </span>
          </span>
          <span [hidden]="!this.editing || this.dataService.hideShared()">
            <input #titleInput style="min-width: 300px;" type="text"
                   [(ngModel)]="this.text"
                   (change)="this.updateTitle($event)"
                   (keyup.enter)="this.updateTitle($event)" (focusout)="updateTitle($event)">
            <span>
                <img style="height: 20px; margin: 0px 5px;" src="/assets/images/done-icon.svg"/>
            </span>
          </span>
        </span>
</div>
