import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../network.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {DataService} from '../../data.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    error_message: string;
    // username = '';
    // password = '';

    username = '';
    password = '';

    constructor(
        public dataService: DataService,
        public networkService: NetworkService,
        public router: Router,
    ) {
    }

    ngOnInit() {
        localStorage.clear();
        document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 2010 00:00:00 UTC; path=/; domain=.mysite.se';
    }

    async login() {
        try {
            //console.log(`here we are: ${this.username} /// ${this.password}`);
            const result = await this.networkService.login(this.username, this.password);
            // await this.router.navigate(['/app/projects']);
            if (result) {
                await this.dataService.updateUserInfo();
            }
            // else {
            //     this.dataService.userData = undefined;
            // }
            window.location.href = '/app/projects';

            // await this.networkService.updateUserInfo();
        } catch (e) {
            console.error(e);
        }
    }

    // async testAPI() {
    //     try {
    //         const result = await this.networkService.search('longevity');
    //         console.log(result);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

}
