<div>
    <ul class="breadcrumb">
        <li *ngIf="this.isExplorer()">
            <span id="query" title="{{ searchString }}"> Query </span>
        </li>
        <li>
            <a (click)="breadCrumbClicked($event)">{{ getBreadcrumbRootText() }}</a>
        </li>
        <li *ngFor="let crumb of breadCrumbsStack">
            <a (click)="breadCrumbClicked(crumb.id)">{{ crumb.name }}</a>
        </li>
        <span
                *ngIf="
                    (this.isCampus() && breadCrumbsStack.length > 0) ||
                    (this.isExplorer() && breadCrumbsStack.length > 0)"
        >
                <button *ngIf="!this.showSearchResults"
                        type="button"
                        class="btn btn-light btnCampus"
                        (click)="_showExperts()"
                >
                    Show the researchers
                </button>
                <button *ngIf="this.showSearchResults"
                        type="button"
                        class="btn btn-light btnCampus"
                        (click)="this.showTiles.emit()"
                >
                    Back to the tiles
                </button>
            </span>
    </ul>
</div>
