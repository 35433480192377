<div id="layer" *ngIf="loaded()">
    <div
        *ngIf="getRemainingDashboards() <= 0 && !finished"
        class="flex-top-margin"
    >
        <a class="btn-info btn-lg">
            <span
                class="glyphicon glyphicon-warning-sign"
                style="font-family: 'Glyphicons Halflings'"
            ></span>
            Your dashboard credit is empty. Please contact us at
            info@treebute.io
        </a>
    </div>

    <div *ngIf="finished" class="thank-you flex-top-margin">
        <p>Thank you.<br />We will contact you when your dashboard is ready.</p>
        <br />
        <div
            class="sample-resources"
            *ngIf="resources != null && resources.length > 0"
        >
            <label><u>Sample Scope Resources</u></label>
            <ul class="articles-ul">
                <li *ngFor="let resource of resources">
                    <a
                        [href]="resource.url"
                        target="_blank"
                        [class.pointer-events-none]="resource.url == '#'"
                        >{{ resource.title }}</a
                    >
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="getRemainingDashboards() > 0 && !finished" class="form-section">
        <form [formGroup]="form" class="dashboard-form">
            <h1>New Co-Pilot</h1>
            <div class="form-group">
                <label for="titleInput">Title</label>
                <input
                    type="text"
                    class="form-control"
                    id="titleInput"
                    placeholder="Enter Title"
                    formControlName="title"
                    required
                />
            </div>
            <div class="form-group">
                <label for="scopeOfWork">Scope Of Work</label>
                <small
                    id="scopeOfWorkHelp"
                    class="form-text"
                    style="display: block"
                    >Please include: How you intend to use the dashboard? What
                    are you trying to learn?</small
                >
                <textarea
                    class="form-control"
                    id="scopeOfWork"
                    rows="3"
                    formControlName="scope"
                    required
                ></textarea>
            </div>

            <div class="form-group">
                <label>Geography</label>
                <small
                    id="GeographyHelp"
                    class="form-text"
                    style="display: block"
                    >World wide/specific country/area</small
                >
                <input
                    type="text"
                    class="form-control"
                    id="geographyInput"
                    placeholder="Enter Geography Information"
                    formControlName="geographyText"
                />
            </div>

            <div class="form-group" formGroupName="visualElements">
                <label>Entities To Include</label>
                <div
                    class="form-check"
                    *ngFor="
                        let item of form.get('visualElements').value | keyvalue
                    "
                >
                    <label>
                        <input
                            type="checkbox"
                            [formControlName]="item.key"
                            [value]="item.value"
                        />
                        {{ item.key }}
                    </label>
                </div>
            </div>

            <div class="form-group">
                <label for="otherInput">Anything else to include</label>
                <textarea
                    class="form-control"
                    id="otherInput"
                    rows="3"
                    formControlName="other"
                ></textarea>
            </div>

            <div class="form-group">
                <label for="refreshRateSelect">Refresh Rate</label>
                <select
                    class="form-control"
                    id="refreshRateSelect"
                    formControlName="refreshRate"
                >
                    <option>Weekly</option>
                    <option>Monthly</option>
                    <option>Yearly</option>
                    <option>Never</option>
                </select>
            </div>

            <div class="form-group">
                <label>Additional websites</label>
                <div style="display: flex">
                    <input
                        #urlInput
                        type="text"
                        class="form-control"
                        placeholder="URL"
                    />
                    <button
                        class="btn btn-primary"
                        (click)="addAdditionalUrl(urlInput)"
                    >
                        Add
                    </button>
                </div>
                <ul>
                    <li *ngFor="let url of additionalUrls; let i = index">
                        {{ url
                        }}<button
                            style="margin-left: 15px"
                            class="btn btn-danger"
                            (click)="removeAdditionalUrl(i)"
                        >
                            Remove
                        </button>
                    </li>
                </ul>
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{ error }}
            </div>

            <div class="alert alert-secondary" role="alert" *ngIf="submitting">
                Submitting your request. Please wait...
            </div>
            <button
                type="submit"
                [disabled]="submitting"
                (click)="onSubmit()"
                class="btn btn-primary"
            >
                Submit
            </button>
        </form>

        <div class="images">
            <img src="assets/images/user-dashboard/1.png" />
            <img src="assets/images/user-dashboard/2.png" />
            <img src="assets/images/user-dashboard/3.png" />
            <img src="assets/images/user-dashboard/4.png" />
        </div>
    </div>
</div>
