import {Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {NetworkService} from "../../../network.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Institution} from "../../../api-responses/SearchQueryResults.interface";
import {SelectModalData, SelectModalSelectItem, YesNoModalData} from "../../../api-responses/Dialogs.interface";

@Component({
    selector: 'select-modal',
    templateUrl: 'select-modal.component.html',
    styleUrls: ['select-modal.component.scss'],
})
export class SelectModalComponent implements OnInit, OnDestroy, OnChanges {

    items: SelectModalSelectItem[];

    constructor(public networkService: NetworkService,
                public dialogRef: MatDialogRef<SelectModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: SelectModalData) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        if (!this.data.values) return;
        this.items = this.data.values;
    }

    selectItem(item: SelectModalSelectItem) {
        this.dialogRef.close(item);
    }

    closeModal() {
        this.dialogRef.close();
    }

    selectItems() {
        this.dialogRef.close(this.items.filter(x =>x.selected));
    }
}
