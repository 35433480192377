import {
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NetworkService } from '../../network.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilitiesService } from '../../utilities.service';
import { FloatingUpdateService } from '../../floating-update.service';
import { DataService } from '../../data.service';

@Component({
    selector: 'app-experts-ct',
    templateUrl: './experts-ct.component.html',
    styleUrls: ['./experts-ct.component.scss'],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class ExpertsCtComponent implements OnInit, OnDestroy {
    loading = false;
    searchString;
    expertsData: any;
    noResults = false;
    showModal = false;
    selectedExpert = undefined;
    wordCloud: any;
    showWordCloud = false;
    enableNextExpert = false;
    enablePreviousExpert = false;
    exportLoading = false;
    feedbackOpen = false;
    innerFeedbackOpen = false;
    patentsPresent = false;
    isCountriesDropdownOpen = false;
    isPhasesDropdownOpen = false;
    isStatusesDropdownOpen = false;
    selectedPhase = 'All Phases';
    selectedCountry = 'All Countries';
    selectedStatus = 'All Statuses';
    piecesOfEvidence = [];
    selectedExperts = [];
    phases = [];
    countries = [];
    statuses = [];
    stages = [
        'Analyzing Clinical Trials',
        'Disambiguating Researchers',
        'Checking For Publications',
        'Checking For Patents',
        'Preparing Results',
    ];
    stageIndex;
    interval;
    lastClick;
    search = '';
    evidenceTabActive = true;
    googleSheets = false;
    private _routerSub = Subscription.EMPTY;

    constructor(
        private networkService: NetworkService,
        private router: Router,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private floatingUpdateService: FloatingUpdateService,
        public dataService: DataService,
    ) {
        this._routerSub = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const searchString =
                        this.route.snapshot.params['searchString'];
                    this.searchString =
                        searchString.length >= 3 ? searchString : '';
                    if (this.searchString !== '') {
                        this.onSearchSubmit();
                    }
                }
            });
    }

    @HostListener('document:click')
    documentClick() {
        setTimeout(() => this.checkDropdown(), 1);
    }

    ngOnInit() {
        this.lastClick = Date.now();
    }

    ngOnDestroy() {
        this._routerSub.unsubscribe();
    }

    searchDisabled() {
        return this.searchString === undefined || this.searchString.length < 3;
    }

    onSearchSubmit() {
        const self = this;
        this.stageIndex = 0;
        this.interval = setInterval(function () {
            self.handleInterval1();
        }, 5000);
        this.loading = true;
        this.noResults = false;
        this.wordCloud = undefined;
        this.showWordCloud = false;
        this.floatingUpdateService.notifyWordSuggestionUpdated(undefined);
        this.expertsData = undefined;
        this.selectedPhase = 'All Phases';
        this.selectedCountry = 'All Countries';
        this.selectedStatus = 'All Statuses';
        this.networkService.getCtExpertsByQuery(this.searchString).subscribe(
            (data) => this.handleCtResponse(data),
            (error) => console.log(error),
        );
    }

    handleKeyboardEvents(event) {
        if (this.isCountriesDropdownOpen) {
            const result = UtilitiesService.handleKeyboardEvents(
                event,
                this.lastClick,
                this.countries,
                this.search,
            );
            if (result) {
                this.search = result[0];
                this.lastClick = result[1];
            }
        }
    }

    handleInterval1() {
        if (this.stageIndex < this.stages.length - 1) {
            this.stageIndex++;
        }
    }

    handleInterval2(data) {
        if (this.stageIndex < this.stages.length - 1) {
            this.stageIndex++;
        } else {
            this.handleCtResponse(data);
        }
    }

    flipWordCloud() {
        this.showWordCloud = !this.showWordCloud;
    }

    handleCtResponse(data) {
        clearInterval(this.interval);
        if (this.stageIndex < this.stages.length - 1) {
            const self = this;
            this.interval = setInterval(function () {
                self.handleInterval2(data);
            }, 200);
        } else {
            this.loading = false;
            if (data.valueA.length < 1) {
                this.noResults = true;
            } else {
                this.expertsData = data.valueA;
                this.wordCloud = data.valueC;
                this.handleFilters();
                this.checkPatents();
            }
        }
    }

    checkPatents() {
        this.patentsPresent = false;

        this.expertsData.forEach((expert) => {
            if (expert.patentsScore > 0) {
                this.patentsPresent = true;
            }
        });
    }

    handlePiecesOfEvidenceArr(expert) {
        this.piecesOfEvidence = [];

        for (const clinicalTrial of expert.clinicalTrials) {
            const obj = {
                type: 'CT',
                title: clinicalTrial.valueB,
                url: `https://clinicaltrials.gov/ct2/show/study/${clinicalTrial.valueA}`,
                phase: clinicalTrial.valueC,
            };
            this.piecesOfEvidence.push(obj);
        }

        for (const publication of expert.publicationsWithDates) {
            const obj = {
                type: 'ARTICLE',
                title: publication.valueC,
                url: publication.valueD,
            };
            this.piecesOfEvidence.push(obj);
        }

        for (const patent of expert.patentTitlesToURLs) {
            const obj = {
                type: 'PATENT',
                title: patent.valueA,
                url: patent.valueB,
            };
            this.piecesOfEvidence.push(obj);
        }
    }

    filterExperts() {
        let response = this.expertsData;

        if (this.selectedPhase !== 'All Phases') {
            response = response.filter((expert) =>
                expert.clinicalTrialPhases.includes(this.selectedPhase),
            );
        }
        if (this.selectedCountry !== 'All Countries') {
            response = response.filter((expert) =>
                expert.countries.includes(this.selectedCountry),
            );
        }
        if (this.selectedStatus !== 'All Statuses') {
            response = response.filter((expert) =>
                expert.clinicalTrialStatus.includes(this.selectedStatus),
            );
        }

        return response;
    }

    handleFilters() {
        const phasesArr = [];
        const countriesArr = [];
        const statusesArr = [];

        this.expertsData.forEach((expert) => {
            expert.clinicalTrialPhases.forEach((phase) => {
                phasesArr.push(phase);
            });

            expert.countries.forEach((country) => {
                countriesArr.push(country);
            });

            expert.clinicalTrialStatus.forEach((status) => {
                statusesArr.push(status);
            });
        });

        const phasesSet = new Set(phasesArr);
        this.phases = Array.from(phasesSet).sort();

        const countriesSet = new Set(countriesArr);
        this.countries = Array.from(countriesSet).sort();

        const statusesSet = new Set(statusesArr);
        this.statuses = Array.from(statusesSet).sort();
    }

    expertCardClicked(event, expert) {
        if (
            event.target.classList[0] !== 'name' &&
            event.target.classList[0] !== 'email' &&
            event.target.classList[0] !== 'read_more_button'
        ) {
            if (!this.selectedExperts.includes(expert)) {
                this.selectedExperts.push(expert);
            } else {
                this.selectedExperts = this.selectedExperts.filter(
                    (e) => e !== expert,
                );
            }
        }
    }

    exportBtnClicked() {
        this.exportLoading = true;
        document.getElementById('exportBtn').style.backgroundImage =
            "url('assets/images/loadloop.gif')";

        let experts = this.filterExperts();
        if (
            this.selectedExperts.filter((expert) => experts.includes(expert))
                .length > 0
        ) {
            experts = experts.filter((expert) =>
                this.selectedExperts.includes(expert),
            );
        }

        const date = new Date();

        this.networkService
            .exportCtExperts(
                experts,
                this.searchString,
                this.selectedPhase,
                this.selectedCountry,
                this.selectedStatus,
                date,
                this.googleSheets,
            )
            .subscribe(
                (data) => {
                    this.exportLoading = false;
                    document.getElementById('exportBtn').style.backgroundImage =
                        "url('assets/images/export-icon-dark.png')";
                    this.cdRef.detectChanges();
                    const queryTrimmed =
                        this.searchString === undefined
                            ? null
                            : this.searchString.length < 50
                            ? this.searchString
                            : this.searchString.substring(0, 50) + '...';
                    const filename =
                        queryTrimmed + ' - clinical trials experts.xlsx';
                    const options = { type: 'application/ms-excel' };
                    this.createAndDownloadBlobFile(data, options, filename);
                },
                (error) => {
                    this.exportLoading = false;
                    document.getElementById('exportBtn').style.backgroundImage =
                        "url('assets/images/export-icon-dark.png')";
                    this.cdRef.detectChanges();
                    console.log(error);
                },
            );
    }

    createAndDownloadBlobFile(body, options, filename) {
        const blob = new Blob([body], options);
            // Browsers that support HTML5 download attribute
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
        }
    }

    openFeedback() {
        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
        this.feedbackOpen = true;
    }

    closeFeedback() {
        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'auto';
        this.feedbackOpen = false;
    }

    expertClicked(expert) {
        this.evidenceTabActive = true;
        this.selectedExpert = expert;
        this.handlePiecesOfEvidenceArr(this.selectedExpert);
        this.checkSwitch();
        this.showModal = true;
        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
    }

    closeModal() {
        this.evidenceTabActive = true;
        this.showModal = false;
        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'auto';
    }

    nextExpert() {
        if (document.getElementById('companiesContent')) {
            document.getElementById('companiesContent').style.left = '0px';
        }
        this.evidenceTabActive = true;
        this.selectedExpert =
            this.filterExperts()[
                this.filterExperts().indexOf(this.selectedExpert) + 1
            ];
        this.handlePiecesOfEvidenceArr(this.selectedExpert);
        this.checkSwitch();
    }

    previousExpert() {
        if (document.getElementById('companiesContent')) {
            document.getElementById('companiesContent').style.left = '0px';
        }
        this.evidenceTabActive = true;
        this.selectedExpert =
            this.filterExperts()[
                this.filterExperts().indexOf(this.selectedExpert) - 1
            ];
        this.handlePiecesOfEvidenceArr(this.selectedExpert);
        this.checkSwitch();
    }

    checkSwitch() {
        this.enableNextExpert =
            this.filterExperts().indexOf(this.selectedExpert) + 1 <=
            this.filterExperts().length - 1;
        this.enablePreviousExpert =
            this.filterExperts().indexOf(this.selectedExpert) - 1 >= 0;
    }

    checkDropdown() {
        this.isCountriesDropdownOpen =
            document.querySelector('.countriesDropdown') &&
            document
                .querySelector('.countriesDropdown')
                .classList.contains('open');
        this.isPhasesDropdownOpen =
            document.querySelector('.phasesDropdown') &&
            document
                .querySelector('.phasesDropdown')
                .classList.contains('open');
        this.isStatusesDropdownOpen =
            document.querySelector('.statusesDropdown') &&
            document
                .querySelector('.statusesDropdown')
                .classList.contains('open');
    }

    ellipsisCompany(company, maxLength) {
        if (company.length > maxLength) {
            company = company.substring(0, maxLength) + '...';
        }
        return company;
    }

    checkPreviousCompany() {
        const len = this.selectedExpert.workedWithIndustryNames.length;
        const left = document.getElementById('companiesContent').style.left;
        if (this.vw() > 900) {
            return Number(left.slice(0, left.length - 2)) === 0 || len <= 3;
        } else {
            return Number(left.slice(0, left.length - 2)) === 0 || len <= 1;
        }
    }

    checkNextCompany() {
        const len = this.selectedExpert.workedWithIndustryNames.length;
        const left = document.getElementById('companiesContent').style.left;
        if (this.vw() > 900) {
            return (
                Math.abs(Number(left.slice(0, left.length - 2))) ===
                    (len - 3) * 181 || len <= 3
            );
        } else {
            return (
                Math.abs(Number(left.slice(0, left.length - 2))) ===
                    (len - 1) * 181 || len <= 1
            );
        }
    }

    previousCompany() {
        const left = document.getElementById('companiesContent').style.left;
        document.getElementById('companiesContent').style.left =
            Number(left.slice(0, left.length - 2)) + 181 + 'px';
    }

    nextCompany() {
        const left = document.getElementById('companiesContent').style.left;
        document.getElementById('companiesContent').style.left =
            Number(left.slice(0, left.length - 2)) - 181 + 'px';
    }

    normalize(number, oldRange, newRange) {
        return Math.round((number * newRange) / oldRange);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }
}
