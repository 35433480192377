<div class="empty-project" (click)="openDialog()">
    <div class="empty-project__icon empty-project-icon"></div>
    <div class="empty-project__title">Create new project</div>
    <div class="empty-project__subtitle">
        Store all your searches and explorations under one roof
    </div>
    <div class="empty-project__button">
        <div class="empty-project__button-icon white-plus-icon"></div>
        <div class="empty-project__button-title">Create new project</div>
    </div>
</div>
