import {Component, Inject, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ShareModalData, YesNoModalData} from "../../../api-responses/Dialogs.interface";
import {NetworkService} from "../../../network.service";

@Component({
    selector: 'share-project-modal',
    templateUrl: './share-modal.component.html',
    styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {
    showLoader: boolean = false;
    validCanonical: boolean = null;

    constructor(public dialogRef: MatDialogRef<ShareModalComponent>,
                public networkService: NetworkService,
                @Inject(MAT_DIALOG_DATA) public data: ShareModalData) {
        this.validCanonical = data.validCanonical;
    }

    ngOnInit(): void {
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(this.data);
    }

    getShareLink() {
        let canonical = this.data.canonicalUrl;
        if (canonical) {
            canonical = this.data.canonicalUrl.split(/\s/).join('-');
            canonical = encodeURI(canonical.toLowerCase());
        } else {
            canonical = '<< canonical >>'
        }

        return `https://reports.treebute.io/${this.data.canonicalPrefix}/${canonical}.html`;
    }

    checkCanonical($event) {
        this.networkService.validCanonical(this.data.projectId, this.data.canonicalUrl).then((res) => {
            this.validCanonical = res;
        })
            .finally(() => {
                this.showLoader = false;
            })
    }

}
