import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../../network.service';

@Component({
    selector: 'app-users-group-creation',
    templateUrl: './users-group-creation.component.html',
    styleUrls: ['./users-group-creation.component.scss'],
})
export class UsersGroupCreationComponent implements OnInit {
    model: any = {};

    constructor(private networkService: NetworkService) {}

    ngOnInit() {}

    onSubmit() {
        this.networkService.createNewUsersGroup(this.model).subscribe(
            () => {},
            (error) => console.log(error),
        );
    }
}
