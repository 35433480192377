<div
        id="company-modal"
        role="dialog"
>
    <div class="detailsContainer">
        <button
                style="background-image: url('assets/images/experts-next.png')"
                id="nextExpert"
                (click)="nextExpert()"
                [disabled]="!enableNextExpert()"
        ></button>
        <button
                style="background-image: url('assets/images/experts-next.png')"
                id="previousExpert"
                (click)="previousExpert()"
                [disabled]="!enablePreviousExpert()"
        ></button>

        <div *ngIf="searchResults"
                id="innerFeedback"
                style="background-image: url('assets/images/feedback.png')"
                (click)="openFeedback(selectedExpert)"
        ></div>

<!--        <div-->
<!--                *ngIf="innerFeedbackOpen && vw() >= 600"-->
<!--                class="modal fade in"-->
<!--                id="innerFeedbackContainer"-->
<!--        >-->
<!--            <app-feedback-window-->
<!--                    (closeModal)="innerFeedbackOpen = false"-->
<!--                    [expert]="selectedExpert.lastName"-->
<!--                    [query]="searchString"-->
<!--                    [scope]="'Clinical trials expert'"-->
<!--            ></app-feedback-window>-->
<!--        </div>-->

        <div
                class="header"
                [class.noTabs]="
                !selectedExpert.workedWith ||
                selectedExpert.workedWith.length === 0
            "
        >
            <img
                    src="assets/images/close-dark.png"
                    class="closeIcon"
                    (click)="closeModal()"
            />
            <div class="d-flex justify-content-start">
                <div
                        class="cardName ellipsis "
                        title=""
                        [style.color]="
                        selectedExpert.company
                            ? '#228B22'
                            : selectedExpert.ctInvestigator
                            ? '#008ded'
                            : '#e85a4e'
                    "
                        (ngChange)="checkWidth($event, selectedExpert.lastName)"
                        (mouseover)="checkWidth($event, selectedExpert.lastName)"
                >
                    {{ selectedExpert.lastName }}
                </div>
                <div>
                    <button class="btn-primary mx-2 px-1" [matMenuTriggerFor]="menu">
                        <small>Connect</small>
                    </button>
                    <mat-menu #menu="matMenu" >
                        <button class="px-3" mat-menu-item (click)="newRequest(selectedExpert)">New Request</button>
                        <button class="px-3" mat-menu-item (click)="addToRequest(selectedExpert)" >Add to Request...</button>
                    </mat-menu>
                </div>
            </div>
            <div
                    class="headerContent"
                    *ngIf="selectedExpert.department || selectedExpert.email"
            >
                <span
                        *ngIf="selectedExpert.department"
                        (ngChange)="checkWidth($event, selectedExpert.department)"
                        (mouseover)="checkWidth($event, selectedExpert.department)"
                        class="ellipsis department grayLink"
                        title=""
                        [class.primaryDep]="
                        selectedExpert.department && !selectedExpert.email
                    "
                >
                    <a href="{{ getGoogleSearchUrl(selectedExpert.department) }}" target="_blank">{{selectedExpert.department}}</a>
                </span>

                <div
                        *ngIf="selectedExpert.email"
                        class="innerEmail"
                        [class.primary]="
                        !selectedExpert.department && selectedExpert.email
                    "
                >
                    <img src="assets/images/envelope_blue_dark.png" />
                    <a
                            target="_blank"
                            href="mailto:{{ selectedExpert.email }}"
                            class="ellipsis"
                    >
                        {{ selectedExpert.email }}
                    </a>
                </div>
            </div>

            <div
                    class="tab"
                    *ngIf="
                    selectedExpert.workedWith &&
                    selectedExpert.workedWith.length > 0
                "
            >
                <button
                        class="tablinks"
                        (click)="evidenceTabActive = true"
                        [class.active]="evidenceTabActive"
                >
                    Evidence
                </button>
                <button
                        class="tablinks"
                        (click)="evidenceTabActive = false"
                        [class.active]="!evidenceTabActive"
                >
                    Collaborations
                </button>
            </div>
        </div>

        <div
                class="articles"
                *ngIf="
                piecesOfEvidence &&
                piecesOfEvidence.length > 0 &&
                evidenceTabActive
            "
        >
            <div class="cardTitle">
                <span>
                    {{ piecesOfEvidence.length }}
                    {{
	                piecesOfEvidence.length === 1
		                ? 'Piece of Evidence'
		                : 'Pieces of Evidence'
                    }}
                </span>
            </div>

            <div class="table">
                <div class="articlesTable">
                    <div
                            class="article"
                            *ngFor="let resource of piecesOfEvidence"
                    >
                        <span class="articleNumber">
                            {{ piecesOfEvidence.indexOf(resource) + 1 }}
                        </span>
                        <div
                                class="publicationTypeLogo"
                                [style.backgroundImage]="
                                resource.type === 'ARTICLE'
                                    ? 'url(assets/images/articles_dark.png)'
                                    : resource.type === 'PATENT'
                                    ? 'url(assets/images/innovation_blue_dark.png)'
                                    : resource.type === 'CT'
                                    ? 'url(assets/images/clinical_trials_icon_dark.png)'
                                    : 'none'
                            "
                        ></div>
                        <a
                                class="articleName ellipsis"
                                href="{{ resource.url }}"
                                target="_blank"
                                title=""
                                (ngChange)="checkWidth($event, resource.title)"
                                (mouseover)="checkWidth($event, resource.title)"
                        >{{ resource.title }}</a
                        >
                        <div
                                *ngIf="
                                resource.type === 'CT' &&
                                resource.phase &&
                                resource.phase !== '[]'
                            "
                                style="
                                flex-shrink: 0;
                                align-self: center;
                                padding-right: 15px;
                                color: #b9646e;
                                font-size: 13px;
                                letter-spacing: 0.2px;
                            "
                        >
                            {{ resource.phase }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
                *ngIf="!evidenceTabActive && selectedExpert.workedWith && selectedExpert.workedWith.length > 0"
        >
            <div class="cardTitle" style="padding-bottom: 10px">
                <img
                        src="assets/images/people-dark.png"
                        style="width: 32px; height: 28px"
                />
                <span> Worked with others </span>
            </div>

            <div
                    class="bars evidenceBars"
                    style="max-height: 300px; overflow: auto; margin-left: 0"
                    [class.fit]="vw() < 900 && selectedExpert.workedWith.length < 6"
            >
                <div
                        class="barContainer"
                        *ngFor="let i of selectedExpert.workedWith"
                >
                    <span
                            class="barTitle"
                            (ngChange)="checkWidth($event, i.valueA)"
                            (mouseover)="checkWidth($event, i.valueA)"
                            title=""
                            style="font-weight: bold; font-size: 12px"
                            [style.width]="
                            vw() <= 480
                                ? 'calc(100% - 170px)'
                                : vw() <= 900
                                ? '120px'
                                : '170px'
                        "
                            [style.paddingRight]="vw() <= 900 ? '20px' : '40px'"
                    >{{ i.valueA }}</span
                    >
                    <span
                            *ngIf="vw() > 480"
                            (ngChange)="checkWidth($event, i.valueB)"
                            (mouseover)="checkWidth($event, i.valueB)"
                            class="department tt ellipsis"
                            title=""
                            style="font-weight: 100"
                            [style.width]="
                            vw() <= 900
                                ? 'calc(100% - 290px)'
                                : 'calc(100% - 340px)'
                        "
                            [style.padding]="
                            vw() <= 900 ? '0 8px 0 0' : '0 28px 0 0'
                        "
                    >{{ i.valueB }}</span
                    >

                    <div class="barContent">
                        <div
                                class="barr"
                                style="border: solid 1px #b9646e"
                                title="{{ i.valueD }} {{
                                i.valueD > 1 ? 'times' : 'time'
                            }}"
                        >
                            <div
                                    [style.background]="'#b9646e'"
                                    [style.width]="
                                    normalize(
                                        i.valueD,
                                        selectedExpert.workedWith[0].valueD,
                                        120
                                    ) +
                                    2 +
                                    'px'
                                "
                                    [class.barr-fill]="
                                    normalize(
                                        i.valueD,
                                        selectedExpert.workedWith[0].valueD,
                                        120
                                    ) > 119
                                "
                            ></div>
                        </div>

                        <div class="email_containerr">
                            <a
                                    *ngIf="i.valueC"
                                    target="_blank"
                                    href="mailto:{{ i.valueC }}"
                                    title="{{ i.valueC }}"
                            >
                                <img
                                        src="assets/images/envelope-icon-dark.png"
                                        class="envelope_iconn"
                                        [style.marginRight]="
                                        vw() <= 900 ? '8px' : '0'
                                    "
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
                class="grants"
                *ngIf="
                selectedExpert.workedWithIndustryNames &&
                selectedExpert.workedWithIndustryNames.length > 0
            "
        >
            <div
                    class="bottomTitle"
                    style="padding-bottom: 0"
                    [class.pad]="vw() < 900"
            >
                <img
                        src="assets/images/building-dark.png"
                        style="width: 29px; height: 31px; margin-top: 3px"
                />
                <span
                        style="line-height: 20px; margin-bottom: -2.5px"
                        *ngIf="selectedExpert.workedWithIndustryNames.length === 1"
                >
                    Worked with
                    {{ selectedExpert.workedWithIndustryNames.length }} company
                </span>
                <span
                        style="line-height: 20px; margin-bottom: -2.5px"
                        *ngIf="selectedExpert.workedWithIndustryNames.length > 1"
                >
                    Worked with
                    {{ selectedExpert.workedWithIndustryNames.length }} companies
                </span>
            </div>

            <div class="grantsList">
                <button
                        (click)="previousCompany()"
                        [disabled]="checkPreviousCompany()"
                        [class.nv]="
                        vw() > 900
                            ? selectedExpert.workedWithIndustryNames.length <= 3
                            : selectedExpert.workedWithIndustryNames.length === 0
                    "
                >
                    <img
                            src="assets/images/previous_arrow-dark.png"
                            [class.disabled]="checkPreviousCompany()"
                    />
                </button>

                <div class="grantsContainer">
                    <div
                            id="companiesContent"
                            [class.one]="
                            selectedExpert.workedWithIndustryNames.length === 1
                        "
                            [class.two]="
                            selectedExpert.workedWithIndustryNames.length === 2
                        "
                    >
                        <div
                                class="grant"
                                *ngFor="
                                let company of this.selectedExpert
                                    .workedWithIndustryNames
                            "
                        >
                            <div
                                    *ngIf="company === ellipsisCompany(company, 45)"
                            >
                                {{ ellipsisCompany(company, 45) }}
                            </div>
                            <span
                                    title="{{ company }}"
                                    *ngIf="company !== ellipsisCompany(company, 45)"
                            >
                                {{ ellipsisCompany(company, 45) }}
                            </span>
                        </div>
                    </div>
                </div>

                <button
                        (click)="nextCompany()"
                        [disabled]="checkNextCompany()"
                        [class.nv]="
                        vw() > 900
                            ? selectedExpert.workedWithIndustryNames.length <= 3
                            : selectedExpert.workedWithIndustryNames.length === 0
                    "
                >
                    <img
                            src="assets/images/next_arrow-dark.png"
                            [class.disabled]="checkNextCompany()"
                    />
                </button>
            </div>
        </div>
    </div>
</div>
