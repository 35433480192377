import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';
import {DepositResponse} from './pages/deposit/deposit';
import {ActivatedRoute, Router} from '@angular/router';
import {InnovationProject, RefreshProjectDto} from './api-responses/InnovationProjects.interface';
import {InvitedUser, RegistrationForm, TreebuteAccount, User, UserInfo} from './api-responses/UserInfo.interface';
import {SearchTypes} from './api-responses/SearchTypes.interface';
import {
    Institution,
    InstitutionResult,
    Researcher,
    SearchQueryResults,
    WordCloud
} from './api-responses/SearchQueryResults.interface';
import {NewProjectResult} from './api-responses/NewProjectResult.interface';
// import {DataService} from "./data.service";
import {ExportProject, ShareModalData} from "./api-responses/Dialogs.interface";
import {Searches} from "./network/searches";
import { LinkedInResult } from './api-responses/graphs.interface';
import {SharedProject} from "./read-only-project-service";
import {ManifestLine} from "./pages/admin/open-alex/ManifestLine";
import {Callout} from "./pages/callout/callouts/callout.interface";
import {Callouts} from "./network/callouts";
import {NetworkServiceCanvas} from "./network/network-service-canvas.service";
import {NetworkServiceInnovationProjectService} from "./network/network-service-innovation-project.service";

@Injectable({
    providedIn: 'root',
})
export class NetworkService {
    projects$ = new ReplaySubject<Array<InnovationProject>>();

    publets$: any;

    userInfoUrl = '/api/v1/users/userinfo';
    updateUserDetailsUrl = '/iapi/updateUserInfo';
    getPubletsUrl = '/iapi/getPublets';
    depositNewPubletUrl = '/iapi/depositPublet';
    uploadPubletsFileUrl = '/iapi/publets/uploadFile';
    getHierarchyUrl = '/iapi/getPubletHierarchy?publetId=';
    getUserGroupsUrl = '/iapi/admin/getUserGroups?limit=';
    getMatchingDescriptorsUrl = '/iapi/knowledgeMap/getMatchingDescriptors';
    getExpertsByQueryUrl = '/iapi/knowledgeMap/getExperts';
    getIndustryExpertsByQueryUrl = '/iapi/knowledgeMap/getIndustryExperts';
    createNewUsersGroupUrl = '/iapi/admin/createNewUsersGroup';
    getUsersInGroupUrl = '/iapi/admin/getUsersInGroup?groupId=';
    createNewUserInGroupUrl = '/iapi/admin/createNewUserInGroup';
    deleteUserInGroupUrl = '/iapi/admin/deleteUserInGroup?userInGroupId=';
    getGroupUsersUrl = '/iapi/getUsersInGroup';
    getCampusMapUrl = '/iapi/knowledgeMap/getHeatMap?parent=';
    getResearchersForDescriptorUrl =
        '/iapi/knowledgeMap/getResearches?parents=';
    getDescriptorsMappingUrl =
        '/iapi/knowledgeMap/getDescriptorsForPublications';
    getTrendingInStateByQueryUrl =
        '/iapi/knowledgeMap/getTrendingInStateByQuery?query=';
    getCompaniesByQueryUrl = '/iapi/knowledgeMap/getCompanies';
    getCompanyExtraInfoUrl = '/iapi/knowledgeMap/getCompanyExtraInfo';
    getInstitutionByNameUrl = '/iapi/admin/getInstitution';
    UpdateInstitutionDetailsUrl = '/iapi/admin/updateInstitution';
    uploadHomePageFileUrl = '/iapi/admin/updateHomePage';
    exportReaserchersUrl = 'iapi/knowledgeMap/exportResults';
    createInstitutionUrl = '/iapi/admin/createInstitution';
    deleteInstitutionUrl = '/iapi/admin/deleteInstitution';
    getAllPossibleInstitutionUrl = '/iapi/getAllPossibleInstitution';
    adminMachineSearch = 'iapi/admin/machine/search';
    adminMachineUpdate = '/iapi/admin/updateMachine';
    adminMachineDocumentUpdate = '/iapi/admin/updateMachineDocument';
    adminMachineCreate = '/iapi/admin/machine/create';
    adminMachineGetInstance = '/iapi/admin/machine/getInstance';
    adminMachineInstanceUpdate = '/iapi/admin/updateInstance';
    adminMachineInstanceDocumentUpdate = '/iapi/admin/updateInstanceDocument';
    adminMachineInstanceResearcherUpdate =
        '/iapi/admin/updateInstanceResearcher';
    adminMachineInstanceCreate = '/iapi/admin/machine/instance/create';
    adminMachineInstanceDelete = '/iapi/admin/machine/deleteInstance';
    adminMachineDelete = '/iapi/admin/machine/deleteMachine';
    adminUserDetails = '/iapi/admin/getUserDetails';
    adminUserDetailsUpdate = '/iapi/admin/updateUserDetails';
    adminPaymentRequestUpdate = '/iapi/admin/updatePayment';
    adminPaymentRequestCreate = '/iapi/admin/createPayment';
    getNonIndustryInstitutionsUrl = '/iapi/knowledgeMap/getInstitutions';
    adminInvestorSearch = 'iapi/admin/investor/search';
    adminInvestorCreate = '/iapi/admin/investor/create';
    adminInvestorUpdate = '/iapi/admin/updateInvestor';
    adminInvestorDelete = '/iapi/admin/investor/deleteInvestor';
    adminInvestorCompanies = '/iapi/admin/investor/getCompanies';
    adminEsriCsvGeneratorUrl = `/dashboard/generateEsriCsv`;
    getTimelineUrl = `/iapi/getTimeline`;

    constructor(
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        public searches: Searches,
        public callouts: Callouts,
        public canvas: NetworkServiceCanvas,
        public innovationProjects: NetworkServiceInnovationProjectService
    ) {
        if (window.location.href.indexOf('/app/register') > -1) {
            return;
        }
        // this.updateUserInfo();
    }

    XupdateUserInfo() {
        return this.http
            .get<UserInfo>('/api/v1/users/userinfo')
            .toPromise()
    }

    async XgetUserInfo() {
        return this.http.get<UserInfo>(this.userInfoUrl).toPromise();
    }

    // getPublets() {
    //     if (this.publets$ === undefined) {
    //         this.publets$ = this.http
    //             .get(this.getPubletsUrl)
    //             .pipe(shareReplay(1));
    //     }
    //     return this.publets$;
    // }

    depositNewPublet(model): Observable<DepositResponse> {
        this.publets$ = undefined; // Clearing the publets cache.
        return this.http.post<DepositResponse>(this.depositNewPubletUrl, model);
    }

    getGroupUsers() {
        return this.http.get(this.getGroupUsersUrl);
    }

    createUser(email, institution, role) {
        return this.http.get(
            `/iapi/admin/createUser?email=${email}&instituteId=${institution}&role=${role}`,
        );
    }

    getLinkedIn(name, affiliation) {
        return this.http.get(
            `iapi/knowledgeMap/getLinkedIn?name=${name}&affiliation=${affiliation}`,
        );

        ///
    }

    getGoogleScholar(name, affiliation) {
        return this.http.get(
            `iapi/knowledgeMap/getGoogleScholarLink?name=${name}&affiliation=${affiliation}`,
        );

        ///
    }

    createNewUsersGroup(model) {
        return this.http.post(this.createNewUsersGroupUrl, model);
    }

    createNewUserInGroup(newUser) {
        return this.http.post(this.createNewUserInGroupUrl, newUser);
    }

    getDescriptorsMapping(
        firstName,
        lastName,
        initials,
        publications,
        parentId,
        email,
        industrySearch,
        globalSearch,
        affiliation,
        allNames,
        affiliations,
    ) {
        let params = new HttpParams().set('publicationIds', publications.map(x => x['valueA']));

        if (firstName) {
            params = params.append('firstName', firstName);
        }
        if (lastName) {
            params = params.append('lastName', lastName);
        }
        if (initials) {
            params = params.append('initials', initials);
        }
        if (email) {
            params = params.append('email', email);
        }
        if (industrySearch) {
            params = params.append('industrySearch', industrySearch);
        }
        if (globalSearch) {
            params = params.append('globalSearch', globalSearch);
        }
        if (affiliation) {
            params = params.append('affiliation', affiliation);
        }
        if (affiliations) {
            const affiliationsWithoutCommas = new Array();
            affiliations.forEach((aff) => {
                affiliationsWithoutCommas.push(aff.replace(/,/g, 'SpCe'));
            });
            params = params.append(
                'affiliations',
                affiliationsWithoutCommas.toString(),
            );
        }
        if (allNames) {
            params = params.append('allNames', allNames);
        }

        return this.http.get(this.getDescriptorsMappingUrl, {params});
    }

    getExpertsByQuery(
        searchString,
        globalSearch,
        country,
        forceNLP,
        academicField,
        affiliation,
        parentIds = null,
    ) {
        let params = new HttpParams()
            .set('query', searchString)
            .set('globalSearch', globalSearch)
            .set('academicField', academicField);

        if (country) {
            params = params.append('country', country);
        }

        if (forceNLP) {
            params = params.append('forceNLP', forceNLP);
        }

        if (affiliation) {
            params = params.append('affiliation', affiliation);
        }

        if (parentIds) {
            params = params.append('descriptorParentIds', parentIds);
        }

        const url = this.getExpertsByQueryUrl;
        return this.http.get(url, {params});
    }

    getExplorerMap(searchString, globalSearch, country, forceNLP) {
        let params = new HttpParams()
            .set('query', searchString)
            .set('globalSearch', globalSearch);

        if (country) {
            params = params.append('country', country);
        }

        if (forceNLP) {
            params = params.append('forceNLP', forceNLP);
        }

        const url = this.getExpertsByQueryUrl;
        return this.http.get(url, {params});
    }

    getIndustryExpertsByQuery(searchString, forceNLP, country) {
        let params = new HttpParams().set('query', searchString);

        if (forceNLP) {
            params = params.append('forceNLP', forceNLP);
        }

        if (country) {
            params = params.append('country', country);
        }

        const url = this.getIndustryExpertsByQueryUrl;
        return this.http.get(url, {params});
    }

    getCompaniesByQuery(searchString, corporatesSearch) {
        const params = new HttpParams()
            .set('query', searchString)
            .set('cs', corporatesSearch);

        const url = this.getCompaniesByQueryUrl;

        return this.http.get(url, {params});
    }

    getCompanyExtraInfo(searchString, id, patentNumbers) {
        const params = new HttpParams()
            .set('query', searchString)
            .set('id', id)
            .set('patentNumbers', patentNumbers);

        const url = this.getCompanyExtraInfoUrl;

        return this.http.get(url, {params});
    }

    getCountries() {
        const url = '/iapi/getAllPossibleCountries';
        return this.http.get(url);
    }

    getInstitutionByName(institutionName) {
        const params = new HttpParams().set('name', institutionName);
        return this.http.get(this.getInstitutionByNameUrl, {params});
    }

    getInstitutionsList(searchString) {
        const params = new HttpParams().set('name', searchString);
        return this.http.get(this.getAllPossibleInstitutionUrl, {params});
    }

    createInstitution(institutionName) {
        const params = new HttpParams().set('name', institutionName);
        return this.http.get(this.createInstitutionUrl, {params});
    }

    getAllInstitutions() {
        return this.http.get('/iapi/getAllInstitutions');
    }

    deleteInstitution(institutionName) {
        const params = new HttpParams().set('name', institutionName);
        return this.http.get(this.deleteInstitutionUrl, {params});
    }

    updateInstitutionDetails(institutionName, fieldName, value, add) {
        const fd = new FormData();
        fd.append('name', institutionName);
        fd.append('field', fieldName);
        fd.append('value', value);
        fd.append('add', add);
        return this.http.post(this.UpdateInstitutionDetailsUrl, fd);
    }

    updateInstitutionImage(institutionName, fieldName, imageFile) {
        const fd = new FormData();
        fd.append('name', institutionName);
        fd.append('field', fieldName);
        fd.append('value', null);
        fd.append('add', 'true');
        fd.append('imageFile', imageFile);
        return this.http.post(this.UpdateInstitutionDetailsUrl, fd);
    }

    updateUserDetails(userDetails) {
        return this.http.post(this.updateUserDetailsUrl, userDetails);
    }

    deleteUserInGroup(userIdToBeDeleted) {
        const url = this.deleteUserInGroupUrl + userIdToBeDeleted;
        return this.http.get(url);
    }

    getTrendingInStateByQuery(query) {
        const url = this.getTrendingInStateByQueryUrl + query;
        return this.http.get(url);
    }

    getMatchingDescriptors(
        searchString,
        trendingState,
        explorerQuery = null,
        forceNLP = null,
        country = null,
        globalSearch = null,
    ) {
        let params = new HttpParams().set('query', searchString);

        if (trendingState !== null) {
            params = params.append('trending', trendingState);
        }

        if (explorerQuery !== null) {
            params = params.append('explorerQuery', explorerQuery);
        }

        if (forceNLP) {
            params = params.append('forceNLP', forceNLP);
        }

        if (country !== null) {
            params = params.append('country', country);
        }

        if (globalSearch) {
            params = params.append('globalSearch', globalSearch);
        }

        const url = this.getMatchingDescriptorsUrl;
        return this.http.get(url, {params});
    }

    getUsersInGroup(groupId: number) {
        const url = this.getUsersInGroupUrl + groupId;
        return this.http.get(url);
    }

    uploadPubletsFile(data) {
        return this.http.post(this.uploadPubletsFileUrl, data);
    }

    uploadHomePageFile(data) {
        return this.http.post(this.uploadHomePageFileUrl, data);
    }

    getHierarchyForPublet(publetId) {
        const url = this.getHierarchyUrl + publetId;
        return this.http.get(url);
    }

    getDataForCampus(parentId, trendingState, fromJump, meshList) {
        const url =
            this.getCampusMapUrl +
            parentId +
            '&trending=' +
            trendingState +
            '&returnEmpty=' +
            fromJump +
            '&ml=' +
            meshList;
        return this.http.get(url);
    }

    getDataForExplorer(
        parent,
        query,
        globalSearch,
        country,
        forceNLP,
        fromJump,
        meshList,
        affiliation,
    ) {
        let params = new HttpParams()
            .set('parent', parent)
            .set('query', query ? query : '')
            .set('globalSearch', globalSearch)
            .set('forceNLP', forceNLP)
            .set('returnEmpty', fromJump)
            .set('ml', meshList);
        if (country) {
            params = params.append('country', country);
        }
        if (affiliation) {
            params = params.append('affiliation', affiliation);
        }
        return this.http.get(`/iapi/knowledgeMap/getSearchHeatMap`, {params});
    }

    getUserGroups(limit) {
        const url = this.getUserGroupsUrl + limit;
        return this.http.get(url);
    }

    getResearchersForDescriptor(ids) {
        const url = this.getResearchersForDescriptorUrl + ids;
        return this.http.get(url);
    }

    logout() {
        //localStorage.removeItem('userinfo');
        this.http.get('/logout').toPromise().then(() => {
            // localStorage.removeItem('userinfo');
            window.location.href = '/app/login';
        }).catch(() => {
            // localStorage.removeItem('userinfo');
            window.location.href = '/app/login';
        });

        // this.router.navigate(['/app/logout']);
    }

    export(experts, searchString, type, scope, topic, date, googleSheets) {
        return this.http
            .post(
                this.exportReaserchersUrl,
                {
                    searchString: searchString,
                    experts: experts,
                    type: type,
                    scope: scope,
                    topic: topic,
                    date: date,
                    googleSheets: googleSheets,
                },
                {responseType: 'blob'},
            )
            .pipe(tap((error) => console.log(error)));
    }

    exportExpert(expert, image, searchString, type, scope, date) {
        return this.http
            .post(
                '/iapi/knowledgeMap/expert/export',
                {
                    expert: expert,
                    image: image,
                    query: searchString,
                    type: type,
                    scope: scope,
                    date: date,
                },
                {responseType: 'blob'},
            )
            .pipe(tap((error) => console.log(error)));
    }

    sendFeedback(message) {
        return this.http.get(`/api/v1/users/sendFeedback?feedback=${message}`);
    }

    uploadMachineFile(file) {
        const fd = new FormData();
        fd.append('file', file, file.name);
        return this.http.post(`/iapi/admin/machine/upload`, fd);
    }

    getMachines(query, globalSearch, country, forceNLP) {
        let params = new HttpParams()
            .set('query', query)
            .set('globalSearch', globalSearch);

        if (country) {
            params = params.append('country', country);
        }

        if (forceNLP) {
            params = params.append('forceNLP', forceNLP);
        }

        const url = '/iapi/searchMachines';
        return this.http.get(url, {params});
    }

    getMachineById(id) {
        return this.http.get('iapi/admin/machine/' + id);
    }

    getMachinesForAdmin(name, make, model) {
        const params = new HttpParams()
            .set('name', name)
            .set('make', make)
            .set('model', model);
        return this.http.get(this.adminMachineSearch, {params});
    }

    updateMachineDetails(id, field, value, imageFile) {
        const fd = new FormData();
        fd.append('id', id);
        fd.append('field', field);
        fd.append('value', value);
        fd.append('imageFile', imageFile);
        return this.http.post(this.adminMachineUpdate, fd);
    }

    updateMachineDocument(
        machineId,
        instanceId,
        docId,
        type,
        data,
        url,
        action,
    ) {
        let apiUrl;
        let id;

        if (machineId) {
            id = machineId;
            apiUrl = this.adminMachineDocumentUpdate;
        }
        if (instanceId) {
            id = instanceId;
            apiUrl = this.adminMachineInstanceDocumentUpdate;
        }

        const model = {
            id: id,
            docId: docId,
            type: type,
            data: data,
            url: url,
            action: action,
        };

        return this.http.post(apiUrl, model);
    }

    createMachine(name, make, model) {
        const params = new HttpParams()
            .set('name', name)
            .set('make', make)
            .set('model', model);
        return this.http.get(this.adminMachineCreate, {params});
    }

    findMachineInstances(machineId) {
        return this.http.get(`iapi/admin/machine/${machineId}/instances`);
    }

    getMachineInstance(instanceId) {
        const params = new HttpParams().set('id', instanceId);
        return this.http.get(this.adminMachineGetInstance, {params});
    }

    updateMachineInstanceDetails(id, field, value) {
        const params = new HttpParams()
            .set('id', id)
            .set('field', field)
            .set('value', value);
        return this.http.get(this.adminMachineInstanceUpdate, {params});
    }

    updateMachineInstanceResearcher(
        instanceId,
        researcherId,
        name,
        role,
        email,
        phone,
        action,
    ) {
        let params = new HttpParams()
            .set('instanceId', instanceId)
            .set('name', name)
            .set('role', role)
            .set('email', email)
            .set('phone', phone)
            .set('action', action);
        if (researcherId) {
            params = params.append('researcherId', researcherId);
        }
        return this.http.get(this.adminMachineInstanceResearcherUpdate, {
            params,
        });
    }

    createNewMachineInstance(machineId, institutionId, department) {
        const params = new HttpParams()
            .set('machineId', machineId)
            .set('institutionId', institutionId)
            .set('department', department);
        return this.http.get(this.adminMachineInstanceCreate, {params});
    }

    deleteMachineInstance(instanceId) {
        const params = new HttpParams().set('id', instanceId);
        return this.http.get(this.adminMachineInstanceDelete, {params});
    }

    deleteMachine(machineId) {
        const params = new HttpParams().set('id', machineId);
        return this.http.get(this.adminMachineDelete, {params});
    }

    findInstanceResearcherEmails(instanceId, affiliation, missingResearchers) {
        const model = {
            instanceId: instanceId,
            affiliation: affiliation,
            missingResearchers: missingResearchers,
        };

        return this.http.post('/iapi/getInstanceExtraInfo', model);
    }

    getInstitutionCompanies() {
        return this.http.get('/iapi/getInstitutionCompanies');
    }

    exportCollMap(companies, img, date) {
        return this.http
            .post(
                '/iapi/institution/exportCollaborationMap',
                {
                    companies: companies,
                    image: img,
                    date: date,
                },
                {responseType: 'blob'},
            )
            .pipe(tap((error) => console.log(error)));
    }

    getPaymentTransactionUrl(originator = null) {
        let params = new HttpParams();
        if (originator != null) {
            params = params.append('originator', originator);
        }
        return this.http.get('/iapi/payment', {params});
    }

    hasTransactionFinished(transactionId) {
        const params = new HttpParams().set('transactionId', transactionId);
        return this.http.get('/iapi/transactionFinished', {params});
    }

    getUserDetailsAdmin(email) {
        const params = new HttpParams().set('email', email);
        return this.http.get(this.adminUserDetails, {params});
    }

    updateUserDetailsAdmin(email, field, value, add) {
        const fd = new FormData();
        fd.append('email', email);
        fd.append('field', field);
        fd.append('value', value);
        if (add != null) {
            fd.append('add', add);
        }
        return this.http.post(this.adminUserDetailsUpdate, fd);
    }

    updatePaymentRequestAdmin(email, paymentId, field, value) {
        const fd = new FormData();
        fd.append('email', email);
        fd.append('id', paymentId);
        fd.append('field', field);
        fd.append('value', value);
        return this.http.post(this.adminPaymentRequestUpdate, fd);
    }

    createPaymentRequestAdmin(model) {
        return this.http.post(this.adminPaymentRequestCreate, model);
    }

    getMachineSchedule() {
        return this.http.get('/iapi/machineSchedule');
    }

    getNonIndustryInstitutions(country) {
        const params = new HttpParams().set('country', country);
        return this.http.get(this.getNonIndustryInstitutionsUrl, {params});
    }

    exportCompanies(companies, query, country, date, googleSheets) {
        return this.http
            .post(
                '/iapi/institution/exportCompanies',
                {
                    companies: companies,
                    query: query,
                    country: country,
                    date: date,
                    googleSheets: googleSheets,
                },
                {responseType: 'blob'},
            )
            .pipe(tap((error) => console.log(error)));
    }

    getCtExpertsByQuery(searchString) {
        const params = new HttpParams().set('query', searchString);
        return this.http.get('/iapi/knowledgeMap/getExpertsCT', {params});
    }

    exportCtExperts(
        experts,
        searchString,
        phase,
        country,
        status,
        date,
        googleSheets,
    ) {
        return this.http
            .post(
                '/iapi/knowledgeMap/exportClinicalTrialExperts',
                {
                    researchers: experts,
                    query: searchString,
                    phase: phase,
                    country: country,
                    status: status,
                    date: date,
                    googleSheets: googleSheets,
                },
                {responseType: 'blob'},
            )
            .pipe(tap((error) => console.log(error)));
    }

    getInvestorsAdmin(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get(this.adminInvestorSearch, {params});
    }

    createInvestor(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get(this.adminInvestorCreate, {params});
    }

    updateInvestorDetails(id, field, value, add) {
        const fd = new FormData();
        fd.append('id', id);
        fd.append('field', field);
        fd.append('value', value);
        fd.append('add', add);
        return this.http.post(this.adminInvestorUpdate, fd);
    }

    deleteInvestor(id) {
        const params = new HttpParams().set('id', id);
        return this.http.get(this.adminInvestorDelete, {params});
    }

    getInvestorCompanies(id) {
        const params = new HttpParams().set('id', id);
        return this.http.get(this.adminInvestorCompanies, {params});
    }

    getTrendingTopicsForInstitution(institutionToCompare) {
        const params = new HttpParams().set(
            'institutionCmp',
            institutionToCompare,
        );

        return this.http.get('/iapi/trending/getTrendingTopicsForInstitution', {
            params,
        });
    }

    getTrendingComparableInstitutions() {
        return this.http.get('/iapi/trending/getComparableInstitutions');
    }

    exportTrendingTopics(institutionsData, img, institutionToCompare) {
        return this.http
            .post(
                '/iapi/strengthMapper/export',
                {
                    institutions: institutionsData,
                    image: img,
                    institutionToCompare: institutionToCompare,
                },
                {responseType: 'blob'},
            )
            .pipe(tap((error) => console.log(error)));
    }

    generateEsriCsv(researchersFile, companiesFile) {
        const fd = new FormData();
        fd.append('researchersFile', researchersFile, researchersFile.name);
        fd.append('companiesFile', companiesFile, companiesFile.name);
        return this.http.post(this.adminEsriCsvGeneratorUrl, fd, {
            responseType: 'arraybuffer',
        });
    }

    getNewspapersForAdmin(country) {
        const params = new HttpParams().set('country', country);
        return this.http.get('/iapi/admin/getNewspapersByCountry', {params});
    }

    getNewspaperById(id) {
        const params = new HttpParams().set('id', id);
        return this.http.get('/iapi/admin/getNewspaperById/', {params});
    }

    createNewspaper(country) {
        const params = new HttpParams().set('country', country);
        return this.http.get('/iapi/admin/createNewspaper', {params});
    }

    updateNewspaper(id, field, value) {
        const fd = new FormData();
        fd.append('id', id);
        fd.append('field', field);
        fd.append('value', value);
        return this.http.post('/iapi/admin/updateNewspaper', fd);
    }

    deleteNewspaper(id) {
        const params = new HttpParams().set('id', id);
        return this.http.get('/iapi/admin/deleteNewspaper', {params});
    }

    getAdminSearchQuery(query, domain, searchFor) {
        const params = new HttpParams()
            .set('query', query)
            .set('domain', domain)
            .set('searchFor', searchFor);
        return this.http.get('/iapi/admin/adminSearchQuery', {params});
    }

    getSynonymsForAdmin(query) {
        const params = new HttpParams().set('query', query);
        return this.http.get('/iapi/admin/findSynonyms', {params});
    }

    getSynonymById(id) {
        const params = new HttpParams().set('id', id);
        return this.http.get('/iapi/admin/getSynonymById/', {params});
    }

    createSynonym(text) {
        const params = new HttpParams().set('text', text);
        return this.http.get('/iapi/admin/createSynonym', {params});
    }

    updateSynonym(id, field, value) {
        const fd = new FormData();
        fd.append('id', id);
        fd.append('field', field);
        fd.append('value', value);
        return this.http.post('/iapi/admin/updateSynonym', fd);
    }

    getStrengthComparableInstitutionForAdmin(insName) {
        const params = new HttpParams().set('name', insName);
        return this.http.get('/iapi/admin/getComparableInstitution', {
            params,
        });
    }

    addStrengthComparableInstitutionForAdmin(insName) {
        const params = new HttpParams().set('name', insName);
        return this.http.get('/iapi/admin/addComparableInstitution', {
            params,
        });
    }

    removeStrengthComparableInstitutionForAdmin(insName) {
        const params = new HttpParams().set('name', insName);
        return this.http.get('/iapi/admin/removeComparableInstitution', {
            params,
        });
    }

    getTimeline(searchString) {
        const params = new HttpParams().set('query', searchString);

        return this.http.get(this.getTimelineUrl, {params});
    }

    exportTimelineGraph(graphs, searchString) {
        return this.http
            .post(
                '/iapi/exportResearchTimelineGraph',
                {
                    query: searchString,
                    graphs: graphs,
                },
                {responseType: 'blob'},
            )
            .pipe(tap((error) => console.log(error)));
    }

    requestUserDashboard(dashboardRequest, scope) {
        let params = new HttpParams();
        if (scope != null) {
            params = params.set('exampleScope', scope);
        }
        return this.http.post('/iapi/requestUserDashboard', dashboardRequest, {
            params,
        });
    }

    getEsriDashboards() {
        return this.http.get('/iapi/admin/getEsriDashboards');
    }

    getEsriDashboardAdmin(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get('/iapi/admin/getEsriDashboard', {params});
    }

    updateEsriDashboardAdmin(name, field, value, add) {
        const fd = new FormData();
        fd.append('name', name);
        fd.append('field', field);
        if (value) {
            fd.append('value', value);
        }
        if (add != null) {
            fd.append('add', add);
        }
        return this.http.post('/iapi/admin/updateEsriDashboard', fd);
    }

    createEsriDashboardAdmin(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get('/iapi/admin/createEsriDashboard', {params});
    }

    deleteEsriDashboardAdmin(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get('/iapi/admin/deleteEsriDashboard', {params});
    }

    executeDashboardUpdateOnEsriAdmin(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get('/iapi/admin/executeUpdateEsriDashboard', {
            params,
        });
    }

    executeDashboardTestOnEsriAdmin(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get('/iapi/admin/executeTestEsriDashboard', {
            params,
        });
    }

    executeLandscapeAlert(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get('/iapi/admin/executeLandscapeAlert', {params});
    }

    getVCs() {
        return this.http.get('/iapi/admin/get_vcs', {});
    }

    crawlVc(name) {
        const params = new HttpParams().set('name', name);
        return this.http.get('/iapi/admin/crawl_vc', {params});
    }

    deadLink(action, domain) {
        const params = new HttpParams()
            .set('action', action)
            .set('domain', domain);
        return this.http.get('/iapi/admin/dead_link', {params});
    }

    testVcCrawler(name: string, url: string, page_processor: string) {
        const params = new HttpParams()
            .set('name', name)
            .set('url', url)
            .set('page_processor', page_processor);
        return this.http.get('/iapi/admin/test_vc_crawler', {params});
    }

    saveStartup(oldName: string, name: string, country: string) {
        const params = new HttpParams()
            .set('name', name)
            .set('oldName', oldName)
            .set('country', country);
        return this.http.get('/iapi/admin/save_startup', {params});
    }

    setInstitutionType(name: string, instType: string, aggregator: boolean) {
        const params = new HttpParams()
            .set('name', name)
            .set('instType', instType)
            .set('aggregator', aggregator.toString());
        return this.http.get('/iapi/admin/set_institution_type', {params});
    }

    getStartups(url) {
        const params = new HttpParams().set('vcDomain', url);

        return this.http.get('/iapi/admin/get_startups', {params});
    }

    getStartupsForReview(url: string) {
        const params = new HttpParams().set('vcDomain', url);
        return this.http.get('/iapi/admin/get_startups_for_review', {params});
    }

    findStartups(search) {
        const params = new HttpParams().set('search', search);

        return this.http.get('/iapi/admin/find_startups', {params});
    }

    // markAsDuplicate(startupName: string) {
    //   let params = new HttpParams()
    //     .set('name', name);
    //   return this.http.get('/iapi/admin/mark_as_duplicate', { params });
    // }
    saveVc(
        name: any,
        fullname: any,
        isActive: any,
        isAggregator: any,
        url: any,
        country: any,
        crawlDepth: any,
        pageProcessor: any,
    ) {
        const params = new HttpParams()
            .set('name', name)
            .set('fullname', fullname)
            .set('isActive', isActive)
            .set('isAggregator', isAggregator)
            .set('url', url)
            .set('country', country)
            .set('crawlDepth', crawlDepth)
            .set('pageProcessor', pageProcessor);
        return this.http.get('/iapi/admin/save_vc', {params});
    }

    deleteAndExcludeVc(vcName: any, exclude: boolean) {
        const params = new HttpParams()
            .set('exclude', exclude.toString())
            .set('vcName', vcName);
        return this.http.get('/iapi/admin/delete_and_exclude_vc', {params});
    }

    async addVc(
        name: string,
        url: string,
        country: string,
        isAggregator: boolean,
        crawlDepth: number,
    ) {
        const params = new HttpParams()
            .set('name', name)
            .set('url', url)
            .set('country', country)
            .set('isAggregator', isAggregator.toString())
            .set('crawlDepth', crawlDepth.toString());
        return this.http.get('/iapi/admin/add_vc', {params}).toPromise();
    }

    async tryGoogleClient(place: string, country: string) {
        const params = new HttpParams()
            .set('place', place)
            .set('country', country);
        return this.http
            .get('/iapi/admin/try_google_client', {params})
            .toPromise();
    }

    async search(query: string) {
        const params = new HttpParams().set('query', query);
        return this.http.get('/iapi/admin/search', {params}).toPromise();
    }

    async getSmartSheets() {
        return this.http.get('/iapi/admin/smartsheet/getAll', {}).toPromise();
    }

    async smartsheetAdd(spreadsheetId: string) {
        const params = new HttpParams().set('spreadsheetId', spreadsheetId);
        return this.http
            .get('/iapi/admin/smartsheet/add', {params})
            .toPromise();
    }

    async smartsheetDelete(spreadsheetId: any) {
        const params = new HttpParams().set('spreadsheetId', spreadsheetId);
        return this.http
            .get('/iapi/admin/smartsheet/delete', {params})
            .toPromise();
    }

    async smartsheetStart(spreadsheetId: any) {
        const params = new HttpParams().set('spreadsheetId', spreadsheetId);
        return this.http
            .get('/iapi/admin/smartsheet/start', {params})
            .toPromise();
    }

    async smartsheetStop(spreadsheetId: any) {
        const params = new HttpParams().set('spreadsheetId', spreadsheetId);
        return this.http
            .get('/iapi/admin/smartsheet/stop', {params})
            .toPromise();
    }

    async getCtPhases(model: any) {
        return this.http.post('/iapi/admin/getCtPhases', model).toPromise();
    }

    async login(username: string, password: string) {
        const params = new HttpParams()
            .set('target', '/app')
            .set('username', username)
            .set('password', password)
            .set('remember-me', 'on');

        return this.http
            .post('/login', params, {observe: 'response', withCredentials: true})
            .toPromise()
            .then((x) => {
                // debugger
                console.log(x);
                return true;
            })
            .catch((x) => {
                // debugger
                console.log(x);
                return false;
            });
    }

    searchCompanies(query: string, all: boolean) {
        const params = new HttpParams()
            .set('query', query)
            .set('all', all.toString());
        return this.http.get(`/api/v1/institutions/search`, {params});
    }

    searchByHost(host: string) {
        const params = {host: host};
        return this.http.get(`/api/v1/institutions/searchByHost`, {params});
    }

    getCompanyById(id: string) {
        return this.http.get(`/api/v1/institutions/` + id);
    }

    getCompanyEvidence(companyId: string) {
        return this.http.get(`/api/v1/institutions/${companyId}/evidence`);
    }

    updateInstitution(inst: Institution) {
        return this.http.put<Institution>(`/api/v1/institutions/${inst.id}`, inst);
    }

    getRandomForShow() {
        return this.http.get(`/api/v1/institutions/getRandomForShow`);
    }

    getLinkedIn2(name, affiliation) {
        return this.http.get(
            `/api/v1/experts/getLinkedIn?name=${name}&affiliation=${affiliation}`,
        ).toPromise().then((res) => {
            return {
                name: name,
                affiliation: affiliation,
                linkedInResponse: res
            } as LinkedInResult
        });
    }

    getGoogleScholar2(name, affiliation) {
        return this.http.get(
            `/api/v1/experts/getGoogleScholarLink?name=${name}&affiliation=${affiliation}`,
        ).toPromise().then((res) => {
            return {
                name: name,
                affiliation: affiliation,
                linkedInResponse: res
            } as LinkedInResult
        });;
    }

    getAutoClassification(companyId: string) {
        return this.http.get(
            `/api/v1/institutions/${companyId}/getAutoClassification`,
        );
    }

    getProjectEvidence(companyId: string) {
        return this.http.get(
            `/api/v1/innovationProjects/${companyId}/getEvidence`,
        );
    }

    register(email: string, firstName: string, lastName: string, password: string, accept: boolean) {
        const registrationForm = {
            firstName: firstName,
            lastName: lastName,
            password: password,
            email: email,
            accept: accept
        } as RegistrationForm;
        return this.http.post('/api/v1/users/register', registrationForm).toPromise();
    }

    getAccounts() {
        return this.http.get('/api/v1/accounts/getAll').toPromise();
    }

    getAccount() {
        return this.http.get('/api/v1/accounts').toPromise();
    }

    updateUser(user: User) {
        return this.http.put('/api/v1/users', user).toPromise();

    }

    getAllUsers() {
        return this.http.get('/api/v1/users', {}).toPromise();
    }

    getUsers(accountId: any) {
        const params = new HttpParams()
            .set('accountId', accountId);
        return this.http.get('/api/v1/users/account', {params}).toPromise();
    }

    updateAccount(editingAccount: TreebuteAccount) {
        return this.http.put('/api/v1/accounts', editingAccount).toPromise();
    }

    createAccount(result: TreebuteAccount) {
        return this.http.post('/api/v1/accounts', result).toPromise();
    }

    inviteUser(invitedUser) {
        return this.http.post('/api/v1/invitedUsers', invitedUser).toPromise();
    }

    getInvitedUsers(accountId: number) {
        const params = new HttpParams()
            .set('accountId', accountId.toString());
        return this.http.get('/api/v1/invitedUsers', {params}).toPromise();
    }

    deleteInvitedUser(invitedUser: InvitedUser) {
        const params = new HttpParams()
            .set('userEmail', invitedUser.userEmail);
        return this.http.delete('/api/v1/invitedUsers', {params}).toPromise();
    }

    getVersionData() {
        return this.http.get('/api/v1/versionData', {}).toPromise();
    }

    getSharedProject(projectId: number) {
        return this.http.get<SharedProject>('/api/v1/sharedProjects/' + projectId).toPromise();
    }

    validCanonical(projectId: number, canonical: string) {
        return this.http.get<boolean>(`/api/v1/sharedProjects/validCanonical?projectId=${projectId}&canonical=${canonical}`).toPromise();
    }

    getSharedData<T>(fileName: string) {
        return this.http.get<T>('/api/v1/sharedProjects/getSharedData?file=' + fileName).toPromise();
    }

    getOpenAlexManifestLines(state: string) {
        return this.http.get<ManifestLine[]>('/api/v1/openAlex?state='+state).toPromise();
    }

    getOpenAlexManifestLineStates() {
        return this.http.get<any>('/api/v1/openAlex/getStates').toPromise();
    }

    updateOpenAlexManifestLineState(id: number, value: string) {
        return this.http.post(`/api/v1/openAlex/${id}?state=${value}`, {}).toPromise();
    }


}

