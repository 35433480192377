<div class="evidence-container pb-5"
     [hidden]="!evidenceList?.length && !institutions?.length && !researchers?.length">
    <div *ngIf="preview" class="">
        <h2 class="pt-5 pb-3"
            [style.color]="evidenceColor"
            *ngIf="showTitle">
            {{title}}
        </h2>
        <a *ngIf="!readOnlyProjectService.isReadOnlyProject && researchers && project" [routerLink]="['/app/projects/' + project.id + '/experts-tool']"
           target="_blank">
            <h4>Experts Tool
            <span class="material-icons-outlined lower-icon">
                        open_in_new
                    </span>
            </h4>
        </a>
        <a *ngIf="!readOnlyProjectService.isReadOnlyProject && isCt && evidenceList && project" [routerLink]="['/app/projects/' + project.id + '/ct-tool']"
           target="_blank">
            <h4>Clinical Trials Tool
            <span class="material-icons-outlined lower-icon">
                        open_in_new
                    </span>
            </h4>
        </a>
        <!--        <h2 class="p-3"-->
        <!--              [style.color]="evidenceColor"-->
        <!--              *ngIf="!isCt && !(researchers && researchers.length > 0) && showTitle">-->
        <!--            {{title}}-->
        <!--        </h2>-->
        <!--        <div *ngIf="researchers && researchers.length > 0">-->
        <!--            <a (click)="navToExpertsTool()">-->
        <!--                <span class="evidence-title" [style.color]="evidenceColor">-->
        <!--                    {{title}}-->
        <!--                </span>-->
        <!--            </a>-->
        <!--        </div>-->
        <!--        <div *ngIf="isCt">-->
        <!--            <a (click)="navToCtTool()">-->
        <!--               <span class="evidence-title" [style.color]="evidenceColor">-->
        <!--                    {{title}}-->
        <!--                </span>-->
        <!--            </a>-->
        <!--        </div>-->
    </div>
    <div class="">
        <div *ngIf="showList && evidenceList">
            <evidence-table [evidence]="evidenceList" [isPatent]="title === 'Derived Patents'" [evidenceHeader]="evidenceHeader">
            </evidence-table>
<!--            <h3 class="p-3">Recent Items</h3>-->
<!--            <div>-->
<!--                <ul>-->
<!--                    <li class="p-1" *ngFor="let evidence of evidenceList; let i=index">-->
<!--                        <a href="{{ evidence.valueA }}"-->
<!--                           target="_blank">-->
<!--                            {{ evidence.valueB }}-->
<!--                            <span class="pub-date">{{ getEvidencePrefix(evidence.valueD) }}</span>-->
<!--                        </a>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
        </div>

        <div [hidden]="!showChart || !evidenceList">
            <h3 class="p-3" *ngIf="showSubTitles">Distribution over Time</h3>
            <div class="evidence-chart" [hidden]="hideChart">
                <canvas #canvas height="300"></canvas>
            </div>
        </div>

        <div *ngIf="researchers">
            <experts-chunk
                    #expertsChunk
                    [showFromSearch]="!readOnlyProjectService.isReadOnlyProject"
                    [showAction]="showAction"
                    [actionButtonText]="actionButtonText"
                    (actionRun)="actionRun.emit($event)"
                    [project]="project"
                    [researchers]="researchers">
            </experts-chunk>
        </div>
    </div>

</div>
