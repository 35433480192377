import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { NetworkService } from '../../network.service';

@Component({
    selector: 'app-feedback-window',
    templateUrl: './feedback-window.component.html',
    styleUrls: ['./feedback-window.component.scss'],
})
export class FeedbackWindowComponent implements OnInit {
    message: string;
    selectedFields = [];
    fields = [
        'The expert is not dealing with this subject anymore',
        'The expert no longer teaches',
        'The expert teaches in another institution',
        'The expert has more articles',
        'Missing informer about the expert',
        'This expert is not alive',
    ];

    @Input() query: string;
    @Input() scope: string;
    @Input() expert: string;
    @Output() closeModal = new EventEmitter();

    constructor(private networkService: NetworkService) {}

    ngOnInit() {}

    sendFeedback() {
        this.closeModal.emit();

        let str = `Query: ${this.query}NLTScope: ${this.scope}NLTMessage: ${this.message}`;
        if (this.expert !== undefined) {
            str =
                `Expert: ${this.expert}NLTFields: [${this.selectedFields}]NLT` +
                str;
        }

        this.networkService.sendFeedback(str).subscribe(
            () => {},
            (error) => console.error(error),
        );
    }

    fieldClicked(field) {
        if (this.selectedFields.includes(field)) {
            this.selectedFields = this.selectedFields.filter(
                (e) => e !== field,
            );
        } else {
            this.selectedFields.push(field);
        }
    }
}
