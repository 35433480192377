import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NewSearchModalData, SearchTitleDialogData} from "../../../../api-responses/Dialogs.interface";
import {
    FeedbackModalData,
    Researcher,
    SearchQueryResults
} from "../../../../api-responses/SearchQueryResults.interface";
import {UtilsComponent} from "../../../../utils/utils.component";
import {NetworkService} from "../../../../network.service";
import {SelectedField} from "../../../../api-responses/graphs.interface";

@Component({
    selector: 'feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModal implements OnInit {
    message: string;

    selectedFields: SelectedField[] = [];
    fields = [
        'The expert is not dealing with this subject anymore',
        'The expert no longer teaches',
        'The expert teaches in another institution',
        'The expert has more articles',
        'Missing informer about the expert',
        'This expert is not alive',
    ];

    constructor(
        public utilsComponent: UtilsComponent,
        public networkService: NetworkService,
        public dialogRef: MatDialogRef<FeedbackModal>,
        @Inject(MAT_DIALOG_DATA) public data: FeedbackModalData)
    {
        for (const field of this.fields) {
            this.selectedFields.push({
                selected: false,
                text: field,
                displayText: field,
                num: 0
            })
        }
    }

    ngOnInit(): void {

    }

    closeModal() {
        this.dialogRef.close();
    }

    sendFeedback() {
        let str = `Query: ${this.data.searchResults.query}NLTScope: ${this.data.searchResults.searchType}NLTMessage: ${this.message}`;
        if (this.data.researcher !== undefined) {
            str =
                `Expert: ${this.getName()}NLTFields: [${this.selectedFields.filter(x => x.selected).map(x => x.text).join(", ")}]NLT` +
                str;
        }

        this.networkService.sendFeedback(encodeURIComponent(str)).subscribe(
            () => {},
            (error) => console.error(error),
        );

        this.dialogRef.close();
    }

    getDescription() {
        return this.utilsComponent.ellipsis(this.data.searchResults.query, 20) + " > " + this.getName()
    }

    private getName() {
        let fn = this.data.researcher.firstName ? this.data.researcher.firstName : '';
        let ln = this.data.researcher.lastName ? this.data.researcher.lastName : '';
        return (fn + ' ' + ln).trim();
    }
}
