import { Component, NgModule, OnInit } from '@angular/core';
import { NetworkService } from '../../../network.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../data.service';
import { Timer } from 'd3';

@Component({
    selector: 'app-institution-manager',
    templateUrl: './smart-sheet-admin.component.html',
    styleUrls: ['./smart-sheet-admin.component.scss'],
})
export class SmartSheetAdminComponent implements OnInit {
    smartSheets: object;
    headElements: Array<string> = ['', 'Spreadsheet', 'Action', 'State'];
    updated: Date = new Date();
    private interval : any = null;
    inputSpreadsheetId: string;
    loading: boolean;
    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        this.interval = setInterval(() => this.getSmartSheets(), 10000);
        this.getSmartSheets();
    }

    async start(spreadsheetId: any) {
        try {
            this.loading = true;
            await this.networkService.smartsheetStart(spreadsheetId);
            (this.smartSheets as Array<string>).filter(
                (x) => x['spreadsheetId'] === spreadsheetId,
            )[0]['runState'] = 'Starting...';
            this.updated = new Date();
        } catch (e) {
            alert('error! ' + e.error);
            clearInterval(this.interval);
        }
        this.loading = false;
    }

    async stop(spreadsheetId: any) {
        try {
            this.loading = true;
            await this.networkService.smartsheetStop(spreadsheetId);
            (this.smartSheets as Array<string>).filter(
                (x) => x['spreadsheetId'] === spreadsheetId,
            )[0]['runState'] = 'Stopping...';
            this.updated = new Date();
        } catch (e) {
            alert('error! ' + e.error);
            clearInterval(this.interval);
        }
        this.loading = false;
    }

    async getSmartSheets() {
        try {
            this.loading = true;

            const result = await this.networkService.getSmartSheets();
            this.smartSheets = result;
            this.updated = new Date();
        } catch (e) {
            alert('error! ' + e.error);
            clearInterval(this.interval);
        }
        this.loading = false;
    }

    async add() {
        this.loading = true;

        try {
            if (confirm('are you sure you want to add?')) {
                const result = await this.networkService.smartsheetAdd(
                    this.inputSpreadsheetId,
                );
                this.smartSheets = result;
                this.updated = new Date();
                location.reload();
            }
        } catch (e) {
            alert('error! ' + e.error);
            clearInterval(this.interval);
        }
        this.loading = false;
    }

    async delete(spreadsheetId: any) {
        this.loading = true;

        try {
            if (confirm('are you sure you want to delete?')) {
                const result = await this.networkService.smartsheetDelete(
                    spreadsheetId,
                );
                this.smartSheets = result;
                this.updated = new Date();
                location.reload();
            }
        } catch (e) {
            alert('error! ' + e.error);
            clearInterval(this.interval);
        }

        this.loading = false;
    }
}
