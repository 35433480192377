<div style="display: flex; flex-direction: column">
    <div
        *ngIf="showModal"
        style="display: flex; justify-content: space-between"
    >
        <div style="height: fit-content; margin-top: 20px">
            <div class="col-8">
                <label for="name">Name</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        [(ngModel)]="name"
                        placeholder="Enter name"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('name', name, false)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="country">Country</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="country"
                        [(ngModel)]="country"
                        placeholder="Enter country"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('country', country, false)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="url">Url (To crawl press 'Enable Crawling')</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="url"
                        [(ngModel)]="url"
                        placeholder="Enter URL"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('url', url, false)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div>
                <h4 style="margin-top: 20px">Companies</h4>
                <div *ngIf="companies">
                    <ul class="list-group">
                        <li
                            *ngFor="let company of companies"
                            style="margin: 0 0 5px 20px"
                        >
                            <b>{{ company }}</b>
                            <button
                                type="button"
                                (click)="updateField('company', company, false)"
                                class="btn btn-danger btn-sm"
                            >
                                Remove
                            </button>
                        </li>
                    </ul>
                </div>
                <input
                    type="text"
                    id="newCompany"
                    [(ngModel)]="newCompany"
                    placeholder="Enter other company name"
                    name="newCompany"
                    style="width: 500px"
                />
                <button
                    type="button"
                    (click)="updateField('company', newCompany, true)"
                    class="btn btn-primary btn-sm"
                >
                    Add
                </button>
            </div>

            <button
                type="button"
                (click)="enableCrawling()"
                class="btn btn-primary btn-sm"
            >
                Enable Crawling
            </button>
            <button
                type="button"
                (click)="disableCrawling()"
                class="btn btn-primary btn-sm"
            >
                Disable crawling
            </button>
            <hr />
            <button
                type="button"
                (click)="deleteInvestor()"
                class="btn btn-primary btn-sm"
                style="background-color: #f44336"
            >
                Delete Investor
            </button>
        </div>
    </div>
</div>
