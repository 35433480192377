<div class="myContainer">
    <div class="col-6">
        <div>
            <span class="controlContainer"
                ><input type="button" (click)="add()" value="Add Spreadsheet"
            /></span>
            <span class="controlContainer"
                ><input
                    type="text"
                    [(ngModel)]="inputSpreadsheetId"
                    style="width: 70%"
            /></span>
            <span *ngIf="loading">Loading...</span>
        </div>
    </div>
    <div class="col-6">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let head of headElements" scope="col">
                        {{ head }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr mdbTableCol *ngFor="let el of smartSheets">
                    <td>
                        <input
                            type="button"
                            value="Delete"
                            (click)="delete(el['spreadsheetId'])"
                        />
                    </td>
                    <td scope="row">
                        <a
                            href="https://docs.google.com/spreadsheets/d/{{
                                el['spreadsheetId']
                            }}"
                            target="_blank"
                            >{{
                                el['title']
                                    ? el['title']
                                    : el['spreadsheetId'] + ' (permissions?)'
                            }}</a
                        >
                    </td>
                    <td>
                        <input
                            type="button"
                            value="Start"
                            (click)="start(el['spreadsheetId'])"
                        />
                        <input
                            type="button"
                            value="Stop"
                            (click)="stop(el['spreadsheetId'])"
                        />
                    </td>
                    <td>
                        {{ el['runState'] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
