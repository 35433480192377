import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {NetworkService} from "../../../../network.service";
import {DataService} from "../../../../data.service";
import {MatDialog} from "@angular/material/dialog";
import {ReadOnlyProjectService, SharedProject} from "../../../../read-only-project-service";
import {ExportProjectModalComponent} from "../export/export-project-modal.component";
import {ExportProject, YesNoModalData} from "../../../../api-responses/Dialogs.interface";
import {YesNoModalComponent} from "../../../dialogs/yesNo/yes-no-modal.component";
import {InnovationProject, RefreshProjectDto} from "../../../../api-responses/InnovationProjects.interface";
import {Subscription} from "rxjs";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {ProjectStateService} from "../../project-state-service";
import {UtilitiesService} from "../../../../utilities.service";
import {Router} from "@angular/router";

@Component({
    selector: 'project-tools',
    templateUrl: './project-tools.component.html',
    styleUrls: ['./project-tools.component.scss'],
    providers: [ProjectStateService, ReadOnlyProjectService]
})
export class ProjectToolsComponent implements OnInit, OnChanges {

    @Input() project: InnovationProject;
    canExportProject: string = 'unknown';
    btnText: string = 'Loading...';
    subscriptions: Subscription[] = [];
    selectedRefreshRate: string;
    hideShared: boolean = true;
    openTools: boolean = false;

    @Output() refreshNow = new EventEmitter();
    showLoader: boolean = false;
    isSharedProject: boolean = false;
    lastRefresh: string = '';
    processing: boolean = false;

    constructor(public networkService: NetworkService,
                private router: Router,
                public dataService: DataService,
                public readOnlyProjectService: ReadOnlyProjectService,
                public matDialog: MatDialog) {
        this.subscriptions.push(this.dataService.canExportObservable.subscribe((res: number[]) => {
            //console.log('123123')
            //console.log(res);
            if (res && this.project && res.indexOf(this.project.id) > -1) {
                this.setState('no');
            }
            else {
                this.setState('yes');
            }
        }));
    }

    setState(canExport: string) {
        if (canExport === 'no') {
            this.btnText = 'Exporting...';
        }
        else {
            this.btnText = 'Export data';
        }

        this.canExportProject = canExport;
    }

    ngOnInit(): void {

    }

    exportProject() {
        if (this.canExportProject != 'yes') return;

        // show popup
        let dialogRef = this.matDialog.open(ExportProjectModalComponent, {
            data: this.project.treebuteSearchResults,
        });


        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result as ExportProject) {
                    result.projectId = this.project.id;
                    this.networkService.innovationProjects.exportProject(result).toPromise().then(() => {
                        this.matDialog.open(YesNoModalComponent, {
                            data: {
                                dialogTitle: 'Export Project Data',
                                dialogDescription: 'Sent the request. You will receive an email with the data once it\'s ready!',
                                yesText: 'OK',
                                buttons: 1
                            } as YesNoModalData,
                        });

                        this.setState('no');;

                    })
                        .catch((e) => {
                            console.log(e);
                            this.matDialog.open(YesNoModalComponent, {
                                data: {
                                    dialogTitle: 'Export Project Data Failed',
                                    dialogDescription: 'The request to export failed. Please contact Treebute for support.',
                                    yesText: 'OK',
                                    buttons: 1,
                                    error: true
                                } as YesNoModalData,
                            });
                        });
                }
            });

    }

    autoRefreshUpdate($event: MatSlideToggleChange) {
        this.showLoader = true;
        this.networkService.innovationProjects.setRefreshUpdate(this.project.id, this.project.autoRefresh, this.selectedRefreshRate)
            .then((res: RefreshProjectDto) => {
                this.project.autoRefresh = res.autoRefresh
                this.project.autoRefreshInterval = res.autoRefreshInterval

                this.lastRefresh = this.project.refreshDate ? `${this.project.refreshDate[0]}-${this.project.refreshDate[1]}-${this.project.refreshDate[2]}` : '';
                this.selectedRefreshRate = this.project.autoRefreshInterval;
            })
            .finally(() => {
                this.showLoader = false;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.project.currentValue) {
            this.hideShared = this.dataService.hideShared();
            this.lastRefresh = this.project.refreshDate ?
                `${this.project.refreshDate[0]}-${this.project.refreshDate[1]}-${this.project.refreshDate[2]}` :
                '';
            this.selectedRefreshRate = this.project.autoRefreshInterval;
        }
    }

    mouseEnter() {
        this.openTools = true;
    }

    mouseLeave() {
        this.openTools = false;
    }

    preventDefault($event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    updateShareProject($event: SharedProject) {
        this.isSharedProject = $event ? $event.enabled : undefined;
    }

    deleteProject() {
        let dialogRef = this.matDialog.open(YesNoModalComponent, {
            data: {
                dialogTitle: 'Delete Project',
                dialogDescription: 'Are you sure you want to delete the project',
                yesText: 'Delete',
                noText: 'Cancel',
                buttons: 2
            } as YesNoModalData,
        });

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result === 'yes') {
                    this.networkService.innovationProjects.deleteProject(this.project.id).then(r => {
                        return this.router.navigate(['/app/projects']);
                    });
                }
            });
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }
}
