import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    @Output() closeModal = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    close() {
        this.closeModal.emit();
    }
}
