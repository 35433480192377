import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-instance-editor',
    templateUrl: './instance-editor.component.html',
    styleUrls: ['./instance-editor.component.scss'],
})
export class InstanceEditorComponent implements OnInit {
    @Input() id;
    @Output() instanceDelete = new EventEmitter();

    machineId;
    institutionId;
    institutionName;
    department;
    researchers = [];
    documents = [];

    institutions;
    dropdownInsName;
    dropdownInsId;
    showModal = false;

    constructor(
        private networkService: NetworkService,
        private http: HttpClient,
    ) {}

    ngOnInit() {
        this.showModal = true;
        this.networkService.getMachineInstance(this.id).subscribe(
            (data) => this.handleInstanceResponse(data),
            (error) => console.log(error),
        );
        if (this.institutions === undefined) {
            this.getInstitutions();
        }
    }

    getInstitutions() {
        this.networkService.getAllInstitutions().subscribe(
            (data) => (this.institutions = data),
            (error) => console.log(error),
        );
    }

    handleInstanceResponse(data) {
        this.machineId = data.machineId;
        this.institutionId = data.institutionId;
        this.institutionName = data.institutionName;
        this.department = data.department;
        this.researchers = data.researchers;
        this.documents = data.documents;
    }

    updateField(field, value) {
        console.log(field);
        console.log(value);
        this.networkService
            .updateMachineInstanceDetails(this.id, field, value)
            .subscribe(
                (data) => this.handleInstanceResponse(data),
                (error) => console.log(error),
            );
    }

    updateDocument(docId, type, data, url) {
        this.networkService
            .updateMachineDocument(
                null,
                this.id,
                docId,
                type.value,
                data.value,
                url.value,
                'edit',
            )
            .subscribe(
                (response) => this.handleInstanceResponse(response),
                (error) => console.log(error),
            );
    }

    removeDocument(docId) {
        this.networkService
            .updateMachineDocument(
                null,
                this.id,
                docId,
                null,
                null,
                null,
                'remove',
            )
            .subscribe(
                (data) => this.handleInstanceResponse(data),
                (error) => console.log(error),
            );
    }

    addDocument(type, data, url) {
        this.networkService
            .updateMachineDocument(
                null,
                this.id,
                null,
                type.value,
                data.value,
                url.value,
                'add',
            )
            .subscribe(
                (response) => this.handleInstanceResponse(response),
                (error) => console.log(error),
            );
    }

    updateResearcher(resId, name, role, email, phone) {
        this.networkService
            .updateMachineInstanceResearcher(
                this.id,
                resId,
                name.value,
                role.value,
                email.value,
                phone.value,
                'edit',
            )
            .subscribe(
                (data) => this.handleInstanceResponse(data),
                (error) => console.log(error),
            );
    }

    removeResearcher(resId) {
        this.networkService
            .updateMachineInstanceResearcher(
                this.id,
                resId,
                null,
                null,
                null,
                null,
                'remove',
            )
            .subscribe(
                (data) => this.handleInstanceResponse(data),
                (error) => console.log(error),
            );
    }

    addResearcher(name, role, email, phone) {
        this.networkService
            .updateMachineInstanceResearcher(
                this.id,
                null,
                name.value,
                role.value,
                email.value,
                phone.value,
                'add',
            )
            .subscribe(
                (data) => this.handleInstanceResponse(data),
                (error) => console.log(error),
            );
    }
    deleteInstance() {
        if (confirm('Are you sure to delete instance id ' + this.id + '?')) {
            this.networkService.deleteMachineInstance(this.id).subscribe(
                (data) => {
                    this.showModal = false;
                    this.instanceDelete.emit();
                },
                (error) => console.log(error),
            );
        }
    }
}
