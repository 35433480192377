<div class="app-chart-container">
  <div class="chart-header">
    <div class="chart-text">
      <a [hidden]="showTools" [routerLink]="getLink()" >Experts Background</a>
      <span [hidden]="!showTools">Experts Background</span>
      <button [hidden]="!showTools" (click)="openExpertsBackgroundFilter()" class="btn-secondary short-button">Filter by Background</button>
    </div>
    <div class="lovely-text" [hidden]="!showTools">
      {{getExpertsBackgroundDescription()}}
    </div>
  </div>
  <div class="app-chart">
    <experts-background-chart *ngIf="project" [project]="project">
    </experts-background-chart>
  </div>
</div>
