<div class="dialog">
    <div class="header-title py-3">
        <div>{{ "Share Project" }}</div>
        <div class="close-button close-icon"
             (click)="cancel()"
        ></div>
    </div>
    <div class="pb-3" *ngIf="projects && projects.length > 0">
        <div class="pb-2">
            Select project to display on the canvas:
        </div>
        <div *ngFor="let project of projects">
            <input class="form-check-input" type="checkbox" value="" id="#checkbox" >
            <label class="form-check-label" for="#checkbox">
                {{ project.name }}
            </label>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <button class="btn btn-dark border text-light" (click)="cancel()">
                Cancel
            </button>
            <button class="btn btn-primary" (click)="save()">
                Save
            </button>
        </div>
    </div>
</div>
