import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NetworkService} from '../../../network.service';
import {Subscription} from 'rxjs';
import {EditProjectDialogComponent} from '../edit-project-dialog/edit-project-dialog.component';
import {NewProjectResult} from 'src/app/api-responses/NewProjectResult.interface';
import {BusinessData} from 'src/app/api-responses/BusinessData.interface';
import {YesNoModalComponent} from "../../dialogs/yesNo/yes-no-modal.component";
import {InnovationProjectEvidenceInterface} from "../../../api-responses/InnovationProjectEvidence.interface";
import {InnovationProject, Place} from "../../../api-responses/InnovationProjects.interface";
import {BusinessColors, EvidenceColors, ExpertsColors} from "../../../utils/colors.enum";
import {Researcher, Resource, SearchQueryResults} from "../../../api-responses/SearchQueryResults.interface";
import moment from 'moment';
import {Moment} from "moment";
import {DataService} from "../../../data.service";
import {ProjectStateService} from "../project-state-service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ReadOnlyProjectService, SharedProject} from "../../../read-only-project-service";
import {SearchDetailsModalComponent} from "../../dialogs/search-details-modal/search-details-modal.component";
import {CreateCalloutModalComponent} from "../../callout/create/create-callout-modal.component";
import {Callout, CreateCalloutDialogData} from "../../callout/callouts/callout.interface";
import {SearchDetailsModalData, YesNoModalData} from "../../../api-responses/Dialogs.interface";
import {DeleteSearchModalComponent} from "../../search-page/dialogs/delete-search-modal/delete-search-modal.component";
import * as mapboxgl from 'mapbox-gl';
import {environment} from "../../../../environments/environment";
import {AnySourceData, LngLatLike, Point} from "mapbox-gl";
import {Feature} from "geojson";
import {EvidenceChunkComponent} from "../project-page/evidenceChunk/evidence-chunk.component";
import states from '../../../utils/states_hash.json';
import { CountriesDataService } from 'src/app/shared/CountriesDataService';

@Component({
    selector: 'app-project-compare-page',
    templateUrl: './project-compare-page.component.html',
    styleUrls: ['./project-compare-page.component.scss'],
    providers: [ProjectStateService, ReadOnlyProjectService]
})
export class ProjectComparePageComponent implements OnInit, OnDestroy {
    private mapLoaded: boolean = false;

    map: mapboxgl.Map;
    style = 'mapbox://styles/mapbox/dark-v10'; //'mapbox://styles/mapbox/streets-v11';
    lat = 37.75;
    lng = -122.41;

    selectedTab = 0;
    subscriptions: Subscription[] = [];
    project: InnovationProject;
    compareProject: InnovationProject;
    industryTableChildData: BusinessData[];
    evidence: InnovationProjectEvidenceInterface;
    compareEvidence: InnovationProjectEvidenceInterface;
    interval;
    showEvidenceLoader = false;
    worldCountries = [];
    public dataForCharts: any[] = [];
    private colors: string[] = [];
    australianUniversities : Resource[] = [];
    israeliUniversities : Resource[] = [];
    projectsIds  = [[23990, 23989, 23988, 23987, 23984, 23983],
    [23995, 23996, 23997, 23998, 23999, 24000]];

    @ViewChild('expertsEvidenceChunk', {static: false}) expertsEvidenceChunk: EvidenceChunkComponent;
    private places: Place[];

    constructor(
        private activatedRoute: ActivatedRoute,
        public networkService: NetworkService,
        public matDialog: MatDialog,
        public dataService: DataService,
        public projectStateService: ProjectStateService,
        public readOnlyProjectService: ReadOnlyProjectService,
        public newCalloutDialogRef: MatDialogRef<CreateCalloutModalComponent>,
        public countriesDataService: CountriesDataService
    ) {
        this.showEvidenceLoader = true;
    }

    hideShared: boolean = true;

    ngOnInit() {
        this.countriesDataService.getCountries().toPromise().then( result => {
            result.push('Americe', 'Korea', 'UK')
            this.worldCountries = result;
        })

        if (this.readOnlyProjectService.isReadOnlyProject) {
            this.readOnlyProjectService.sharedProject.subscribe( (sharedProject: SharedProject) => {
                 this.networkService.getSharedData<InnovationProject>(sharedProject.projectFile).then( innovationProject => {
                    this.project = innovationProject;

                    this.networkService.getSharedData<InnovationProjectEvidenceInterface>(sharedProject.evidenceFile).then( evidence => {
                        this.evidence = this.fixExpertsCountryUnknown(evidence);
                        this.setCorporatesAsEvidence(this.evidence);
                        this.setDataForDisplay(false);
                        this.showEvidenceLoader = false;
                        this.hideShared = this.dataService.hideShared();

                        this._loadMap(sharedProject.placesFile);
                    })

                })
            })
        }
        else {
            this._normalInit();
        }
    }

    _normalInit() {
        this.countriesDataService.getCountries().toPromise().then( result => {
            this.worldCountries = result;
        })
        this.subscriptions.push(
            this.projectStateService.projectState.subscribe( project => {
                if (!project) return;

                this.project = project as InnovationProject;

                 this.subscriptions.push(
                    this.projectStateService.evidenceStateObservable.subscribe( evidence => {
                        this.evidence = this.fixExpertsCountryUnknown(evidence);
                        this.setCorporatesAsEvidence(this.evidence);
                        this.setDataForDisplay(false);
                        this.showEvidenceLoader = false;
                        this.hideShared = this.dataService.hideShared();
                        this.getUniversities(evidence, false);

                        this._loadMap(undefined)
                    })
                );
            })
        );
        this.subscriptions.push(
            this.projectStateService.compareProjectState.subscribe( project => {
                if (!project) return;

                this.compareProject = project as InnovationProject;

                 this.subscriptions.push(
                    this.projectStateService.compareEvidenceStateObservable.subscribe( evidence => {
                        this.compareEvidence = this.fixExpertsCountryUnknown(evidence);
                        this.setCorporatesAsEvidence(this.compareEvidence);
                        this.setDataForDisplay(true);
                        this.showEvidenceLoader = false;
                        this.hideShared = this.dataService.hideShared();
                        this.getUniversities(evidence, true);


                        this._loadMap(undefined)
                    })
                );
            })
        );

        this.activatedRoute.fragment
            .pipe(
                filter((fragment) => fragment === 'executive-dashboard'),
                take(1),
            )
            .toPromise()
            .then(() => {
                this.selectedTab = 1;
            });

        this.project && console.log(this.project);

        this.activatedRoute.paramMap.subscribe(map => {
            // console.log(map)
        });
    }

    getUniversities(evidence: InnovationProjectEvidenceInterface, isCompare: boolean){
        let country = isCompare ? 'ISRAEL' : 'AUSTRALIA';        

        const dictionery = ['AND', 'TECHNION', 'HADASSAH', 'ISRAEL-CANADA'];
        const showing = ['AND'];
        const filteredExperts = evidence.experts.filter( expert => {      
            return expert.department.toUpperCase().includes(country)
        });
        const uniSet = new Set;
        filteredExperts.forEach( expert => {
            expert.department.toUpperCase().split(",").forEach( subString => {
                let add = false;
                if(subString.length <= 100){
                    if(subString.includes("UNIVERSITY") && subString.includes(country)||
                        subString.includes('INSTITUTE') && subString.includes(country) ||
                        subString.includes("SCHOOL") && subString.includes(country) ||
                        subString.includes("CENTRE") && subString.includes(country) ||
                        subString.includes("CENTER") && subString.includes(country)){
                        
                        for(let i = 0 ; i < dictionery.length ; i++) {
                            if(subString.includes(dictionery[i]) && showing.includes(dictionery[i])){
                                return 
                            } else if(subString.includes(dictionery[i]) && !showing.includes(dictionery[i])){
                                add = true
                                showing.push(dictionery[i]);
                            } else {
                                add = true;
                            }
                        }

                        let firstWord = subString.substring[0];
                        if(subString.includes(firstWord) && showing.includes(firstWord)){
                            return       
                        } else if(subString.includes(firstWord) && !showing.includes(firstWord)){
                            add = true
                            dictionery.push(firstWord);
                            showing.push(firstWord);
                        } else {
                            add = true;
                        }

                    }
                }
                if(add){
                    uniSet.add(subString); 
                }
            })
        })
        uniSet.forEach( auUni => {
            let university = {
                valueB: auUni,
                // valueC: auUni,
                valueD: ""
            } as Resource;
            isCompare ? this.israeliUniversities.push(university) : this.australianUniversities.push(university);
        })
        
    }

    findUnknownCountriesInDescription( evidence : InnovationProjectEvidenceInterface ){
        evidence.experts.forEach( expert => {
            if(expert.countries.length === 0){
                this.worldCountries.forEach( country => {
                    if (expert && expert.department && country) {
                        if (expert.department.toLowerCase().includes(country.toLowerCase())) {
                            expert.countries.push(country);
                        }
                    }
                });
            }
        });
        return evidence;
    }


    fixExpertsCountryUnknown( evidence : InnovationProjectEvidenceInterface ){
        this.findUnknownCountriesInDescription(evidence).experts.sort( ( expert1, expert2 ) => {
            return expert1.countries.length > expert2.countries.length ? -1 : 1;
        });
        return evidence;
    }

    openNewProjectDialog(isCompareProject: boolean) {
        if(!isCompareProject){
            let dialogRef = this.matDialog.open(EditProjectDialogComponent, {
                data: {
                    id: this.project.id,
                    name: this.project.name,
                    description: this.project.description,
                    creationDate: this.project.creationDate,
                } as NewProjectResult,
            });
    
            dialogRef
                .afterClosed()
                .toPromise()
                .then((result) => {
                    if (!result) return;
                    this.project.name = result.name;
                    this.project.description = result.description;
                });
        } else {
            let dialogRef = this.matDialog.open(EditProjectDialogComponent, {
                data: {
                    id: this.compareProject.id,
                    name: this.compareProject.name,
                    description: this.compareProject.description,
                    creationDate: this.compareProject.creationDate,
                } as NewProjectResult,
            });
    
            dialogRef
                .afterClosed()
                .toPromise()
                .then((result) => {
                    if (!result) return;
                    this.compareProject.name = result.name;
                    this.compareProject.description = result.description;
                });
        }

        
    }

    eventHandler(event: BusinessData[]) {
        this.industryTableChildData = event;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe(),
        );
    }

    userClickDelete($event: any) {
        this.networkService.searches.deleteSearch($event).toPromise()
            .then((project: InnovationProject) => {
                // remove the search from the list
                this.project = project;
            });
    }


    getDataForChart(papers: Resource[]) {
        if (!papers) return [];

        let paperIndexer: PaperIndexer = new PaperIndexer();

        for (const paper of papers) {
            if (!paper.valueD) continue;
            try {
                let m = moment(paper.valueD, 'YYYY-MM-DD');
                paperIndexer.setDataInQuarterByDate(m)
            }
            catch (e) {}
        }

        return {
            datasets: [{data: paperIndexer.getDataYearly()}],
            labels: paperIndexer.getLabelsYearly()
        }
    }

    getColor(x: string) {
        switch (x) {
            case 'patentCount': return EvidenceColors.patentCount;
            case 'websiteCount': return EvidenceColors.websiteCount;
            case 'publicationCount': return EvidenceColors.publicationCount;
            case 'clinicalTrialCount': return EvidenceColors.clinicalTrialCount;
            case 'grantCount': return EvidenceColors.grantCount;

            case 'experts': return ExpertsColors.ACADEMIC_EXPERTS;
            case 'corporates': return BusinessColors.CORPORATES;
            case 'startups': return BusinessColors.STARTUPS;
        }
    }

    // private _startIntervalCanExport() {
    //     this.interval = setInterval(() => {
    //         this.networkService.canExportProject(this.project.id.toString())
    //             .toPromise().then((res: string) => {
    //             this.canExportProject = res;
    //             if (res === 'yes') {
    //                 clearInterval(this.interval);
    //                 this.interval = undefined;
    //             }
    //         });
    //     }, 10000);
    // }
    corporatesAsEvidenceList: Resource[] = [];

    private setDataForDisplay(isCompareProject: boolean) {
        this.dataForCharts['publications'] = this.getDataForChart(isCompareProject ? this.compareEvidence.papers : this.evidence.papers);
        this.dataForCharts['grants'] = this.getDataForChart(isCompareProject ? this.compareEvidence.grants : this.evidence.grants);
        this.dataForCharts['cts'] = this.getDataForChart(isCompareProject ? this.compareEvidence.cts : this.evidence.cts);
        this.dataForCharts['news'] = this.getDataForChart(isCompareProject ? this.compareEvidence.news : this.evidence.news);
        this.dataForCharts['patents'] = this.getDataForChart(isCompareProject ? this.compareEvidence.patents : this.evidence.patents);

        this.colors['publications'] = this.getColor('publicationCount');
        this.colors['grants'] = this.getColor('grantCount');
        this.colors['cts'] = this.getColor('clinicalTrialCount');
        this.colors['news'] = this.getColor('patentCount')
        this.colors['patents'] = this.getColor('grantCount')
        this.colors['experts'] = this.getColor('experts')
        this.colors['websites'] = this.getColor('websiteCount')
        this.colors['startups'] = this.getColor('startups')
    }

    userClickQuery($event: SearchQueryResults) {
        let dialogRef = this.matDialog.open(SearchDetailsModalComponent, {
            data: {
                buttons: 1,
                yesText: "OK",
                dialogTitle: $event.title,
                query: $event
            } as SearchDetailsModalData
        });

        dialogRef
            .afterClosed()
            .toPromise()
            .then(() => {

            });

    }

    directRequest(expert: Researcher) {
        this.newCalloutDialogRef = this.matDialog.open(CreateCalloutModalComponent, {
            data: {
                researcher: expert
            } as CreateCalloutDialogData
        });
        this.newCalloutDialogRef && this.newCalloutDialogRef.afterClosed().toPromise().then((callout: Callout) => {
            // create the callout
            callout && window.open('/app/callout/' + callout.id, '_blank');
        })
    }

    refreshNow() {
        let dialogRef = this.matDialog.open(YesNoModalComponent, {
            data: {
                dialogTitle: 'Refresh Project',
                dialogDescription: 'Are you sure you want to refresh the project queries?',
                yesText: 'Refresh',
                noText: 'Cancel',
                buttons: 2
            } as YesNoModalData,
        });

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result === 'yes') {
                    this.showEvidenceLoader = true;
                    this.networkService.innovationProjects.refresh(this.project.id).finally(() => {
                        this.showEvidenceLoader = false;
                        window.location.reload();
                    });
                }
            });
    }

    deleteSearch(id:number) {
        const dialogRef = this.matDialog.open(DeleteSearchModalComponent);

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result) {
                    this.showEvidenceLoader = true;
                    this.networkService.searches.deleteSearch(id).toPromise().then(x => {
                        this.project.treebuteSearchResults = this.project.treebuteSearchResults.filter(x => x.id != id);
                    }).finally(() => {
                        this.showEvidenceLoader = false;
                    });
                }
            })

    }

    private setCorporatesAsEvidence(evidence: InnovationProjectEvidenceInterface) {
        if (!evidence.corporates) return;
        const result: Resource[] = [];

        // get country from name for all corporates
        for (let corporate of evidence.corporates){
            this.worldCountries.forEach( country => {
                if(corporate.fullName.toLowerCase().includes(country.toLowerCase())){
                    corporate.countries.push(country);
                }
            });
        }

        // set raw corporate data as a Resouce Array
        for (const e of evidence.corporates) {
            const record = {
                valueA: e.homepageUrl,
                valueB: e.name,
                valueD: e.countries && e.countries.length > 0 ? e.countries[0] : undefined
            } as Resource;
            result.push(record);
        }

        // prefix 'United States' to country if corporate is in the USA
        for (const resource of result) {
            let country = resource.valueD.toUpperCase();
            let isUsaState = country &&
                country.length === 2 &&
                states[country] !== undefined;

            if (isUsaState) {
                resource.valueD = 'United States, ' + states[country];
            }
        }

        // sort by country
        let notUndefined = result.filter(x => x.valueD != undefined && x.valueD.toLowerCase() != 'unknown');
        let undef = result.filter(x => x.valueD == undefined || x.valueD.toLowerCase() === 'unknown');
        notUndefined.sort();

        evidence.corporatesAsEvidenceList = [...notUndefined, ...undef];
    }

    private _loadMap(placesFile: string) {
        if (placesFile) {
            this.networkService.getSharedData<Place[]>(placesFile).then((places: Place[]) => {
                places && this.__loadMap(places);
            })
        }
        else {
            this.networkService.innovationProjects.getPlaces(this.project.id).then((places: Place[]) => {
                places && this.__loadMap(places);
            })
        }
    }

    private __loadMap(places: Place[]) {
        this.places = places;
        setTimeout(() => {
            if (!this.mapLoaded) {
                // main source: https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/

                this.map = new mapboxgl.Map({
                    accessToken: environment.mapbox.accessToken,
                    container: 'map',
                    style: this.style,
                    zoom: 0,
                    center: [this.lng, this.lat]
                });

                // Add map controls
                this.map.addControl(new mapboxgl.NavigationControl());
                let id = 100;
                let features = places.filter(x => x != null && x.title && x.location && x.location.valueB && x.location.valueA).map(x => {
                    let links = [];
                    if (x && x.publications) {
                        for (const publication of x.publications) {
                            links.push(`<a href="${publication.valueD}" target="_blank" title="${publication.valueC}">${publication.valueC}</a>`)
                        }
                    }

                    return {
                        'id': id++,
                        'type': 'Feature',
                        'properties': {
                            'description':
                                `<div style="overflow-y: auto; max-height: 150px;">` +
                                `<p><strong><a uuid="${x.researcher && x.researcher.uuid && !this.readOnlyProjectService.isReadOnlyProject ? x.researcher.uuid : ''}" 
                                    id="${'expert-popup-header-' + (x.researcher && x.researcher.uuid ? x.researcher.uuid :
                                    x.title.toLowerCase().replace(/\s+/g, "") +
                                    x.subTitle.toLowerCase().replace(/\s+/g, ""))}"
                                    >${x.title}</a></strong></p>` +
                                `<p><i>${x.subTitle}</i></p>` +
                                (x.counted > 2 ? `<div>(with ${x.counted - 1} others)</div>` : '') +
                                `<p /><div>Publications:</div><ul>` + links.map(l => `<li>${l}</li>`).join("")+
                                `</ul></div>`,
                            'collaborators': x.counted
                        },
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [x.location.valueB, x.location.valueA]
                        }
                    } as Feature
                });

                this.map.on('load', () => {
                    this.map.addSource('places', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': features
                        }
                    } as AnySourceData);

                    // // Add a layer showing the places.
                    // this.map.addLayer({
                    //     'id': 'places',
                    //     'type': 'symbol',
                    //     'source': 'places',
                    //     'minzoom': 4,
                    //     'paint': {
                    //
                    //     },
                    //     'layout': {
                    //         'icon-image': 'marker-15',
                    //         'icon-allow-overlap': true,
                    //         "icon-size": 2
                    //     }
                    // });

                    // Add a layer showing the places.
                    this.map.addLayer({
                        'id': 'places',
                        'type': 'circle',
                        'source': 'places',
                        'minzoom': 2,
                        'paint': {
                            'circle-radius': 5,
                            'circle-color': '#f8f282',
                            "circle-stroke-color": '#341d1a'
                            // 'circle-color': '#a8dc9f',
                            // "circle-stroke-color": '#cccccc'
                        }
                    });

                    this.map.addLayer(
                        {
                            'id': 'earthquakes-heat',
                            'type': 'heatmap',
                            'source': 'places',
                            'maxzoom': 6,
                            'paint': {
// Increase the heatmap weight based on frequency and property magnitude
                                'heatmap-weight': [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'collaborators'],
                                    3,
                                    3,
                                    9,
                                    4
                                ],
// Increase the heatmap color weight weight by zoom level
// heatmap-intensity is a multiplier on top of heatmap-weight
                                'heatmap-intensity': [
                                    'interpolate',
                                    ['linear'],
                                    ['zoom'],
                                    0,
                                    1,
                                    9,
                                    3
                                ],
// Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
// Begin color ramp at 0-stop with a 0-transparancy color
// to create a blur-like effect.
                                'heatmap-color': [
                                    'interpolate',
                                    ['linear'],
                                    ['heatmap-density'],
                                    0,
                                    'rgba(33,102,172,0)',
                                    0.2,
                                    'rgb(103,169,207)',
                                    0.4,
                                    'rgb(209,229,240)',
                                    0.6,
                                    'rgb(253,219,199)',
                                    0.8,
                                    'rgb(239,138,98)',
                                    1,
                                    'rgb(178,24,43)'
                                ],
// Adjust the heatmap radius by zoom level
                                'heatmap-radius': [
                                    'interpolate',
                                    ['linear'],
                                    ['zoom'],
                                    0,
                                    2,
                                    9,
                                    20
                                ],
// Transition from heatmap to circle layer by zoom level
                                'heatmap-opacity': [
                                    'interpolate',
                                    ['linear'],
                                    ['zoom'],
                                    4,
                                    1,
                                    6,
                                    0
                                ]
                            }
                        },
                        'waterway-label'
                    );

                    this.map.on('click', 'places', (e) => {
                        const coordinates = e.features[0].geometry['coordinates'];// e.features[0].geometry.coordinates.slice();
                        const description = e.features[0].properties.description;

                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        new mapboxgl.Popup({ className: "dark-popup", focusAfterOpen: false })
                            .setLngLat(coordinates)
                            .setHTML(description)
                            .addTo(this.map);

                        setTimeout(() => {
                            // get all the 'A' elements representing the popup headers
                            let links = document.querySelectorAll("[id^='expert-popup-header-']");

                            // if there is already a javascript event - ignore
                            for (const link of Array.from(links)) {
                                let mark = link.getAttribute('hasLink');
                                if (mark) continue;

                                link.setAttribute('hasLink', 'true');
                                link.addEventListener('click', (() => this.expertClickFromLink(link)))
                                link['style']['cursor'] = 'pointer';
                            }

                            // add a javascript event on the element

                            //

                        }, 10);
                    });

                    this.map.on('mouseenter', 'places', () => {
                        this.map.getCanvas().style.cursor = 'pointer';
                    });

                    this.map.on('mouseleave', 'places', () => {
                        this.map.getCanvas().style.cursor = '';
                    });

                });

                this.mapLoaded=true;

                setTimeout(() => this.map.resize(), 10);
            }
        }, 10);

    }

    private expertClickFromLink(link: Element) {
        if (!this.places) return;
        let places = this.places
            .filter(p => p.researcher)
            .filter(p => p.researcher.uuid === link.getAttribute('uuid'));

        if (places && places.length > 0) {
            let place = places[0];
            this.expertsEvidenceChunk.expertClick(place.researcher, true);
        }
    }
}


class PaperIndexer {
    yearIndex = {}
    labels = [];

    constructor() {

        let years = Array.from(Array(20).keys()).map(x => x+ 2010);
        for (const year of years) {
            this.yearIndex[year] = [0, 0, 0, 0]; // Year Quarters
            this.labels.push(`Q1.${year}`);
            this.labels.push(`Q2.${year}`);
            this.labels.push(`Q3.${year}`);
            this.labels.push(`Q4.${year}`);
        }
    }

    getData() {
        let result = [];
        for (let d in this.yearIndex) {
            result.push(this.yearIndex[d][0]);
            result.push(this.yearIndex[d][1]);
            result.push(this.yearIndex[d][2]);
            result.push(this.yearIndex[d][3]);
        }

        return result;
    }

    getDataYearly() {
        let result = [];
        for (let d in this.yearIndex) {
            result.push(this.yearIndex[d].reduce((x, a) => x + a, 0));
        }

        return result;
    }

    getLabels() {
        return this.labels;
    }

    getLabelsYearly() {
        return Array.from(Array(20).keys()).map(x => x+ 2010);
    }

    setDataInQuarterByDate(m: Moment) {
        if (!m) return;
        this.yearIndex[m.year()][m.quarter() - 1]++;
    }

}
