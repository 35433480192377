<div>
    <app-expert-details *ngIf="goodToGo"
            [expert]="currentExpert"
            [searchResults]="searchResults"
            [enableNextExpert]="enableNextExpert()"
            [enablePreviousExpert]="enablePreviousExpert()"
                        [tabActive]="getTabActive()"
            (close)="close()"
            (nextExpert)="nextExpert()"
            (previousExpert)="previousExpert()"
    >
    </app-expert-details>
</div>
