<div class="container project-page pt-5">
    <div class="loader-panel" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <h1>Canvas List</h1>
    <div class="py-5">
        <ul>
            <li *ngFor="let canvas of canvasList">
                <a [routerLink]="'/app/canvas/'+canvas.id">{{canvas.title}}</a>
            </li>
        </ul>
    </div>
    <div>
        <button class="btn btn-primary" (click)="createNewCanvas()">Add New Canvas</button>
    </div>

</div>
