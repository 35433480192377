import { Injectable, ɵConsole } from '@angular/core';
import {Researcher} from "./api-responses/SearchQueryResults.interface";
import {ClinicalTrial} from "./api-responses/ClinicalTrial.interface";
import {ChartDist, ChartFilter, SelectedField} from "./api-responses/graphs.interface";
import states from "./utils/states_hash.json";

@Injectable({
    providedIn: 'root',
})
export class UtilitiesService {
    constructor() {}

    static vw() {
        return Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0,
        );
    }

    static trimString(str, len) {
        if (str.length < len) {
            return str;
        }
        let temp = str.substring(0, len);
        if (temp.includes(' ')) {
            temp = temp.substring(0, temp.lastIndexOf(' '));
        }
        return temp.trim() + '...';
    }

    static checkWidth(event, title) {
        event.target.title = '';
        if (event.target.offsetWidth !== event.target.scrollWidth) {
            event.target.title = title;
        }
    }

    static isAccountAdmin(userData) {
        return UtilitiesService.hasRole(userData, 'ROLE_ACCOUNT_ADMIN');
    }

    static isAdmin(userData) {
        return UtilitiesService.hasRole(userData, 'ROLE_ADMIN');
    }

    static isLimited(userData) {
        return UtilitiesService.hasRole(userData, 'ROLE_LIMITED');
    }

    static isDashboardUser(userData) {
        return UtilitiesService.hasRole(userData, 'ROLE_DASHBOARD');
    }

    static isStartupFinder(userData) {
        return UtilitiesService.hasRole(userData, 'ROLE_STARTUP_FINDER');
    }

    static isCorporateFinder(userData) {
        return UtilitiesService.hasRole(userData, 'ROLE_CORPORATE_FINDER');
    }

    static hasRole(userData, role) {
        if (userData === undefined || userData == null) {
            return false;
        } else {
            return userData.authorities.includes(role);
        }
    }

    static arraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = arr1.length; i--; ) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }

    static checkBrowser() {
        if (/Edge/.test(navigator.userAgent)) {
            return 'Edge';
        } else if (/Firefox/.test(navigator.userAgent)) {
            return 'Firefox';
        } else if (/Chrome/.test(navigator.userAgent)) {
            return 'Chrome';
        }
    }

    static handleKeyboardEvents(
        event,
        lastClick,
        array,
        search,
        global = null,
        component = null,
        smoothScroll = null,
        retry = false,
    ) {
        if (
            (component === null || component === 'expertsSearch') &&
            (global === null || global === true)
        ) {
            if (
                event.key === undefined ||
                !/[a-zA-Z]/.test(String.fromCharCode(event.keyCode)) ||
                event.ctrlKey ||
                !/[a-zA-Z]/.test(event.key)
            ) {
                return;
            }

            if (Math.round(Date.now() - lastClick) > 10000) {
                search = '';
            }

            search += event.key.toLowerCase();
            let scroll = false;
            let found = false;

            array.forEach((country) => {
                if (
                    !scroll &&
                    country.startsWith(
                        search[0].toUpperCase() + search.substring(1),
                    )
                ) {
                    found = true;
                    const elm = document.getElementById(country);
                    if (elm) {
                        smoothScroll
                            ? elm.scrollIntoView()
                            : elm.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'end',
                                  inline: 'nearest',
                              });
                        scroll = true;
                    }
                }
                return;
            });

            if (!found && !retry) {
                return this.handleKeyboardEvents(
                    event,
                    lastClick,
                    array,
                    '',
                    global,
                    component,
                    smoothScroll,
                    true,
                );
            }

            const ret = search; // Copy the value
            return [ret, Date.now()];
        }
    }

    static getCountry(researcher: Researcher): string {
        let result = researcher.countries.length > 0 ?
            researcher.countries[0] :
            "Unknown";

        let isUsaState = result &&
            result.length === 2 &&
            states[result.toUpperCase()] !== undefined;

        if (isUsaState) {
            result = 'United States, ' + states[result.toUpperCase()];
        }

        return result;
    }


    // static getGoogleSearchLink(term: string, containerElem: Element) {
    //     if (!term) return '';
    //     let cleanDep = term.replace(" ", "+");
    //     let link = `<a href="https://www.google.com/search?q=${cleanDep}" target="_blank">${term}</a>`;
    //     containerElem.insertAdjacentHTML('beforeend', link);
    // }

    static getGoogleSearchUrl(term: string) {
        if (!term) return '';
        let cleanTerm = term.replace(',' , ' ').replace(" ", "+");
        return `https://www.google.com/search?q=${cleanTerm}`;
    }

    static getDistributionForChart(list: Object[], getAttr: (x) => any): ChartDist[] {
        let result : ChartDist[] = [];
        for (const item of list) {
            if (!getAttr(item)) continue;
            for (const attr of getAttr(item)) {
                let records = result.filter(x => x.txt === attr);
                let record: ChartDist;
                if (records.length === 0) {
                    record = {
                        txt: attr,
                        count: 0
                    } as ChartDist;

                    result.push(record);
                } else {
                    record = records[0];
                }

                record.count += 1;
            }
        }
        result.sort((a, b) => b.count - a.count);
        return result;
    }

    static getSelectedForChart(items: ChartDist[]) {
        return items.map(x => {
            return {text: x.txt, displayText: x.txt, selected: true, num: x.count}
        });
    }

    static createFilter(type: string, filter: Function, conf: SelectedField[]): ChartFilter {
        return {
            filter: filter,
            type: type,
            conf: conf
        }
    }

    static filter<T>(data: T[], filters: ChartFilter[]): T[] {
        let result: T[] = data;
        for (const filter of filters) {
            result = result.filter(x => {
                let include = false;
                try {
                    if (filter.type === 'listAttr') {
                        for (const country of filter.filter(x)) {
                            for (const selectedCountry of filter.conf) {
                                if ((country.toLowerCase() === selectedCountry.text.toLowerCase()) && selectedCountry.selected) {
                                    include = true;
                                    break;
                                }
                            }
                        }
                    }
                    else if (filter.type === 'simpleAttr') {
                        // by single attr
                        for (const selectedCountry of filter.conf) {
                            if ((filter.filter(x).toLowerCase() === selectedCountry.text.toLowerCase()) && selectedCountry.selected) {
                                include = true;
                                break;
                            }
                        }
                    }
                }
                catch (e) {
                    return false;
                }

                return include;
            })
        }

        return result;
    }
}
