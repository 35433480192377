<div (click)="!expertDetailsLoading ? this.showModal.emit() : null"
    class="backruond_card"
    [class.container_disabled]="
        isLimited() &&
        this.cardIndex >= this.dataService.userData.limitedUserMaxResultsSize
    "
    [class.exclude]="expert.exclude"
    [class.selectedCard]="false"
    [style.opacity]="opacity"
>
    <div
        *ngIf="selected && false"
        class="check"
        style="
            background-image: url('assets/images/v-symbol.png');
            display: block;
        "
    ></div>
    <div
        *ngIf="!selected && false"
        class="check"
        style="
            background-image: url('assets/images/v-symbol-hover.png');
            background-size: 90%;
        "
    ></div>

    <div
        style="
            display: flex;
            align-items: center;
            justify-content: space-between;
        "
    >
        <div
            *ngIf="expert.logoURL"
            [style.backgroundImage]="
                expert.logoURL ? 'url(' + expert.logoURL + ')' : 'none'
            "
            class="instLogo"
        ></div>

        <div [style.width]="expert.logoURL ? 'calc(100% - 50px)' : '100%'">
            <div [class.a]="name.length > nameMaxLength">
                <div
                    class="name wrap"

                    [style.margin]="department ? '-3px 0 3px' : '0 0 3px'"
                >
                    {{ name }}
                </div>
                <span class="tooltipName"> {{ name }} </span>
            </div>
            <div
                [class.b]="
                    department &&
                    ((expert.logoURL && department.length > 40) ||
                        (!expert.logoURL && department.length > 30))
                "
            >
                <div class="institute wrap">{{ department }}</div>
                <span class="tooltipInstitute"> {{ department }} </span>
            </div>
        </div>
    </div>

    <div class="categories">
        <div class="category">
            <span class="title">Evidence</span>
            <div class="bar" style="border: solid 1px #e85a4e">
                <div
                    [style.background]="'#e85a4e'"
                    [style.width]="relevancy + 2 + 'px'"
                    [class.fill]="relevancy > 139"
                    [class.empty]="relevancy === 0"
                ></div>
            </div>
        </div>

        <div class="category">
            <span class="title">Impact</span>
            <div class="bar" style="border: solid 1px #b9646e">
                <div
                    [style.background]="'#b9646e'"
                    [style.width]="impact + 2 + 'px'"
                    [class.fill]="impact > 139"
                    [class.empty]="impact === 0"
                ></div>
            </div>
        </div>

        <div class="category" *ngIf="innovationPresent">
            <span class="title">Innovation</span>
            <div class="bar" style="border: solid 1px #74749d">
                <div
                    [style.background]="'#74749d'"
                    [style.width]="innovation + 2 + 'px'"
                    [class.fill]="innovation > 139"
                    [class.empty]="innovation === 0"
                ></div>
            </div>
        </div>

        <div class="line"></div>
    </div>

    <div class="quote_container" *ngIf="quote">
        <img src="assets/images/innovation-icon.png" class="innovation_icon" />
        <div class="quote">{{ quote }}</div>
    </div>

    <div class="email_container" *ngIf="email">
        <img src="assets/images/envelope-icon-dark.png" class="envelope_icon" />
        <a target="_blank" href="mailto:{{ email }}" class="email wrap">{{
            email
        }}</a>
    </div>

    <div>
        <button class="read_more_button" [matMenuTriggerFor]="menu" (click)="preventDefault($event)">
            Connect
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="directRequest.emit($event)">New Request</button>
            <button mat-menu-item (click)="addToRequest(expert)" >Add to Request...</button>
        </mat-menu>
    </div>

<!--    <button-->
<!--        [disabled]="expertDetailsLoading"-->
<!--        class="read_more_button"-->
<!--        [class.selectedCardButton]="selected"-->
<!--        id="loadingBtn{{ firstName }}{{ lastName }}"-->
<!--        style="background-image: url('assets/images/loadloop.gif')"-->
<!--        (click)="!expertDetailsLoading ? this.showModal.emit($event) : null"-->
<!--    >-->
<!--        {{ dataService.isFeatureFlag() ? 'Reach Out' : 'View More' }}-->
<!--    </button>-->
    <exclude-include (isExcludeChange)="shouldSave()" class="exclude-include"
                     [hidden]="dataService.hideShared()"
                     [(isExclude)]="expert.exclude">

    </exclude-include>
</div>
