<!--<ul id="progress">-->
<!--    <li [class.active]="getStatusIndex()===0">{{getText(0)}}</li>-->
<!--    <li [class.active]="getStatusIndex()===1">{{getText(1)}}</li>-->
<!--    <li [class.active]="getStatusIndex()===2">{{getText(2)}}</li>-->
<!--    <li [class.active]="getStatusIndex()===3">{{getText(3)}}</li>-->
<!--    <li [class.active]="getStatusIndex()===4">{{getText(4)}}</li>-->
<!--</ul>-->
<div class="wrap2"  *ngIf="active">
    <div class="progress2" [class.active]="isActive(0)" matTooltip="{{getTooltip(0)}}">
        <span class="text-over">
            {{getText(0)}}
        </span>
        <span class="text-over-2">
            {{getText(0)}}
        </span>
    </div>
    <div class="progress2" [class.active]="isActive(1)" matTooltip="{{getTooltip(1)}}">
        <span class="text-over">
            {{getText(1)}}
        </span>
        <span class="text-over-2">
            {{getText(1)}}
        </span>
    </div>
    <div class="progress2" [class.active]="isActive(2)" matTooltip="{{getTooltip(2)}}">
        <span class="text-over">
            {{getText(2)}}
        </span>
        <span class="text-over-2">
            {{getText(2)}}
        </span>
    </div>
    <div class="progress2" [class.active]="isActive(3)" matTooltip="{{getTooltip(3)}}">
        <span class="text-over">
            {{getText(3)}}
        </span>
        <span class="text-over-2">
            {{getText(3)}}
        </span>
    </div>
<!--    <div class="progress2" [class.active]="getStatusIndex()===4">-->
<!--        <span class="text-over">-->
<!--        {{getText(4)}}-->
<!--        </span>-->
<!--    </div>-->
</div>
