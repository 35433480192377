<div class="col-md-6 offset-md-3" id="group-creation-modal">
    <h3>Edit Users Group - {{ group.groupName }}</h3>

    <form #newUserInGroupForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="email">Email</label>
            <input
                type="email"
                class="form-control"
                id="email"
                #email="ngModel"
                name="email"
                [(ngModel)]="newUser.email"
                required
            />
        </div>
        <div class="form-group">
            <label for="jobType">Job type</label>
            <input
                type="text"
                class="form-control"
                id="jobType"
                #jobType="ngModel"
                name="jobType"
                [(ngModel)]="newUser.jobType"
            />
        </div>
        <div class="form-group">
            <label>User level</label><br />
            <label class="radio-inline"
                ><input
                    name="level"
                    type="radio"
                    [(ngModel)]="newUser.level"
                    required
                    value="Regular"
                />Regular</label
            >
            <label class="radio-inline"
                ><input
                    name="level"
                    type="radio"
                    [(ngModel)]="newUser.level"
                    required
                    value="Manager"
                />Manager</label
            >
        </div>

        <div class="form-group">
            <button
                class="btn btn-primary"
                [disabled]="!newUserInGroupForm.form.valid"
            >
                Create
            </button>
        </div>
    </form>

    <div *ngIf="usersInGroup$">
        <h4>Users in {{ group.groupName }}</h4>
        <ul class="list-group">
            <li *ngFor="let user of usersInGroup$" class="list-group-item">
                <a class="dropdown-item btn-sm"
                    >{{ user.userInGroupType }} {{ user.email }}
                    {{ user.exists }}</a
                ><span
                    class="glyphicon glyphicon-remove"
                    (click)="deleteUserClicked(user)"
                ></span>
            </li>
        </ul>
    </div>
</div>
