import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NetworkService} from "../../../network.service";
import {ShareModalData} from "../../../api-responses/Dialogs.interface";
import {Canvas} from "../canvas.interface";
import {InnovationProject} from "../../../api-responses/InnovationProjects.interface";

interface SelectedProject {
    id: number,
    name: string,
    selected: boolean
}


@Component({
    selector: 'canvas-project-select',
    templateUrl: './canvas-project-select-modal.component.html',
    styleUrls: ['./canvas-project-select-modal.component.scss'],
})
export class CanvasProjectSelectModalComponent implements OnInit{

    projects: InnovationProject[] = [];
    selectedProjects:SelectedProject[] = [];

    constructor(public dialogRef: MatDialogRef<Canvas>,
                public networkService: NetworkService,
                @Inject(MAT_DIALOG_DATA) public data: Canvas) {
    }


    ngOnInit(): void {
        this.networkService.innovationProjects.getAll().then((res: InnovationProject[]) => {
            this.projects = res;
            let selected = this.data.projects.map(x => x.id);

            this.selectedProjects = this.projects.map(x => { return {
                id: x.id,
                selected: selected.indexOf(x.id) > -1,
                name: x.name
            } })
        })
    }


    cancel() {
        this.dialogRef.close();
    }

    save() {
        let selected = this.selectedProjects.filter(x => x.selected).map(x => x.id);
        for (const project of this.data.projects) {

        }

        this.dialogRef.close();
    }
}

