import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SelectedCompany } from '../../../../api-responses/SearchQueryResults.interface';

@Injectable({
    providedIn: 'root',
})
export class CompanyModalService {
    constructor() {}

    // ****************** From service to modal ****************
    // data
    public selectedData: any = {};

    // channel
    private selectedDataMessageSource = new BehaviorSubject(this.selectedData);

    // listener
    selectedDataObservable = this.selectedDataMessageSource.asObservable();

    // broadcast
    changeSelectedCompany(message: SelectedCompany) {
        this.selectedDataMessageSource.next(message);
    }

    // ****************** From modal to service ****************
    //data
    public desiredIndex: number = -1;

    // channel
    private desiredIndexMessageSource = new BehaviorSubject(this.selectedData);

    // listener
    desiredIndexObservable = this.desiredIndexMessageSource.asObservable();

    // broadcast
    changeDesiredIndex(message: number) {
        this.desiredIndexMessageSource.next(message);
    }
}
