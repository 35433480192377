<div style="display: flex; flex-direction: column">
    <div class="col-md-6 offset-md-3">
        <h3>Search for a strength mapper comparable institution:</h3>
        <form (ngSubmit)="onSearchSubmit()">
            <div class="form-group" style="display: flex">
                <textarea
                    [(ngModel)]="searchString"
                    name="searchString"
                    class="form-control"
                    rows="1"
                    id="searchString"
                ></textarea>
                <button [disabled]="searchDisabled()" class="btn btn-primary">
                    Search
                </button>
            </div>
        </form>
    </div>
    <div *ngIf="noResults" class="noResults">
        <a class="btn btn-info btn-lg">
            <span class="glyphicon glyphicon-warning-sign"></span> No results -
            please enter different text
        </a>
        <div *ngIf="newInstitute" style="margin: 20px">
            <span style="font-size: 18px; vertical-align: middle">
                Do you want to create a new strength comparable institute?
            </span>
            <button
                class="btn btn-primary btn-sm"
                style="width: 50px; margin: 0 2px"
                (click)="create()"
            >
                Yes
            </button>
            <button
                class="btn btn-primary btn-sm"
                style="width: 50px; margin: 0 2px"
                (click)="newInstitute = !newInstitute"
            >
                No
            </button>
        </div>
    </div>
    <div *ngIf="name" class="results">
        <div class="col-8" style="margin-bottom: 10px">
            <label for="name">Name</label>
            <div style="display: flex">
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    value="{{ name }}"
                    style="
                        width: 500px;
                        cursor: default;
                        background-color: lightgrey;
                    "
                    disabled
                />
            </div>
        </div>
        <div class="col-8">
            <button
                type="button"
                (click)="delete()"
                class="btn btn-primary btn-sm"
            >
                Delete
            </button>
        </div>
    </div>
</div>
