import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import moment from "moment";
import { UtilsComponent } from 'src/app/utils/utils.component';
@Component({
    selector: 'vcs-admin-management',
    templateUrl: './vcs-admin.component.html',
    styleUrls: ['./vcs-admin.component.scss'],
})
export class VcsAdminComponent implements OnInit {
    vcs: any = [];
    crawlTypeSelect: any;
    @ViewChild('fastLink') fastLink: ElementRef;
    @ViewChild('vcName') vcName: ElementRef;
    @ViewChild('fullName') fullName: ElementRef;
    @ViewChild('isActive') isActive: ElementRef;
    @ViewChild('isAggregator') isAggregator: ElementRef;
    @ViewChild('url') url: ElementRef;
    @ViewChild('countrySelect') countrySelect: ElementRef;
    @ViewChild('crawlDepthSelect') crawlDepthSelect: ElementRef;
    @ViewChild('pageProcessor') pageProcessor: ElementRef;
    countries: any[] = UtilsComponent.countries;

    constructor(private networkService: NetworkService) {}

    saveAllAndClose(
        name: string,
        fullName: string,
        isActive: boolean,
        isAggregator: boolean,
        url: string,
        countrySelect: string,
        crawlDepthSelect: string,
        pageProcessor: string,
    ) {
        this.saveAll(
            name,
            fullName,
            isActive,
            isAggregator,
            url,
            countrySelect,
            crawlDepthSelect,
            pageProcessor,
        );
        this.vcs = this.vcs.filter((vc) => vc['name'] !== name);
    }

    saveAll(
        name: string,
        fullName: string,
        isActive: boolean,
        isAggregator: boolean,
        url: string,
        countrySelect: string,
        crawlDepthSelect: string,
        pageProcessor: string,
    ) {
        this.networkService
            .saveVc(
                name,
                fullName,
                isActive,
                isAggregator,
                url,
                countrySelect,
                crawlDepthSelect,
                pageProcessor,
            )
            .subscribe(
                (data) => {
                    alert('Saved!');
                },
                (error) => {
                    alert(error);
                },
            );
    }

    ngOnInit() {
        this.networkService.getVCs().subscribe(
            (data) => {
                if (data) {
                    this.handleResponse(data);
                }
            },
            (error) => console.error(error),
        );
    }

    handleResponse(data) {
        data.forEach(
            (x) =>
                (x['lastProcessedDate'] = moment(
                    x['lastProcessedDate'],
                ).fromNow()),
        );
        this.vcs = data;
    }

    crawlVC(
        name: string,
        fullName: string,
        isActive: boolean,
        isAggregator: boolean,
        url: string,
        countrySelect: string,
        crawlDepthSelect: string,
        pageProcessor: string,
    ) {
        if (!isActive) {
            alert('VC must be active to crawl');
            return;
        }

        if (confirm('Do you want to crawl this VC now?')) {
            this.networkService
                .saveVc(
                    name,
                    fullName,
                    isActive,
                    isAggregator,
                    url,
                    countrySelect,
                    crawlDepthSelect,
                    pageProcessor,
                )
                .subscribe(
                    (data1) => {
                        this.networkService.crawlVc(name).subscribe(
                            (data2) => {
                                alert(
                                    'Request to crawl ' + name + ' was sent!',
                                );
                                window.location.reload();
                            },
                            (error) => console.error(error),
                        );
                    },
                    (error) => {
                        alert(error);
                    },
                );
        }
    }

    updatePageProcessor(name: string, pageProcessor: HTMLInputElement) {
        pageProcessor.getAttribute('value');
    }

    testPageProcessor(
        name: string,
        url: HTMLInputElement,
        pageProcessor: HTMLInputElement,
        spinner: HTMLDivElement,
        result: HTMLSpanElement,
    ) {
        spinner.style.display = 'block-inline';
        result.innerHTML = 'crawling....';
        this.networkService
            .testVcCrawler(name, url.value, pageProcessor.value)
            .subscribe(
                (data) => {
                    if (data) {
                        spinner.style.display = 'none';
                        if ((data as Array<object>).length <= 0) {
                            result.innerHTML = `That didn't work. try again`;
                        } else {
                            result.innerHTML = JSON.stringify(
                                (data as Array<object>).map(
                                    (x) => x['homepageUrl'],
                                ),
                            );
                        }
                        console.log(data);
                    }
                },
                (error) => {
                    result.innerHTML = `There was an error in the server`;
                    console.error(error);
                    spinner.style.display = 'none';
                },
            );
    }

    urlChange($event: Event) {
        // this.fastLink.nativeElement.setAttribute('href', $event.target.value);
    }

    deleteAndExcludeVc(vcName: any, exclude: boolean) {
        if (confirm('are you sure you want to delete ' + vcName + '?')) {
            this.networkService.deleteAndExcludeVc(vcName, exclude).subscribe(
                (data) => {
                    this.vcs = this.vcs.filter((vc) => vc['name'] !== vcName);
                },
                (error) => console.error(error),
            );
        }
    }
}
