import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {Researcher, SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {NetworkService} from "../../../../network.service";
import {DataService} from "../../../../data.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ExpertsModalData, YesNoModalData} from "../../../../api-responses/Dialogs.interface";

@Component({
    selector: 'expert-modal',
    templateUrl: './expert-modal.component.html',
    styleUrls: ['./expert-modal.component.scss'],
})
export class ExpertModalComponent implements OnInit, OnDestroy {

    currentExpert: Researcher;
    experts: Researcher[];
    searchResults: SearchQueryResults;
    goodToGo: boolean = false;

    constructor(private networkService: NetworkService,
                public dataService: DataService,
                public dialogRef: MatDialogRef<ExpertModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ExpertsModalData
    ) {
        this.experts = data.experts;
        this.currentExpert = data.expert;
        this.searchResults = data.searchResults

    }

    ngOnInit(): void {
        this._loadExpert(this.currentExpert)
    }

    _loadExpert(expert: Researcher) {
        this.dataService.updateLoadingState(true);
        if (!expert.expertData) expert.expertData = {};
        this.networkService
            .getDescriptorsMapping(
                expert.firstName,
                expert.lastName,
                expert.initials,
                expert.publications,
                undefined,
                expert.email,
                this.data.industrySearch,
                this.data.isGlobal,
                expert.department,
                expert.allNames,
                expert.affiliations,
            )
            .subscribe((data) => {
                const body = document.getElementsByTagName('body')[0];
                // body.style.overflowY = 'hidden';

                this.handleExpertData(expert, data);
                this.currentExpert = expert;
                this.dataService.updateLoadingState(false);

                this.goodToGo = this.data.expert.expertData &&
                    Object.keys(this.data.expert.expertData).length > 0;
            }, error => {
                this.dataService.updateLoadingState(false);
            });
    }

    ngOnDestroy(): void {
    }

    handleExpertData(researcher, data) {
        researcher.expertData = data;
        researcher.patentsData = researcher.patentTitlesToURLs;
        if (data.department && data.department.length > 3) {
            researcher.department = data.department;
        }
        if (data.email) {
            researcher.email = data.email;
        }
        // this.cdRef.detectChanges();
    }

    close() {
        this.dialogRef.close();
    }

    public enableNextExpert() {
        return !this.data.hideNav && this.experts.indexOf(this.currentExpert) < this.experts.length - 1;
    }

    public enablePreviousExpert() {
        return !this.data.hideNav &&this.experts.indexOf(this.currentExpert) > 0;
    }

    nextExpert() {
        this.data.reachOut = false;
        let index = this.experts.indexOf(this.currentExpert);
        if (this.data.simpleNavigation) {
            this._loadExpert(this.experts[index + 1]);
        } else {
            this.dialogRef.close({ reopen: this.experts[index+1]})
        }
    }

    previousExpert() {
        this.data.reachOut = false;
        let index = this.experts.indexOf(this.currentExpert);
        if (this.data.simpleNavigation) {
            this._loadExpert(this.experts[index - 1]);
        }
        else {
            this.dialogRef.close({reopen: this.experts[index - 1]})
        }
    }

    getTabActive() {
        return this.data.reachOut ? 2 : 0;
    }
}
