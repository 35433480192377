<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8" />
    <base href="/">
    <title>$pageTitle</title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
    <meta name="description" content="$description">
    <meta name="keywords" content="$keywords">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="icon" type="image/x-icon" href="/images/fabicon-treebute.png">
    <style>
        #my_custom_link {
            position: fixed;
            z-index: 1000;
            top: 5px;
            right: 5px;
            height: 40px;
            width: 40px;
            box-shadow: 0 0 8px 0 #008ded;
            background-color: #2e2e2e;
            background-size: 64%;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
        }

        .intercom-messenger-frame {
            top: 52px !important;
            right: 5px !important;
        }
    </style>

    <script>
		window.intercomSettings = {
			app_id: "$intercomAppId",
			name: "$name",
			user_id: "$id",
			email: "$email",
			created_at: "$createdAt",
			horizontal_padding: 20,
			vertical_padding: 20,
			hide_default_launcher: true,
			custom_launcher_selector: '#my_custom_link'
		};
    </script>
    <script>(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ozi0aqhj';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();</script>




</head>
<body>

<div id="container">
    <script>
		let sections = [
			{
				title: "In a nutshell (TL;DR)",
				id: "in-a-nutshell",
				content: `The following key points of the Terms of Use (the “<b>Terms</b>”) are only brought for your convenience. They do not substitute the full Terms. <br><br>
                  <ul>
                    <li> You may only use the Service if you are an individual with full legal capacity, and duly authorized to enter into this agreement. </li>
                    <li> To use the Service, you must subscribe to the Service and accept these Terms of Use. </li>
                    <li> Using the Service may be subject to payment of applicable Fees, if and as presented to you during subscription or at a later time. </li>
                    <li> We do not claim ownership over the content that you contribute, but you do give us a right to use it for the purpose of providing the Service, improving it and developing new services and offerings. </li>
                    <li> We may send you commercial messages when you subscribe to the Service, but you may always opt-out from receiving such. </li>
                    <li> You may only upload lawful, non-infringing content on or through the Service. You may not post or upload any objectionable content. </li>
                    <li> Treebute has the right to suspend your use of the Service, if you do not comply with these Terms, including if you upload content that allegedly infringes on the rights of others. </li>
                    <li> We respect your privacy. To learn more about our privacy practices, see our <a target='_blank' href='privacy2'>Privacy Policy</a> which is incorporated to these Terms by reference. </li>
                    <li> You may cancel Your Subscription at any time by providing us a written notice of cancellation and termination. </li>
                    <li> <i>THESE TERMS INCLUDE ADDITIONAL PROVISIONS THAT YOU SHOULD CAREFULLY READ, REGARDING WARRANTY, LIMITED LIABILITY, INDEMNIFICATION AND ASSIGNMENT.</i> </li>
                  </ul>
                  `
			},
			{
				title: "Agreement",
				id: "agreement",
				content: `Please read carefully the following Terms. By using, accessing or Subscribing to the Service, you agree to be bound by the Terms. If you do not agree to the Terms, you may not use the Service. Throughout these Terms, you will be referred to as “<b>you</b>” or “<b>your</b>”. <br><br>
                  If you are subscribing and entering into these Terms on behalf of an organization (the “<b>Organization</b>”), you represent and warrant that you have or you were granted full authority to bind the Organization to these Terms. <br><br>
                  If you do not agree to comply with and be bound by these Terms, or if you do not have the authority to bind the Organization by these Terms, do not accept these Terms or access the Service, and do not provide us with any personal data of yours. <br><br>
                  You may only use the Service if you are an individual over the age of 18, with full legal capacity. You represent and warrant that you are 18 years of age or older.`
			},
			{
				title: "Commercial messages",
				id: "commercial-messages",
				content: "<i>BY SUBMITTING YOUR CONTACT DETAILS, YOU PROVIDE US WITH YOUR EXPRESS CONSENT TO RECEIVE COMMERCIAL MESSAGES FROM TREEBUTE OR OTHERS ON ITS BEHALF. AT ANY TIME, YOU MAY WITHDRAW YOUR CONSENT AND NOTIFY US THAT YOU REFUSE TO RECEIVE COMMERCIAL MESSAGES, ALTOGETHER OR OF SPECIFIC KIND, BY SENDING A NOTICE OF CONSENT WITHDRAWAL. THE CHANNELS THROUGH WHICH YOU MAY SEND SUCH NOTICE WILL BE DETAILED IN THE COMMERCIAL MESSAGES SENT TO YOU BY THE COMPANY OR OTHERS ON ITS BEHALF.</i>"
			},
			{
				title: "Description of the service",
				id: "description-of-the-service",
				content: "The Service is made available to you on a software as a service basis through the Site. It includes: <b>(i)</b> The software that you access via the Site; <b>(ii)</b> Any other products, services and features made available or provided to you by Treebute in connection with the Service; and <b>(iii)</b> The trademarks, service marks and logos (“<b>Marks</b>”), content, text, documents, descriptions, products, graphics, photos, sounds, videos and interactive features contained in or made available through the Service."
			},
			{
				title: "Subscription to the service",
				id: "subscription-to-the-service",
				content: "Subject to your compliance with these Terms and (if applicable) the payment of subscription fees by you or the Organization, Treebute hereby grants you a non-exclusive, non-transferable, non-sublicensable and fully revocable subscription (“<b>Subscription</b>”) to access and use the Service during the Subscription period."
			},
			{
				title: "Who may use the service?",
				id: "who-may-use-the-service",
				content: `You and any individual acting on behalf of the Organisation who has been provided with a user identification and password (“<b>Authorized Users</b>”) can access and use the Service for reasonable business needs only.  Authorized Users may include but are not limited to employees, consultants, contractors agents and third parties acting for or on behalf of the Organization. <br><br>
                  In order to use the Service and become an Authorized User, you are required to create a personal user account (the “<b>Account</b>”). By creating the Account, you acknowledge and agree: <b>(i)</b> To provide us with accurate and complete Account and login information; <b>(ii)</b> To keep, and ensure that, your Account login details and password are always secured; and <b>(iii)</b> To remain solely responsible and liable for any activity arising out of failure to keep your Account details confidential; and <b>(iv)</b> To promptly notify us in writing if you become aware of any unauthorized access or use of your Account or the Service.`
			},
			{
				title: "Fees and billing",
				id: "fees-and-billing",
				content: `We may offer the Service (or some of its features) on a trial-basis, free-of-charge, which may be time-limited. Beyond this trial-basis, the Service may be offered subject to your payment of applicable fees (the “Fees”), in accordance with the packages, schemes and amounts presented to you upon subscribing to the Service or at a later time (“Plans”). We may transform a free of charge section of the Service to a fee-based service and will seek your consent to the payment of those Fees. If you do not consent, we may terminate your Subscription. <br><br>
                  All Fees are quoted in US Dollars, unless expressly stated otherwise. <br><br>
                  If applicable, we will charge you for Fees using the payment method you provide (“Billing Information”). You represent and warrant that you are lawfully permitted to use your selected payment method. You must keep the Billing Information you provided to us current, complete, and accurate, and notify us promptly in case of any change in your Billing Information. <br><br>
                  By subscribing to the Service and choosing your Plan, you give your consent to being billed for the Fees, in addition to any applicable taxes and surcharges or commissions charged by the payment processor. All amounts payable to us are exclusive of any excise, sales tax, VAT, withholding tax or other governmental charges or transaction charges. You are responsible for the payment of all such applicable taxes or charges. <br><br>
                  The Fees you pay are non-refundable, because the service is information & insights and thus - not returnable. You are responsible for paying all applicable Fees whether or not you actually used or otherwise benefited from the Service. <br><br>
                  Your Billing Information may be processed and handled through relevant third-party payment processors and therefore subject to the terms and conditions of these third parties pursuant to your contractual relations with them.`
			},
			{
				title: "Third party content",
				id: "third-party-content",
				content: `The information pertaining to most of the scientific researchers presented through the service is provided by third parties. We disclaim any responsibility for such content’s accuracy or reliability. We do not guarantee and make no representation or judgment about the accuracy, adequacy, completeness, credibility, authenticity, validity, integrity or legality of third party content available in the service. <br><br>
                  We may, but are under no duty to, review content we made available through the Service. We may, in our sole discretion, temporarily or permanently remove or block access to content, if we find such content in violation of these Terms.`
			},
			{
				title: "Restrictions",
				id: "restrictions",
				content: `While using the Service, you agree to refrain from – <br><br>
                  <ul>
                    <li> Breaching the Terms, or any other applicable rules and instructions that we may convey with respect to the use of the Service; </li>
                    <li> Engaging in any activity that constitutes or encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable law, including laws governing privacy, defamation, spam, copyright, commerce and consumer rights. </li>
                    <li> Interfering with, burdening or disrupting the functionality of the Service; </li>
                    <li> Breaching the security of the Service or identifying any security vulnerabilities in it; </li>
                    <li> Circumventing or manipulating the operation or functionality of the Service, or attempting to enable features or functionalities that are otherwise disabled, inaccessible or undocumented in the Service; </li>
                    <li> Using or launching any automated system, including without limitation robots, crawlers and similar applications to collect or compile content from the Service; </li>
                    <li> Impersonating any person or entity, or making any false statement pertaining to your identity, employment, agency or affiliation with any person or entity; </li>
                    <li> Collecting, harvesting, obtaining or processing personal information of or about other users of the Service. </li>
                  </ul>
                  You may not access or use the Service in order to develop or create a similar or competitive product or service. <br><br>
                  If we, in our discretion, determine that the you violates these Terms, we may with or without notice to you, discontinue, terminate or suspend the operation of the service as it pertains to you, or any part or aspects thereof, temporarily or permanently. If we terminate the operation of the Service, you will be entitled to a pro-rated equitable refund of the Fees you have paid (if any).`
			},
			{
				title: "Your privacy",
				id: "your-privacy",
				content: "We respect your privacy. Our <a target='_blank' href='privacy'>Privacy Policy</a> which is incorporated to these Terms by reference explains the privacy practices on the Service."
			},
			{
				title: "Intellectual property",
				id: "intellectual-property",
				content: `We and our licensors own all rights, title and interest in and to the Service, including patents, copyrights, trademarks, trade names, service marks, trade secrets and other intellectual property rights, and any goodwill associated therewith. <br><br>
                  Unless expressly permitted under these Terms, you may not copy, distribute, display or perform publicly, sublicense, decompile, disassemble, reduce to human readable form, execute publicly, make available to the public, adapt, make commercial use of, process, compile, translate, sell, lend, rent, reverse engineer, combine with other software, modify or create derivative works of the Service, any part thereof or any of the Content we will make available through our Service, either by yourself or by a third party on your behalf, in any way or by any means. <br><br>
                  You may not adapt or otherwise use, including in any Internet domain name, any name, mark or logo that is similar to our marks and logos. You must refrain from any action or omission which may dilute or tarnish our goodwill.`
			},
			{
				title: "Changes in the service; discontinuation",
				id: "changes-in-the-service",
				content: `We may, but are not obligated to, maintain the Service with periodic updates or upgrades. We will determine the frequency and scope of such updates and you will have no plea, claim or demand against us or our directors, officers, employees, agents, advisors, consultants, subcontractors and assignees (collectively, our “<b>Staff</b>”), for any of these updates or the lack thereof. <br><br>
                  We may, at any time, without prior notice, change the features of the Service or suspend the operation of the Service, temporarily or permanently, without any liability to you.`
			},
			{
				title: "Support, availability and quality",
				id: "support-availability-and-quality",
				content: `The availability, quality and functionality of the Service depends on various factors, including software, hardware and communication networks, which are provided by third parties, at their responsibility. These factors are not fault-free. We may, from time to time, need to interrupt the Service for maintenance and other operational reasons. <br><br>
                  <i>WE DO NOT WARRANT THAT THE SERVICE WILL OPERATE WITHOUT DISRUPTION, ERRORS OR INTERRUPTIONS, OR THAT IT WILL BE ACCESSIBLE, OR AVAILABLE AT ALL TIMES OR IMMUNE FROM ERRORS, GLITCHES OR UNAUTHORIZED ACCESS.</i> You will not receive any compensation or refund for such interruptions. <br><br>
                  You acknowledge that the Service DOES NOT provide any data back-up services, including with respect to any Content or any other data that you or third parties upload, post or use. <br><br>
                  During Your Subscription period, we will, either directly or with the assistance of third parties, provide you technical support for technical questions, problems and inquiries regarding the Service, during our business days and hours, and pursuant to the support scheme, hours and channels separately conveyed to you. <br><br>
                  We will attempt to respond to your technical questions, problems and inquiries within a reasonable time. However, we may decline to provide such support for matters that we deem, in our sole discretion, to require unreasonable time, effort, costs or expenses. We make no warranties to any specific response-time or to the successful or satisfactory resolution of the question, problem or inquiry.`
			},
			{
				title: "Termination and suspension",
				id: "termination-and-suspension",
				subTitles: [
					{
						title: "Termination by you",
						id: "termination-by-you",
						content: "You may cancel your Subscription to the Service and terminate these Terms at any time by providing us written notice of cancellation and termination."
					},
					{
						title: "Termination by us",
						id: "termination-by-us",
						content: `Failure to settle any overdue Fees within thirty (30) calendar days of its original due date will constitute a material breach of these Terms, and may result in suspension, disablement or even termination of your subscription to the Service, in addition to any other remedies available to us under the applicable law. <br><br>
                      In addition to any remedies that may be available to us under any applicable law or these Terms, if we believe that you misused the Service or breached these Terms, we may, in our sole discretion, limit, suspend or terminate your use of the Service, and take technical and legal measures to keep you from using the Service.`
					},
					{
						title: "Effects of termination",
						id: "effects-of-termination",
						content: `Upon termination, you must discontinue any and all use of the Service. Following termination, you will not be able to access the Service. Termination of these Terms will not preclude our continued use of the Content and the personal data you provided to us prior to termination for promotional purposes, in accordance with the rights you granted us in these Terms. <br><br>
                      See our <a target='_blank' href='privacy'>Privacy Policy</a> for details regarding retention and deletion of your personal information. <br><br>
                      The following sections shall survive any termination, or expiration of the Terms: Fees and Billing, Effects of termination, Content and dealings, Your privacy, Intellectual property, Limitation of Liability, Indemnification, Governing Law & Jurisdiction, General.`
					}
				]
			},
			{
				title: "Changes to the terms",
				id: "changes-to-the-terms",
				content: "We may amend the Terms from time to time. In such case, we will send an email notification alerting you of the change to the email address you provided us during registration with the Service. Your continued use of the Service after the effective date of the amended Terms attest to your consent to the amended Terms. If you do not agree to the amended Terms, you have only the right to terminate your subscription. The latest version of the Terms will be accessible through the Service. If you do not accept the amended Terms, these Terms will be terminated (see “Effects of termination” section)."
			},
			{
				title: "Disclaimer of warranty",
				id: "disclaimer-of-warranty",
				content: "<i>THE SERVICE IS PROVIDED FOR USE “AS IS” AND “WITH ALL FAULTS”. WE AND OUR STAFF DISCLAIM ALL WARRANTIES AND REPRESENTATIONS, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE, CONTENT, YOUR SUBSCRIPTION, THE FEES AND BILLING. THE BILLING INFORMATION, ANY INTERACTION RELATED TO THE SERVICE, ANY THIRD PARTY SOFTWARE, HARDWARE OR PLATFORM, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, RELIABILITY, NON-INFRINGEMENT, TITLE, COMPATIBILITY, PERFORMANCE, AVAILABILITY, SAFETY, SECURITY OR ACCURACY.</i>"
			},
			{
				title: "Limitation of liability",
				id: "limitation-of-liability",
				content: `<i>ANY AND ALL COMMUNICATIONS AND INTERACTIONS BETWEEN YOU AND THIRD PARTIES (SUCH AS USERS), AND ALL CONSEQUENCES RESULTING FROM THE ABOVE, ARE STRICTLY BETWEEN YOU AND SUCH THIRD PARTIES, AND YOU ASSUME FULL AND EXCLUSIVE RESPONSIBILITY FOR THEM. WE ARE NOT A PARTY TO THOSE COMMUNICATIONS, INTERACTIONS, DEALINGS, ENGAGEMENTS AND TRANSACTIONS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOUR USE OF THE SERVICE IS AT YOUR OWN SOLE RESPONSIBILITY. <br><br>
                  WE, INCLUDING OUR STAFF, SHALL NOT BE LIABLE TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW, FOR ANY INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, STATUTORY, INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY SIMILAR DAMAGE OR LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES AND PAYMENTS, EITHER IN TORT (INCLUDING NEGLIGENCE), CONTRACT, OR IN ANY OTHER FORM OR THEORY OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE SERVICE, THE CONTENT, THE FEES AND BILLING, THE USE OF, OR THE INABILITY OF YOU OR THIRD PARTIES TO USE THE SERVICE, AND YOUR SUBSCRIPTION, OR ANY COMMUNICATION OR INTERACTIONS WITH THE SERVICE, OR ANY COMMUNICATIONS, INTERACTIONS, DEALINGS, ENGAGEMENTS AND TRANSACTIONS WITH OTHERS ON OR THROUGH THE SERVICE, OR YOUR RELIANCE UPON THE SERVICE OR ANY CONTENT UPLOADED OR AVAILABLE TO THE SERVICE, OR ANY FAILURE, ERROR, OR BREAKDOWN IN THE FUNCTION OF THE SERVICE, OR ANY RELIANCE MADE BY YOU ON THIRD PARTY SOFTWARE, HARDWARE OR PLATFORM, OR ANY FAULT, OR ERROR MADE BY OUR STAFF, OR ANY DENIAL OR CANCELATION OF YOUR SUBSCRIPTION, OR RETENTION, DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF CONTENT ON THE SERVICE. <br><br>
                  TO THE GREATEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WILL WE BE LIABLE ONLY FOR DIRECT DAMAGES YOU ACTUALLY SUFFERED THROUGH OUR BREACH OF THESE TERMS NOT EXCEDDING THE FEES YOU ACTUALLY PAID (IF ANY) TO USE THE SERVICE, IN THE SIX (6) MONTHS PRECEDING THE EVENT PURPORTEDLY GIVING RISE TO THE CLAIMED DAMAGE.</i>`
			},
			{
				title: "Indemnification",
				id: "indemnification",
				content: "To the maximum extent permitted by law, you agree to indemnify, defend and hold harmless, us and our staff, at your own expense and immediately after receiving a written notice thereof, from and against any damages, loss, costs and expenses, including attorney’s fees and legal expenses, resulting from any plea, claim, allegation or demand, arising from, or in connection with your use of, or inability to use, the Service, your Website, any Content, your breach of the Terms, Your breach of any other terms, rules or regulations applicable to the Service."
			},
			{
				title: "Links & commercial information in the service",
				id: "links-and-commercial-information-in-the-service",
				content: "The Service may contain links to Content published on other websites or external sources, provided by third parties. By linking to a certain website, we do not endorse, or sponsor its content, or confirm its accuracy, credibility, authenticity, reliability, validity, integrity, or legality. We assume no responsibility or liability for such third-party websites or content, or their availability."
			},
			{
				title: "Publicity",
				id: "publicity",
				content: "Treebute may publicly identify you or your Organization as a customer and include your or the Organization’s name, trademark, and logo in its customers’ list in its website and other promotional and marketing materials."
			},
			{
				title: "Governing law & jurisdiction",
				id: "governing-law-and-jurisdiction",
				content: `Regardless of your place of residence or organization, or where you access or use the Service from, these Terms and your use of the Service will be exclusively governed by and construed in accordance with the laws of the State of Israel, excluding any otherwise applicable rules of conflict of laws, which would result in the application of the laws of a jurisdiction other than the State of Israel. <br><br>
                  The competent courts located in the Tel-Aviv-Jaffa district will have exclusive and sole jurisdiction over any dispute, claim or controversy arising from, or in connection with, the Service and its use, and with respect to any matter relating to the validity, applicability, performance or interpretation of these Terms. You and us, each, hereby expressly consent to personal jurisdiction in Israel and expressly waive any right to object to such personal jurisdiction or the non-convenience of such forum. <br><br>
                  Notwithstanding the foregoing, we may also lodge a claim against you: <b>(a)</b> pursuant to the indemnity clause above, in any court adjudicating a third party claim against us; and <b>(b)</b> for interim, emergency or injunctive relief in any other court having general jurisdiction over you.`
			},
			{
				title: "General",
				id: "general",
				content: `These Terms constitute the entire agreement between you and us concerning the subject matter herein and supersede all prior and contemporaneous negotiations and oral representations, agreements and statements. <br><br>
                  No waiver, concession, extension, representation, alteration, addition or derogation from the Terms by us, or pursuant to the Terms, will be effective unless consented to explicitly and executed in writing by our authorized representative. <br><br>
                  Failure on our part to demand performance of any provision in the Terms shall not constitute a waiver of any of our rights under the Terms. <br><br>
                  You may not assign or transfer your rights and obligations under the Terms without our prior written consent, which consent shall not be unreasonably withheld. Any attempted or actual assignment by you, without our prior consent, shall be null and void. Notwithstanding the provisions of the Assignment of Obligations Law-1969, we may assign these Terms in their entirety, including all right, duties, liabilities and obligations therein, upon notice to you and without obtaining your further specific consent, to a third-party, upon a merger, acquisition, change of control or the sale of all or substantially all of its equity or assets relating to the Agreement. By virtue of such assignment, the assignee assumes our stead, including all right, duties, liabilities and obligations. <br><br>
                  The section headings in the Terms are included for convenience only and shall take no part in the interpretation or construing of the Terms. <br><br>
                  If any provision of the Terms is held to be illegal, invalid, or unenforceable by a competent court, then the provision shall be performed and enforced to the maximum extent permitted by law to reflect as closely as possible, the original intention of that provision, and the remaining provisions of the Terms shall continue to remain in full force and effect.`
			},
			{
				title: "Contact us",
				id: "contact-us",
				content: "At any time, you may contact us with any question that you may have with respect to the Service, at: <a target='_blank' href='mailto:info@treebute.io'>info@treebute.io</a>."
			}
		]

		const switchSection = (i) => {
			let elements = document.querySelectorAll('.linkCont');
			let subTitles = document.querySelectorAll('.indent');
			let titlesContainer = document.getElementById('titles');

			for (let e of elements) {
				e.classList.remove('selectedLink');
			}

			i.classList.add('selectedLink');

			if (i.innerText === 'Termination and suspension' || i.innerText === 'Termination by you' || i.innerText === 'Termination by us' || i.innerText === 'Effects of termination') {
				let termination = document.getElementById('termination').classList.add('open');
				subTitles.forEach(e => e.classList.add('indentVisible'));
				titlesContainer.style.top = 'calc(100% - 1601px)';
			} else {
				let termination = document.getElementById('termination').classList.remove('open');
				subTitles.forEach(e => e.classList.remove('indentVisible'));
				titlesContainer.style.top = 'calc(100% - 1430px)';
			}
		}

		const render = () => {
			let parent = document.getElementById('intro');

			for (let section of sections) {
				let currentSection = document.createElement('div');
				currentSection.classList.add('topic');

				let title = document.createElement('h3');
				title.innerText = section.title;
				title.id = section.id;
				currentSection.appendChild(title);

				if (section.id === "in-a-nutshell") {
					currentSection.id = "introSection";
				}

				if (section.content) {
					let content = document.createElement('p')
					content.innerHTML = section.content;
					currentSection.appendChild(content);
				}

				if (section.subTitles) {
					for (let subTitle of section.subTitles) {
						let title = document.createElement('h4');
						title.innerHTML = subTitle.title;
						title.id = subTitle.id;
						currentSection.appendChild(title);

						let content = document.createElement('p');
						content.innerHTML = subTitle.content;
						currentSection.appendChild(content);
					}
				}

				parent.appendChild(currentSection);
			}
		}

		render()
    </script>
    <div id="content">
        <div id="titles">
            <div id="navLogo" style="background-image: url('images/terms.png');"></div>

            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#in-a-nutshell"> <div class="link" style="border-top: solid 2px rgba(255, 255, 255, 0.2);"> In a nutshell (TL;DR) </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#agreement"> <div class="link"> Agreement </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#commercial-messages"> <div class="link"> Commercial messages </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#description-of-the-service"> <div class="link"> Description of the service </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#subscription-to-the-service"> <div class="link"> Subscription to the service </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#who-may-use-the-service"> <div class="link"> Who may use the service? </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#fees-and-billing"> <div class="link"> Fees and billing </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#third-party-content"> <div class="link"> Third party content </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#restrictions"> <div class="link"> Restrictions </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#your-privacy"> <div class="link"> Your privacy </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#intellectual-property"> <div class="link"> Intellectual property </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#changes-in-the-service"> <div class="link"> Changes in the service; discontinuation </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#support-availability-and-quality"> <div class="link"> Support, availability and quality </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#termination-and-suspension" id="termination"> <div class="link"> Termination and suspension <div class="dropdown" style="background-image: url('images/dropdown.png');"></div> </div> </a>
            <a onclick="switchSection(this)" class="linkCont indent" href="app/terms/#termination-by-you"> <div class="link"> Termination by you </div> </a>
            <a onclick="switchSection(this)" class="linkCont indent" href="app/terms/#termination-by-us"> <div class="link"> Termination by us </div> </a>
            <a onclick="switchSection(this)" class="linkCont indent" href="app/terms/#effects-of-termination"> <div class="link" style="border-bottom: none;"> Effects of termination </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#changes-to-the-terms"> <div class="link" style="border-top: solid 2px rgba(255, 255, 255, 0.2);"> Changes to the terms </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#disclaimer-of-warranty"> <div class="link"> Disclaimer of warranty </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#limitation-of-liability"> <div class="link"> Limitation of liability </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#indemnification"> <div class="link"> Indemnification </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#links-and-commercial-information-in-the-service"> <div class="link"> Links & commercial information in the service </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#publicity"> <div class="link"> Publicity </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#governing-law-and-jurisdiction"> <div class="link"> Governing law & jurisdiction </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#general"> <div class="link"> General </div> </a>
            <a onclick="switchSection(this)" class="linkCont" href="app/terms/#contact-us"> <div class="link"> Contact us </div> </a>
        </div>

        <div style="height: 100%; overflow: auto; margin-left: 280px;" id="scrolled">
            <div id="intro">
                <h1> Terms of Service </h1>

                <p>
                    Welcome to <b>Treebute.io</b>, an AI cloud-based global platform for scientific knowledge navigation, discovery and trade (the "<b>Service</b>"). The Service is owned and operated through our website, accessible at <a href="https://www.treebute.io/" target="_blank">www.treebute.io</a> (the “Site”) by <b>Treebute.io Ltd</b>. ("<b>Treebute</b>", “<b>we</b>”, “<b>us</b>”, “<b>our</b>”). <br><br>
                </p>
            </div>

            <div id="footer">
                <div> <span style="text-decoration: underline;">Last updated</span>: February 5<sup>th</sup>, 2020 </div>
            </div>
        </div>
    </div>
</div>



</body>



</html>
