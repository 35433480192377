import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {InnovationProject} from "../../../../../api-responses/InnovationProjects.interface";
import {TreebuteProjectSummaryReducer} from "../../../../../utils/treebuteProjectSummaryReducer";
import {EvidenceCount} from "../../../EvidenceAmount.interface";
import {SummaryCount} from "../../../SummaryAmount.interface";
import {BusinessColors, EvidenceColors, ExpertsColors} from "../../../../../utils/colors.enum";

@Component({
    selector: 'app-summary-chart',
    templateUrl: './summary-chart.component.html',
    styleUrls: ['./summary-chart.component.scss'],
})
export class SummaryChartComponent implements OnInit {

    @Input() project: InnovationProject;
    @Input() isEvidence;
    @Input() isIndustry;
    @Input() title;

    data = {};
    private summaryCount: SummaryCount;
    private evidenceCount: EvidenceCount;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isIndustry) {
            this.isIndustry = changes.isIndustry.currentValue;
        }

        if (!changes.project.currentValue) return;
        // let searchResults = this.project.treebuteSearchResults
        //     .filter(x => this.getByType(x));

        this.summaryCount = TreebuteProjectSummaryReducer.getEntitiesSummary(changes.project.currentValue);

        function extract(x: number, data: any[], labels: any[], t: string) {
            if (Number(x > 0)) {
                data.push(x);
                labels.push(x + t);
            }
        }

        const data = [];
        const labels = [];
        let colors = [];
        if (this.isEvidence) {
            this.evidenceCount = TreebuteProjectSummaryReducer.getEvidence(changes.project.currentValue, false, this.isIndustry);

            extract(this.project.publicationCount, data, labels, ' Publications');
            extract(this.project.grantCount, data, labels, ' Grants');
            extract(this.project.patentCount, data, labels, ' Patents');
            // extract(this.project.websiteCount, data, labels, ' Websites');
            extract(this.project.ctCount, data, labels, ' Clinical Trials');

            colors = [
                EvidenceColors.publicationCount,
                EvidenceColors.grantCount,
                EvidenceColors.patentCount,
                // EvidenceColors.websiteCount,
                EvidenceColors.clinicalTrialCount
            ];

        } else {
            if (this.isIndustry) {
                extract(this.project.corporateCount, data, labels, ' Corporates');
                extract(this.project.startupCount, data, labels, ' Startups');

                colors = [
                    BusinessColors.CORPORATES,
                    BusinessColors.STARTUPS
                ];

            } else {
                extract(this.project.academicExpertCount, data, labels, ' Academic Experts');
                extract(this.project.industryExpertCount, data, labels, ' Industry Experts');
                extract(this.project.ctExpertCount, data, labels, ' Clinical Trial Experts');
                extract(this.project.tileExpertCount, data, labels, ' Tile Search Experts');

                colors = [
                    ExpertsColors.ACADEMIC_EXPERTS,
                    ExpertsColors.INDUSTRY_EXPERTS,
                    ExpertsColors.CT_EXPERTS,
                    ExpertsColors.TILE_SEARCH_EXPERTS,
                ];

            }

        }

        this.data = {
            datasets: [{
                label: "",
                data: [
                    ...data
                ],
                backgroundColor: [
                    ...colors
                ],
                borderColor: "transparent"
            }],
            labels: [
                ...labels
            ]
        }
    }

    // private getByType(x: SearchResult) : boolean{
    //     return this.isIndustry ?
    //         ['STARTUPS', 'CORPORATES'].some(el => x.searchType.includes(el)) :
    //         ['ACADEMIC_EXPERTS', 'INDUSTRY_EXPERTS', 'CT_EXPERTS', 'TILE_EXPERTS'].some(el => x.searchType.includes(el));
    // }
}
