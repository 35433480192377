import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../../network.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../data.service';

@Component({
    selector: 'app-institution-manager',
    templateUrl: './ct-phase-tool.component.html',
    styleUrls: ['./ct-phase-tool.component.scss'],
})
export class CtPhaseToolComponent implements OnInit {
    query: string;
    input: string;
    output: string;

    constructor(
        private networkService: NetworkService,
        private http: HttpClient,
        public dataService: DataService,
    ) {}

    ngOnInit() {}

    async getCtPhases() {
        try {
            const result: any = await this.networkService.getCtPhases({
                input: this.input,
            });
            this.output = result.output;
        } catch (e) {
            alert('error! ' + e.error);
        }
    }
}
