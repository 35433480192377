import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NetworkService } from 'src/app/network.service';
import moment from 'moment';
import { UtilsComponent } from 'src/app/utils/utils.component';
import { AgmCoreModule } from '@agm/core';

@Component({
    selector: 'vcs-admin-management',
    templateUrl: './google-client-tester.component.html',
    styleUrls: ['./google-client-tester.component.scss'],
})
export class GoogleClientTesterComponent implements OnInit {
    @ViewChild('place') place: ElementRef;
    @ViewChild('country') country: ElementRef;
    @ViewChild('rawResult') rawResult: ElementRef;
    lng: number;
    lat: number;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {}

    async tryGoogleClient() {
        try {
            const result = await this.networkService.tryGoogleClient(
                this.place.nativeElement.value,
                this.country.nativeElement.value,
            );

            this.rawResult.nativeElement.value = result['rawResult'];
            if (result['lat'] && result['lng']) {
                this.lat = result['lat'];
                this.lng = result['lng'];
            }
        } catch (e) {}
    }
}
