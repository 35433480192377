import {Component, OnInit} from "@angular/core";
import {Canvas} from "../canvas.interface";
import {NetworkService} from "../../../network.service";
import {DataService} from "../../../data.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
    selector: 'canvas-list-page',
    templateUrl: './canvas-list-page.component.html',
    styleUrls: ['./canvas-list-page.component.scss'],
})
export class CanvasListPageComponent implements OnInit{
    loading: boolean = true;
    canvasList: Canvas[];


    constructor(private networkService: NetworkService,
                private router: Router,
                public dataService: DataService,
                public matDialog: MatDialog,) {
    }

    ngOnInit(): void {
        this.networkService.canvas.getAll().then((canvasList) => {
            this.canvasList = canvasList;

        })
            .finally(() => {
                this.loading = false;
            })
    }

    createNewCanvas() {
        let canvas = {
            title: 'New Canvas',
            description: 'Canvas Description'
        } as Canvas;
        this.networkService.canvas.create(canvas).then((res) => {
            this.router.navigate(['/app/canvas/'+res.id], {});
        })
    }
}
