<div>
    <table mat-table matSort [dataSource]="this.evidenceLines" class="common-table">
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Title
            </th>
            <td mat-cell *matCellDef="let evidence">
                <a href="{{ evidence.valueA ? evidence.valueA : 'https://www.google.com/search?q=' +  evidence.valueB + ' ' + (evidence.valueD.toLowerCase() === 'unknown' ? '' : evidence.valueD)}}"
                   target="_blank">
                    {{ evidence.valueB }}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="publicationDate"  style="text-transform: capitalize">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ evidenceHeader }}
            </th>
            <td mat-cell *matCellDef="let evidence"  style="text-transform: capitalize">
                {{ getFormattedValue(evidence.valueD) }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" #paginator class="mt-3"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
</div>


