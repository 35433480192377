import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {DeleteSearchModalComponent} from "../../dialogs/delete-search-modal/delete-search-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {SearchPageUtils} from "../../common/searchPageUtils";
import {DataService} from "../../../../data.service";

@Component({
    selector: 'app-search-file-tools',
    templateUrl: './search-file-tools.component.html',
    styleUrls: ['./search-file-tools.component.scss']
})
export class SearchFileToolsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() searchResults!: SearchQueryResults;
    @Output() searchResultsChange = new EventEmitter<SearchQueryResults>();

    @Output() exportData = new EventEmitter();
    @Output() save = new EventEmitter<SearchQueryResults>();
    @Output() deleteSearch = new EventEmitter()


    constructor(public matDialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                private searchPageUtils: SearchPageUtils,
                public dataService: DataService) {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnDestroy(): void {
    }


    deleteSearchConfirm(id: number) {
        const dialogRef = this.matDialog.open(DeleteSearchModalComponent);

        dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result) {
                    this.deleteSearch.emit(id);
                }
            });
    }


    isFreeSearch() {
        return this.searchPageUtils.isFreeSearch();
    }


    saveSearch($event: MouseEvent) {
        this.save.emit(this.searchResults);
    }
}
