import {ChangeDetectorRef, Component, Input, ViewChild} from "@angular/core";
import {MatPaginator} from "@angular/material/paginator";
import {Institution, Researcher, Resource} from "../../../../../api-responses/SearchQueryResults.interface";
import {MatTableDataSource} from "@angular/material/table";
import moment from "moment";

@Component({
    selector: 'evidence-table',
    templateUrl: './evidence-table.component.html',
    styleUrls: ['./evidence-table.component.scss'],
})
export class EvidenceTableComponent {
    displayedColumns: string[] = ['title', 'publicationDate'];
    @ViewChild(MatPaginator, {static: false}) paginator: any
    @Input() evidence: Resource[] = [];
    evidenceLines: MatTableDataSource<Resource>;
    @Input() evidenceHeader: string = 'Date';
    @Input() evidenceAsText: boolean = false;
    @Input() isPatent: boolean = false;

    constructor(public cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.evidenceLines = new MatTableDataSource<Resource>(this.evidence);
        this.evidenceLines.paginator = this.paginator;
        this.cdr.detectChanges();
    }

    getFormattedValue(valueD: any) {
        if(this.isPatent){
            return;
        }
        if (this.evidenceAsText) {
            return valueD;
        }
        let m = moment(valueD, 'YYYY-MM-DD');
        return m.format("MMM YYYY");
    }
}
