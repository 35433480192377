<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title">{{ data.dialogTitle }}</div>
        <div class="header-subtitle">
            <div class="line">
                {{ data.dialogDescription }}
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="button-cancel" (click)="closeModal()">
                Cancel
            </div>
            <div class="button-save" (click)="overwriteSearch()" *ngIf="showingSavedResults()">
                Overwrite
            </div>
            <div class="button-save" (click)="newSearch()">
                New Search
            </div>
        </div>
    </div>
</div>
