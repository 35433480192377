<div>
    <h1>Import Machines</h1>
    <div class="row">
        <span id="file">File:</span>
        <input
            type="file"
            style="text-align: center"
            (change)="updateFile($event)"
        />
    </div>
    <div id="message">{{ message }}</div>
    <button
        id="addBtn"
        (click)="upload()"
        [disabled]="btnDisabled()"
        [class.btnEnabled]="!btnDisabled()"
    >
        Upload
    </button>
</div>
