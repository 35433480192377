import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TreebuteProjectSummaryReducer} from "../../../../utils/treebuteProjectSummaryReducer";
import {SearchQueryResults} from "../../../../api-responses/SearchQueryResults.interface";
import {UtilsComponent} from "../../../../utils/utils.component";

@Component({
    selector: 'app-search-filter-researcher',
    templateUrl: './search-filter-researcher.component.html',
    styleUrls: ['./search-filter-researcher.component.scss']
})
export class SearchFilterResearcherComponent implements OnInit, OnChanges {

    @Input() searchResults!: SearchQueryResults;
    @Output() searchResultsChange = new EventEmitter<SearchQueryResults>()

    expertOption: string = 'ALL';
    expertOptions: string[] = ['ALL', 'Lead Investigators', 'Rising talents', 'Potential collaborators', 'Influencers'];

    constructor() {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.expertOption = this.searchResults.filterInvestigator;
    }

    isAcademicExpertsSearch() {
        return TreebuteProjectSummaryReducer.isAcademicExperts(this.searchResults);
    }

    selectExpertOption(value: any) {
        this.searchResults.filterInvestigator = value;
        this.searchResultsChange.emit(this.searchResults);
    }
}
