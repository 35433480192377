<div *ngIf="showComponent()">
    <div class="search-input-suggestion-text">
        <div *ngIf="this.showWordSuggest">
            add '{{wordSuggestion}}' as&nbsp;<a (click)="addWord('')"> AND</a> <span *ngIf="isNotNLP()"> / <a (click)="addWord('OR')">OR</a> / <a
                (click)="addWord('NOT')">NOT</a></span>
        </div>
    </div>
    <div class="search-input-footer" *ngIf="!this.showWordMap" >
        <div class="search-tune" (click)="this.showWordMap = !this.showWordMap">
            <div class="tune-icon"></div>
            <div class="tune-text">Tune your search</div>
        </div>
    </div>
    <div class="search-input-footer" *ngIf="this.showWordMap" >
        <div class="search-tune" (click)="this.showWordMap = !this.showWordMap">
            <div class="tune-icon"></div>
            <div class="tune-text">Hide Word Cloud</div>
        </div>
    </div>

    <div class="search-input-footer" *ngIf="this.showWordMap">
        <app-word-cloud
                (wordClick)="suggestWord($event)"
                [data]="this.wordCloud">
        </app-word-cloud>
    </div>
</div>
