import {Component, OnInit, ViewChild} from '@angular/core';
import {NetworkService} from "../../../../network.service";
import {ProjectStateService} from "../../project-state-service";
import {DataService} from "../../../../data.service";
import {Subscription} from "rxjs";
import {InnovationProject} from "../../../../api-responses/InnovationProjects.interface";
import {ClinicalTrial} from "../../../../api-responses/ClinicalTrial.interface";
import {UtilitiesService} from "../../../../utilities.service";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import moment from 'moment';
import {ChartDist, ChartFilter, SelectedField} from "../../../../api-responses/graphs.interface";

@Component({
    selector: 'app-ct-tool',
    templateUrl: './ct-tool.component.html',
    styleUrls: ['./ct-tool.component.scss'],
    providers: [ProjectStateService]
})
export class CtToolComponent implements OnInit {

    loading: boolean = true;

    project: InnovationProject;

    selectedCountries: SelectedField[];
    selectedPhases: SelectedField[];
    cts: ClinicalTrial[];
    filteredCts: ClinicalTrial[];
    dataSource; // = new MatTableDataSource(this.filteredCts);

    subscriptions: Subscription[] = [];

    displayedColumns: string[] = [
        "ctId",
        "title",
        "studyType",
        "status",
        "phases",
        "studyStartDate",
        "lastUpdatePostedDateNct",
        "facilities",
        "countries"
    ];

    filters: ChartFilter[] = [];

    constructor(private networkService: NetworkService,
                private projectStateService: ProjectStateService,
                public dataService: DataService,
                private _liveAnnouncer: LiveAnnouncer) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.projectStateService.projectState.subscribe((project: InnovationProject) => {
                this.project = project;
                this.loading = !(this.project && this.cts);

                this.networkService.innovationProjects.getCts(this.project.id).then((cts: ClinicalTrial[]) => {
                    this.cts = cts;
                    for (const ct of cts) {
                        ct.ctId = ct.nctid ? ct.nctid : ct.eudraid;
                        ct.phases = ct.phases && ct.phases.length > 0 ? ct.phases : ['Empty']

                        ct.countries = ct.countries && ct.countries.length > 0 ? ct.countries : ['Unknown'];
                        ct.countries = ct.countries.map(x => x.toLowerCase());
                    }

                    this.selectedCountries = UtilitiesService.getSelectedForChart(this.getCountries());
                    this.selectedPhases = UtilitiesService.getSelectedForChart(this.getPhases());

                    this._updateFilters();
                    this.filteredCts = this.cts;
                    this._setSortTable();
                });

            }));
    }

    /** Announce the change in sort state for assistive technology. */
    announceSortChange(sortState: Sort) {
        // This example uses English messages. If your application supports
        // multiple language, you would internationalize these strings.
        // Furthermore, you can customize the message to add additional
        // details about the values being sorted.
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce('Sorting cleared');
        }
    }

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    _setSortTable() {
        this.filteredCts = UtilitiesService.filter<ClinicalTrial>(this.cts, this.filters);
        this.dataSource = new MatTableDataSource(this.filteredCts);
        setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.loading = false;
        })
    }

    getTitle(title: string) {
        return UtilitiesService.trimString(title, 30);
    }

    getDate(date: Date) {
        return moment(date).format('MMM DD, YYYY');
    }

    getCountries() {
        let list = this.cts;
        let getAttr = (x) => { return x.countries };
        return UtilitiesService.getDistributionForChart(list, getAttr);
    }

    updateByCountry($event: SelectedField[]) {
        this.selectedCountries = $event;
        this._setSortTable();
    }

    getTextForList(field: Array<String>) {
        if (!field) return '';
        return UtilitiesService.trimString(field.join(", "), 30);
    }

    getTextForListAsTooltip(field: Array<String>) {
        if (!field) return '';
        return field.join(", \n");
    }

    updateByPhase($event: SelectedField[]) {
        this.selectedPhases = $event;
        this._setSortTable();
    }

    getPhases() {
        let list = this.cts;
        let getAttr = (x) => { return x.phases };
        return UtilitiesService.getDistributionForChart(list, getAttr);
    }

    private _updateFilters() {
        this.filters = [];

        this.filters.push(UtilitiesService.createFilter(
            "listAttr",
            (item) => { return item.countries; },
            this.selectedCountries
        ));

        this.filters.push(UtilitiesService.createFilter(
            "listAttr",
            (item) => { return item.phases; },
            this.selectedPhases
        ));
    }
}
