<div class="light"
     [class.red]="color === 'RED'"
     [class.green]="color === 'GREEN'"
     [class.blue]="color === 'BLUE'"
     [class.yellow]="color === 'YELLOW'"
     [class.gray]="color === 'GRAY'"

     [class.small]="size === 'SMALL'"
     [class.medium]="size === 'MEDIUM'"
     [class.large]="size === 'LARGE'"
>
</div>
