<div class="container project-page" [class.disable-when-loading]="showEvidenceLoader">
    <div class="justify-content-start pt-4 row" style="position: relative; height: 117px"
         [hidden]="!readOnlyProjectService.isReadOnlyProject">
        <img src="https://beta.treebute.io/assets/images/logo_topic.png" style="position:absolute; left: -7px;">
    </div>
    <div class="total-progress-bar" *ngIf="!project">
        <mat-spinner mode="indeterminate"></mat-spinner>
    </div>
    <div *ngIf="project">
        <div *ngIf="dataService.isAdmin()" class="project-name pt-5">
            <project-tools
                    *ngIf="!readOnlyProjectService.isReadOnlyProject"
                    [project]="project"
                    (refreshNow)="refreshNow()"
            ></project-tools>
        </div>
        <div class="project-name pt-5">
            <div style="flex: 1; display: flex; flex-direction: row; align-items: center">
                <h1 [innerText]="project?.name" class="pretty-header"></h1>
                <div [hidden]="dataService.hideShared() || readOnlyProjectService.isReadOnlyProject" class="edit-icon"
                     (click)="openNewProjectDialog()"></div>
            </div>

        </div>
        <div class="pt-1" *ngIf="project.description">
            <p class="lead" style="white-space: pre-line">
                <markdown
                        [data]="project.description">
                </markdown>
            </p>
        </div>
        <div class="title-shared pb-3" *ngIf="project.shared">
            <div>Shared Project</div>
            <div>Author: Naveh D. Shetrit</div>
        </div>
        <div class="project-tools-container" [hidden]="true || readOnlyProjectService.isReadOnlyProject">
            <div>
                <a [routerLink]="['/app/projects/' + project.id + '/experts-tool']"
                   target="_blank"> Experts Tool
                    <span class="material-icons-outlined lower-icon">
                        open_in_new
                    </span>
                </a>
            </div>
            <div>
                <a [routerLink]="['/app/projects/' + project.id + '/ct-tool']"
                   target="_blank">Clinical Trials Tool
                    <span class="material-icons-outlined lower-icon">
                        open_in_new
                    </span>
                </a>
            </div>
        </div>
        <div *ngIf="false && project && !readOnlyProjectService.isReadOnlyProject"
             [hidden]="hideShared"
             class="plus-search-icon d-none d-sm-none d-md-inline"
             routerLink="search"></div>
        <!--        <mat-tab-group-->
        <!--                [selectedIndex]="selectedTab"-->
        <!--                (selectedIndexChange)="selectedTab = $event"-->
        <!--        >-->
        <!--            <mat-tab label="Main search" *ngIf="false">-->
        <!--                                <app-search-summary-->
        <!--                                        [project]="this.project"-->
        <!--                                        [industryTableChildData]="industryTableChildData"-->
        <!--                                        [isIndustry]="false"-->
        <!--                                        [title]="'Experts and knowledge maps findings'"-->
        <!--                                        [pluralName]="'Experts'"-->
        <!--                                        (userClickDelete)="this.userClickDelete($event)"-->
        <!--                                ></app-search-summary>-->
        <!--            </mat-tab>-->
        <!--                        <mat-tab label="Executive dashboard" [hidden]="true">-->
        <!--                            <iframe src="//www.arcgis.com/apps/opsdashboard/index.html#/125d3f716dbb4c0c9c2079a3c526b883"-->
        <!--                                    [width]="'100%'" [height]="'1200px'"></iframe>-->
        <!--                        </mat-tab>-->
        <!--        </mat-tab-group>-->
        <div class="pb-4">
            <app-search-summary
                    [project]="this.project"
                    [industryTableChildData]="industryTableChildData"
                    [isIndustry]="true"
                    [title]="'Data Layers'"
                    [pluralName]="'Industries'"
                    (userClickDelete)="this.userClickDelete($event)"
                    [readOnlyMode]="true"
                    (clickChip)="userClickQuery($event)"
                    (deleteSearch)="deleteSearch($event)"
            ></app-search-summary>
        </div>
        <div id='map' class="dark" [class.showMap]="mapLoaded"></div>
        <div *ngIf="evidence && evidence.papers.length > 0">
            <h2 class="pt-5 pb-4">Evidence Structure</h2>
            <div class="row">
                <div class="col-md-6 col-sm-12 pt-3">
                    <app-summary-chart *ngIf="project" [project]="project" [isEvidence]="true"
                                       title="Science & Technology"
                                       [isIndustry]="true"></app-summary-chart>
                </div>
                <div class="col-md-6 col-sm-12 pt-3">
                    <app-summary-chart *ngIf="project" [project]="project" [isEvidence]="false"
                                       title="Industrial Landscape"
                                       [isIndustry]="true"></app-summary-chart>
                </div>
                <!--            <div class="chart-container">-->
                <!--                <experts-background-chart-container-->
                <!--                        [showTools]="false"-->
                <!--                        *ngIf="project"-->
                <!--                        [project]="project">-->
                <!--                </experts-background-chart-container>-->
                <!--            </div>-->
            </div>
        </div>

        <div class="loader-panel" *ngIf="showEvidenceLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="evidence && evidence.papers.length > 0" class="">
            <h2 class="pt-5 pb-3  publications-header">Publications</h2>
            <div class=" ">
                <evidence-chunk
                        [showSubTitles]="false"
                        [showTitle]="false"
                        [showList]="true"
                        [showChart]="false"
                        [title]="'Recent Publications'"
                        [dataForChart]="dataForCharts['publications']"
                        [evidenceList]="evidence.papers"
                        [evidenceColor]="colors['publications']">
                </evidence-chunk>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-11 ">
                    <div class="chart-container">
                        <!--                        <h3 class="p-3">Publications over Time</h3>-->
                        <evidence-chunk
                                [showTitle]="false"
                                [showList]="false"
                                [title]="'Derived Publications'"
                                [dataForChart]="dataForCharts['publications']"
                                [evidenceList]="evidence.papers"
                                [evidenceColor]="colors['publications']">
                        </evidence-chunk>
                    </div>
                </div>
                <div class="col-lg-6 col-md-11">
                    <div class="chart-container">
                        <h3 class="p-3">Taxonomy</h3>
                        <taxonomy-chart *ngIf="project"
                                        [project]="project">
                        </taxonomy-chart>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="evidence && evidence.corporates">
            <div *ngIf="evidence.corporates && evidence.corporates.length > 0" class=" pb-5">
                <h2 class="pt-5 pb-3"
                    [style.color]="getColor('corporates')">
                    Corporations
                </h2>
                <evidence-table [evidence]="evidence.corporatesAsEvidenceList" [evidenceHeader]="'Country'" [evidenceAsText]="true">
                </evidence-table>
            </div>
            <!--            <evidence-chunk [title]="'Corporations'" [evidenceList]="evidence.corporatesAsEvidenceList"-->
            <!--                            [institutions]="evidence.corporates"-->
            <!--                            [evidenceColor]="getColor('corporates')" showChart="false"></evidence-chunk>-->
            <evidence-chunk [title]="'Derived Grants'" [dataForChart]="dataForCharts['grants']"
                            *ngIf="project.hideGrants !== true" [evidenceList]="evidence.grants" [evidenceColor]="colors['grants']"></evidence-chunk>
            <evidence-chunk [title]="'Derived Clinical Trials'" [dataForChart]="dataForCharts['cts']"
                            [evidenceList]="evidence.cts" [evidenceColor]="colors['cts']"
                            *ngIf="project.hideClinicalTrials !== true" [project]="project" [isCt]="true"></evidence-chunk>
            <evidence-chunk [title]="'News'" [dataForChart]="dataForCharts['news']"
                            [evidenceList]="evidence.news" [evidenceColor]="colors['websites']" [showSubTitles]="false"></evidence-chunk>
            <evidence-chunk [title]="'Derived Patents'" [dataForChart]="dataForCharts['patents']"
                            [evidenceList]="evidence.patents" [evidenceColor]="colors['patents']"></evidence-chunk>
            <!--            <evidence-chunk [title]="'Websites'" [dataForChart]="getDataForChart(evidence.websites)" [evidenceList]="evidence.websites"></evidence-chunk>-->

            <evidence-chunk
                    #expertsEvidenceChunk
                    [showAction]="!readOnlyProjectService.isReadOnlyProject"
                    [actionButtonText]="'Connect'"
                    (actionRun)="directRequest($event)"
                    [project]="project" [title]="'Experts'"
                    [researchers]="evidence.experts"
                    [evidenceColor]="colors['experts']">
            </evidence-chunk>

            <div *ngIf="australianUniversities.length > 0" class=" pb-5">
                <h2 class="pt-5 pb-3"
                    [style.color]="getColor('corporates')">
                    Universities
                </h2>
                <evidence-table [evidence]="australianUniversities" [evidenceHeader]="' '" [evidenceAsText]="true">
                </evidence-table>
            </div>

            <app-startup-preview [project]="project" [companies]="evidence.startups"
                                 [evidenceColor]="colors['startups']">
            </app-startup-preview>
        </div>

        <div class="line"> </div>
    </div>
</div>
