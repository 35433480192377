import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UtilsComponent} from "../../../../utils/utils.component";
import {NetworkService} from "../../../../network.service";
import {DataService} from "../../../../data.service";
import {Institution, InstitutionResult, SearchDetails} from "../../../../api-responses/SearchQueryResults.interface";
import {Router} from "@angular/router";
import {InnovationProject} from "../../../../api-responses/InnovationProjects.interface";
import {SearchResult} from "../../../../api-responses/SearchResult.interface";
import {ReadOnlyProjectService} from "../../../../read-only-project-service";

@Component({
  selector: 'app-startup-preview',
  templateUrl: './startup-preview.component.html',
  styleUrls: ['./startup-preview.component.scss']
})
export class StartupPreviewComponent implements OnInit, OnChanges {

  @Input() project: InnovationProject;
  @Input() companies: Institution[] = [];
  @Input() evidenceColor: string;

  constructor(
      public router: Router,
      public utilsComponent: UtilsComponent,
      public networkService: NetworkService,
      public dataService: DataService,
      public readOnlyProjectService: ReadOnlyProjectService
  ) { }

  ngOnInit() {
  }

  goToInst(inst: Institution) {
    if (this.readOnlyProjectService.isReadOnlyProject) return;
    this.router.navigate([`/app/companies/` + inst.id]);
  }

  hasCompanies() {
    return this.companies && this.companies.length > 0;
  }

  getSearches(searchDetails: SearchDetails[]) {

  }

  searchesDict = {};
  companySearches = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project.currentValue) {
      this.searchesDict = {};
      for (const treebuteSearchResult of this.project.treebuteSearchResults) {
        this.searchesDict[treebuteSearchResult.id] = treebuteSearchResult;
      }
    }

    if (changes.companies.currentValue) {
      this.companySearches = {};
      for (const company of changes.companies.currentValue) {
        for (const searchDetail of company.searchDetails2) {
          let result = [];
          let search: SearchResult = this.searchesDict[searchDetail.searchId];
          result.push({
            id: search.id,
            title: search.title,
            searchType: search.searchType
          });
          if (this.companySearches[company.id]) {
            this.companySearches[company.id].push(result[0]);
          }
          else {
            this.companySearches[company.id] = result;
          }
        }
      }
    }
  }
}
