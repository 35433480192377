import { Component, Input, OnInit } from '@angular/core';
import { InnovationProject } from 'src/app/api-responses/InnovationProjects.interface';
import { EvidenceCount } from '../EvidenceAmount.interface';
import { SummaryCount } from '../SummaryAmount.interface';
import { EvidenceColors } from 'src/app/utils/colors.enum';
import {DataService} from "../../../data.service";
import {NetworkService} from "../../../network.service";
import {Router} from "@angular/router";


@Component({
    selector: 'app-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {

    @Input() project: InnovationProject;
    evidence: any[];
    summary: any[];

    futureFeature = false; /*TODO- will be deleted on future release*/
    @Input() readonly = false;

    getEvidences()  {
        return [
            {
                icon: 'patents-icon',
                title: 'Patents',
                evidenceAmount: this.project.patentCount,
                color: EvidenceColors.patentCount,
            },
            {
                icon: 'websites-icon',
                title: 'Websites',
                evidenceAmount: this.project.websiteCount,
                color: EvidenceColors.websiteCount,
            },
            {
                icon: 'publications-icon',
                title: 'Publications',
                evidenceAmount: this.project.publicationCount,
                color: EvidenceColors.publicationCount,
            },
            {
                icon: 'clinical-trials-icon',
                title: 'Clinical trials',
                evidenceAmount: this.project.ctCount,
                color: EvidenceColors.clinicalTrialCount,
            },
            {
                icon: 'grants-icon',
                title: 'Grants',
                evidenceAmount: this.project.grantCount,
                color: EvidenceColors.grantCount,
            },
        ];
    }

    getSummary() {
        return [
            {
                summaryAmount: this.project.searchCount,
                title: 'Saved searches',
            },
            {
                summaryAmount: this.project.totalExpertCount,
                title: 'Identified experts',
            },
            {
                summaryAmount: this.project.startupCount + this.project.corporateCount,
                title: 'Businesses',
            },
        ];
    }

    constructor(public dataService: DataService,
                public router: Router,
                public networkService: NetworkService,
    ) {}

    ngOnInit() {
        this.setValues();
    }

    toggleFavorite(event) {
        event.stopPropagation();
    }

    getShortProjectName(name: string) {
        if (name && name.length > 40) {
            return name.substring(0, 40) + "...";
        }
        return name;
    }

    getEvidenceCount(project: InnovationProject) {
        return {
            patentCount: project.patentCount,
            websiteCount: project.websiteCount,
            publicationCount: project.publicationCount,
            clinicalTrialCount: project.ctCount,
            grantCount: project.grantCount
        }
    }

    getSummaryCount(project: InnovationProject) {
        return {
            savedSearchCount: project.searchCount,
            startupsCount: project.startupCount,
            corporatesCount: project.corporateCount,
            academicExpertsCount: project.academicExpertCount,
            industryExpertsCount: project.industryExpertCount,
            ctExpertsCount: project.ctExpertCount,
            patentExpertCount: project.patentExpertCount,
            totalExpertCount: project.totalExpertCount
        }
    }

    private setValues() {
        this.evidence = this.getEvidences();
        this.summary =  this.getSummary();
    }

    navigateToProj($event, id: number) {
        const suffix = this.readonly ? '/read-only-project' : '';
        const link = `/app/projects/${id}${suffix}`;
        switch ($event.button) {
            case 0:
                this.router.navigate([link], {});
                break;
            case 1:
                window.open(link, "_blank");
                break;
        }
    }
}
