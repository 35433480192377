<div class="grants-content">
    Grants awarded :
    <ul class="list-group">
        <li *ngFor="let grant of grants" class="list-group-item">
            <a class="dropdown-item btn-sm"
                >{{ grant.fullGrantName }} ({{ grant.grantIds.length }})</a
            >
        </li>
    </ul>
</div>
