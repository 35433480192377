import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, interval, Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {startWith, take} from "rxjs/operators";
import {NetworkService} from "./network.service";
import {UserInfo} from "./api-responses/UserInfo.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {InnovationProject} from "./api-responses/InnovationProjects.interface";
import {UtilitiesService} from "./utilities.service";
import {VersionData} from "./api-responses/VersionData.interface";
import {SearchQueryResults} from "./api-responses/SearchQueryResults.interface";
import {environment} from "../environments/environment";
import {SharedProject} from "./read-only-project-service";

@Injectable({
    providedIn: 'root',
})
export class DataService implements OnInit, OnDestroy {

    public canExportProject = Subscription;
    subscriptions = [];

    constructor(private networkService: NetworkService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {

        //console.log(" ****** salute");
        if (window.location.href.indexOf('/read-only-') > -1) {
            return;
        }
        this.subscriptions.push(interval(10000).pipe(
            startWith(0)
        ).subscribe(() => {
            if (!this.userData) return;

            this.networkService.innovationProjects.canExport().subscribe((res: number[]) => {
                if (!res) return;

                // first time:
                if (res && res.length > 0 && this._canExportProject.length === 0) {
                    this._canExportProject = res;
                    this.canExportUpdate(res);
                }
                // every other time:
                const intersection = res.filter(element => this._canExportProject.includes(element));
                if (intersection.length !== this._canExportProject.length) {
                    this.canExportUpdate(res);
                }
            });
        }));

        this.updateUserInfo()

        this.saveSearchMessageSource.subscribe((navRequest: NavRequest) => {
            if (navRequest.message === NavRequest.MSG.SHOULD_SAVE) {
                this._shouldSave = true;
                console.log("dataService._shouldSave: " + this._shouldSave);
            }

            if (navRequest.message === NavRequest.MSG.SHOULD_NOT_SAVE) {
                this._shouldSave = false;
            }

            if (navRequest.message === NavRequest.MSG.NAV) {
                if (!this._shouldSave || this.hideShared()) {
                    this.saveSearchUpdate(new NavRequest(navRequest.dest, NavRequest.MSG.NAV_OK, navRequest.mouseEvent));
                }
                else {
                    // request nav
                    this.saveSearchUpdate(new NavRequest(navRequest.dest, NavRequest.MSG.REQUEST_SAVE, navRequest.mouseEvent));
                }
            }

            if (navRequest.message === NavRequest.MSG.NAV_GRANTED) {
                this._shouldSave = false;
                this.saveSearchUpdate(new NavRequest(navRequest.dest, NavRequest.MSG.NAV_OK, navRequest.mouseEvent));
            }

        })

        this.checkVersion();

        //this.monitorProjectRoute();
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    public _canExportProject: number[] = [];

    // channel
    private canExportMessageSource = new BehaviorSubject(this._canExportProject);

    // listener
    canExportObservable = this.canExportMessageSource.asObservable();

    // broadcast
    canExportUpdate(message: number[]) {
        this._canExportProject = message;
        this.canExportMessageSource.next(message);
    }


    userInfo$ = new ReplaySubject<UserInfo>();

    async updateUserInfo() {
        if (this.userData) { // (localStorage.getItem('userinfo')) {
            return new Promise(() => {
                const _userInfo = this.userData; // JSON.parse(localStorage.getItem('userinfo'));
                this.userInfo$.next(_userInfo);
                return _userInfo;
            });
        }

        return this.networkService.XupdateUserInfo().then((userInfo) => {
            this.loadIntercom(userInfo);
            this.userInfo$.next(userInfo);
            this.userData = userInfo;
            //localStorage.setItem('userinfo', JSON.stringify(userInfo));
            if (this._isControlPage()) {
                this.router.navigate(['/app/projects'], {});
            }

        })
            .catch((e) => {
                console.error(e);
                this.userData = undefined; // localStorage.removeItem('userInfo');

                // don't redirect for registration
                if (this._isControlPage()) return;

                return this.router.navigate(['/app/login']);
            });
    }

    async getUserInfo() {
        // let result = await this.http.get(this.userInfoUrl).toPromise();
        let result = await this.networkService.XgetUserInfo();
        if (!result)
            this.router.navigate(['/app/login']).then(r => {
                return 0;
            });

        return result;
    }

    public logout() {
        this.userData = undefined;
        this.networkService.logout();
    }

    static char = '∣';
    userData: UserInfo;
    machineParameters: any;
    insMgntQuery: string;
    urlParams: string;

    public calcHideShared() {
        this._hideShared = this.isAdmin() ? false : this.project && this.project.shared;
    }

    // *****************************************************
    _hideShared: boolean = true;
    project: InnovationProject;
    compareProject: InnovationProject;
    public projectState = new Subject<InnovationProject>();
    public compareProjectState = new Subject<InnovationProject>();
    updateProjectIdState(project: InnovationProject, compareProject: boolean) {
        if(compareProject)
            this.compareProject = project;
        else this.project = project;

        this.calcHideShared();

        this.networkService.getSharedProject(compareProject ? this.compareProject.id : this.project.id).then((sharedProject) => {
            this.updateSharedProject(sharedProject)
        })

        compareProject ? this.compareProjectState.next(project) : this.projectState.next(project);
    }

    // *****************************************************
    _sharedProject: SharedProject;
    public sharedProject = new Subject<SharedProject>();
    updateSharedProject(sharedProject: SharedProject) {
        this._sharedProject = sharedProject;
        this.sharedProject.next(sharedProject);
    }

    // *****************************************************
    public otherPageState = new Subject<boolean>();
    updateOtherPageState(otherPage: boolean) {
        this.calcHideShared();
        this.otherPageState.next(otherPage);
    }

    // *****************************************************
    public searchIdState = new Subject<number>();
    updateSearchIdState(searchId: number) {
        this.searchIdState.next(searchId);
    }

    // *****************************************************
    public searchState = new Subject<SearchQueryResults>();
    updateSearchState(search: SearchQueryResults) {
        this.searchState.next(search);
    }

    // *****************************************************

    // *****************************************************
    public loadingState = new Subject<boolean>();
    updateLoadingState(loading: boolean) {
        this.loadingState.next(loading);
    }
    // *****************************************************

    private _shouldSave: boolean = false;

    hideShared() {
        // if (this.isAdmin()) return false;
        // return this.project && this.project.shared;
        return this._hideShared;
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.userData);
    }

    public isFeatureFlag() {
        return localStorage.getItem("isFeatureFlag") === "true";
    }

    public _saveSearchMessage: NavRequest = new NavRequest('', '');

    // channel
    private saveSearchMessageSource = new BehaviorSubject(this._saveSearchMessage);

    // listener
    saveSearchObservable = this.saveSearchMessageSource.asObservable();

    // broadcast
    saveSearchUpdate(message: NavRequest) {
        this._saveSearchMessage = message;
        this.saveSearchMessageSource.next(message);
    }

    public version: string = '2.0.0.37.beta';
    remoteVersionData: VersionData;
    newVersionExists$ = new ReplaySubject<boolean>();

    checkVersion() {
        function _isNewerVersion (oldVer, newVer) {
            const oldParts = oldVer.split('.')
            const newParts = newVer.split('.')
            for (var i = 0; i < newParts.length; i++) {
                const a = ~~newParts[i] // parse int
                const b = ~~oldParts[i] // parse int
                if (a > b) return true
                if (a < b) return false
            }
            return false
        }

        function handler() {
            this.networkService.getVersionData()
                .then((versionData: VersionData) => {
                    this.remoteVersionData = versionData;
                    let newVersion = _isNewerVersion(this.version.replace('.beta', ''), versionData.webClientVersion.replace('.beta', ''));
                    this.newVersionExists$.next(newVersion);

                    setTimeout(handler.bind(this), 60000);
                })
                .catch((err) => {
                    setTimeout(handler.bind(this), 6000);
                });
        }

        handler.call(this);
    }


    private _isControlPage() {
        return  this.router.url.indexOf('/app/register') > -1 || this.router.url.indexOf('/app/login') > -1 || this.router.url.indexOf('/app/terms') > -1;
    }

    intercomLoaded = false;

    private loadIntercom(userInfo: UserInfo) {
        if (!this.intercomLoaded) {
            window["intercomSettings"] = {
                app_id: userInfo.intercomAppId,
                name: userInfo.firstName + " " + userInfo.lastName,
                user_id: userInfo.id,
                email: userInfo.email,
                created_at: userInfo.created_at,
                horizontal_padding: 20,
                vertical_padding: 20,
                hide_default_launcher: true,
                custom_launcher_selector: '#my_custom_link'
            };

            // @ts-ignore
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ozi0aqhj';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})(); // @ts-ignore
            this.intercomLoaded = true;
        }
    }

    // private monitorProjectRoute() {
    //     this.activatedRoute.paramMap.pipe().subscribe((map: ParamMap) => {
    //         const projectId = map.get('projectId');
    //         this.networkService
    //             .getProjectById(projectId)
    //             .toPromise()
    //             .then((project) => {
    //                 if (this.project && project.id === project.id) return;
    //                 this.project = project;
    //                 this.routeStateService.updatePathParamState({'projectId': projectId});
    //             });
    //
    //
    //     });
    // }
    preventDefault($event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    isDev(): boolean {
        return !environment.production;
        // return !!this.userData.dev;
    }
}

export class NavRequest {
    public dest: string;
    public message: string;
    static MSG = {
        SHOULD_NOT_SAVE: 'shouldNotSave',
        NAV: 'nav',
        SHOULD_SAVE: 'shouldSave',
        REQUEST_SAVE: 'requestSave',
        NAV_OK: 'navOk',
        NAV_GRANTED: 'navRequestGranted'
    };
    public mouseEvent: MouseEvent;

    constructor(dest: string, message: string, mouseEvent: MouseEvent = null) {
        this.dest = dest;
        this.message = message;
        this.mouseEvent = mouseEvent;
    }
}
