import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SelectedField} from "../../../../../api-responses/graphs.interface";
import {NetworkService} from "../../../../../network.service";
import {DataService} from "../../../../../data.service";
import {ChartFilterModalData} from "../../../../../api-responses/Dialogs.interface";

@Component({
    selector: 'chart-filter-modal',
    templateUrl: './chart-filter-modal.component.html',
    styleUrls: ['./chart-filter-modal.component.scss']
})
export class ChartFilterModalComponent implements OnInit {

    selectedItems: SelectedField[] = [];
    textFilter: string;

    constructor(public networkService: NetworkService,
                public dataService: DataService,
                public dialogRef: MatDialogRef<ChartFilterModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ChartFilterModalData) {
    }

    ngOnInit() {
        this.selectedItems = this.data.items;
    }

    closeModal() {
        this.dialogRef.close();
    }

    filter() {
        this.dialogRef.close({
            selectedItems: this.selectedItems
        })
    }

    anythingSelected() {
        return this.selectedItems && this.selectedItems.filter(x => x.selected).length > 0;
    }

    selectAll() {
        this.selectedItems.forEach(x => x.selected = true);
        this.updateFilter()
    }

    selectNone() {
        this.selectedItems.forEach(x => x.selected = false);
        this.updateFilter()
    }

    updateFilter() {
        // this.dataService.updateLoadingState(true);
        // setTimeout(() => {
        //     this.selectedCountriesChange.emit(this.selectedItems.filter(x => x.selected));
        //     this.dataService.updateLoadingState(false);
        // }, 0)
    }

    getSelectedItems() {
        if (this.textFilter) {
            return this.selectedItems.filter(x => x.text.toLowerCase().indexOf(this.textFilter.toLowerCase()) > -1);
        } else {
            return this.selectedItems;
        }
    }

}
