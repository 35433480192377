import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../../../utilities.service';
import { DataService } from '../../../data.service';

@Component({
    selector: 'app-machine-card',
    templateUrl: './machine-card.component.html',
    styleUrls: ['./machine-card.component.scss'],
})
export class MachineCardComponent implements OnInit {
    @Input() machine;
    @Input() enableNextMachine;
    @Input() enablePreviousMachine;
    @Input() nextLoading;
    @Input() previousLoading;

    @Output() closeModal = new EventEmitter();
    @Output() openBooking = new EventEmitter();
    @Output() nextMachine = new EventEmitter();
    @Output() previousMachine = new EventEmitter();

    constructor(public dataService: DataService) {}

    ngOnInit() {}

    editMachine() {
        this.dataService.machineParameters = {
            name: this.machine.name,
            make: this.machine.make,
            model: this.machine.model,
            machineId: this.machine.machineId,
            instanceId: this.machine.instanceId,
        };
    }

    openBookingModal() {
        this.openBooking.emit(this.machine);
    }

    checkWidth(event, title) {
        return UtilitiesService.checkWidth(event, title);
    }

    trimString(str, len) {
        return UtilitiesService.trimString(str, len);
    }

    vw() {
        return UtilitiesService.vw();
    }

    isAdmin() {
        return UtilitiesService.isAdmin(this.dataService.userData);
    }
}
