<div class="container project-page pt-5" [class.disable-when-loading]="loading">
    <div class="loader-panel" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div>
        <button class="btn btn-primary" [cdkCopyToClipboard]="getEmailContent()">Copy Email Content</button>
    </div>
    <div *ngIf="!loading" id="update-content" #updateContent>
        <div *ngFor="let update of updates">
            <div *ngIf="anyEvidence(update.evidence)">
                <h1 style="color: #ff553e">{{ update.title }}</h1>
                <div *ngIf="update.evidence.papers.length > 0">
                    <h2 style="color: #81d4fa">New Outstanding Publications</h2>
                    <ul>
                        <li *ngFor="let paper of update.evidence.papers">
                            <a href="{{paper.valueA}}">{{ paper.valueB }}</a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="update.evidence.experts.length > 0">
                    <h2 style="color: #80cbc4">New Outstanding Experts</h2>
                    <ul>
                        <li *ngFor="let expert of update.evidence.experts">
                            <div>
                                {{expert.firstName + ' ' + expert.lastName}}
                            </div>
                            <div class="font-italic">
                                {{ expert.department }}
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="update.evidence.corporates.length > 0">
                    <h2 style="color:#b55963;">New Outstanding Corporations</h2>
                    <ul>
                        <li *ngFor="let corp of update.evidence.corporates">
                            <div>
                                {{ corp.name }}
                                <span>
                                    {{ utilsComponent.getCountryText(corp) }}
                                </span>
                            </div>

                        </li>
                    </ul>
                </div>
                <div *ngIf="update.evidence.startups.length > 0">
                    <h2 style="color:#44d7b6;">New Outstanding Startups</h2>
                    <ul>
                        <li *ngFor="let startup of update.evidence.startups">
                            <div>
                                {{ startup.name }}
                                <span>
                                    {{ utilsComponent.getCountryText(startup) }}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="update.evidence.patents.length > 0">
                    <h2 style="color:#ffb74d;">New Outstanding Patents</h2>
                    <ul>
                        <li *ngFor="let patent of update.evidence.patents">
                            <a href="{{patent.valueA}}" target="_blank">{{ patent.valueB }}</a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="update.evidence.grants.length > 0">
                    <h2 style="color:#f06292;">New Outstanding Grants</h2>
                    <ul>
                        <li *ngFor="let grant of update.evidence.grants">
                            <a href="{{grant.valueA}}" target="_blank">{{ grant.valueB }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loading">
        <pre>{{messages.join('\n')}}</pre>
    </div>
</div>
