<div *ngIf="showModal" style="display: flex; flex-direction: column">
    <h3>Instance {{ id }}</h3>
    <div class="col-8">
        <label for="machineId">Machine ID</label>
        <div style="display: flex">
            <input
                type="text"
                class="form-control"
                id="machineId"
                [(ngModel)]="machineId"
                placeholder="Enter Machine Id"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateField('machineId', machineId)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <div class="col-8">
        <label for="department">Department</label>
        <div style="display: flex">
            <input
                type="text"
                class="form-control"
                id="department"
                [(ngModel)]="department"
                placeholder="Enter Department"
                style="width: 500px"
            />
            <button
                type="button"
                (click)="updateField('department', department)"
                class="btn btn-primary btn-sm"
            >
                Update
            </button>
        </div>
    </div>
    <div class="col-8">
        <label for="institutionName">institution</label>
        <div style="display: flex">
            {{ institutionId }} {{ institutionName }}
        </div>
    </div>
    <div class="col-8">
        <span id="institution">Select a new institution:</span>
        <div class="dropdown">
            <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {{ dropdownInsId }} {{ dropdownInsName }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <span
                    id="{{ i.valueA }}"
                    class="dropdown-item"
                    type="button"
                    *ngFor="let i of institutions"
                    (click)="
                        dropdownInsName = i.valueA; dropdownInsId = i.valueB
                    "
                >
                    {{ i.valueB }} - {{ i.valueA }}
                </span>
            </div>
        </div>
        <button
            type="button"
            (click)="updateField('institutionId', dropdownInsId)"
            class="btn btn-primary btn-sm"
        >
            Update Institution
        </button>
    </div>

    <div>
        <div>
            <h3>Add Document</h3>
            <div class="documentBox">
                <div class="col-8">
                    <label for="type">Type</label>
                    <div style="display: flex">
                        <input
                            #type
                            type="text"
                            class="form-control"
                            id="docType"
                            placeholder="Enter document type"
                            style="width: 500px"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="data">Data</label>
                    <div style="display: flex">
                        <textarea
                            #data
                            type="text"
                            class="form-control"
                            id="docData"
                            placeholder="Enter document data"
                            style="width: 500px"
                        ></textarea>
                    </div>
                </div>
                <div class="col-8">
                    <label for="type">URL</label>
                    <div style="display: flex">
                        <input
                            #url
                            type="text"
                            class="form-control"
                            id="docUrl"
                            placeholder="Enter document URL"
                            style="width: 500px"
                        />
                    </div>
                </div>
                <button
                    type="button"
                    (click)="addDocument(type, data, url)"
                    class="btn btn-primary btn-sm"
                >
                    Add
                </button>
            </div>
        </div>
        <div *ngFor="let doc of documents">
            <h3>Document {{ documents.indexOf(doc) + 1 }}</h3>
            <div class="documentBox">
                <div class="col-8">
                    <label for="type">Type</label>
                    <div style="display: flex">
                        <input
                            #type
                            type="text"
                            class="form-control"
                            id="docType"
                            placeholder="Enter document type"
                            style="width: 500px"
                            value="{{ doc.type }}"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="data">Data</label>
                    <div style="display: flex">
                        <textarea
                            #data
                            type="text"
                            class="form-control"
                            id="docData"
                            placeholder="Enter document data"
                            style="width: 500px"
                            value="{{ doc.data }}"
                        ></textarea>
                    </div>
                </div>
                <div class="col-8">
                    <label for="type">URL</label>
                    <div style="display: flex">
                        <input
                            #url
                            type="text"
                            class="form-control"
                            id="docUrl"
                            placeholder="Enter document URL"
                            style="width: 500px"
                            value="{{ doc.url }}"
                        />
                    </div>
                </div>
                <button
                    type="button"
                    (click)="updateDocument(doc.id, type, data, url)"
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
                <button
                    type="button"
                    (click)="removeDocument(doc.id)"
                    class="btn btn-primary btn-sm"
                >
                    Remove
                </button>
            </div>
        </div>
    </div>

    <div>
        <div>
            <h3>Add Researcher</h3>
            <div class="documentBox">
                <div class="col-8">
                    <label for="type">Name</label>
                    <div style="display: flex">
                        <input
                            #name
                            type="text"
                            class="form-control"
                            id="resName"
                            placeholder="Enter a name"
                            style="width: 500px"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="data">Role</label>
                    <div style="display: flex">
                        <input
                            #role
                            type="text"
                            class="form-control"
                            id="resRole"
                            placeholder="Enter a role"
                            style="width: 500px"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="type">Email</label>
                    <div style="display: flex">
                        <input
                            #email
                            type="text"
                            class="form-control"
                            id="resEmail"
                            placeholder="Enter an email"
                            style="width: 500px"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="type">Phone</label>
                    <div style="display: flex">
                        <input
                            #phone
                            type="text"
                            class="form-control"
                            id="resPhone"
                            placeholder="Enter a phone number"
                            style="width: 500px"
                        />
                    </div>
                </div>
                <button
                    type="button"
                    (click)="addResearcher(name, role, email, phone)"
                    class="btn btn-primary btn-sm"
                >
                    Add
                </button>
            </div>
        </div>
        <div *ngFor="let researcher of researchers">
            <h3>Researcher {{ researchers.indexOf(researcher) + 1 }}</h3>
            <div class="documentBox">
                <div class="col-8">
                    <label for="type">Name</label>
                    <div style="display: flex">
                        <input
                            #name
                            type="text"
                            class="form-control"
                            id="resName"
                            placeholder="Enter a name"
                            style="width: 500px"
                            value="{{ researcher.name }}"
                        />
                    </div>
                </div>
                <div class="col-8">
                    <label for="data">Role</label>
                    <div style="display: flex">
                        <textarea
                            #role
                            type="text"
                            class="form-control"
                            id="resRole"
                            placeholder="Enter a role"
                            style="width: 500px"
                            value="{{ researcher.role }}"
                        ></textarea>
                    </div>
                </div>
                <div class="col-8">
                    <label for="data">Email</label>
                    <div style="display: flex">
                        <textarea
                            #email
                            type="text"
                            class="form-control"
                            id="resEmail"
                            placeholder="Enter an email"
                            style="width: 500px"
                            value="{{ researcher.email }}"
                        ></textarea>
                    </div>
                </div>
                <div class="col-8">
                    <label for="data">Phone</label>
                    <div style="display: flex">
                        <textarea
                            #phone
                            type="text"
                            class="form-control"
                            id="resPhone"
                            placeholder="Enter a phone"
                            style="width: 500px"
                            value="{{ researcher.phone }}"
                        ></textarea>
                    </div>
                </div>
                <button
                    type="button"
                    (click)="
                        updateResearcher(
                            researcher.id,
                            name,
                            role,
                            email,
                            phone
                        )
                    "
                    class="btn btn-primary btn-sm"
                >
                    Update
                </button>
                <button
                    type="button"
                    (click)="removeResearcher(researcher.id)"
                    class="btn btn-primary btn-sm"
                >
                    Remove
                </button>
            </div>
        </div>

        <div>
            <button
                type="button"
                (click)="deleteInstance()"
                class="btn btn-primary btn-sm"
                style="color: red"
            >
                Delete instance
            </button>
        </div>
    </div>
</div>
