import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../../network.service';

@Component({
    selector: 'app-publets-auto-complete',
    templateUrl: './publets-auto-complete.component.html',
    styleUrls: ['./publets-auto-complete.component.scss'],
})
export class PubletsAutoCompleteComponent implements OnInit {
    constructor(private networkService: NetworkService) {}

    ngOnInit() {}

    getAllPubletsStartingWith(text) {
        // todo
    }
}
