<div id="container">
    <p>Admin</p>
    <p>
        <a *ngIf="isAdmin()" routerLink="/app/admin/open-alex"
        >Open Alex</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/user-management"
            >User Management</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/groupMgnt"
            >Groups management</a
        ><br />
        <a
            *ngIf="isAdmin()"
            routerLink="/app/admin/insMgnt"
            (click)="resetInstitutionParams()"
            >Institutions management</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/investorMgmt"
            >Investor management</a
        ><br />
        <a
            *ngIf="isAdmin()"
            routerLink="/app/admin/machMgmt"
            (click)="resetMachineParams()"
            >Machine management</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/machine-import"
            >Import machines</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/esriDynamicGenerator"
            >Generate Dynamic Esri Dashboard</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/esri-csv-generator"
            >Generate ESRI Dashboard CSV (Researchers and Companies)</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/newspaperMgmt"
            >Newspaper management</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/synonymsMgmt"
            >Synonyms management</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/adminSearch"
            >Search calibration</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/strengthCompInsMgmt"
            >Strength Mapper Comparable Institution Management</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/vcs-admin">VCs Admin</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/search-tool">Search Tool</a
        ><br />
        <a
            *ngIf="isAdmin()"
            routerLink="/app/admin/startups-admin"
            [queryParams]="{ review: true }"
            >Startups Admin</a
        ><br />
        <a *ngIf="isAdmin()" (click)="openModal()">Create a new user</a><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/google-client-tester"
            >Google Client Tester</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/smart-sheet-admin"
            >Treebute Oracle Admin</a
        ><br />
        <a *ngIf="isAdmin()" routerLink="/app/admin/ct-phase-tool"
            >CT Phase Tool</a
        ><br />
    </p>
    <label name="upload" class="btn btn-success btn-file" style="margin-left: 0"
        >Update homepage
        <input
            type="file"
            style="display: none"
            (change)="updateHomepage($event)"
        />
    </label>

    <div id="modal" *ngIf="showModal">
        <div id="window">
            <div
                (click)="showModal = false"
                class="close"
                style="background-image: url('assets/images/x.png')"
            ></div>
            <h1>User details</h1>
            <div class="row">
                <span id="email">Email:</span>
                <input
                    type="email"
                    style="text-align: center"
                    [(ngModel)]="email"
                />
            </div>
            <div class="row">
                <span id="institution">Institution:</span>
                <div class="dropdown">
                    <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {{ institution }}
                    </button>

                    <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <span
                            id="{{ i.valueA }}"
                            class="dropdown-item"
                            type="button"
                            *ngFor="let i of institutions"
                            (click)="
                                institution = i.valueA; institutionId = i.valueB
                            "
                        >
                            {{ i.valueA }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <span id="userRole">User Role:</span>
                <select
                    [(ngModel)]="newUserRole"
                    id="newUserRole"
                    class="dropdown"
                >
                    <option value="" selected></option>
                    <option *ngFor="let role of userRoleTypes" [value]="role">
                        {{ role }}
                    </option>
                </select>
            </div>
            <div id="message" *ngIf="badRequest">There has been a problem.</div>
            <button
                id="addBtn"
                (click)="add()"
                [disabled]="btnDisabled()"
                [class.btnEnabled]="!btnDisabled()"
            >
                Add
            </button>
        </div>
    </div>
</div>
