<div>
    <mat-form-field class="location-select" appearance="fill">
        <mat-select [(value)]="locationOption" (selectionChange)="this.selectLocation($event)">
            <mat-option
                    *ngFor="let locationOption of locationsOptions"
                    [value]="locationOption"
            >{{ locationOption }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
