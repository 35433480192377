import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {YesNoModalComponent} from "../../../dialogs/yesNo/yes-no-modal.component";
import {ShareModalData, YesNoModalData} from "../../../../api-responses/Dialogs.interface";
import {MatDialog} from "@angular/material/dialog";
import {NetworkService} from "../../../../network.service";
import {DataService} from "../../../../data.service";
import {SharedProject} from "../../../../read-only-project-service";
import {InnovationProject} from "../../../../api-responses/InnovationProjects.interface";
import {ShareModalComponent} from "../../../dialogs/share/share-modal.component";

@Component({
    selector: 'app-share-project',
    templateUrl: './share-project.component.html',
    styleUrls: ['./share-project.component.scss']
})
export class ShareProjectComponent implements OnInit {
    loading: boolean = true;
    shareLink: string;

    @Input() project: InnovationProject;
    @Output() updateShareProject = new EventEmitter()

    sharedProject: SharedProject;
    sharingIsInProgress: boolean = false;
    removeShareIsInProgress: boolean = false;

    @Input() processing: boolean;
    @Output() processingChange = new EventEmitter();

    constructor(
        public dataService: DataService,
        public matDialog: MatDialog,
        private networkService: NetworkService) {

    }

    ngOnInit(): void {
        // this.networkService.getSharedProject(this.project.id).then((sharedProject: SharedProject) => {
        this.dataService.sharedProject.subscribe((sharedProject: SharedProject) => {
            if (sharedProject) {
                this.sharedProject = sharedProject;
                this.updateShareProject.emit(sharedProject);

                if (sharedProject.canonicalUrl) {
                    let prefix = this.dataService.userData.canonicalPrefix;
                    prefix += this.dataService.userData.dev ? '_DEV' : '';
                    this.shareLink = `https://reports.treebute.io/${prefix}/${sharedProject.canonicalUrl}.html`;
                }
                else {
                    let host = this.dataService.isDev() ? 'http://localhost:4200' : 'https://beta2.treebute.io';
                    this.shareLink = `${host}/app/projects/${this.project.id}/read-only-project`;
                }
            }
            this.loading = false;
        })
    }

    showShareModal() {
        let canonical = this.sharedProject ? this.sharedProject.canonicalUrl : '';
        this.networkService.validCanonical(this.project.id, canonical).then((validCanonical) => {
            let dialogRef = this.matDialog.open(ShareModalComponent, {
                data: {
                    projectId: this.project.id,
                    shared: this.sharedProject ? this.sharedProject.enabled : false,
                    canonicalUrl: this.sharedProject ? this.sharedProject.canonicalUrl : '',
                    canonicalPrefix: this.dataService.userData.canonicalPrefix,
                    validCanonical: validCanonical
                } as ShareModalData
            });

            dialogRef
                .afterClosed()
                .toPromise()
                .then((result: ShareModalData) => {
                    if (!result) return
                    this.processingChange.emit(true);
                    let share = result.shared;
                    this.sharingIsInProgress = share;
                    this.removeShareIsInProgress = !share;
                    this.networkService.innovationProjects.share(this.project.id, result)
                        .then((sharedProject: SharedProject) => {
                            this.sharedProject = sharedProject;
                            this.dataService.updateSharedProject(sharedProject);
                        })
                        .catch(() => {
                            let dialogRef2 = this.matDialog.open(YesNoModalComponent, {
                                data: {
                                    dialogTitle: 'Error',
                                    dialogDescription: 'There was a problem when trying to update share options',
                                    buttons: 1,
                                    yesText: 'OK'
                                } as YesNoModalData
                            })
                        })
                        .finally(() => {
                            this.sharingIsInProgress = false;
                            this.removeShareIsInProgress = false;
                            this.updateShareProject.emit(this.sharedProject);
                            this.processingChange.emit(false);
                        });
                });
        })

    }

    copyUrl() {
        navigator.clipboard.writeText( location.protocol + '//' + location.host + "/" + this.shareLink).then(() => {

        });
    }
}
