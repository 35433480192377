import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-newpaper-management',
    templateUrl: './newpaper-management.component.html',
    styleUrls: ['./newpaper-management.component.scss'],
})
export class NewpaperManagementComponent implements OnInit {
    constructor(private networkService: NetworkService) {}

    query: string;
    id: string;
    show = false;
    selectedId;
    message;
    newspapers;

    ngOnInit() {}

    onSearchSubmit() {
        this.message = null;
        this.show = false;
        this.newspapers = [];
        this.networkService.getNewspapersForAdmin(this.query).subscribe(
            (data) => this.handleSearchResult(data),
            (error) => (this.message = error.error.toString()),
        );
    }

    handleSearchResult(data) {
        if (!data || data.length === 0) {
            this.message = 'Newspapers not found.';
        } else {
            this.newspapers = data;
        }
    }

    createNewspaper() {
        if (this.query === undefined || this.query === '') {
            alert('Country cannot be empty');
            return;
        }
        if (
            confirm(
                'Are you sure to create a newspaper with country ' +
                    this.query +
                    '?',
            )
        ) {
            this.message = null;
            this.networkService.createNewspaper(this.query).subscribe(
                (id) => this.openNewspaperDetails(id),
                (error) => (this.message = error.error.toString()),
            );
        }
    }

    openNewspaperDetails(id) {
        this.show = false;
        this.selectedId = id;
        setTimeout(() => (this.show = true), 1);
    }
}
