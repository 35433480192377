<div class="navbar" *ngIf="this.dataService.userData">
    <div class="navbar-nav-wrapper">
        <div class="navbar-nav-back" *ngIf="isSearchPage() || isProjectPage() || isToolPage()">
            <span class="spacer"></span>
            <a (click)="navToProjects()" (mousedown)="navToProjects($event)">
                Projects
            </a>
        </div>
        <div class="navbar-nav-back" *ngIf="this.project">
            <a (click)="navToProject()" (mousedown)="navToProject($event)">
                <span class="inline-icon material-icons">arrow_right</span>
                {{ getProjectName() }}
            </a>
        </div>
        <div class="navbar-nav-back" *ngIf="this.search">
            <a>
                <span class="inline-icon material-icons">arrow_right</span>
                {{ search.title }}
            </a>
        </div>
        <div class="navbar-nav-back" *ngIf="isToolPage()">
            <span class="inline-icon material-icons">arrow_right</span>
            <span class="text-capitalize">{{getToolName()}}</span>
        </div>
    </div>
    <!--    <div class="navbar-nav-back" *ngIf="isSearchPage()">-->
    <!--        <a (click)="back()">-->
    <!--            <span class="inline-icon material-icons" >arrow_back</span>-->
    <!--            Back to Project-->
    <!--        </a>-->
    <!--    </div>-->
    <!--    <div class="navbar-nav-back" *ngIf="this.isProjectPage()">-->

    <!--    </div>-->
    <div class="nav-bar-tools">
        <div class="new-version-button blink" [hidden]="!newVersionExists">
            <button class="btn-warning" (click)="reload()">Newer Version Released! Click to Refresh Page</button>
        </div>
        <img id="my_custom_link" class="chat" src="/assets/images/chat-icon.svg"/>
        <div id="avatarButton" class="avatar" (click)="toggleAccountOptionsModal()">
            {{
			(this.dataService.userInfo$ | async)?.firstName[0]?.toUpperCase()
            }}
        </div>
    </div>
</div>

<div *ngIf="showAccountOptionsModal">
    <app-account-options></app-account-options>
</div>
