import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-strength-comp-ins-management',
    templateUrl: './strength-comp-ins-management.component.html',
    styleUrls: ['./strength-comp-ins-management.component.scss'],
})
export class StrengthCompInsManagementComponent implements OnInit {
    searchString;
    name: string;
    noResults = false;
    newInstitute = false;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {}

    onSearchSubmit() {
        this.name = '';
        this.newInstitute = false;

        this.networkService
            .getStrengthComparableInstitutionForAdmin(this.searchString)
            .subscribe(
                (data) => this.handleResponse(data),
                (error) => console.log(error),
            );
    }

    handleResponse(data) {
        if (!data) {
            this.noResults = true;
            this.newInstitute = true;
        } else {
            this.noResults = false;
            this.name = data;
        }
    }

    searchDisabled() {
        return this.searchString === undefined || this.searchString.length < 3;
    }

    create() {
        this.networkService
            .addStrengthComparableInstitutionForAdmin(this.searchString)
            .subscribe(
                (data) => this.handleResponse(data),
                (error) => {
                    console.log(error);
                    alert(error.error);
                },
            );
    }

    delete() {
        this.networkService
            .removeStrengthComparableInstitutionForAdmin(this.name)
            .subscribe(
                (data) => {
                    this.name = '';
                    this.noResults = true;
                },
                (error) => {
                    console.log(error);
                    alert(error.error);
                },
            );
    }
}
