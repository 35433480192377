import {Component, Inject, OnInit} from "@angular/core";
import {NetworkService} from "../../../network.service";
import {DataService} from "../../../data.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NgModel} from "@angular/forms";
import {Callout, CreateCalloutDialogData} from "../callouts/callout.interface";

@Component({
    selector: 'create-callout-modal',
    templateUrl: './create-callout-modal.component.html',
    styleUrls: ['./create-callout-modal.component.scss'],
})
export class CreateCalloutModalComponent implements OnInit{
    optionAdvise: boolean = false;
    optionProject: boolean = false;
    optionData: boolean = false;

    titleInput: string = "";
    descriptionInput: string = "";

    constructor(
        private networkService: NetworkService,
        public dataService: DataService,
        public dialogRef: MatDialogRef<CreateCalloutModalComponent>,
        public matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: CreateCalloutDialogData
    ) {
    }


    ngOnInit(): void {
        if (this.data.callout) {
            this.optionData = this.data.callout.data;
            this.optionAdvise = this.data.callout.advise;
            this.optionProject = this.data.callout.project;
            this.titleInput = this.data.callout.title;
            this.descriptionInput = this.data.callout.description;
        }
    }

    close() {
        this.dialogRef.close();
    }

    create(advise: boolean, project: boolean, data: boolean, title: string, description: string) {
        if (this.data && this.data.callout) {
            this.data.callout.advise = advise;
            this.data.callout.project = project;
            this.data.callout.data = data;
            this.data.callout.title = title;
            this.data.callout.description = description;
            this.networkService.callouts.update(this.data.callout).then((result: Callout) => {
                this.dialogRef.close(result);
            })
        }
        else {
            return this.networkService.callouts.create({
                advise: advise,
                project: project,
                data: data,
                title: title,
                description: description
            }).then((callout: Callout) => {
                if (callout && callout.id) {
                    // if there's an expert - attach it to the callout
                    if (this.data && this.data.researcher) {
                        this.networkService.callouts.addCandidate(callout, this.data.researcher).then(() => {
                            this.dialogRef.close(callout);
                            window.open('/app/callout/' + callout.id, '_blank');
                        });
                    }
                    this.dialogRef.close(callout);
                }
            })
                .catch((e) => {
                    console.error(e)
                    this.dialogRef.close({});
                });
        }
    }

    closeModal() {
        this.dialogRef.close();
    }

    logit() {
        console.log(this.optionData);
    }

    isLengthValid(model: NgModel) {
        return model.invalid && (model.dirty || model.touched) && model.errors?.['minlength'];
    }

}
