import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {SearchQueryResults} from "../../../api-responses/SearchQueryResults.interface";
import {TileBreadCrumb} from "./tile-bread.crumb";
import {TileSearchBreadcrumbsComponent} from "./breadcrumbs/tile-search-breadcrumbs.component";
import {UtilsComponent} from "../../../utils/utils.component";

@Component({
    selector: 'tile-search-component',
    templateUrl: './tile-search.component.html',
    styleUrls: ['./tile-search.component.scss'],
})
export class TileSearchComponent implements OnInit, OnDestroy, OnChanges {

    @Input() searchResults: SearchQueryResults;
    @Input() showSearchResults: boolean = false;
    @Input() tileData: any;

    @Input() pisOnly: boolean = false;
    @Input() youngLeadersOnly: boolean = false;
    @Input() youngTalentsOnly: boolean = false;
    @Input() influencerOnly: boolean = false;
    @Input() potentialCollaborators: boolean = false;
    @Input() globalSearch: boolean;
    @Input() searchString: any;
    @Input() selectedCountry: any;
    @Input() descriptorParentIds: any;


    global: any;
    forceNLP: any;
    selectedAffiliation: any;

    @Output() breadCrumbsStackPop = new EventEmitter();
    @Output() breadCrumbsStackPush = new EventEmitter<TileBreadCrumb>();
    @Input() breadCrumbsStack: TileBreadCrumb[];
    @Output() navigate = new EventEmitter();

    @Output() showTheResearchers = new EventEmitter();
    @Output() showTiles = new EventEmitter();
    @Output() searchTiles = new EventEmitter();
    @Output() updateMap = new EventEmitter();

    callStack: string[] = [];


    constructor(public utilsComponent: UtilsComponent) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.showSearchResults && changes.showSearchResults.currentValue) {
            this.showSearchResults = changes.showSearchResults.currentValue;
        }
        if (changes.selectedCountry && changes.selectedCountry.currentValue) {
            this.selectedCountry = changes.selectedCountry.currentValue;
        }
        if (changes.tileData && changes.tileData.currentValue) {
            this.tileData = changes.tileData.currentValue;
        }
        if (changes.searchResults && changes.searchResults.currentValue) {
            this.searchResults = changes.searchResults.currentValue;

            if (changes.breadCrumbsStack && changes.breadCrumbsStack.currentValue) {
                // this.showSearchResults = false;
                this.breadCrumbsStack = changes.breadCrumbsStack.currentValue;
            }
            else {
                this.breadCrumbsStack = this.utilsComponent.getBreadCrumbs(this.searchResults);

            }

            // let descriptorIds = this.searchResults.descriptorParentIds ?
            //     this.searchResults.descriptorParentIds.split(',') : [];
            //
            // let descriptorNames = this.searchResults.descriptorParentIds ?
            //     this.searchResults.descriptorParentNames.split(',') : [];
            //
            // for (let i=0; i<descriptorIds.length; i++) {
            //     let b : TileBreadCrumb = {} as TileBreadCrumb;
            //     b.id = descriptorIds[i];
            //     b.name = descriptorNames[i];
            //     this.breadCrumbsStack.breadCrumbsStackPush(b);
            // }
            //
            // this.navigate(this.breadCrumbsStack);
        }
    }

    handleNlp($event: any) {
        console.log('handle NLP');
    }

    _breadCrumbsStackPush($event: TileBreadCrumb) {
        // this.breadCrumbsStack.push($event);
        this.breadCrumbsStackPush.emit($event);
    }

    reset() {
        this.breadCrumbsStack = [];
    }

    _breadCrumbsStackPop() {
        // this.breadCrumbsStack.pop();
        this.breadCrumbsStackPop.emit();
    }

    // _navigate($event: TileBreadCrumb[]) {
    //     this.breadCrumbsStack = $event;
    //     this.callStack = this.breadCrumbsStack ? this.breadCrumbsStack.map(x => x.id) : [];
    // }


    showBreadCrumbs() {
        return true; // this.breadCrumbsStack.length > 0;
    }

    _updateMap($event: any) {
        let event = { data: {
                query: this.searchResults ? this.searchResults.query : '',
                callStack: this.callStack,
                ...$event.data
            }
        }
        this.updateMap.emit(event);
    }
}

