import { Component, OnInit, Input } from '@angular/core';
import { NetworkService } from 'src/app/network.service';

@Component({
    selector: 'app-synonyms-editor',
    templateUrl: './synonyms-editor.component.html',
    styleUrls: ['./synonyms-editor.component.scss'],
})
export class SynonymsEditorComponent implements OnInit {
    @Input() id;

    showModal = false;
    duringUpdate = false;

    text: string;
    synonyms: string;

    constructor(private networkService: NetworkService) {}

    ngOnInit() {
        this.showModal = true;
        this.networkService.getSynonymById(this.id).subscribe(
            (data) => this.handleResponse(data),
            (error) => console.log(error),
        );
    }

    handleResponse(data) {
        this.id = data.dbId;
        this.text = data.text;
        this.synonyms = data.synonyms;
        this.duringUpdate = false;
    }

    updateField(field, value) {
        this.duringUpdate = true;
        this.networkService.updateSynonym(this.id, field, value).subscribe(
            (data) => this.handleResponse(data),
            (error) => {
                this.duringUpdate = false;
                console.log(error);
                alert(error.error.toString());
                this.showModal = false;
            },
        );
    }

    btnDisabled() {
        return this.duringUpdate;
    }
}
