import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {InnovationProject} from "../../../../../api-responses/InnovationProjects.interface";
import {TreebuteProjectSummaryReducer} from "../../../../../utils/treebuteProjectSummaryReducer";
import {EvidenceCount} from "../../../EvidenceAmount.interface";
import {SummaryCount} from "../../../SummaryAmount.interface";
import {BusinessColors, EvidenceColors, ExpertsColors} from "../../../../../utils/colors.enum";
import {NetworkService} from "../../../../../network.service";
import {Descriptor} from "../../../../../api-responses/descriptors.interface";
import {ReadOnlyProjectService, SharedProject} from "../../../../../read-only-project-service";

@Component({
    selector: 'taxonomy-chart',
    templateUrl: './taxonomy-chart.component.html',
    styleUrls: ['./taxonomy-chart.component.scss'],
})
export class TaxonomyChartComponent implements OnInit {

    @Input() project: InnovationProject;
    @Input() isEvidence;
    @Input() isIndustry;
    @Input() title;

    data: object = {};
    descriptorLabels: string[];

    constructor(public readOnlyProjectService: ReadOnlyProjectService, public networkService: NetworkService) {
        if (this.readOnlyProjectService.isReadOnlyProject) {
            this.networkService.getSharedData<Descriptor[]>(this.readOnlyProjectService._sharedProject.descriptorsFile)
                .then((descriptors: Descriptor[]) => {
                this._loadData(descriptors);
            })

            // this.readOnlyProjectService.sharedProject.subscribe((sharedProject: SharedProject) => {
            //     this.networkService.getSharedData<Descriptor[]>(sharedProject.descriptorsFile).then((descriptors: Descriptor[]) => {
            //         this._loadData(descriptors);
            //     })
            // })
        }
    }

    ngOnInit() {
        if (!this.readOnlyProjectService.isReadOnlyProject) {
            this.networkService.innovationProjects.getProjectDescriptors(this.project.id).then((descriptors: Descriptor[]) => {
                this._loadData(descriptors);
            })
        }
    }

    _loadData(descriptors: Descriptor[]) {
        let colors = [
            EvidenceColors.publicationCount,
            EvidenceColors.grantCount,
            EvidenceColors.patentCount,
            EvidenceColors.websiteCount,
            EvidenceColors.clinicalTrialCount,
            ExpertsColors.TILE_SEARCH_EXPERTS,
            ExpertsColors.PATENT_EXPERTS,
            ExpertsColors.CT_EXPERTS,
            ExpertsColors.INDUSTRY_EXPERTS,
            ExpertsColors.ACADEMIC_EXPERTS
        ];

        let data = descriptors.map(x => x.count);
        let sum = descriptors.map(x => x.count).reduce((a,b) => a+b, 0);
        let labels = descriptors.map(x => (x.count*100 / sum).toFixed(1)+"%");
        this.descriptorLabels = descriptors.map(x => x.heading);

        this.data = {
            datasets: [{
                label: "",
                data: [
                    ...data
                ],
                backgroundColor: [
                    ...colors
                ],
                borderColor: "transparent"
            }],
            labels: [
                ...labels
            ]
        }
    }

    // private getByType(x: SearchResult) : boolean{
    //     return this.isIndustry ?
    //         ['STARTUPS', 'CORPORATES'].some(el => x.searchType.includes(el)) :
    //         ['ACADEMIC_EXPERTS', 'INDUSTRY_EXPERTS', 'CT_EXPERTS', 'TILE_EXPERTS'].some(el => x.searchType.includes(el));
    // }
    hasData() {
        return this.data && Object.keys(this.data).length > 0;
    }
}
