<div style="display: flex; flex-direction: column">
    <div
        *ngIf="showModal"
        style="display: flex; justify-content: space-between"
    >
        <div style="height: fit-content; margin-top: 20px">
            <div class="col-8">
                <label for="text" style="color: beige">Text</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="text"
                        [(ngModel)]="text"
                        placeholder="Enter text"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('text', text)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
            <div class="col-8">
                <label for="synonyms" style="color: beige">Synonyms</label>
                <div style="display: flex">
                    <input
                        type="text"
                        class="form-control"
                        id="synonyms"
                        [(ngModel)]="synonyms"
                        placeholder="Enter synonyms"
                        style="width: 500px"
                    />
                    <button
                        type="button"
                        (click)="updateField('synonyms', synonyms)"
                        class="btn btn-primary btn-sm"
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
