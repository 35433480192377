<!--<ul-->
<!--    *ngIf="-->
<!--        type === 'campus' &&-->
<!--        dataService.userData &&-->
<!--        dataService.userData.longitude &&-->
<!--        dataService.userData.longitude &&-->
<!--        isAdmin(dataService.userData)-->
<!--    "-->
<!--    class="nav nav-pills mb-3"-->
<!--    id="pills-tab"-->
<!--    role="tablist"-->
<!--    style="background: #3e3e3e"-->
<!--&gt;-->
<!--    <li class="nav-item active">-->
<!--        <a-->
<!--            class="nav-link active"-->
<!--            id="pills-home-tab"-->
<!--            data-toggle="pill"-->
<!--            role="tab"-->
<!--            aria-controls="pills-home"-->
<!--            aria-selected="true"-->
<!--            style="-->
<!--                letter-spacing: 0.4px;-->
<!--                border-radius: 0;-->
<!--                cursor: pointer;-->
<!--                padding: 5px 20px;-->
<!--                text-align: center;-->
<!--            "-->
<!--            (click)="campusTab()"-->
<!--        >-->
<!--            Researchers map-->
<!--        </a>-->
<!--    </li>-->
<!--    <li class="nav-item">-->
<!--        <a-->
<!--            class="nav-link"-->
<!--            id="pills-profile-tab"-->
<!--            data-toggle="pill"-->
<!--            role="tab"-->
<!--            aria-controls="pills-profile"-->
<!--            aria-selected="false"-->
<!--            style="-->
<!--                letter-spacing: 0.4px;-->
<!--                border-radius: 0;-->
<!--                cursor: pointer;-->
<!--                padding: 5px 20px;-->
<!--                text-align: center;-->
<!--            "-->
<!--            (click)="worldTab()"-->
<!--        >-->
<!--            Collaboration map-->
<!--        </a>-->
<!--    </li>-->
<!--</ul>-->

<div
    id="mapContainer"
    [class.shorter]="this.isExplorer()"
    [class.noCollMap]="
        dataService.userData &&
        (!dataService.userData.longitude ||
            !dataService.userData.longitude ||
            !isAdmin(dataService.userData))
    "
>
    <button
        *ngIf="
            !campusTabActive &&
            dataService.userData &&
            dataService.userData.longitude &&
            dataService.userData.longitude &&
            isAdmin(dataService.userData)
        "
        [disabled]="exportLoading || !collMapExportEnabled"
        [style.opacity]="collMapExportEnabled ? 1 : 0.5"
        id="exportBtn"
        [class.exportBtnClick]="!exportLoading && collMapExportEnabled"
        style="
            background-image: url('assets/images/export-icon-dark.png');
            height: 35px;
        "
        (click)="exportBtnClicked()"
    ></button>

    <div
        *ngIf="
            !campusTabActive &&
            dataService.userData &&
            dataService.userData.longitude &&
            dataService.userData.longitude &&
            isAdmin(dataService.userData)
        "
    >
        <app-world-map
            (mapLoaded)="mapLoaded()"
            (mapInit)="mapInit()"
        ></app-world-map>
    </div>

    <div
        *ngIf="
            (this.isCampus() ||
                (this.isExplorer() && showBar && !noResults)) &&
            campusTabActive
        "
        class="searchTopic"
    >
<!--        <app-search-campus-map-->
<!--            (jumpToMesh)="jumpToMesh($event)"-->
<!--            (trendingState)="updateTrendingState($event)"-->
<!--            [type]="type"-->
<!--            [query]="searchString"-->
<!--            [globalSearch]="global"-->
<!--            [country]="selectedCountry"-->
<!--            [forceNLP]="forceNLP"-->
<!--            [institution]="selectedAffiliation"-->
<!--        >-->
<!--        </app-search-campus-map>-->
    </div>

    <div *ngIf="mapLoading && campusTabActive" class="lds-hourglass"></div>


    <div
        id="{{ type }}"
        [class.hidden]="
            (noResults && foamtree != undefined) || !campusTabActive
        "
    ></div>

</div>
