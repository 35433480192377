<div class="dialog">
    <div class="header">
        <div
                class="close-button close-icon"
                (click)="closeModal()"
        ></div>
        <div class="header-title"> Export Project Data</div>
        <div class="search-tool-container">
            <div class="line">
            </div>
            <!--            <div>-->
            <!--                <input type="text" [(ngModel)]="instQuery" />-->
            <!--                <button class="btn btn-primary" (click)="search()">Search</button>-->
            <!--            </div>-->
            <div class="result-box">
                <div class="result-box-left">
                    <div class="chbx-container">
                        <input type="checkbox" [(ngModel)]="exportProject.description"> Add Descriptions
                    </div>
                    <div class="chbx-container">
                        <input type="checkbox" [(ngModel)]="exportProject.geo"> Add Geo Data
                    </div>
                    <div class="chbx-container">
                        <input type="checkbox" [(ngModel)]="exportProject.googleAcademic"> Add Link to Google Academic
                    </div>
                    <div class="chbx-container">
                        <input type="checkbox" [(ngModel)]="exportProject.linkedIn"> Add Link to LinkedIn
                    </div>
                    <div class="chbx-container" *ngIf="isAdmin()">
                        <input type="checkbox" [(ngModel)]="exportProject.esri"> Also export as ESRI
                    </div>
                </div>
                <div class="result-box-right">
                    <div *ngFor="let search of searches">
                        <div class="chbx-container">
                        <input type="checkbox" [(ngModel)]="search.selected"/> {{ getTitle(search.searchResult.title) }}
                        ({{ search.searchResult.searchType }})
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <button class="btn btn-dark border text-light" (click)="closeModal()">
                Cancel
            </button>
            <button class="btn btn-primary" (click)="export()" [disabled]="!anythingSelected()">
                Export
            </button>
        </div>
    </div>
</div>
