<div class="search-section" [class.disable-when-loading]="loading">
    <div>
        <div class="filters">
            <div class="internal-container">
                <div class="filter lovely-text">
                    filters:
                </div>
                <div class="filter">
                    <search-filter-country
                            [(searchResults)]="searchResults"
                            (searchResultsChange)="searchResultsChange.emit($event)"
                    ></search-filter-country>
                </div>
                <div class="filter">
                    <app-search-filter-researcher
                            [(searchResults)]="searchResults"
                            (searchResultsChange)="searchResultsChange.emit($event)"
                    ></app-search-filter-researcher>
                </div>
                <div class="filter">
                    <app-search-filter-researcher-ct
                            [(searchResults)]="searchResults"
                            (searchResultsChange)="searchResultsChange.emit($event)"
                    ></app-search-filter-researcher-ct>
                </div>
                <div class="filter" [hidden]="!isIndustry">
                    <input type="checkbox" [(ngModel)]="hasGrants" (ngModelChange)="toggle($event)"/>
                    <span class="lovely-text" >Only With Grants</span>
                </div>
            </div>
        </div>
        <div class="description-container">
            <div class="internal-container">
                <div class="lovely-text">
                    {{ this.getSearchDescription() }}
                </div>
            </div>
        </div>
    </div>
</div>
